/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isFolder } from 'utils/helpers';
import { copyService } from 'App/Pages/ProcessApplicationVersions/features/copy/CopyService';
import { notificationStore } from 'components/NotificationSystem';

export const handleCopyVersionToTarget = async ({ versionId, target, history }) => {
  try {
    if (isFolder(target)) {
      await copyService.createProcessApplicationFromVersion(versionId, target.projectId, target.id);
      history.push(`/folders/${target.id}`);
    } else {
      await copyService.createProcessApplicationFromVersion(versionId, target.id, undefined);
      history.push(`/projects/${target.id}`);
    }
    notificationStore.showSuccess('Process application has been created from this version.');
  } catch {
    notificationStore.showError(`Oops! We couldn't create the process application from this version.`);
  }
};
