/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import moment from 'moment/moment';

import { getEntityIcon, getEntityName, getEntityVersionsLink } from './helpers';
import hasAccess, { actions } from './user-access';

export function getNameRendererInput(resource) {
  const nameObject = resource.milestone?.name
    ? {
        content: resource.name,
        pretitle: resource.milestone.name
      }
    : getEntityName(resource);

  const iconAndNameObject = {
    icon: getEntityIcon(resource),
    name: nameObject
  };

  return hasAccess({ permissionAccess: resource.project?.permissionLevel }, actions.VIEW_VERSIONS)
    ? {
        ...iconAndNameObject,
        linkProps: { link: getEntityVersionsLink(resource) }
      }
    : iconAndNameObject;
}

export const getMilestoneUpdatedDate = (resource) => {
  if (resource.milestone?.updated) {
    return moment(resource.milestone.updated).format('YYYY-MM-DD HH:mm');
  }

  return '';
};
