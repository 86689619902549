/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineLoading } from '@carbon/react';
import { UnknownFilled } from '@carbon/icons-react';

import { truncateLongText } from 'App/Pages/Project/IdpApplication/IdpProject/utils/utils';
import { EXTRACTION_VALIDATION_STATUS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

import * as Styled from './StatusCell.styled';

function StatusCell({ row }) {
  const renderStatusCell = (IconComponent, primaryText, secondaryText, isPassed = true) => {
    return (
      <Styled.StatusContainer>
        <Styled.IconContainer>
          <IconComponent size={16} />
        </Styled.IconContainer>
        <Styled.ColumnContainer>
          <div>{primaryText}</div>
          {secondaryText && (
            <Styled.StatusLabelContainer $isPassed={isPassed}>{secondaryText}</Styled.StatusLabelContainer>
          )}
        </Styled.ColumnContainer>
      </Styled.StatusContainer>
    );
  };
  switch (row.status) {
    case EXTRACTION_VALIDATION_STATUS.PASS:
      return renderStatusCell(
        Styled.GreenCheckmarkFilled,
        truncateLongText(row.extractedValue),
        `Expected value: ${truncateLongText(row.expectedValue)}`,
        true
      );
    case EXTRACTION_VALIDATION_STATUS.FAIL:
      return renderStatusCell(
        Styled.RedErrorFilled,
        truncateLongText(row.extractedValue),
        `Expected value: ${truncateLongText(row.expectedValue)}`,
        false
      );
    case EXTRACTION_VALIDATION_STATUS.IN_PROGRESS:
      return (
        <Styled.StatusContainer>
          <InlineLoading status="active" iconDescription="Loading" description="Testing document" />
        </Styled.StatusContainer>
      );
    case null:
    case undefined:
      if (row.expectedValue) {
        return renderStatusCell(
          UnknownFilled,
          'Test documents to see result',
          `Expected value: ${truncateLongText(row.expectedValue)}`
        );
      } else {
        return renderStatusCell(
          Styled.YellowWarningFilled,
          'Test case is missing to compare result',
          row.extractedValue ? `Extracted value: ${row.extractedValue}` : null
        );
      }
    default:
      return null;
  }
}

export default StatusCell;
