/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const lightGreen = '#47C28B';
export const lightOrange = '#FFC667';
export const lightRed = '#FA8072';

export const darkGreen = '#359168';
export const darkOrange = '#E28F00';
export const darkRed = '#FA8072';
