/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import history from 'utils/history';
import { README } from 'utils/constants';
import { getEntityLink } from 'utils/helpers';
import { projectStore } from 'stores';

export const createAndNavigateToReadme = async () => {
  const readme = await projectStore.createFile({ type: README });

  if (readme) {
    history.push(getEntityLink(readme));
  }
};
