/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const ElementMenuButton = styled.button`
  padding: 0;
  border: none;
  border-radius: 2px;
  color: white;
  outline: none;
  cursor: pointer;
  background-color: var(--cds-blue-60);

  &:hover,
  &:focus {
    background-color: var(--cds-blue-60);
  }

  &:active {
    background-color: var(--cds-blue-70);
  }

  > svg {
    vertical-align: middle;
  }
`;
