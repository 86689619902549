/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  button:nth-child(1) {
    padding: 0 var(--spacing-small);
    border-radius: 2px 0 0 2px;
  }

  button:nth-child(2) {
    padding: 0;
    border-radius: 0 2px 2px 0;
    width: 30px;
    background-position: center center;
    margin-left: 1px;

    &,
    &:hover {
      stroke-linecap: round;
    }
  }
`;
