/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import tokenSimulation from 'bpmn-js-token-simulation';
import viewerTokenSimulation from 'bpmn-js-token-simulation/lib/viewer';

import toggleOverride from './toggleOverride';
import help from './help';
import tokenSimulationKeyboardBindingsOverride from './tokenSimulationKeyboardBindingsOverride';

export const tokenSimulationModule = {
  __depends__: [tokenSimulation, toggleOverride, help, tokenSimulationKeyboardBindingsOverride]
};
export const viewerTokenSimulationModule = {
  __depends__: [viewerTokenSimulation, toggleOverride, help]
};
