/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { userStore } from 'stores';
import { useQuery } from 'hooks';
import { authService } from 'services';

export default function useAuthentication() {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    switch (location.pathname) {
      case '/login':
        if (userStore.isAuthenticated) {
          history.push(query.get('returnUrl') || '/');
        } else {
          authService.loginWithRedirect();
        }
        break;
      case '/login-callback':
        authService.handleRedirectCallback();
        break;
      case '/logout':
        if (userStore.isSuperUserModeActive) {
          userStore.toggleSuperUserMode(); // Turn off
        }
        authService.logout();
        break;
    }
  }, [location]);
}
