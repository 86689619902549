/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { clustersStore } from 'stores';
import getClustersWithDocumentHandling from 'App/Pages/Project/IdpApplication/utils/get-clusters-with-document-handling';

/**
 * @param {Object} props - Hook props object
 * @param {Boolean} props.reloadClusters - Whether clusters needs to be reloaded or not
 * @param {string} [props.clusterId] - Cluster id for pre-selected cluster. If not provided then first available cluster with document handling feature is selected
 */
export function useClusterSelection({ reloadClusters, clusterId }) {
  const clusters = getClustersWithDocumentHandling(clustersStore.clusters);
  const firstAvailableCluster = clusters?.find((cluster) => !cluster.disabled);
  const preSelectedCluster = clusters?.find((cluster) => cluster.uuid === clusterId && !cluster.disabled);

  const [selectedCluster, setSelectedCluster] = useState(preSelectedCluster ?? firstAvailableCluster);

  useEffect(() => {
    if (reloadClusters) {
      clustersStore.loadClusters();
    }
  }, [reloadClusters]);

  useEffect(() => {
    const newPreSelectedCluster = clusters?.find((cluster) => cluster.uuid === clusterId && !cluster.disabled);
    if (newPreSelectedCluster && newPreSelectedCluster.uuid !== selectedCluster?.uuid) {
      setSelectedCluster(newPreSelectedCluster);
    }
  }, [clusterId]);

  useEffect(() => {
    // When the list of clusters gets updated, we verify if the current selected one is still present.
    // If it is not, then we select the first one by default
    if (!clusters?.some((cluster) => cluster.uuid === selectedCluster?.uuid)) {
      setSelectedCluster(firstAvailableCluster);
    }
  }, [clusters, firstAvailableCluster, selectedCluster]);

  return {
    clusters,
    selectedCluster,
    setSelectedCluster
  };
}
