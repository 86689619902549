/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isLabel } from 'diagram-js/lib/util/ModelUtil';

import { addBottomLeftOverlay } from 'App/Pages/Diagram/overlay-util';
import { publicationStore } from 'App/Pages/Diagram/stores';

import formLinkStore from './FormLinkStore';
import { isFormLinkingSupportedByElement, getElementsWithForm } from './utils';

const OVERLAY_TYPE = 'form-link-menu';

function FormLinkExtension(bpmnjs, elementRegistry, eventBus, overlays) {
  const addOverlays = () => {
    elementRegistry.forEach((element) => addOverlayIfSupported(element));
  };

  const addOverlayIfSupported = (element) => {
    if (isFormLinkingSupportedByElement(element) && !isLabel(element)) {
      addBottomLeftOverlay({ overlays, overlayType: OVERLAY_TYPE, element });
    }
  };

  const setElementsWithForm = () => {
    const { startEvents, userTasks } = getElementsWithForm(bpmnjs);

    formLinkStore.setLinkedElements([...startEvents, ...userTasks]);

    publicationStore.setIsStartEventAttachedToForm(startEvents.length > 0);
  };

  const setSelectedElementIfSupported = (element) => {
    if (element && isFormLinkingSupportedByElement(element)) {
      formLinkStore.setSelectedElement(element);
    } else {
      formLinkStore.setSelectedElement(null);
    }
  };

  // TODO(philippfromme): this state should automatically be updated when the
  // selected element changes
  const updatePublicAccess = () => {
    const { selectedElement } = formLinkStore;

    if (!selectedElement) {
      return;
    }

    publicationStore.setIsPublicAccessSupported(isFormLinkingSupportedByElement(selectedElement));
  };

  eventBus.on('import.done', () => {
    setElementsWithForm();

    addOverlays();
  });

  eventBus.on('shape.added', (context) => {
    const { element } = context;

    addOverlayIfSupported(element);
  });

  eventBus.on('element.changed', (context) => {
    const { element } = context;

    if (element.id === formLinkStore.selectedElementId) {
      setSelectedElementIfSupported(element);

      updatePublicAccess();
    }
  });

  eventBus.on('elements.changed', () => {
    setElementsWithForm();
  });

  eventBus.on('selection.changed', (event) => {
    const { newSelection = [] } = event;

    const element = newSelection.length === 1 && newSelection[0];

    setSelectedElementIfSupported(element);
  });
}

FormLinkExtension.$inject = ['bpmnjs', 'elementRegistry', 'eventBus', 'overlays'];

export default {
  __init__: ['formLinkExtension'],
  formLinkExtension: ['type', FormLinkExtension]
};
