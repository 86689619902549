/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Sidebar } from 'primitives';

export function DateCreated({ dateString }) {
  return (
    <div role="listitem" aria-disabled>
      {/* @ts-expect-error TS2339 */}
      <Sidebar.Separator>
        <span>{dateString}</span>
        {/* @ts-expect-error TS2339 */}
      </Sidebar.Separator>
    </div>
  );
}
