/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * @param {object} options
 * @param {boolean} options.isDirty    - Whether there are unsaved changes.
 * @param {function}[options.onConfirm]  - Callback function called when user confirms leaving.
 * @param {function}[options.onCancel]   - Callback function called when user cancels leaving.
 */
export default function useNavigationGuard({ isDirty, onConfirm = () => {}, onCancel = () => {} }) {
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const unblockRef = useRef(null);

  // ------------------------------------------------------------------
  // 1. In-app route changes (using history.block)
  // ------------------------------------------------------------------
  useEffect(() => {
    if (isDirty) {
      // If we have unsaved changes, block navigation
      unblockRef.current = history.block((targetLocation, action) => {
        // Block navigation and open our custom modal
        setIsModalOpen(true);
        setPendingAction({
          type: 'internal',
          location: targetLocation,
          action
        });
        // Returning false means "fully block" (no native prompt).
        return false;
      });
    } else {
      // If not dirty, un-block if we previously set it
      unblockRef.current?.();
    }

    // Cleanup: if this component unmounts, un-block
    return () => {
      unblockRef.current?.();
    };
  }, [isDirty, history]);

  // ------------------------------------------------------------------
  // 2. External link clicks
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!isDirty) {
      return;
    }

    function handleExternalLinkClick(event) {
      const target = event.target;
      if (!target || target.tagName !== 'A') {
        return;
      }

      const href = target.getAttribute('href');
      if (!href) {
        return;
      }

      let url;
      try {
        // Construct an absolute URL to check origin
        url = new URL(href, window.location.origin);
      } catch {
        // Mailto links etc. might fail in URL()
        return;
      }

      const isExternal = url.origin !== window.location.origin;
      if (isExternal) {
        // Block the default navigation
        event.preventDefault();
        setIsModalOpen(true);
        setPendingAction({ type: 'external', url: href });
      }
    }

    document.addEventListener('click', handleExternalLinkClick);
    return () => {
      document.removeEventListener('click', handleExternalLinkClick);
    };
  }, [isDirty]);

  // ------------------------------------------------------------------
  // 3. Hard reloads / tab closures (beforeunload)
  // ------------------------------------------------------------------
  useEffect(() => {
    function handleBeforeUnload(e) {
      if (isDirty) {
        // Show a native browser prompt
        e.preventDefault();
        // Some browsers require setting returnValue
        e.returnValue = '';
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  // ------------------------------------------------------------------
  // 4. Handlers for our custom modal
  // ------------------------------------------------------------------
  function handleConfirm() {
    setIsModalOpen(false);
    onConfirm();

    if (!pendingAction) {
      return;
    }

    if (pendingAction.type === 'internal') {
      // Allow the original navigation to proceed.
      // 1) Unblock
      unblockRef.current?.();
      // 2) Manually navigate
      history.push(pendingAction.location.pathname, pendingAction.location.state);
      // 3) (Optional) Re-block if still dirty
      if (isDirty) {
        unblockRef.current = history.block((targetLocation, action) => {
          setIsModalOpen(true);
          setPendingAction({
            type: 'internal',
            location: targetLocation,
            action
          });
          return false;
        });
      }
    } else if (pendingAction.type === 'external') {
      // Navigate to external URL
      window.location.href = pendingAction.url;
    }

    setPendingAction(null);
  }

  function handleCancel() {
    setIsModalOpen(false);
    onCancel();

    if (pendingAction?.type === 'internal') {
      // We do nothing here, so navigation remains blocked.
      // The user stays on the current page.
    }
    setPendingAction(null);
  }

  // Return state and callbacks to let the component render a modal
  return {
    isModalOpen,
    handleConfirm,
    handleCancel
  };
}
