/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { v4 as uuidv4 } from 'uuid';

import { notificationStore } from 'components/NotificationSystem';
import pluralize from 'utils/pluralize';
import {
  getDuplicateResourceInProcessApplicationErrorMessage,
  isDuplicateResourceInProcessApplicationError
} from 'utils/helpers';

const errorDuration = 20000;
const successDuration = 8000;

export const showInvalidFilesError = (files) => {
  return notificationStore.error(
    {
      title: `Invalid ${pluralize('file', files.length)}`,
      content: (
        <>
          {files.map((file) => (
            <div key={uuidv4()}>{file.name}</div>
          ))}
        </>
      )
    },
    {
      shouldPersist: false,
      duration: errorDuration
    }
  );
};

export const showProgressIndicationInfo = (files, content) => {
  return notificationStore.info(
    {
      title: `Uploading ${pluralize('file', files.length)}`,
      content
    },
    {
      shouldPersist: true
    }
  );
};

/**
 * type {UploadError}
 * @typedef {Object} UploadError
 * @property {Error} error - The error object
 * @property {string} filename - The name of the file that failed to upload
 */
/**
 * Show a notification that the file upload has failed.
 * @param errors {UploadError[]} - The errors that occurred during the upload
 * @returns {import('components/NotificationSystem/NotificationStore').Notification[]} - The rendered errors
 */
export const showFileUploadFailedError = (errors) => {
  const maxRenderedErrors = 5;
  const renderedErrors = [];

  const renderError = (error, filename) => {
    const errorOpts = {
      shouldPersist: false,
      duration: errorDuration
    };

    const errorContent = isDuplicateResourceInProcessApplicationError(error)
      ? getDuplicateResourceInProcessApplicationErrorMessage(error)
      : error?.message || 'An unknown error occurred';

    return notificationStore.error(
      {
        title: `Failed to upload ${filename}`,
        content: errorContent
      },
      errorOpts
    );
  };

  if (errors.length > maxRenderedErrors) {
    renderedErrors.push(
      notificationStore.error(
        {
          title: `Failed to upload ${pluralize('file', errors.length)}`,
          content: (
            <>
              {errors.slice(0, maxRenderedErrors).map(({ filename }) => (
                <div key={uuidv4()}>{filename}</div>
              ))}
            </>
          )
        },
        {
          shouldPersist: false,
          duration: errorDuration
        }
      )
    );
  } else {
    errors.forEach(({ error, filename }) => {
      renderedErrors.push(renderError(error, filename));
    });
  }

  return renderedErrors;
};

export const showSimpleError = ({ title, content }) => {
  return notificationStore.error(
    {
      title,
      content
    },
    {
      shouldPersist: false,
      duration: errorDuration
    }
  );
};

export const showC7FileUploadSuccess = (files) => {
  const length = files.length;

  return notificationStore.success(
    {
      title: `Upload successful`,
      content: (
        <>
          <span>
            <strong>{length}</strong> Camunda 7 {pluralize('resource', length)} {pluralize('has', length)} been
            imported.
            <br />
            <br />
            Some attributes might have changed.{' '}
            <a href="https://docs.camunda.io/docs/guides/migrating-from-camunda-7/" target="blank">
              Learn more
            </a>{' '}
            about migrating from Camunda 7.
          </span>
        </>
      )
    },
    {
      duration: successDuration
    }
  );
};
