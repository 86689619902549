/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

import { configuredClustersStore, userStore } from 'stores';

/**
 * Loads clusters from configuration.
 *
 * Only relevent for Self-Managed.
 * In SaaS, clusters list is obtained from Console.
 */
export default function useConfiguredClusters() {
  const { isAuthenticated } = userStore;

  useEffect(() => {
    if (isAuthenticated) {
      configuredClustersStore.load();
    }
  }, [isAuthenticated]);
}
