/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { CodeSnippet, InlineNotification } from '@carbon/react';

import { Button, Dialog } from 'primitives';

import * as Styled from './DeploymentErrorsModal.styled';

export default function DeploymentErrorsModal({ open, onClose, title, notification, errors }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>
        <Dialog.Title>{title}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Content>
        <InlineNotification title={notification} kind="error" lowContrast hideCloseButton />
        <Styled.ErrorMessageSection>
          <Styled.ErrorMessageLabel>Error message</Styled.ErrorMessageLabel>
          <CodeSnippet data-test="errors-list" wrapText type="multi" copyButtonDescription="Copy">
            {errors}
          </CodeSnippet>
        </Styled.ErrorMessageSection>
      </Dialog.Content>
      <Dialog.Footer>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
