/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ConnectorError } from './ConnectorError';
import { documentExtractionProcess } from './bpmn-snippets';
import runInstanceWithResult from './run-instance-with-result';

export const CloudProvider = {
  AWS: 'aws',
  VERTEX: 'vertex'
};
Object.freeze(CloudProvider);

export default async function extractDocument({
  idpProjectId,
  clusterId,
  idpDocuments,
  taxonomy,
  modelId,
  baseRequest = {
    type: CloudProvider.AWS,
    authentication: {
      type: 'credentials',
      accessKey: '{{secrets.IDP_AWS_ACCESSKEY}}',
      secretKey: '{{secrets.IDP_AWS_SECRETKEY}}'
    },
    configuration: {
      region: '{{secrets.IDP_AWS_REGION}}'
    },
    s3BucketName: '{{secrets.IDP_AWS_BUCKET_NAME}}',
    extractionEngineType: 'AWS_TEXTRACT'
  }
}) {
  const xml = documentExtractionProcess
    .replace('{{TAXONOMY}}', escapeQuotes(JSON.stringify(taxonomy)))
    .replace('{{CONVERSE_DATA}}', escapeQuotes(JSON.stringify(getConverseData(modelId))))
    .replace('{{BASE_REQUEST}}', escapeQuotes(JSON.stringify(baseRequest)));
  const instancePayloads = idpDocuments.map((document) => ({
    document: {
      'camunda.document.type': document.clusterDocumentType,
      storeId: document.clusterDocumentStoreId,
      documentId: document.clusterDocumentId,
      contentHash: document.clusterDocumentContentHash,
      metadata: {
        size: document.size
      }
    }
  }));
  const results = await runInstanceWithResult(idpProjectId, clusterId, xml, 100, instancePayloads);
  const fulfilledResults = results.filter((result) => result.status === 'fulfilled');
  const error = fulfilledResults.find((result) => !!result?.value?.variables?.error)?.value?.variables?.error;

  if (error) {
    throw new ConnectorError(error.message, error.type);
  }

  return fulfilledResults.map((result) => ({
    processInstanceKey: result.value.processInstanceKey,
    clusterDocumentId: result.value.variables.document.documentId,
    variables: sanitizeExtractedFields(result.value.variables.result.extractedFields)
  }));
}

function escapeQuotes(string) {
  return string.replaceAll('"', '&#34;');
}

function getConverseData(modelId) {
  // TODO: Replace with model specific converse data when available.
  return {
    modelId: modelId,
    maxTokens: 512,
    temperature: 0.5,
    topP: '0.9'
  };
}

function sanitizeExtractedFields(extractedFields) {
  if (!extractedFields || typeof extractedFields !== 'object') {
    return extractedFields;
  }

  const sanitized = { ...extractedFields };

  for (const key in sanitized) {
    const value = sanitized[key];

    if (typeof value === 'object' && value !== null) {
      sanitized[key] = JSON.stringify(value);
    }
  }

  return sanitized;
}
