/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { processApplicationService } from 'services';

/**
 * Sets the latest version name as placeholder
 */
export function usePlaceholder(processApplicationId, isOpen) {
  const [placeholder, setPlaceholder] = useState('1.0');
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          const versions = (await processApplicationService.fetchProcessApplicationVersions(processApplicationId)).sort(
            (a, b) => b.created - a.created
          );
          const latestVersionName = versions?.[0]?.name;
          if (latestVersionName) {
            setPlaceholder(latestVersionName);
            setHelperText('The placeholder shows the name of the last saved version for reference.');
          } else {
            setHelperText('');
          }
        } catch {
          setHelperText('');
        }
      })();
    }
  }, [isOpen, processApplicationId]);

  return { placeholder, helperText };
}
