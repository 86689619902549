/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { v4 } from 'uuid';

import { CONNECTOR_TEMPLATE_MAX_ICON_SIZE } from 'utils/constants';
import { notificationStore } from 'components/NotificationSystem';

import CreateNewElementTemplateFileUploadCommonFileItem from './CreateNewElementTemplateFileUploadCommonFileItem';
import CreateNewElementTemplateFileUploadCommon from './CreateNewElementTemplateFileUploadCommon';
import * as Styled from './CreateNewElementTemplate.styled';

export function CreateNewElementTemplateFileUpload({
  fileData,
  setFileData,
  validFileTypes,
  fileSizeLimit = CONNECTOR_TEMPLATE_MAX_ICON_SIZE,
  onLoadFn,
  onDeleteFn,
  afterLoadError
}) {
  const uploaderButton = useRef(null);

  const onAddFile = useCallback((evt, { addedFiles }) => {
    evt.stopPropagation();
    addFile(addedFiles);
  });

  const isTextType = (validFileTypes) => {
    return (
      validFileTypes.includes('.json') ||
      validFileTypes.includes('json') ||
      validFileTypes.includes('.yaml') ||
      validFileTypes.includes('yaml')
    );
  };

  const addFile = (addedFiles) => {
    try {
      if (!addedFiles) {
        return;
      }
      const fileData = {
        uuid: v4(),
        name: addedFiles[0].name,
        fileSize: addedFiles[0].size,
        status: 'uploading',
        iconDescription: 'Uploading',
        invalidFileType: addedFiles[0].invalidFileType
      };

      // validations
      if (!CreateNewElementTemplateFileUploadCommon.isFileSizeValid(fileData, setFileData, fileSizeLimit)) {
        return;
      }
      if (!CreateNewElementTemplateFileUploadCommon.isFileTypeValid(fileData, setFileData)) {
        return;
      }

      // set file content
      setFileData(fileData);
      const reader = new FileReader();
      reader.addEventListener('load', async (e) => {
        const fileContent = e.target.result;
        fileData.fileContent = fileContent;
        CreateNewElementTemplateFileUploadCommon.setFileContentAndShowWithCompleteStatus(
          fileData,
          setFileData,
          fileContent
        );
        if (onLoadFn) {
          const isOnloadSuccessful = await onLoadFn(fileContent);
          if (!isOnloadSuccessful) {
            afterLoadError && afterLoadError(fileData, setFileData);
          } else {
            CreateNewElementTemplateFileUploadCommon.showFileContentWithEditStatus(fileData, setFileData);
          }
        } else {
          CreateNewElementTemplateFileUploadCommon.showFileContentWithEditStatus(fileData, setFileData);
        }
      });
      if (isTextType(validFileTypes)) {
        reader.readAsText(addedFiles[0]);
      } else {
        reader.readAsDataURL(addedFiles[0]);
      }
    } catch (e) {
      notificationStore.error(
        { title: 'Error', content: `Oops! We couldn't import the file.` },
        { shouldPersist: true }
      );
      console.error(e);
    }
  };

  return (
    <>
      {!fileData && (
        <Styled.FullWidthFileUploaderDropContainer
          labelText={'Drag and drop a file here or click to upload'}
          multiple={false}
          accept={validFileTypes}
          onAddFiles={onAddFile}
          innerRef={uploaderButton}
        />
      )}
      {fileData && (
        <CreateNewElementTemplateFileUploadCommonFileItem
          fileData={fileData}
          setFileData={setFileData}
          onDeleteFn={onDeleteFn}
        />
      )}
    </>
  );
}

export default observer(CreateNewElementTemplateFileUpload);
