/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { mapToProcessLandscapeData } from 'features/landscape-view/utils/helpers';
import landscapeViewService from 'features/landscape-view/LandscapeViewService';
import { notificationStore } from 'components/NotificationSystem';

export const useFetchLandscapeData = (isOpen, projectId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [landscapeData, setLandscapeData] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const fetchLandscapeData = async () => {
      try {
        setIsLoading(true);
        const data = await landscapeViewService.fetchProcessLandscapeData(projectId);
        const mappedLandscapeData = mapToProcessLandscapeData(data);

        setLandscapeData(mappedLandscapeData);
      } catch (err) {
        console.error(err);
        setError(`Failed to load landscape data: ${err.error}`);
        notificationStore.error({
          title: `Failed to load landscape data:`,
          content: `${err.status} ${err.error}.`
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchLandscapeData();
  }, [isOpen, projectId]);

  return { isLoading, error, landscapeData };
};
