/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { clustersStore } from 'stores';
import { authService, consoleService, trackingService } from 'services';
import config from 'utils/config';
import pluralize from 'utils/pluralize';

const SALES_PLAN_TYPE = {
  PAID_CC: 'paid-cc',
  ENTERPRISE: 'enterprise',
  TRIAL: 'trial',
  FREE: 'free',
  FREE_TP_PAID_REQUEST: 'free-to-paid-request',
  IN_NEGOTIATION: 'in-negotiation',
  INTERNAL: 'internal'
};

const EARLY_ACCESS_FEATURES = {
  PLAY: 'zeebeplay'
};

class BaseOrganizationStore {
  organizations = []; // List of organizations that the user has access to
  currentOrganization = {}; // Currently selected organization
  currentOrganizationInfo = null; // Info (permissions, license, etc.) related to the selected organization
  availableOrganizations = []; // List of organizations that the user is assigned to
  hasElevatedOrganizationPermissions = false;

  // Fetch index to prevent race condition
  latestFetch = 0;

  constructor() {
    makeObservable(this, {
      currentOrganization: observable,
      currentOrganizationInfo: observable,
      availableOrganizations: observable,
      currentOrganizationId: computed,
      hasClustersReadPermission: computed,
      hasElevatedOrganizationPermissions: observable,
      hasWorkflowsCreatePermission: computed,
      hasInstancesCreatePermission: computed,
      hasMemberCreatePermission: computed,
      hasBillingPermission: computed,
      organizationManagementPageUrl: computed,
      isTrialExpired: computed,
      isUsingTrial: computed,
      remainingTrialDays: computed,
      hasLegacyPlayAccess: computed,
      hasPlayC8Access: computed,
      isInternal: computed,
      isEnterpriseDevOrganization: computed,
      setOrganization: action,
      setAvailableOrganizations: action
    });
  }

  #updateOrganizationInfo = (organization) => {
    runInAction(() => (this.currentOrganization = organization));
    this.fetchOrganizationInfoFromConsole(organization.id);
    trackingService.setOrganization(organization);
  };

  setOrganization = (organizationId) => {
    const organization = this.guessCurrentOrganization(organizationId);
    if (organization) {
      this.#updateOrganizationInfo(organization);
    } else {
      authService
        .getTokenAndFetchAuthProviderUser()
        .then((updatedOrganizations) => {
          // After updating the token, the user has at least one organization
          runInAction(() => (this.organizations = updatedOrganizations));
          this.#updateOrganizationInfo(this.guessCurrentOrganization(organizationId));
        })
        .catch(() => console.info('User has no organization, that should not happen.'));
    }
  };

  setAvailableOrganizations = (organizations) => {
    this.availableOrganizations = organizations;
  };

  /**
   * Makes the best guess what the current organization is based on the available information
   */
  guessCurrentOrganization = (organizationId) => {
    /**
     * Guessing logic
     *
     * Use the organization ID passed as parameter if the user has access to the
     * corresponding organization. If not, try using the last used organization ID
     * from Local Storage (if present). If that fails too, use the first organization
     * from the organizations list.
     */
    let organization = null;
    const organizationIdFromLS = localStorage.getItem('modeler.org.last_used');
    if (organizationId && this.userHasAccessToOrganization(organizationId)) {
      // using ID from param
      organization = this.getOrganizationById(organizationId);
      localStorage.setItem('modeler.org.last_used', organizationId);
    } else if (organizationIdFromLS && this.userHasAccessToOrganization(organizationIdFromLS)) {
      // using ID from LS
      organization = this.getOrganizationById(organizationIdFromLS);
    } else {
      // using the first organization from the list
      organization = this.organizations[0];
    }

    return organization;
  };

  /**
   * Fetches the list of organizations that the current user is assigned to
   */
  fetchUserOrganizationsFromConsole = async () => {
    try {
      this.setAvailableOrganizations(await consoleService.getUserOrganizations());
    } catch (e) {
      console.info(e);
    }
  };

  /**
   * Fetches info (like permissions, license, clusters etc.) related to the current organization
   * from the console
   */
  fetchOrganizationInfoFromConsole = async (organizationId) => {
    const currentFetch = ++this.latestFetch;

    try {
      const organizationInfo = await consoleService.getOrganizationInfo(organizationId);
      await clustersStore.loadClusters();

      trackingService.trackLicenseCheck(
        organizationInfo?.salesPlan.type,
        Boolean(organizationInfo?.salesPlan.trialExpired)
      );

      // Make sure the last initiated fetch wins
      if (currentFetch === this.latestFetch) {
        runInAction(() => {
          this.currentOrganizationInfo = organizationInfo;
        });
      }
    } catch (e) {
      console.info(e);
    }
  };

  getOrganizationById = (organizationId) => {
    return this.organizations.find((organization) => organization.id === organizationId);
  };

  userHasAccessToOrganization = (orgId) => {
    return this.organizations.some((org) => org?.id === orgId);
  };

  /**
   * Determines if a specific feature is enabled for this user in his current organization.
   */
  // Keeping this method as it will be reused for new connectors.
  isFeatureEnabled = () => {
    return true;
  };

  get currentOrganizationId() {
    return this.currentOrganization?.id;
  }

  /**
   * Determines if the license for the current organization is expired.
   */
  get isTrialExpired() {
    return this.currentOrganizationInfo?.salesPlan.trialExpired;
  }

  get hasClustersReadPermission() {
    return Boolean(this.currentOrganizationInfo?.permissions?.org?.clusters?.read);
  }

  get hasClustersCreatePermission() {
    return Boolean(this.currentOrganizationInfo?.permissions?.org?.clusters?.create);
  }

  get hasWorkflowsCreatePermission() {
    return Boolean(this.currentOrganizationInfo?.permissions?.org?.workflows?.create);
  }

  get hasInstancesCreatePermission() {
    return Boolean(this.currentOrganizationInfo?.permissions?.org?.instances?.create);
  }

  hasApplicationReadPermission = (application) =>
    Boolean(this.currentOrganizationInfo?.permissions?.cluster?.[application]?.read);

  get hasMemberCreatePermission() {
    return Boolean(this.currentOrganizationInfo?.permissions?.org?.users?.member?.create);
  }

  get hasGeneralUserCreatePermission() {
    return Boolean(this.currentOrganizationInfo?.permissions?.org?.users?.general?.create);
  }

  get hasBillingPermission() {
    const billing = this.currentOrganizationInfo?.permissions?.org?.billing;
    return Boolean(billing && !billing.create && billing.read && billing.update && !billing.delete);
  }

  get consoleDashboardPageUrl() {
    return `https://console.${config.camundaCloudBaseDomain}/org/${this.currentOrganization.id}`;
  }

  get organizationManagementPageUrl() {
    return `${this.consoleDashboardPageUrl}/management`;
  }

  /**
   * Determines if user is on a trial license
   */
  get isUsingTrial() {
    return this.currentOrganizationInfo?.salesPlan.type === 'trial';
  }

  /**
   * Returns the number of days remaining in the users trial, formatted as string, e.g. "4 days"
   */
  get remainingTrialDays() {
    const trialExpirationDate = new Date(this.currentOrganizationInfo?.salesPlan.trialExpirationDate);
    const now = Date.now();
    const countdownMs = trialExpirationDate.getTime() - now;
    const countdownDays = Math.max(countdownMs / 1000 / 60 / 60 / 24, 0);
    const remainingDays = countdownDays.toFixed(0);

    return `${remainingDays} ${pluralize('day', remainingDays)}`;
  }

  /**
   * Returns the cluster permissions for a given app
   * @param {String} app
   * @returns {Object}
   */
  getAppClusterPermissions(app) {
    return this.currentOrganizationInfo?.permissions?.cluster[app];
  }

  get isInternal() {
    return this.currentOrganizationInfo?.salesPlan?.type === SALES_PLAN_TYPE.INTERNAL;
  }

  get isEnterprise() {
    return this.currentOrganizationInfo?.salesPlan?.type === SALES_PLAN_TYPE.ENTERPRISE;
  }

  get isProfessional() {
    return this.currentOrganizationInfo?.salesPlan?.type === SALES_PLAN_TYPE.PAID_CC;
  }

  get isEnterpriseDevOrganization() {
    return this.isEnterprise && this.currentOrganizationInfo?.salesPlan?.isEnterpriseDev;
  }

  /**
   * @returns {boolean}
   */
  get hasLegacyPlayAccess() {
    return (
      this.currentOrganizationInfo?.hasAcceptedBetaTerms &&
      this.currentOrganizationInfo?.earlyAccessFeatures?.includes(EARLY_ACCESS_FEATURES.PLAY)
    );
  }

  get hasPlayC8Access() {
    return this.hasClustersReadPermission && this.hasWorkflowsCreatePermission && this.hasInstancesCreatePermission;
  }
}

export default BaseOrganizationStore;
