/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { ResizablePanel } from 'primitives';
import { CodeEditor } from 'components';
import { currentDiagramStore } from 'stores';
import debounce from 'utils/debounce';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';

import * as Styled from './XMLEditor.styled';
import XMLEditorErrorsPanel from './XMLEditorErrorsPanel';

const XMLEditor = ({ open, readonly = false }) => {
  const { isEditorOpen } = XMLEditorStore;
  const [content, setContent] = useState(null);
  const { modeler, isErrorPanelCollapsed, validationProblems } = currentDiagramStore;
  const { diagram, isLoadingModeler } = currentDiagramStore.state;
  const { content: diagramContent } = diagram;
  const SAVE_CONTENT_DEBOUNCE_DELAY = 500;

  /**
   * Open or close the editor based on the `open` prop.
   * In this case we don't trace the open event because it's not a user action.
   */
  useEffect(() => {
    if (open) {
      XMLEditorStore.openEditor(false);
    }
  }, [open]);

  /**
   * Load the XML content when the editor is opened and the modeler is ready
   */
  useEffect(() => {
    if (!modeler) {
      return;
    }

    (async () => {
      try {
        // Try to load the XML content from the modeler, this might not work if the diagram content is broken.
        const { xml } = await modeler.saveXML({ format: true });
        setContent(xml);
      } catch (err) {
        console.error('Failed to load the XML content from the modeler', err);
        if (diagramContent) {
          setContent(diagramContent);
        } else {
          console.warn('There is no modeler or diagram content to load into the XML editor');
          setContent('');
        }
      }
    })();
  }, [isLoadingModeler, isEditorOpen, modeler, diagramContent]);

  const handleOnChange = debounce(async (content) => {
    if (modeler) {
      await XMLEditorStore.validateAndSaveContent(content);
    }
  }, SAVE_CONTENT_DEBOUNCE_DELAY);

  const shouldShowErrorsPanel = Boolean(validationProblems.errors?.length || validationProblems.warnings?.length);

  return (
    isEditorOpen && (
      <Styled.Container>
        {content && (
          <CodeEditor
            value={content}
            hideControls
            wordWrap="on"
            minimap
            readOnly={readonly}
            language="xml"
            onChange={handleOnChange}
          />
        )}

        {shouldShowErrorsPanel && (
          <ResizablePanel
            panelKey="error-panel"
            handleAriaLabel="Resize error panel"
            open={!isErrorPanelCollapsed}
            background="white"
            position="bottom"
            minSize={235}
            maxSize={560}
            sizeClosed={35}
            onOpenChange={(state) => currentDiagramStore.setIsErrorPanelCollapsed(state)}
          >
            <XMLEditorErrorsPanel />
          </ResizablePanel>
        )}
      </Styled.Container>
    )
  );
};

export default observer(XMLEditor);
