/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import { idpApplicationStore, notificationStore } from 'stores';
import buildSlug from 'utils/buildSlug';
import { tracingService, idpProjectService } from 'services';
import history from 'utils/history';

class IdpProjectStore {
  isCreationModalVisible = false;
  idpProject = {};

  constructor() {
    makeAutoObservable(this);
  }

  setIsCreationModalVisible(isCreationModalVisible) {
    this.isCreationModalVisible = isCreationModalVisible;
  }

  setIdpProject(idpProject) {
    this.idpProject = idpProject;
  }

  startCreation() {
    this.setIsCreationModalVisible(true);
  }

  async finalizeCreation(name, type, extractionMethod) {
    try {
      const idpApplicationId = idpApplicationStore.idpApplication.id;

      this.setIdpProject(
        await idpProjectService.createIdpProject({
          idpApplicationId,
          name,
          type,
          extractionMethod
        })
      );

      history.push(
        `/idp-applications/${idpApplicationId}/idp-projects/${buildSlug(this.idpProject.id, this.idpProject.name)}`
      );
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't create your IDP project. Please try again later.");
      tracingService.traceError(err, 'Failed to create IDP project');
    }
  }
}

export default new IdpProjectStore();
