/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { processApplicationStore } from 'stores';

import Notification from './Notification';

export default function DeploymentSuccessNotification({ link }) {
  const title = processApplicationStore.fromAProcessApplication ? 'Process application deployed!' : 'Diagram deployed!';
  const linkText = processApplicationStore.fromAProcessApplication
    ? 'View main process in Operate'
    : 'View process in Operate';
  return <Notification message={title} link={link} linkText={linkText} />;
}
