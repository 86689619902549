/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { C3ClusterTag } from '@camunda/camunda-composite-components';

import { elevatedPermissionsRequiredText } from './elevatedPermissionsRequiredText';

export const reviewMissingProps = {
  title: 'Missing approval',
  subtitle: (
    <>
      This version needs to be approved by a reviewer before being deployed to a <C3ClusterTag stage={'prod'} /> stage.
    </>
  )
};

export const noDraftToProdProps = ({ closeModal, history, processApplicationId }) => ({
  title: 'Draft cannot be deployed',
  actionButtonLabel: 'Show versions',
  onActionButtonClick: () => {
    closeModal();
    history.push(`/process-applications/${processApplicationId}/versions`);
  },
  subtitle: (
    <>
      Draft versions cannot be deployed to a <C3ClusterTag stage={'prod'} /> stage. Create a new version and get it
      reviewed to deploy it to production.
    </>
  )
});

export const elevatedPermissionsMissingProps = {
  title: 'Missing permissions',
  subtitle: elevatedPermissionsRequiredText
};
