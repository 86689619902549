/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, makeObservable, observable } from 'mobx';

import { currentDiagramStore, notificationStore } from 'stores';
import { projectService, tracingService } from 'services';
import { getCalledElementProcessId } from 'utils/web-modeler-diagram-parser';
import { isExpression } from 'App/Pages/Diagram/properties-panel-util';

class CallActivityLinkStore {
  selectedCallActivity = null;
  linkedCallActivities = [];
  selectedElement = null;

  constructor() {
    makeObservable(this, {
      selectedCallActivity: observable,
      linkedCallActivities: observable,
      selectedElement: observable,
      setSelectedCallActivity: action,
      setLinkedCallActivities: action,
      setSelectedElement: action
    });
  }

  setSelectedCallActivity = (element) => {
    this.selectedCallActivity = element;
  };

  setLinkedCallActivities = (callActivities) => {
    this.linkedCallActivities = callActivities;
  };

  setSelectedElement = (element) => {
    this.selectedElement = element;
  };

  isElementLinked = (element) => {
    return Boolean(this.linkedCallActivities?.find((el) => el.id == element?.id));
  };

  async getExistingLinks(element) {
    if (this.isElementLinked(element)) {
      const extensionElements = element.businessObject?.get('extensionElements');
      const processId = getCalledElementProcessId(extensionElements);

      try {
        const existingLinks = {};
        if (isExpression(processId)) {
          existingLinks.expression = processId;
        } else {
          const files = await projectService.fetchFilesByProcessId(currentDiagramStore.state.project.id, processId);
          if (files.length) {
            existingLinks.links = files;
          } else {
            existingLinks.broken = processId;
          }
        }

        return existingLinks;
      } catch (ex) {
        notificationStore.showError('Yikes! Could not fetch linked diagrams. Please try again later.');
        tracingService.traceError(ex, 'Error while fetching linked diagrams');
      }
    }

    return [];
  }
}

export default new CallActivityLinkStore();
