/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Helmet } from 'react-helmet-async';

import { TopBar, Breadcrumb } from 'components';
import { milestoneStore } from 'stores';
import { getPageTitle, getParentLink } from 'utils/helpers';

export default function Header() {
  const { diagram: readme, project } = milestoneStore.state;

  return (
    <>
      <Helmet>
        <title>{getPageTitle(`${readme.name} - Versions`)}</title>
      </Helmet>
      <TopBar.Breadcrumbs>
        <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
        <Breadcrumb data-test="breadcrumb-project" title={project.name} variant="link" to={`/projects/${project.id}`} />
        {Boolean(readme.folder) && (
          <>
            {readme.folder.parentId && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
            <Breadcrumb
              title={readme.folder.name}
              variant="link"
              to={getParentLink(readme)}
              data-test="parent-folder"
            />
          </>
        )}
        <Breadcrumb data-test="breadcrumb-readme" title={readme.name} variant="link" to={`/readmes/${readme.id}`} />
        <Breadcrumb data-test="breadcrumb-version" title="Versions" variant="text" isBold />
      </TopBar.Breadcrumbs>
    </>
  );
}
