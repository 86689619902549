/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { buildAssistantModule } from '@camunda/form-js-assistant-module';

import FormAssistantAPI from './AIFormGenerator/FormAssistantAPI';
import FormAssistantHooks from './AIFormGenerator/FormAssistantHooks';
import FormAssistantExtensionModule from './AIFormGenerator/FormAssistantExtensionModule';

const EDITOR_ADDITIONAL_MODULES = [
  buildAssistantModule(FormAssistantAPI, FormAssistantHooks),
  FormAssistantExtensionModule
];

export default EDITOR_ADDITIONAL_MODULES;
