/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { InlineNotification, Modal, Stack } from '@carbon/react';
import { useEffect, useState } from 'react';

import { getGitProvider } from 'features/git-sync/utils';
import { gitSettingsStore } from 'features/git-sync';
import { projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';

import { validatePattern, validateRequired } from './fields/validations';

const GitSettingsDialog = () => {
  const { project } = projectStore;
  const canSetupProcessApplicationSettings = hasAccess(project, actions.MODIFY_PROJECT);
  const {
    shouldShowDialog,
    status,
    settings,
    shouldInitiateSyncAfterSetup,
    hasSaved,
    hasErrors: hasErrorsWhileSaving
  } = gitSettingsStore;
  const { fields: Fields, validationRules, label: gitLabel } = getGitProvider();

  const [errors, setErrors] = useState({});
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    checkCanSave();
  }, [errors, canSetupProcessApplicationSettings]);

  if (!Fields) {
    throw new Error('No fields found for the given git provider');
  }

  const onChangeSetting = (key, value) => {
    value = sanitizeValue(value);

    const rule = validationRules[key];

    gitSettingsStore.storeSetting(key, value);

    if (rule) {
      if (!isValidRequired(rule, value)) {
        setErrors({ ...errors, [key]: 'This field is required' });
        return;
      }

      if ((rule.customValidation && !rule.customValidation(value)) || !validatePattern(rule.pattern, value)) {
        setErrors({ ...errors, [key]: 'Invalid value' });
        return;
      }

      const newErrors = { ...errors };
      delete newErrors[key];
      setErrors(newErrors);
    }
  };

  const onInputBlur = (key, value) => {
    const rule = validationRules[key];

    if (typeof rule?.onBlur === 'function') {
      value = rule.onBlur(value);
      if (value !== settings[key]) {
        gitSettingsStore.storeSetting(key, value);
      }
    }
  };

  const checkCanSave = () => {
    if (!canSetupProcessApplicationSettings) {
      setCanSave(false);
      return;
    }

    let hasInvalidField = false;

    Object.entries(validationRules).forEach(([key, rule]) => {
      const value = settings[key];

      if (
        !isValidRequired(rule, value) ||
        (rule.customValidation && !rule.customValidation(value)) ||
        !validatePattern(rule.pattern, value)
      ) {
        hasInvalidField = true;
      }
    });

    setCanSave(!hasInvalidField);
  };

  const isValidRequired = (rule, value) => {
    return rule.required ? validateRequired(value) : true;
  };

  const sanitizeValue = (value) => {
    return typeof value === 'string' ? value.trim() : value;
  };

  const hasValidationErrors = Object.values(errors).some((error) => error);

  const getLoadingDescription = () => {
    if (hasSaved) {
      return 'Saved';
    }

    if (hasErrorsWhileSaving) {
      return 'Failed';
    }

    return 'Saving...';
  };

  return (
    <Modal
      open={shouldShowDialog}
      size="sm"
      modalHeading={`Configure ${gitLabel}`}
      primaryButtonText="Save configuration"
      primaryButtonDisabled={!canSave || hasValidationErrors}
      secondaryButtonText="Cancel"
      onRequestSubmit={() => gitSettingsStore.save()}
      onSecondarySubmit={() => gitSettingsStore.close()}
      onRequestClose={() => gitSettingsStore.close()}
      loadingStatus={status}
      loadingDescription={getLoadingDescription()}
    >
      <Stack gap={7}>
        <Fields errors={errors} onChangeSetting={onChangeSetting} onInputBlur={onInputBlur} />

        {shouldInitiateSyncAfterSetup && (
          <InlineNotification
            kind="info"
            title="Initial sync required"
            lowContrast
            hideCloseButton
            subtitle={`Configuring ${gitLabel} requires an initial sync. The sync will start once the configuration is saved.`}
          />
        )}
      </Stack>
    </Modal>
  );
};

export default observer(GitSettingsDialog);
