/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Callout, InlineLoading } from '@carbon/react';

import { idpProjectStore, projectStore } from 'stores';
import ExtractionFields from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/unstructured-extraction/idp-extraction-fields/extraction-fields/ExtractionFields';
import {
  EXTRACTION_MODELS,
  EXTRACTION_NOTIFICATION_STEPS
} from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';
import {
  PdfViewer,
  PdfViewerHeader
} from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/unstructured-extraction/idp-extraction-fields/pdf-viewer';
import hasAccess, { actions } from 'utils/user-access';

import * as Styled from './IdpExtractionFields.styled';

const IdpExtractionFields = ({ clusterStatus, areIdpConnectorSecretsMissing }) => {
  const { project } = projectStore;
  const { activeExtractionIdpDocument, idpExtractionFields, extractionStepNotification, idpDocuments, idpProject } =
    idpProjectStore;
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [idpDocumentBlob, setIdpDocumentBlob] = useState(null);
  const [isLoadingIdpDocument, setIsLoadingIdpDocument] = useState(false);
  const [idpDocumentScale, setIdpDocumentScale] = useState(1);
  const [extractionModel, setExtractionModel] = useState(null);
  const canUserModifyProject = hasAccess(project, actions.MODIFY_PROJECT) || hasAccess(project, actions.MODIFY_DIAGRAM);

  useEffect(() => {
    if (idpExtractionFields.length === 0) {
      idpProjectStore.setExtractionStepNotification(EXTRACTION_NOTIFICATION_STEPS.ADD_AN_EXTRACTION_FIELD);
    }
    return () => {
      idpProjectStore.setExtractionStepNotification(null);
      idpProjectStore.setActiveExtractionIdpDocument(null);
    };
  }, []);

  useEffect(() => {
    if (idpDocuments.length > 0) {
      const idpDocument = activeExtractionIdpDocument ?? idpDocuments[0];
      idpProjectStore.setActiveExtractionIdpDocument(idpDocument);
      idpProjectStore.fetchIdpExtractionFields(idpDocument.id);
      handleIdpDocumentChange(idpDocument);
    }
  }, [idpDocuments.length]);

  const handleIdpDocumentChange = async (selectedIdpDocument) => {
    setIsLoadingIdpDocument(true);
    const blob = await idpProjectStore.getIdpDocument(selectedIdpDocument.id);
    idpProjectStore.setActiveExtractionIdpDocument(selectedIdpDocument);
    setCurrentPage(1);
    setIdpDocumentScale(1);
    setIdpDocumentBlob(blob);
    setIsLoadingIdpDocument(false);
    if (extractionModel) {
      await idpProjectStore.fetchIdpExtractionFieldTestcaseResults(idpProject.id, extractionModel);
      idpProjectStore.syncTestcaseResults(idpProjectStore.getModelTestCaseResults(extractionModel), true);
    } else {
      await Promise.all(
        EXTRACTION_MODELS.map(async (model) => {
          if (!idpProjectStore.doesTestCaseResultExistForModelId(model.id)) {
            await idpProjectStore.fetchIdpExtractionFieldTestcaseResults(idpProject?.id, model.id);
          }
        })
      );
      idpProjectStore.syncTestcaseResults(idpProjectStore.getModelTestCaseResults(EXTRACTION_MODELS[0].id), false);
    }
  };

  const handleUpdateExtractionModel = async (extractionModel) => {
    setExtractionModel(extractionModel);
    await idpProjectStore.fetchIdpExtractionFieldTestcaseResults(idpProject.id, extractionModel);
    idpProjectStore.syncTestcaseResults(idpProjectStore.getModelTestCaseResults(extractionModel), true);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => setNumberOfPages(numPages);

  const handleUpdateExtractionField = async (extractionField, triggerSuccessNotification) => {
    await idpProjectStore.updateIdpExtractionField(
      extractionField.id,
      extractionField.name,
      extractionField.type,
      extractionField.llmPromptDescription,
      triggerSuccessNotification
    );
  };

  const handleUpdateExtractionFieldValues = async (llmModelId, extractionFields) => {
    await idpProjectStore.updateIdpExtractionFieldValues(
      idpProject.id,
      activeExtractionIdpDocument.id,
      extractionFields,
      llmModelId
    );
  };

  return (
    <>
      {canUserModifyProject && extractionStepNotification && (
        <Styled.CalloutNotificationContainer>
          <Callout
            title={extractionStepNotification.title}
            subtitle={extractionStepNotification.content}
            titleId="extraction-step-notification"
            kind="info"
            lowContrast
            actionButtonLabel="Got it"
            onActionButtonClick={() => idpProjectStore.setExtractionStepNotification(null)}
          />
        </Styled.CalloutNotificationContainer>
      )}
      <Styled.Container>
        <Styled.Section $orientation="left" $isExtractionStepNotificationExists={!!extractionStepNotification}>
          <ExtractionFields
            canUserModifyExtractionFields={canUserModifyProject}
            extractionModel={extractionModel}
            clusterStatus={clusterStatus}
            areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
            onUpdateExtractionModel={handleUpdateExtractionModel}
            onUpdateExtractionField={handleUpdateExtractionField}
            onUpdateExtractionFieldValues={handleUpdateExtractionFieldValues}
          />
        </Styled.Section>
        <Styled.Section $orientation="right" $isExtractionStepNotificationExists={!!extractionStepNotification}>
          {activeExtractionIdpDocument && (
            <PdfViewerHeader
              documents={idpDocuments}
              selectedDocument={activeExtractionIdpDocument}
              selectedDocumentPageCount={numberOfPages}
              onDocumentChange={handleIdpDocumentChange}
              onDocumentPageChange={setCurrentPage}
              onDocumentScaleChange={setIdpDocumentScale}
            />
          )}
          {isLoadingIdpDocument ? (
            <Styled.LoaderContainer>
              <InlineLoading status="active" iconDescription="Loading" description="Loading document..." />
            </Styled.LoaderContainer>
          ) : (
            <PdfViewer
              pageNumber={currentPage}
              scale={idpDocumentScale}
              file={idpDocumentBlob}
              onDocumentLoadSuccess={handleDocumentLoadSuccess}
              onPageChange={setCurrentPage}
            />
          )}
        </Styled.Section>
      </Styled.Container>
    </>
  );
};

export default observer(IdpExtractionFields);
