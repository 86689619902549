/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { notificationStore } from 'components/NotificationSystem';
import { DOWNLOAD_LIMIT_EXCEEDED } from 'stores/project-store/ProjectStore';

import { downloadService } from './DownloadService';

class DownloadUtility {
  /**
   * Downloads a given process application version.
   * @param {string} versionId - The ID of the process application version.
   * @param {string} versionName - The name of the process application version.
   * @param {string} processApplicationName - The name of the process application.
   * @returns {Promise<void>}
   */
  async downloadProcessApplicationVersion(versionId, versionName, processApplicationName) {
    let infoNotification;

    const disposeInfoNotification = () => {
      if (infoNotification) {
        notificationStore.disposeNotification(infoNotification.id, 'info');
      }
    };
    infoNotification = notificationStore.info(
      {
        title: 'Preparing the download',
        content: 'We are zipping your files. The download starts soon.'
      },
      { duration: 8000 }
    );
    // In order for the user to see the notification, waiting a bit before letting the download start
    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      const fileName = `${processApplicationName}-${versionName}`;
      await downloadService.downloadProcessApplicationVersion(versionId, fileName);
    } catch (error) {
      let errorMessage = `Yikes! Could not download the selected process application version. Please try again later.`;

      const isDownloadLimitExceeded = error?.[0]?.reason === DOWNLOAD_LIMIT_EXCEEDED;

      if (isDownloadLimitExceeded && error[0].detail) {
        errorMessage = error[0].detail;
      }

      notificationStore.showError(errorMessage);
    } finally {
      disposeInfoNotification();
    }
  }
}

export const downloadUtility = new DownloadUtility();
