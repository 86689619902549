/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { formatDateToString } from 'utils/date-format';

import { DRAFT_VERSION } from './config';
import { Version } from './version';
import { DateCreated } from './date-created';

/**
 * @typedef {import('components/VersionsList/types').VersionListEntryDto} VersionListEntryDto
 */

/**
 * Component to display a version list
 *
 * @param {Object} props - The component props.
 * @param {VersionListEntryDto[]} props.versions
 * @param {Function} props.reloadVersions
 * @param {String} props.selectedVersionId
 * @param {Function} props.setSelectedVersionId
 * @param {Boolean} props.isDiffingEnabled
 * @param {String} props.currentUserId
 * @param {Function} props.onRenameVersion
 * @param {JSX.Element} [props.DraftVersion=null]
 * @param {JSX.Element} [props.EmptyState=null]
 * @param {Function} props.renderVersionMenu
 * @param {Function} [props.renderReview=null]
 * @param {Function} [props.renderPublishedTag=null]
 * @returns {JSX.Element}
 */
export default function VersionsList({
  versions,
  reloadVersions,
  selectedVersionId,
  setSelectedVersionId,
  isDiffingEnabled,
  currentUserId,
  onRenameVersion,
  DraftVersion = null,
  EmptyState = null,
  renderVersionMenu,
  renderReview = null,
  renderPublishedTag = null
}) {
  const isCurrentUser = (version) => {
    const { createdBy } = version;
    const authorId = createdBy.id;
    return currentUserId === authorId;
  };

  const getIndex = (versionId) => {
    return versions.findIndex(({ id }) => id === versionId);
  };

  const checkIfPrimarySelection = (version) => {
    return selectedVersionId === version.id;
  };

  const checkIfSecondarySelection = (version) => {
    return selectedVersionId === DRAFT_VERSION.id
      ? getIndex(version.id) === 0
      : getIndex(selectedVersionId) + 1 === getIndex(version.id);
  };

  const versionsGroupedByDate = {};
  versions.forEach((version) => {
    const title = formatDateToString(new Date(version.created));
    if (!versionsGroupedByDate.hasOwnProperty(title)) {
      versionsGroupedByDate[title] = [];
    }
    versionsGroupedByDate[title].push(version);
  });

  return (
    <Container>
      {DraftVersion}
      {EmptyState}
      {Object.entries(versionsGroupedByDate).map(([dateCreated, items]) => (
        <VersionsByDate key={dateCreated}>
          <DateCreated dateString={dateCreated} />
          {items.map((version) => (
            <Version
              key={version.id}
              version={version}
              reloadVersions={reloadVersions}
              isPrimarySelection={checkIfPrimarySelection(version)}
              isSecondarySelection={checkIfSecondarySelection(version)}
              setSelectedVersionId={setSelectedVersionId}
              isDiffingEnabled={isDiffingEnabled}
              isCurrentUser={isCurrentUser(version)}
              renderVersionMenu={renderVersionMenu}
              renderReview={renderReview}
              onRenameVersion={onRenameVersion}
              renderPublishedTag={renderPublishedTag}
            />
          ))}
        </VersionsByDate>
      ))}
    </Container>
  );
}

const Container = styled.div`
  padding: 0;
  margin: 0;

  list-style: none;

  overflow-y: auto;
  flex-grow: 1;
`;

const VersionsByDate = styled.div``;
