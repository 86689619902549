/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const FormInput = styled.input`
  font: inherit;
  color: var(--grey-darken-33);
  width: 100%;
  border: 1px solid ${({ $error }) => ($error ? 'var(--red-base-62)' : 'var(--silver-darken-80)')};
  margin: 0;
  height: 36px;
  padding: 8px ${({ $addon }) => ($addon ? '35px' : '15px')} 8px 15px;
  background-color: white;
  transition: border 0.15s linear;
  border-radius: 3px;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  box-sizing: border-box;

  &:disabled {
    background-color: var(--silver-base-97);
    cursor: not-allowed;
  }

  &:focus {
    border-color: var(--cds-blue-60);
  }

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
`;

export const FormTextarea = styled.textarea`
  font: inherit;
  color: var(--grey-darken-33);
  width: 100%;
  border: 1px solid ${({ $error }) => ($error ? 'var(--red-base-62)' : 'var(--silver-darken-80)')};
  margin: 0;
  padding: 8px 15px;
  min-height: 36px;
  ${({ $grow }) => ($grow ? 'max-height: 100px' : '')};
  background-color: white;
  line-height: 1.3;
  transition: border 0.15s linear;
  border-radius: 3px;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  box-sizing: border-box;

  &:disabled {
    background-color: var(--silver-base-97);
    cursor: not-allowed;
  }

  &:focus {
    border-color: var(--cds-blue-60);
  }
`;

export const FormLabel = styled.label`
  display: block;
  margin-bottom: var(--spacing-tiny);
`;

export const FormError = styled.p`
  margin: 3px 0 0 0;
  font-size: 12px;
  color: var(--red-darken-52);
`;

export const FormAddon = styled.span`
  position: absolute;
  right: 0;
  top: 27px;

  button {
    border-radius: 0 3px 3px 0;
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  position: relative;

  ${({ $hasMarginBottom }) => $hasMarginBottom && 'margin-bottom: 15px'};
`;
