/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import { processApplicationStore, currentDiagramStore, organizationStore, projectStore } from 'stores';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import createPermission from 'utils/createPermission';
import { BPMN } from 'utils/constants';

class DeploymentPermissionsStore {
  isAdminOrEditor = false;

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    const project = projectStore.project?.id ? projectStore.project : currentDiagramStore.state.project;
    const permission = createPermission(project?.permissionAccess);
    this.isAdminOrEditor = permission.is(['ADMIN', 'WRITE']);
  }

  reset() {
    this.isAdminOrEditor = false;
  }

  get isAllowedToDeploy() {
    return (
      this.isAdminOrEditor &&
      organizationStore.hasClustersReadPermission &&
      organizationStore.hasWorkflowsCreatePermission
    );
  }

  get isAllowedToDeployToSelectedCluster() {
    // Everyone can deploy to non-prod clusters, but only owners and admins can deploy to prod clusters
    return (
      !deploymentStore.isSelectedClusterProd ||
      (deploymentStore.isSelectedClusterProd && organizationStore.hasElevatedOrganizationPermissions)
    );
  }

  get weAreInBPMNDiagramOrInProcessApplication() {
    return currentDiagramStore.state.diagram?.type === BPMN || processApplicationStore.fromAProcessApplication;
  }

  get isAllowedToStartInstance() {
    return (
      this.isAllowedToDeploy &&
      organizationStore.hasInstancesCreatePermission &&
      this.weAreInBPMNDiagramOrInProcessApplication
    );
  }

  get isAllowedToOpenOperate() {
    return this.isAdminOrEditor && organizationStore.hasApplicationReadPermission('operate');
  }

  get isAllowedToOpenTasklist() {
    return (
      this.isAdminOrEditor &&
      organizationStore.hasApplicationReadPermission('tasklist') &&
      this.weAreInBPMNDiagramOrInProcessApplication
    );
  }

  get isAllowedToOpenOptimize() {
    return this.isAdminOrEditor && organizationStore.hasApplicationReadPermission('optimize');
  }

  get isExternalApplicationLinksMenuItemPresent() {
    return this.isAllowedToOpenOperate || this.isAllowedToOpenTasklist || this.isAllowedToOpenOptimize;
  }

  get isDeployOrStartInstanceButtonPresent() {
    return this.isAdminOrEditor;
  }
}

export default new DeploymentPermissionsStore();
