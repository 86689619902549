/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

class MainProcessRedirectModalStore {
  isVisible = false;
  actionType = null;

  constructor() {
    makeAutoObservable(this);
  }

  open = (actionType) => {
    this.#setActionType(actionType);
    if (this.actionType) {
      this.#setIsVisible(true);
    }
  };

  close = () => {
    this.#setActionType(null);
    this.#setIsVisible(false);
  };

  #setIsVisible(isVisible) {
    this.isVisible = isVisible;
  }

  #setActionType(actionType) {
    if (actionType === null || ['deploy', 'execute'].includes(actionType)) {
      this.actionType = actionType;
    } else {
      throw new Error(`Invalid actionType: ${actionType}. Must be 'deploy' or 'execute'.`);
    }
  }
}

export default new MainProcessRedirectModalStore();
