/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef } from 'react';

import { CodeEditor } from 'components';

import { MarkdownRenderer } from './MarkdownRenderer';
import * as Styled from './MarkdownEditor.styled';

export const MARKDOWN_VIEW_MODES = {
  EDITOR: 'editor',
  PREVIEW: 'preview',
  SPLIT: 'split'
};

export function MarkdownEditor({
  content,
  readOnly,
  onChange = () => {},
  modifiedContent = null,
  titles = undefined,
  viewMode = MARKDOWN_VIEW_MODES.EDITOR
}) {
  const editorRef = useRef(null);
  const previewRef = useRef(null);

  return (
    <Styled.Container>
      {/* @ts-expect-error TS2339 */}
      <Styled.EditorContainer ref={editorRef} $viewMode={viewMode} $hidden={viewMode === MARKDOWN_VIEW_MODES.PREVIEW}>
        {/* @ts-expect-error TS2339 */}
        <CodeEditor
          titles={titles}
          value={content}
          modified={modifiedContent}
          onChange={onChange}
          language="markdown"
          readOnly={readOnly}
          wordWrap="on"
          disableSuggestions
        />
      </Styled.EditorContainer>

      {/* @ts-expect-error TS2339 */}
      <Styled.PreviewContainer ref={previewRef} $viewMode={viewMode} $hidden={viewMode === MARKDOWN_VIEW_MODES.EDITOR}>
        <MarkdownRenderer content={content} />
      </Styled.PreviewContainer>
    </Styled.Container>
  );
}
