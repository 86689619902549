/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, makeObservable, observable } from 'mobx';

import { currentDiagramStore, notificationStore } from 'stores';
import { projectService, tracingService } from 'services';
import { getCalledDecisionId } from 'App/Pages/Diagram/model-parser-util';
import { isExpression } from 'App/Pages/Diagram/properties-panel-util';
import selectAndScrollToElement from 'utils/modeler-utils/select-and-scroll-to-element';

class BusinessRuleTaskLinkStore {
  selectedBusinessRuleTask = null;
  linkedBusinessRuleTasks = [];
  /**
   * This field is set before navigating to a DMN diagram from a linked business rule task
   * and is used to programmatically open the drilldown view, if present
   */
  decisionIdToOpen = null;
  currentView = null;
  /**
   * ID of the decision whose drilldown view is currently opened
   */
  openedDecisionId = null;

  constructor() {
    makeObservable(this, {
      selectedBusinessRuleTask: observable,
      linkedBusinessRuleTasks: observable,
      decisionIdToOpen: observable,
      currentView: observable,
      openedDecisionId: observable,
      setSelectedBusinessRuleTask: action,
      setLinkedBusinessRuleTasks: action,
      setDecisionIdToOpen: action,
      setCurrentView: action,
      setOpenedDecisionId: action
    });
  }

  setSelectedBusinessRuleTask = (element) => {
    this.selectedBusinessRuleTask = element;
  };

  setLinkedBusinessRuleTasks = (businessRuleTasks) => {
    this.linkedBusinessRuleTasks = businessRuleTasks;
  };

  setDecisionIdToOpen = (decisionId) => {
    this.decisionIdToOpen = decisionId;
  };

  setCurrentView = (view) => {
    this.currentView = view;
  };

  setOpenedDecisionId = (decisionId) => {
    this.openedDecisionId = decisionId;
  };

  isElementLinked = (element) => {
    return Boolean(this.linkedBusinessRuleTasks?.find((el) => el.id == element?.id));
  };

  async getExistingLinks(element) {
    if (this.isElementLinked(element)) {
      const extensionElements = element.businessObject?.get('extensionElements');
      const decisionId = getCalledDecisionId(extensionElements);

      try {
        const existingLinks = {};
        if (isExpression(decisionId)) {
          existingLinks.expression = decisionId;
        } else {
          const files = await projectService.fetchFilesByDecisionId(currentDiagramStore.state.project.id, decisionId);
          if (files.length) {
            existingLinks.links = files;
          } else {
            existingLinks.broken = decisionId;
          }
        }

        return existingLinks;
      } catch (ex) {
        notificationStore.showError('Yikes! Could not fetch linked diagrams. Please try again later.');
        tracingService.traceError(ex, 'Error while fetching linked diagrams');
      }
    }

    return [];
  }

  handleNavigationFromBusinessRuleTaskLink = (modeler) => {
    modeler.on('views.changed', ({ activeView }) => {
      if (!activeView) {
        return;
      }

      const { id, type } = activeView;
      if (type === 'decisionTable' || type === 'literalExpression') {
        this.setOpenedDecisionId(id);
        this.setDecisionIdToOpen(null);
      } else {
        this.setOpenedDecisionId(null);
      }
      this.setCurrentView(type);
    });

    // decisionIdToOpen will be set when opening diagram page from a linked business rule task
    if (this.decisionIdToOpen) {
      const views = modeler?.getViews();
      const drilldownView = views?.find((view) => view.id === this.decisionIdToOpen);

      // Open drilldown view if present for the decision, otherwise select the decision
      if (drilldownView) {
        modeler.open(drilldownView);
      } else {
        selectAndScrollToElement(modeler, this.decisionIdToOpen);
      }
    }
  };
}

export default new BusinessRuleTaskLinkStore();
