/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { WithExternalLinkIcon as BaseWithExternalLinkIcon } from 'primitives';

export const Container = styled.main`
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow-x: hidden;
`;

export const DescriptionContainer = styled.div`
  z-index: 1;
  margin-bottom: 24px;
`;

export const Link = styled.a``;

export const WithExternalLinkIcon = styled(BaseWithExternalLinkIcon)`
  display: none;

  ${Link}:hover > &, ${Link}:focus-within > & {
    display: inline;
  }
`;

export const DescriptionTextWrapper = styled.span`
  line-height: 1.1;
`;
