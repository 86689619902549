/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import isUserAlreadyCollaborator from 'stores/invitation-modal-store/is-user-already-collaborator';
import { projectStore } from 'stores';

export const filterOrganizationMembers = (organizationMembers) => {
  return organizationMembers
    .filter((member) => !isUserAlreadyCollaborator(projectStore.collaborators, member))
    .map((member) => member.email);
};
