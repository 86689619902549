/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Launch } from '@carbon/icons-react';
import { Checkbox, CheckboxGroup, Accordion } from '@carbon/react';

import * as Styled from './DiscoverConnectorsModal.styled';

export const DiscoverConnectorsModalFilter = ({
  availableFilters,
  filters,
  handleFilterCheckBoxOnclick,
  resetFilters
}) => {
  const isCheckboxChecked = (filterGroup, filterOption) => {
    const filtersInFilterGroup = filters[filterGroup];
    return filtersInFilterGroup?.length > 0 ? filtersInFilterGroup.includes(filterOption) : false;
  };

  return (
    <div>
      <div>
        <Styled.DiscoverMarketplaceResetFilters
          kind="ghost"
          size="sm"
          onClick={resetFilters}
          disabled={!Object.values(filters).some((filterValues) => filterValues?.length > 0)}
        >
          Reset filters
        </Styled.DiscoverMarketplaceResetFilters>
      </div>
      <Accordion isFlush>
        {availableFilters?.map((filterGroup) => (
          <Styled.FilterAccordionItem key={filterGroup?.value} title={filterGroup?.label} open>
            <CheckboxGroup
              legendText={''}
              helperText={
                filterGroup?.link?.href ? (
                  <Styled.ExternalLink target="_blank" href={filterGroup?.link?.href}>
                    {filterGroup?.link?.text}
                    <Styled.ResourceLink>
                      <Launch />
                    </Styled.ResourceLink>
                  </Styled.ExternalLink>
                ) : (
                  <div>{filterGroup?.link?.text}</div>
                )
              }
            >
              {filterGroup.options?.map((filterOption) => (
                <Checkbox
                  key={`${filterGroup.value}-${filterOption.value}`}
                  labelText={filterOption.label}
                  id={`${filterGroup.value}-${filterOption.value}`}
                  checked={isCheckboxChecked(filterGroup.value?.trim(), filterOption.value?.trim())}
                  onClick={() => handleFilterCheckBoxOnclick(filterGroup.value?.trim(), filterOption.value?.trim())}
                />
              ))}
            </CheckboxGroup>
          </Styled.FilterAccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default observer(DiscoverConnectorsModalFilter);
