/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { TabPanel as BaseTabPanel } from '@carbon/react';

export const TabsContainer = styled.main`
  margin-top: 20px;
`;

export const TabPanel = styled(BaseTabPanel)`
  padding: 24px 0 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: absolute;
  right: 40px;
  z-index: 2;
`;

export const PublishOption = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const EmptyStateDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;
