/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import localStorage from 'utils/localstorage';

class IsModesTooltipVisibleStore {
  isVisible = true;

  #storageKey = 'modes_tooltip_shown_once';

  constructor() {
    makeAutoObservable(this);
  }

  hide() {
    this.#setIsVisible(false);
  }

  #setIsVisible = (val) => {
    this.isVisible = val;
    localStorage.setItem(this.#storageKey, val ? 'false' : 'true');
  };

  get shouldBeShown() {
    return !localStorage.getItem(this.#storageKey)
      ? this.isVisible
      : localStorage.getItem(this.#storageKey) === 'false';
  }
}

export default new IsModesTooltipVisibleStore();
