/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { createRoot } from 'react-dom/client';
import { getBBox } from 'diagram-js/lib/util/Elements';

import sanitizeTechnicalTerms from 'utils/diffing/BPMNDiff/sanitize-technical-terms';
import checkForRemovedTargetOrSource from 'utils/diffing/check-for-removed-target-or-source';
import { Changes } from 'icons';

import IconOverlay from './IconOverlay/index';

export const drawIcons = (additionsOrChanges, type, modeler) => {
  const overlays = modeler.get('overlays');
  const cleanup = [];

  additionsOrChanges.forEach(({ id: elementId, iconPosition: hasIconPosition, changes }) => {
    let iconType = type;
    let spanFragment = document.createElement('span');
    const root = createRoot(spanFragment);

    const element = modeler.get('elementRegistry').get(elementId);
    if (!element) {
      return;
    }

    const elementType = element.type;
    const isFlow = elementType === 'bpmn:SequenceFlow' || elementType === 'bpmn:MessageFlow';

    // if there is no element
    // or the type is added and the element type is a seqneceflow or message flow
    // return immediately, because we don't want to draw icons for added sequence and message flows
    if (!element || (iconType === 'added' && isFlow)) {
      return;
    }

    const useHitbox = element.type !== 'bpmn:Participant' && element.type !== 'bpmn:SubProcess';

    let { width, height } = getBBox(element);

    let iconPosition;

    if (hasIconPosition) {
      const { x, y } = getBBox(element);
      iconPosition = getIconPosition(element, hasIconPosition);

      iconPosition.y = height ? ((iconPosition.y - y) / height) * 100 : 0;
      iconPosition.x = width ? ((iconPosition.x - x) / width) * 100 : 0;
    }

    if (!width) {
      width = 20;
    }

    if (!height) {
      height = 20;
    }

    if (changes) {
      if (changes.some(checkForRemovedTargetOrSource)) {
        iconType = 'removed';
      }
    }

    const sanitizedElementType = sanitizeTechnicalTerms(elementType);

    root.render(
      <IconOverlay
        width={width}
        height={height}
        useHitbox={useHitbox}
        type={iconType}
        iconPosition={iconPosition}
        details={changes}
        elementType={sanitizedElementType}
      />
    );

    const overlay = {
      position: {
        top: 0,
        left: 0
      },
      html: spanFragment,
      zoom: {
        minZoom: 0,
        maxZoom: 4
      }
    };
    const overlayId = overlays.add(elementId, overlay);

    cleanup.push(() => {
      root.render(null);
      overlays.remove(overlayId);
      spanFragment = null;
    });
  });

  return cleanup;
};

const getIconPosition = (element, hasIconPosition) => {
  if (hasIconPosition === 'start') {
    return { ...element.waypoints[0] };
  }
  if (hasIconPosition === 'end') {
    return { ...element.waypoints[element.waypoints.length - 1] };
  }
};

export const TargetEngineVersionChange = ({ before, after }) => {
  return before && after ? (
    <div>
      <Changes style={{ verticalAlign: 'middle', color: 'var(--cds-blue-60)' }} />
      <span style={{ verticalAlign: 'middle', paddingLeft: '8px' }}>
        Selected engine version changed from <strong>{before}</strong> to <strong>{after}</strong>
      </span>
    </div>
  ) : null;
};

export const drawTargetEngineVersionChange = (change) => {
  if (document.querySelector('.engine-version-change')) {
    document.querySelector('.engine-version-change').remove();
  }

  if (change) {
    let spanFragment = document.createElement('span');
    const root = createRoot(spanFragment);
    root.render(<TargetEngineVersionChange before={change?.before} after={change?.after} />);

    spanFragment.style.position = 'absolute';
    spanFragment.style.top = '20px';
    spanFragment.style.left = '20px';
    spanFragment.classList.add('engine-version-change');
    document.querySelector('.djs-container').appendChild(spanFragment);

    return () => {
      root.render(null);
    };
  }
};
