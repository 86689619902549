/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ComposedModal, ModalBody, Tile, Button, Link, Search, AccordionItem, Column } from '@carbon/react';
import { Copy } from '@carbon/icons-react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const DiscoverConnectorsDialog = styled(ComposedModal)`
  .cds--modal-container {
    background: white;
    height: 100%;
  }

  .cds--modal-content {
    margin-bottom: 0;
  }
`;

export const ModalTitle = styled.div`
  color: var(--cm-color-black-primary-text);
  font-weight: 400;
  font-size: 20px;
`;

export const DiscoverConnectorsModalBody = styled(ModalBody)`
  padding-top: 0px !important;
  padding-bottom: 48px !important;
`;

export const LoadingRow = styled(Row)`
  height: 100%;
`;

export const ConnectorsLoadingRow = styled(LoadingRow)`
  margin-top: 20vh;
`;

export const LoadingColumn = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterAccordionItem = styled(AccordionItem)`
  .cds--accordion__title {
    padding: 0;
  }
`;

export const ConnectorSearch = styled(Search)`
  .cds--search-input {
    background-color: #f4f4f4;
  }
`;

export const NoConnectorsFoundText = styled.div`
  margin-top: 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const ResourceLink = styled.div`
  color: inherit;

  svg {
    vertical-align: middle;
  }
`;

export const ExternalLink = styled(Link)`
  & ${ResourceLink} {
    visibility: hidden;
    margin-left: 4px;
  }

  &:hover ${ResourceLink} {
    visibility: visible;
  }
`;

const ResourceIconSize = '24px';

export const ResourceLogoRow = styled(Row)`
  margin-bottom: 8px;
`;

export const ResourceLogo = styled.img`
  width: auto;
  height: ${ResourceIconSize};
`;

export const DiscoverMarketplaceResetFilters = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  line-height: 16px;
  margin-bottom: 16px;
`;

export const ConnectorTile = styled(Tile)`
  margin-top: 16px;
  height: 270px;
  display: flex;
  flex-direction: column;
`;

export const ConnectorTileContent = styled.div`
  flex: 1;
`;

export const ConnectorTileFooter = styled(Row)`
  flex-wrap: wrap;
`;

export const ConnectorNameRow = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.16px;
  margin-bottom: 8px;
`;

export const ConnectorCreatedByRow = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  margin-bottom: 8px;
`;

export const ConnectorDescriptionRow = styled.div`
  color: var(--cm-color-black-secondary-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  margin-bottom: 8px;
  max-height: 54px;
`;

export const EllipsisText = styled.div`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const DocumentationLink = styled(ExternalLink)``;

export const SelfHostedLink = styled(ExternalLink)`
  font-size: 14px;
  padding-top: 16px;
`;

export const ConnectorCTAButton = styled(Button)`
  flex: 1;
  max-width: 100%;
`;

export const MenuItemDividerRow = styled(Row)`
  width: 110%;
  margin: 0 -16px;
`;

export const CopyLogsIcon = styled(Copy)`
  fill: var(--cds-blue-60) !important;
`;

export const SupportLink = styled(Link)`
  color: var(--cds-text-primary, #161616) !important;
  text-decoration: underline !important;
`;
