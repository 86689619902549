/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { LINK_TYPES, NODE_TYPES_MAPPING, PROCESS } from 'features/landscape-view/utils/constants';

export const mapToProcessLandscapeData = (data) => {
  const nodes = [];
  const links = [];

  const processMap = new Map();

  data.processes.forEach((process) => {
    const node = {
      type: NODE_TYPES_MAPPING[PROCESS],
      id: process.id,
      data: {
        name: process.name || null,
        sourceId: process.id
      },
      externalData: {
        processFileId: process.fileId,
        readmeFileId: process.readmeFileId,
        version: process.version || 'draft'
      }
    };

    nodes.push(node);
    processMap.set(process.id, process);
  });

  data.processes.forEach((process) => {
    process.calledProcesses.forEach((calledProcess) => {
      if (processMap.has(calledProcess.id)) {
        links.push({
          type: LINK_TYPES.CALL,
          from: process.id,
          to: calledProcess.id
        });
      }
    });

    if (process.parentProcessApplicationId) {
      const parentProcess = data.processes.find((p) => p.fileId === process.parentProcessApplicationId);

      if (parentProcess) {
        links.push({
          type: LINK_TYPES.PARENT_OF,
          from: parentProcess.id,
          to: process.id
        });
      }
    }
  });

  return { elements: { nodes, links } };
};
