/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { StatsigProvider } from 'statsig-react';

import { organizationStore } from 'stores';
import config from 'utils/config';
import { TARGET_ENV } from 'utils/constants';

export default function WithStatsig({ children, condition, hashedUserId }) {
  const { currentOrganizationId, currentOrganizationInfo } = organizationStore;

  let tier = 'development';
  if (TARGET_ENV.INT === config.targetEnv) {
    tier = 'staging';
  } else if (TARGET_ENV.PROD === config.targetEnv) {
    tier = 'production';
  }

  if (condition) {
    return (
      <StatsigProvider
        sdkKey={config.statsig.sdkKey}
        waitForInitialization
        user={{
          userID: hashedUserId,
          customIDs: {
            orgId: currentOrganizationId
          },
          custom: {
            orgId: currentOrganizationId,
            salesPlan: currentOrganizationInfo?.salesPlan.type
          }
        }}
        options={{
          environment: { tier }
        }}
      >
        {children}
      </StatsigProvider>
    );
  } else {
    return children;
  }
}
