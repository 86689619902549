/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Modal } from '@carbon/react';
import { observer } from 'mobx-react';

import { idpProjectStore } from 'stores';
import { EXTRACTION_NOTIFICATION_STEPS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

function OverrideTestCaseModal({ onOverrideTestCase }) {
  const closeModal = () => idpProjectStore.setIsOverrideTestCaseModalVisible(false);

  const handleRequestSubmit = async () => {
    await onOverrideTestCase?.();
    if (idpProjectStore.idpExtractionFields.every((idpExtractionField) => !!idpExtractionField.extractedValue)) {
      idpProjectStore.setExtractionStepNotification(EXTRACTION_NOTIFICATION_STEPS.REPEAT_FOR_ALL_DOCUMENTS);
    }
    closeModal();
  };

  return (
    <Modal
      open={idpProjectStore.isOverrideTestCaseModalVisible}
      modalHeading="Override test case"
      primaryButtonText="Override test case"
      secondaryButtonText="Cancel"
      onRequestSubmit={handleRequestSubmit}
      onRequestClose={closeModal}
      preventCloseOnClickOutside
    >
      <span>You are overriding the current test case results with the latest extraction results.</span>
    </Modal>
  );
}

export default observer(OverrideTestCaseModal);
