/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {Object} Params
 * @property {string} processApplicationId - The ID of the process application
 */

import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';

import { FileViewer, startLoading, stopLoading, useViewModes } from 'features/view-file';
import { BPMN, DMN } from 'utils/constants';
import { DiffingToggle, useIsDiffingEnabled } from 'features/toggle-diffing';
import { VersionsSidebar } from 'components';

import { Header } from './features/header';
import { VersionsTitle } from './features/sidebar';
import { FileSwitcher, useFileSelection } from './features/cycle-through-files';
import { ProcessApplicationVersionsList, useVersionSelection } from './features/view-versions-list';
import { ProcessApplicationVersionDeployModal } from './features/deploy';
import { BpmnChangeList } from './features/bpmn-change-list';
import { useLoadProcessApplication, useLoadProcessApplicationVersions } from './hooks';

const ProcessApplicationVersions = () => {
  /** @type {Params} */
  const params = useParams();
  const processApplication = useLoadProcessApplication(params.processApplicationId);
  const { versions, reload } = useLoadProcessApplicationVersions(params.processApplicationId);
  const { selectedVersionId, setSelectedVersionId, selectedVersionMainProcessId, fileOrMilestoneIds } =
    useVersionSelection(processApplication, startLoading, stopLoading);
  const { fileSelection, selectFile } = useFileSelection(
    selectedVersionId,
    versions,
    processApplication,
    startLoading,
    stopLoading
  );
  const { isDiffingEnabled, toggleDiffing } = useIsDiffingEnabled();
  const viewModes = useViewModes();
  const [bpmnViewer, setBpmnViewer] = useState(null);

  if (!processApplication) {
    return;
  }

  const isDmnVisualView = fileSelection?.fileType === DMN && viewModes.isVisualView;
  const isBpmnVisualView = fileSelection?.fileType === BPMN && viewModes.isVisualView;

  return (
    <Container>
      <Header processApplication={processApplication} />
      <FileContainer>
        <FileSwitcher
          fileOrMilestoneIds={fileOrMilestoneIds}
          fileSelection={fileSelection}
          selectFile={selectFile}
          mainProcessFileId={selectedVersionMainProcessId}
        />
        <FileViewer
          fileSelection={fileSelection}
          isDiffingEnabled={isDiffingEnabled}
          viewModes={viewModes}
          bpmnViewer={bpmnViewer}
          setBpmnViewer={setBpmnViewer}
        />
      </FileContainer>
      <VersionsSidebar
        VersionsList={
          <ProcessApplicationVersionsList
            versions={versions}
            reloadVersions={reload}
            processApplication={processApplication}
            selectedVersionId={selectedVersionId}
            setSelectedVersionId={setSelectedVersionId}
            isDiffingEnabled={isDiffingEnabled}
          />
        }
        DiffingToggle={<DiffingToggle isDiffingEnabled={isDiffingEnabled} toggleDiffing={toggleDiffing} />}
        isShowDiffingToggle={!isDmnVisualView}
        BPMNChangeList={
          <BpmnChangeList
            currentContent={fileSelection?.current?.content}
            previousContent={fileSelection?.previous?.content}
            bpmnViewer={bpmnViewer}
            isDiffingEnabled={isDiffingEnabled}
          />
        }
        VersionsTitle={<VersionsTitle />}
        isShowBpmnChangeList={isBpmnVisualView}
      />
      <ProcessApplicationVersionDeployModal processApplication={processApplication} bpmnViewer={bpmnViewer} />
    </Container>
  );
};

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export default ProcessApplicationVersions;
