/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Dialog } from 'primitives';

export const Wrapper = styled(Dialog)`
  width: 550px;
`;

export const Content = styled(Dialog.Content)`
  p {
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 1.5;
  }
`;
