/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

class IdpApplicationService extends Service {
  constructor() {
    super();
  }

  /**
   * Creates a new IDP application in the given project, or in the given folder.
   *
   * @param {String} name the name of the IDP application
   * @param {String} projectId the project id
   * @param {String} parentId the folder id
   * @param {String} clusterId the cluster that can be set as default in the context of this IDP application
   * @return {Promise}
   */
  createIdpApplication({ name, projectId, folderId, clusterId }) {
    const payload = {
      name,
      projectId,
      folderId,
      clusterId
    };
    return this.post(`/internal-api/idp-applications`, payload);
  }

  /**
   * Returns the details related to the given IDP application
   *
   * @param {String} idpApplicationId the IDP application id
   * @return {Promise}
   */
  fetchIdpApplication(idpApplicationId) {
    return this.get(`/internal-api/idp-applications/${idpApplicationId}`);
  }

  /**
   * Returns the details related to the given IDP application
   *
   * @param {String} idpApplicationId the IDP application id
   * @param {String} name the new name for the IDP application
   * @return {Promise}
   */
  renameIdpApplication(idpApplicationId, name) {
    return this.patch(`/internal-api/idp-applications/${idpApplicationId}`, { name });
  }

  /**
   * Removes an IDP application.
   *
   * @param {String} idpApplicationId the IDP application id
   * @returns {Promise}
   */
  deleteIdpApplication(idpApplicationId) {
    return this.delete(`/internal-api/idp-applications/${idpApplicationId}`);
  }

  /**
   * Moves IDP applications into a different project or folder.
   *
   * @param {String} targetProjectId Target project ID
   * @param {String} targetFolderId Target folder ID, if given.
   * @param {String[]} idpApplicationIds An array of IDP application IDs.
   * @return {Promise}
   */
  moveIdpApplications(idpApplicationIds, targetProjectId, targetFolderId) {
    const payload = {
      targetProjectId,
      targetFolderId,
      idpApplicationIds
    };
    return this.post(`/internal-api/idp-applications/move`, payload);
  }
}

export default new IdpApplicationService();
