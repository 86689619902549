/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import moment from 'moment';
import { Button } from '@carbon/react';

import { DRAFT_VERSION } from 'components';

import * as Styled from './Version.styled';

/**
 * @typedef {import('react').MouseEventHandler} MouseEventHandler
 */

/**
 * Component to display draft version
 *
 * @param {Object} props - The component props.
 * @param {String} props.lastModified
 * @param {Boolean} props.isPrimarySelection
 * @param {Boolean} props.isSecondarySelection
 * @param {Function} props.setSelectedVersionId
 * @param {MouseEventHandler} props.onCreateVersion
 * @param {JSX.Element} props.VersionCreationModal
 * @returns {JSX.Element}
 */
export default function DraftVersion({
  lastModified,
  isPrimarySelection,
  isSecondarySelection,
  setSelectedVersionId,
  onCreateVersion,
  VersionCreationModal
}) {
  return (
    <Styled.Version
      // @ts-expect-error TS2769
      $isPrimary={isPrimarySelection}
      $isSecondary={isSecondarySelection}
      onClick={() => setSelectedVersionId(DRAFT_VERSION.id)}
      data-test="milestone"
      role="listitem"
      aria-label={DRAFT_VERSION.name}
      data-selected
    >
      {/* @ts-expect-error TS2339 */}
      <Styled.PlaceholderAvatar $isPrimary={isPrimarySelection} />
      <Styled.VersionContent>
        <Styled.VersionTitle initialValue={DRAFT_VERSION.name} isEditing={false}>
          {DRAFT_VERSION.name}
        </Styled.VersionTitle>

        <Styled.VersionParagraph>
          <time dateTime={lastModified}>{moment(lastModified).format('HH:mm')}</time>
        </Styled.VersionParagraph>
      </Styled.VersionContent>
      <Button size="sm" kind="tertiary" onClick={onCreateVersion}>
        Create version
      </Button>

      {VersionCreationModal}
    </Styled.Version>
  );
}
