/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { C3UserConfigurationProvider } from '@camunda/camunda-composite-components';

import { useGate } from 'App/Statsig';
import { getEnv } from 'utils/helpers';
import { authService } from 'services';
import { organizationStore } from 'stores';

export const UserConfigurationWrapper = ({ children }) => {
  const { currentOrganizationInfo } = organizationStore;
  const { value: isNoCloudInUrlsEnabled } = useGate('no-cloud-in-urls');

  return authService.token && currentOrganizationInfo?.uuid ? (
    <C3UserConfigurationProvider
      activeOrganizationId={currentOrganizationInfo?.uuid}
      stage={getEnv()}
      userToken={authService.token}
      getNewUserToken={() => authService.getToken()}
      currentApp="modeler"
      handleTheme={false}
      noCloudInUrl={isNoCloudInUrlsEnabled}
    >
      {children}
    </C3UserConfigurationProvider>
  ) : (
    <>{children}</>
  );
};
