/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Button, InlineNotification, TextInput as _TextInput, Link } from '@carbon/react';

export const InlineButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const InlineDeleteButton = styled(Button)`
  margin-top: 25px;
`;

export const InfoNotification = styled(InlineNotification)`
  margin-bottom: 50px;
`;

export const TextInput = styled(_TextInput)`
  .cds--popover-content {
    position: absolute;
    min-width: 300px;
  }
`;

export const TooltipLink = styled(Link)`
  display: flex;
  margin-top: 12px;

  &,
  &:visited,
  &:active,
  &:hover {
    color: var(--tooltip-link, hsl(218, 100%, 74%)) !important;
  }
`;
