/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { processApplicationStore } from 'stores';
import { GitSettingsDialog, gitSettingsStore, GitSyncButtons, GitSyncDialog, gitSyncStore } from 'features/git-sync';

const GitSync = () => {
  const { processApplication } = processApplicationStore;
  const { id: processApplicationId } = processApplication;

  useEffect(() => {
    if (!processApplicationId) {
      return;
    }

    gitSettingsStore.init(processApplicationId);

    return () => {
      gitSyncStore.reset();
      gitSettingsStore.reset();
    };
  }, [processApplicationId]);

  return (
    <>
      <GitSyncButtons />
      <GitSyncDialog />
      <GitSettingsDialog />
    </>
  );
};

export default observer(GitSync);
