/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from 'components';
import { executeModalStore } from 'App/Pages/Diagram/stores';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';

function DeployListItem({ versionId, closeVersionMenuDropdown }) {
  // @ts-ignore
  return (
    <Dropdown.ListItem
      onClick={async () => {
        deploymentStore.setProcessApplicationVersionId(versionId);
        executeModalStore.setMode('deploy');
        closeVersionMenuDropdown();
      }}
      hasSubDropdown={false}
      to={null}
      noPadding={false}
      target={null}
      disabled={false}
    >
      Deploy
    </Dropdown.ListItem>
  );
}

export default DeployListItem;
