/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Stack, Button } from '@carbon/react';

import { CONNECTOR_TEMPLATE_MAX_ICON_SIZE, CONNECTOR_TEMPLATE_VALID_ICON_TYPES } from 'utils/constants';
import CreateNewElementTemplateFileUploadFromUrl from 'App/Pages/CreateNewElementTemplate/CreateNewElementTemplateFileUploadFromUrl';
import CreateNewElementTemplateFileUpload from 'App/Pages/CreateNewElementTemplate/CreateNewElementTemplateFileUpload';

import * as Styled from './ConnectorTemplateIconUploadPopoverContent.styled';

export function ConnectorTemplateIconUploadPopoverContent({ iconFileData, setIconFileData, handleIconUpload }) {
  const maxIconSize = CONNECTOR_TEMPLATE_MAX_ICON_SIZE;
  const dataSourceTypes = [
    {
      id: 'import-from-url',
      label: 'Import from URL'
    },
    { id: 'upload-file', label: 'Upload file' }
  ];
  const [iconSourceType, setIconSourceType] = useState(dataSourceTypes[0]?.id);

  return (
    <Styled.IconUploadPopoverContent className="icon-upload-popover-content">
      <Styled.IconUploadMainStack gap={5}>
        <Stack gap={2}>
          <Styled.IconUploadPopoverHeader>Upload custom icon</Styled.IconUploadPopoverHeader>
        </Stack>
        <Stack gap={2}>
          Upload a custom icon for the Connector template to override the default BPMN symbol when the template is used
          in a diagram.
        </Stack>
        <Stack gap={2}>
          <Styled.IconUploadContentSwitcher
            size="md"
            onChange={(data) => setIconSourceType(data.name)}
            selectedIndex={iconSourceType === 'import-from-url' ? 0 : 1}
          >
            {dataSourceTypes.map((sourceType) => (
              <Styled.SwitchButton key={sourceType.id} name={sourceType.id} disabled={false}>
                {sourceType.label}
              </Styled.SwitchButton>
            ))}
          </Styled.IconUploadContentSwitcher>
        </Stack>
        <Stack gap={2}>
          {iconSourceType === 'import-from-url' && (
            <>
              <Stack
                gap={8}
              >{`Icon requirements: max ${maxIconSize / 1000 - 1} KB, file types ${CONNECTOR_TEMPLATE_VALID_ICON_TYPES.join(', ')}.`}</Stack>
              <Stack>
                <CreateNewElementTemplateFileUploadFromUrl
                  fileData={iconFileData}
                  setFileData={setIconFileData}
                  fileSizeLimit={maxIconSize}
                />
              </Stack>
            </>
          )}
          {iconSourceType === 'upload-file' && (
            <Stack>
              <>
                <Stack gap={8}>
                  {`Icon requirements: max ${maxIconSize / 1000 - 1} KB, file types ${CONNECTOR_TEMPLATE_VALID_ICON_TYPES.join(', ')}.`}
                </Stack>
                <Stack gap={5}>
                  <CreateNewElementTemplateFileUpload
                    fileData={iconFileData}
                    setFileData={setIconFileData}
                    validFileTypes={CONNECTOR_TEMPLATE_VALID_ICON_TYPES}
                    fileSizeLimit={maxIconSize}
                  />
                </Stack>
              </>
            </Stack>
          )}
        </Stack>
        <Stack>
          <Styled.IconUploadPopoverContentButtonContainer>
            <Button
              data-test="add-icon-cta"
              kind="primary"
              onClick={handleIconUpload}
              disabled={!iconFileData || iconFileData?.invalid}
            >
              Add icon
            </Button>
          </Styled.IconUploadPopoverContentButtonContainer>
        </Stack>
      </Styled.IconUploadMainStack>
    </Styled.IconUploadPopoverContent>
  );
}

export default observer(ConnectorTemplateIconUploadPopoverContent);
