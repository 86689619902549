/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalTheme } from '@carbon/react';

import { useConfiguredClusters } from 'hooks';
import { notificationStore, organizationStore, topBannerStore, userStore } from 'stores';
import { tracingService } from 'services';
import { ConfirmActionDialog, ErrorDisplay, TopBar } from 'components';
import { Snackbar, Spinner, TopBanner } from 'primitives';
import { transportCarrier } from 'transports';
import { getPageTitle } from 'utils/helpers';
import config from 'utils/config';
import { Notifications } from 'components/NotificationSystem';

import Home from './Pages/Home';
import Diagram from './Pages/Diagram/Diagram';
import Form from './Pages/Form/Form';
import CreateNewElementTemplate from './Pages/CreateNewElementTemplate/CreateNewElementTemplate';
import ConnectorTemplate from './Pages/ConnectorTemplate/ConnectorTemplate';
import Project from './Pages/Project/Project';
import Folder from './Pages/Project/Folder';
import ProcessApplication from './Pages/Project/ProcessApplication';
import { Readme } from './Pages/Readme';
import { ReadmeVersions } from './Pages/ReadmeVersions';
import IdpApplication from './Pages/Project/IdpApplication';
import IdpProject from './Pages/Project/IdpApplication/IdpProject';
import Settings from './Pages/Settings/Settings';
import NoMatch from './Pages/NoMatch';
import Share from './Pages/Share/Share';
import DiagramMilestones from './Pages/DiagramMilestones';
import FormMilestones from './Pages/FormMilestones';
import ConnectorTemplateVersions from './Pages/ConnectorTemplateVersions';
import ProcessApplicationVersions from './Pages/ProcessApplicationVersions';
import IdpProjectVersions from './Pages/IdpProjectVersions';
import DeployableStarterBehavior from './DeployableStarterBehavior';
import useAppCues from './useAppCues';
import useAuthentication from './useAuthentication';
import { useInitStatsigParams, WithStatsig } from './Statsig';
import { AppTeaser } from './Pages/AppTeaser';
import { UserConfigurationWrapper } from './UserConfigurationWrapper';

export const App = () => {
  const [isNewVersionMessageVisible, setIsNewVersionMessageVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated, userAuthProviderId, userId } = userStore;
  const { currentOrganizationId, currentOrganizationInfo } = organizationStore;
  const isInShare = location.pathname.includes('/share/');

  useAuthentication();

  useEffect(() => {
    const preventDefault = (evt) => evt.preventDefault();

    window.addEventListener('dragover', preventDefault, false);
    window.addEventListener('drop', preventDefault, false);

    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', preventDefault);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const socket = transportCarrier.socket;
      const channel = socket?.subscribe('private-maintenance');

      channel?.bind('app:notifications:new-version', () => setIsNewVersionMessageVisible(true));
    }
  }, [isAuthenticated]);

  useAppCues({
    history,
    currentOrganizationInfo,
    config,
    organizationStore,
    userAuthProviderId
  });

  const { isStatsigParamsInitialized, hashedUserId } = useInitStatsigParams(
    userId,
    currentOrganizationId,
    currentOrganizationInfo
  );

  useConfiguredClusters();

  // Statsig params (user and organization info) are not available in Share page
  const isStasigEnabled = config?.statsig?.enabled && !isInShare;

  if (isStasigEnabled && !isStatsigParamsInitialized) {
    return <Spinner fullHeight />;
  }

  return (
    <GlobalTheme theme="g10">
      <WithStatsig isEnabled={isStasigEnabled} hashedUserId={hashedUserId}>
        <UserConfigurationWrapper>
          <ErrorBoundary FallbackComponent={ErrorDisplay}>
            <Helmet>
              {/* @ts-expect-error TS2554 */}
              <title>{getPageTitle()}</title>
            </Helmet>

            {isAuthenticated && !isInShare && <ConfirmActionDialog />}

            <TopBar isInShare={isInShare} />

            <TopBanner.OutdatedBrowserMessage
              onClose={topBannerStore.hideOutdatedBrowserMessage}
              open={topBannerStore.isOutdatedBrowserMessageVisible}
            />

            {isAuthenticated && (
              <Fragment>
                <TopBanner.UpdatedAppMessage open={isNewVersionMessageVisible} />

                <TopBanner.DownTimeBanner
                  open={topBannerStore.isDownTimeBannerVisible}
                  onClose={topBannerStore.hideDownTimeBanner}
                  schedule={topBannerStore.downTimeSchedule}
                />

                <TopBanner.TrialBanner open={organizationStore.isUsingTrial && !organizationStore.isTrialExpired} />
              </Fragment>
            )}

            <ErrorBoundary
              FallbackComponent={ErrorDisplay}
              resetKeys={[location.pathname]}
              onReset={() => history.push('/')}
              // @ts-expect-error TS2322
              onError={tracingService.traceError}
            >
              <Switch>
                <Route
                  path={['/', '/org/:orgId', '/import/connectors', '/import/processes', '/import/resources']}
                  exact
                >
                  <Home />
                </Route>

                <Route path={['/login', '/login-callback', '/logout']} exact>
                  <Spinner fullHeight />
                </Route>

                <Redirect from="/projects" to="/" exact />
                <Route path="/projects/:slug" exact>
                  <Project />
                </Route>
                <Route path="/tutorial/:id">
                  <DeployableStarterBehavior />
                </Route>
                <Route path="/folders/:slug">
                  <Folder />
                </Route>
                <Route path="/process-applications/:slug" exact>
                  <ProcessApplication />
                </Route>
                <Route path="/idp-applications/:slug" exact>
                  <IdpApplication />
                </Route>
                <Route path="/idp-projects/:slug/:tab(extract|upload|evaluate)?" exact>
                  <IdpProject />
                </Route>
                <Route path="/idp-projects/:idpProjectId/versions" exact>
                  <IdpProjectVersions />
                </Route>
                <Route path={['/diagrams/:slug', '/diagrams/xml/:slug']} exact>
                  <Diagram />
                </Route>
                {/* Diagram milestones were renamed to versions */}
                <Redirect from="/diagrams/:id/milestones/:slug?" to="/diagrams/:id/versions/:slug?" />
                <Route path="/diagrams/:id/versions/:slug?">
                  <DiagramMilestones />
                </Route>
                <Route path="/process-applications/:processApplicationId/versions">
                  <ProcessApplicationVersions />
                </Route>
                <Route path="/forms/:slug" exact>
                  <Form />
                </Route>
                {/* Form milestones were renamed to versions */}
                <Redirect from="/forms/:id/milestones/:slug?" to="/forms/:id/versions/:slug?" />
                <Route path="/forms/:id/versions/:slug?">
                  <FormMilestones />
                </Route>
                <Route path="/projects/:slug/create-new-element-template" exact>
                  <CreateNewElementTemplate />
                </Route>
                <Route path="/projects/:slug/:processAppId/create-new-element-template" exact>
                  <CreateNewElementTemplate />
                </Route>
                <Route path="/connector-templates/:slug" exact>
                  <ConnectorTemplate />
                </Route>
                <Route path="/connector-templates/:id/versions/:slug?">
                  <ConnectorTemplateVersions />
                </Route>
                <Route path="/readmes/:slug" exact>
                  <Readme />
                </Route>
                <Route path="/readmes/:id/versions/:slug?">
                  <ReadmeVersions />
                </Route>
                <Route path={['/shares/:id', '/share/:id']}>
                  <Share />
                </Route>
                <Route path="/notifications" exact>
                  <Redirect to="/settings/email-notifications" />
                </Route>
                <Route path="/settings/:setting?">
                  <Settings />
                </Route>
                <Route path="/appTeaser/operate">
                  <AppTeaser appName="operate" />
                </Route>
                <Route path="/appTeaser/optimize">
                  <AppTeaser appName="optimize" />
                </Route>
                <Route path="/appTeaser/tasklist">
                  <AppTeaser appName="tasklist" />
                </Route>
                <Route>
                  {/* @ts-expect-error TS2741 */}
                  <NoMatch />
                </Route>
              </Switch>
            </ErrorBoundary>
            <Snackbar
              open={notificationStore.isNotificationVisible}
              onClose={notificationStore.hideNotification}
              onExited={notificationStore.resetNotification}
              variant={notificationStore.variant}
              message={notificationStore.message}
              progress={notificationStore.isProgress}
              duration={notificationStore.duration}
              data-test="snackbar"
            />
            <Notifications />
          </ErrorBoundary>
        </UserConfigurationWrapper>
      </WithStatsig>
    </GlobalTheme>
  );
};

export default observer(App);
