/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';
import { Accordion, TextArea } from '@carbon/react';

const maxWidthBreakpoint = '1300px';

export const AddNewExtractionFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddNewExtractionFieldNameAndTypeSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding-bottom: 20px;

  & > *:first-child {
    flex: 2;
  }

  & > *:nth-child(2) {
    flex: 1;
  }
`;

export const AddNewExtractionFieldFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  & > *:first-child {
    flex: 4;
  }

  & > *:nth-child(2) {
    flex: 1;
`;

export const ExtractDocumentSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: ${maxWidthBreakpoint}) {
    flex-direction: column;
    align-items: unset;
  }

  & > * {
    flex: 1;

    @media screen and (max-width: ${maxWidthBreakpoint}) {
      flex: unset;
    }
  }
`;

export const ExtractDocumentActionContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;

  @media screen and (max-width: ${maxWidthBreakpoint}) {
    justify-content: space-between;
  }
`;

export const ExtractionFieldsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;

  @media screen and (max-height: 700px) {
    overflow-y: unset;
  }
`;

export const ExtractionFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  & > *:first-child {
    overflow-wrap: break-word;

    label {
      max-width: 80%;
    }
  }

  & > *:first-child,
  & > *:nth-child(2) {
    flex: 1 1 0;
  }

  & > *:last-child {
    flex: 0 0 5%;
  }
`;

export const ExtractionFieldContainerExtractedValueField = styled(TextArea)`
  textarea {
    height: ${({ $isExpectedValueDefined }) => ($isExpectedValueDefined ? '5.3rem' : '6.4rem')};
  }
`;

export const AddExtractionFieldPanelAccordion = styled(Accordion)`
  /*
   * This is added to remove the border added by default for the accordion with one item in it.
   */
  .cds--accordion__item {
    border: none;
  }

  /*
   * This is added to remove the extra margin and padding so Text fields align with the chevron icon.
   */
  .cds--accordion__content {
    padding-inline-end: 0;
    margin-inline-start: 0;
  }
`;

export const AddFieldContainer = styled.div`
  background-color: white;
  padding: ${({ $isExpanded }) => ($isExpanded ? '8px 20px 0 0' : '8px 20px 8px 0')};
`;

export const ExtractionFieldsContainer = styled.div`
  padding: 16px 20px;
  background-color: white;
  flex: 1 1 0;
  overflow-y: auto;
`;

export const ExtractionModelSelectionContainer = styled.div`
  padding: 16px 20px;
  background-color: white;
`;

export const ContainerHeader = styled.span`
  font-weight: 600;
`;

export const Placeholder = styled.span`
  color: var(--cds-text-secondary, #525252);
`;
