/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { processApplicationService, settingsService, tracingService } from 'services';
import { notificationStore } from 'components/NotificationSystem';

/**
 * @typedef {'REVIEW_MISSING' | 'ADMIN_REQUIRED' | 'NO_RESTRICTIONS'} DeploymentPolicyCheckResult
 */

/**
 * @readonly
 * @type {{REVIEW_MISSING: 'REVIEW_MISSING', ADMIN_REQUIRED: 'ADMIN_REQUIRED', NO_RESTRICTIONS: 'NO_RESTRICTIONS'}}
 */
export const POLICY_RESULT = {
  REVIEW_MISSING: 'REVIEW_MISSING',
  ADMIN_REQUIRED: 'ADMIN_REQUIRED',
  NO_RESTRICTIONS: 'NO_RESTRICTIONS'
};

async function fetchIsVersionApproved(processApplicationId, processApplicationVersionId) {
  const versions = await processApplicationService.fetchProcessApplicationVersions(processApplicationId);
  return versions.find((version) => version.id === processApplicationVersionId)?.latestReview?.status === 'APPROVED';
}

/**
 * @param {object} args
 * @param {string} args.organizationId
 * @param {boolean} args.hasElevatedOrganizationPermissions
 * @param {string} [args.processApplicationId]
 * @param {string} [args.processApplicationVersionId]
 * @returns {Promise<DeploymentPolicyCheckResult>} The policy result
 */
async function checkPolicy({
  organizationId,
  hasElevatedOrganizationPermissions,
  processApplicationId,
  processApplicationVersionId
}) {
  const isDraft = processApplicationId && !processApplicationVersionId;
  const settings = await settingsService.fetchDeploymentPolicy(organizationId);

  if (settings.value === 'REVIEW_REQUIRED') {
    if (isDraft) {
      return POLICY_RESULT.REVIEW_MISSING;
    } else {
      const isVersionApproved = await fetchIsVersionApproved(processApplicationId, processApplicationVersionId);

      if (isVersionApproved) {
        return POLICY_RESULT.NO_RESTRICTIONS;
      } else {
        return POLICY_RESULT.REVIEW_MISSING;
      }
    }
  } else if (!hasElevatedOrganizationPermissions) {
    return POLICY_RESULT.ADMIN_REQUIRED;
  } else {
    return POLICY_RESULT.NO_RESTRICTIONS;
  }
}

/**
 * @param {object} args
 * @param {boolean} args.isClusterProd
 * @param {string} args.organizationId
 * @param {boolean} args.hasElevatedOrganizationPermissions
 * @param {string} [args.processApplicationId] should be falsy if this is not a process app
 * @param {string} [args.processApplicationVersionId] should be falsy if this is a draft
 * @param {boolean} [args.isOpen = true]
 * @returns {{isLoading: boolean, deploymentPolicyCheckResult: DeploymentPolicyCheckResult}}
 */
export function useCheckDeploymentPolicy({
  isClusterProd,
  organizationId,
  hasElevatedOrganizationPermissions,
  processApplicationId,
  processApplicationVersionId,
  isOpen = true
}) {
  const isNormalProcess = !processApplicationId;
  /** @type {[DeploymentPolicyCheckResult, React.Dispatch<React.SetStateAction<DeploymentPolicyCheckResult>>]} */
  const [deploymentPolicyCheckResult, setDeploymentPolicyCheckResult] = useState(POLICY_RESULT.NO_RESTRICTIONS);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let ignoreResult = false;

    if (!isOpen) {
      return;
    } else if (!isClusterProd || (isNormalProcess && hasElevatedOrganizationPermissions)) {
      // no restrictions apply for deployments to non-prod stages or to admins who want to deploy a normal process to prod
      setDeploymentPolicyCheckResult(POLICY_RESULT.NO_RESTRICTIONS);
      setIsLoading(false);
    } else if (isNormalProcess && !hasElevatedOrganizationPermissions) {
      // admin permissions are required for deploying process that are not in a process app to prod
      setDeploymentPolicyCheckResult(POLICY_RESULT.ADMIN_REQUIRED);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      checkPolicy({
        organizationId,
        hasElevatedOrganizationPermissions,
        processApplicationId,
        processApplicationVersionId
      })
        .then((result) => {
          if (!ignoreResult) {
            setDeploymentPolicyCheckResult(result);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (!ignoreResult) {
            tracingService.traceError(err, 'Failed to deduce prod deployment requirements from policy');
            notificationStore.error({
              title: 'Something went wrong',
              content: 'Please try reloading the page'
            });
            setIsLoading(false);
          }
        });
    }
    return () => {
      ignoreResult = true;
    };
  }, [
    organizationId,
    processApplicationId,
    processApplicationVersionId,
    isClusterProd,
    hasElevatedOrganizationPermissions,
    isNormalProcess,
    isOpen
  ]);

  return { deploymentPolicyCheckResult, isLoading };
}
