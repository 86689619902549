/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import * as Styled from './BrowseBlueprintsModal.styled';
import BrowseBlueprintsModalList from './BrowseBlueprintsModalList';
import BrowseBlueprintsModalTopFilters from './BrowseBlueprintsModalTopFilters';
import BrowseBlueprintsModalLoading from './BrowseBlueprintsModalLoading';

export const BrowseBlueprintsModalListContainer = ({
  isBlueprintsLoading,
  filters,
  availableFilters,
  setFilters,
  setSearchValue,
  allBlueprints,
  blueprints,
  handleClose,
  errors,
  page,
  pageSize,
  totalItems,
  setPage,
  setPageSize,
  setBlueprints,
  isBlueprintsLoadingError,
  searchValue,
  setIsLoading
}) => {
  return (
    <>
      <BrowseBlueprintsModalTopFilters
        availableFilters={availableFilters}
        filters={filters}
        setFilters={setFilters}
        setSearchValue={setSearchValue}
      />
      <Styled.BrowseBlueprintListContainer>
        {isBlueprintsLoading ? (
          <BrowseBlueprintsModalLoading isBlueprintsLoading />
        ) : (
          <>
            <BrowseBlueprintsModalList
              allBlueprints={allBlueprints}
              blueprints={blueprints}
              errors={errors}
              handleClose={handleClose}
              page={page}
              pageSize={pageSize}
              totalItems={totalItems}
              setPage={setPage}
              setPageSize={setPageSize}
              setBlueprints={setBlueprints}
              isBlueprintsLoadingError={isBlueprintsLoadingError}
              searchValue={searchValue}
              setIsLoading={setIsLoading}
            />
          </>
        )}
      </Styled.BrowseBlueprintListContainer>
    </>
  );
};

export default observer(BrowseBlueprintsModalListContainer);
