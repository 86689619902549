/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tooltip } from 'primitives';
import textEnhancer from 'utils/text-enhancer';

import * as Styled from './DocumentationOverlay.styled';

const DocumentationOverlay = ({ element }) => {
  const documentation = element.businessObject.get('documentation');

  return (
    <Tooltip
      interactive
      justify="left"
      title={
        <Styled.Content
          data-test="specification-overlay"
          dangerouslySetInnerHTML={{
            __html: textEnhancer(getDocumentationText(documentation))
          }}
        />
      }
    >
      <Styled.DocumentationIcon />
    </Tooltip>
  );
};

export default DocumentationOverlay;

const getDocumentationText = (element) => {
  return element.length > 0 && element[0].text ? element[0].text : '';
};
