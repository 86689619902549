/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import ClusterCreationModal from 'features/create-cluster/ClusterCreationModal';

export function IdpClusterCreationModal({ onClose, onSubmit }) {
  return (
    <ClusterCreationModal
      title="Cluster required"
      subtitle={
        <>
          Your organization doesn't have any cluster yet.
          <br />
          Create a cluster to test your IDP projects.
        </>
      }
      creationContextEntity={null}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
