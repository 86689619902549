/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * ConnectorError error type representing connector input errors.
 *
 * @class ConnectorInputError
 */
export class ConnectorError extends Error {
  /**
   * Creates an instance of ConnectorInputError.
   *
   * @param {string} message - The error message.
   * @param {string} type - The error type.
   */
  constructor(message, type) {
    super(message);
    this.type = type;
  }
}
