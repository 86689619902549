/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { TreeView } from '@carbon/icons-react';
import { Tooltip } from '@carbon/react';

import * as Styled from './LandscapeViewButton.styled';

export function LandscapeViewButton({ onClick = () => {}, tabIndex }) {
  return (
    <Tooltip description="View the landscape to identify relations between your processes." align="bottom">
      <Styled.LandscapeButton
        kind="ghost"
        size="md"
        onClick={onClick}
        renderIcon={TreeView}
        data-test="open-landscape"
        tabIndex={tabIndex}
      >
        <span>View landscape</span>
      </Styled.LandscapeButton>
    </Tooltip>
  );
}
