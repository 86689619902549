/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef } from 'react';
import { createPortal, flushSync } from 'react-dom';
import PropTypes from 'prop-types';

import * as Styled from './Snackbar.styled';

export default function Snackbar({
  open = false,
  onClose,
  onExited,
  variant = 'info',
  message,
  progress = false,
  duration = 3500,
  ...props
}) {
  const ref = useRef();
  let timer;

  useEffect(() => {
    clearTimeout(timer);

    if (open) {
      timer = setTimeout(() => {
        ref.current?.classList.add('slideout');

        // wait for slideout animation to complete
        setTimeout(() => {
          // onClose and onExited must not be batched together
          // because open must be set to false and then true again for this hook to work
          // flushSync ensures a re-render after onClose (setting open to false)
          // and after onExited (setting open to true again for the next notification)
          flushSync(onClose);
          flushSync(onExited);
        }, 200);
      }, duration);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return createPortal(
    <Styled.Wrapper ref={ref} role="alert" $variant={variant} {...props}>
      {progress && <Styled.LoadingAnimation $light size="small" />}
      {message}
    </Styled.Wrapper>,
    document.body
  );
}

Snackbar.propTypes = {
  variant: PropTypes.oneOf(['info', 'error']),
  progress: PropTypes.bool,
  message: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func
};
