/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import * as Styled from 'components/MultiSelectInput/MultiSelectInput.styled';

export const AllMembersSuggestionOption = ({ handleOnClick }) => {
  return (
    <div
      key="invite-all"
      onMouseDown={(event) => {
        event.preventDefault();
        handleOnClick();
      }}
    >
      <Styled.CollaboratorItem
        // @ts-expect-error TS2769
        username="Invite all organization members"
        displayName="All users in the organization"
        subtitle="Gives the chosen role to all current users of your organization"
        isCursorNotAllowed={false}
        isUserGroup
      />
    </div>
  );
};
