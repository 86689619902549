/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { Folders } from '@carbon/icons-react';
import { Button } from '@carbon/react';

const ActionsButtons = ({
  showCancel,
  isCancelGhost,
  showPublish,
  showUnpublish,
  showDuplicatesActions,
  hasErrors,
  disabled,
  onCancel,
  onPublish,
  onUnpublish,
  onSaveAsCopy,
  onReplace,
  onGoToSupport,
  onGoToMarketplace
}) => {
  return (
    <>
      {hasErrors && (
        <>
          <Button kind="ghost" onClick={onGoToSupport}>
            Feedback and support
          </Button>
          <Button kind="primary" onClick={onGoToMarketplace}>
            Back to Marketplace
          </Button>
        </>
      )}

      {!hasErrors && (
        <>
          {showCancel && (
            <Button kind={isCancelGhost ? 'ghost' : 'secondary'} onClick={onCancel}>
              Cancel
            </Button>
          )}

          {showPublish && (
            <Button
              kind="primary"
              renderIcon={Folders}
              iconDescription="Add to project"
              data-test="publish-to-selected-project"
              disabled={disabled}
              onClick={onPublish}
            >
              Add to project
            </Button>
          )}

          {showUnpublish && (
            <Button kind="danger" data-test="unpublish" disabled={disabled} onClick={onUnpublish}>
              Unpublish
            </Button>
          )}

          {showDuplicatesActions && (
            <>
              <Button kind="secondary" disabled={disabled} onClick={onSaveAsCopy}>
                Save as copy
              </Button>

              <Button kind="primary" disabled={disabled} onClick={onReplace}>
                Replace resource
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

ActionsButtons.propTypes = {
  showCancel: PropTypes.bool,
  showPublish: PropTypes.bool,
  showDuplicatesActions: PropTypes.bool,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onPublish: PropTypes.func,
  onSaveAsCopy: PropTypes.func,
  onReplace: PropTypes.func
};

export default ActionsButtons;
