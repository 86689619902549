/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';

class LandscapeViewService extends Service {
  /**
   * Fetches the processLandscapeData required to render the process landscape and returns the output of the process
   * @param {*} projectId The specific project id to generate landscape for
   * @returns {Promise} - the promise of the fetch landscape
   */
  fetchProcessLandscapeData(projectId) {
    return this.get(`/internal-api/projects/${projectId}/process-landscape`);
  }
}

export default new LandscapeViewService();
