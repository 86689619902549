/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

import reviewStore from 'App/Pages/ProcessApplicationVersions/features/review/ReviewStore';

/**
 * @param {Object} params
 * @param {Array<import('services/ProcessApplicationService.common').ProcessApplicationVersionListEntryDto>} params.versions
 */
export default function useInitReviewStore({ versions }) {
  useEffect(() => {
    const reviews = versions.map((version) => version.latestReview).filter(Boolean);
    const reviewRequests = versions.map((version) => version.reviewRequest).filter(Boolean);
    reviewStore.init(reviews, reviewRequests);

    return () => {
      reviewStore.reset();
    };
  }, [versions]);
}
