/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import debounce from 'utils/debounce';

import Service from './Service';

class CommentService extends Service {
  /**
   * Fetches all comments associated to specific diagram, based
   * on the given `fileId`.
   *
   * @param {String} fileId
   * @returns {Promise}
   */
  fetchByDiagramId(fileId) {
    return this.get(`/internal-api/files/${fileId}/comments`);
  }

  /**
   * Creates a new comment for the given `fileId`.
   *
   * @param {Object} payload
   * @param {String} payload.fileId The associated diagram ID.
   * @param {String} payload.content The comment's content.
   * @param {String} [payload.reference] An optional reference to a BPMN element
   * @returns {Promise}
   */
  create(payload) {
    return this.post(`/internal-api/comments`, payload);
  }

  /**
   * Updates an existing comment's content.
   *
   * @param {String} commentId
   * @param {Object} payload
   * @param {String} payload.content The updated content.
   * @returns {Promise}
   */
  update(commentId, payload) {
    return this.patch(`/internal-api/comments/${commentId}`, payload);
  }

  /**
   * Removes an existing comment, based on the given `commentId`.
   *
   * @param {String} commentId
   * @returns {Promise}
   */
  destroy = debounce(
    (commentId, originAppInstanceId) => {
      return this.delete(`/internal-api/comments/${commentId}`, {
        originAppInstanceId
      });
    },
    Service.debounceDelay,
    Service.debounceOptions
  );

  /**
   * Removes all comments belonging to a specific BPMN element. This method
   * gets invoked once a BPMN element is deleted from the canvas.
   *
   * @param {String} diagramId
   * @param {String} nodeId The BPMN element's ID.
   * @returns {Promise}
   */
  destroyByNode(diagramId, nodeId, originAppInstanceId) {
    return this.delete(`/internal-api/files/${diagramId}/node/${nodeId}`, {
      originAppInstanceId
    });
  }
}

export default new CommentService();
