/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import formLinkStore from './FormLinkStore';
import FormLinkOverlay from './FormLinkOverlay';

const FormLinking = ({ isLoadingModeler, permission }) => {
  const { selectedElement, isElementLinked } = formLinkStore;

  let showOverlay = !isLoadingModeler && selectedElement;
  const isReadOnly = permission.is(['COMMENT', 'READ']);

  if (isReadOnly) {
    if (!isElementLinked(selectedElement)) {
      showOverlay = false;
    }
  }

  return <>{showOverlay && <FormLinkOverlay element={selectedElement} readOnly={isReadOnly} />}</>;
};

export default observer(FormLinking);
