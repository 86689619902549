/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

class DefineStagesModalStore {
  isVisible = false;
  // We need to keep trace of the mode (deploy, execute or play) that the `ExecuteModal` was open, so that we can easily transition back to it
  executeModalMode = null;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * It opens the DefineStagesModal, storing the starting mode of the ExecuteModal
   * @param executeModalMode {'deploy'|'execute'|'play'}
   */
  open = ({ executeModalMode }) => {
    this.#setIsVisible(true);
    this.executeModalMode = executeModalMode;
  };

  /**
   * It closes the DefineStagesModal
   */
  close = () => {
    this.#setIsVisible(false);
  };

  #setIsVisible(isVisible) {
    this.isVisible = isVisible;
  }
}

export default new DefineStagesModalStore();
