/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

class AssetUpdateService extends Service {
  /**
   * Fetch some endpoint to force version/lastRefreshAge diffing on the WebApp.
   * Expected response: an empty json or status 230 (which would reload the app).
   *
   * @returns {Promise}
   */
  checkForUpdate() {
    return this.get('/updatecheck');
  }
}

export default new AssetUpdateService();
