/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { organizationStore } from 'stores';
import { dedicatedModesStore, executeModalStore } from 'App/Pages/Diagram/stores';
import { WithExternalLinkIcon } from 'primitives';
import usePlayC8Enabled from 'App/Pages/Diagram/hooks/usePlayC8Enabled';

import * as Styled from './LegacyPlayModeInfo.styled';

export default function LegacyPlayModeInfo() {
  const { playModeIndex, setViewModeIndex } = dedicatedModesStore;
  const isC8PlayEnabled = usePlayC8Enabled();
  const { hasLegacyPlayAccess } = organizationStore;

  const switchToPlayMode = () => {
    executeModalStore.setMode(undefined);
    setViewModeIndex(playModeIndex);
  };

  const openPlayDocumentation = () => {
    window.open('https://docs.camunda.io/docs/components/modeler/web-modeler/play-your-process/');
  };

  if (isC8PlayEnabled) {
    return;
  }

  return (
    <Styled.Link onClick={hasLegacyPlayAccess ? switchToPlayMode : openPlayDocumentation}>
      or evaluate your process in {hasLegacyPlayAccess ? 'Play' : <WithExternalLinkIcon label="Play" />}
    </Styled.Link>
  );
}
