/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import FileMapper from 'utils/file-mapper';
import { BPMN } from 'utils/constants';

import Service from './Service';

/**
 * @typedef {import('components/VersionsList/types').VersionListEntryDto} VersionListEntryDto
 * @typedef {import('components/VersionsList/types').UserDto} UserDto
 */

/**
 * @typedef {Object} ProcessApplicationReviewRequestDto
 * @property {string} id - The ID of the review request.
 * @property {string} processApplicationVersionId - The ID of the process application version.
 * @property {number} created - The timestamp when the review request was created.
 * @property {UserDto} createdBy - The creator of the review request.
 */

/**
 * @typedef {Object} ProcessApplicationReviewDto
 * @property {string} id - The ID of the review.
 * @property {string} processApplicationVersionId - The ID of the process application version.
 * @property {"APPROVED" | "CHANGES_REQUESTED"} status - The status of the review.
 * @property {string} comment - The comment for the review.
 * @property {number} created - The timestamp when the review was created.
 * @property {UserDto} createdBy - The creator of the review.
 */

/**
 * @typedef {VersionListEntryDto & {
 *   processApplicationId: string, // The ID of the process application.
 *   reviewRequest: ProcessApplicationReviewRequestDto, // The review request for the process application version.
 *   latestReview: ProcessApplicationReviewDto, // The latest review for the process application version.
 * }} ProcessApplicationVersionListEntryDto
 */

/**
 * @typedef {Object} FileOverview
 * @property {string} id
 * @property {string} name
 * @property {number} created
 * @property {number} changed
 * @property {string} projectId
 * @property {string} folderId
 * @property {string} organizationId
 * @property {number} revision
 * @property {string} processId
 * @property {string} type
 * @property {Object} author
 * @property {string} formId;
 */

/**
 * @typedef {Object} ProcessApplicationWithOnlyDevClusterId
 * @property {string} id,
 * @property {string} name,
 * @property {string} projectId,
 * @property {string} parentId,
 * @property {string} mainProcessFileId,
 * @property {Array<Object>} files,
 * @property {string} defaultDevClusterId,
 */

/**
 * @typedef {Object} ProcessApplication
 * @property {string} id,
 * @property {string} name,
 * @property {string} projectId,
 * @property {string} parentId,
 * @property {string} mainProcessFileId,
 * @property {Array<FileOverview>} files,
 * @property {string} defaultDevClusterId,
 * @property {string} defaultTestClusterId,
 * @property {string} defaultStageClusterId,
 * @property {string} defaultProdClusterId,
 * @property {Array<ProcessApplicationReviewRequestDto>} reviewRequest
 */

/**
 * @typedef {Object} MilestoneWithoutContent
 * @property {String} id
 * @property {String} fileId
 * @property {String} name
 * @property {Boolean} organizationPublic
 * @property {String} authorId
 * @property {String} authorName
 * @property {String} created
 */

/**
 * @typedef {Object} ProcessApplicationVersion
 * @property {string} id
 * @property {string} name
 * @property {string} processApplicationId
 * @property {string} mainProcessFileId
 * @property {Array<MilestoneWithoutContent>} milestones
 */

/**
 * @typedef {Object} DeployedResourceVersion
 * @property {string} id - The id of the deployment
 * @property {number} version - The version number of the deployment
 */

/**
 * @typedef {Object} ProcessApplicationDeployment
 * @property {Array<DeployedResourceVersion>} processes - The deployed processes
 * @property {Array<DeployedResourceVersion>} decisions - The deployed decisions
 * @property {Array<DeployedResourceVersion>} forms - The deployed forms
 * @property {number} bundleSize - The size of the deployment bundle
 * @property {number} numberOfFiles - The number of files in the deployment
 */

/**
 * @typedef {Object} SaasFileExecute
 * @property {string} clusterId - The id of the cluster
 * @property {string} variables - The variables for the execution
 * @property {string} formResolutions - The form resolutions for the execution
 */

/**
 * @typedef {Object} SelfManagedFileExecute
 * @property {string} clusterId - The id of the cluster
 * @property {string} tenantId - The id of the tenant
 * @property {string} variables - The variables for the execution
 * @property {string} formResolutions - The form resolutions for the execution
 */

class BaseProcessApplicationService extends Service {
  constructor() {
    super();
  }

  /**
   * Creates a new process application in the given project, or in the given folder.
   *
   * @param {Object} params
   * @param {String | undefined} [params.projectId] the project id
   * @param {String | undefined} [params.parentId] the folder id
   * @param {String} [params.processApplicationName]
   * @param {String | undefined} [params.defaultDevClusterId] the cluster that can be set as default in the context of this process application
   * @param {Object | undefined} [params.mainProcessFile]
   * @return {Promise}
   */
  createProcessApplication({ projectId, parentId, processApplicationName, defaultDevClusterId, mainProcessFile }) {
    const mainProcessContentToUse = mainProcessFile
      ? mainProcessFile.content
      : new FileMapper(BPMN).generate({
          name: processApplicationName
        }).content;

    const payload = {
      name: processApplicationName,
      defaultDevClusterId,
      parentId,
      projectId,
      mainProcessContent: mainProcessContentToUse
    };
    return this.post(`/internal-api/process-applications`, payload);
  }

  /**
   * Updates the definition of the stages for the given process application
   *
   * @param {String} processApplicationId
   * @param {Object} payload
   * @return {Promise}
   */
  updateStages(processApplicationId, payload) {
    return this.put(`/internal-api/process-applications/${processApplicationId}/stages`, payload);
  }

  /**
   * Returns the details related to the given process application
   *
   * @param {String} processApplicationId
   * @return {Promise<ProcessApplication>}
   */
  fetchProcessApplication(processApplicationId) {
    return this.get(`/internal-api/process-applications/${processApplicationId}`);
  }

  /**
   * Retrieves the external resources for the given process
   * @param {String} processApplicationId
   * @return {Promise}
   */
  fetchExternalResources(processApplicationId) {
    return this.get(`/internal-api/process-applications/${processApplicationId}/external-resources`);
  }

  /**
   * Retrieves the external resources for the given process application version
   * @param {String} processApplicationVersionId
   * @return {Promise}
   */
  fetchProcessApplicationVersionExternalResources(processApplicationVersionId) {
    return this.get(`/internal-api/process-application-versions/${processApplicationVersionId}/external-resources`);
  }

  /**
   * Sets the given file as main process for the process application
   * @param {String} processApplicationId
   * @param {String} fileId
   * @return {Promise}
   */
  reassignMainProcess(processApplicationId, fileId) {
    return this.patch(`/internal-api/process-applications/${processApplicationId}/main-process`, { fileId });
  }

  /**
   * Creates a process application version for the given process application
   *
   * @param {String} processApplicationId
   * @param {String} name the version name
   * @return {Promise}
   */
  createProcessApplicationVersion(processApplicationId, name) {
    return this.post(`/internal-api/process-application-versions`, { name, processApplicationId });
  }

  /**
   * Fetches the process application versions for the given process application
   *
   * @param {String} processApplicationId
   * @return {Promise<[ProcessApplicationVersionListEntryDto]>}
   */
  fetchProcessApplicationVersions(processApplicationId) {
    return this.get(`/internal-api/process-applications/${processApplicationId}/process-application-versions`);
  }

  /**
   * Fetches the process application version for the given version id
   *
   * @param {String} versionId
   * @return {Promise<ProcessApplicationVersion>}
   */
  fetchProcessApplicationVersion(versionId) {
    return this.get(`/internal-api/process-application-versions/${versionId}`);
  }

  /**
   * Rename a process application version
   *
   * @param {String} processApplicationVersionId
   * @param {String} name
   * @return {Promise}
   */
  renameProcessApplicationVersion(processApplicationVersionId, name) {
    return this.patch(`/internal-api/process-application-versions/${processApplicationVersionId}`, { name });
  }
}

export default BaseProcessApplicationService;
