/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import {
  lightGreen,
  lightOrange,
  lightRed,
  diffingAddedStyles,
  diffingChangedStyles,
  diffingRemovedStyles
} from 'utils/diffing';

import { highlightSelectedFlowElement } from './Sidebar/BPMNChangeList';

export const Container = styled.div`
  display: flex;
  flex: 1;

  .added {
    background-color: ${lightGreen};
  }
  .changed {
    background-color: ${lightOrange};
  }

  .removed {
    background-color: ${lightRed};
  }

  .rounded {
    border-radius: 50%;
    padding: 6px;
    color: white;
    width: 28px;
    height: 28px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    border: 2px solid white;
  }

  ${diffingAddedStyles};
  ${diffingChangedStyles};
  ${diffingRemovedStyles};
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TabPanels = styled.div`
  display: flex;
  flex: 1;

  ${highlightSelectedFlowElement}
`;

export const TabPanel = styled.div`
  display: flex;
  flex: 1;
`;

export const CanvasBottomRight = styled.div``;

export const FeedbackButtonSlot = styled.div`
  position: absolute;
  right: 0;
  bottom: 32px;
`;
