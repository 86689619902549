/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import * as Styled from './SidePanelComponents.styled';

export const SidePanelEntry = ({ title, headerActions = null, children }) => (
  <>
    <Styled.SidePanelHeaderContainer>
      <Styled.SidePanelHeaderContent>
        <Styled.SidePanelHeader>{title}</Styled.SidePanelHeader>
        {headerActions}
      </Styled.SidePanelHeaderContent>
    </Styled.SidePanelHeaderContainer>
    <Styled.SidePanelEntryContainer>{children}</Styled.SidePanelEntryContainer>
  </>
);
