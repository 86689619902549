/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import parseDiagramsToDefinitions from './parse-diagrams-to-definitions';
import diffModdleDefinitions from './diff-moddle-definitions';

export default async function (baseDiagram, topDiagram) {
  if (!baseDiagram) {
    throw new Error('BPMNDiff needs a baseDiagram to make a diff against.');
  }
  if (!topDiagram) {
    throw new Error('BPMNDiff needs a topDiagram to make a diff against.');
  }

  const [baseDefinitions, topDefinitions] = await parseDiagramsToDefinitions([baseDiagram, topDiagram]);
  return diffModdleDefinitions(baseDefinitions, topDefinitions);
}
