/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import callingProcessesStore from './CallingProcessesStore';

function CallingProcessesExtension(eventBus) {
  eventBus.on(['selection.changed'], (event) => {
    const element = event.newSelection[0];
    callingProcessesStore.setSelectedElement(element);
  });
}

CallingProcessesExtension.$inject = ['eventBus'];

export default {
  __init__: ['callingProcessesExtension'],
  callingProcessesExtension: ['type', CallingProcessesExtension]
};
