/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

/**
 * For users with only permission to view properties, the design mode is not shown
 * Automatically switch to validate mode (if design was selected by default)
 */
export default function useSwitchToAvailableModeForUserWithPermissionToOnlyReadProperties(
  hasPermissionToOnlyViewProperties,
  isFormPlaygroundReady,
  mode,
  switchMode
) {
  useEffect(() => {
    if (hasPermissionToOnlyViewProperties && isFormPlaygroundReady) {
      if (mode === 'design' || mode === 'validate') {
        switchMode('validate', 'system');
      }
    }
  }, [isFormPlaygroundReady]);
}
