/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

class BaseTracingService {
  /**
   * @param {{ modelerUser: { id: String }}} _payload
   * @returns {void}
   */
  registerUser(_payload) {
    throw new Error('Not implemented');
  }

  /**
   * @returns {void}
   */
  logout = () => {
    throw new Error('Not implemented');
  };

  /**
   * @param {any} _error
   * @param {String} _customMessage
   * @returns {void}
   */
  traceError = (_error, _customMessage) => {
    throw new Error('Not implemented');
  };

  /**
   * @param {Function} _caller
   * @param {String} _message
   * @returns {void}
   */
  traceDebug = (_caller, _message) => {
    throw new Error('Not implemented');
  };
}

export default BaseTracingService;
