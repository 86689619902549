/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { User } from '@carbon/icons-react';
import PropTypes from 'prop-types';

import { Tooltip } from 'primitives';
import { Star, InvitedCollaborator } from 'icons';
import { getColorForName, isColorDark } from 'utils/color-functions';
import { getInitialsForName } from 'utils/helpers';

import * as Styled from './Avatar.styled';

export default function Avatar({
  size = 'small',
  hasStar = false,
  isInvited = false,
  isPending,
  fullname,
  tooltip = null,
  disableTooltip = false,
  ...props
}) {
  if (isInvited) {
    return <InvitedCollaborator />;
  }

  if (isPending) {
    return (
      <Styled.PendingIcon>
        <User />
      </Styled.PendingIcon>
    );
  }

  const initials = getInitialsForName(fullname);
  const color = getColorForName(fullname);

  return (
    <Tooltip title={tooltip || fullname} disabled={disableTooltip}>
      <Styled.Wrapper
        size={size}
        $backgroundColor={color}
        $isTextDark={isColorDark(color)}
        data-test={`avatar-${fullname}`}
        {...props}
      >
        {initials}
        {hasStar && <Star />}
      </Styled.Wrapper>
    </Tooltip>
  );
}

Avatar.propTypes = {
  size: PropTypes.oneOf(['small', 'big']),
  fullname: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disableTooltip: PropTypes.bool,
  tooltip: PropTypes.node,
  hasStar: PropTypes.bool,
  isInvited: PropTypes.bool
};
