/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import detectConnectorsStore from './DetectConnectorsStore';
import { convertConnectorId } from './utils';

export default function DetectConnectors(eventBus, elementRegistry) {
  eventBus.on('canvas.destroy', () => {
    detectConnectorsStore.setConnectors([]);
    detectConnectorsStore.setConnectorElements([]);
  });

  eventBus.on('import.done', () => {
    getConnectors();
  });

  eventBus.on('commandStack.changed', () => {
    getConnectors();
  });

  const getConnectors = () => {
    const connectors = [];
    const connectorElements = [];
    elementRegistry.forEach((el) => {
      let connector = null;
      if (getBusinessObject(el)?.hasOwnProperty('modelerTemplate')) {
        connector = getBusinessObject(el)?.modelerTemplate;
      } else if (getBusinessObject(el)?.$attrs?.hasOwnProperty('zeebe:modelerTemplate')) {
        connector = getBusinessObject(el)?.$attrs['zeebe:modelerTemplate'];
      }

      connector = convertConnectorId(connector);

      if (connector && !connectors.includes(connector)) {
        connectorElements.push(el);
        connectors.push(connector);
      }
    });
    detectConnectorsStore.setConnectors(connectors);
    detectConnectorsStore.setConnectorElements(connectorElements);
  };
}

DetectConnectors.$inject = ['eventBus', 'elementRegistry'];
