/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import map from 'lodash/fp/map';
import compact from 'lodash/fp/compact';
import fromPairs from 'lodash/fp/fromPairs';
import flow from 'lodash/fp/flow';

export function getSearchParams(params) {
  const searchParams = params || location.search;

  return flow(
    map((item) => {
      if (item) {
        return item.split('=');
      }
    }),
    compact,
    fromPairs
  )(searchParams.slice(1).split('&'));
}

export function replaceSearchParams(history, params) {
  const url = `${location.pathname}${params}`;

  // the state has to be changed via the `history` prop,
  // otherwise the change won't be observed by the React Router when listening on history
  history.replace(url);
}

export function getUrlParam(key) {
  var url = new URL(window.location.href);
  return url.searchParams.get(key);
}
