/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

// The `!important` modifier for the `$isActive` state is needed
// because we need to overwrite the Entity List's behavior of hiding
// buttons when the row is not focused or hovered over.
export const IconButton = styled.button`
  border-radius: ${({ $noRadius }) => ($noRadius ? 'inherit' : '50%')};
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: inherit;

  &::-moz-focus-inner {
    border: 0;
  }

  &:not([disabled]):hover {
    background-color: rgba(0, 0, 0, 0.07);
  }

  &:not([disabled]):active {
    background-color: rgba(0, 0, 0, 0.16);
  }

  &[disabled] {
    color: var(--grey-lighten-56);
    cursor: not-allowed;
  }

  ${({ $isActive, isHover }) => {
    if ($isActive) {
      return `
      background-color: rgba(0, 0, 0, 0.16);
      opacity: 1 !important;

      &:hover {
        background-color: rgba(0, 0, 0, 0.16);
      }
    `;
    }

    if (isHover) {
      return 'background-color: rgba(0, 0, 0, 0.07);';
    }
  }}

  ${({ size }) => {
    if (size == 'small') {
      return `
        height: 30px;
        width: 30px;
      `;
    }

    return `
      height: 36px;
      width: 36px;
    `;
  }}
`;
