/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { getEntityVersionsLink, getPageTitle, isContainedInProcessApplication } from 'utils/helpers';
import { processApplicationStore } from 'stores';
import { MarkdownEditor, MARKDOWN_VIEW_MODES } from 'features/markdown-file-handling/index.js';
import getIdFromSlug from 'utils/getIdFromSlug';
import { exportReadme } from 'utils/file-io/export-readme.js';
import { FILE_TYPE_NAME_MAPPING, README } from 'utils/constants.js';

import { Header } from './features/header/index.js';
import { ActionBar } from './features/action-bar/index.js';
import { ReadmeEditorStore } from './features/state-management/index.js';
import { useReadmeBreadcrumbs } from './features/breadcrumbs/useReadmeBreadcrumbs.js';
/**
 * @typedef {Object} ReadmeParams
 * @property {string} slug
 */

export const Readme = observer(function Readme() {
  /** @type {ReadmeParams} */
  const { slug } = useParams();
  const history = useHistory();
  const readmeEditorStore = useMemo(() => new ReadmeEditorStore(getIdFromSlug(slug), history), [slug, history]);

  const { readme, loading, isReadOnly, canViewVersions, status, updateReadmeContent } = readmeEditorStore;
  const [selectedMode, setSelectedMode] = useState(MARKDOWN_VIEW_MODES.EDITOR);

  const modeOptions = useMemo(
    () => [
      { value: MARKDOWN_VIEW_MODES.EDITOR, label: isReadOnly ? 'File' : 'Edit' },
      { value: MARKDOWN_VIEW_MODES.PREVIEW, label: 'Preview' }
    ],
    [isReadOnly]
  );

  const breadcrumbs = useReadmeBreadcrumbs(readmeEditorStore);

  const onViewVersion = useMemo(() => {
    if (!canViewVersions) {
      return null;
    }

    if (isContainedInProcessApplication(readme)) {
      return () => history.push(`/process-applications/${processApplicationStore.processApplication.id}/versions`);
    }

    return () => history.push(getEntityVersionsLink(readme));
  }, [canViewVersions, history, readme]);

  const onExport = useCallback(() => {
    exportReadme(readme);
  }, [readme]);

  useEffect(() => {
    (async () => {
      if (readme && isContainedInProcessApplication(readme)) {
        await processApplicationStore.init(readme.folderId, true);
      }
    })();

    return () => processApplicationStore.reset();
  }, [readme]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(readme?.name)}</title>
      </Helmet>
      <Header loading={loading} breadcrumbs={breadcrumbs} />
      <ActionBar
        fileTypeName={FILE_TYPE_NAME_MAPPING[README]}
        loading={loading}
        status={status}
        selectedMode={selectedMode}
        isReadOnly={isReadOnly}
        onViewVersion={onViewVersion}
        onExport={onExport}
        onModeChange={setSelectedMode}
        modeOptions={modeOptions}
      />
      {!loading && readme && (
        <MarkdownEditor
          content={readme.content}
          onChange={updateReadmeContent}
          readOnly={isReadOnly}
          viewMode={selectedMode}
        />
      )}
    </>
  );
});
