/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component, Fragment } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Chip, Input } from 'primitives';

import * as Styled from './MultiSelectInput.styled';
import UserNotFound from './UserNotFound';
import {
  deleteItem,
  getItem,
  getSuggestionSubtitle,
  handlePaste,
  isAllowedToAddSuggestion,
  isSuggestionAlreadyAdded,
  renderItems
} from './lib';

class MultiSelectInput extends Component {
  value = '';
  error = null;
  suggestionsVisible = false;
  items = this.props.dataSource;
  nodes = new Map();

  constructor(props) {
    super(props);

    makeObservable(this, {
      value: observable,
      error: observable,
      suggestionsVisible: observable,
      items: observable,
      handleChange: action,
      handleKeyDown: action,
      handleDelete: action,
      handleBlur: action,
      handleFocus: action,
      handleSuggestionsSelect: action,
      filteredSuggestions: computed,
      validateAndAdd: action
    });
  }

  /**
   * Sets a ref to a suggestion element.
   *
   * @param {HTMLElement} node The HTML node
   * @param {String} key A unique identifier
   */
  setRef(node, key) {
    this.nodes.set(key, node);
  }

  /**
   * Keeps track of the input value for the email address, since
   * it's a controlled component.
   *
   * @param {KeyboardEvent} evt The triggered event.
   */
  handleChange = (evt) => {
    this.error = null;
    this.value = evt.target.value;

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.value);
    }
  };

  /**
   * If the user presses `tab`, `enter` or a comma, the
   * content of the input field will be validated and if validation
   * succeeds it will be added to the list of invitees.
   *
   * The input value will be resetted afterward.
   *
   * @param {KeyboardEvent} evt The triggered event.
   */
  handleKeyDown = (evt) => {
    if (['ArrowDown', 'ArrowUp'].includes(evt.code)) {
      this.handleSuggestionsNavigation(evt);

      return;
    }

    if (this.props.suggestions && 'Enter' === evt.code) {
      const currentIndex = this.filteredSuggestions.findIndex((i) => i.isSelected);

      if (currentIndex !== -1) {
        this.filteredSuggestions[currentIndex].isSelected = false;

        this.handleSuggestionsSelect(this.filteredSuggestions[currentIndex]);
      }
    }

    if (['Comma', 'Tab', 'Enter', 'Space'].includes(evt.code) && !this.props.organizationMembersOnly) {
      const value = this.value.trim();

      if (!value) {
        return;
      }

      evt.preventDefault();

      this.validateAndAdd(value);
    }
  };

  /**
   * Removes an invitee from the list.
   *
   * @param {String} toBeRemoved The email address of the invitee to be removed.
   */
  handleDelete = (toBeRemoved) => {
    deleteItem(this.items, toBeRemoved);
  };

  /**
   * Hides the suggestions once the user has left
   * the input field.
   */
  handleBlur = (event) => {
    if (!event?.defaultPrevented) {
      this.suggestionsVisible = false;
    }
    if (this.value !== '' && !this.props.organizationMembersOnly) {
      this.validateAndAdd(this.value);
    }
  };

  /**
   * Shows the suggestions once the user has entered
   * the input field.
   */
  handleFocus = () => {
    if (this.props.suggestions) {
      this.suggestionsVisible = true;
    }
  };

  /**
   * Adds an invitee to the list after a user has selected
   * him from the suggestions list.
   *
   * @param {String} email The email to add to the list.
   */
  handleSuggestionsSelect = ({ email, id, name }) => {
    const item = getItem(email, id, name);
    if (!item) {
      return;
    }
    this.items.push(item);

    this.value = '';
    this.error = null;
  };

  /**
   * If a user presses the Arrow Down or Arrow Up key,
   * it will select the next or previous suggestion in the list
   * if available.
   *
   * @param {KeyboardEvent} evt The triggered event.
   */
  handleSuggestionsNavigation = (evt) => {
    if (this.filteredSuggestions.length === 0) {
      return;
    }

    evt.preventDefault();

    const currentIndex = this.filteredSuggestions.findIndex((suggestion) => suggestion.isSelected);
    let nextIndex = currentIndex;

    if (currentIndex === -1) {
      this.filteredSuggestions[0].isSelected = true;
    } else {
      const nextSuggestion = this.filteredSuggestions[evt.code === 'ArrowDown' ? ++nextIndex : --nextIndex];

      if (nextSuggestion) {
        this.filteredSuggestions[currentIndex].isSelected = false;

        nextSuggestion.isSelected = true;

        this.nodes.get(nextSuggestion.email).scrollIntoView({
          block: 'nearest'
        });
      }
    }
  };

  /**
   * Returns a list of possible suggestions, based on
   * what the user has selected beforehand.
   *
   * @return {Array} An array of user objects.
   */
  get filteredSuggestions() {
    if (this.props.suggestions) {
      return this.props.suggestions.filter((suggestion) => {
        return !isSuggestionAlreadyAdded(suggestion, this.items) && this.find(suggestion) && this.value !== '';
      });
    }

    return [];
  }

  /**
   * Compares the current input value with a given user object and returns
   * `true` if either of those matches.
   *
   * @param {Object} user The user object.
   * @return {Boolean}
   */
  find({ email, name = '' }) {
    const search = this.value.toLowerCase();

    return email?.toLowerCase().includes(search) || name.toLowerCase().includes(search);
  }

  /**
   * Validates the current email input and if valid adds
   * it into the list of invitees.
   */
  validateAndAdd(value) {
    const error = this.props.onValidate(value);

    if (!error) {
      this.items.push(value);

      this.value = '';
      this.error = null;
    } else {
      this.error = error;
    }
  }

  render() {
    return (
      <Fragment>
        {renderItems(this.items, Chip, this.handleDelete)}

        {this.props.warningComponent && <>{this.props.warningComponent}</>}

        <Styled.Grid>
          <Input
            value={this.value}
            error={Boolean(this.error)}
            errorMessage={this.error}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onPaste={(e) => handlePaste(e, this.validateAndAdd.bind(this), this.props.isUsedInShare)}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            placeholder={this.props.placeholder}
            data-test={this.props['data-test'] || 'multi-select-input'}
            disabled={this.props.maxLength && this.items.length >= this.props.maxLength}
          />

          {this.props.action && <Styled.Action>{this.props.action}</Styled.Action>}

          {this.suggestionsVisible && (
            <Styled.UserSuggestions elevation={2} data-test="user-suggestions">
              {this.filteredSuggestions.map((suggestion, index) => {
                const subtitle = getSuggestionSubtitle(suggestion);
                const nodeKey = `${suggestion.email ?? suggestion.username}-${index}`;
                return (
                  <div ref={(node) => this.setRef(node, subtitle)} key={nodeKey}>
                    <Styled.CollaboratorItem
                      username={suggestion.name}
                      displayName={suggestion.name}
                      subtitle={subtitle}
                      id={suggestion.id}
                      isSelected={suggestion.isSelected}
                      isCursorNotAllowed={!isAllowedToAddSuggestion(suggestion.email)}
                      onMouseDown={(event) => {
                        event?.preventDefault();
                        this.handleSuggestionsSelect(suggestion);
                      }}
                    />
                  </div>
                );
              })}

              {this.filteredSuggestions.length === 0 && this.props.organizationMembersOnly && this.value !== '' && (
                <UserNotFound />
              )}
            </Styled.UserSuggestions>
          )}
        </Styled.Grid>
      </Fragment>
    );
  }
}

MultiSelectInput.propTypes = {
  placeholder: PropTypes.string,
  onValidate: PropTypes.func,
  onChange: PropTypes.func,
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  maxLength: PropTypes.number,
  organizationMembersOnly: PropTypes.bool,
  isUsedInShare: PropTypes.bool,
  warningComponent: PropTypes.node
};

MultiSelectInput.defaultProps = {
  dataSource: [],
  suggestions: false,
  placeholder: 'Enter an email address...',
  onValidate: () => false,
  onChange: () => false,
  organizationMembersOnly: false,
  isUsedInShare: false
};

export default observer(MultiSelectInput);
