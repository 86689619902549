/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import moment from 'moment';
import { Button, InlineLoading } from '@carbon/react';

import ActionButton from 'App/Pages/Home/ActionButton';
import getBatchActions from 'App/Pages/Home/BatchActions';
import { EmptyState } from 'primitives';
import { homeStore } from 'stores';
import { LinkRenderer } from 'components/EntityTable/CellRenderer';
import EntityAction from 'App/Pages/Home/EntityAction';
import { getCollaborators, getItemsCount } from 'App/Pages/Home/utils';
import buildSlug from 'utils/buildSlug';
import { EntityTable } from 'components';

import * as Styled from './ProjectsTable.styled';

const ProjectsTable = ({ projects = [], isCreatingProject = false, projectsLoading = false }) => {
  return (
    <EntityTable
      // @ts-expect-error TS2741
      action={({ tabIndex }) => projects.length > 0 && <ActionButton tabIndex={tabIndex} />}
      // @ts-expect-error TS2322
      batchActions={getBatchActions()}
      // @ts-expect-error TS2741
      emptyState={
        <EmptyState
          icon={<Styled.EmptyStateIcon />}
          title="Start by creating a project"
          description="Click Create new project to organize and share your diagrams."
          action={
            isCreatingProject ? (
              <InlineLoading description="Creating project..." status="active" />
            ) : (
              <Button onClick={() => homeStore.createProject()}>Create new project</Button>
            )
          }
          link={
            <a href="https://docs.camunda.io/docs/guides/" target="_blank" rel="noreferrer">
              View the getting started guide
            </a>
          }
        />
      }
      columns={[
        { key: 'name', header: 'Name', renderer: LinkRenderer, sortable: true },
        { key: 'content', header: 'Content', width: '120px', sortable: true },
        {
          key: 'lastChanged',
          header: 'Last changed',
          width: '200px',
          sortable: true
        },
        {
          key: 'collaborators',
          header: 'Collaborators',
          width: '200px',
          renderer: (AvatarList) => {
            return <Styled.CollaboratorsWrapper>{AvatarList}</Styled.CollaboratorsWrapper>;
          }
        },
        {
          key: 'entityAction',
          header: '',
          width: '50px',
          noPadding: true,
          renderer: (project) => <EntityAction project={project} />
        }
      ]}
      isLoading={projectsLoading}
      title={''}
      rows={projects.map((project) => ({
        id: project.id,
        content: getItemsCount(project),
        collaborators: getCollaborators(project),
        name: {
          value: project.name,
          link: `/projects/${buildSlug(project.id, project.name)}`
        },
        lastChanged: moment(project.lastModified).format('YYYY-MM-DD HH:mm'),
        entityAction: project
      }))}
    />
  );
};

export default ProjectsTable;
