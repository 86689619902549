/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('features/view-file/types').FileSelection} FileSelection
 */

/**
 * @typedef {(
 *   fileOrMilestoneId: string,
 * ) => void} SelectFileFunction
 */

import { useEffect, useState } from 'react';

import { fileService, milestoneService } from 'services';

const FETCH_FAILED_MESSAGE = 'Failed to load data for the selected file!';

/**
 * Custom hook to manage selected file state.
 *
 * It returns an object (fileSelection) that includes the details of the current selected milestone file and its
 * previous version.
 *
 * @param {string} selectedMilestoneId - The ID of the currently selected milestone.
 * @param {Array<Object>} versions - List of available versions for the IDP project
 * @param {Function} startLoading
 * @param {Function} stopLoading
 *
 * @returns {{
 *   milestoneFileSelection: FileSelection|null, // The details and content of the selected file and its previous version.
 *   selectFile: SelectFileFunction // Function to set fileSelection based on the file or milestone ID passed.
 * }}
 */
export default function useMilestoneFileSelection(selectedMilestoneId, versions, startLoading, stopLoading) {
  /**
   * @type {[FileSelection|null, Function]}
   */
  const [milestoneFileSelection, setMilestoneFileSelection] = useState(null);

  useEffect(() => {
    if (selectedMilestoneId) {
      (async () => {
        startLoading();
        try {
          await selectFile(selectedMilestoneId);
        } catch {
          console.error(FETCH_FAILED_MESSAGE);
          stopLoading();
        }
      })();
    }
  }, [selectedMilestoneId, versions]);

  /**
   * When selecting a file in a saved version,
   *  - the current version is found from the milestone associated with the selected saved version,
   *  - the previous version is found from the milestone associated with the previous saved version.
   *
   */
  const selectFile = async (milestoneId) => {
    try {
      /** @type {FileSelection} */
      const selection = {
        current: null,
        previous: null,
        fileId: '',
        fileType: ''
      };

      // Get file content from selected version
      const milestoneDetails = await milestoneService.fetchById(milestoneId);
      selection.current = {
        versionName: milestoneDetails.name,
        content: milestoneDetails.content
      };

      // Get file id and type
      const fileId = milestoneDetails.fileId;
      const file = await fileService.fetchById(milestoneDetails.fileId);
      selection.fileType = file.type;
      selection.fileId = fileId;

      // Get file content from previous version
      const selectedMilestoneIndexIndex = versions.findIndex((version) => version.milestoneId === selectedMilestoneId);
      const isPreviousVersionPresent = selectedMilestoneIndexIndex + 1 < versions.length;
      if (isPreviousVersionPresent) {
        const previousVersionId = versions[selectedMilestoneIndexIndex + 1].milestoneId;
        if (previousVersionId) {
          // Previous version present for this file
          const previousMilestoneDetails = await milestoneService.fetchById(previousVersionId);
          selection.previous = {
            versionName: previousMilestoneDetails.name,
            content: previousMilestoneDetails.content
          };

          selection.previous.fileName = previousMilestoneDetails.name;
        }
      }

      setMilestoneFileSelection(selection);
    } catch {
      console.error(FETCH_FAILED_MESSAGE);
    } finally {
      stopLoading();
    }
  };

  return { milestoneFileSelection, selectFile };
}
