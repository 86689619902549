/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

class InvitationService extends Service {
  /**
   * Fetches all user invitation suggestions for a project, based on
   * the current organization.
   *
   * @returns {Promise}
   */
  fetchInvitationSuggestions({ organizationId, filter = 'all' }) {
    return this.get(`/cloud/accounts/organizations/${organizationId}/members?filter=${filter}&useCache=false`);
  }

  async resendInvitation(organizationId, collaborator) {
    const members = await this.fetchInvitationSuggestions({
      organizationId,
      filter: 'invites'
    });
    const inviteId = members?.filter((invite) => invite.email === collaborator.email)[0]?.invite?.inviteId;

    if (!inviteId) {
      throw new Error('Cannot find the invite Id for the given collaborator and organization');
    }

    return this.post(`/cloud/accounts/organizations/${organizationId}/invites/${inviteId}/resend`);
  }

  inviteExternalUser(organizationId, emailAddress) {
    return this.post(`/cloud/accounts/organizations/${organizationId}/invites`, {
      orgRoles: ['developer', 'analyst'],
      email: emailAddress
    });
  }
}

export default new InvitationService();
