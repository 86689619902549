/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import parseValidateAndAddEmails from 'components/MultiSelectInput/lib/parse-validate-and-add-emails';

/**
 * If the user pastes data into the email field, the clipboard
 * data will get parsed and if there are any valid email addresses,
 * they will copied into an array and added into the list
 * of invitees.
 *
 * @param {Event} evt The clipboard event.
 */
export default function handlePaste(evt, validateAndAdd) {
  parseValidateAndAddEmails(evt, validateAndAdd);
}
