/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, makeObservable, observable } from 'mobx';
import isEqual from 'lodash/isEqual';

import { performLinting as performDiagramUtilLinting } from 'App/Pages/Diagram/lint-util';

export class LintingStore {
  lintErrors = [];
  runtimeErrors = [];

  constructor() {
    makeObservable(this, {
      runtimeErrors: observable,
      lintErrors: observable,
      reset: action,
      setLintErrors: action,
      setRuntimeErrors: action
    });
  }

  reset = () => {
    this.runtimeErrors = [];
    this.lintErrors = [];
  };

  setLintErrors = (arr) => {
    this.lintErrors = arr;
  };

  setRuntimeErrors = (arr) => {
    this.runtimeErrors = arr;
  };

  performRuntimeLint = async (modeler, isImplementMode, inboundConnectorHealthData) => {
    try {
      const newRuntimeErrors =
        inboundConnectorHealthData
          ?.filter((data) => data?.health?.status === 'DOWN' && data?.health?.error?.message)
          ?.map((data) => ({
            category: 'error',
            documentation: { url: null },
            executionPlatform: 'Camunda Cloud',
            id: data?.elementId,
            message: data?.health?.error?.message,
            name: undefined,
            rule: 'element-templates/validate'
          }))
          ?.sort((a, b) => a.id.localeCompare(b.id)) || [];

      // Check if newRuntimeErrors are different from the current runtimeErrors
      if (!isEqual(this.runtimeErrors, newRuntimeErrors)) {
        this.setRuntimeErrors(newRuntimeErrors);

        /*
         * Each time we perform the connector polling,
         * we eventually update the problems panel with added/removed/updated runtime errors.
         * There is no need to call the Camunda linting library.
         */
        const skipDiagramUtilLinting = true;
        await this.performLinting(modeler, isImplementMode, skipDiagramUtilLinting);
      }
    } catch (err) {
      console.error('Error in performRuntimeLint:', err);
    }
  };

  /**
   * It performs linting, considering also runtime errors, and returns all linting errors
   * @param {object} modeler
   * @param {boolean} isImplementMode
   * @param {boolean} skipDiagramUtilLinting
   * @return {Promise<Array>}
   */
  performLinting = async (modeler, isImplementMode, skipDiagramUtilLinting = false) => {
    try {
      const elementRegistry = modeler?.get('elementRegistry');
      const lintErrors = skipDiagramUtilLinting
        ? this.lintErrors.filter((error) => !this.runtimeErrors.some((runtimeError) => runtimeError.id === error.id))
        : await performDiagramUtilLinting(modeler, isImplementMode);

      let allLintErrors = lintErrors;
      if (isImplementMode) {
        const runtimeErrorsForAvailableElements = this.runtimeErrors?.filter((runtimeError) =>
          elementRegistry?.get(runtimeError.id)
        );
        allLintErrors = lintErrors.concat(runtimeErrorsForAvailableElements);
      }

      this.setLintErrors(allLintErrors);

      const linting = modeler.get('linting', false);

      if (linting) {
        const canvasLintErrors = allLintErrors.filter((e) => e.category !== 'info');

        linting.setErrors(canvasLintErrors);
        linting.activate();
      }

      return allLintErrors;
    } catch (err) {
      console.error('Error in performLinting:', err);
    }
  };
}

export default new LintingStore();
