/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button as PureButton } from 'primitives';
import { InlineEditable } from 'components/Form';

const itemFontSize = '14px';

export const Button = styled(PureButton)`
  display: inline-block;
`;

export const Editable = styled(InlineEditable)`
  font-size: ${itemFontSize};
  max-width: 250px;

  div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input {
    width: 100%;
  }
`;

export const LinkCrumb = styled(Link)`
  font-size: ${itemFontSize};
  text-decoration: none;
  font-weight: 500;
  color: var(--grey-lighten-56);
`;

export const Text = styled.p`
  font-size: ${itemFontSize};
  color: var(--grey-darken-33);
  margin: 0 var(--spacing-small) 0 0;
  ${({ $isBold }) => $isBold && `font-weight: 500`};
`;
