/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Redo, Undo } from '@carbon/icons-react';
import ReactDOMServer from 'react-dom/server';

import { BPMN_EVENTS_PRIORITY } from 'utils/constants';

import style from './undoRedoButtons.css';

export default function UndoRedoButtons(eventBus, commandStack) {
  const TOP_OFFSET = 25;

  /**
   * @typedef {Object} ButtonProps
   * @property {string} id - The unique identifier for the button.
   * @property {string} label - The label for the button, used for accessibility.
   * @property {JSX.Element} icon - The icon component to be rendered inside the button.
   * @property {Function} action - The function to be called when the button is clicked.
   */
  const BUTTONS = {
    UNDO: {
      id: 'undo',
      label: 'Undo',
      icon: <Undo />,
      action: () => commandStack.undo()
    },
    REDO: {
      id: 'redo',
      label: 'Redo',
      icon: <Redo />,
      action: () => commandStack.redo()
    }
  };

  let paletteEl;
  let parentEl;
  const containerId = 'undo-redo-buttons';
  const containerEl = document.createElement('div');
  const undoButton = document.createElement('button');
  const redoButton = document.createElement('button');
  let positionObserver;
  let isPaletteTwoColumn = false;

  const init = () => {
    insertCSS(containerId, style);
    registerEventBusListeners();
  };

  const registerEventBusListeners = () => {
    eventBus.on('palette.create', BPMN_EVENTS_PRIORITY.LOW, onPaletteCreate);
    eventBus.on('palette.changed', BPMN_EVENTS_PRIORITY.LOW, onPaletteUpdate);
    eventBus.on('commandStack.changed', BPMN_EVENTS_PRIORITY.LOW, onCommandStackChange);
    eventBus.on('import.done', BPMN_EVENTS_PRIORITY.HIGH, resetButtons);
    eventBus.on('diagram.destroy', BPMN_EVENTS_PRIORITY.HIGH, unregisterEventBusListeners);
  };

  const unregisterEventBusListeners = () => {
    eventBus.off('palette.create', onPaletteCreate);
    eventBus.off('palette.changed', onPaletteUpdate);
    eventBus.off('commandStack.changed', onCommandStackChange);
    eventBus.off('import.done', resetButtons);
    eventBus.off('diagram.destroy', unregisterEventBusListeners);
    unregisterPositionObserver();
  };

  const onPaletteCreate = (event) => {
    const { container } = event;

    paletteEl = container;
    parentEl = container.parentElement;

    registerPositionObserver();

    removeButtons();

    renderContainer()
      .then(() => {
        renderButton(BUTTONS.UNDO);
        renderButton(BUTTONS.REDO);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onPaletteUpdate = (event) => {
    const { open, twoColumn } = event;
    const isHidden = isPaletteHidden() || !open;
    isPaletteTwoColumn = twoColumn;

    toggleButtonsVisibility(!isHidden);
  };

  const onCommandStackChange = () => {
    const canUndo = commandStack.canUndo();
    const canRedo = commandStack.canRedo();

    toggleElementDisabled(undoButton, !canUndo);
    toggleElementDisabled(redoButton, !canRedo);
  };

  const registerPositionObserver = () => {
    if (!positionObserver) {
      positionObserver = new MutationObserver(stickContainerToPalette);

      positionObserver.observe(paletteEl, {
        attributes: true,
        childList: true,
        subtree: true
      });
    }
  };

  const unregisterPositionObserver = () => {
    positionObserver?.disconnect();
    positionObserver = null;
  };

  const removeButtons = () => {
    containerEl?.remove();
  };

  const resetButtons = () => {
    toggleElementDisabled(undoButton, true);
    toggleElementDisabled(redoButton, true);
  };

  const toggleButtonsVisibility = (visible) => {
    containerEl.style.visibility = visible ? 'visible' : 'hidden';
  };

  const renderContainer = () => {
    return new Promise((resolve, reject) => {
      if (!parentEl) {
        reject(new Error('Parent element not found'));
      }

      containerEl.id = containerId;
      parentEl.appendChild(containerEl);

      resolve();
    });
  };

  const stickContainerToPalette = () => {
    containerEl.classList.toggle('two-columns', isPaletteTwoColumn);

    const { x, height } = paletteEl.getBoundingClientRect();
    const top = height + TOP_OFFSET;
    const left = x;

    containerEl.style.top = `${top}px`;
    containerEl.style.left = `${left}px`;
  };

  /**
   * Renders a button with the given properties
   * @param {ButtonProps} buttonProps - The properties for the button to be rendered.
   */
  const renderButton = (buttonProps) => {
    const buttonEl = buttonProps.id === BUTTONS.UNDO.id ? undoButton : redoButton;

    buttonEl.id = `${buttonProps.id}-button`;
    buttonEl.innerHTML = ReactDOMServer.renderToString(buttonProps.icon);
    buttonEl.title = buttonProps.label;
    buttonEl.ariaLabel = buttonProps.label;
    buttonEl.role = 'button';
    buttonEl.onclick = buttonProps.action;

    containerEl.appendChild(buttonEl);
    toggleElementDisabled(buttonEl, true);
  };

  const toggleElementDisabled = (el, disabled) => {
    el.disabled = disabled;
  };

  const isPaletteHidden = () => {
    const style = window.getComputedStyle(paletteEl);
    return style.display === 'none';
  };

  init();
}

function insertCSS(name, css) {
  const id = `style-${name}`;

  if (document.querySelector(`[data-css-file="${id}"]`)) {
    return;
  }

  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');

  style.setAttribute('data-css-file', id);

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  head.appendChild(style);
}

UndoRedoButtons.$inject = ['eventBus', 'commandStack'];
