/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { processApplicationStore } from 'stores';
import { callActivityLinkStore } from 'App/Pages/Diagram/CallActivityLinking';
import { businessRuleTaskLinkStore } from 'App/Pages/Diagram/BusinessRuleTaskLinking';

import { LinkedFormsList } from './LinkedFormsList';
import * as Styled from './DeployLinkedDiagramInfo.styled';

const DeployLinkedDiagramInfo = () => {
  const processApplicationHasExternalResources =
    processApplicationStore?.hasExternalCallActivityLinks ||
    processApplicationStore?.hasExternalBusinessRuleTaskLinks ||
    processApplicationStore?.externalForms?.length > 0;

  const hasLinkedCallActivities = callActivityLinkStore?.linkedCallActivities?.length > 0;
  const hasLinkedBusinessRuleTasks = businessRuleTaskLinkStore?.linkedBusinessRuleTasks?.length > 0;

  const getOtherResourcesMessage = () => {
    const hasOnlyCallActivities = processApplicationStore?.fromAProcessApplication
      ? processApplicationStore?.hasExternalCallActivityLinks &&
        !processApplicationStore?.hasExternalBusinessRuleTaskLinks
      : hasLinkedCallActivities && !hasLinkedBusinessRuleTasks;
    const hasOnlyBusinessRuleTasks = processApplicationStore?.fromAProcessApplication
      ? !processApplicationStore?.hasExternalCallActivityLinks &&
        processApplicationStore?.hasExternalBusinessRuleTaskLinks
      : !hasLinkedCallActivities && hasLinkedBusinessRuleTasks;

    const target = processApplicationStore?.fromAProcessApplication ? 'process application' : 'diagram';

    let message = processApplicationHasExternalResources ? 'external ' : '';
    if (hasOnlyCallActivities) {
      message = message.concat('Call Activity');
    } else if (hasOnlyBusinessRuleTasks) {
      message = message.concat('Business Rule Task');
    } else if (hasLinkedCallActivities && hasLinkedBusinessRuleTasks) {
      message = message.concat('Call Activity and Business Rule Task');
    }

    return (
      <Styled.InfoMessage kind="info" lowContrast hideCloseButton>
        The {target} has {message} elements. They have to be&nbsp;
        <strong>deployed separately.</strong>
      </Styled.InfoMessage>
    );
  };

  return processApplicationStore?.fromAProcessApplication ? (
    <>
      {processApplicationHasExternalResources && <Styled.SectionHeader>External resources</Styled.SectionHeader>}
      {processApplicationStore?.externalForms?.length > 0 && <LinkedFormsList />}
      {(processApplicationStore?.hasExternalCallActivityLinks ||
        processApplicationStore?.hasExternalBusinessRuleTaskLinks) && (
        <Styled.OtherResources>{getOtherResourcesMessage()}</Styled.OtherResources>
      )}
    </>
  ) : (
    <>
      <LinkedFormsList />
      {(hasLinkedCallActivities || hasLinkedBusinessRuleTasks) && (
        <Styled.OtherResources>
          <Styled.SectionHeader>Other resources</Styled.SectionHeader>
          {getOtherResourcesMessage()}
        </Styled.OtherResources>
      )}
    </>
  );
};

export default observer(DeployLinkedDiagramInfo);
