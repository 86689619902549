/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';
import semver from 'semver';

const VERSION_8_4 = '8.4.0';

class IsLinkingImprovementsModalVisibleStore {
  isVisible = false;
  selectedClusterVersion = null;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.selectedClusterVersion = null;
  };

  setSelectedClusterVersion = (selectedClusterVersion) => {
    this.selectedClusterVersion = selectedClusterVersion;
  };

  show = () => {
    this.#setIsVisible(true);
  };

  hide = () => {
    this.#setIsVisible(false);
  };

  isClusterVersionLT8_4(version) {
    if (!version) {
      return false;
    }

    const coercedVersion = semver.coerce(version);
    return semver.valid(coercedVersion) && semver.lt(coercedVersion, VERSION_8_4);
  }

  #setIsVisible = (val) => {
    this.isVisible = val;
  };
}

export default new IsLinkingImprovementsModalVisibleStore();
