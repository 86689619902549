/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplication} ProcessApplication
 */

import { useEffect, useState } from 'react';

import { DRAFT_VERSION } from 'components';
import { processApplicationService } from 'services';

/**
 * Custom hook to manage the selected version state.
 *
 * It returns an array of IDs (fileOrMilestoneIds) in addition to the state getter and setter.
 *  If the draft is selected, it contains the IDs of the files currently in the process application.
 *  If a saved version is selected, it contains the IDs of the milestones present in the selected process app version.
 *
 * @param {ProcessApplication} processApplication
 * @param {Function} startLoading
 * @param {Function} stopLoading
 *
 * @returns {{
 *   selectedVersionId: string|null,
 *   setSelectedVersionId: Function,
 *   selectedVersionMainProcessId: string|null,
 *   fileOrMilestoneIds: Array<string>|null //  List of file IDs if DRAFT is selected OR milestone IDs if an already saved version is selected.
 * }} An object containing the selected version state and associated data.
 *
 */
export function useVersionSelection(processApplication, startLoading, stopLoading) {
  const processApplicationFiles = processApplication?.files;
  const mainProcessFileId = processApplication?.mainProcessFileId;

  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const [selectedVersionMainProcessId, setSelectedVersionMainProcessId] = useState(null);
  const [fileOrMilestoneIds, setFileOrMilestoneIds] = useState(null);

  useEffect(() => {
    const isDraftVersionSelected = selectedVersionId === DRAFT_VERSION.id;
    if (isDraftVersionSelected && processApplicationFiles && mainProcessFileId) {
      const fileIds = processApplicationFiles.filter((file) => file.id !== mainProcessFileId).map((file) => file.id);
      setFileOrMilestoneIds([mainProcessFileId, ...fileIds]); // Main process ID first, followed by IDs of other files
      setSelectedVersionMainProcessId(processApplication.mainProcessFileId);
    }
  }, [selectedVersionId, processApplicationFiles]);

  useEffect(() => {
    const isSavedVersionSelected = selectedVersionId && selectedVersionId !== DRAFT_VERSION.id;
    if (isSavedVersionSelected) {
      (async () => {
        try {
          startLoading();

          const version = await processApplicationService.fetchProcessApplicationVersion(selectedVersionId);
          const mainProcessMilestoneId = version.milestones.find(
            (milestone) => milestone.fileId === version.mainProcessFileId
          ).id;
          const milestoneIds = version.milestones
            .filter((milestone) => milestone.id !== mainProcessMilestoneId)
            .map((milestone) => milestone.id);

          setFileOrMilestoneIds([mainProcessMilestoneId, ...milestoneIds]); // Main process milestone ID first, followed by IDs of other milestones
          setSelectedVersionMainProcessId(version.mainProcessFileId);
        } catch {
          console.error('Failed to load data for the selected version!');
          stopLoading();
        }
      })();
    }
  }, [selectedVersionId]);

  return { selectedVersionId, setSelectedVersionId, selectedVersionMainProcessId, fileOrMilestoneIds };
}
