/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Book, Edit } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import { useHistory } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { createAndNavigateToReadme } from 'features/folder-exploring';
import { MarkdownRenderer } from 'features/markdown-file-handling';
import { EmptyState } from 'primitives';
import { getEntityLink } from 'utils/helpers';

import * as Styled from './FolderReadmeViewer.styled';

export function FolderReadmeViewer({ currentReadme, canEditReadme }) {
  const history = useHistory();

  const [isCreating, setIsCreating] = useState(false);

  const onClickCreate = useCallback(() => {
    if (isCreating) {
      return;
    }

    setIsCreating(true);

    (async () => {
      await createAndNavigateToReadme();
      setIsCreating(false);
    })();
  }, [isCreating]);

  if (!currentReadme && !canEditReadme) {
    return null;
  }

  if (!currentReadme) {
    return (
      <Styled.FolderReadmeContainer data-test="folder-readme-container">
        {/* @ts-ignore */}
        <EmptyState
          title="There is no README created yet"
          description="Use a README file to clearly document your processes"
          icon={<Book width="48" height="48" />}
          action={
            <Button onClick={onClickCreate} disabled={isCreating} kind="tertiary">
              Create a README
            </Button>
          }
          // link={
          //   <Link href="#" target="_blank" rel="noreferrer">
          //     Learn more about readmes
          //   </Link>
          // }
        />
      </Styled.FolderReadmeContainer>
    );
  }

  return (
    <Styled.FolderReadmeContainer data-test="folder-readme-container">
      <Styled.Header>
        <Styled.Title>
          <Book width="24" height="24" />
          README
        </Styled.Title>
        {canEditReadme && (
          <Button
            kind="ghost"
            size="md"
            onClick={() => history.push(getEntityLink(currentReadme))}
            renderIcon={Edit}
            iconDescription="Edit README"
          >
            <span>Edit README</span>
          </Button>
        )}
      </Styled.Header>
      <MarkdownRenderer content={currentReadme.content} />
    </Styled.FolderReadmeContainer>
  );
}
