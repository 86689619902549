/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';
import { REVIEW_STATUS } from 'App/Pages/ProcessApplicationVersions/features/review/constants';

class MockReviewService extends Service {
  /**
   * Requests changes for a given milestone with an optional comment.
   * @param {string} versionId - The ID of the milestone.
   * @param {string} comment - The comment for the review.
   * @returns {Promise<import('services/ProcessApplicationService.common').ProcessApplicationReviewDto>}
   */
  async requestChanges(versionId, comment) {
    return this.post(`/internal-api/process-application-versions/${versionId}/reviews`, {
      comment,
      status: REVIEW_STATUS.CHANGES_REQUESTED
    });
  }

  /**
   * Approves a review for a given milestone with an optional comment.
   * @param {string} versionId - The ID of the milestone.
   * @param {string} comment - The comment for the review.
   * @returns {Promise<import('services/ProcessApplicationService.common').ProcessApplicationReviewDto>}
   */
  async approve(versionId, comment) {
    return this.post(`/internal-api/process-application-versions/${versionId}/reviews`, {
      comment,
      status: REVIEW_STATUS.APPROVED
    });
  }

  /**
   * Requests a review for a given milestone.
   * @param {string} versionId - The ID of  the milestone.
   * @returns {Promise<import('services/ProcessApplicationService.common').ProcessApplicationReviewRequestDto>}
   */
  async requestReview(versionId) {
    return this.post(`/internal-api/process-application-versions/${versionId}/review-requests`);
  }
}

export default new MockReviewService();
