/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

class BaseLicenseStore {
  /**
   * @returns {number}
   */
  get expiresAt() {
    throw new Error('Not implemented');
  }

  /**
   * @returns {boolean}
   */
  get isValidLicense() {
    throw new Error('Not implemented');
  }

  /**
   * @returns {boolean}
   */
  get isCommercialLicense() {
    throw new Error('Not implemented');
  }

  /**
   * @returns {boolean}
   */
  get isDeveloperLicense() {
    throw new Error('Not implemented');
  }
}

export default BaseLicenseStore;
