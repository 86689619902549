/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { milestoneStore, organizationStore } from 'stores';
import { AddMilestone, Dots } from 'icons';
import { IconButton } from 'primitives';
import { Dropdown, TargetSelector, VersionPublishDialog } from 'components';
import hasAccess, { actions } from 'utils/user-access';
import { isProcessApplication } from 'utils/helpers';

import ConfirmPublicationDialog from './ConfirmPublicationDialog';

export default function MilestoneMenu({ milestone, canPublishToOrg, canCopyTo = true }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [moveAnchorEl, setMoveAnchorEl] = useState(null);
  const [versionPublishDialogOpen, setVersionPublishDialogOpen] = useState(false);
  const [orgPublishDialogOpen, setOrgPublishDialogOpen] = useState(false);

  const { project } = milestoneStore.state;
  const { isDraft } = milestoneStore;

  const handleMove = async (target) => {
    setAnchorEl(null);
    setMoveAnchorEl(null);

    if (target.projectId) {
      const redirectTarget = isProcessApplication(target) ? 'process-applications' : 'folders';
      if (await milestoneStore.createFromMilestone(milestone, target.projectId, target.id, target)) {
        history.push(`/${redirectTarget}/${target.id}`);
      }
    } else {
      if (await milestoneStore.createFromMilestone(milestone, target.id, undefined, target)) {
        history.push(`/projects/${target.id}`);
      }
    }
  };

  if (isDraft(milestone.id)) {
    return (
      <Fragment>
        <IconButton
          title={milestoneStore.state?.isTemplate ? 'Publish a new version to project' : 'Create a new version'}
          data-test="publish-version"
          onClick={() => {
            setVersionPublishDialogOpen(true);
          }}
        >
          <AddMilestone width="24" height="24" />
        </IconButton>
        {/* @ts-expect-error TS2741 */}
        <VersionPublishDialog
          open={versionPublishDialogOpen}
          onClose={() => setVersionPublishDialogOpen(false)}
          file={milestoneStore.state.diagram}
          origin="latest-version"
        />
      </Fragment>
    );
  }

  const publishToOrganizationMenuEntryIsAvailable =
    milestoneStore.state?.isTemplate && organizationStore.hasElevatedOrganizationPermissions && canPublishToOrg;

  const publishToOrganizationMenuEntry = (
    // @ts-expect-error TS2739
    <Dropdown.ListItem
      data-test="version-menu-item-publish-to-org"
      onClick={(evt) => {
        evt.stopPropagation();
        setAnchorEl(null);
        setOrgPublishDialogOpen(true);
      }}
    >
      Publish to organization
    </Dropdown.ListItem>
  );

  return (
    <Fragment>
      <ConfirmPublicationDialog
        milestone={milestone}
        onClose={() => setOrgPublishDialogOpen(false)}
        open={orgPublishDialogOpen}
        templateName={milestoneStore.state?.diagram?.name}
      />
      <IconButton
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          evt.stopPropagation();
        }}
        aria-haspopup="true"
        aria-owns={anchorEl ? 'version-menu' : undefined}
        data-test="version-menu-button"
        title="Browse version actions"
      >
        <Dots />
      </IconButton>

      <Dropdown
        size="small"
        id="version-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setMoveAnchorEl(null);
        }}
      >
        <>
          {publishToOrganizationMenuEntryIsAvailable && (
            <>
              {publishToOrganizationMenuEntry}
              <Dropdown.ListItemDivider />
            </>
          )}
          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem
            data-test="version-menu-item-rename"
            onClick={(evt) => {
              evt.stopPropagation();
              setAnchorEl(null);
              milestoneStore.setEditingMilestone(milestone.id);
            }}
          >
            Edit name
          </Dropdown.ListItem>

          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem
            data-test="version-menu-item-restore"
            disabled={milestoneStore.state.diagram.imported}
            onClick={(evt) => {
              evt.stopPropagation();
              setAnchorEl(null);
              milestoneStore.restoreMilestone(milestone);
            }}
          >
            Restore as latest
          </Dropdown.ListItem>

          {hasAccess(project, actions.DELETE_MILESTONE) && (
            // @ts-expect-error TS2739
            <Dropdown.ListItem
              data-test="version-menu-item-delete"
              onClick={(evt) => {
                evt.stopPropagation();
                setAnchorEl(null);
                milestoneStore.delete(milestone);
              }}
            >
              Delete
            </Dropdown.ListItem>
          )}
          {canCopyTo && (
            // @ts-expect-error TS2739
            <Dropdown.ListItem
              hasSubDropdown
              data-test="version-menu-item-copy"
              onClick={(evt) => setMoveAnchorEl(evt.currentTarget)}
            >
              Copy to...
            </Dropdown.ListItem>
          )}
          <TargetSelector
            anchorEl={moveAnchorEl}
            open={Boolean(moveAnchorEl)}
            startingPoint={project}
            invalidTargetIds={[]}
            onSubmit={handleMove}
            action="copy"
          />
        </>
      </Dropdown>
    </Fragment>
  );
}
