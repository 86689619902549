/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Modal } from '@carbon/react';
import styled from 'styled-components';

export const ProcessApplicationVersionModal = styled(Modal)`
  .cds--modal-container {
    max-width: 650px;
  }

  & [role='dialog'] button {
    opacity: 1;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const VersionDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
