/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Button, FileUploaderButton, Link, Tab, TabList, TabPanels, Tabs } from '@carbon/react';
import { ChevronDown, Enterprise, Folders } from '@carbon/icons-react';

import * as Shared from 'App/Pages/Project/Shared.styled';
import { idpApplicationStore, idpProjectStore, organizationStore } from 'stores';
import { EmptyState, Spinner } from 'primitives';
import { IdpProjectDocumentUpload, SadFace } from 'icons';
import { IDP_PROJECT_EXTRACTION_METHODS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';
import { Dropdown, EntityTable } from 'components';
import history from 'utils/history';
import EvaluateExtraction from 'App/Pages/Project/IdpApplication/IdpProject/IdpEvaluateExtraction';
import { useClusterConnectorSecrets, useClusterStatus } from 'App/Pages/Project/IdpApplication/hooks';
import IdpExtractionProjectPublishModal from 'App/Pages/Project/IdpApplication/IdpProject/IdpEvaluateExtraction/idp-extraction-project-publish-modal/IdpExtractionProjectPublishModal';

import IdpExtractionFields from './IdpExtractionFields';
import * as Styled from './IdpProject.styled';
import IdpProjectHeader from './IdpProjectHeader';
import { ClusterNotification } from './cluster-notification';
import IdpDocuments from './IdpDocuments';

const EXTRACT_DATA_TAB_INDEX = 1;
const VALIDATE_EXTRACTION_TAB_INDEX = 2;
const ALLOWED_IDP_DOCUMENT_FILE_TYPES = ['.pdf'];
const TABS = ['upload', 'extract', 'evaluate'];

const IdpProjectPage = () => {
  const [loading, setLoading] = useState(true);

  // @ts-expect-error TS2339
  const { slug, tab } = useParams();
  const { idpProject, idpDocuments, idpExtractionFields, idpPublishedVersions, uploadIdpDocuments, deleteIdpDocument } =
    idpProjectStore;
  const { idpApplication } = idpApplicationStore;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPublishingOnOrganizationLevel, setIsPublishingOnOrganizationLevel] = useState(false);
  const [isPublishExtractionProjectModalVisible, setIsPublishExtractionProjectModalVisible] = useState(false);
  const [latestTestcaseResultLlmModelId, setLatestTestcaseResultLlmModelId] = useState(null);
  const clusterStatus = useClusterStatus(idpApplication?.clusterId);
  const areIdpConnectorSecretsMissing = useClusterConnectorSecrets(idpApplication?.clusterId);

  const reset = () => {
    idpProjectStore.reset();
  };

  useEffect(() => {
    const init = async () => {
      await idpProjectStore.init(slug.split('--')[0]);
      setLoading(false);
    };

    init();
    if (isNoTabSelected()) {
      history.push(`/idp-projects/${slug}/${TABS[0]}`);
    }

    return () => reset();
  }, [slug]);

  useEffect(() => {
    if (!isNoTabSelected()) {
      setSelectedTabIndex(TABS.indexOf(tab));
    }
  }, [tab]);

  const isNoTabSelected = () => !tab || TABS.indexOf(tab) === -1;

  const IdpProjectNotFound = () => {
    return (
      // @ts-expect-error TS2739
      <EmptyState
        title="IDP project not found!"
        description="Sorry, your IDP project might have been deleted."
        icon={<SadFace width="48" height="48" />}
      />
    );
  };

  const TitleContainer = () => {
    return (
      <Styled.TitleContainer>
        <Shared.Title data-test="idp-project-name">{idpProject.name}</Shared.Title>

        <Styled.Subtitle data-test="idp-project-extractionMethod">
          <Styled.SubtitleContainer>
            {idpProject.description && <div data-test="idp-project-description">{idpProject.description} -&nbsp;</div>}
            <div>{getIdpProjectExtractionMethod(idpProject.extractionMethod)}</div>
          </Styled.SubtitleContainer>
        </Styled.Subtitle>
      </Styled.TitleContainer>
    );
  };

  const UploadButton = ({ onDocumentsUploadProgress }) => {
    return (
      <FileUploaderButton
        name="idp-documents"
        multiple
        disableLabelChanges
        labelText="Upload documents"
        size="lg"
        accept={ALLOWED_IDP_DOCUMENT_FILE_TYPES}
        onChange={(event) => uploadIdpDocuments(event.target.files, onDocumentsUploadProgress)}
      />
    );
  };

  const getIdpProjectExtractionMethod = (value) => {
    return IDP_PROJECT_EXTRACTION_METHODS.find((method) => method.value === value)?.title;
  };

  const extractIdpDocument = async (document) => {
    await idpProjectStore.fetchIdpExtractionFields(document.id);
    idpProjectStore.setActiveExtractionIdpDocument(document);
    history.push(`/idp-projects/${slug}/${TABS[EXTRACT_DATA_TAB_INDEX]}`);
    setSelectedTabIndex(EXTRACT_DATA_TAB_INDEX);
  };

  const handleSwitchToExtractData = (document) => {
    idpProjectStore.setActiveExtractionIdpDocument(document);
    history.push(`/idp-projects/${slug}/${TABS[EXTRACT_DATA_TAB_INDEX]}`);
    setSelectedTabIndex(EXTRACT_DATA_TAB_INDEX);
  };

  const handleNavigationToVersions = () => {
    history.push(`/idp-projects/${idpProject.id}/versions`);
  };

  const handleIdpExtractionProjectPublication = async (isPublishingOnOrganizationLevel) => {
    setIsPublishingOnOrganizationLevel(isPublishingOnOrganizationLevel);
    setAnchorEl(null);
    await idpProjectStore.determineAndSetActiveExtractionModel(setLatestTestcaseResultLlmModelId);
    setIsPublishExtractionProjectModalVisible(true);
  };

  const NoIdpDocumentsInIdpProject = () => {
    return (
      <EntityTable
        // @ts-expect-error TS2741
        emptyState={
          <EmptyState
            icon={<IdpProjectDocumentUpload width="101" height="108" />}
            title="Upload Sample Documents"
            description={
              <Styled.EmptyStateDescriptionContainer>
                Start by uploading a set of sample PDF documents that represent the specific document type you want to
                extract data from. You will use these documents to test the extraction template you create.
              </Styled.EmptyStateDescriptionContainer>
            }
            // @ts-expect-error TS2741
            action={<UploadButton />}
            link={
              <Link
                href="https://docs.camunda.io/docs/8.7/components/modeler/web-modeler/idp/idp-unstructured-extraction/#upload-documents"
                target="_blank"
                rel="noreferrer"
              >
                For more information, see the document upload guidelines.
              </Link>
            }
          />
        }
        columns={[]}
        rows={[]}
        title={<TitleContainer />}
      />
    );
  };

  return (
    <>
      <IdpProjectHeader />
      {loading ? (
        <Spinner fullHeight />
      ) : idpProject ? (
        <Styled.Container>
          {idpDocuments.length === 0 ? (
            <NoIdpDocumentsInIdpProject />
          ) : (
            <>
              <TitleContainer />
              <Styled.TabsContainer>
                {selectedTabIndex === VALIDATE_EXTRACTION_TAB_INDEX && (
                  <Styled.Header>
                    <Button
                      size="md"
                      kind="ghost"
                      onClick={handleNavigationToVersions}
                      disabled={idpPublishedVersions.length === 0}
                    >
                      Versions
                    </Button>
                    {organizationStore.hasElevatedOrganizationPermissions ? (
                      <>
                        <Button
                          size="md"
                          renderIcon={ChevronDown}
                          iconDescription="ChevronDown"
                          disabled={idpExtractionFields.length === 0}
                          onClick={(evt) => setAnchorEl(evt?.currentTarget)}
                        >
                          Publish
                        </Button>
                        <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                          {/* @ts-expect-error TS2739 */}
                          <Dropdown.ListItem onClick={() => handleIdpExtractionProjectPublication(false)}>
                            <Styled.PublishOption>
                              <Folders />
                              Publish to project
                            </Styled.PublishOption>
                          </Dropdown.ListItem>
                          {/* @ts-expect-error TS2739 */}
                          <Dropdown.ListItem onClick={() => handleIdpExtractionProjectPublication(true)}>
                            <Styled.PublishOption>
                              <Enterprise />
                              Publish to organization
                            </Styled.PublishOption>
                          </Dropdown.ListItem>
                        </Dropdown>
                      </>
                    ) : (
                      <Button size="md" onClick={() => handleIdpExtractionProjectPublication(false)}>
                        Publish to project
                      </Button>
                    )}
                  </Styled.Header>
                )}
                <Tabs
                  selectedIndex={selectedTabIndex}
                  onChange={({ selectedIndex }) => {
                    history.push(`/idp-projects/${slug}/${TABS[selectedIndex]}`);
                    setSelectedTabIndex(selectedIndex);
                  }}
                >
                  <TabList aria-label="IDP project tabs" data-test="idp-project-tabs">
                    <Tab role="tab">Upload documents</Tab>
                    <Tab role="tab">Extract data</Tab>
                    <Tab role="tab">Validate extraction</Tab>
                  </TabList>
                  <TabPanels>
                    <Styled.TabPanel data-test="upload-documents-tab-panel">
                      <IdpDocuments
                        idpDocuments={idpDocuments}
                        onDocumentDelete={deleteIdpDocument}
                        onDocumentExtract={extractIdpDocument}
                        renderUploadButton={UploadButton}
                      />
                    </Styled.TabPanel>
                    <Styled.TabPanel data-test="extract-data-tab-panel">
                      {selectedTabIndex === 1 && (
                        <>
                          {idpApplication?.clusterId && (
                            <ClusterNotification
                              clusterId={idpApplication.clusterId}
                              areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                            />
                          )}
                          <IdpExtractionFields
                            clusterStatus={clusterStatus}
                            areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                          />
                        </>
                      )}
                    </Styled.TabPanel>
                    <Styled.TabPanel data-test="evaluate-extraction-tab-panel">
                      {selectedTabIndex === 2 && (
                        <>
                          {idpApplication?.clusterId && (
                            <ClusterNotification
                              clusterId={idpApplication.clusterId}
                              areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                            />
                          )}
                          <EvaluateExtraction
                            clusterStatus={clusterStatus}
                            areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                            onSwitchTabToExtractData={handleSwitchToExtractData}
                          />
                        </>
                      )}
                    </Styled.TabPanel>
                  </TabPanels>
                </Tabs>
              </Styled.TabsContainer>
            </>
          )}
        </Styled.Container>
      ) : (
        <IdpProjectNotFound />
      )}
      <IdpExtractionProjectPublishModal
        isPublishExtractionProjectModalVisible={isPublishExtractionProjectModalVisible}
        isPublishingOnOrganizationLevel={isPublishingOnOrganizationLevel}
        selectedLlmModelId={latestTestcaseResultLlmModelId}
        onCloseModal={() => setIsPublishExtractionProjectModalVisible(false)}
      />
    </>
  );
};

export default observer(IdpProjectPage);
