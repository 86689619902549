/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Link } from '@carbon/react';

export const LandscapeEmptyStateContainer = styled.div`
  padding-inline: 5rem;
  padding-block: 4.75rem;
  height: 100%;
  width: 100%;
  background: #f4f4f4;
`;

export const LandscapeEmptyStateDescription = styled.p`
  margin-block-start: 0.5rem;
  width: 50%;
  font-size: 14px;
  color: var(--cds-text-secondary, #525252);
`;

export const LandscapeEmptyStateLink = styled(Link)`
  margin-block-start: 1.5rem;
`;
