/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Button } from 'primitives';

export const NewCommentForm = styled.form`
  display: flex;
  align-items: flex-end;

  margin: var(--spacing-small);
`;

export const NewCommentFormButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
  margin-left: var(--spacing-tiny);

  > svg {
    margin: 0;
  }
`;
