/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { LogoGithub } from '@carbon/icons-react';

import GitHubFields, {
  FIELDS_THAT_REQUIRE_RESYNC as GitHubFieldsThatRequireResync,
  FIELDS_VALIDATION as GitHubFieldsValidation
} from './settings/fields/GitHubFields';

// TODO: Use a dynamic configuration here
export const CONFIGURED_GIT_PROVIDER = 'github';

export const SupportedGitProviders = {
  github: {
    label: 'GitHub',
    icon: LogoGithub,
    fields: GitHubFields,
    validationRules: GitHubFieldsValidation,
    fieldsThatRequireResync: GitHubFieldsThatRequireResync,
    providerBaseUrl: 'https://github.com',
    branchPathPattern: ':repository/tree/:branch'
  }
};
