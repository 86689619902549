/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled, { css } from 'styled-components';

export const InvalidFormMessage = styled.div`
  padding: 10px 20px;
  overflow: auto;
`;

export const FormPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--cm-color-white-base);

  ${({ $bordered }) =>
    $bordered &&
    css`
      border-radius: 3px;
      border: 1px solid #e0e0e0;
    `}
`;

export const FormPreview = styled.div`
  flex-grow: 1;
  min-height: 0;
  margin-top: 10px;
  margin-left: -21px;

  ${({ scale }) =>
    scale &&
    css`
      transform: scale(${scale});
      transform-origin: top left;

      /**
       * Calculation to offset the margin introduced when scaling down
       * Needed a slightly different offset after screen width of 1584px
       */
      margin-right: calc((1 - ${scale}) / 0.7 * -100%);
      @media screen and (max-width: 1584px) {
        margin-left: -15px;
        margin-right: calc((1 - ${scale}) / 0.77 * -100%);
      }
    `}
`;
