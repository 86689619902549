/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link } from '@carbon/react';

import { P } from './StyledDescription';

export function DescriptionText() {
  return (
    <>
      <P>
        <b>Enable</b> this setting to allow any collaborators with deployment privileges to deploy process application
        versions from Web Modeler to a production stage after review approval.{' '}
      </P>
      <P>
        <b>Disable</b> this setting to only allow organization administrators to deploy process application versions to
        a production stage from Web Modeler.{' '}
      </P>
      <P>
        This setting can only be configured by{' '}
        <Link
          size={'sm'}
          href={
            'https://docs.camunda.io/docs/components/console/manage-organization/manage-users/#roles-and-permissions'
          }
          inline
        >
          organization administrators
        </Link>
        .
      </P>
    </>
  );
}
