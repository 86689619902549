/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect } from 'react';
import { MeterChart } from '@carbon/charts-react';

import {
  SUMMARY_CHART_CONFIG,
  EXTRACTION_VALIDATION_STATUS
} from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

import * as Styled from './TestCaseSummary.styled';

const TestCaseSummary = ({ testCaseResults }) => {
  const [summaryChartConfig, setSummaryChartConfig] = useState(SUMMARY_CHART_CONFIG);

  useEffect(() => {
    // Calculate total passes and fails across all test cases
    const totalResults = testCaseResults.reduce(
      (acc, field) => {
        field.testCaseResults.forEach((result) => {
          if (result.status === EXTRACTION_VALIDATION_STATUS.PASS) {
            acc.passes++;
          } else if (result.status === EXTRACTION_VALIDATION_STATUS.FAIL) {
            acc.fails++;
          }
        });
        return acc;
      },
      { passes: 0, fails: 0 }
    );

    // Calculate total and percentages
    const total = totalResults.passes + totalResults.fails;
    const successPercentage = total > 0 ? ((totalResults.passes / total) * 100).toFixed(1) : 0;
    const failurePercentage = total > 0 ? ((totalResults.fails / total) * 100).toFixed(1) : 0;

    // Create a new chart config object with percentages
    const newChartConfig = {
      data: [
        {
          group: 'Passed',
          value: totalResults.passes,
          percentage: `${successPercentage}%`
        },
        {
          group: 'Failed',
          value: totalResults.fails,
          percentage: `${failurePercentage}%`
        }
      ],
      options: {
        ...SUMMARY_CHART_CONFIG.options,
        meter: {
          ...SUMMARY_CHART_CONFIG.options.meter,
          proportional: {
            ...SUMMARY_CHART_CONFIG.options.meter.proportional,
            total: total,
            unit: `Test cases (${successPercentage}% success rate)`
          }
        }
      }
    };
    setSummaryChartConfig(newChartConfig);
  }, [testCaseResults]);

  // Don't render anything if there are no test results
  if (summaryChartConfig.data?.[0]?.value === 0 && summaryChartConfig.data?.[1]?.value === 0) {
    return null;
  }

  return (
    <Styled.SummaryContainer>
      <div className="meter-chart-container">
        <div className="meter-chart-title">
          Field extraction summary
          <div className="meter-chart-percentage-container">
            <Styled.GreenCheckmarkFilled size={16} />
            {summaryChartConfig.data[0].percentage}
          </div>
          <Styled.StatusOverviewContainer>
            <Styled.RedErrorFilled size={16} />
            {summaryChartConfig.data[1].percentage}
          </Styled.StatusOverviewContainer>
        </div>
        <MeterChart data={summaryChartConfig.data} options={summaryChartConfig.options} />
      </div>
    </Styled.SummaryContainer>
  );
};

export default TestCaseSummary;
