/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect, useRef } from 'react';

import { fileService } from 'services';

import { handleError } from '../utils/errorHandling';

const DEFAULT_CACHE_SIZE = 10;

export const useCachedReadmeContent = (readmeId, cacheSize = DEFAULT_CACHE_SIZE) => {
  const cacheRef = useRef(new Map());

  const [isLoading, setIsLoading] = useState(false);
  const [readmeContent, setReadmeContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    const fetchContent = async () => {
      if (!readmeId) {
        setReadmeContent(null);
        setError(null);
        return;
      }

      if (cacheRef.current.has(readmeId)) {
        setReadmeContent(cacheRef.current.get(readmeId));
        setError(null);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const file = await fileService.fetchById(readmeId);
        if (isCancelled) {
          return;
        }

        if (cacheRef.current.size >= cacheSize) {
          const oldestKey = cacheRef.current.keys().next().value;
          cacheRef.current.delete(oldestKey);
        }
        cacheRef.current.set(readmeId, file.content);
        setReadmeContent(file.content);
      } catch (err) {
        if (isCancelled) {
          return;
        }
        setError(err);
        handleError(err, 'Could not load the readme content.');
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    };

    fetchContent();

    return () => {
      isCancelled = true;
    };
  }, [readmeId, cacheSize]);

  return { readmeContent, isLoading, error };
};
