/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Helmet } from 'react-helmet-async';

import { TopBar, Breadcrumb } from 'components';
import { getPageTitle } from 'utils/helpers';
import buildSlug from 'utils/buildSlug';

import useLoadProject from './use-load-project';

export default function Header({ processApplication }) {
  const project = useLoadProject(processApplication);

  if (!processApplication || !project) {
    return null;
  }

  const isInFolder = !!processApplication.parentId;
  const mainProcess = processApplication.files.find((file) => file.id === processApplication.mainProcessFileId);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(`${processApplication.name} - Versions`)}</title>
      </Helmet>
      <TopBar.Breadcrumbs>
        <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
        <Breadcrumb data-test="breadcrumb-project" title={project.name} variant="link" to={`/projects/${project.id}`} />
        {isInFolder && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
        <Breadcrumb
          title={processApplication.name}
          variant="link"
          to={`/process-applications/${buildSlug(processApplication.id, processApplication.name)}`}
          data-test="parent-folder"
        />
        <Breadcrumb
          data-test="breadcrumb-diagram"
          title={mainProcess.name}
          variant="link"
          to={`/diagrams/${buildSlug(mainProcess.id, mainProcess.name)}`}
        />
        <Breadcrumb data-test="breadcrumb-milestone" title="Versions" variant="text" isBold />
      </TopBar.Breadcrumbs>
    </>
  );
}
