/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, Dialog, Typography } from 'primitives';
import { milestoneStore } from 'stores';
import { VERSION_PUBLISH_DIALOG_LABELS } from 'components';

export default function ConfirmPublicationDialog({ onClose, open, milestone, templateName }) {
  const handleConfirm = () => {
    milestoneStore.publishToOrganization(milestone);
    onClose();
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onConfirm={handleConfirm} onClose={handleClose}>
      <Dialog.Header>
        <Dialog.Title data-test="dialog-title">Publish Connector template to organization</Dialog.Title>
      </Dialog.Header>

      <Dialog.Content>
        <Typography $gutterBottom>
          {VERSION_PUBLISH_DIALOG_LABELS.connector_template.description(true, templateName)}
        </Typography>
      </Dialog.Content>

      <Dialog.Footer>
        <Button data-test="cancel" variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button data-test="confirm" onClick={handleConfirm}>
          Publish template
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
