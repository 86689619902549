/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { StructuredListBody, StructuredListWrapper } from '@carbon/react';

import { milestoneStore } from 'stores';
import { Sidebar } from 'primitives';

import ChangeList from './ChangeList';
import EmptyState from './EmptyState';
import { LIST_TYPES } from './helpers';
import * as Styled from './BPMNChangeList.styled';

function BPMNChangeList({ isBPMNVisualView }) {
  const { _removed, _added, _changed, _layoutChanged } = milestoneStore.state.bpmnChangeList;

  const totalChangesCount =
    (_removed ? Object.keys(_removed).length : 0) +
    (_added ? Object.keys(_added).length : 0) +
    (_changed ? Object.keys(_changed).length : 0) +
    (_layoutChanged ? Object.keys(_layoutChanged).length : 0);

  const isNothingToCompare = !milestoneStore.state?.selection?.secondary;
  const isNoChanges = !isNothingToCompare && totalChangesCount === 0;

  return (
    isBPMNVisualView && (
      <Sidebar.Section>
        <Styled.SidebarTitle>{`Changes (${totalChangesCount})`}</Styled.SidebarTitle>
        <Styled.ChangeListContainer>
          <StructuredListWrapper isCondensed selection>
            <StructuredListBody>
              <ChangeList list={_removed} type={LIST_TYPES.REMOVED} />
              <ChangeList list={_added} type={LIST_TYPES.ADDED} />
              <ChangeList list={_changed} type={LIST_TYPES.CHANGED} />
              <ChangeList list={_layoutChanged} type={LIST_TYPES.MOVED} />
            </StructuredListBody>
          </StructuredListWrapper>
          {isNothingToCompare && <EmptyState text="Nothing to compare" />}
          {isNoChanges && <EmptyState text="No changes" />}
        </Styled.ChangeListContainer>
      </Sidebar.Section>
    )
  );
}

export default observer(BPMNChangeList);
