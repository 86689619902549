/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { dedicatedModesStore } from 'App/Pages/Diagram/stores';

function TokenSimulationKeyboardBindingsOverride(eventBus, injector, toggleMode) {
  const keyboard = injector.get('keyboard', false);

  // Disable default keyboard binding for toggling token simulation mode
  eventBus.on('keyboard.init', function () {
    keyboard.addListener(function (event) {
      if (event.keyEvent.code === 'KeyT' && dedicatedModesStore.isImplementMode) {
        toggleMode.toggleMode(false);
      }
    });
  });
}

TokenSimulationKeyboardBindingsOverride.$inject = ['eventBus', 'injector', 'toggleMode'];

export default {
  __init__: ['tokenSimulationKeyboardBindingsOverride'],
  tokenSimulationKeyboardBindingsOverride: ['type', TokenSimulationKeyboardBindingsOverride]
};
