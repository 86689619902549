/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Message = styled.div`
  width: calc(100% - 3rem);
  font-weight: bold;
`;

export const RatingsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;

  .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--cds-layout-size-height-local);
    height: var(--cds-layout-size-height-local);
    padding: 0;
  }

  .rating:first-child {
    margin-left: 0;
  }
`;

export const RatingsLabels = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

export const Suggestions = styled.div`
  margin-top: 1rem;

  textarea {
    width: 100%;
    margin-top: 0.5rem;
    background-color: var(--cds-layer, #f4f4f4);
    resize: none;
  }
`;

export const AllowContact = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
