/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { TextInput } from '@carbon/react';

import { idpProjectStore } from 'stores';

import * as Styled from './SettingsModal.styled';

export const SettingsModal = observer(({ isOpen, onClose }) => {
  const { idpProject } = idpProjectStore;
  const [description, setDescription] = useState(idpProject?.description);

  const isUpdateDisabled = () => !description || description === idpProject?.description;

  const closeModal = () => {
    setDescription(idpProject?.description);
    onClose();
  };

  const performRequest = async () => {
    if (isUpdateDisabled()) {
      return;
    }

    await idpProjectStore.update(description);
    onClose();
  };

  return (
    <Styled.IdpProjectSettingsModal
      open={isOpen}
      modalHeading="Document extraction settings"
      primaryButtonText="Save"
      primaryButtonDisabled={isUpdateDisabled()}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
      preventCloseOnClickOutside
    >
      <Styled.IdpProjectSettingsModalContent>
        <TextInput
          autoComplete="off"
          enableCounter
          data-modal-primary-focus
          id="idp-project-description"
          labelText="Description"
          placeholder="Describe the document type this template should extract"
          value={description}
          maxCount={100}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
      </Styled.IdpProjectSettingsModalContent>
    </Styled.IdpProjectSettingsModal>
  );
});
