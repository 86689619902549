/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import { useEffect, useState } from 'react';

import { getDefinitions, isConnector } from 'utils/web-modeler-diagram-parser';
import { BPMN } from 'utils/constants';
import { clusterService } from 'services';
import { getExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

export const useDiagramWithMissingClients = ({ currentOrganization, selectedClusterId, diagramXml, diagramType }) => {
  const [hasMissingClient, setHasMissingClient] = useState(false);
  const [doesSupportClients, setDoesSupportClients] = useState(false);

  useEffect(() => {
    (async () => {
      if (!diagramXml || diagramType !== BPMN) {
        setHasMissingClient(false);
        return;
      }
      setDoesSupportClients(await diagramSupportsClients(diagramXml));
    })();
  }, [diagramXml, diagramType]);

  /**
   * Update clients when the window is focused; this is to ensure that the output is always up-to-date
   * when the user switches back to the tab after creating a client
   */
  useEffect(() => {
    const onFocus = async () => {
      if (document.hidden) {
        return;
      }

      if (doesSupportClients) {
        await getClientsAndSetFlag();
      }
    };
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  /**
   * Set the flag if the diagram supports clients and there are no clients configured
   */
  useEffect(() => {
    if (!doesSupportClients || !currentOrganization || !selectedClusterId) {
      setHasMissingClient(false);
      return;
    }

    (async () => {
      await getClientsAndSetFlag();
    })();
  }, [doesSupportClients, currentOrganization, selectedClusterId]);

  async function getClientsAndSetFlag() {
    try {
      const clients = await clusterService.getClients(currentOrganization.id, selectedClusterId);
      const hasClientWithZeebePermission = clients.some((client) => client.permissions.includes('Zeebe'));
      setHasMissingClient(clients?.length === 0 || !hasClientWithZeebePermission);
    } catch (error) {
      console.error('Error while fetching clients', error);
      setHasMissingClient(false);
    }
  }

  return hasMissingClient;
};

async function diagramSupportsClients(diagramXml) {
  const definitions = await getDefinitions(diagramXml);
  const elements = definitions.diagrams
    .flatMap((diagram) => diagram.plane?.planeElement?.flat())
    .map((element) => element?.bpmnElement);

  if (!elements) {
    return false;
  }

  const isMessageDefinition = (definition) => definition.$type === 'bpmn:MessageEventDefinition';
  const isMessageOrSignalDefinition = (definition) =>
    isMessageDefinition(definition) || definition.$type === 'bpmn:SignalEventDefinition';
  const matchEventDefinitions = (eventDefinitions, matcher) => eventDefinitions?.some(matcher);

  const eligibilityMap = {
    'bpmn:StartEvent': ({ eventDefinitions }) => matchEventDefinitions(eventDefinitions, isMessageOrSignalDefinition),
    'bpmn:ServiceTask': ({ element }) => !isConnector(element),
    'bpmn:ReceiveTask': () => true,
    'bpmn:SendTask': () => true,
    'bpmn:IntermediateThrowEvent': ({ eventDefinitions }) =>
      matchEventDefinitions(eventDefinitions, isMessageOrSignalDefinition),
    'bpmn:IntermediateCatchEvent': ({ eventDefinitions }) =>
      matchEventDefinitions(eventDefinitions, isMessageOrSignalDefinition),
    'bpmn:BoundaryEvent': ({ eventDefinitions }) =>
      matchEventDefinitions(eventDefinitions, isMessageOrSignalDefinition),
    '*': ({ element, businessObject }) =>
      !isConnector(element) && getExtensionElement(businessObject, 'zeebe:TaskDefinition')
  };

  for (const element of elements) {
    if (!element) {
      continue;
    }

    const businessObject = getBusinessObject(element);
    const eventDefinitions = businessObject.get('eventDefinitions');

    try {
      const eligibility = eligibilityMap[element.$type] || eligibilityMap['*'];
      if (eligibility && eligibility({ element, businessObject, eventDefinitions })) {
        return true;
      }
    } catch (error) {
      console.error('Error while checking element eligibility for API clients', error);
    }
  }

  return false;
}
