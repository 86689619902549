/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { selectedElementStore } from 'App/Pages/Diagram/stores';

function StoreSelectedElement(eventBus) {
  const onSelectionChanged = (event) => {
    const selectedElements = event.newSelection;
    const selectedElement = selectedElements[0];

    if (selectedElement) {
      selectedElementStore.setSelectedElement(selectedElement);
    } else {
      selectedElementStore.reset();
    }
  };

  const onChangeTemplate = (event) => {
    const element = event.context.element;

    if (element) {
      selectedElementStore.setNeedsRefresh(true);
    }
  };

  eventBus.on('canvas.destroy', () => {
    selectedElementStore.reset();
  });
  eventBus.on('selection.changed', onSelectionChanged);
  eventBus.on('commandStack.propertiesPanel.zeebe.changeTemplate.postExecute', onChangeTemplate);
}

StoreSelectedElement.$inject = ['eventBus'];

export default {
  __init__: ['storeSelectedElementExtension'],
  storeSelectedElementExtension: ['type', StoreSelectedElement]
};
