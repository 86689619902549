/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('features/view-file/types').FileSelection} FileSelection
 */

/**
 * @typedef {(
 *   fileOrMilestoneId: string,
 * ) => void} SelectFileFunction
 */

/**
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplication} ProcessApplication
 */

import { useEffect, useState } from 'react';

import { DRAFT_VERSION } from 'components';
import { fileService, milestoneService, processApplicationService } from 'services';

const FETCH_FAILED_MESSAGE = 'Failed to load data for the selected file!';

/**
 * Custom hook to manage selected file state.
 *
 * It returns an object (fileSelection) that includes the details of the current selected file and its previous version.
 *  If the draft is selected,
 *    - the current version is the current state of the file,
 *    - the previous version is found in the latest saved version of the process application.
 *  If a saved version is selected,
 *    - the current version is found in the selected saved versions of the process application,
 *    - the previous version is found in the previous saved version of the process application.
 *
 * @param {string} selectedVersionId - The ID of the currently selected process app version.
 * @param {Array<Object>} versions - List of available versions for the process application.
 * @param {ProcessApplication} processApplication - The process application object containing files.
 * @param {Function} startLoading
 * @param {Function} stopLoading
 *
 * @returns {{
 *   fileSelection: FileSelection|null, // The details and content of the selected file and its previous version.
 *   selectFile: SelectFileFunction // Function to set fileSelection based on the file or milestone ID passed.
 * }}
 */
export default function useFileSelection(selectedVersionId, versions, processApplication, startLoading, stopLoading) {
  /**
   * @type {[FileSelection|null, Function]}
   */
  const [fileSelection, setFileSelection] = useState(null);

  /**
   * When the draft is selected, selects the main process in the process application.
   */
  useEffect(() => {
    if (!processApplication?.files || !versions) {
      return;
    }

    if (isDraftVersionSelected(selectedVersionId)) {
      startLoading();
      selectFileInDraft(processApplication.mainProcessFileId);
    }
  }, [selectedVersionId, processApplication?.files, versions]);

  /**
   * When a saved version is selected, selects the main process milestone from the list of milestones in the process application.
   */
  useEffect(() => {
    const isSavedVersionSelected = selectedVersionId && selectedVersionId !== DRAFT_VERSION.id;
    if (isSavedVersionSelected) {
      (async () => {
        startLoading();
        try {
          const selectedVersion = await processApplicationService.fetchProcessApplicationVersion(selectedVersionId);
          const mainProcessMilestoneId = selectedVersion.milestones.find(
            (milestone) => milestone.fileId === selectedVersion.mainProcessFileId
          ).id;
          selectFileInSavedVersion(mainProcessMilestoneId);
        } catch {
          console.error(FETCH_FAILED_MESSAGE);
          stopLoading();
        }
      })();
    }
  }, [selectedVersionId, versions]);

  /**
   * Pass fileId if the draft is selected & milestoneId if a saved version is selected
   * @param {String} fileOrMilestoneId
   */
  const selectFile = (fileOrMilestoneId) => {
    if (isDraftVersionSelected(selectedVersionId)) {
      selectFileInDraft(fileOrMilestoneId);
    } else {
      selectFileInSavedVersion(fileOrMilestoneId);
    }
  };

  /**
   * When selecting a file in the draft,
   *  - the current version of the file is the latest state of the file
   *  - the previous version is found from the milestone associated with the latest saved version.
   *
   */
  const selectFileInDraft = async (fileOrMilestoneId) => {
    try {
      startLoading();
      const fileId = fileOrMilestoneId;
      /** @type {FileSelection} */
      const selection = {
        current: null,
        previous: null,
        fileId: '',
        fileType: ''
      };

      // Get file content from draft
      const file = await fileService.fetchById(fileId);
      selection.current = {
        fileName: file.name,
        versionName: 'draft',
        content: file.content
      };

      // Get file id and type
      selection.fileType = file.type;
      selection.fileId = file.id;

      // Get file content from latest version
      if (versions.length > 0) {
        const latestVersionId = versions[0].id;
        const latestVersion = await processApplicationService.fetchProcessApplicationVersion(latestVersionId);
        const milestone = latestVersion.milestones.find((milestone) => milestone.fileId === fileId);
        if (milestone) {
          const milstoneDetails = await milestoneService.fetchById(milestone.id);
          selection.previous = {
            fileName: file.name, // latest filename
            versionName: milstoneDetails.name,
            content: milstoneDetails.content
          };
        }
      }

      setFileSelection(selection);
    } catch {
      console.log(FETCH_FAILED_MESSAGE);
    } finally {
      stopLoading();
    }
  };

  /**
   * When selecting a file in a saved version,
   *  - the current version is found from the milestone associated with the selected saved version,
   *  - the previous version is found from the milestone associated with the previous saved version.
   *
   */
  const selectFileInSavedVersion = async (fileOrMilestoneId) => {
    try {
      const milestoneId = fileOrMilestoneId;
      /** @type {FileSelection} */
      const selection = {
        current: null,
        previous: null,
        fileId: '',
        fileType: ''
      };

      // Get file content from selected version
      const milstoneDetails = await milestoneService.fetchById(milestoneId);
      selection.current = {
        versionName: milstoneDetails.name,
        content: milstoneDetails.content
      };

      // Get file id, name and type
      const fileId = milstoneDetails.fileId;
      const file = await fileService.fetchById(milstoneDetails.fileId);
      selection.fileType = file.type;
      selection.fileId = fileId;
      selection.current.fileName = file.name; // latest filename

      // Get file content from previous version
      const selectedVersionIndex = versions.findIndex((version) => version.id === selectedVersionId);
      const isPreviousVersionPresent = selectedVersionIndex + 1 < versions.length;
      if (isPreviousVersionPresent) {
        const previousVersionId = versions[selectedVersionIndex + 1].id;
        const previousVersion = await processApplicationService.fetchProcessApplicationVersion(previousVersionId);
        const previousMilestone = previousVersion.milestones.find((milestone) => milestone.fileId === fileId);
        if (previousMilestone) {
          // Previous version present for this file
          const previousMilestoneDetails = await milestoneService.fetchById(previousMilestone.id);
          selection.previous = {
            fileName: file.name, // latest filename
            versionName: previousMilestoneDetails.name,
            content: previousMilestoneDetails.content
          };
        }
      }

      setFileSelection(selection);
    } catch {
      console.error(FETCH_FAILED_MESSAGE);
    } finally {
      stopLoading();
    }
  };

  return { fileSelection, selectFile };
}

const isDraftVersionSelected = (selectedVersionId) => {
  return selectedVersionId === DRAFT_VERSION.id;
};
