/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, runInAction } from 'mobx';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import semver from 'semver';

import { currentDiagramStore, notificationStore, projectStore } from 'stores';
import { projectService, fileService } from 'services';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';

class FormLinkStore {
  minimumClusterVersions = ['8.4.0', 'zeebe snapshot'];

  linkedElements = null;
  linkedFormFiles = new Map();
  fetchedFormFileContents = new Map();
  selectedElementId = null;
  selectedElementName = '';
  selectedElement = null;
  isLoading = false;
  deploymentConflictsIds = {};

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    runInAction(() => {
      this.linkedElements = null;
      this.linkedFormFiles = new Map();
      this.fetchedFormFileContents = new Map();
      this.selectedElementId = null;
      this.selectedElementName = '';
      this.selectedElement = null;
      this.isLoading = false;
      this.deploymentConflictsIds = {};
    });
  }

  setSelectedElement = (element) => {
    if (!element) {
      runInAction(() => {
        this.selectedElement = null;
        this.selectedElementId = null;
        this.selectedElementName = '';
      });
      return;
    }

    runInAction(() => {
      this.selectedElement = element;
      this.selectedElementId = element.id;
      this.selectedElementName = getBusinessObject(element)?.name || '';
    });
  };

  setLinkedElements = (list) => {
    this.linkedElements = list;
  };

  getLinkedElement = (element) => {
    return this.linkedElements?.find((el) => el.elementId === element?.id);
  };

  get linkedStartEvent() {
    return this.linkedElements?.find((el) => el.elementType === 'bpmn:StartEvent');
  }

  get startEventHasUnresolvedFormConflicts() {
    return this.linkedStartEvent ? this.linkedElementHasUnresolvedFormConflicts(this.linkedStartEvent) : false;
  }

  get startEventFormContent() {
    const startEvent = this.linkedStartEvent;

    if (!startEvent) {
      return null;
    }

    let embeddedFormBody;
    try {
      embeddedFormBody = startEvent.formBody ? JSON.parse(startEvent.formBody) : null;
    } catch (ex) {
      notificationStore.showError(
        'Something went wrong while parsing the form. Please check the JSON configuration in the properties panel.'
      );
      console.error(ex);
    }

    return embeddedFormBody || this.fetchedFormFileContents.get(startEvent.formId) || null;
  }

  isElementLinked = (element) => {
    return Boolean(this.getLinkedElement(element));
  };

  hasEmbeddedForm = (element) => {
    return Boolean(this.getEmbeddedForm(element)) || Boolean(this.getEmbeddedFormKey(element));
  };

  getEmbeddedForm = (element) => {
    const linkedElement = this.getLinkedElement(element);
    return linkedElement?.formBody;
  };

  getEmbeddedFormKey = (element) => {
    const linkedElement = this.getLinkedElement(element);
    return linkedElement?.formKey;
  };

  getLinkedFormId = (element) => {
    const linkedElement = this.getLinkedElement(element);
    return linkedElement?.formId;
  };

  /**
   * Fetches the linked forms for the given element.
   * @param {*} element
   * @returns {Promise<{forms: Array, orphan: boolean}>}
   */
  async getExistingLinks(element) {
    if (this.isElementLinked(element)) {
      const { project } = currentDiagramStore.state;
      const linkedFormId = this.getLinkedFormId(element);

      if (!linkedFormId) {
        throw new Error('Missing linked form id');
      }

      try {
        runInAction(() => {
          this.isLoading = true;
        });

        return await this.#fetchFilesByFormId(project.id, linkedFormId);
      } catch (ex) {
        console.error(ex);
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }

    return [];
  }

  atLeastOneLinkedForm() {
    return this.linkedElements?.filter((el) => el.formId)?.length > 0;
  }

  /**
   * Fetches the files for the given form id.
   * @param {*} projectId
   * @param {*} formId
   * @returns
   */
  async #fetchFilesByFormId(projectId, formId) {
    const linkedForms = await projectService.fetchFilesByFormId(projectId, formId);

    if (!linkedForms?.length) {
      return [];
    }

    const fetchedContents = await Promise.all(
      linkedForms.map(async (formFile) => {
        const fileContent = await fileService.fetchById(formFile.id);
        const parsedContent = JSON.parse(fileContent.content);

        runInAction(() => {
          // We cache the parsed content, so that we don't have to fetch and parse it again.
          this.fetchedFormFileContents.set(formId, parsedContent);
        });

        return {
          ...formFile,
          fileId: fileContent.id,
          fileName: fileContent.name,
          content: parsedContent,
          formId: parsedContent.id,
          lastUpdate: fileContent.changed
        };
      })
    );

    return fetchedContents;
  }

  /**
   * Fetches all the linked form files.
   * @returns {Promise<Array>} The unique linked forms
   */
  async getLinkedFormFiles() {
    const project = projectStore.project?.id ? projectStore.project : currentDiagramStore.state.project;
    const linkedFormFiles = new Map();
    const conflictingFormIds = {};

    runInAction(() => {
      this.isLoading = true;
    });

    for (const form of this.linkedForms) {
      const files = await this.#fetchFilesByFormId(project.id, form.formId);

      if (files.length === 0) {
        continue;
      }

      if (files.length > 1) {
        // If there are more than one file for the same form id, we consider it a conflict.
        // These will be fixed by the user in the UI.
        conflictingFormIds[form.formId] = files;
      }

      linkedFormFiles.set(form.formId, files);
    }

    runInAction(() => {
      this.deploymentConflictsIds = conflictingFormIds;
      this.linkedFormFiles = linkedFormFiles;
      this.isLoading = false;
    });

    return linkedFormFiles;
  }

  /**
   * Resolves a conflict by removing all the other files that don't match the given file id.
   * @param {*} formId
   * @param {*} fileId
   */
  resolveConflict(formId, fileId) {
    if (!this.deploymentConflictsIds[formId]) {
      return;
    }

    runInAction(() => {
      this.deploymentConflictsIds[formId] = [{ fileId }];
      this.#updateFetchedFormFileContentByFileId(fileId, formId);
    });
  }

  /**
   * Updates the fetched form file content for the given file id.
   * @param {*} fileId
   * @param {*} formId
   */
  #updateFetchedFormFileContentByFileId(fileId, formId) {
    const linkedFormFile = this.linkedFormFiles.get(formId)?.find((file) => file.fileId === fileId);
    if (!linkedFormFile) {
      throw new Error(`Unable to find file with id ${fileId}`);
    }
    this.fetchedFormFileContents.set(linkedFormFile.formId, linkedFormFile.content);
  }

  get supportsLinkToForm() {
    const version = deploymentStore.selectedClusterVersion;

    if (!version) {
      return false;
    }

    const coercedVersion = semver.coerce(version);

    return (
      this.minimumClusterVersions.includes(version.toLowerCase()) ||
      this.minimumClusterVersions.some((minimumVersion) =>
        semver.valid(coercedVersion) && semver.valid(minimumVersion)
          ? semver.gte(coercedVersion, minimumVersion)
          : false
      )
    );
  }

  get linkedForms() {
    return this.linkedElements?.filter((el) => el.formId);
  }

  get embeddedForms() {
    return this.linkedElements?.filter((el) => el.formBody);
  }

  get diagramHasEmbeddedForms() {
    return this.embeddedForms?.length > 0;
  }

  get diagramHasLinkedForms() {
    return this.linkedForms?.length > 0;
  }

  get diagramHasOnlyEmbeddedForms() {
    return this.diagramHasEmbeddedForms && !this.diagramHasLinkedForms;
  }

  get diagramHasOnlyLinkedForms() {
    return !this.diagramHasEmbeddedForms && this.diagramHasLinkedForms;
  }

  /**
   * Returns true if there are multiple linked forms for the same form id.
   * @returns {boolean}
   */
  get diagramHasConflictingLinkedForms() {
    return Object.values(this.deploymentConflictsIds).some((conflicts) => conflicts.length > 1);
  }

  /**
   * Returns a flat list of resolved conflicts.
   * @returns {Array} The resolved conflicts
   */
  get formResolutions() {
    const resolutions = [];

    for (const formId in this.deploymentConflictsIds) {
      const conflicts = this.deploymentConflictsIds[formId];

      if (conflicts.length === 1) {
        resolutions.push(conflicts[0].fileId);
      }
    }

    // We return undefined instead of an empty array, so that output, when passed as a payload to the backend, gets ignored.
    return resolutions.length ? resolutions : undefined;
  }

  /**
   * Returns true if the given element is linked to a form ID that is used by multiple forms but not resolved yet.
   * @param {*} element
   * @returns
   */
  linkedElementHasUnresolvedFormConflicts(element) {
    const { formId: linkedFormId } = element;

    if (!linkedFormId) {
      return false;
    }

    return this.deploymentConflictsIds[linkedFormId]?.length > 1;
  }

  /**
   * Returns a map of form ids that are used by the linked elements.
   * The map comprehends also the resolved conflicts.
   */
  get usedFormIds() {
    if (!this.linkedFormFiles.size) {
      return undefined;
    }

    const formIds = {};

    for (const form of this.linkedForms) {
      if (Object.keys(this.deploymentConflictsIds).includes(form.formId)) {
        // If the form is conflicting, we skip it for the moment.
        continue;
      }

      const formFile = this.linkedFormFiles.get(form.formId)?.[0];

      if (!formFile) {
        continue;
      }

      formIds[formFile.formId] = formFile.fileId;
    }

    for (const formId in this.deploymentConflictsIds) {
      const conflicts = this.deploymentConflictsIds[formId];

      if (conflicts.length === 1) {
        formIds[formId] = conflicts[0].fileId;
      }
    }

    return Object.keys(formIds).length ? formIds : undefined;
  }
}

export default new FormLinkStore();
