/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineNotification } from '@carbon/react';
import { observer } from 'mobx-react';

import { publicationStore } from 'App/Pages/Diagram/stores';

const PublicationClusterMessage = ({ selectedCluster, clusters }) => {
  const { isStartEventAttachedToForm, isPublicAccessSupported } = publicationStore;
  const shouldShowPublicationMessage = isPublicAccessSupported && isStartEventAttachedToForm;

  if (!shouldShowPublicationMessage) {
    return null;
  }

  if (clusters?.length === 0) {
    return (
      <InlineNotification kind="info" lowContrast hideCloseButton>
        To enable the publication feature, create a cluster with Zeebe version 8.3.0 or higher
      </InlineNotification>
    );
  }

  const isClusterSupported = publicationStore.isClusterSupported(selectedCluster?.generation?.versions?.zeebe);

  if (selectedCluster && !isClusterSupported && publicationStore.isPublicAccessEnabled) {
    return (
      <InlineNotification
        title="Your cluster version does not support the publication feature"
        kind="warning"
        lowContrast
        hideCloseButton
      >
        To enable the publication feature, update your cluster to Zeebe version 8.3.0 or higher
      </InlineNotification>
    );
  }

  return null;
};

export default observer(PublicationClusterMessage);
