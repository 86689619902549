/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { useClusterSelection } from 'App/Pages/Project/IdpApplication/hooks';

import SettingsModalCommon from './SettingsModal.common';
import { ClusterSelection } from './../cluster-selection';

export const SettingsModal = observer(({ isOpen, onClose, clusterId }) => {
  const { clusters, selectedCluster, setSelectedCluster } = useClusterSelection({ reloadClusters: isOpen, clusterId });

  return (
    <SettingsModalCommon
      variant="lg"
      isOpen={isOpen}
      onClose={onClose}
      clusters={clusters}
      modalContentExtensionSection={
        <ClusterSelection
          clusters={clusters}
          selectedClusterId={selectedCluster?.uuid}
          onClusterIdSelection={(clusterId) =>
            setSelectedCluster(clusters?.find((cluster) => cluster.uuid === clusterId))
          }
        />
      }
      getAdditionalParametersWhenFinalizingCreation={() => [selectedCluster?.uuid]}
    />
  );
});
