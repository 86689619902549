/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { Tooltip } from 'primitives';
import { Pencil, FatPlus } from 'icons';
import { currentDiagramStore } from 'stores';

import ListOfChanges from './ListOfChanges';

const IconContainer = styled.div`
  position: absolute;

  ${({ $iconScale }) =>
    $iconScale &&
    `
      transform: translateX(-50%) translateY(-50%) scale(${$iconScale}, ${$iconScale});
    `};

  ${({ iconPosition }) =>
    iconPosition &&
    `
      top: ${iconPosition.y}%;
      left: ${iconPosition.x}%;
    `}
`;

const HoverContainer = styled.div`
  cursor: pointer;

  ${(props) =>
    props.$useHitbox &&
    `
      width: ${props.width}px;
      height: ${props.height}px;
    `};
`;

const getIconByType = (type) =>
  type === 'added' ? <FatPlus width="20" height="20" /> : <Pencil width="20" height="20" />;

const Overlay = (props) => {
  const { type, useHitbox, width, height, iconPosition, elementType } = props;
  const className = `rounded ${type}`;
  const [iconRef, setIconRef] = React.useState();

  return (
    <Tooltip
      align="top"
      justify="left"
      title={<ListOfChanges items={props.details} elementType={elementType} />}
      interactive
      anchorEl={iconRef}
    >
      <HoverContainer $useHitbox={useHitbox} width={width} height={height}>
        <IconContainer
          className={className}
          ref={setIconRef}
          iconPosition={iconPosition}
          $iconScale={currentDiagramStore.iconScale}
          data-test="diffing-container"
        >
          {getIconByType(type)}
        </IconContainer>
      </HoverContainer>
    </Tooltip>
  );
};

export default observer(Overlay);
