/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('services/IdpProjectService').IdpProject} IdpProject
 */

import { useState, useEffect } from 'react';

import { idpProjectService } from 'services';
/**
 * @param {String} idpProjectId
 * @returns {IdpProject}
 */
const useLoadIdpProject = (idpProjectId) => {
  const [idpProject, setIdpProject] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const idpProject = await idpProjectService.fetchIdpProject(idpProjectId);
        setIdpProject(idpProject);
      } catch {
        console.error('Failed to fetch IDP project!');
      }
    })();
  }, [idpProjectId]);

  return idpProject;
};

export default useLoadIdpProject;
