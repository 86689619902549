/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { DefinitionTooltip, Link, TextInput } from '@carbon/react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';

import { notificationStore } from 'components/NotificationSystem';
import { processApplicationService, trackingService } from 'services';
import { processApplicationStore } from 'stores';

import * as Styled from './VersionCreationModal.styled';
import { useIsModalOpen } from './use-is-modal-open';
import { usePlaceholder } from './use-placeholder';
import { showSuccessNotification } from './show-success-notification';

export function VersionCreationModal({
  processApplicationId,
  onVersionCreated = () => {},
  isConnectorTemplatePublish = false,
  connectorTemplateName = '',
  isShowLinkToVersionsPage = false
}) {
  const history = useHistory();
  const { isOpen, closeVersionCreationModal } = useIsModalOpen();
  const [processAppVersionTag, setProcessAppVersionTag] = useState('');
  const { placeholder, helperText } = usePlaceholder(processApplicationId, isOpen);

  const hasValidVersion = processAppVersionTag.trim().length;

  const createProcessApplicationVersion = async (name) => {
    await processApplicationService.createProcessApplicationVersion(processApplicationId, name);
    onVersionCreated();

    trackingService.trackProcessAppVersionCreation();
  };

  const handleVersionCreation = () => {
    if (hasValidVersion) {
      createProcessApplicationVersion(processAppVersionTag)
        .then(() => {
          showSuccessNotification({
            isConnectorTemplatePublish,
            isShowLinkToVersionsPage,
            onVersionsListClick: () =>
              history.push(`/process-applications/${processApplicationStore.processApplication?.id}/versions`)
          });
        })
        .catch(() =>
          notificationStore.showError(
            'Yikes! Could not create the version for this process application. Please try again later.'
          )
        );
      setProcessAppVersionTag('');
      closeVersionCreationModal();
    }
  };

  return isOpen
    ? createPortal(
        <Styled.ProcessApplicationVersionModal
          open={isOpen}
          modalHeading={
            isConnectorTemplatePublish ? 'Create new version and publish it to a project' : 'Create new version'
          }
          primaryButtonText="Create"
          primaryButtonDisabled={!hasValidVersion}
          secondaryButtonText="Cancel"
          onRequestSubmit={handleVersionCreation}
          onRequestClose={closeVersionCreationModal}
          preventCloseOnClickOutside
        >
          <Styled.ModalContent>
            <Styled.VersionDescription>
              {isConnectorTemplatePublish && (
                <span>
                  {`Publish "${connectorTemplateName}" to enable it in your project and allow collaborators to use it in
                their diagrams.`}
                </span>
              )}
              <span>Use versioning to track and review changes across all resources in the process application.</span>
              <Link
                href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#process-application-versioning"
                target="_blank"
              >
                Learn more
              </Link>
            </Styled.VersionDescription>

            <TextInput
              data-modal-primary-focus
              id="version-tag"
              aria-labelledby="version-tag-label"
              labelText={
                <DefinitionTooltip
                  autoAlign
                  definition={
                    <span role="tooltip">
                      When you create or restore a version, its version tag is saved in the main process's XML,
                      overwriting the existing tag. Tip: Use semantic versioning to make relationships between versions
                      clear.
                    </span>
                  }
                  openOnHover
                  align="right"
                >
                  <label id="version-tag-label">Version tag</label>
                </DefinitionTooltip>
              }
              placeholder={placeholder}
              helperText={helperText}
              value={processAppVersionTag}
              onChange={(event) => {
                setProcessAppVersionTag(event.target.value);
              }}
              autoComplete="off"
            />
          </Styled.ModalContent>
        </Styled.ProcessApplicationVersionModal>,
        document.body
      )
    : null;
}
