/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const List = styled.ul`
  margin: 0px;
  padding: 0px;

  font-size: 14px;
  line-height: 1.1;

  list-style: none;

  > li:not(:last-child) {
    margin-bottom: var(--spacing-small);
  }
`;
