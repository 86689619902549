/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { organizationStore, clustersStore } from 'stores';
import { clusterService, trackingService, authService } from 'services';
import { Tooltip } from 'primitives';

export function ClusterUpdateNotification({ children, isModalOpen, latestDeployedClusterId }) {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [clusterName, setClusterName] = useState('');

  const { currentOrganizationId } = organizationStore;

  useEffect(() => {
    // hide the notification when the modal is opened
    setIsNotificationVisible(false);
  }, [isModalOpen]);

  useEffect(() => {
    if (!authService.token) {
      return;
    }

    const stream = clusterService.getEventStream(authService.token);

    stream.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.org !== currentOrganizationId || data.entity !== 'cluster') {
        return;
      }

      if (['created', 'upgraded', 'deleted', 'updated'].includes(data.type)) {
        // update cluster info to refresh execute modal contents
        clustersStore.loadClusters();
      }

      if (data.type === 'created' && !latestDeployedClusterId) {
        // a cluster became available to deploy the process to
        setClusterName(data.payload.name);
        setIsNotificationVisible(true);
        return;
      }
      if (data.type === 'upgraded' && latestDeployedClusterId === data.payload.uuid) {
        // The cluster this process is deployed to has been updated
        setClusterName(data.payload.name);
        setIsNotificationVisible(true);
      }
    };

    return () => {
      stream?.close();
    };
  }, [latestDeployedClusterId, currentOrganizationId, authService.token]);

  const showTooltip = isNotificationVisible && !isModalOpen;

  useEffect(() => {
    if (showTooltip) {
      trackingService.trackPopover('clusterReadyNotification');
    }
  }, [isNotificationVisible]);

  if (showTooltip) {
    return (
      <Tooltip keepOpen title={`The ${clusterName} cluster is now available to execute your process.`} showAfter={1}>
        {children}
      </Tooltip>
    );
  }
  return children;
}

export default observer(ClusterUpdateNotification);
