/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from '@carbon/react';
import styled from 'styled-components';

export const ProjectsDropdown = styled(Dropdown)`
  margin-bottom: var(--spacing-small);
`;

export const CreateNewProjectItem = styled.div`
  color: var(--cds-blue-60);
`;
