/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, runInAction } from 'mobx';

import { discoverConnectorsService } from './';

class DiscoverConnectorsStore {
  resourcesToImport = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    // initialize store and fetch data if needed
  };

  reset = () => {
    // clear the fetched data and reset the store
    this.resourcesToImport = [];
  };

  async importTemplate(connectorId, iconUrl) {
    const { templates } = await discoverConnectorsService.fetchConnectorsDetailsData(connectorId);

    if (templates) {
      const templatesWithIcons = templates.flatMap((template) => {
        if (template.url) {
          return template.url
            .split(',')
            .map((templateUrl) => {
              let templateCopy = JSON.parse(JSON.stringify(template));
              templateCopy.iconUrl = iconUrl;
              templateCopy.url = templateUrl;
              return templateCopy;
            })
            .flat();
        } else {
          return []; // Return an empty array if template.url is not defined or null
        }
      });
      runInAction(() => {
        this.resourcesToImport = templatesWithIcons;
      });
    }
  }
}

export default new DiscoverConnectorsStore();
