/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, autorun, makeObservable, observable } from 'mobx';

import { dedicatedModesStore } from 'App/Pages/Diagram/stores';

class TokenSimulationStore {
  isTokenSimulationOn = false;
  onTokenSimulationChange = () => {};
  cleanup = () => {};
  dedicatedModesStoreDisposer = () => {};

  constructor() {
    makeObservable(this, {
      isTokenSimulationOn: observable,
      onTokenSimulationChange: observable,
      reset: action,
      toggleTokenSimulation: action,
      setOnTokenSimulationChange: action,
      setCleanup: action
    });
  }

  init() {
    this.#listenToDedicatedModeChange();
  }

  #listenToDedicatedModeChange() {
    this.dedicatedModesStoreDisposer = autorun(() => {
      if (!dedicatedModesStore.isDesignMode) {
        this.onTokenSimulationChange(false);
      }
    });
  }

  reset() {
    this.cleanup();
    this.isTokenSimulationOn = false;
    this.cleanup = () => {};
    this.dedicatedModesStoreDisposer();
    this.dedicatedModesStoreDisposer = () => {};
  }

  toggleTokenSimulation = () => {
    this.isTokenSimulationOn = !this.isTokenSimulationOn;
  };

  setOnTokenSimulationChange = (fn) => {
    this.onTokenSimulationChange = fn;
  };

  setCleanup = (fn) => {
    this.cleanup = fn;
  };
}

export default new TokenSimulationStore();
