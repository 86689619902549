/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * Returns true if the connector type is any type of inbound connector
 * @param type the connector type
 * @returns boolean
 */
export const isAnyInboundConnector = (type) => {
  return isWebhookConnector(type) || isPollingConnector(type) || isSubscriptionConnector(type);
};

/**
 *
 * @param type the connector type
 * @returns boolean if is generic Webhook connector or i.e. GitHub
 */
export const isWebhookConnector = (type) => {
  return type?.includes('webhook');
};

/**
 *
 * @param type the connector type
 * @returns boolean if is a Polling connector i.e. Polling
 */
export const isPollingConnector = (type) => {
  return type?.includes('polling');
};

/**
 *
 * @param type the connector type
 * @returns boolean if is a Subscription connector i.e. Kafka, RabbitMQ
 */
export const isSubscriptionConnector = (type) => {
  return type?.includes('subscription') || type?.includes('inbound');
};

/**
 * Returns the selected inbound functionality name we should display in properties panel
 * @param type the connector type
 * @returns {string}
 */
export const getSelectedInboundFunctionality = (type) => {
  if (isWebhookConnector(type)) {
    return 'webhook';
  } else if (isPollingConnector(type)) {
    return 'polling';
  } else if (isSubscriptionConnector(type)) {
    return 'subscription';
  }
  return '';
};

/**
 * Returns the selected inbound entity name we should display in the notification message
 * @param {*} type the connector type
 * @returns {string}
 */
export const getSelectedInboundShareableEntity = (type) => {
  const shareableEntities = {
    webhook: 'link',
    subscription: 'subscription'
  };

  return shareableEntities[getSelectedInboundFunctionality(type)] || 'text';
};
