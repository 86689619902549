/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useMemo } from 'react';

/**
 * Replaces iframe embeds in markdown content with placeholder messages
 * @param {string} content - The markdown content that may contain iframe embeds
 * @returns {string} The processed content with iframes replaced
 */
export const useTrimEmbeds = (content) => {
  return useMemo(() => {
    if (!content) {
      return '';
    }

    const iframeRegex = /<iframe[^>]*>.*?<\/iframe>|!\[[^\]]*\]\([^)]*\){:target="_blank"}/g;

    // Replace matches with a placeholder message
    return content.replace(iframeRegex, '> _Hidden embedded content, open the readme to view it in full_');
  }, [content]);
};
