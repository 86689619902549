/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from 'components';

import editReviewModalsStore from './EditReviewModalsStore';

function EditReviewListItem({ versionId, closeVersionMenuDropdown }) {
  return (
    // @ts-expect-error TS2554
    <Dropdown.ListItem hasSubDropdown>
      Edit review
      <Dropdown.SubDropdown>
        {/** @ts-expect-error TS2554 */}
        <Dropdown.ListItem
          onClick={() => {
            editReviewModalsStore.openApproveModal(versionId);
            closeVersionMenuDropdown();
          }}
        >
          Approve
        </Dropdown.ListItem>
        {/** @ts-expect-error TS2554 */}
        <Dropdown.ListItem
          onClick={() => {
            editReviewModalsStore.openRequestChangesModal(versionId);
            closeVersionMenuDropdown();
          }}
        >
          Request Changes
        </Dropdown.ListItem>
      </Dropdown.SubDropdown>
    </Dropdown.ListItem>
  );
}

export default EditReviewListItem;
