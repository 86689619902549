/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

/**
 * Custom hook that selects the draft version if the currently selected version is deleted.
 *
 * @param {Array} versions - Array of IDP project version objects.
 * @param {string} selectedMilestoneId - The ID of the currently selected IDP project version milestone.
 * @param {Function} setSelectedMilestoneId - Function to set the selected PA version milestone ID.
 */
const useFirstVersionIfSelectedVersionIsDeleted = (versions, selectedMilestoneId, setSelectedMilestoneId) => {
  // Check if the selected version is still available (not deleted)
  useEffect(() => {
    if (!versions.some((version) => version.milestoneId === selectedMilestoneId) && versions.length > 0) {
      setSelectedMilestoneId(versions[0].milestoneId);
    }
  }, [versions, selectedMilestoneId]);
};

export { useFirstVersionIfSelectedVersionIsDeleted };
