/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import BPMNModdle from 'bpmn-moddle';
import DMNModdle from 'dmn-moddle';

import { BPMN } from 'utils/constants';
import { notificationStore } from 'stores';
import { trackingService } from 'services';

import saveFile from './save-file';

/**
 * Converts a given diagram into XML and downloads
 * it to the user's computer.
 *
 * @param {Object} modeler An instance of the bpmn-js Modeler with access to the `saveXML` method.
 * @param {Object} diagram
 * @param {String} [diagram.name] The diagram's name.
 * @param {String} [diagram.type] The diagram's type, like `BPMN`.
 */
export default async function exportDiagramXML(modeler, diagram) {
  try {
    const fileName = `${diagram.name}.${diagram.type.toLowerCase()}`;

    if (diagram.type === BPMN) {
      const alignToOrigin = modeler.get('alignToOrigin');
      alignToOrigin.align();
    }

    // By doing changes on the XML, we might implicitly change the format of the content
    // We skip the format at this step, and we perform it right before saving the content.
    const { xml } = await modeler.saveXML({ format: false });

    const safeXml = await formatXml(xml, diagram.type);
    saveFile(safeXml, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport({
      fileId: diagram.id,
      fileTypeKey: diagram.type,
      exportType: 'xml',
      from: 'diagram',
      fileCount: 1,
      fileSize: safeXml?.length
    });
  } catch (ex) {
    console.error(ex);
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
  }
}

async function formatXml(source, diagramType) {
  let moddle;

  if (diagramType === 'BPMN') {
    moddle = new BPMNModdle();
  } else if (diagramType === 'DMN') {
    moddle = new DMNModdle();
  } else {
    notificationStore.showError(`Diagram of type '${diagramType}' not supported for export.`);
    return;
  }

  const { rootElement: definitions } = await moddle.fromXML(source);
  const { xml: newXml } = await moddle.toXML(definitions, { format: true });

  return newXml;
}
