/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { TabPanel as BaseTabPanel } from '@carbon/react';

export const TabsContainer = styled.main`
  margin: 40px 40px 0 40px;
  overflow-y: auto;
`;

export const TabPanel = styled(BaseTabPanel)`
  padding: 24px 0 0;
`;

export const TabContentContainer = styled.main`
  display: flex;
  flex: 1;
`;
