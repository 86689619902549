/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Link, Callout } from '@carbon/react';

import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import { organizationStore } from 'stores';

import { useDiagramWithMissingClients } from './useDiagramWithMissingClients';

const MissingClientsMessage = ({ diagramXml, diagramType }) => {
  const { selectedClusterId } = deploymentStore;
  const { currentOrganization } = organizationStore;

  const hasMissingClient = useDiagramWithMissingClients({
    diagramXml,
    diagramType,
    currentOrganization,
    selectedClusterId
  });

  if (!hasMissingClient) {
    return null;
  }

  return (
    <Callout
      title="API client credentials are missing"
      titleId="missing-clients-notification-title"
      subtitle={
        <>
          One or more elements in your diagram should be completed by API. Create a client credential for the selected
          cluster.{' '}
          <Link
            href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/run-or-publish-your-process/#missing-credentials"
            inline
            target="_blank"
          >
            Learn more
          </Link>
        </>
      }
      kind="warning"
      lowContrast
      actionButtonLabel={'Create client'}
      onActionButtonClick={() => {
        window.open(`${organizationStore.consoleDashboardPageUrl}/cluster/${selectedClusterId}/api`);
      }}
    />
  );
};

export default observer(MissingClientsMessage);
