/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, Tag } from '@carbon/react';
import { CaretLeft, CaretRight } from '@carbon/icons-react';
import { useEffect, useState } from 'react';

import * as Styled from './FileSwitcher.styled';

/**
 * `fileOrMilestoneIds` list is used to determine the number of files.
 * - It contains fileIds if the draft is selected and milestoneIds if a saved version is selected.
 *
 * `fileSelection` object contains the details of the selected file & its previous version.
 *
 * `selectFile` is used to update the `fileSelection` based on the file or milestone id passed.
 *
 * @param {Object} props
 * @param {Array<String>} props.fileOrMilestoneIds // Contains fileIds if the draft version is selected & milestoneIds if a saved version is selected
 * @param {import('./use-file-selection').FileSelection} props.fileSelection
 * @param {import('./use-file-selection').SelectFileFunction} props.selectFile
 * @param {string} props.mainProcessFileId
 */
export default function FileSwitcher({ fileOrMilestoneIds, fileSelection, selectFile, mainProcessFileId }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useResetSelectedIndexOnFileListChange(fileOrMilestoneIds, setSelectedIndex);

  if (!fileSelection || !fileOrMilestoneIds) {
    return;
  }

  const selectPreviousFile = () => {
    const previousIndex = selectedIndex - 1;
    selectFile(fileOrMilestoneIds[previousIndex]);
    setSelectedIndex(previousIndex);
  };

  const selectNextFile = () => {
    const nextIndex = selectedIndex + 1;
    selectFile(fileOrMilestoneIds[nextIndex]);
    setSelectedIndex(nextIndex);
  };

  const { current, fileId } = fileSelection;
  const fileCount = fileOrMilestoneIds.length;

  const isMainProcess = fileId === mainProcessFileId;
  const isFirstFileSelected = selectedIndex === 0;
  const isLastFileSelected = selectedIndex === fileCount - 1;

  return (
    <Styled.Container data-test="file-switcher">
      <Styled.Content>
        <Styled.FileName>
          {current.fileName}
          {isMainProcess && <Tag size="sm">Main process</Tag>}
        </Styled.FileName>
        <Styled.FilesList>
          <Button
            size="sm"
            kind="ghost"
            aria-label="Previous file"
            renderIcon={CaretLeft}
            iconDescription="Previous file"
            tooltipPosition="bottom"
            hasIconOnly
            onClick={selectPreviousFile}
            disabled={isFirstFileSelected}
          />
          <Styled.SelectedFile>{`${selectedIndex + 1}/${fileCount} files`}</Styled.SelectedFile>
          <Button
            size="sm"
            kind="ghost"
            aria-label="Next file"
            renderIcon={CaretRight}
            iconDescription="Next file"
            tooltipPosition="bottom"
            hasIconOnly
            onClick={selectNextFile}
            disabled={isLastFileSelected}
          />
        </Styled.FilesList>
      </Styled.Content>
    </Styled.Container>
  );
}

const useResetSelectedIndexOnFileListChange = (fileOrMilestoneIds, setSelectedIndex) => {
  useEffect(() => {
    if (fileOrMilestoneIds) {
      setSelectedIndex(0);
    }
  }, [fileOrMilestoneIds]);
};
