/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Modal } from '@carbon/react';
import { observer } from 'mobx-react';

import { currentDiagramStore } from 'stores';

import invalidDiagramDialogStore from './InvalidDiagramDialogStore';
import * as Styled from './InvalidDiagramDialog.styled';

function InvalidDiagramDialog() {
  const { healthState } = currentDiagramStore;
  const { isOpen, hasBeenDismissed } = invalidDiagramDialogStore;

  if (hasBeenDismissed || !isOpen) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading="Invalid diagram"
      primaryButtonText="Ask in Forum"
      secondaryButtonText="Close"
      onRequestClose={() => invalidDiagramDialogStore.close()}
      onRequestSubmit={() => {
        window.open('https://forum.camunda.io/c/modeler/', '_blank');
        invalidDiagramDialogStore.close();
      }}
    >
      <Styled.CodeSnippet type="single" align="bottom-right" feedback="Copied to clipboard">
        {healthState?.error?.message}
      </Styled.CodeSnippet>
      <p>
        <strong>Do you believe that this diagram is valid?</strong>
      </p>
      <p>Post this error with your diagram in our forum for help.</p>
    </Modal>
  );
}

export default observer(InvalidDiagramDialog);
