/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { PopoverContent, ContentSwitcher, Switch, Stack } from '@carbon/react';

export const IconUploadMainStack = styled(Stack)`
  input {
    background-color: #f4f4f4;
  }
  .cds--file__drop-container {
    background-color: #f4f4f4;
  }

  button {
    margin: 0px !important;
  }
`;

export const IconUploadPopoverHeader = styled.h2`
  color: var(--cm-color-black-primary-text, #161616);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`;

export const IconUploadPopoverContent = styled(PopoverContent)`
  max-inline-size: max-content;
  inline-size: 40em;
  padding: 1rem;
`;

export const IconUploadContentSwitcher = styled(ContentSwitcher)`
  max-width: 23em;
`;

export const SwitchButton = styled(Switch)`
  margin: 0px !important;
`;

export const IconUploadPopoverContentButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 9.7rem;
    margin: 0px !important;
  }
`;
