/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Stack, Toggle, ToggleSkeleton } from '@carbon/react';

import { organizationStore } from 'stores';

import { useDeploymentPolicy } from './use-deployment-policy';
import { DescriptionText } from './DescriptionText';

function ProcessApplicationDeploymentSettings() {
  const { hasElevatedOrganizationPermissions, currentOrganizationId } = organizationStore;
  const [isLoading, isReviewRequired, toggleDeploymentPolicy] = useDeploymentPolicy(currentOrganizationId);

  return (
    <Stack gap={2}>
      {isLoading ? (
        <ToggleSkeleton />
      ) : (
        <Toggle
          hideLabel
          size="sm"
          labelText="Allow deployments to production stage after approval"
          toggled={isReviewRequired}
          onToggle={toggleDeploymentPolicy}
          disabled={!hasElevatedOrganizationPermissions}
          id="deployment"
        />
      )}
      <DescriptionText />
    </Stack>
  );
}

export default observer(ProcessApplicationDeploymentSettings);
