/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isEmpty } from 'utils/helpers';
import { BPMN_DIAGRAM_DEFAULT_NAME } from 'utils/file-mapper/file-mapper';

export const checkAndSyncProcessName = (modeler, name) => {
  const modeling = modeler.get('modeling');
  const canvas = modeler.get('canvas');

  const currentName = canvas?.getRootElement()?.businessObject?.get('name');
  const proposedNameIsNotDefaultOne = !isEmpty(name) && name !== BPMN_DIAGRAM_DEFAULT_NAME;
  const isCurrentNameReplaceable = isEmpty(currentName) || currentName === BPMN_DIAGRAM_DEFAULT_NAME;
  if (isCurrentNameReplaceable && proposedNameIsNotDefaultOne) {
    modeling?.updateProperties(canvas?.getRootElement(), { name });
  }
};
