/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link } from '@carbon/react';
import styled from 'styled-components';

export function EmptyState({ isNoVersionsPresent }) {
  return (
    isNoVersionsPresent && (
      <Container>
        <Heading>No versions yet</Heading>
        <div>
          Use versioning to track and review changes across all resources in a process application. Once a version is
          approved, you can promote it to the next stage(s) of the deployment pipeline.
        </div>
        <br />
        <div>
          Create a version by clicking the <strong>Create version</strong> button.
        </div>
        <Link
          href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#process-application-versioning"
          target="_blank"
        >
          Learn more about versions
        </Link>
      </Container>
    )
  );
}

const Container = styled.div`
  padding: var(--spacing-small);
`;

const Heading = styled.h6`
  margin-bottom: var(--spacing-tiny);
`;
