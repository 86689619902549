/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const LIST_TYPES = {
  ADDED: 'ADDED',
  CHANGED: 'CHANGED',
  REMOVED: 'REMOVED',
  MOVED: 'MOVED'
};

export const listTypeConfig = {
  ADDED: { label: 'Added', tagType: 'green' },
  CHANGED: { label: 'Changed', tagType: 'teal' },
  REMOVED: { label: 'Removed', tagType: 'red' },
  MOVED: { label: 'Moved', tagType: 'blue' }
};
