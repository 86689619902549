/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { lightGreen, lightOrange, lightRed } from 'utils/diffing';

// TODO: write this custom definition generation in a more readable way

/**
 * this function clones sequence and message flow markers (arrow heads and starting points)
 * with a different color (green and orange). To paint added sequence and message flows as well
 * as changed sequence and message flows respectively with the desired color.
 */
export default () => {
  if (!document.querySelector('#green-arrow')) {
    const defs = document.querySelector('defs');
    if (!defs) {
      return;
    }

    const sequenceFlowArrow = defs.querySelector('defs > marker[id^="sequenceflow-end-white-black"]');
    const messageFlowArrow = defs.querySelector('defs > marker[id^="messageflow-end-white-black"]');
    const messageFlowEnd = defs.querySelector('defs > marker[id^="messageflow-start-white-black"]');
    const conditionalFlowEnd = defs.querySelector('defs > marker[id^="conditional-flow-marker-white-black"]');

    const conditionalDefaultFlow = defs.querySelector('defs > marker[id^="conditional-default-flow-marker"]');

    const documentFragment = document.createDocumentFragment();

    // green arrow
    if (sequenceFlowArrow) {
      const clonedArrowGreen = sequenceFlowArrow.cloneNode(true);
      clonedArrowGreen.setAttribute('id', 'green-sequenceflow-arrow');
      const clonedArrowGreenPath = clonedArrowGreen.querySelector('path');
      clonedArrowGreenPath.style.fill = lightGreen;
      clonedArrowGreenPath.style.stroke = lightGreen;

      documentFragment.appendChild(clonedArrowGreen);
    }

    if (messageFlowArrow) {
      const clonedMessageFlowArrowGreen = messageFlowArrow.cloneNode(true);
      clonedMessageFlowArrowGreen.setAttribute('id', 'green-messageflow-arrow');

      const clonedMessageFlowArrowGreenPath = clonedMessageFlowArrowGreen.querySelector('path');
      clonedMessageFlowArrowGreenPath.style.stroke = lightGreen;

      documentFragment.appendChild(clonedMessageFlowArrowGreen);
    }

    if (messageFlowEnd) {
      const clonedMessageFlowEndGreen = messageFlowEnd.cloneNode(true);
      clonedMessageFlowEndGreen.setAttribute('id', 'green-messageflow-end');
      const clonedMessageFlowEndGreenPath = clonedMessageFlowEndGreen.querySelector('circle');
      clonedMessageFlowEndGreenPath.style.stroke = lightGreen;

      documentFragment.appendChild(clonedMessageFlowEndGreen);
    }

    if (conditionalDefaultFlow) {
      const clonedConditionalDefaultFlowGreen = conditionalDefaultFlow.cloneNode(true);
      clonedConditionalDefaultFlowGreen.setAttribute('id', 'green-conditional-default-flow-end');
      const clonedConditionalDefaultFlowGreenPath = clonedConditionalDefaultFlowGreen.querySelector('path');
      clonedConditionalDefaultFlowGreenPath.style.stroke = lightGreen;

      documentFragment.appendChild(clonedConditionalDefaultFlowGreen);
    }

    // orange arrow
    if (sequenceFlowArrow) {
      const clonedArrowOrange = sequenceFlowArrow.cloneNode(true);
      clonedArrowOrange.setAttribute('id', 'orange-sequenceflow-arrow');
      const clonedArrowOrangePath = clonedArrowOrange.querySelector('path');
      clonedArrowOrangePath.style.fill = lightOrange;
      clonedArrowOrangePath.style.stroke = lightOrange;
      documentFragment.appendChild(clonedArrowOrange);
    }

    if (messageFlowArrow) {
      const clonedMessageFlowArrowOrange = messageFlowArrow.cloneNode(true);
      clonedMessageFlowArrowOrange.setAttribute('id', 'orange-messageflow-arrow');
      const clonedMessageFlowArrowOrangePath = clonedMessageFlowArrowOrange.querySelector('path');
      clonedMessageFlowArrowOrangePath.style.stroke = lightOrange;

      documentFragment.appendChild(clonedMessageFlowArrowOrange);
    }

    if (conditionalFlowEnd) {
      const clonedConditionalFlowEndOrange = conditionalFlowEnd.cloneNode(true);
      clonedConditionalFlowEndOrange.setAttribute('id', 'orange-conditionalflow-end');
      const clonedConditionalFlowEndOrangePath = clonedConditionalFlowEndOrange.querySelector('path');
      clonedConditionalFlowEndOrangePath.style.stroke = lightOrange;

      documentFragment.appendChild(clonedConditionalFlowEndOrange);
    }

    if (conditionalDefaultFlow) {
      const clonedConditionalDefaultFlowOrange = conditionalDefaultFlow.cloneNode(true);
      clonedConditionalDefaultFlowOrange.setAttribute('id', 'orange-conditional-default-flow-end');
      const clonedConditionalDefaultFlowOrangePath = clonedConditionalDefaultFlowOrange.querySelector('path');
      clonedConditionalDefaultFlowOrangePath.style.stroke = lightOrange;

      documentFragment.appendChild(clonedConditionalDefaultFlowOrange);
    }

    // red arrow
    if (sequenceFlowArrow) {
      const clonedArrowRed = sequenceFlowArrow.cloneNode(true);
      clonedArrowRed.setAttribute('id', 'red-sequenceflow-arrow');
      const clonedArrowRedPath = clonedArrowRed.querySelector('path');
      clonedArrowRedPath.style.fill = lightRed;
      clonedArrowRedPath.style.stroke = lightRed;
      documentFragment.appendChild(clonedArrowRed);
    }

    if (messageFlowArrow) {
      const clonedMessageFlowArrowRed = messageFlowArrow.cloneNode(true);
      clonedMessageFlowArrowRed.setAttribute('id', 'red-messageflow-arrow');
      const clonedMessageFlowArrowRedPath = clonedMessageFlowArrowRed.querySelector('path');
      clonedMessageFlowArrowRedPath.style.stroke = lightRed;

      documentFragment.appendChild(clonedMessageFlowArrowRed);
    }

    if (conditionalFlowEnd) {
      const clonedConditionalFlowEndRed = conditionalFlowEnd.cloneNode(true);
      clonedConditionalFlowEndRed.setAttribute('id', 'red-conditionalflow-end');
      const clonedConditionalFlowEndRedPath = clonedConditionalFlowEndRed.querySelector('path');
      clonedConditionalFlowEndRedPath.style.stroke = lightRed;

      documentFragment.appendChild(clonedConditionalFlowEndRed);
    }

    if (conditionalDefaultFlow) {
      const clonedConditionalDefaultFlowRed = conditionalDefaultFlow.cloneNode(true);
      clonedConditionalDefaultFlowRed.setAttribute('id', 'red-conditional-default-flow-end');
      const clonedConditionalDefaultFlowRedPath = clonedConditionalDefaultFlowRed.querySelector('path');
      clonedConditionalDefaultFlowRedPath.style.stroke = lightRed;

      documentFragment.appendChild(clonedConditionalDefaultFlowRed);
    }

    defs.appendChild(documentFragment);
  }
};
