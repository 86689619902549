/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Modal } from '@carbon/react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

import { notificationStore } from 'components/NotificationSystem';
import { FILE_TYPE_MAPPING } from 'utils/constants';
import { milestoneService, trackingService } from 'services';

export function PublishToOrganizationModal({
  onClose,
  open,
  milestone,
  template,
  versionName,
  onPublication = () => {}
}) {
  return open
    ? createPortal(
        <Modal
          open={open}
          modalHeading="Publish latest template version to organization"
          primaryButtonText="Publish template"
          secondaryButtonText="Cancel"
          onRequestClose={onClose}
          onRequestSubmit={() => {
            milestoneService
              .publishToOrganization(milestone.id)
              .then(() => {
                onPublication();
                trackingService.trackPublicationMilestoneToOrganization({
                  from: 'publish',
                  fileId: template.id,
                  fileType: FILE_TYPE_MAPPING.CONNECTOR_TEMPLATE
                });
                notificationStore.showSuccess('Latest template version published to the organization.');
              })
              .catch(() => notificationStore.showError('Could not publish the version to the organization.'));
            onClose();
          }}
          preventCloseOnClickOutside
          size="sm"
        >
          <Content>{`Publish "${versionName}" (latest) of "${template.name}" to the organization to allow members to use it in their diagrams.`}</Content>
        </Modal>,
        document.body
      )
    : null;
}

const Content = styled.div`
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;
