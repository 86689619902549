/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';

import { unpublishResourceModalStore } from 'components/UnpublishResourceModal/stores';

export default function EntityActionSharedResources({ resource, organizationId }) {
  const handleUnpublish = () => {
    unpublishResourceModalStore.showUnpublishModal(resource, organizationId);
  };

  return (
    <Fragment>
      <OverflowMenu
        align="top-right"
        flipped
        aria-label="Entity Context Action"
        aria-haspopup="true"
        data-test="entity-context-dropdown"
      >
        <OverflowMenuItem
          isDelete
          key="unpublish-resource"
          itemText="Unpublish"
          data-test="unpublish-shared-resource-entity-action"
          onClick={handleUnpublish}
        />
      </OverflowMenu>
    </Fragment>
  );
}
