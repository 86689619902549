/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { C3AppTeaserPage } from '@camunda/camunda-composite-components';
import styled from 'styled-components';

const AppTeaserWrapper = styled.div`
  padding: 40px;
  overflow: auto;

  h1 {
    font-size: var(--cds-heading-04-font-size);
    font-weight: var(--cds-heading-04-font-weight);
    line-height: var(--cds-heading-04-line-height);
    letter-spacing: var(--cds-heading-04-letter-spacing);
  }

  h2 {
    font-size: var(--cds-heading-03-font-size);
    font-weight: var(--cds-heading-03-font-weight);
    line-height: var(--cds-heading-03-line-height);
    letter-spacing: var(--cds-heading-03-letter-spacing);
  }

  p {
    font-size: var(--cds-body-01-font-size);
    font-weight: var(--cds-body-01-font-weight);
    line-height: var(--cds-body-01-line-height);
    letter-spacing: var(--cds-body-01-letter-spacing);
  }
`;

export const AppTeaser = ({ appName }) => {
  return (
    <AppTeaserWrapper className="app-teaser-page">
      <C3AppTeaserPage appName={appName} />
    </AppTeaserWrapper>
  );
};
