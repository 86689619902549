/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('services/IdpProjectService').IdpExtractionProjectVersionListEntryDto} IdpExtractionProjectVersionListEntryDto
 */

import { useState, useEffect } from 'react';

import { idpProjectService } from 'services';

/**
 * @param {String} idpProjectId
 * @returns {{ versions: IdpExtractionProjectVersionListEntryDto[], reload: () => Promise<void>, isLoading: Boolean }} - An object containing the list of IDP extraction project versions, a reload function and loading indicator.
 */
const useLoadIdpProjectVersions = (idpProjectId) => {
  const [versions, setVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadVersions();
  }, [idpProjectId]);

  const loadVersions = async () => {
    if (idpProjectId) {
      let idpProjectVersions = [];
      try {
        setIsLoading(true);
        idpProjectVersions = (await idpProjectService.getExtractionProjectVersions(idpProjectId)).sort(
          (a, b) => b.created - a.created
        );
      } catch (error) {
        console.error('Error while fetching IDP project versions', error);
      } finally {
        setIsLoading(false);
      }

      setVersions(idpProjectVersions);
    }
  };

  return { versions, reload: loadVersions, isLoading };
};

export default useLoadIdpProjectVersions;
