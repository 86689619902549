/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tag } from '@carbon/react';
import { Enterprise, Folders } from '@carbon/icons-react';

import { organizationStore, userStore } from 'stores';
import { VersionMenu } from 'App/Pages/IdpProjectVersions/features/view-versions-list/version-menu';
import { VersionsList } from 'components';
import { renameIdpProjectVersion } from 'App/Pages/IdpProjectVersions/features/rename';
import { Tooltip } from 'primitives';

import { useFirstVersionIfSelectedVersionIsDeleted } from './use-first-version-if-selected-version-is-deleted';

export default function IdpApplicationVersionsList({
  versions,
  reloadVersions,
  selectedMilestoneId,
  setSelectedMilestoneId,
  isDiffingEnabled
}) {
  const processedVersion = versions.map((version) => ({ ...version, id: version.milestoneId ?? version.id }));
  useFirstVersionIfSelectedVersionIsDeleted(processedVersion, selectedMilestoneId, setSelectedMilestoneId);
  const [latestVersion] = processedVersion.sort((a, b) => b.created - a.created);

  const renderVersionMenu = ({ version, setIsEditing }) => {
    const canPublishToOrganization =
      organizationStore.hasElevatedOrganizationPermissions && version.organizationPublic === false;

    return (
      <VersionMenu
        milestoneId={version.id}
        reloadVersions={reloadVersions}
        setIsEditing={setIsEditing}
        canPublishToOrganization={canPublishToOrganization}
        canRestoreAsLatest={latestVersion?.id !== version.id}
      />
    );
  };

  const renderPublishedTag = ({ version }) => (
    <Tooltip title={`Published to ${version.organizationPublic ? 'organization' : 'project'}`}>
      <Tag renderIcon={version.organizationPublic ? Enterprise : Folders} type="high-contrast">
        <span>Published</span>
      </Tag>
    </Tooltip>
  );

  const renameVersion = async ({ name, version, reloadVersions, setIsEditing }) =>
    await renameIdpProjectVersion(version.id, version.name, name, reloadVersions, setIsEditing);

  return (
    <VersionsList
      versions={processedVersion}
      reloadVersions={reloadVersions}
      selectedVersionId={selectedMilestoneId}
      setSelectedVersionId={(version) => setSelectedMilestoneId(version.id)}
      isDiffingEnabled={isDiffingEnabled}
      currentUserId={userStore.userId}
      renderVersionMenu={renderVersionMenu}
      renderPublishedTag={renderPublishedTag}
      onRenameVersion={renameVersion}
    />
  );
}
