/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import * as Styled from './IconButton.styled';

Styled.IconButton.propTypes = {
  component: PropTypes.element,
  $noRadius: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal']),
  children: PropTypes.node.isRequired
};

Styled.IconButton.defaultProps = {
  size: 'normal'
};

export default Styled.IconButton;
