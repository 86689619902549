/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Link, TextInput } from '@carbon/react';

import { idpProjectStore } from 'stores';
import { IDP_PROJECT_EXTRACTION_METHODS } from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';

import * as Styled from './../CreationModal.styled';

function ExtractionProject() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState(undefined);
  const [extractionMethod] = useState(IDP_PROJECT_EXTRACTION_METHODS[1].value);

  const hasValidIdpProjectName = name.trim().length;

  const closeModal = () => {
    idpProjectStore.setIsExtractionProjectCreationModalVisible(false);
    setName('');
    setDescription(undefined);
  };

  const performRequest = async () => {
    if (!hasValidIdpProjectName) {
      return;
    }
    await idpProjectStore.finalizeCreation(
      name,
      description !== '' ? description : undefined,
      'EXTRACTION',
      extractionMethod
    );
    closeModal();
  };

  return (
    <Styled.IdpProjectModal
      open={idpProjectStore.isExtractionProjectCreationModalVisible}
      modalHeading="New document extraction template"
      primaryButtonText="Create"
      primaryButtonDisabled={!hasValidIdpProjectName}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
      preventCloseOnClickOutside
    >
      <Styled.IdpProjectModalContent>
        <span>
          Use document extraction to define extraction fields to identify and extract data per document type.&nbsp;
          <Link
            href="https://docs.camunda.io/docs/8.7/components/modeler/web-modeler/idp/idp-document-extraction/"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about document extraction
          </Link>
          .
        </span>
        <TextInput
          autoComplete="off"
          data-modal-primary-focus
          enableCounter
          id="idp-project-name"
          labelText="Name"
          placeholder="The name should reflect the project document type. i.e. Invoice"
          value={name}
          maxCount={100}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <TextInput
          autoComplete="off"
          data-modal-primary-focus
          enableCounter
          id="idp-project-description"
          labelText="Description"
          placeholder="Describe the document type this template should extract"
          value={description}
          maxCount={100}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
        {/* Uncomment when structured form extraction is implemented */}
        {/* <Dropdown
          id="idp-project-extraction-method"
          titleText="Extraction method"
          helperText="Choose the extraction method based on your document structure"
@@ -87,7 +101,7 @@ function ExtractionProject() {
              <span>{item.description}</span>
            </>
          )}
        />
        /> */}
      </Styled.IdpProjectModalContent>
    </Styled.IdpProjectModal>
  );
}

export default observer(ExtractionProject);
