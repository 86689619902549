/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { trackingService } from 'services';

export default class FormAssistantTracking {
  constructor(eventBus) {
    this._eventBus = eventBus;

    this._eventBus.on('form.assistant.open', ({ origin }) => {
      trackingService.trackFormAssistantOpen(origin);
    });

    this._eventBus.on('form.assistant.close', ({ page }) => {
      trackingService.trackFormAssistantClose(page);
    });

    this._eventBus.on('form.assistant.generation.start', ({ prompt }) => {
      trackingService.trackFormAssistantGenerationStart(prompt);
    });

    this._eventBus.on('form.assistant.generation.error', ({ errorMessage }) => {
      trackingService.trackFormAssistantGenerationError(errorMessage);
    });

    this._eventBus.on('form.assistant.generation.success', () => {
      trackingService.trackFormAssistantGenerationSuccess();
    });

    this._eventBus.on('form.assistant.import.error', ({ errorMessage }) => {
      trackingService.trackFormAssistantImportError(errorMessage);
    });

    this._eventBus.on('form.assistant.import.success', () => {
      trackingService.trackFormAssistantImportSuccess();
    });
  }
}

FormAssistantTracking.$inject = ['eventBus'];
