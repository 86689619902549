/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Helmet } from 'react-helmet-async';

import { TopBar, Breadcrumb } from 'components';
import { getPageTitle } from 'utils/helpers';
import buildSlug from 'utils/buildSlug';

import useLoadIdpApplication from './use-load-idp-application';
import useLoadProject from './use-load-project';
import useLoadFolder from './use-load-folder';

export default function Header({ idpProject }) {
  const idpApplication = useLoadIdpApplication(idpProject);
  const project = useLoadProject(idpApplication);
  const folder = useLoadFolder(idpApplication?.folderId);

  if (!idpApplication || !project) {
    return null;
  }

  const pageTitle = idpProject?.name || 'IDP project not Found';

  return (
    <>
      <Helmet>
        <title>{getPageTitle(pageTitle)}</title>
      </Helmet>
      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" to="/" data-test="breadcrumb-home" />
        <Breadcrumb title={project.name} variant="link" to={`/projects/${buildSlug(project.id, project.name)}`} />
        {folder?.parent && (
          <>
            {folder.parent.parentId && <Breadcrumb title="..." variant="text" data-test="grand-parent-dots" />}
            <Breadcrumb
              title={folder.parent.name}
              variant="link"
              to={`/folders/${buildSlug(folder.parent.id, folder.parent.name)}`}
              data-test="grand-parent-folder"
            />
          </>
        )}
        {folder?.name && (
          <Breadcrumb
            title={folder.name}
            variant="link"
            to={`/folders/${buildSlug(folder.id, folder.name)}`}
            data-test="parent-folder"
          />
        )}
        <Breadcrumb
          title={idpApplication.name}
          variant="link"
          to={`/idp-applications/${buildSlug(idpApplication.id, idpApplication.name)}`}
        />
        <Breadcrumb
          title={idpProject.name}
          variant="link"
          to={`/idp-projects/${buildSlug(idpProject.id, idpProject.name)}/upload`}
        />
        <Breadcrumb isBold title="Versions" variant="text" />
      </TopBar.Breadcrumbs>
    </>
  );
}
