/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Info, Saved, Danger } from 'icons';

import * as Styled from './StatusIcon.styled';

export const StatusIcon = ({ status }) => {
  switch (status) {
    case 'progress':
      return <Styled.SavingIcon width="16" height="16" />;
    case 'done':
      return <Saved width="20" height="20" />;
    case 'warning':
      return <Danger width="18" height="18" />;
    case 'info':
      return <Info width="14" height="14" />;
    default:
      return null;
  }
};
