/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isCamunda7Diagram, isCamunda8Diagram, isValidBpmnDiagram, isValidDmnFile } from 'utils/validate-diagram';
import isValidForm, { isCamunda7Form } from 'utils/validate-form';
import isValidConnectorTemplate from 'utils/validate-connector-template';
import { BPMN, CONNECTOR_TEMPLATE, DMN, FORM, IDP_APPLICATION } from 'utils/constants';
import isValidIdpApplication from 'utils/validate-idp-application';

const MAX_FILE_UPLOAD_SIZE = 3 * 1024 * 1024;

/**
 * Validates an array of files based on their file size, file extension, and content.
 *
 * @param {Object[]} files The files to validate.
 * @param {String[]} acceptedFileTypes An array of accepted file types.
 * @ts-expect-error TS1064 returns {Object} A object with `invalid` and `valid` properties, both are arrays containing the results.
 */
export default async function validateFiles(files, acceptedFileTypes = []) {
  const invalid = [];
  const valid = [];

  for (const file of files) {
    const hasAcceptableSize = file.size < MAX_FILE_UPLOAD_SIZE;
    const hasValidExtension = acceptedFileTypes.includes(file.type);
    let hasValidContent = true;

    switch (file.type) {
      case FORM:
        hasValidContent = isValidForm(file.content);
        break;
      case DMN:
        hasValidContent = await isValidDmnFile(file.content);
        break;
      case CONNECTOR_TEMPLATE:
        hasValidContent = isValidConnectorTemplate(file.content);
        break;
      case IDP_APPLICATION:
        hasValidContent = isValidIdpApplication(file.content);
        break;
      default:
        hasValidContent =
          (await isValidBpmnDiagram(file.content)) &&
          (isCamunda8Diagram(file.content) || isCamunda7Diagram(file.content));
    }

    if (hasAcceptableSize && hasValidExtension && hasValidContent) {
      valid.push(file);
    } else {
      invalid.push(file);
    }
  }

  return { valid, invalid };
}

/**
 * Checks if the given file is a Camunda 7 diagram or form.
 * @param file
 * @returns {*|boolean}
 */
export const isCamunda7File = (file) => {
  try {
    return (
      ((file.type === BPMN || file.type === DMN) && isCamunda7Diagram(file.content)) ||
      (file.type === FORM && isCamunda7Form(JSON.parse(file.content)))
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Checks if the given file is an IDP application
 * @param file
 * @returns {*|boolean}
 */
export const isIdpApplicationFile = (file) => {
  try {
    return file.type === IDP_APPLICATION && isValidIdpApplication(JSON.parse(file.content));
  } catch (err) {
    console.error(err);
    return false;
  }
};
