/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { projectService } from 'services';

export default function useLoadProject(processApplication) {
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (processApplication) {
      (async () => {
        try {
          const project = await projectService.fetchById({ projectId: processApplication.projectId });
          setProject(project);
        } catch {
          console.error('Failed to fetch project!');
        }
      })();
    }
  }, [processApplication]);

  return project;
}
