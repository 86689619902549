/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {Object} Params
 * @property {string} idpProjectId - The ID of the IDP project
 */

import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { VersionsSidebar } from 'components/VersionsSidebar';
import { DiffingToggle, useIsDiffingEnabled } from 'features/toggle-diffing';
import { FileViewer, startLoading, stopLoading } from 'features/view-file';
import history from 'utils/history';
import buildSlug from 'utils/buildSlug';
import useLoadIdpApplication from 'App/Pages/IdpProjectVersions/features/header/use-load-idp-application';
import useLoadProject from 'App/Pages/IdpProjectVersions/features/header/use-load-project';
import hasAccess, { actions } from 'utils/user-access';
import { Spinner } from 'primitives';

import { Header } from './features/header';
import { IdpApplicationVersionsList } from './features/view-versions-list';
import { useLoadIdpProject, useLoadIdpProjectVersions, useMilestoneFileSelection } from './hooks';
import * as Styled from './IdpProjectVersions.styled';

const IdpProjectVersions = () => {
  /** @type {Params} */
  const { idpProjectId } = useParams();
  const idpProject = useLoadIdpProject(idpProjectId);
  const idpApplication = useLoadIdpApplication(idpProject);
  const project = useLoadProject(idpApplication);
  const { versions, reload, isLoading } = useLoadIdpProjectVersions(idpProjectId);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState(null);
  const { milestoneFileSelection } = useMilestoneFileSelection(
    selectedMilestoneId,
    versions,
    startLoading,
    stopLoading
  );
  const { isDiffingEnabled, toggleDiffing } = useIsDiffingEnabled();
  const canUserModifyProject = hasAccess(project, actions.MODIFY_PROJECT) || hasAccess(project, actions.MODIFY_DIAGRAM);

  // This is added to prevent the race condition to ensure component is loaded when all entities are available.
  if (isLoading || !idpProject || !project || !idpApplication) {
    return <Spinner fullHeight />;
  }

  if (!canUserModifyProject || (!isLoading && versions.length === 0)) {
    // Redirect to base URL for IDP project. Any sub-routing will be handled by the child component in `IdpProject`
    history.push(`/idp-projects/${buildSlug(idpProject.id, idpProject.name)}`);
  }

  return (
    <Styled.Container>
      <Header idpProject={idpProject} />
      <Styled.FileContainer>
        <FileViewer fileSelection={milestoneFileSelection} isDiffingEnabled={isDiffingEnabled} />
      </Styled.FileContainer>
      <VersionsSidebar
        isShowDiffingToggle
        VersionsList={
          <IdpApplicationVersionsList
            versions={versions}
            reloadVersions={reload}
            selectedMilestoneId={selectedMilestoneId}
            setSelectedMilestoneId={setSelectedMilestoneId}
            isDiffingEnabled={isDiffingEnabled}
          />
        }
        DiffingToggle={<DiffingToggle isDiffingEnabled={isDiffingEnabled} toggleDiffing={toggleDiffing} />}
      />
    </Styled.Container>
  );
};

export default IdpProjectVersions;
