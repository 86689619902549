/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { businessRuleTaskLinkStore } from 'App/Pages/Diagram/BusinessRuleTaskLinking';
import { DMN } from 'utils/constants';
import { breadcrumbStore, currentDiagramStore, notificationStore } from 'stores';
import { fileService, tracingService } from 'services';
import buildSlug from 'utils/buildSlug';
import { createEntity, generateIdFromElementName, trackSubResourceCreation } from 'components/TargetSelector/utils';
import { createOrUpdateExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

export default async function createDmnDiagram(target, history) {
  const elementName = getBusinessObject(businessRuleTaskLinkStore.selectedBusinessRuleTask)?.name || '';
  const attributes = elementName
    ? {
        name: elementName,
        decisionName: elementName,
        decisionId: generateIdFromElementName(elementName, DMN)
      }
    : { decisionId: generateIdFromElementName('', DMN) };
  const entity = createEntity({
    type: DMN,
    attributes,
    target
  });

  try {
    const diagram = await fileService.create(entity);
    createOrUpdateExtensionElement(
      businessRuleTaskLinkStore.selectedBusinessRuleTask,
      'zeebe:CalledDecision',
      { decisionId: attributes.decisionId },
      currentDiagramStore.modeler
    );
    await currentDiagramStore.saveContent();

    trackSubResourceCreation({
      newFileId: diagram.id,
      newFileType: DMN,
      newFileTarget: target
    });
    breadcrumbStore.toggleEditingFor('diagram');
    history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
  } catch (ex) {
    console.error(ex);
    notificationStore.showError('Could not create new DMN diagram. Please try again.');
    tracingService.traceError(ex, 'Failed to create new DMN diagram');
  }
}
