/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useStatsigClient } from 'App/Statsig';

const IDP_FEATURE = 'idp-application';

export function useIsIdpEnabled() {
  const { checkGate } = useStatsigClient();
  return checkGate(IDP_FEATURE);
}
