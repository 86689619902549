/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, Link as _Link, Section } from '@carbon/react';
import styled from 'styled-components';

export const CamundaAIButton = styled(Button)`
  margin-left: 5px;
  border-radius: 2px;
`;

export const DialogBody = styled.div`
  padding: 80px 40px;
`;

export const Description = styled(Section)`
  margin-top: 10px;
`;

export const Link = styled(_Link)`
  margin-top: 40px;
  cursor: pointer;
`;
