/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link } from '@carbon/react';

import { bpmnCopilotDocsLink } from 'experiments/ai-features/bpmn-copilot/bpmn-copilot-docs-link';
import buildPath from 'utils/build-path';

export const getCopilotDescription = (isAdminOrEditor, diagramId) => {
  const lastLines = isAdminOrEditor ? (
    <>
      for assistance creating a new BPMN process without implementation details. Note that the Copilot currently
      overwrites existing work, but will save a{' '}
      <Link href={buildPath(`/diagrams/${diagramId}/versions`)} aria-label="Open process versions" target="_blank">
        version
      </Link>{' '}
      of the process.
    </>
  ) : (
    <>
      to ask questions about your BPMN process. This Copilot is in read-only mode and will not make any changes to the
      process.
    </>
  );

  return (
    <>
      Chat with the&nbsp;
      <Link href={bpmnCopilotDocsLink} target="_blank" rel="noreferrer">
        BPMN Copilot
      </Link>
      &nbsp;
      {lastLines}
    </>
  );
};

export const getPlaceholderText = (isAdminOrEditor) => {
  return isAdminOrEditor
    ? 'Enter a process description, or click Docs AI'
    : 'Ask a question about this process, or click Docs AI';
};

export const getFirstResponseMessage = (isAdminOrEditor) => {
  const firstLine = isAdminOrEditor
    ? 'Certainly! I usually take around 50 seconds to handle your request.'
    : 'Certainly! I usually provide answers about the process in around 30 seconds.';

  return (
    <>
      {firstLine}
      <br />
      <br />
      I don't always get it right, so please review the process and feel free to try again with a different prompt.
      <br />
      <br />
      To learn more, visit the&nbsp;
      <Link href={bpmnCopilotDocsLink} target="_blank" rel="noreferrer" inline>
        BPMN Copilot documentation
      </Link>
      .
    </>
  );
};

export const getExamplePrompt1 = (isAdminOrEditor) => {
  if (isAdminOrEditor) {
    return 'Generate a mortgage approval process';
  }

  return 'Explain this process to me';
};

export const getExamplePrompt2 = (isAdminOrEditor) => {
  if (isAdminOrEditor) {
    return 'Create an e-commerce order fulfillment process';
  }

  return 'What are the error paths of this process?';
};

export const getErrorMessage = (e) => {
  if (!e?.message || e instanceof TypeError) {
    return GENERIC_ERROR_MESSAGE;
  }

  return e.message;
};

export const GENERIC_ERROR_MESSAGE = 'Something went wrong on our end. Please try again.';

export const ATTEMPTED_DIAGRAM_CHANGES_ERROR_MESSAGE =
  'It looks like you are trying to make changes. Please note that you have read-only access, so you can ask questions about the process but cannot modify or create anything.';
