/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { useState } from 'react';
import { observer } from 'mobx-react';

import { DiagramControls } from 'components';
import { LandscapeEmptyState } from 'features/landscape-view/landscape-empty-state';
import {
  useFetchLandscapeData,
  useInitializeProcessLandscape,
  useCachedReadmeContent,
  useTrimEmbeds
} from 'features/landscape-view/hooks';
import { EmptySidePanel, SelectionSidePanel } from 'features/landscape-view/sidepanel';

import { LandscapeViewModalLoading } from './LandscapeViewModalLoading';
import * as Styled from './LandscapeViewModal.styled';

export const LandscapeViewModal = ({ isOpen = false, onClose = () => {}, project }) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const { isLoading, error, landscapeData } = useFetchLandscapeData(isOpen, project.id);
  const { landscapeContainerRef } = useInitializeProcessLandscape(landscapeData, setSelectedNode);

  const {
    isLoading: isReadmeLoading,
    error: readmeError,
    readmeContent
  } = useCachedReadmeContent(selectedNode?.businessObject?.externalData?.readmeFileId);

  const trimmedReadmeContent = useTrimEmbeds(readmeContent);

  if (error) {
    return;
  }

  const disposeState = () => {
    setTimeout(() => {
      setSelectedNode(null);
    }, 500);
  };

  const handleClose = () => {
    disposeState();
    onClose();
  };

  return (
    <Styled.LandscapeViewModalContainer
      open={isOpen}
      onClose={handleClose}
      size="lg"
      aria-label="Process Landscape Modal"
    >
      {isLoading ? (
        <LandscapeViewModalLoading />
      ) : (
        <>
          <Styled.LandscapeViewModalHeaderContainer closeModal={handleClose}>
            <Styled.LandscapeViewModalHeader>{project.name} landscape</Styled.LandscapeViewModalHeader>
          </Styled.LandscapeViewModalHeaderContainer>
          <Styled.LandscapeViewModalBody>
            {landscapeData && landscapeData.elements?.nodes?.length > 0 ? (
              <Styled.LandscapeViewModalGrid>
                <Styled.ColumnChart lg={12} md={6} sm={4} className="chart-container">
                  <Styled.LandscapeCanvas data-test="landscape-container" ref={landscapeContainerRef} />
                  <Styled.DiagramControlsContainer>
                    <DiagramControls />
                  </Styled.DiagramControlsContainer>
                </Styled.ColumnChart>
                <Styled.ColumnSidePanel
                  lg={4}
                  md={2}
                  sm={0}
                  className="details-panel"
                  data-test="process-details-panel"
                >
                  {selectedNode ? (
                    <SelectionSidePanel
                      selectedNode={selectedNode}
                      readmeError={readmeError}
                      isReadmeLoading={isReadmeLoading}
                      readmeContent={trimmedReadmeContent}
                    />
                  ) : (
                    <EmptySidePanel />
                  )}
                </Styled.ColumnSidePanel>
              </Styled.LandscapeViewModalGrid>
            ) : (
              <LandscapeEmptyState />
            )}
          </Styled.LandscapeViewModalBody>
        </>
      )}
    </Styled.LandscapeViewModalContainer>
  );
};

LandscapeViewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default observer(LandscapeViewModal);
