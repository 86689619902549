/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Sidebar } from 'primitives';
import { Title } from 'App/Pages/Project/Shared.styled';

export const SidebarOuter = styled(Sidebar)`
  width: 450px;
`;

export const CollaboratorSidebarTitle = styled(Title)`
  margin: 20px;
`;

export const CollaboratorSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EntityTableContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 15px 0 15px;
`;

export const Icon = styled.span`
  margin-right: var(--spacing-small);
`;

export const NotificationContainer = styled.div`
  padding: 10px 10px 0 10px;
`;
