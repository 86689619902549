/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const IDP_PROJECT_TYPE = {
  EXTRACTION: 'EXTRACTION',
  DOCUMENT_AUTOMATION: 'DOCUMENT_AUTOMATION'
};
export const IDP_PROJECT_EXTRACTION_METHODS = [
  {
    id: 'form-extraction',
    title: 'Form Extraction',
    value: 'FORM',
    description: 'Extract data from documents with a consistent layout.'
  },
  {
    id: 'unstructured-data-extraction',
    title: 'Unstructured data extraction',
    value: 'UNSTRUCTURED_DATA',
    description: 'Extract Unstructured data into structured formats.'
  }
];
export const EXTRACTION_FIELD_TYPES = [
  { id: 'STRING', name: 'String' },
  { id: 'NUMBER', name: 'Number' },
  { id: 'BOOLEAN', name: 'Boolean' }
];
export const EXTRACTION_MODELS = [
  { id: 'anthropic.claude-3-5-sonnet-20240620-v1:0', name: 'Claude 3.5 Sonnet' },
  { id: 'anthropic.claude-3-sonnet-20240229-v1:0', name: 'Claude 3 Sonnet' },
  { id: 'anthropic.claude-3-haiku-20240307-v1:0', name: 'Claude 3 Haiku' },
  { id: 'meta.llama3-70b-instruct-v1:0', name: 'Llama 3 70B Instruct' },
  { id: 'meta.llama3-8b-instruct-v1:0', name: 'Llama 3 8B Instruct' },
  { id: 'amazon.titan-text-premier-v1:0', name: 'Titan Text Premier' }
];
export const EXTRACTION_VALIDATION_STATUS = {
  PASS: 'PASS',
  IN_PROGRESS: 'IN_PROGRESS',
  TESTCASE_MISSING: 'TESTCASE_MISSING',
  FAIL: 'FAIL'
};
export const EXTRACTION_NOTIFICATION_STEPS = {
  ADD_AN_EXTRACTION_FIELD: {
    title: 'Step 1/4: Add an extraction field.',
    content:
      'The field name will serve as an output variable in a BPMN process. Provide a clear and specific prompt to guide the LLM in extracting the required data.'
  },
  SELECT_AN_EXTRACTION_FIELD: {
    title: 'Step 2/4: Select an extraction model that fits your requirements.',
    content: 'If results are inaccurate, refine the field prompt or choose a different model.'
  },
  SAVE_AS_TEST_CASE: {
    title: 'Step 3/4: Save as test case.',
    content:
      'Save the expected extraction output as a test case for future reference. Use the same test case to evaluate other models and compare their results.'
  },
  REPEAT_FOR_ALL_DOCUMENTS: {
    title: 'Step 4/4: Repeat for all documents.',
    content:
      'You completed your first document extraction. Repeat the process of creating and evaluating test cases for each document.'
  }
};
export const IDP_APPLICATION_CAMUNDA_DOCS_LINK =
  'https://docs.camunda.io/docs/8.7/components/modeler/web-modeler/idp/idp-configuration/';
export const IDP_EXTRACTION_CONNECTOR_SECRETS = [
  'IDP_AWS_ACCESSKEY',
  'IDP_AWS_SECRETKEY',
  'IDP_AWS_REGION',
  'IDP_AWS_BUCKET_NAME'
];
export const MAX_ALLOWED_IDP_DOCUMENT_FILE_SIZE = 4 * 1024 * 1024;
export const INVALID_IDP_DOCUMENT_REASONS = {
  IDP_DOCUMENT_TOO_LARGE: {
    title: 'Document is too large'
  },
  IDP_DOCUMENTS_TOO_LARGE: {
    title: 'Total document size too large'
  },
  IDP_DOCUMENT_INVALID_NAME: {
    title: 'Invalid document name'
  },
  IDP_DOCUMENT_EMPTY: {
    title: 'Document is empty'
  },
  IDP_DOCUMENT_INVALID_FORMAT: {
    title: 'Document is not in PDF format'
  },
  IDP_DOCUMENT_CORRUPT: {
    title: 'Document cannot be read'
  },
  IDP_DOCUMENT_PASSWORD_PROTECTED: {
    title: 'Document is password-protected'
  }
};

export var SUMMARY_CHART_CONFIG = {
  data: [
    {
      group: 'Passed',
      value: 0
    },
    {
      group: 'Failed',
      value: 0
    }
  ],
  options: {
    height: '8px',
    meter: {
      proportional: {
        total: 0,
        unit: 'Test cases'
      },
      showLabels: false
    },
    color: {
      scale: {
        Passed: '#24a148',
        Failed: '#da1e28'
      }
    },
    legend: {
      enabled: false
    },
    toolbar: {
      enabled: false
    }
  }
};
