/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('./types').FileSelection} FileSelection
 */

import styled from 'styled-components';

import { BPMN, CONNECTOR_TEMPLATE, DMN, FORM, README } from 'utils/constants';
import { Spinner } from 'primitives';

import { ViewModesTabs } from './view-modes';
import { BpmnViewer } from './bpmn-viewer';
import { CodeViewer } from './code-viewer';
import { DmnViewer } from './dmn-viewer';
import { useLoadingState } from './use-loading-state';

/**
 * @param {Object} props
 * @param {FileSelection|null} props.fileSelection
 * @param {Boolean} props.isDiffingEnabled
 * @param {import('./view-modes').ViewModes} [props.viewModes]
 * @param {Object} [props.bpmnViewer]
 * @param {Function} [props.setBpmnViewer]
 */
export default function FileViewer({ fileSelection, isDiffingEnabled, viewModes, bpmnViewer, setBpmnViewer }) {
  const { isLoading } = useLoadingState();

  if (!fileSelection?.current) {
    return;
  }

  if (isLoading) {
    return <Spinner fullHeight />;
  }

  return (
    <Container>
      {fileSelection.fileType === BPMN && (
        <ViewModesTabs
          VisualViewer={
            <BpmnViewer
              fileSelection={fileSelection}
              isDiffingEnabled={isDiffingEnabled}
              bpmnViewer={bpmnViewer}
              setBpmnViewer={setBpmnViewer}
            />
          }
          CodeViewer={<CodeViewer fileSelection={fileSelection} isDiffingEnabled={isDiffingEnabled} />}
          viewModes={viewModes}
        />
      )}
      {fileSelection.fileType === DMN && (
        <ViewModesTabs
          VisualViewer={<DmnViewer fileSelection={fileSelection} />}
          CodeViewer={<CodeViewer fileSelection={fileSelection} isDiffingEnabled={isDiffingEnabled} />}
          viewModes={viewModes}
        />
      )}
      {(fileSelection.fileType === FORM ||
        fileSelection.fileType === CONNECTOR_TEMPLATE ||
        fileSelection.fileType === README) && (
        <CodeViewer fileSelection={fileSelection} isDiffingEnabled={isDiffingEnabled} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;
