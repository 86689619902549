/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useMemo } from 'react';
import { loader, useMonaco } from '@monaco-editor/react';

import { MONACO_LOADER_CONFIG } from 'components/CodeEditor/config';

export default () => {
  const monaco = useMonaco();
  loader.config(MONACO_LOADER_CONFIG);

  return useMemo(
    () => [
      {
        provideHover: function (model, position) {
          if (!monaco) {
            return null;
          }

          const versionIndex = model.getValue().indexOf('"version"');
          // does not contain version
          if (versionIndex === -1) {
            return null;
          }

          const versionLineNumber = model.getPositionAt(versionIndex).lineNumber;
          const range = new monaco.Range(
            versionLineNumber,
            0,
            versionLineNumber,
            model.getLineLength(versionLineNumber) - 1
          );

          if (range.containsPosition(position)) {
            return {
              range,
              contents: [
                {
                  supportHtml: true,
                  value:
                    'Connector template versions are managed by Web Modeler, see <a href="https://docs.camunda.io/docs/next/components/connectors/manage-connector-templates/#versioning-connector-templates" target="_blank" rel="noreferrer">documentation</a>.'
                }
              ]
            };
          }

          return null;
        }
      }
    ],
    [monaco]
  );
};
