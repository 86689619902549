/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Tooltip as _Tooltip } from '@carbon/react';

export const Tooltip = styled(_Tooltip)`
  .cds--tooltip-content {
    height: 30px;
    line-height: 12px;
    font-size: 12px;
    padding: 9px 12px;
    border-radius: 4px;
    width: max-content;
    margin: 6px 0;
  }

  .cds--popover-caret {
    margin: 6px 0;
  }
`;
