/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled, { keyframes } from 'styled-components';

import { Saving } from 'icons';
import { Button } from 'primitives';

export const ActionBar = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--spacing-small) var(--spacing-tiny);
  color: var(--grey-lighten-50);
  border-bottom: 1px solid var(--silver-darken-87);
  position: sticky;
  top: 49px;
  background-color: var(--silver-base-97);
  min-height: 57px;

  button {
    margin-left: var(--spacing-tiny);
  }
`;

export const RightSection = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

export const Spinner = styled(Saving)`
  animation: ${spinning} 1.5s linear infinite;
`;

export const Status = styled.p`
  user-select: none;
  margin-left: 20px;
  display: flex;
  font-size: 13px;
  color: var(--grey-darken-33);
  align-items: center;

  > svg {
    margin-right: 3px;
  }
`;

export const BackButton = styled(Button)`
  color: var(--grey-darken-33);
  margin-right: auto;

  > svg {
    margin-right: 7px;
  }
`;

export const ModeSwitcher = styled.div`
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  overflow: hidden;
  margin-left: 7px;

  button:not(:first-child) {
    border-left: 1px solid #c6c6c6;
  }
`;

export const Mode = styled.button`
  color: var(--grey-darken-33);
  margin: 0 !important;
  border: 0;
  border-radius: 0;
  height: 32px;
  width: 48px;
  cursor: pointer;
  svg {
    vertical-align: middle;
  }

  ${({ selected }) => `${selected ? 'background: #FFFFFF; color: #000000;' : 'background: #e0e0e0;'}`}
`;

export const CalledBySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
