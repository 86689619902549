/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { README } from 'utils/constants';

import { fetchReadme } from './fetchReadme';

export function useFetchReadme({ entities }) {
  const [currentReadme, setCurrentReadme] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (!entities) {
      return;
    }

    const currentReadmeEntity = entities
      .filter((entity) => entity.type === README)
      .sort((a, b) => b.changed - a.changed)[0];

    if (currentReadmeEntity) {
      (async () => {
        const readme = await fetchReadme(currentReadmeEntity.id);
        setCurrentReadme(readme);
        setLoading(false);
      })();
    } else {
      setCurrentReadme(null);
      setLoading(false);
    }
  }, [entities]);

  return {
    currentReadme,
    isReadmePresent: !isLoading && currentReadme !== null,
    isReadmeLoading: isLoading
  };
}
