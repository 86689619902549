/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { projectStore } from 'stores';
import { EmptyState as EmptyStatePrimitive } from 'primitives';
import hasAccess, { actions } from 'utils/user-access';
import { NoAccess, ProcessApplication, SadFace } from 'icons';

import EmptyStateActionButtons from './EmptyStateActionButtons';

export function EmptyState({ description }) {
  const { project } = projectStore;

  const shouldShowProjectNotFound = !hasAccess(project, actions.VIEW_PROJECT);
  const shouldShowEmptyProject =
    hasAccess(project, actions.VIEW_PROJECT) &&
    !hasAccess(project, actions.CREATE_DIAGRAM) &&
    project.files.length === 0;

  if (shouldShowEmptyProject) {
    return (
      <EmptyStatePrimitive
        title="Project empty!"
        description="Sorry, the project has no files yet."
        icon={<SadFace width="48" height="48" />}
      />
    );
  }

  if (shouldShowProjectNotFound) {
    return (
      <EmptyStatePrimitive
        title="Project not found!"
        description={
          <>
            We couldn't locate the project.
            <br />
            Please check your access or ensure the project exists.
          </>
        }
        icon={<NoAccess />}
      />
    );
  }

  return (
    <EmptyStatePrimitive
      icon={<ProcessApplication />}
      title="It all starts with a process application"
      description={description}
      action={<EmptyStateActionButtons />}
      link={
        <a
          href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications"
          target="_blank"
          rel="noreferrer"
        >
          Learn more about process applications
        </a>
      }
    />
  );
}

export default observer(EmptyState);
