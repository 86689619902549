/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';

let setterFn;

const useIsModalOpen = () => {
  const [isOpen, setIsOpen] = useState(false);

  setterFn = setIsOpen;
  const closeVersionCreationModal = () => setIsOpen(false);

  return { isOpen, closeVersionCreationModal };
};

const openVersionCreationModal = () => setterFn(true);

export { openVersionCreationModal, useIsModalOpen };
