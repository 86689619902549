/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Button, IconButton } from '@carbon/react';
import { Settings } from '@carbon/icons-react';

import { getGitProviderIcon, getGitProviderLabel } from 'features/git-sync/utils';
import { projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { gitSettingsStore, gitSyncStore, useUserCapabilities } from 'features/git-sync';

import * as Styled from './GitSyncButtons.styled';

const GitSyncButtons = () => {
  const { project } = projectStore;
  const { hasUnsavedSettings, hasAllRequiredSettings, shouldShowDialog } = gitSettingsStore;

  const canSync = hasAccess(project, actions.MODIFY_DIAGRAM) && hasAllRequiredSettings;
  const hasSettings = (hasUnsavedSettings && shouldShowDialog) || (!hasUnsavedSettings && hasAllRequiredSettings);

  const { hasElevatedAccess, canSetupProcessApplicationSettings } = useUserCapabilities();

  const onSync = () => {
    gitSyncStore.open();
  };

  const onSetup = () => {
    gitSettingsStore.open();
  };

  const gitLabel = getGitProviderLabel();
  const gitIcon = getGitProviderIcon();

  const ConnectButton = () => (
    <Button
      kind="primary"
      size="sm"
      iconDescription={`Open the dialog to connect ${gitLabel}`}
      renderIcon={gitIcon}
      onClick={() => onSetup()}
    >
      Connect {gitLabel}
    </Button>
  );

  const SyncButton = () => (
    <Button
      kind="primary"
      size="sm"
      iconDescription={`Open the dialog to sync with ${gitLabel}`}
      renderIcon={gitIcon}
      onClick={() => onSync()}
    >
      Sync with {gitLabel}
    </Button>
  );

  const SettingsButton = () => (
    <IconButton label={`Open ${gitLabel} settings`} size="sm" kind="ghost" onClick={() => onSetup()}>
      <Settings />
    </IconButton>
  );

  return hasSettings ? (
    <Styled.Buttons>
      {canSync && <SyncButton />} {canSetupProcessApplicationSettings && <SettingsButton />}
    </Styled.Buttons>
  ) : hasElevatedAccess ? (
    <ConnectButton />
  ) : (
    <>{canSetupProcessApplicationSettings && <SettingsButton />}</>
  );
};

export default observer(GitSyncButtons);
