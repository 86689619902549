/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

/**
 * @typedef {import('services/ProcessApplicationService.common').UserDto} UserDto
 */

/**
 * @typedef {Object} IdpProject
 * @property {string} id,
 * @property {string} name,
 * @property {string} type,
 * @property {string} extractionMethod,
 * @property {string} idpApplicationId
 */

/**
 * @typedef {Object} IdpExtractionProjectVersionListEntryDto
 * @property {string} id,
 * @property {string} name,
 * @property {string} milestoneId,
 * @property {boolean} organizationPublic,
 * @property {UserDto} createdBy,
 * @property {number} created
 */

class IdpProjectService extends Service {
  constructor() {
    super();
  }

  /**
   * Creates a new IDP project in the given IDP application.
   *
   * @param {Object} params
   * @param {String} [params.idpApplicationId] the IDP application id
   * @param {String} [params.name] the name of the IDP project
   * @param {String} [params.description] the description of the IDP extraction project
   * @param {String} [params.type] the type of the IDP project
   * @param {String} [params.extractionMethod] the extraction method of the IDP project
   * @return {Promise}
   */
  createIdpProject({ idpApplicationId, name, description, type, extractionMethod }) {
    const payload = {
      idpApplicationId,
      name,
      description,
      type,
      extractionMethod
    };
    return this.post(`/internal-api/idp-projects`, payload);
  }

  /**
   * Returns the details related to the given IDP project
   *
   * @param {String} idpProjectId the IDP project id
   * @return {Promise}
   */
  fetchIdpProject(idpProjectId) {
    return this.get(`/internal-api/idp-projects/${idpProjectId}`);
  }

  /**
   * Renames an IDP project.
   *
   * @param {String} idpProjectId the IDP project id
   * @param {String} name the new name for the IDP project
   * @returns {Promise}
   */
  renameIdpProject(idpProjectId, name) {
    return this.patch(`/internal-api/idp-projects/${idpProjectId}`, { name });
  }

  /**
   * Removes an IDP project.
   *
   * @param {String} idpProjectId the IDP project id
   * @returns {Promise}
   */
  deleteIdpProject(idpProjectId) {
    return this.delete(`/internal-api/idp-projects/${idpProjectId}`);
  }

  /**
   * Deploys a process in the specified cluster
   *
   * @param {String} idpProjectId the IDP project id
   * @param {String} clusterId the id of the cluster to deploy the process to
   * @param {String} bpmnXml the xml string of the process to deploy
   * @param {String} resourceName the filename of the bpmn process. "process.bpmn" by default
   * @param {String} [tenantId] the tenant to deploy the process to. Used for Self-Managed only
   * @return {Promise}
   */
  deployProcess(idpProjectId, clusterId, bpmnXml, resourceName = 'process.bpmn', tenantId) {
    return this.post(`/internal-api/idp-projects/${idpProjectId}/deploy-process`, {
      clusterId,
      bpmnXml,
      resourceName,
      tenantId
    });
  }

  /**
   * Starts an instance of a specified process and version and returns the result
   *
   * @param {String} idpProjectId the IDP project id
   * @param {Object} payload Specification for which process to start with which variables
   * @param {String} payload.clusterId the id of the cluster to deploy the process to
   * @param {String} payload.processId the id of the cluster to deploy the process to
   * @param {Number} payload.version the id of the cluster to deploy the process to
   * @param {Object} payload.variables the id of the cluster to deploy the process to
   * @param {Number} payload.timeoutSeconds the xml string of the process to deploy
   * @param {String} [payload.tenantId] the tenant to deploy the process to. Used for Self-Managed only
   * @return {Promise}
   */
  startInstance(idpProjectId, payload) {
    return this.post(`/internal-api/idp-projects/${idpProjectId}/start-instance`, payload);
  }

  /**
   * Returns the IDP documents uploaded to the given IDP project
   *
   * @param {String} idpProjectId the IDP project id
   * @return {Promise}
   */
  fetchIdpDocuments(idpProjectId) {
    return this.get(`/internal-api/idp-projects/${idpProjectId}/documents`);
  }

  /**
   * Returns the IDP extraction project versions published for the given IDP project
   *
   * @param {String} idpProjectId the IDP project id
   * @return {Promise}
   */
  getExtractionProjectVersions(idpProjectId) {
    return this.get(`/internal-api/idp-projects/${idpProjectId}/extraction-versions`);
  }

  /**
   * Publishes an IDP extraction project with given ID
   *
   * @param {String} idpProjectId the IDP project id
   * @param {Object} payload - Specification for which publish operation is performed with variables
   * @param {String} payload.versionName - The version name for IDP extraction project
   * @param {String} payload.versionDescription - The version description for IDP extraction project
   * @param {String} payload.llmModelId - The LLM model ID for which publishing is done
   * @param {Boolean} payload.organizationPublic - Whether publish is done on project or organization level
   * @return {Promise}
   */
  publishIdpExtractionProject(idpProjectId, payload) {
    return this.post(`/internal-api/idp-projects/${idpProjectId}/publish-extraction`, payload);
  }

  /**
   * Updates multiple IDP extraction fields for a given IDP project and IDP document
   *
   * @param {String} idpProjectId the IDP project id
   * @param {Object} payload - Specification for which update operation is performed with variables
   * @param {Object[]} payload.testCaseResults - The IDP test case results which need to be updated
   * @param {String} payload.testCaseResults[].idpDocumentId - The IDP document id
   * @param {String} payload.testCaseResults[].idpExtractionFieldId - The IDP extraction field id
   * @param {String} payload.testCaseResults[].llmModelId - LLM model id
   * @param {String} payload.testCaseResults[].status - The status of the test case result
   * @param {String} payload.testCaseResults[].extractedValue - The extracted value of the extraction field
   * @return {Promise} A promise that resolves when the fields are updated.
   */
  updateIdpTestCaseResults(idpProjectId, payload) {
    return this.put(`/internal-api/idp-projects/${idpProjectId}/test-case-results`, payload);
  }

  /**
   * Uploads IDP document to the cluster
   *
   * @param {String} idpDocumentId the IDP document id
   * @return {Promise}
   */
  uploadIdpDocumentToCluster(idpDocumentId) {
    return this.post(`/internal-api/idp-documents/${idpDocumentId}/upload`);
  }

  /**
   * Uploads documents to the given IDP project
   *
   * @param {String} idpProjectId the IDP project id
   * @param {File[]} documents documents to upload
   * @param {Function} [onDocumentsUploadProgress] callback function providing the updated progress percentage
   * @return {Promise}
   */
  uploadDocuments(idpProjectId, documents, onDocumentsUploadProgress) {
    const formData = new FormData();
    for (let i = 0; i < documents.length; i++) {
      formData.append('files', documents[i]);
    }

    return this.post(
      `/internal-api/idp-projects/${idpProjectId}/upload`,
      formData,
      'multipart/form-data',
      onDocumentsUploadProgress
    );
  }

  /**
   * Downloads an IDP document in the given IDP project
   *
   * @param {String} idpDocumentId the IDP document id
   * @return {Promise}
   */
  getIdpDocument(idpDocumentId) {
    return this.get(`/internal-api/idp-documents/${idpDocumentId}`);
  }

  /**
   * Deletes an IDP document in the given IDP project
   *
   * @param {String} idpDocumentId the IDP document id
   * @return {Promise}
   */
  deleteIdpDocument(idpDocumentId) {
    return this.delete(`/internal-api/idp-documents/${idpDocumentId}`);
  }

  /**
   * Returns the IDP extraction fields for the given IDP document
   *
   * @param {String} idpDocumentId the IDP document id
   * @return {Promise}
   */
  fetchIdpExtractionFields(idpDocumentId) {
    return this.get(`/internal-api/idp-documents/${idpDocumentId}/extraction-field-values`);
  }

  /**
   * Adds a new IDP extraction field to the given IDP project
   *
   * @param {String} idpProjectId the IDP project id
   * @param {String} name the name of the extraction field
   * @param {String} type the type of the extraction field
   * @param {String} llmPromptDescription the prompt description for the LLM model
   * @return {Promise}
   */
  addIdpExtractionField(idpProjectId, name, type, llmPromptDescription) {
    return this.post(`/internal-api/idp-extraction-fields`, {
      idpProjectId,
      name,
      type,
      llmPromptDescription
    });
  }

  /**
   * Updates the IDP extraction field in the given IDP project
   *
   * @param {String} id the ID of the extraction field
   * @param {String} name the name of the extraction field
   * @param {String} type the type of the extraction field
   * @param {String} llmPromptDescription the prompt description for the LLM model
   * @return {Promise}
   */
  updateIdpExtractionField(id, name, type, llmPromptDescription) {
    return this.patch(`/internal-api/idp-extraction-fields/${id}`, {
      name,
      type,
      llmPromptDescription
    });
  }

  /**
   * Updates multiple IDP extraction fields for a given IDP project and IDP document
   *
   * @param {String} idpDocumentId the IDP document id
   * @param {Object} payload - Specification for which update operation is performed with variables
   * @param {Object[]} payload.newValues - The IDP extraction fields which need to be updated
   * @param {String} payload.newValues[].id - The name of the extraction field
   * @param {String} payload.newValues[].expectedValue - The expected value of the extraction field
   * @return {Promise} A promise that resolves when the fields are updated.
   */
  updateIdpExtractionFieldValues(idpDocumentId, payload) {
    return this.put(`/internal-api/idp-documents/${idpDocumentId}/extraction-field-values`, payload);
  }

  /**
   * Deletes an IDP extraction field with given ID
   *
   * @param {String} idpExtractionFieldId the IDP extraction field id
   * @return {Promise}
   */
  deleteIdpExtractionField(idpExtractionFieldId) {
    return this.delete(`/internal-api/idp-extraction-fields/${idpExtractionFieldId}`);
  }

  /**
   * Fetches the testcase results for the given IDP extraction field and LLM model
   *
   * @param {String} idpProjectId the IDP project id
   * @param {String} llmModelId the LLM model id
   * @return {Promise}
   */
  fetchIdpExtractionFieldTestcaseResults(idpProjectId, llmModelId) {
    return this.get(`/internal-api/idp-projects/${idpProjectId}/test-case-results/${llmModelId}`);
  }
}

export default new IdpProjectService();
