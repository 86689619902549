/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { observer } from 'mobx-react';

import { breadcrumbStore, formStore } from 'stores';
import { Dropdown, TopBar, Breadcrumb, FileUpload } from 'components';
import hasAccess, { actions } from 'utils/user-access';
import { getParentLink } from 'utils/helpers';

export const Header = () => {
  const { form, project, loading } = formStore;

  return (
    <TopBar.Breadcrumbs>
      {loading ? (
        <Breadcrumb title="Loading..." variant="text" />
      ) : (
        <Fragment>
          <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
          <Breadcrumb
            title={project.name}
            variant="link"
            data-test="breadcrumb-project"
            to={`/projects/${project.id}`}
          />
          {Boolean(form.folder) && (
            <Fragment>
              {form.folder.parentId && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
              <Breadcrumb title={form.folder.name} variant="link" to={getParentLink(form)} data-test="parent-folder" />
            </Fragment>
          )}
          {hasAccess(project, actions.MODIFY_FORM) ? (
            <Fragment>
              <Breadcrumb
                title={form.name}
                variant="dropdown"
                data-test="breadcrumb-form"
                isBold
                forPage="form"
                handleSubmit={formStore.rename}
              >
                {/* @ts-expect-error TS2739 */}
                <Dropdown.ListItem
                  onClick={() => {
                    breadcrumbStore.toggleDropdownVisibility();
                    breadcrumbStore.toggleEditingFor('form');
                  }}
                  data-test="rename-form"
                >
                  Edit name
                </Dropdown.ListItem>
                {/* @ts-expect-error TS2739 */}
                <Dropdown.ListItem data-test="upload-form" noPadding>
                  {/* @ts-expect-error TS2322 */}
                  <FileUpload onFiles={formStore.upload} label="Replace via upload" accept=".form, .json" />
                </Dropdown.ListItem>
                {/* @ts-expect-error TS2739 */}
                <Dropdown.ListItem onClick={formStore.duplicate} data-test="duplicate-form">
                  Duplicate
                </Dropdown.ListItem>
                {/* @ts-expect-error TS2739 */}
                <Dropdown.ListItem onClick={formStore.delete} data-test="delete-form">
                  Delete
                </Dropdown.ListItem>
              </Breadcrumb>
            </Fragment>
          ) : (
            <Breadcrumb data-test="breadcrumb-form" title={form.name} variant="text" isBold />
          )}
        </Fragment>
      )}
    </TopBar.Breadcrumbs>
  );
};

export default observer(Header);
