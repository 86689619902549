/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Sidebar } from 'primitives';

import * as Styled from './VersionsSidebar.styled';

/**
 * Component to display a sidebar for the version list
 *
 * @param {Object} props - The component props.
 * @param {JSX.Element} props.VersionsList
 * @param {JSX.Element} props.DiffingToggle
 * @param {Boolean} props.isShowDiffingToggle
 * @param {JSX.Element} [props.BPMNChangeList=null]
 * @param {Boolean} [props.isShowBpmnChangeList=false]
 * @param {JSX.Element} [props.VersionsTitle=null]
 * @returns {JSX.Element}
 */
export function VersionsSidebar({
  VersionsList,
  DiffingToggle,
  isShowDiffingToggle,
  BPMNChangeList = null,
  isShowBpmnChangeList = false,
  VersionsTitle = null
}) {
  return (
    // @ts-expect-error TS2339
    <Sidebar $isVisible data-test="versions-sidebar">
      {/* @ts-expect-error TS2339 */}
      <Sidebar.Inner>
        <Styled.SidebarSection $isHalfHeight={isShowBpmnChangeList}>
          {VersionsTitle}
          {VersionsList}
        </Styled.SidebarSection>

        {isShowBpmnChangeList && BPMNChangeList}
        {/* @ts-expect-error TS2339 */}
      </Sidebar.Inner>
      {/* @ts-expect-error TS2339 */}
      {isShowDiffingToggle && <Sidebar.Footer>{DiffingToggle}</Sidebar.Footer>}
    </Sidebar>
  );
}
