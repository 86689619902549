/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';

import { tracingService } from 'services';

/**
 * Custom hook for managing state that persists in local storage.
 *
 * When used multiple times with the same key, updating it in one place will not automatically update
 * other instances of the state value
 *
 * @param {string} key - The key under which the value will be stored in local storage.
 * @param {any} initialValue - The initial value of the state.
 * @returns {[any, Function]} - A tuple containing the current state value and a function to update it.
 */
export default (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (ex) {
      tracingService.traceError(ex, 'Failed to get value from local storage');
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (ex) {
      tracingService.traceError(ex, 'Failed to set value in local storage');
      // continue regardless of error
    }
  };

  return [storedValue, setValue];
};
