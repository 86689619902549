/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import * as Styled from './TargetSelector.styled';

export default function usePosition(anchorEl, open, align) {
  const [ready, setReady] = useState(false);
  const [position, setPosition] = useState({
    top: 0,
    left: 0
  });

  useEffect(() => {
    const calculateCoords = (anchorEl) => {
      const { left, top, width, height } = anchorEl.getBoundingClientRect();

      let leftPosition = 0;
      let topPosition = 0;

      // Calculate the horizontal position (left or right).
      switch (align) {
        case 'bottom':
          leftPosition = left;
          break;
        default:
          if (document.body.offsetWidth > left + width + Styled.SPACING + Styled.COMPONENT_WIDTH) {
            leftPosition = left + width + Styled.SPACING;
          } else {
            leftPosition = left - Styled.COMPONENT_WIDTH - Styled.SPACING;
          }
      }

      // Calculate the vertical position (bottom or top).
      switch (align) {
        case 'bottom':
          topPosition = top + height;
          break;
        default:
          if (document.body.offsetHeight > top + height + Styled.COMPONENT_HEIGHT - 54) {
            topPosition = top + height - Styled.COMPONENT_HEADER_HEIGHT - Styled.COMPONENT_ITEM_HEIGHT;
          } else {
            topPosition = document.body.offsetHeight - Styled.COMPONENT_HEIGHT - 20;
          }
      }

      return { left: leftPosition, top: topPosition };
    };

    const handleViewportChange = () => setPosition(calculateCoords(anchorEl));

    if (anchorEl && open) {
      setPosition(calculateCoords(anchorEl));
      setReady(true);

      window.addEventListener('resize', handleViewportChange);

      return () => {
        setReady(false);

        window.removeEventListener('resize', handleViewportChange);
      };
    }
  }, [anchorEl]);

  return { position, ready };
}
