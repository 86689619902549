/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';

import { currentDiagramStore } from 'stores';
import { fileService, projectService } from 'services';

export default class LinkedFormVariableProvider {
  constructor(eventBus) {
    this.eventBus = eventBus;

    this.getLinkedForm.bind(this);

    eventBus.on('formVariableProvider.getFormSchema', this.getLinkedForm);
  }

  getLinkedForm({ element }) {
    const formId = getFormId(element);
    if (!formId) {
      return;
    }

    return getFormSchema(formId);
  }
}

LinkedFormVariableProvider.$inject = ['eventBus'];

// helpers //////////

function getFormId(element) {
  const extensionElements = element.get('extensionElements');

  if (!extensionElements) {
    return;
  }

  const values = extensionElements.get('values');

  if (!values || !values.length) {
    return;
  }

  const formDefinition = values.find((value) => is(value, 'zeebe:FormDefinition'));
  if (!formDefinition) {
    return;
  }

  return formDefinition.get('formId');
}

async function getFormSchema(formId) {
  const { project } = currentDiagramStore.state;

  const files = await projectService.fetchFilesByFormId(project.id, formId);

  if (files.length !== 1) {
    return;
  }

  const file = files[0];
  const fileContent = await fileService.fetchById(file.id);

  return fileContent.content;
}
