/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineNotification } from '@carbon/react';

export function SelfReviewWarning() {
  return (
    <InlineNotification title="You are reviewing your own changes!" hideCloseButton kind="warning" lowContrast>
      This is only possible because you have admin permission levels. Proceed with caution.
    </InlineNotification>
  );
}
