/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { CONFIGURED_GIT_PROVIDER, SupportedGitProviders } from './constants';

export const getGitProvider = () => {
  const gitProvider = SupportedGitProviders[CONFIGURED_GIT_PROVIDER];
  if (!gitProvider) {
    throw new Error('Unsupported Git provider was configured');
  }
  return gitProvider;
};

export const getGitProviderLabel = () => {
  const gitProvider = getGitProvider();
  return gitProvider.label;
};

export const getGitProviderIcon = () => {
  const gitProvider = getGitProvider();
  return gitProvider.icon;
};
