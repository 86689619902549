/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Button, ClickableTile, Link, Stack } from '@carbon/react';
import { styles } from '@carbon/elements';

export const ChatboxHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 16px;
  max-height: 64px;
  width: 100%;
`;

export const CamundaCopilotHeader = styled.h1`
  color: var(--cds-text-primary);
  ${styles.heading03};
  font-weight: 700;
  font-size: 22px;
`;

export const HeaderGroup = styled(Stack)`
  align-items: center;
`;

export const HeaderSpace = styled.div`
  flex-grow: 1;
`;

export const DocsAiLink = styled(Link)`
  margin: 0 10px 0 10px;
  cursor: pointer;
`;

export const CloseButton = styled(Button)`
  margin-left: 10px;
  border: 0;
  color: var(--cds-icon-primary);
`;

/**
 * @type {import('styled-components').IStyledComponent<'web', {
 *     $isOpen: boolean,
 * } & React.ComponentProps<typeof Stack>>}
 */
export const ExamplePromptGroup = styled(Stack)`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ExamplePrompt = styled(ClickableTile)`
  background-color: #f4f4f4;
  border: 1px solid #a8a8a8;
  margin-top: 8px;
  padding: 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.16px;
`;

const chatboxTileBottomPaddingPixels = 4;

/**
 * @type {import('styled-components').IStyledComponent<'web', {
 *     $isOpen: boolean,
 *     $height: number,
 *     $position: {left: number, top: number}
 * } & React.HTMLAttributes<HTMLDivElement> & React.RefAttributes<HTMLDivElement>>}
 */
export const ChatboxTile = styled.div`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: ${({ $height }) => '' + $height + 'px' || '350px'};
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  width: 450px;
  ${({ $position }) =>
    $position
      ? `
        position: absolute;
        top: ${$position.top - chatboxTileBottomPaddingPixels}px;
        left: ${$position.left}px;
      `
      : // Position at bottom - center
        `
        position: fixed;
        right: 50%;
        bottom: 1rem;
        transform: translateX(50%);
      `}
  h2 {
    ${styles.headingCompact01};
    color: var(--cds-text-secondary);
  }
`;

export const ChatboxBody = styled.div`
  padding: 16px;
  padding-top: 0;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  overflow-y: auto;
`;

export const BodyGroup = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 8px;
  ${styles.body01};
`;

export const TextDescriptionSpan = styled.span`
  word-wrap: break-word;
  width: 400px;
  line-height: 20px;
  color: var(--cds-text-primary);
`;

export const ChatboxForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: #f4f4f4;
  border: 1px solid #8d8d8d;
  width: calc(100% - 32px);
  margin-bottom: var(--cds-spacing-05);
`;

export const ChatboxSend = styled(Button)`
  align-self: flex-end;
`;

// This wrapper is used to properly position the Carbon button. Button itself cannot be directly styled, because Carbon uses a wrapper around it.
export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const StyledTextArea = styled.textarea`
  background: #f4f4f4;
  border: none;
  padding: 10px;
  width: 100%;
  resize: none;
  overflow-y: scroll;
  min-height: 68px;
  max-height: 68px;
  line-height: 24px;
  margin-right: var(--cds-spacing-08);
`;
