/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex: 1;
  overflow-x: hidden;
`;

export const SuperUserModeWarning = styled.div`
  padding: 30px 65px 10px 65px;

  .inline-notification-warning {
    max-width: initial;
  }
`;
