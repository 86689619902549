/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { LinkCrumb, DropdownCrumb, TextCrumb } from './variants';

export default function Breadcrumb({ variant, ...rest }) {
  switch (variant) {
    case 'link':
      return <LinkCrumb {...rest} />;
    case 'dropdown':
      return <DropdownCrumb {...rest} />;
    default:
      return <TextCrumb {...rest} />;
  }
}
