/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { userStore } from 'stores';

export default ({ orgName, licenseTag }) => {
  return {
    ariaLabel: 'Navigation',
    orgName: orgName,
    elements: [],
    licenseTag,
    tags: userStore.isSuperUserModeActive
      ? [
          {
            key: 'superUserMode',
            label: 'Super-user mode',
            color: 'high-contrast'
          }
        ]
      : undefined
  };
};
