/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getSanitizedZeebeVersionString } from './cluster-version-util';

export default function getClusterGeneration(cluster) {
  const version = getSanitizedZeebeVersionString(cluster.generation?.versions?.zeebe);
  const generation = version ? `Zeebe ${version}` : cluster.generation?.version?.zeebe;
  return generation ? `(${generation})` : '';
}
