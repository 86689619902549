/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { action, makeObservable, observable, computed, runInAction } from 'mobx';
import { Loading } from '@carbon/react';
import { Observer } from 'mobx-react';

import {
  processApplicationStore,
  confirmActionStore,
  currentDiagramStore,
  organizationStore,
  userStore,
  idpApplicationStore
} from 'stores';
import {
  fileService,
  folderService,
  idpApplicationService,
  idpProjectService,
  projectService,
  tracingService,
  trackingService
} from 'services';
import {
  getDuplicateResourceInProcessApplicationErrorMessage,
  isDuplicateResourceInProcessApplicationError,
  isFile,
  isFolder,
  isIdpApplication,
  isIdpProject,
  isProcessApplication
} from 'utils/helpers';
import FileMapper from 'utils/file-mapper';
import { stringifyEntityCounts } from 'utils/dialog-helpers';
import RealtimeChannel from 'utils/RealtimeChannel';
import { validateFiles } from 'utils/file-io';
import {
  BPMN,
  CONNECTOR_TEMPLATE,
  DEFAULT,
  DIAGRAM_TYPES,
  FOLDER,
  FORM,
  IDP_APPLICATION,
  PROCESS_APPLICATION
} from 'utils/constants';
import { isCamunda7File } from 'utils/file-io/validate-files';
import upsertExecutionPlatformToC8 from 'utils/web-modeler-diagram-parser/upsert-execution-platform';
import { notificationStore } from 'components/NotificationSystem';
import pluralize from 'utils/pluralize';
import { getBpmnAttributes } from 'utils/web-modeler-diagram-parser';
import history from 'utils/history';
import findCollaborator from 'utils/invitation/find-collaborator';
import removeCollaborator from 'utils/invitation/remove-collaborator';
import getDeleteKey from 'utils/invitation/get-delete-key';

import {
  showC7FileUploadSuccess,
  showFileUploadFailedError,
  showInvalidFilesError,
  showProgressIndicationInfo,
  showSimpleError
} from './utils/notifications';

const getUpdateKey = getDeleteKey;

export const DELETE_DIAGRAM_WARNINGS = {
  LINKED_DIAGRAM: 'Links from or to diagrams will be removed.',
  COMMENTS: 'All comments will be deleted.',
  SHARED_LINK: 'Shared links will be inaccessible.',
  PUBLIC_MILESTONE: "A connector template has at least one organization's public version."
};

export const DOWNLOAD_LIMIT_EXCEEDED = 'DOWNLOAD_LIMIT_EXCEEDED';

class ProjectStore {
  loading = true;
  isCreatingFile = false;
  project = {};
  collaborators = [];
  folder = {};
  isFolder = false;
  fromProcessApplicationId = null;
  fromIdpApplicationId = null;
  permissionChannel = null;
  filesToUpload = 0;
  uploadedFiles = 0;

  constructor() {
    makeObservable(this, {
      loading: observable,
      isCreatingFile: observable,
      filesToUpload: observable,
      uploadedFiles: observable,
      project: observable,
      collaborators: observable,
      folder: observable,
      isFolder: observable,
      fromProcessApplicationId: observable,
      fromIdpApplicationId: observable,
      entities: computed,
      reset: action,
      initProject: action,
      initFolder: action,
      fetchProjectData: action,
      fetchCollaboratorData: action
    });
  }

  /**
   * Initializes the realtime channel and fetches folder
   * or project data, based on the given type.
   *
   * @param {String} type Either `folder` or `project`.
   * @param {String} id The `folderId` or `projectId`
   * @param {Boolean|undefined} fromProcessApplicationId populated when triggered from a process application
   * @param {Boolean|undefined} fromIdpApplicationId populated when triggered from an IDP application
   */
  async init(type, id, fromProcessApplicationId, fromIdpApplicationId) {
    if (userStore.isAuthenticated) {
      this.permissionChannel = new RealtimeChannel(`private-user-${userStore.userId}`, {
        'permission:edit': this.handlePermissionEdited,
        'permission:remove': this.handlePermissionRemoved
      });

      this.permissionChannel.subscribe();
    }

    runInAction(() => {
      this.fromProcessApplicationId = fromProcessApplicationId;
      this.fromIdpApplicationId = fromIdpApplicationId;
    });

    if (type === 'folder') {
      await this.initFolder(id);
    } else {
      await this.initProject(id);
    }
  }

  /**
   * Resets the realtime channel.
   */
  reset = () => {
    if (userStore.isAuthenticated && this.permissionChannel) {
      this.permissionChannel.unsubscribe();
    }
    this.loading = true;
    this.project = {};
    this.collaborators = [];
    this.folder = {};
    this.isFolder = false;
    this.fromProcessApplicationId = null;
    this.fromIdpApplicationId = null;
  };

  /**
   * Fetches data about the current project.
   *
   * @param {String} projectId
   */
  // @ts-expect-error TS2339
  async fetchProjectData({ projectId, includeFiles = false, includeFolders = false }) {
    try {
      const [project, collaborators] = await Promise.all([
        projectService.fetchById({ projectId, includeFiles, includeFolders }),
        projectService.fetchCollaborators(projectId)
      ]);

      runInAction(() => {
        this.project = project ?? {};
        this.collaborators = collaborators ?? [];
      });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not fetch the project information. Please try again later.');
      tracingService.traceError(ex, 'Failed to fetch project data');
    }
  }

  /**
   * Fetches collaborators for a given project id.
   *
   * @param {String} projectId
   */
  async fetchCollaboratorData(projectId) {
    try {
      const collaborators = await projectService.fetchCollaborators(projectId);

      runInAction(() => {
        this.collaborators = collaborators ?? [];
      });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not fetch collaborator information. Please try again later.');
      tracingService.traceError(ex, 'Failed to fetch collaborator data');
    }
  }

  /**
   * Fetches all data required to display the project page, including files and
   * collaborators.
   *
   * @param {String} projectId
   */
  async initProject(projectId) {
    this.loading = true;
    // @ts-expect-error TS2345
    await this.fetchProjectData({
      projectId,
      includeFiles: true,
      includeFolders: true
    });

    organizationStore.compareAndSwitchOrganization(this.project.organizationId);

    runInAction(() => {
      this.isFolder = false;
      this.loading = false;
    });

    trackingService.trackPageView(
      this.fromProcessApplicationId ? 'process-application' : this.fromIdpApplicationId ? 'idp-application' : 'project'
    );
  }

  async initFolder(folderId) {
    this.loading = true;

    try {
      const folder = await folderService.fetchById(folderId);

      if (folder.parentId) {
        folder.parent = await folderService.fetchById(folder.parentId);
      }

      // @ts-expect-error TS2345
      await this.fetchProjectData({
        projectId: folder.projectId,
        includeFiles: true,
        includeFolders: true
      });

      runInAction(() => {
        this.folder = { ...folder, type: FOLDER };
        this.isFolder = true;
        this.loading = false;
      });

      trackingService.trackPageView(
        this.fromProcessApplicationId ? 'process-application' : this.fromIdpApplicationId ? 'idp-application' : 'folder'
      );
    } catch (ex) {
      notificationStore.showError('Yikes! Could not fetch the folder information. Please try again later.');
      tracingService.traceError(ex, 'Failed to fetch folder data');
    }
  }

  /**
   * Renames the current project.
   *
   * @param {String} name The new project name.
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async renameProject(name) {
    if (name.trim().length === 0 || this.project.name === name) {
      return false;
    }

    try {
      await projectService.rename(this.project.id, { name });

      trackingService.trackRename(DEFAULT);

      runInAction(() => (this.project.name = name));
      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not rename your project. Please try again later.');
      tracingService.traceError(ex, 'Failed to rename project');

      return false;
    }
  }

  /**
   * Deletes the current project.
   *
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async deleteProject() {
    try {
      const confirmed = await confirmActionStore.confirm({
        title: `Deleting project`,
        text: `You're about to delete the project "${this.project.name}" and its files. Collaborators won't be able to access them afterwards.`,
        confirmLabel: `Delete project`,
        isDangerous: true
      });

      if (!confirmed) {
        return false;
      }

      await projectService.destroy(this.project.id);

      notificationStore.showSuccess(`Your project has been deleted.`);

      return true;
    } catch (ex) {
      notificationStore.showError(`Yikes! Could not delete your project. Please try again later.`);
      tracingService.traceError(ex, 'Failed to delete project');

      return false;
    }
  }

  /**
   * Makes the currently authenticated user leave a project.
   *
   * @param {String} collaborator The collaborator to remove.
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async leaveProject(collaborator) {
    try {
      const confirmed = await confirmActionStore.confirm({
        title: 'Leave project',
        text: 'Are you sure you want to stop collaborating on the selected project?',
        confirmLabel: 'Leave project',
        isDangerous: true
      });

      if (!confirmed) {
        return false;
      }

      await projectService.destroyCollaborator(
        this.project.id,
        // @ts-expect-error TS2339
        getDeleteKey({ email: collaborator.email, iamId: collaborator.iamId })
      );

      notificationStore.success({
        title: 'Left project',
        content: 'You have left the project.'
      });

      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not leave the project. Please try again later.');
      tracingService.traceError(ex, 'Failed to leave project');

      return false;
    }
  }

  /**
   * Appends a number of files to the existing list.
   *
   * @param {Array} files
   */
  addFiles(files) {
    if (!Array.isArray(files)) {
      files = [files];
    }

    runInAction(() => {
      if (this.fromProcessApplicationId) {
        processApplicationStore.processApplication.files = [
          ...(processApplicationStore.processApplication?.files || []),
          ...files
        ];
      } else if (this.isFolder) {
        this.folder.files = [...(this.folder?.files || []), ...files];
      } else {
        this.project.files = [...(this.project?.files || []), ...files];
      }
    });
  }

  /**
   * Duplicates a selection of files.
   *
   * @param {Array|Object} files
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async duplicateFiles(files) {
    if (!Array.isArray(files)) {
      files = [files];
    }

    const fileIds = files.map((file) => file.id);

    try {
      const response = await fileService.duplicate(fileIds);

      let parentType = DEFAULT;
      if (this.fromProcessApplicationId) {
        parentType = PROCESS_APPLICATION;
      } else if (this.isFolder) {
        parentType = FOLDER;
      }

      files.forEach(({ id, type }) => trackingService.trackCreateFile(id, 'duplicate', type, parentType));

      runInAction(() => {
        if (this.fromProcessApplicationId) {
          processApplicationStore.processApplication.files = [
            ...processApplicationStore.processApplication.files,
            ...response
          ];
        } else if (this.isFolder) {
          this.folder.files = [...this.folder.files, ...response];
        } else {
          this.project.files = [...this.project.files, ...response];
        }
      });

      return true;
    } catch (ex) {
      showSimpleError({
        title: `Failed to duplicate ${pluralize('resource', fileIds.length)}`,
        content: `An error occurred while duplicating the selected ${pluralize('resource', fileIds.length)}.`
      });
      tracingService.traceError(ex, 'Failed to duplicate files');

      return false;
    }
  }

  /**
   * Generates a confirmation prompt that displays before the entity
   * move is executed. It displays the number of folders, files and
   * IDP applications to be moved and eventual warnings about diagram links.
   *
   * @param {String[]} fileIds
   * @param {String[]} folderIds
   * @param {String[]} idpApplicationIds
   * @returns {Promise}
   */
  async confirmMove(fileIds, folderIds, idpApplicationIds) {
    const { warnings } =
      fileIds.length > 0 || folderIds.length > 0 ? await fileService.moveDryRun(fileIds, folderIds) : { warnings: [] };

    const text = stringifyEntityCounts({
      fileCount: fileIds.length,
      folderCount: folderIds.length + idpApplicationIds.length
    });

    return confirmActionStore.confirm({
      title: `Moving ${text}`,
      text: (
        <Fragment>
          You're about to move {text}. Collaborators might lose access to this content.
          {warnings.length > 0 && (
            <ul>
              {warnings.map((warning) => (
                <li key={warning}>{DELETE_DIAGRAM_WARNINGS[warning]}</li>
              ))}
            </ul>
          )}
        </Fragment>
      ),
      confirmLabel: 'Move',
      isDangerous: true
    });
  }

  /**
   * Moves a selection of files into a new or existing project.
   *
   * @param {Array|Object} entities
   * @param {String} targetProjectId
   * @param {String} targetFolderId
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async moveEntities(entities, targetProjectId, targetFolderId) {
    if (!Array.isArray(entities)) {
      entities = [entities];
    }

    const fileIds = entities.filter(isFile).map((file) => file.id);
    const folderIds = entities
      .filter((entity) => isFolder(entity) || entity.type === PROCESS_APPLICATION)
      .map((folder) => folder.id);
    const idpApplicationIds = entities.filter((entity) => isIdpApplication(entity)).map((entity) => entity.id);

    try {
      if (targetProjectId !== this.project.id) {
        const confirmed = await this.confirmMove(fileIds, folderIds, idpApplicationIds);

        if (!confirmed) {
          return false;
        }
      }

      const requests = [];

      if (fileIds.length) {
        requests.push(fileService.move({ fileIds, targetProjectId, targetFolderId }));
      }

      if (folderIds.length) {
        requests.push(
          folderService.move({
            folderIds,
            projectId: targetProjectId,
            parentId: targetFolderId
          })
        );
      }

      if (idpApplicationIds.length) {
        requests.push(idpApplicationService.moveIdpApplications(idpApplicationIds, targetProjectId, targetFolderId));
      }

      await Promise.all(requests);

      notificationStore.showSuccess('Your data has been moved.');

      return true;
    } catch (ex) {
      const errorContent = isDuplicateResourceInProcessApplicationError(ex)
        ? getDuplicateResourceInProcessApplicationErrorMessage(ex)
        : `An error occurred while moving the selected ${pluralize('resource', entities.length)}.`;

      showSimpleError({
        title: `Failed to move ${pluralize('resource', entities.length)}`,
        content: errorContent
      });

      return false;
    }
  }

  /**
   * Creates a new file.
   *
   * @param {Object} Parameters The file parameters.
   * @ts-expect-error TS1064 returns {Object} The new file.
   */
  async createFile({
    type,
    file,
    source = 'button',
    processTemplateUsed,
    importUrl,
    showNotification = true,
    isC7Import = false
  }) {
    runInAction(() => {
      this.isCreatingFile = true;
    });

    const entity = new FileMapper(type).generate(file);
    const folderId = this.isFolder ? this.folder.id : null;

    const payload = {
      ...entity,
      // Removing the extension from the filename
      name: entity.name?.replace(/\.[^/.]+$/, ''),
      projectId: this.project?.id || file.projectId,
      folderId: this.fromProcessApplicationId ?? folderId
    };

    if (importUrl) {
      payload.importUrl = importUrl;
    }

    try {
      const createdFile = await fileService.create(payload);

      let parentType = DEFAULT;
      if (this.fromProcessApplicationId) {
        parentType = PROCESS_APPLICATION;
      } else if (this.isFolder) {
        parentType = FOLDER;
      }

      trackingService.trackCreateFile(
        createdFile.id,
        source,
        type,
        parentType,
        file?.content,
        processTemplateUsed,
        null,
        isC7Import
      );

      return createdFile;
    } catch (ex) {
      tracingService.traceError(ex, 'Failed to create file');

      if (showNotification) {
        const errorContent = isDuplicateResourceInProcessApplicationError(ex)
          ? getDuplicateResourceInProcessApplicationErrorMessage(ex)
          : entity.name;

        showSimpleError({
          title: 'Failed to upload resource',
          content: errorContent
        });
      }

      throw ex;
    } finally {
      runInAction(() => {
        this.isCreatingFile = false;
      });
    }
  }

  /**
   * Creates a new folder.
   *
   * @ts-expect-error TS1064 returns {Object} The new folder.
   */
  async createFolder() {
    try {
      const response = await folderService.create({
        name: 'New folder',
        projectId: this.project.id,
        parentId: this.isFolder ? this.folder.id : null
      });

      // @ts-expect-error TS2345
      trackingService.trackCreateFolder({
        from: 'button',
        parentType: this.isFolder ? 'folder' : 'project',
        folderType: 'folder'
      });

      return response;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not create your folder. Please try again later.');
      tracingService.traceError(ex, 'Failed to create folder');
    }
  }

  /**
   * Renames the current folder.
   *
   * @param {String} name The new folder name.
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async renameFolder(name) {
    if (name.trim().length === 0 || this.folder.name === name) {
      return false;
    }

    try {
      await folderService.rename(this.folder.id, { name });

      trackingService.trackRename(FOLDER);

      runInAction(() => (this.folder.name = name));
      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not rename your folder. Please try again later.');
      tracingService.traceError(ex, 'Failed to rename folder');

      return false;
    }
  }

  /**
   * Generates a confirmation prompt that displays before the entity
   * deletion is executed. It displays the number of folders, subfolders, files
   * and IDP applications to be deleted and eventual warnings about diagram
   * shares, links, etc.
   *
   * @param {Object[]} entities
   * @param {String[]} fileIds
   * @param {String[]} folderIds
   * @param {String[]} idpApplicationsIds
   * @param {String[]} idpProjectIds
   * @returns {Promise}
   */
  async confirmDeletion(entities, fileIds, folderIds, idpApplicationsIds, idpProjectIds) {
    let { warnings, fileCount, folderCount } =
      folderIds.length || fileIds.length
        ? await fileService.destroyDryRun(fileIds, folderIds)
        : {
            warnings: [],
            fileCount: 0,
            folderCount: 0
          };

    const oneConnectorWithPublicVersion = warnings?.includes('PUBLIC_MILESTONE');

    if (oneConnectorWithPublicVersion && !organizationStore.hasElevatedOrganizationPermissions) {
      return notificationStore.showError(
        `File deletion is not permitted. ${DELETE_DIAGRAM_WARNINGS['PUBLIC_MILESTONE']} Only organization owners, and organization admins are allowed to delete it.`
      );
    }

    const text = stringifyEntityCounts({
      fileCount,
      // `folderCount` contains all folders (including subfolders), so we subtract
      // the selected root folders from that number.
      subfolderCount: folderCount - folderIds.length,
      // `folderIds` contains only IDs for folder and process application, so we add
      // the count for IDP applications and IDP projects
      folderCount: folderIds.length + idpApplicationsIds?.length + idpProjectIds?.length
    });

    folderCount = folderIds.length + idpApplicationsIds?.length + idpProjectIds?.length;

    let dialogContent = text,
      title = text;
    if (fileCount === 1 && folderCount === 0) {
      dialogContent = `the file "${entities[0]?.name}"`;
      title = 'file';
    } else if (folderCount === 1 && fileCount === 0) {
      dialogContent = `the folder "${entities[0]?.name}"`;
      title = 'folder';
    }

    return confirmActionStore.confirm({
      title: `Deleting ${title}`,
      text: (
        <Fragment>
          You're about to delete {dialogContent}. Collaborators won't be able to access this content any longer.
          {warnings.length > 0 && (
            <ul>
              {warnings.map((warning) => (
                <li key={warning}>{DELETE_DIAGRAM_WARNINGS[warning]}</li>
              ))}
            </ul>
          )}
        </Fragment>
      ),
      confirmLabel: 'Delete',
      isDangerous: true,
      ...(fileCount > 0 && { isFileDeletion: true })
    });
  }

  /**
   * Deletes a given array of files and folders.
   *
   * @param {Array} entities files or folders references
   *
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async deleteEntities(entities) {
    if (!Array.isArray(entities)) {
      entities = [entities];
    }

    const fileIds = entities.filter(isFile).map((file) => file.id);
    const folderIds = entities
      .filter((entity) => isFolder(entity) || entity?.type === PROCESS_APPLICATION)
      .map((folder) => folder.id);
    const idpApplicationsIds = entities.filter(isIdpApplication).map((entity) => entity.id);
    const idpProjectIds = entities.filter(isIdpProject).map((entity) => entity.id);
    const foldersCount = entities.filter((entity) => isFolder(entity)).length;
    const processApplicationsCount = entities.filter((entity) => isProcessApplication(entity)).length;

    try {
      const confirmed = await this.confirmDeletion(entities, fileIds, folderIds, idpApplicationsIds, idpProjectIds);

      if (!confirmed) {
        return false;
      }

      await Promise.all([
        ...folderIds.map((folderId) => folderService.destroy(folderId)),
        ...idpApplicationsIds.map((idpApplicationsId) => idpApplicationService.deleteIdpApplication(idpApplicationsId)),
        ...idpProjectIds.map((idpProjectId) => idpProjectService.deleteIdpProject(idpProjectId)),
        ...(fileIds.length > 0 ? [fileService.destroy(fileIds)] : [])
      ]);

      notificationStore.showSuccess('Your data has been deleted.');

      runInAction(() => {
        if (this.fromProcessApplicationId) {
          // Process applications can contain files only
          processApplicationStore.processApplication.files = processApplicationStore.processApplication.files.filter(
            (file) => !fileIds.includes(file.id)
          );
          trackingService.trackDeleteEntities(PROCESS_APPLICATION, fileIds.length);
        } else if (this.fromIdpApplicationId) {
          idpApplicationStore.idpApplication.idpProjects = idpApplicationStore.idpApplication?.idpProjects.filter(
            (idpProject) => !idpProjectIds.includes(idpProject.id)
          );
          const idpProjects = idpApplicationStore.idpApplication?.idpProjects;
          trackingService.trackDeleteEntities(IDP_APPLICATION, idpProjects?.length);
        } else if (this.isFolder) {
          this.folder.children = this.folder.children.filter((folder) => !folderIds.includes(folder.id));
          this.folder.processApplications = this.folder.processApplications.filter(
            (processApplication) => !folderIds.includes(processApplication.id)
          );
          this.folder.files = this.folder.files.filter((file) => !fileIds.includes(file.id));
          trackingService.trackDeleteEntities(FOLDER, fileIds.length, foldersCount, processApplicationsCount);
        } else {
          this.project.processApplications = this.project.processApplications.filter(
            (processApplication) => !folderIds.includes(processApplication.id)
          );
          this.project.idpApplications = this.project.idpApplications.filter(
            (idpApplication) => !idpApplicationsIds.includes(idpApplication.id)
          );
          this.project.folders = this.project.folders.filter((folder) => !folderIds.includes(folder.id));
          this.project.files = this.project.files.filter((file) => !fileIds.includes(file.id));
          trackingService.trackDeleteEntities(DEFAULT, fileIds.length, foldersCount, processApplicationsCount);
        }
      });

      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not delete your folders or files. Please try again later.');
      tracingService.traceError(ex, 'Failed to delete entities');

      return false;
    }
  }

  /**
   * Deletes a certain collaborator from the project, based on the given email.
   *
   * @param {String} collaboratorToDelete The collaborator to delete.
   * @ts-expect-error TS1064 returns {Boolean}
   */
  async deleteCollaborator(collaboratorToDelete) {
    try {
      const confirmed = await confirmActionStore.confirm({
        title: 'Removing collaborator from project',
        text: 'Are you sure you want to remove this collaborator from the project?',
        isDangerous: true,
        confirmLabel: 'Remove'
      });

      if (!confirmed) {
        return false;
      }

      await projectService.destroyCollaborator(
        this.project.id,
        getDeleteKey({
          // @ts-expect-error TS2339
          email: collaboratorToDelete.email,
          // @ts-expect-error TS2353
          iamId: collaboratorToDelete.iamId
        })
      );

      runInAction(() => {
        this.collaborators = removeCollaborator(this.collaborators, collaboratorToDelete);
      });

      notificationStore.showSuccess(`The collaborator has been removed from "${this.project.name}".`);

      return true;
    } catch (ex) {
      if (this.hasOnlyOneAdmin) {
        notificationStore.showError('Removing the last admin of a project is not possible.');
      } else {
        notificationStore.showError(
          `Yikes! The collaborator could not be removed from "${this.project.name}". Please try again later.`
        );
        tracingService.traceError(ex, 'Failed to remove collaborator');
      }
    }
  }

  /**
   * Updates permission access to the currently opened project for a collaborator
   * after receiving a realtime update.
   *
   * @param {Object} param
   * @param {String} [param.payload] The Socket payload, containing the project id.
   */
  handlePermissionEdited = ({ payload }) => {
    const { projectId } = JSON.parse(payload);

    if ([this.project.id, currentDiagramStore.state.project.id].includes(projectId)) {
      projectService.fetchById({ projectId, includeFiles: true, includeFolders: true }).then((project) => {
        if (this.project.id === projectId) {
          runInAction(() => (this.project = project));
        }

        if (currentDiagramStore.state.project.id === projectId) {
          currentDiagramStore.setProject(project);
        }
      });
    }
  };

  /**
   * Removes a collaborator from a project after receiving a
   * realtime update.
   *
   * @param {Object} param
   * @param {String} [param.payload] The Socket payload, containing the project.
   */
  handlePermissionRemoved = ({ payload }) => {
    const { projectIds, intent } = JSON.parse(payload);

    if (intent === 'remove' && projectIds.includes(this.project.id)) {
      history.push('/');

      notificationStore.info({
        title: 'Removed from project',
        content: `Your access to "${this.project.name}" has been removed.`
      });
    }
  };

  /**
   * Updates the permission of a given collaborator (via email).
   *
   * @param {String} email The collaborator to update.
   * @param {String} iamId The IAM ID of the collaborator.
   * @param {String} permissionAccess The updated permission.
   */
  async updateCollaboratorPermission(email, iamId, permissionAccess) {
    try {
      await projectService.updateCollaboratorPermissions(this.project.id, {
        // @ts-expect-error TS2353
        ...getUpdateKey({ email, iamId }),
        permissionAccess
      });

      notificationStore.showSuccess('Collaborator permissions have been updated.');

      const collaborator = findCollaborator(this.collaborators, {
        email,
        // @ts-expect-error TS2353
        iamId
      });
      runInAction(() => {
        collaborator.permissionAccess = permissionAccess;
      });
    } catch (ex) {
      if (this.hasOnlyOneAdmin) {
        notificationStore.showError(
          'As long as the project has only one admin, any attempts to modify or change their role will not be possible.'
        );
      } else {
        notificationStore.showError('Could not update the collaborator permissions.');
        tracingService.traceError(ex, 'Failed to update collaborator permissions');
      }
    }
  }

  uploadFiles = async (files) => {
    const validFileTypes = [...DIAGRAM_TYPES, FORM, CONNECTOR_TEMPLATE];

    const { valid, invalid } = await validateFiles(files, validFileTypes);
    const fileUploadErrors = [];

    if (invalid.length > 0) {
      showInvalidFilesError(invalid);
    }

    if (valid.length > 0) {
      runInAction(() => {
        this.filesToUpload = valid.length;
        this.uploadedFiles = 0;
      });

      const progressNotification = showProgressIndicationInfo(
        valid,
        <Observer>
          {() => (
            <div style={{ display: 'inline-flex', gap: '10px' }}>
              <Loading withOverlay={false} small /> {this.uploadedFiles} / {this.filesToUpload}
            </div>
          )}
        </Observer>
      );

      const createdFiles = [];
      const c7CreatedFiles = [];

      for (const validFile of valid) {
        try {
          const isC7File = isCamunda7File(validFile);

          if (isC7File) {
            validFile.content = await upsertExecutionPlatformToC8(validFile.type, validFile.content);
          }

          let fileWithAdditionalAttributes = await this.addAdditionalAttributesToFile(validFile);
          const createdFile = await this.createFile({
            type: fileWithAdditionalAttributes.type,
            file: fileWithAdditionalAttributes,
            source: 'upload',
            showNotification: false,
            isC7Import: isC7File
          });

          if (createdFile) {
            runInAction(() => {
              this.uploadedFiles++;
            });

            createdFiles.push(createdFile);

            if (isC7File) {
              c7CreatedFiles.push(createdFile);
            }
          } else {
            fileUploadErrors.push({
              error: new Error('Could not create file'),
              filename: validFile.name
            });
          }
        } catch (ex) {
          tracingService.traceError(ex, 'Failed to upload file');

          fileUploadErrors.push({
            error: ex,
            filename: validFile.name
          });
        }
      }

      // We remove the progress notification after all files have been uploaded.
      notificationStore.disposeNotification(progressNotification.id, progressNotification.props.kind, true);

      if (createdFiles.length > 0) {
        // We add files only once to the store, so we don't trigger unnecessary updates.
        this.addFiles(createdFiles);
        this.#notifyImports(c7CreatedFiles);
      }

      if (fileUploadErrors.length > 0) {
        showFileUploadFailedError(fileUploadErrors);
      }
    }
  };

  addAdditionalAttributesToFile = async (validFile) => {
    let additionalAttributes = {};
    switch (validFile.type) {
      case BPMN:
        additionalAttributes = await getBpmnAttributes(validFile);
        break;
      case FORM:
        additionalAttributes = { content: validFile.content };
        break;
    }
    return {
      ...validFile,
      ...additionalAttributes
    };
  };

  #notifyImports(c7files) {
    const c7FilesCount = c7files.length;

    if (c7FilesCount > 0) {
      showC7FileUploadSuccess(c7files);
    } else {
      notificationStore.showSuccess('Upload successful');
    }
  }

  /**
   * Given the entities in input, it triggers the download only for the entities that are files
   * @param entities
   * @returns {Promise<number | void>}
   */
  async downloadEntities(entities = []) {
    const [files, folders] = this.extractFilesAndFolders(entities);
    let infoNotification;

    const disposeInfoNotification = () => {
      if (infoNotification) {
        notificationStore.disposeNotification(infoNotification.id, 'info');
      }
    };

    if (files.length > 1 || folders.length > 0) {
      infoNotification = notificationStore.info(
        {
          title: 'Preparing the download',
          content: 'We are zipping your files. The download starts soon.'
        },
        { duration: 8000 }
      );

      // In order for the user to see the notification, waiting a bit before letting the download start
      await new Promise((resolve) => setTimeout(resolve, 4000));
    }

    try {
      return await fileService.download(files, folders);
    } catch (error) {
      let errorMessage = `Yikes! Could not download the selected ${pluralize('item', files?.length + folders?.length)}. Please try again later.`;

      const isDownloadLimitExceeded = error?.[0]?.reason === DOWNLOAD_LIMIT_EXCEEDED;

      if (isDownloadLimitExceeded && error[0].detail) {
        errorMessage = error[0].detail;
      }

      notificationStore.showError(errorMessage);
    } finally {
      disposeInfoNotification();
    }
  }

  extractFilesAndFolders(entities) {
    return entities.reduce(
      ([filesAcc, foldersAcc], entity) => {
        if (isFile(entity)) {
          const fileExtension = entity.type === CONNECTOR_TEMPLATE ? 'json' : entity.type?.toLowerCase();
          filesAcc.push({
            id: entity.id,
            name: `${entity.name}.${fileExtension}`
          });
        } else if (isFolder(entity) || entity?.type === PROCESS_APPLICATION) {
          foldersAcc.push({
            id: entity.id,
            name: entity.name
          });
        }

        return [filesAcc, foldersAcc];
      },
      [[], []]
    );
  }

  get entities() {
    if (this.loading || !this.project.id) {
      return [];
    }

    if (this.isFolder) {
      return [
        ...this.folder.children.map((folder) => ({
          ...folder,
          type: FOLDER
        })),
        ...(this.folder.processApplications?.map((processApplication) => ({
          ...processApplication,
          type: PROCESS_APPLICATION
        })) || []),
        ...(this.folder.idpApplications?.map((idpApplication) => ({
          ...idpApplication,
          type: IDP_APPLICATION
        })) || []),
        ...this.folder.files.filter(isFile)
      ];
    }

    return [
      ...this.project.folders.map((folder) => ({ ...folder, type: FOLDER })),
      ...(this.project.processApplications?.map((processApplication) => ({
        ...processApplication,
        type: PROCESS_APPLICATION
      })) || []),
      ...(this.project.idpApplications?.map((idpApplication) => ({
        ...idpApplication,
        type: IDP_APPLICATION
      })) || []),
      ...this.project.files.filter(isFile)
    ];
  }

  get hasOnlyOneAdmin() {
    return this.collaborators.filter((collaborator) => collaborator.permissionAccess === 'ADMIN').length === 1;
  }
}

export default new ProjectStore();
