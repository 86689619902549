/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';

class CopyService extends Service {
  /**
   * Creates a new process application off a specific process application version. The version's content
   * will be used for the creation.
   *
   * @param {String} versionId The version's ID that's used as the source.
   * @param targetProjectId The target project's ID that will contain the newly created process application.
   * @param targetFolderId The target folder's ID that will contain the newly created process application.
   * @returns {Promise}
   */
  async createProcessApplicationFromVersion(versionId, targetProjectId, targetFolderId) {
    const payload = {
      targetProjectId,
      targetFolderId
    };

    return this.post(`/internal-api/process-application-versions/${versionId}/duplicate`, payload);
  }
}

export const copyService = new CopyService();
