/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { InformationFilled } from '@carbon/icons-react';

import { Play } from 'icons';
import { Dropdown } from 'components';
import { Link, WithExternalLinkIcon } from 'primitives';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';

import * as Styled from './DeploymentActions.styled';

function StartInstanceButton({ onExecute, runButtonTitle, isRunDisabled }) {
  const { latestDeployedCluster } = deploymentStore;

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Styled.StartInstanceButton
        data-test="start-instance-dropdown"
        size="small"
        title={runButtonTitle}
        onMainButtonClick={onExecute}
        onDropdownClick={(evt) => setAnchorEl(evt.currentTarget)}
        disabled={isRunDisabled}
      >
        <Play /> Run
      </Styled.StartInstanceButton>

      <Dropdown size="small" open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {!latestDeployedCluster ? (
          <Styled.StartInstanceMessage role="menuitem">
            <InformationFilled /> Start an instance to access process details
          </Styled.StartInstanceMessage>
        ) : (
          <>
            <Styled.DropdownListGroup>
              <Link href={deploymentStore.operateUrl} target="_blank" rel="noreferrer">
                <Styled.DropdownListItem aria-label="View process instance button">
                  <WithExternalLinkIcon label="View process instances" />
                </Styled.DropdownListItem>
              </Link>
            </Styled.DropdownListGroup>

            <Styled.DropdownListGroup>
              <Styled.ClusterDropdownListItem disabled>{latestDeployedCluster.name}</Styled.ClusterDropdownListItem>
            </Styled.DropdownListGroup>
          </>
        )}
      </Dropdown>
    </>
  );
}

export default observer(StartInstanceButton);
