/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * Checks if the submited change object has
 * a property key of value Target or Source and if the remove property is true
 * @param {*} change
 */
export default function checkForRemovedTargetOrSource(change) {
  return ['Target', 'Source'].includes(change.property) && change.removed === true;
}
