/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';

let setterFn;

const useLoadingState = () => {
  const [isLoading, setIsLoading] = useState(false);

  setterFn = setIsLoading;

  return { isLoading };
};

const startLoading = () => setterFn(true);
const stopLoading = () => setterFn(false);

export { startLoading, stopLoading, useLoadingState };
