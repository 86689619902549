/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { InboundConnectorDetails, InboundConnectorDetailError, InboundConnectorLogs } from './';

export const InboundConnectorDetailContainer = ({ inboundData, inboundLogs, initialLogLoadingInProgress }) => {
  return (
    <div>
      <div>Details</div>
      <div>
        <InboundConnectorDetailError error={inboundData?.health?.error} />
        <InboundConnectorDetails
          clusterUrl={inboundData?.clusterUrl}
          details={inboundData?.health?.details}
          data={inboundData?.data}
        />
        {inboundData?.health && (
          <InboundConnectorLogs logs={inboundLogs} initialLogLoadingInProgress={initialLogLoadingInProgress} />
        )}
      </div>
    </div>
  );
};

export default observer(InboundConnectorDetailContainer);
