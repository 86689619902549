/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autorun, toJS } from 'mobx';

import { currentDiagramStore, ootbConnectorsStore } from 'stores';

import DetectConnectorsModule from './detectConnectors';
import DetectInboundConnector from './detectInboundConnector';
import GenerateWebhookIdModule from './generateWebhookId';

export const hasBuiltInTemplates = true;

let storeListenerDisposer;

export default (additionalTemplates) => ({
  __depends__: [DetectConnectorsModule, DetectInboundConnector, GenerateWebhookIdModule],
  __init__: [
    [
      'elementTemplatesLoader',
      function (elementTemplatesLoader) {
        let lasElementTemplates;

        const OOTB_TEMPLATES = ootbConnectorsStore.getOOTBConnectors();

        const setTemplates = (templates) => {
          const newTemplates = [...OOTB_TEMPLATES, ensureCategoryIsPresent(templates)].flat();

          // Avoid unnecessary updates if the templates are the same
          if (JSON.stringify(newTemplates) === JSON.stringify(lasElementTemplates)) {
            return;
          }

          elementTemplatesLoader.setTemplates(newTemplates);
          lasElementTemplates = newTemplates;
        };

        setTemplates(additionalTemplates);

        if (storeListenerDisposer) {
          storeListenerDisposer();
        }

        storeListenerDisposer = autorun(() => {
          if (currentDiagramStore.state?.templates?.length) {
            setTemplates(toJS(currentDiagramStore.state.templates));
          }
        });
      }
    ]
  ]
});

function ensureCategoryIsPresent(templates) {
  return templates.map((template) => {
    if (!template.category) {
      template.category = {
        id: 'connectors',
        name: 'Connectors'
      };
    }
    return template;
  });
}
