/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { trackingService } from 'services';

export default ({ showHelpCenterAndNotifications }) => {
  if (!showHelpCenterAndNotifications) {
    return {
      helpCenterConfig: null,
      notificationsConfig: null
    };
  }

  return {
    helpCenterConfig: {
      origin: 'webmodeler',
      mixpanelTrack: (event, data) => trackingService.track(event, data),
      autoStartSurvey: true
    },
    notificationsConfig: {
      isOpen: false,
      ariaLabel: 'Notifications'
    }
  };
};
