/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef } from 'react';
import { ProcessLandscape, basicWebModelerConfig } from '@camunda/process-landscape';
import minimapModule from 'diagram-js-minimap';

import { diagramControlStore } from 'stores';
export const useInitializeProcessLandscape = (landscapeData, setSelectedNode) => {
  const landscapeContainerRef = useRef(null);
  const landscapeRef = useRef(null);
  const { setModeler, setFullscreenSupport, setResetViewportStrategy } = diagramControlStore;

  const config = basicWebModelerConfig();

  useEffect(() => {
    if (!landscapeData || !landscapeContainerRef.current) {
      return;
    }

    const landscape = new ProcessLandscape({
      root: landscapeContainerRef.current,
      data: landscapeData,
      additionalModules: [minimapModule],
      ...config
    });

    landscapeRef.current = landscape;
    setModeler(landscapeRef.current);
    setFullscreenSupport(false);
    setResetViewportStrategy('process-landscape');

    const handleSelectionChanged = (event) => {
      const newSelection = event.newSelection;
      setSelectedNode(newSelection.length ? newSelection[0] : null);
    };

    landscape.on('selection.changed', handleSelectionChanged);

    return () => {
      landscape.off('selection.changed', handleSelectionChanged);
      landscape.destroy();
      landscapeRef.current = null;
    };
  }, [landscapeContainerRef, landscapeData, setSelectedNode]);

  return { landscapeContainerRef, landscapeRef };
};
