/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, Modal, Callout } from '@carbon/react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';

import reviewStore from 'App/Pages/ProcessApplicationVersions/features/review/ReviewStore';

function RequestReview({ versionId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRequestReview = async () => {
    if (reviewStore.hasOpenReviewForProcessApplication) {
      setIsModalOpen(true);
    } else {
      await reviewStore.requestReview(versionId);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button kind="primary" size="sm" onClick={handleRequestReview}>
        Request review
      </Button>
      {isModalOpen
        ? createPortal(
            <Modal
              open={isModalOpen}
              onRequestClose={closeModal}
              modalHeading="Request new review"
              preventCloseOnClickOutside
              primaryButtonText="Request new review"
              secondaryButtonText="Cancel"
              onRequestSubmit={async () => {
                await reviewStore.requestReview(versionId);
                closeModal();
              }}
            >
              <Callout
                title="Review pending"
                subtitle="There is already a review pending for another version. If you request a new review, the existing review will get canceled."
                kind="warning"
                lowContrast
              />
            </Modal>,
            document.body
          )
        : null}
    </>
  );
}

export default observer(RequestReview);
