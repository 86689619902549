/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Link as _Link } from '@carbon/react';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  .super-user-mode__toggletip-content {
    width: 15rem;
  }
`;

export const Link = styled(_Link)`
  color: var(--cds-link-text-color) !important;
`;
