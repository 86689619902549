/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, FileUploaderButton, Link, Tab, TabList, TabPanels, Tabs } from '@carbon/react';
import { ChevronDown, Enterprise, Folders } from '@carbon/icons-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { idpApplicationStore, idpProjectStore, organizationStore } from 'stores';
import { Dropdown, EntityTable } from 'components';
import history from 'utils/history';
import { ClusterNotification } from 'App/Pages/Project/IdpApplication/idp-project/cluster-notification';
import IdpExtractionFields from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/unstructured-extraction/idp-extraction-fields/IdpExtractionFields';
import { EmptyState } from 'primitives';
import { IdpProjectDocumentUpload } from 'icons';
import { useClusterConnectorSecrets, useClusterStatus } from 'App/Pages/Project/IdpApplication/hooks';
import IdpExtractionProjectPublishModal from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/unstructured-extraction/idp-evaluate-extraction/idp-extraction-project-publish-modal/IdpExtractionProjectPublishModal';

import EvaluateExtraction from './idp-evaluate-extraction';
import IdpDocuments from './idp-documents';
import * as Styled from './UnstructuredExtraction.styled';

const UPLOAD_DOCUMENTS_TAB_INDEX = 0;
const EXTRACT_DATA_TAB_INDEX = 1;
const VALIDATE_EXTRACTION_TAB_INDEX = 2;
const TABS = ['upload', 'extract', 'evaluate'];
const ALLOWED_IDP_DOCUMENT_FILE_TYPES = ['.pdf'];

export const UnstructuredExtraction = observer(
  ({ canUserModifyProject, handleNavigationToVersions, renderTitleContainer }) => {
    // @ts-expect-error TS2339
    const { slug, tab } = useParams();
    const { idpDocuments, idpExtractionFields, idpPublishedVersions, uploadIdpDocuments, deleteIdpDocument } =
      idpProjectStore;
    const { idpApplication } = idpApplicationStore;
    const [isPublishingOnOrganizationLevel, setIsPublishingOnOrganizationLevel] = useState(false);
    const [isPublishExtractionProjectModalVisible, setIsPublishExtractionProjectModalVisible] = useState(false);
    const [latestTestcaseResultLlmModelId, setLatestTestcaseResultLlmModelId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(UPLOAD_DOCUMENTS_TAB_INDEX);
    const clusterStatus = useClusterStatus(idpApplication?.clusterId);
    const areIdpConnectorSecretsMissing = useClusterConnectorSecrets(idpApplication?.clusterId);

    useEffect(() => {
      if (isNoTabSelected()) {
        history.push(`/idp-projects/${slug}/${TABS[UPLOAD_DOCUMENTS_TAB_INDEX]}`);
        setSelectedTabIndex(UPLOAD_DOCUMENTS_TAB_INDEX);
      }
    }, []);

    const isNoTabSelected = () => !tab || TABS.indexOf(tab) === -1;

    const handleIdpExtractionProjectPublication = async (isPublishingOnOrganizationLevel) => {
      setIsPublishingOnOrganizationLevel(isPublishingOnOrganizationLevel);
      setAnchorEl(null);
      await idpProjectStore.determineAndSetActiveExtractionModel(setLatestTestcaseResultLlmModelId);
      setIsPublishExtractionProjectModalVisible(true);
    };

    const extractIdpDocument = async (document) => {
      await idpProjectStore.fetchIdpExtractionFields(document.id);
      idpProjectStore.setActiveExtractionIdpDocument(document);
      history.push(`/idp-projects/${slug}/${TABS[EXTRACT_DATA_TAB_INDEX]}`);
      setSelectedTabIndex(EXTRACT_DATA_TAB_INDEX);
    };

    const handleSwitchToExtractData = (document) => {
      idpProjectStore.setActiveExtractionIdpDocument(document);
      history.push(`/idp-projects/${slug}/${TABS[EXTRACT_DATA_TAB_INDEX]}`);
      setSelectedTabIndex(EXTRACT_DATA_TAB_INDEX);
    };

    const UploadButton = ({ onDocumentsUploadProgress }) => {
      if (!canUserModifyProject) {
        return null;
      }

      return (
        <FileUploaderButton
          name="idp-documents"
          multiple
          disableLabelChanges
          labelText="Upload documents"
          size="lg"
          disabled={!canUserModifyProject}
          accept={ALLOWED_IDP_DOCUMENT_FILE_TYPES}
          onChange={(event) => uploadIdpDocuments(event.target.files, onDocumentsUploadProgress)}
        />
      );
    };

    const NoIdpDocumentsInIdpProject = () => {
      return (
        <EntityTable
          // @ts-expect-error TS2741
          emptyState={
            <EmptyState
              icon={<IdpProjectDocumentUpload width="101" height="108" />}
              title="Upload Sample Documents"
              description={
                <Styled.EmptyStateDescriptionContainer>
                  Start by uploading a set of sample PDF documents that represent the specific document type you want to
                  extract data from. You will use these documents to test the extraction template you create.
                </Styled.EmptyStateDescriptionContainer>
              }
              // @ts-expect-error TS2741
              action={<UploadButton />}
              link={
                <Link
                  href="https://docs.camunda.io/docs/8.7/components/modeler/web-modeler/idp/idp-unstructured-extraction/#upload-documents"
                  target="_blank"
                  rel="noreferrer"
                >
                  For more information, see the document upload guidelines.
                </Link>
              }
            />
          }
          columns={[]}
          rows={[]}
          title={renderTitleContainer}
        />
      );
    };

    return idpDocuments.length > 0 ? (
      <>
        <Styled.TabsContainer>
          {selectedTabIndex === VALIDATE_EXTRACTION_TAB_INDEX && canUserModifyProject && (
            <Styled.Header>
              <Button
                size="md"
                kind="ghost"
                onClick={handleNavigationToVersions}
                disabled={idpPublishedVersions.length === 0}
              >
                Versions
              </Button>
              {organizationStore.hasElevatedOrganizationPermissions ? (
                <>
                  <Button
                    size="md"
                    renderIcon={ChevronDown}
                    iconDescription="ChevronDown"
                    disabled={idpExtractionFields.length === 0}
                    onClick={(evt) => setAnchorEl(evt?.currentTarget)}
                  >
                    Publish
                  </Button>
                  <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                    {/* @ts-expect-error TS2739 */}
                    <Dropdown.ListItem onClick={() => handleIdpExtractionProjectPublication(false)}>
                      <Styled.PublishOption>
                        <Folders />
                        Publish to project
                      </Styled.PublishOption>
                    </Dropdown.ListItem>
                    {/* @ts-expect-error TS2739 */}
                    <Dropdown.ListItem onClick={() => handleIdpExtractionProjectPublication(true)}>
                      <Styled.PublishOption>
                        <Enterprise />
                        Publish to organization
                      </Styled.PublishOption>
                    </Dropdown.ListItem>
                  </Dropdown>
                </>
              ) : (
                <Button size="md" onClick={() => handleIdpExtractionProjectPublication(false)}>
                  Publish to project
                </Button>
              )}
            </Styled.Header>
          )}
          <Tabs
            selectedIndex={selectedTabIndex}
            onChange={({ selectedIndex }) => {
              history.push(`/idp-projects/${slug}/${TABS[selectedIndex]}`);
              setSelectedTabIndex(selectedIndex);
            }}
          >
            <TabList aria-label="IDP project tabs" data-test="idp-project-tabs">
              <Tab role="tab">Upload documents</Tab>
              <Tab role="tab">Extract data</Tab>
              <Tab role="tab">Validate extraction</Tab>
            </TabList>
            <TabPanels>
              <Styled.TabPanel data-test="upload-documents-tab-panel">
                <IdpDocuments
                  canUserModifyProject={canUserModifyProject}
                  idpDocuments={idpDocuments}
                  onDocumentDelete={deleteIdpDocument}
                  onDocumentExtract={extractIdpDocument}
                  renderUploadButton={UploadButton}
                />
              </Styled.TabPanel>
              <Styled.TabPanel data-test="extract-data-tab-panel">
                {selectedTabIndex === 1 && (
                  <>
                    {canUserModifyProject && idpApplication?.clusterId && (
                      <ClusterNotification
                        clusterId={idpApplication.clusterId}
                        areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                      />
                    )}
                    <IdpExtractionFields
                      clusterStatus={clusterStatus}
                      areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                    />
                  </>
                )}
              </Styled.TabPanel>
              <Styled.TabPanel data-test="evaluate-extraction-tab-panel">
                {selectedTabIndex === 2 && (
                  <>
                    {canUserModifyProject && idpApplication?.clusterId && (
                      <ClusterNotification
                        clusterId={idpApplication.clusterId}
                        areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                      />
                    )}
                    <EvaluateExtraction
                      canUserTestExtractionFields={canUserModifyProject}
                      clusterStatus={clusterStatus}
                      areIdpConnectorSecretsMissing={areIdpConnectorSecretsMissing}
                      onSwitchTabToExtractData={handleSwitchToExtractData}
                    />
                  </>
                )}
              </Styled.TabPanel>
            </TabPanels>
          </Tabs>
        </Styled.TabsContainer>
        <IdpExtractionProjectPublishModal
          isPublishExtractionProjectModalVisible={isPublishExtractionProjectModalVisible}
          isPublishingOnOrganizationLevel={isPublishingOnOrganizationLevel}
          selectedLlmModelId={latestTestcaseResultLlmModelId}
          onCloseModal={() => setIsPublishExtractionProjectModalVisible(false)}
        />
      </>
    ) : (
      <NoIdpDocumentsInIdpProject />
    );
  }
);
