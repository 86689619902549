/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default function isEmailValid(email) {
  const regex = new RegExp(
    /^[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*@([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])?$/
  );

  return regex.test(email) && email.length <= 320;
}
