/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { marked } from 'marked';

import * as Styled from './MarkdownRenderer.styled';

export function MarkdownRenderer({ content, noPadding = false }) {
  if (!content) {
    return null;
  }

  try {
    /** @type {(text: string, options: any) => string} */
    const parser = marked.parse;
    const html = parser(content, {
      gfm: true,
      breaks: true
    });

    const Wrapper = noPadding ? Styled.InlineMarkdownWrapper : Styled.MarkdownWrapper;

    return (
      <Wrapper>
        <div className="markdown-content" data-test="markdown-content" dangerouslySetInnerHTML={{ __html: html }} />
      </Wrapper>
    );
  } catch (error) {
    console.error('Error while parsing markdown content:', error);
    return null;
  }
}
