/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { BPMN, DMN, FOLDER, FORM, PROCESS_APPLICATION } from 'utils/constants';
import { Details, Diagram, DiagramWithFolder, Folder, Project, Table } from 'icons';

export default function ItemIcon({ type }) {
  switch (type) {
    case BPMN:
      return <Diagram width="16" height="16" />;
    case DMN:
      return <Table width="16" height="16" />;
    case 'DECISION':
      return '';
    case FORM:
      return <Details width="16" height="16" />;
    case FOLDER:
      return <Folder width="16" height="16" />;
    case PROCESS_APPLICATION:
      return <DiagramWithFolder width="16" height="16" />;
    default:
      return <Project width="16" height="16" />;
  }
}
