/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useHistory } from 'react-router-dom';
import { Button as CarbonButton } from '@carbon/react';

import createDmnDiagram from './createDmnDiagram';

export default function CreateNewDmnButton({ target }) {
  const history = useHistory();

  return (
    <CarbonButton size="sm" kind="ghost" onClick={() => createDmnDiagram(target, history)}>
      + Create DMN diagram
    </CarbonButton>
  );
}
