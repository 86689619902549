/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const SaveAsButtonContainer = styled.div`
  position: absolute;
  right: 35px;
  top: 62px;
  z-index: 100;

  cursor: pointer;

  display: inline-flex;
  align-items: center;
  gap: var(--spacing-tiny);

  font-size: 14px;
  color: var(--cds-blue-60);

  &:hover {
    text-decoration: underline;
  }
`;

export const InputContainer = styled.div`
  margin-top: var(--spacing-medium);
`;
