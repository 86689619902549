/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const STATUS_SERVICE_UNAVAILABLE = 503;

// NOTE: SERVER_ERRORS should always be aligned with the client
export const SERVER_ERRORS = {
  INTERNAL_SERVER_ERROR: 'internal_server_error',
  GENERIC_ERROR: 'generic_error'
};

export const clean = (baseUrl, returnUrl) => {
  const returnURLExclusions = ['login-callback'];
  const isExcluded = returnUrl && returnURLExclusions.some((exclusion) => returnUrl.includes(exclusion));

  if (returnUrl && !isExcluded) {
    if (returnUrl.startsWith('/')) {
      return returnUrl;
    } else if (returnUrl.startsWith(`${baseUrl}/`)) {
      return returnUrl.replace(baseUrl, '');
    }
  }
  return '/';
};

export const getRestApiUserData = async (ctx) => {
  if (ctx?.state?.restApiUser) {
    return ctx.state.restApiUser;
  }
  const { data } = await ctx.restApi.getUser();
  ctx.state.restApiUser = data;
  return ctx.state.restApiUser;
};

export const removeSensitiveHeaders = (proxyReqOpts) => {
  delete proxyReqOpts.headers['cookie'];
  delete proxyReqOpts.headers['x-forwarded-for'];
  delete proxyReqOpts.headers['x-real-ip'];
  delete proxyReqOpts.headers['x-original-forwarded-for'];
};
