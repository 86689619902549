/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import * as Styled from './Tooltip.styled';

export default function Tooltip({ label, children }) {
  return (
    <Styled.Tooltip label={label} align="bottom" enterDelayMs={200} leaveDelayMs={0}>
      {children}
    </Styled.Tooltip>
  );
}
