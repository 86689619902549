/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { InlineNotification } from '@carbon/react';

import { Breadcrumb, HomeContent, ImportModal, TopBar, UnpublishResourceModal } from 'components';
import { trackingService } from 'services';
import { homeStore, organizationStore, userStore } from 'stores';
import { getPageTitle } from 'utils/helpers';
import config from 'utils/config';

import * as Styled from './Home.styled';

export const Home = () => {
  const { orgId } = useParams();
  const { projects, loading, isCreatingProject } = homeStore;
  const { isAuthenticated, isSuperUserModeActive } = userStore;
  const { currentOrganizationId } = organizationStore;
  const location = useLocation();
  const [resourcesToImport, setResourcesToImport] = useState([]);

  useEffect(() => {
    homeStore.reset();

    if (isAuthenticated && orgId) {
      organizationStore.setOrganization(orgId);
    }

    trackingService.trackPageView('home');
    homeStore.init();

    return () => {
      homeStore.reset();
    };
  }, [orgId, isSuperUserModeActive]);

  /**
   * If the user is on the import route, fetch the resources metadata and set the state.
   */
  useEffect(() => {
    if (!config.marketplace?.enabled) {
      return;
    }

    (async () => {
      const isImportRoute = location.pathname && location.search && location.pathname.includes('import');

      if (isImportRoute) {
        const resources = new URLSearchParams(location.search).get('source').split(',');
        setResourcesToImport(resources);
      }
    })();
  }, [location.pathname, location.search, organizationStore]);

  const hasResourcesToImport = !!resourcesToImport.length;

  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle('Home')}</title>
      </Helmet>

      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" isBold to="/" />
      </TopBar.Breadcrumbs>

      {isSuperUserModeActive && (
        <Styled.SuperUserModeWarning>
          <InlineNotification
            className="inline-notification-warning"
            kind="warning"
            lowContrast
            hideCloseButton
            title="Super-user mode is activated:"
            subtitle={`In super-user mode, you have full access to all files in the organization. Please be cautious as changes may be irreversible.`}
          />
        </Styled.SuperUserModeWarning>
      )}

      {config.marketplace?.enabled && (
        <ImportModal open={hasResourcesToImport} resourcesToImportURLs={resourcesToImport} projects={projects} />
      )}
      <UnpublishResourceModal organizationId={currentOrganizationId} />
      <HomeContent projects={projects} projectsLoading={loading} isCreatingProject={isCreatingProject} />
    </Fragment>
  );
};

export default observer(Home);
