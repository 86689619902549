/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { userStore } from 'stores';

import { WebSocket } from './WebSocket';

class TransportCarrier {
  #socket = undefined;

  constructor() {
    this.#initTransportCarrier();
  }

  get socket() {
    if (!userStore.isAuthenticated) {
      return undefined;
    }

    return this.#socket;
  }

  #initTransportCarrier() {
    this.#socket = new WebSocket();
  }
}

export default new TransportCarrier();
