/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  z-index: var(--z-dialog);
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Inner = styled.div`
  position: relative;
  background-color: white;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  overflow-y: auto;
  width: 100%;
  max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
  max-height: calc(100% - 64px);
  border-radius: var(--spacing-tiny);
`;

export const Header = styled.header`
  text-align: center;
  padding: 15px 25px;
  border-bottom: 1px solid var(--silver-darken-87);
`;

export const Title = styled.h2.attrs({ id: 'dialog-title' })`
  margin: 0;
  font-size: 16px;
`;

export const Subtitle = styled.p`
  margin: 0;
`;

export const Content = styled.div`
  padding: 25px;
`;

export const Footer = styled.footer`
  display: flex;
  padding: 15px 25px;
  align-items: center;
  justify-content: ${({ justify }) => `${justify ? justify : 'flex-end'}`};
  border-top: 1px solid var(--silver-darken-87);
  background-color: var(--silver-darken-94);

  > *:not(:last-child) {
    margin-right: 5px;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 11px;
  right: 5px;
  z-index: 1;
`;
