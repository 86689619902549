/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import * as Styled from './BpmnCopilotEmptyStateContent.styled';

export default function BpmnCopilotEmptyStateContent() {
  return (
    <Styled.Container>
      Add elements using the <strong>Append button</strong> <Styled.Plus /> or use the <strong>BPMN Copilot</strong>{' '}
      <Styled.AiButton /> below to generate an initial diagram.
    </Styled.Container>
  );
}
