/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';
import { Modal } from '@carbon/react';

export const IdpApplicationModal = styled(Modal)`
  .cds--modal-container {
    max-width: ${(props) => (props.variant === 'sm' ? '565px' : '740px')};
  }
`;
