/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autosaveVersion } from './autosave-version';

export default class FormAssistantHooks {
  constructor(formEditor) {
    this._formEditor = formEditor;
  }

  onSchemaVerified() {
    const isFormNotEmpty = this._formEditor?.getSchema()?.components?.length;
    if (isFormNotEmpty) {
      autosaveVersion();
    }
  }
}

FormAssistantHooks.$inject = ['formEditor'];
