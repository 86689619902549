/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ActionableNotification } from '@carbon/react';
import { useHistory } from 'react-router-dom';

import { DEPLOYMENT_POLICY_CHECK_RESULT } from 'features/deploy-to-prod-policy/use-check-deployment-policy';

import { reviewMissingProps, noDraftToProdProps, elevatedPermissionsMissingProps } from './warnings';

/**
 * @param {object} props
 * @param {import('features/deploy-to-prod-policy/use-check-deployment-policy').DeploymentPolicyCheckResult} props.deploymentPolicyCheckResult
 * @param {string} [props.processApplicationId] an undefined id indicates we are not in a process application context
 * @param {boolean} [props.isDraft]
 * @param {Function} props.closeModal
 * @returns {JSX.Element|null}
 */
export function DeploymentPolicyWarning({ deploymentPolicyCheckResult, processApplicationId, isDraft, closeModal }) {
  const history = useHistory();
  let notificationContentProps = {};
  if (deploymentPolicyCheckResult === DEPLOYMENT_POLICY_CHECK_RESULT.REVIEW_MISSING && !isDraft) {
    notificationContentProps = reviewMissingProps;
  } else if (deploymentPolicyCheckResult === DEPLOYMENT_POLICY_CHECK_RESULT.REVIEW_MISSING && isDraft) {
    notificationContentProps = noDraftToProdProps({ history, processApplicationId, closeModal });
  } else if (deploymentPolicyCheckResult === DEPLOYMENT_POLICY_CHECK_RESULT.ADMIN_REQUIRED) {
    notificationContentProps = elevatedPermissionsMissingProps;
  } else {
    return null;
  }
  return <ActionableNotification kind="warning" inline lowContrast hideCloseButton {...notificationContentProps} />;
}
