/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { notificationStore } from 'components/NotificationSystem';
import { milestoneService } from 'services';

/**
 * Renames a IDP project version.
 *
 * @param {string} milestoneId - The ID of the milestone to rename.
 * @param {string} currentName - The current name of the version.
 * @param {string} newName - The new name of the version.
 * @param {Function} reloadVersions - A callback function to reload the versions.
 * @param {Function} setIsEditing - A callback function to set the editing state.
 * @returns {Promise<void>}
 */
async function renameIdpProjectVersion(milestoneId, currentName, newName, reloadVersions, setIsEditing) {
  try {
    if (newName.trim().length === 0 || newName.trim() === currentName) {
      setIsEditing(false);
      return;
    }
    await milestoneService.rename(milestoneId, { name: newName });
    setIsEditing(false);
    reloadVersions();
  } catch {
    notificationStore.showError('Failed to rename the IDP project version.');
  }
}

export { renameIdpProjectVersion };
