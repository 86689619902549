/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { Button, DefinitionTooltip } from '@carbon/react';
import styled from 'styled-components';
import moment from 'moment';

import { ReviewCommentModal } from 'App/Pages/ProcessApplicationVersions/features/review/status/ReviewCommentModal';
import { REVIEW_STATUS } from 'App/Pages/ProcessApplicationVersions/features/review/constants';

/**
 * @param {Object} props
 * @param {'APPROVED' | 'CHANGES_REQUESTED'} props.reviewStatus
 * @param {string} props.comment
 * @param {number} props.reviewDate
 * @param {string} props.reviewerName
 * @param {boolean} props.isReviewerCurrentUser
 * @returns {JSX.Element}
 */
export default function ReviewStatus({ reviewStatus, comment, reviewDate, reviewerName, isReviewerCurrentUser }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shouldOverflow = comment && comment.length > 100;
  const displayName = isReviewerCurrentUser ? 'You' : reviewerName;

  const ReviewInfo = (
    <>
      <strong>{displayName}</strong>
      <br />
      <time dateTime={`${reviewDate}`}>{moment(reviewDate).format('dddd, D MMMM YYYY - HH:mm')}</time>
    </>
  );
  return (
    <>
      <section>
        <DefinitionTooltip align="bottom" definition={ReviewInfo} openOnHover>
          {reviewStatus === REVIEW_STATUS.APPROVED ? 'Approved' : 'Changes requested'}
        </DefinitionTooltip>

        {shouldOverflow ? <EllipsisParagraph>{comment}</EllipsisParagraph> : <P>{comment}</P>}
        {shouldOverflow && (
          <ButtonContainer>
            <Button kind="ghost" size="sm" onClick={() => setIsModalOpen(true)}>
              Open comment
            </Button>
          </ButtonContainer>
        )}
      </section>
      {isModalOpen && (
        <ReviewCommentModal comment={comment} open={isModalOpen} onRequestClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: var(--cds-spacing-02, 4px);
`;

const P = styled.p`
  color: var(--Text-text-secondary, #525252);
  /* Utility styles/label-01 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  overflow-wrap: break-word;
  margin-top: var(--cds-spacing-02, 4px);
`;

const EllipsisParagraph = styled(P)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: var(--cds-spacing-02, 4px);
`;
