/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Group } from '@bpmn-io/properties-panel';

import publicationProperties from './PublicationGroup/PublicationProperties';
import { FormProps } from './FormGroup/FormProperties';

export function createPublicationGroup(element) {
  return {
    id: 'process-publication',
    label: 'Publication',
    entries: [
      {
        id: 'process-publication-properties',
        element,
        component: publicationProperties
      }
    ]
  };
}

export function createFormGroup(element, injector) {
  const group = {
    id: 'form',
    label: 'Form',
    entries: [...FormProps({ element, injector })],
    component: Group
  };

  return group.entries.length ? group : null;
}
