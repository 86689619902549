/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import editReviewModalsStore from 'App/Pages/ProcessApplicationVersions/features/review/edit-review/EditReviewModalsStore';
import ApproveModal from 'App/Pages/ProcessApplicationVersions/features/review/perform-review/ApproveModal';
import RequestChangesModal from 'App/Pages/ProcessApplicationVersions/features/review/perform-review/RequestChangesModal';

function EditReviewModals({ versionId, isAdminSelfReview }) {
  const isApproveChangesModalOpen = editReviewModalsStore.approveForVersionId === versionId;
  const isRequestChangesModalOpen = editReviewModalsStore.requestChangesForVersionId === versionId;

  return (
    <>
      {isApproveChangesModalOpen && (
        <ApproveModal
          open={isApproveChangesModalOpen}
          versionId={versionId}
          closeModal={() => editReviewModalsStore.closeApproveModal()}
          isAdminSelfReview={isAdminSelfReview}
        />
      )}
      {isRequestChangesModalOpen && (
        <RequestChangesModal
          open={isRequestChangesModalOpen}
          versionId={versionId}
          closeModal={() => editReviewModalsStore.closeRequestChangesModal()}
          isAdminSelfReview={isAdminSelfReview}
        />
      )}
    </>
  );
}

export default observer(EditReviewModals);
