/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import partial from 'lodash/partial';

import { Typography, Alert, Spinner, Dialog, Button, Input } from 'primitives';
import { shareStore, notificationStore } from 'stores';
import { Danger } from 'icons';
import config from 'utils/config';
import { MultiSelectInput } from 'components';
import { MAX_INPUT_EMAILS } from 'utils/constants';

import PasswordToggle from './PasswordToggle/PasswordToggle';
import RemovePasswordControl from './RemovePasswordControl';
import DestroyLinkControl from './DestroyLinkControl';
import PasswordControl from './PasswordControl/PasswordControl';
import * as Styled from './ShareView.styled';

const LINK_COPIED_MESSAGE = 'The link has been copied to the clipboard.';
const SNIPPET_COPIED_MESSAGE = 'The snippet has been copied to the clipboard.';

class ShareView extends Component {
  UNSAFE_componentWillMount() {
    shareStore.setDiagram(this.props.diagram);
  }

  componentWillUnmount() {
    shareStore.reset();
  }

  focusShareLinkInput = (event) => {
    event.currentTarget.select();
  };

  handleCopyClick = () => {
    this.linkInput.select();
    document.execCommand('copy');

    notificationStore.showNotification({
      message: LINK_COPIED_MESSAGE
    });
  };

  handleCopyEmbedClick = () => {
    this.hiddenEmbedCodeNode.select();
    document.execCommand('copy');

    notificationStore.showNotification({
      message: SNIPPET_COPIED_MESSAGE
    });
  };

  setLinkInput = (node) => {
    this.linkInput = node;
  };

  setHiddenEmbedCodeNode = (node) => {
    this.hiddenEmbedCodeNode = node;
  };

  render() {
    return (
      <Fragment>
        <Dialog open={shareStore.isMainDialogOpen} onClose={this.props.onClose}>
          {/* When the diagram has no share link created yet */}
          {!shareStore.state.diagram.shareId && (
            <Fragment>
              <Dialog.Header>
                <Dialog.Title data-test="share-title">Create share link</Dialog.Title>
              </Dialog.Header>
              <Dialog.Content>
                <Styled.Centered>
                  <Typography $gutterBottom>
                    Let anyone view this diagram anywhere.
                    <br />
                    Shared links are designed for sharing externally via link, email, or embed.
                  </Typography>

                  <Styled.InfoMessage
                    kind="info"
                    title="Password-protect your sensitive information"
                    subtitle="By default, shared links are accessible in the public domain (internet)"
                    lowContrast
                    hideCloseButton
                  />

                  <Button variant="primary" onClick={shareStore.createLink} data-test="create-share-cta">
                    Create link
                  </Button>
                </Styled.Centered>
              </Dialog.Content>
            </Fragment>
          )}

          {/* When a share link was created for this diagram */}
          {shareStore.state.diagram.shareId && (
            <Fragment>
              {!shareStore.state.isShareByEmailControlVisible && (
                <Fragment>
                  <Dialog.Header>
                    <Dialog.Title data-test="share-title">Share diagram</Dialog.Title>
                  </Dialog.Header>
                  <Dialog.Content data-test="share-content">
                    <Typography $gutterBottom>
                      Share via link, email, or embed. We recommend ensuring your link is password-protected for added
                      security by clicking <strong>Add</strong> next to Password protect below.
                    </Typography>

                    <Styled.EmailGrid>
                      <Styled.ColumnLeft>
                        <Input
                          label="Link URL"
                          name="link"
                          value={`${config.modelerUrl}/share/${shareStore.state.diagram.shareId}`}
                          readOnly
                          data-test="share-link-container"
                          ref={this.setLinkInput}
                          onClick={this.focusShareLinkInput}
                        />
                      </Styled.ColumnLeft>

                      <Styled.HiddenTextarea
                        ref={this.setHiddenEmbedCodeNode}
                        aria-hidden="true"
                        defaultValue={generateEmbedCode(shareStore.state.diagram.shareId)}
                        data-test="embed-textarea"
                      />

                      <Button variant="primary" onClick={this.handleCopyClick} data-test="copy-button">
                        Copy
                      </Button>
                    </Styled.EmailGrid>

                    <Styled.ButtonGrid>
                      <Styled.ColumnLeft>
                        <Button
                          variant="danger-primary"
                          data-test="destroy-share-button"
                          onClick={shareStore.toggleDestroyLinkControl(true)}
                        >
                          Destroy link
                        </Button>
                      </Styled.ColumnLeft>
                      <Button variant="secondary" onClick={this.handleCopyEmbedClick} data-test="embed-button">
                        Embed
                      </Button>
                      <Button
                        variant="secondary"
                        data-test="share-via-email"
                        onClick={shareStore.toggleShareByEmailControl(true)}
                      >
                        Email
                      </Button>
                    </Styled.ButtonGrid>
                  </Dialog.Content>

                  <Styled.DialogActions>
                    <PasswordToggle
                      diagram={shareStore.state.diagram}
                      toggleRemovePasswordControl={shareStore.toggleRemovePasswordControl}
                      togglePasswordControl={shareStore.togglePasswordControl}
                    />
                  </Styled.DialogActions>
                </Fragment>
              )}

              {/* When sharing via email */}
              {shareStore.state.isShareByEmailControlVisible && (
                <Fragment>
                  <Dialog.Header>
                    <Dialog.Title data-test="share-title">Share via email</Dialog.Title>
                  </Dialog.Header>
                  <Dialog.Content data-test="share-content">
                    <MultiSelectInput
                      placeholder={`Write or paste up to ${MAX_INPUT_EMAILS} email addresses and press Enter or Tab`}
                      dataSource={shareStore.state.emails}
                      onValidate={shareStore.validate}
                      maxLength={MAX_INPUT_EMAILS}
                      isUsedInShare
                    />
                    {shareStore.state.isShowErrorVisible && (
                      <Styled.WarningContainer>
                        <Styled.ImageContainer>
                          <Danger width="20" height="20" />
                        </Styled.ImageContainer>
                        <Styled.WarningText>{shareStore.state.errorMessage}</Styled.WarningText>
                      </Styled.WarningContainer>
                    )}
                    <Styled.EmailTextInput
                      name="message"
                      placeholder="Add message"
                      rows={4}
                      multiline
                      onChange={shareStore.handleEmailMessageChange}
                    />
                  </Dialog.Content>
                  <Dialog.Footer>
                    <Button variant="secondary" onClick={shareStore.toggleShareByEmailControl(false)}>
                      Back
                    </Button>
                    <Button
                      disabled={!shareStore.hasEmails}
                      variant="primary"
                      onClick={shareStore.handleShareEmailSubmit}
                      type="submit"
                      data-test="send-share-email"
                    >
                      Send
                    </Button>
                  </Dialog.Footer>
                </Fragment>
              )}
            </Fragment>
          )}

          {shareStore.state.isLoading && <Spinner />}
          {shareStore.state.error && <Alert type="danger">{shareStore.state.error}</Alert>}
        </Dialog>

        {/* SubDialog for setting a password */}
        <Dialog open={shareStore.state.isPasswordControlVisible} onClose={shareStore.togglePasswordControl(false)}>
          <PasswordControl
            diagram={shareStore.state.diagram}
            onCreatePassword={partial(notificationStore.showNotification, {
              message: 'Password has been saved.'
            })}
            onClose={shareStore.togglePasswordControl(false)}
          />
        </Dialog>

        {/* SubDialog for destroying the link */}
        <Dialog
          open={shareStore.state.isDestroyLinkControlVisible}
          onClose={shareStore.toggleDestroyLinkControl(false)}
        >
          <DestroyLinkControl
            diagram={shareStore.state.diagram}
            onCancel={shareStore.toggleDestroyLinkControl(false)}
            closeParent={this.props.onClose}
          />
        </Dialog>

        {/* SubDialog for removal of password */}
        <Dialog
          open={shareStore.state.isRemovePasswordControlVisible}
          onClose={shareStore.toggleRemovePasswordControl(false)}
        >
          <RemovePasswordControl onCancel={shareStore.toggleRemovePasswordControl(false)} />
        </Dialog>
      </Fragment>
    );
  }
}

const generateEmbedCode = (shareId) =>
  `<iframe src="${config.modelerUrl}/embed/${shareId}" width="700" height="500" style="border:1px solid #ccc" allowfullscreen></iframe>`;

export default observer(ShareView);
