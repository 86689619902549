/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { Sidebar } from 'primitives';
import { MilestonesDiffingToggle, MilestonesSection } from 'components';
import { milestoneStore } from 'stores';

import * as Styled from './Sidebar.styled';
import BPMNChangeList from './BPMNChangeList/BPMNChangeList';

function MilestoneSidebar() {
  const isDMNCodeView = milestoneStore.state.isCodeView && milestoneStore.isDMN;
  const isBPMNVisualView = !milestoneStore.state.isCodeView && milestoneStore.isBPMN;

  return (
    <Sidebar $isVisible data-test="milestones-sidebar">
      <Sidebar.Inner>
        <Styled.SidebarSection $isHalfHeight={isBPMNVisualView}>
          <Sidebar.Title>Milestones</Sidebar.Title>
          <MilestonesSection />
        </Styled.SidebarSection>

        <BPMNChangeList isBPMNVisualView={isBPMNVisualView} />
      </Sidebar.Inner>

      {(milestoneStore.isBPMN || isDMNCodeView) && <MilestonesDiffingToggle />}
    </Sidebar>
  );
}

export default observer(MilestoneSidebar);
