/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { CLUSTER_STATUS } from './constants';

export default function getClusterStatus(clusterStatus, components = ['zeebe']) {
  if (!clusterStatus) {
    return 'Unknown';
  }

  if (components.every((component) => clusterStatus[component] === CLUSTER_STATUS.HEALTHY)) {
    return CLUSTER_STATUS.HEALTHY;
  }

  const filteredStatuses = Object.values(CLUSTER_STATUS).filter((status) => status !== CLUSTER_STATUS.HEALTHY);

  for (const status of filteredStatuses) {
    if (components.some((component) => clusterStatus[component] === status)) {
      return status;
    }
  }
}
