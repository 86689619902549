/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Modal } from '@carbon/react';

export const IdpProjectModal = styled(Modal)`
  .cds--modal-container {
    max-width: 740px;
  }

  .cds--modal-content {
    /*
     * Needed for dropdowns inside the modal to render properly
     * See https://github.com/carbon-design-system/carbon/issues/4684#issuecomment-554090767
     */
    overflow: visible;
  }

  .cds--list-box__menu-item {
    /*
     * Needed to increase the height of each line item
     */
    block-size: 4rem;
  }

  .cds--list-box__menu-item__option {
    /*
     * Needed to span the line item to 100% height instead of default value
     */
    height: 100%;
  }
`;

export const IdpProjectModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const IdpProjectTypeSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;

  > * {
    flex: 1; // Makes each RadioTile take up an equal portion of available space
  }
`;
