/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { MenuButton, MenuItem } from '@carbon/react';
import { useState } from 'react';

import ApproveModal from 'App/Pages/ProcessApplicationVersions/features/review/perform-review/ApproveModal';
import RequestChangesModal from 'App/Pages/ProcessApplicationVersions/features/review/perform-review/RequestChangesModal';

function PerformReview({ versionId, isAdminSelfReview }) {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRequestChangesModalOpen, setIsRequestChangesModalOpen] = useState(false);

  return (
    <>
      <MenuButton size="sm" label="Review" menuAlignment="bottom-start">
        <MenuItem
          label="Approve"
          onClick={(e) => {
            e.preventDefault();
            setIsApproveModalOpen(true);
          }}
        />
        <MenuItem
          label="Request changes"
          onClick={(e) => {
            e.preventDefault();
            setIsRequestChangesModalOpen(true);
          }}
        />
      </MenuButton>
      <ApproveModal
        open={isApproveModalOpen}
        closeModal={() => setIsApproveModalOpen(false)}
        versionId={versionId}
        isAdminSelfReview={isAdminSelfReview}
      />
      <RequestChangesModal
        open={isRequestChangesModalOpen}
        closeModal={() => setIsRequestChangesModalOpen(false)}
        versionId={versionId}
        isAdminSelfReview={isAdminSelfReview}
      />
    </>
  );
}
export default PerformReview;
