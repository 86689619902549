/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { html } from 'htm/preact';
import { useEffect, useRef } from '@bpmn-io/properties-panel/preact/hooks';
import { useService } from 'bpmn-js-properties-panel';
import { createRoot } from 'react-dom/client';

import { trackingService } from 'services';

import PublicationGroup from './PublicationGroupComponent';

export default function () {
  const renderRef = useRef();
  const eventBus = useService('eventBus');

  const trackInteraction = (event) => {
    const isExpanded = event.layout.groups['process-publication']?.open;
    if (isExpanded) {
      trackingService.trackPublicationGroupExpand();
    }
  };

  useEffect(() => {
    const root = createRoot(renderRef.current);
    root.render(<PublicationPropertiesComponent />);
  }, [eventBus]);

  useEffect(() => {
    eventBus.on('propertiesPanel.layoutChanged', trackInteraction);

    return () => {
      eventBus.off('propertiesPanel.layoutChanged', trackInteraction);
    };
  }, [eventBus]);

  return html`<div ref=${renderRef}></div>`;
}

function PublicationPropertiesComponent() {
  return <PublicationGroup />;
}
