/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';

import { isContainedInProcessApplication } from 'utils/helpers';
import { milestoneStore, diffingStore, notificationStore, processApplicationStore } from 'stores';
import { projectService, fileService, trackingService, folderService, tracingService } from 'services';
import { MarkdownEditor } from 'features/markdown-file-handling';
import { Spinner } from 'primitives';
import hasAccess, { actions } from 'utils/user-access';

import Sidebar from './Sidebar';
import Header from './Header';
import * as Styled from './ReadmeVersions.styled';

export const ReadmeVersions = observer(function ReadmeVersions() {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    trackingService.trackPageView('readme-versions');
    init();

    return () => {
      milestoneStore.reset();
      diffingStore.reset();
    };
    // @ts-expect-error TS2339
  }, [params.id]);

  const init = async () => {
    setLoading(true);

    try {
      // @ts-expect-error TS2339
      const file = await fileService.fetchById(params.id);

      const requests = [projectService.fetchById({ projectId: file.projectId })];
      if (isContainedInProcessApplication(file)) {
        const skipParentInitialization = true;
        requests.push(processApplicationStore.init(file.folderId, skipParentInitialization));
      } else if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }

      const [project, folder] = await Promise.all(requests);

      if (!hasAccess(project, actions.VIEW_VERSIONS)) {
        // @ts-expect-error TS2339
        return history.push(`/readmes/${params.id}`);
      }

      // @ts-expect-error TS2339
      const versionIdsFromUrl = params.slug?.split('...');

      // @ts-expect-error TS2339
      milestoneStore.init({ ...file, folder }, project, [], versionIdsFromUrl);
      await milestoneStore.fetchAll();

      milestoneStore.trackMilestoneView('milestone-history');
    } catch (error) {
      notificationStore.showError('Could not load readme versions. Please try again later.');
      tracingService.traceError(error, 'Failed to initialize readme versions');
      history.push('/');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Styled.Container>
      {loading ? (
        <Spinner fullHeight />
      ) : (
        <Fragment>
          <Header />
          <Styled.LeftSection>
            <MarkdownEditor
              titles={milestoneStore.titles}
              content={milestoneStore.primaryContent}
              modifiedContent={milestoneStore.secondaryContent}
              viewMode={'editor'}
              readOnly
            />
          </Styled.LeftSection>
          <Sidebar />
        </Fragment>
      )}
    </Styled.Container>
  );
});
