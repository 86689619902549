/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { notificationStore } from 'components/NotificationSystem';

import { renameService } from './RenameService';

/**
 * Renames a process application version.
 *
 * @param {string} versionId - The ID of the version to rename.
 * @param {string} versionName - The current name of the version.
 * @param {string} newName - The new name for the version.
 * @param {Function} reloadVersions - A callback function to reload the versions.
 * @param {Function} setIsEditing - A callback function to set the editing state.
 * @returns {Promise<void>}
 */
async function renameProcessApplicationVersion(versionId, versionName, newName, reloadVersions, setIsEditing) {
  try {
    if (newName.trim().length === 0 || newName.trim() === versionName) {
      setIsEditing(false);
      return;
    }
    await renameService.renameProcessApplicationVersion(versionId, newName);
    setIsEditing(false);
    reloadVersions();
  } catch {
    notificationStore.showError('Failed to rename the process application version.');
  }
}

export { renameProcessApplicationVersion };
