/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default function isValidIdpApplication(json) {
  try {
    const parsed = typeof json === 'object' ? json : JSON.parse(json);

    return (
      !!parsed.name &&
      typeof parsed.name === 'string' &&
      !!parsed.clusterId &&
      typeof parsed.clusterId === 'string' &&
      !!parsed.idpProjects &&
      Array.isArray(parsed.idpProjects)
    );
  } catch {
    return false;
  }
}
