/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import { Info, Danger, Success, Warning } from 'icons';
import { Typography } from 'primitives';

import * as Styled from './Alert.styled';

const icons = {
  warning: Warning,
  danger: Danger,
  success: Success,
  info: Info
};

export default function Alert({ type = 'success', children, ...props }) {
  const Icon = icons[type];

  return (
    <Styled.Wrapper type={type} role="alert" {...props}>
      <Icon width="18" height="18" />
      <Typography variant="caption">{children}</Typography>
    </Styled.Wrapper>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'danger', 'warning', 'success'])
};
