/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useState } from 'react';

import { IconButton } from 'primitives';
import { Dots } from 'icons';
import { Dropdown } from 'components';
import EditExtractionFieldModal from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/unstructured-extraction/idp-extraction-fields/extraction-fields/edit-extraction-field-modal/EditExtractionFieldModal';
import { idpProjectStore } from 'stores';

import * as Styled from './ExtractionFieldAction.styled';

const ExtractionFieldAction = ({ entity, onUpdateExtractionField }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditExtractionFieldModalVisible, setIsEditExtractionFieldModalVisible] = useState(false);

  const { idpExtractionFields } = idpProjectStore;

  function handleEdit() {
    setIsEditExtractionFieldModalVisible(true);
    setAnchorEl(null);
  }

  function getExtractionFieldById() {
    return idpExtractionFields.find((field) => field.id === entity.idpExtractionFieldId);
  }

  return (
    <Styled.ActionContainer>
      <Fragment>
        <IconButton
          // @ts-expect-error TS2769
          $isActive={Boolean(anchorEl)}
          aria-label="Entity Context Action"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="entity-context-dropdown"
          $noRadius
        >
          <Dots />
        </IconButton>
        <Dropdown
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem key="duplicate-diagram" onClick={() => handleEdit()} data-test="duplicate-diagram">
            Edit
          </Dropdown.ListItem>
        </Dropdown>
      </Fragment>

      <EditExtractionFieldModal
        isOpen={isEditExtractionFieldModalVisible}
        editingExtractionField={getExtractionFieldById()}
        onSaveChanges={onUpdateExtractionField}
        onCloseModal={() => setIsEditExtractionFieldModalVisible(false)}
      />
    </Styled.ActionContainer>
  );
};

export default ExtractionFieldAction;
