/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const SubscribeLink = styled.a`
  text-decoration: none;
`;

export const ExpiredTrialHeader = styled.h2`
  margin-top: 0;
  font-size: 15px;
`;

export const ExpiredTrialText = styled.p`
  margin-top: 5px;
  font-size: 14px;
`;

export const Loader = styled.div`
  text-align: center;
`;

export const LoaderDescription = styled.div`
  margin-top: 20px;
`;
