/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

/**
 * Custom hook that selects the draft version if the currently selected version is deleted.
 *
 * @param {Array} versions - Array of PA version objects.
 * @param {string} selectedVersionId - The ID of the currently selected PA version.
 * @param {Function} setSelectedVersionId - Function to set the selected PA version ID.
 * @param {Object} DRAFT_VERSION - The default draft version object.
 */
const useSelectDraftIfSelectedVersionIsDeleted = (versions, selectedVersionId, setSelectedVersionId, DRAFT_VERSION) => {
  // Check if the selected version is still available (not deleted)
  useEffect(() => {
    if (!versions.some((version) => version.id === selectedVersionId)) {
      setSelectedVersionId(DRAFT_VERSION.id);
    }
  }, [versions, selectedVersionId]);
};

export { useSelectDraftIfSelectedVersionIsDeleted };
