/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, Input, Typography } from 'primitives';
import { milestoneStore, notificationStore } from 'stores';
import { milestoneService } from 'services';
import { isTemplate } from 'utils/helpers';

const CONNECTOR_TEMPLATE_TYPE = 'connector_template';
const DIAGRAM_TYPE = 'diagram';

export const LABELS = {
  diagram: {
    title: () => 'Create a new milestone',
    description: () => 'After you create this milestone, it will be available in your history.',
    input: 'Please name this milestone (optional):',
    default: 'Untitled milestone',
    button: 'Create'
  },
  connector_template: {
    title: (targetIsOrganization) => `Publish version to ${targetIsOrganization ? 'organization' : 'the project'}`,
    description: (targetIsOrganization, templateName) =>
      `Publish "${templateName}"
      ${
        targetIsOrganization
          ? 'to the organization to allow members'
          : 'to enable it in your project and allow collaborators'
      }
      to use it in their diagrams.`,
    input: 'Version name (optional):',
    default: 'Untitled version',
    button: 'Publish'
  }
};

export default function VersionCreationDialog({ onClose, open, file, origin, targetIsOrganization }) {
  const [value, setValue] = useState('');
  const [version, setVersion] = useState(1);
  const type = isTemplate(file) ? CONNECTOR_TEMPLATE_TYPE : DIAGRAM_TYPE;
  const defaultName = `${LABELS[type].default} ${version}`;

  useEffect(() => {
    if (open) {
      milestoneService
        .fetchByFileId(file.id)
        .then((milestones) => {
          setVersion(milestones.length + 1);
        })
        .catch(() => notificationStore.showError('Could not fetch your versions. Please try again later.'));
    }
  }, [open]);

  const handleClose = () => {
    setValue('');
    onClose(false);
  };

  const handleConfirm = async () => {
    const payload = {
      file: file,
      name: value || defaultName,
      append: origin === 'latest-version',
      origin: origin,
      organizationPublic: targetIsOrganization
    };

    const result =
      type === CONNECTOR_TEMPLATE_TYPE ? milestoneStore.createWithRolesCheck(payload) : milestoneStore.create(payload);

    result
      .then(() => {
        notificationStore.showSuccess(
          isTemplate(file) ? `"${file.name}" has been published.` : 'New milestone added to history.'
        );

        setValue('');
        onClose();
      })
      .catch(() => {
        notificationStore.showError(
          isTemplate(file)
            ? `Yikes! Could not publish "${file.name}". Please try again later.`
            : 'Yikes! Could not create a new milestone. Please try again later.'
        );
      });
  };

  return (
    <Dialog open={open} onConfirm={handleConfirm} onClose={handleClose}>
      <Dialog.Header>
        <Dialog.Title data-test="dialog-title">{LABELS[type].title(targetIsOrganization)}</Dialog.Title>
      </Dialog.Header>

      <Dialog.Content>
        <Typography $gutterBottom>{LABELS[type].description(targetIsOrganization, file.name)}</Typography>

        <Input
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          placeholder={defaultName}
          label={LABELS[type].input}
          data-test={isTemplate(file) ? 'version-name' : 'milestone-name'}
        />
      </Dialog.Content>

      <Dialog.Footer>
        <Button data-test="cancel" variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button data-test="confirm" onClick={handleConfirm}>
          {LABELS[type].button}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

VersionCreationDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  origin: PropTypes.string
};
