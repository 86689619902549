/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { currentDiagramStore } from 'stores';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';

import { SaveConnectorConfig } from './SaveConnectorConfig';
import * as Styled from './Properties.styled';

const Properties = ({ isReadOnly = false }) => {
  const propertiesRef = useRef();

  const { modeler } = currentDiagramStore;
  const { isLoadingModeler } = currentDiagramStore.state;

  useMakePropertiesPanelInputsReadOnly(isReadOnly);

  const attachPropertiesPanel = () => {
    try {
      const propertiesPanel = modeler.get('propertiesPanel');
      propertiesRef.current.innerHTML = '';
      propertiesPanel.attachTo(propertiesRef.current);
    } catch {
      // Could happen if the modeler does not have a properties panel.
      // This is the case when opening a dmn decision table from a linking bpmn diagram
      // it first loads the DRD and tries to attach the properties panel here. Meanwhile
      // the decision table is opened, which does not have a properties panel and this
      // function throws an exception.
    }
  };

  useEffect(() => {
    if (modeler && !isLoadingModeler && propertiesRef.current) {
      attachPropertiesPanel();

      /**
       * For DMN diagrams, if properties panel is dynamically attached,
       * it has to re-attached after every import
       **/
      if (!currentDiagramStore.isBPMN) {
        modeler.on('import.done', () => {
          attachPropertiesPanel();
        });
      }
    }
  }, [isLoadingModeler, modeler]);

  const [hasSaveAs, setHasSaveAs] = useState(false);

  return (
    <>
      {dedicatedModesStore.isImplementMode && (
        <SaveConnectorConfig
          onActivate={(isActive) => {
            setHasSaveAs(isActive);
          }}
        />
      )}
      <Styled.Properties
        id="properties-panel"
        ref={propertiesRef}
        data-test="properties-panel"
        className={hasSaveAs ? 'with-save-as' : ''}
        $readOnly={isReadOnly}
        $isDocumentationOnly={dedicatedModesStore.isDesignMode}
      />
    </>
  );
};

export default observer(Properties);

const useMakePropertiesPanelInputsReadOnly = (isReadOnly) => {
  useEffect(() => {
    const addReadonlyAttribute = (element) => {
      element.setAttribute('readonly', 'true');
    };

    const setContentEditableAttributeToFalse = (element) => {
      element.setAttribute('contenteditable', 'false');
    };

    const makeInputElementsWithinAddedNodesReadOnly = (mutation) => {
      mutation.addedNodes.forEach((addedNode) => {
        try {
          const matchingElements = addedNode.querySelectorAll('.bio-properties-panel-input');
          matchingElements.forEach(addReadonlyAttribute);

          const matchingDivElements = addedNode.querySelectorAll('.cm-content');
          matchingDivElements.forEach(setContentEditableAttributeToFalse);
        } catch {
          // fail silently
        }
      });
    };

    const makePropertiesPanelInputsReadOnly = (targetNode) => {
      const isAlreadyObserving = targetNode._observer;
      if (!isAlreadyObserving) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
              makeInputElementsWithinAddedNodesReadOnly(mutation);
            }
          });
        });

        observer.observe(targetNode, { childList: true, subtree: true });

        targetNode._observer = observer;
      }
    };

    const targetNode = document.getElementById('properties-panel');
    if (isReadOnly && targetNode) {
      makePropertiesPanelInputsReadOnly(targetNode);
    }

    return () => {
      if (targetNode?._observer) {
        targetNode._observer.disconnect();
      }
    };
  }, []);
};
