/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Modal, Stack, TextArea } from '@carbon/react';
import { useRef } from 'react';
import { createPortal } from 'react-dom';

import reviewStore from 'App/Pages/ProcessApplicationVersions/features/review/ReviewStore';
import Description from 'App/Pages/ProcessApplicationVersions/features/review/perform-review/Description';
import { SelfReviewWarning } from 'App/Pages/ProcessApplicationVersions/features/review/perform-review/SelfReviewWarning';

export default function RequestChangesModal({ open, closeModal, versionId, isAdminSelfReview }) {
  const commentRef = useRef(null);

  return createPortal(
    <Modal
      open={open}
      onRequestClose={closeModal}
      modalHeading="Request changes"
      preventCloseOnClickOutside
      primaryButtonText="Request changes"
      secondaryButtonText="Cancel"
      onRequestSubmit={async () => {
        const comment = commentRef?.current?.value;
        await reviewStore.requestChanges(versionId, comment);
        closeModal();
      }}
    >
      <Stack gap={2}>
        {isAdminSelfReview && <SelfReviewWarning />}
        <Description>You are going to request changes for an entire process application.</Description>
        <TextArea
          data-modal-primary-focus
          labelText="Comment (optional)"
          placeholder="Request your changes here..."
          ref={commentRef}
        />
      </Stack>
    </Modal>,
    document.body
  );
}
