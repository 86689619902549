/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled, { keyframes } from 'styled-components';
import { Button } from '@carbon/react';

import { Saving } from 'icons';

export const ActionBar = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-small) 7px;
  color: var(--grey-lighten-50);
  border-bottom: 1px solid var(--silver-darken-87);
  position: relative;
  background-color: var(--silver-base-97);
  z-index: var(--z-action-bar-header);

  button {
    margin-left: 12px;

    &.publish-button,
    &.publish-to-organization-button {
      margin-right: var(--spacing-small);
    }

    &.publish-to-organization-button {
      gap: var(--spacing-small);
    }
  }
`;

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

export const Spinner = styled(Saving)`
  animation: ${spinning} 1.5s linear infinite;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Status = styled.p`
  margin: 0 auto 0 var(--spacing-small);
  display: flex;
  color: ${({ $isDanger }) => ($isDanger ? 'var(--red-darken-52)' : 'var(--grey-darken-33)')};
  font-size: unset;
  line-height: unset;

  > svg {
    margin-right: 7px;
  }
`;

export const PublishOption = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const IconUploadButton = styled(Button)`
  line-height: 1.6em;

  > svg {
    margin-top: 0.2em;
    margin-block-start: 0.2em !important;
  }
`;
