/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { NameRenderer, NameWithoutLinkRenderer } from './index';

function NameLinkOptionalRenderer({ name, icon, linkProps, tooltipProps }) {
  return linkProps
    ? NameRenderer({ name, icon, ...linkProps })
    : NameWithoutLinkRenderer({ name, icon, ...tooltipProps });
}

NameLinkOptionalRenderer.containsSearchWord = NameRenderer.containsSearchWord;

NameLinkOptionalRenderer.getComparableValue = NameRenderer.getComparableValue;

NameLinkOptionalRenderer.getDataTest = NameRenderer.getDataTest;

export default NameLinkOptionalRenderer;
