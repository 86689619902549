/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isDMN } from 'utils/helpers';
import { folderService, projectService, trackingService } from 'services';
import { notificationStore, organizationStore } from 'stores';

export default function useProjectData(state, dispatch) {
  const handleFailure = () => {
    notificationStore.showError('Yikes! Something went wrong, please try again.');
  };

  const createChild = (name) => {
    if (state.level === 'root') {
      // Create a new project.
      projectService
        .create({
          name,
          organizationId: organizationStore.currentOrganizationId
        })
        .then((project) => {
          trackingService.trackCreateProject(state.action);

          dispatch({ type: 'ADD_ITEM', payload: project });
          dispatch({ type: 'TOGGLE_SELECT_ITEM', payload: project });
        })
        .catch(handleFailure);
    } else {
      // Create a new folder.
      folderService
        .create({
          name,
          projectId: state.current.projectId || state.current.id,
          parentId: state.current.projectId && state.current.id
        })
        .then((folder) => {
          trackingService.trackCreateFolder({ from: state.action, parentType: state.level, folderType: 'folder' });

          dispatch({ type: 'ADD_ITEM', payload: folder });
          dispatch({ type: 'TOGGLE_SELECT_ITEM', payload: folder });
        })
        .catch(handleFailure);
    }
  };

  const fetchChild = (item, isProject) => {
    if (isDMN(item)) {
      dispatch({ type: 'OPEN_FILE', payload: item });
    } else if (isProject) {
      // Get the project's content.
      projectService
        .fetchById({
          projectId: item.id,
          includeFiles: state.shouldIncludeFiles,
          includeFolders: state.shouldIncludeFolders
        })
        .then((project) => {
          dispatch({ type: 'OPEN_PROJECT', payload: project });
        })
        .catch(handleFailure);
    } else {
      // Get the folder's content.
      folderService
        .fetchById(item.id)
        .then((folder) => {
          dispatch({ type: 'OPEN_FOLDER', payload: folder });
        })
        .catch(handleFailure);
    }
  };

  const fetchParent = () => {
    if (state.current.parentId) {
      // Fetch a folder.
      folderService
        .fetchById(state.current.parentId)
        .then((folder) => {
          dispatch({ type: 'OPEN_FOLDER', payload: folder });
        })
        .catch(handleFailure);
    } else if (state.current.projectId) {
      // Fetch a project.
      projectService
        .fetchById({
          projectId: state.current.projectId,
          includeFiles: state.shouldIncludeFiles,
          includeFolders: state.shouldIncludeFolders
        })
        .then((project) => {
          dispatch({ type: 'OPEN_PROJECT', payload: project });
        })
        .catch(handleFailure);
    } else {
      // Fetch all organization projects.
      projectService
        .fetchByOrganizationId(organizationStore.currentOrganizationId)
        .then((projects) => {
          dispatch({ type: 'OPEN_ORGANIZATION_ROOT', payload: projects });
        })
        .catch(handleFailure);
    }
  };

  const fetchRelatedBPMNDiagrams = (diagram) => {
    return projectService
      .fetchFilesByProcessId(diagram.projectId, diagram.processId)
      .then((files) => files.filter((file) => file.id !== diagram.id))
      .catch(handleFailure);
  };

  const fetchRelatedDMNDiagrams = (diagramId, projectId, decisionId) => {
    return projectService
      .fetchFilesByDecisionId(projectId, decisionId)
      .then((files) => files.filter((file) => file.id !== diagramId))
      .catch(handleFailure);
  };

  const fetchRelatedForms = (formId, projectId, formFileId) => {
    return projectService
      .fetchFilesByFormId(projectId, formId)
      .then((files) => files.filter((file) => file.id !== formFileId))
      .catch(handleFailure);
  };

  return {
    fetchChild,
    fetchParent,
    createChild,
    fetchRelatedBPMNDiagrams,
    fetchRelatedDMNDiagrams,
    fetchRelatedForms
  };
}
