/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import moment from 'moment';

/**
 * Formats a given date into a human-readable string, like 'Today'
 * or '3 September 2020'.
 *
 * @param {Date} date
 * @returns {String}
 */
export function formatDateToString(date) {
  if (moment().subtract(1, 'days').isSame(date, 'day')) {
    return 'Yesterday';
  } else if (moment().isSame(date, 'day')) {
    return 'Today';
  } else {
    return moment(date).format('D MMMM YYYY');
  }
}

/**
 * Returns a human readable timestamp
 *
 * @param {Date} date
 * @returns {String}
 */
export function getReadableTimestamp(date = new Date()) {
  return moment(date).format('YYYYMMDDTHHmmss');
}
