/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Wrapper = styled.span`
  svg {
    ${({ $iconColor }) => $iconColor && `fill: ${$iconColor} !important;`}
    vertical-align: middle;
  }
`;

export const Label = styled.span`
  margin-right: 3px;
  vertical-align: middle;
`;
