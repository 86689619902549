/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getFocusable } from './useFocusTrap';

export default function useKeyboardTrap({ onClose, onSubmit, ref }) {
  return (event) => {
    if (event.key === 'Escape' && onClose) {
      return onClose(event);
    }

    if (event.key === 'Enter' && onSubmit) {
      return onSubmit(event);
    }

    const focusable = getFocusable(ref);

    if (event.key == 'Tab' && focusable.length > 0) {
      const index = focusable.indexOf(document.activeElement);

      if (index == focusable.length - 1) {
        event.preventDefault();

        focusable[0].focus();
      }
    }
  };
}
