/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  processApplicationStore,
  breadcrumbStore,
  currentDiagramStore,
  notificationStore,
  milestoneStore
} from 'stores';
import { getParentLink } from 'utils/helpers';
import {
  Breadcrumb,
  Dropdown,
  FileUpload,
  TopBar,
  VersionPublishDialog,
  ProcessApplicationVersionTagModal
} from 'components';
import { fileService } from 'services';
import history from 'utils/history';
import buildSlug from 'utils/buildSlug';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';

const Header = ({ permission }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { diagram, project } = currentDiagramStore.state;
  const { isEditorOpen: isXMLEditorOpen } = XMLEditorStore;
  const { fromAProcessApplication, mainProcessId } = processApplicationStore;

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleKeyPress = (evt) => {
    if (evt.shiftKey && evt.key == 'M' && evt.target.tagName === 'BODY') {
      if (project.permissionAccess === 'WRITE' || project.permissionAccess === 'ADMIN') {
        setIsDialogOpen(true);
      }
    }
  };

  const handleDuplication = async () => {
    const { diagram } = currentDiagramStore.state;

    fileService
      .duplicate([diagram.id])
      .then((files) => {
        history.push(`/diagrams/${buildSlug(files[0].id, files[0].name)}`);

        notificationStore.showSuccess('Your diagram has been duplicated. You are currently in the duplicated version.');

        breadcrumbStore.toggleDropdownVisibility();
      })
      .catch(() => notificationStore.showError('Could not duplicate the diagram.'));
  };

  const handleDelete = async () => {
    currentDiagramStore.deleteCurrentDiagram();
  };

  const handleEditName = () => {
    breadcrumbStore.toggleDropdownVisibility();
    breadcrumbStore.toggleEditingFor('diagram');
  };

  const handleEditNameSubmit = (name) => {
    breadcrumbStore.finishEditing();

    if (name && name.trim().length > 0) {
      currentDiagramStore.renameDiagram(name);
    }
  };

  const isProcessApplicationMainProcess = mainProcessId && diagram.processId && mainProcessId === diagram.processId;

  return (
    <TopBar.Breadcrumbs>
      <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
      <Breadcrumb title={project.name} variant="link" data-test="breadcrumb-project" to={`/projects/${project.id}`} />
      {Boolean(diagram.folder) && (
        <Fragment>
          {diagram.folder.parentId && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
          <Breadcrumb
            title={diagram.folder.name}
            variant="link"
            to={getParentLink(diagram)}
            data-test="parent-folder"
          />
        </Fragment>
      )}
      {permission.is(['WRITE', 'ADMIN']) && !isXMLEditorOpen ? (
        <Breadcrumb
          title={diagram.name}
          variant="dropdown"
          data-test="breadcrumb-diagram"
          isBold
          forPage="diagram"
          handleSubmit={handleEditNameSubmit}
        >
          <Fragment>
            <Dropdown.ListItem
              key="create-milestone"
              onClick={() => {
                breadcrumbStore.toggleDropdownVisibility();
                setIsDialogOpen(true);
              }}
              data-test="diagram-menu-item-create-milestone"
            >
              Create milestone
            </Dropdown.ListItem>
            <Dropdown.ListItem
              key="show-milestone-history"
              to={`/diagrams/${diagram.id}/milestones`}
              onClick={breadcrumbStore.toggleDropdownVisibility}
              data-test="diagram-menu-item-show-history"
            >
              Show milestone history
            </Dropdown.ListItem>

            <hr />

            <Dropdown.ListItem key="edit-name" onClick={handleEditName} data-test="diagram-menu-item-rename-diagram">
              Edit name
            </Dropdown.ListItem>
            <Dropdown.ListItem key="replace-via-upload" data-test="diagram-menu-item-upload-diagram" noPadding>
              <FileUpload
                onFiles={currentDiagramStore.uploadFiles}
                label="Replace via upload"
                accept={`.xml, .${diagram.type.toLowerCase()}`}
              />
            </Dropdown.ListItem>
            <Dropdown.ListItem key="duplicate-diagram" onClick={handleDuplication} data-test="diagram-menu-duplicate">
              Duplicate
            </Dropdown.ListItem>
          </Fragment>

          {!isProcessApplicationMainProcess && (
            <Dropdown.ListItem key="delete-diagram" onClick={handleDelete} data-test="diagram-menu-delete">
              Delete
            </Dropdown.ListItem>
          )}
        </Breadcrumb>
      ) : isXMLEditorOpen ? (
        <Breadcrumb data-test="breadcrumb-diagram" title={diagram.name} variant="link" to={`/diagrams/${diagram.id}`} />
      ) : (
        <Breadcrumb data-test="breadcrumb-diagram" title={diagram.name} variant="text" isBold />
      )}

      {isXMLEditorOpen && <Breadcrumb data-test="breadcrumb-xml-editor" title="XML Editor" variant="text" isBold />}

      {fromAProcessApplication ? (
        <ProcessApplicationVersionTagModal
          open={isDialogOpen}
          onRequestClose={() => setIsDialogOpen(false)}
          numberOfResources={processApplicationStore.processApplication.files?.length}
          handleVersionCreation={(name) =>
            milestoneStore.createForProcessApplication({
              processApplicationId: processApplicationStore.processApplication.id,
              fileId: diagram.id,
              append: false,
              origin: 'breadcrumb',
              name
            })
          }
        />
      ) : (
        <VersionPublishDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          file={diagram}
          origin="breadcrumb"
        />
      )}
    </TopBar.Breadcrumbs>
  );
};

export default observer(Header);
