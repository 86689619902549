/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { SkeletonIcon } from '@carbon/react';
import { Launch } from '@carbon/icons-react';

import { Tooltip } from 'primitives';

import * as Styled from './TemplateItem.styled';

const TemplateItem = ({ id, name, icon, source, hasDuplicates, duplicates }) => {
  return (
    <Styled.ResourceItem href={source} target="_blank">
      {hasDuplicates && duplicates?.some((duplicate) => duplicate.templateId === id) && <Styled.YellowWarningIcon />}
      {icon ? (
        typeof icon === 'string' && icon.substring(0, 14) === 'data:image/svg' ? (
          <Styled.ResourceLogo src={icon} alt={`${name} logo`} />
        ) : (
          icon
        )
      ) : (
        <SkeletonIcon />
      )}

      <Tooltip title={name} showOnlyOnOverflow>
        <Styled.ResourceName>{name}</Styled.ResourceName>
      </Tooltip>

      <Tooltip title={source} showOnlyOnOverflow>
        <Styled.ResoureUrl>{source}</Styled.ResoureUrl>
      </Tooltip>

      <Styled.ResourceLink>
        <Launch />
      </Styled.ResourceLink>
    </Styled.ResourceItem>
  );
};

TemplateItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  source: PropTypes.string,
  hasDuplicates: PropTypes.bool,
  duplicates: PropTypes.array
};

export default TemplateItem;
