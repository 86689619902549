/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Tab, Tabs } from '@carbon/react';
import { useCallback } from 'react';

import { Info, Milestone, Download } from 'icons';
import { IconButton } from 'primitives';

import { StatusIcon } from './StatusIcon';
import * as Styled from './ActionBar.styled';

const MODES_TABS_CLASSNAME = 'mode-tab';

/**
 * @typedef {Object} ModeOption
 * @property {string} value - Mode identifier
 * @property {string} label - Display label for the mode
 */

/**
 * @typedef {Object} StatusMessage
 * @property {'success'|'error'|'warning'} type - Type of status
 * @property {string} message - Status message to display
 */

/**
 * @param {Object} props
 * @param {string} props.fileTypeName - The written name of the file type
 * @param {string} props.selectedMode - Currently selected mode
 * @param {boolean} props.loading - Loading state
 * @param {StatusMessage} [props.status] - Current status message
 * @param {boolean} props.isReadOnly - Whether file is in readonly mode
 * @param {Function} [props.onViewVersion] - Handler for version button click
 * @param {Function} [props.onExport] - Handler for export button click
 * @param {ModeOption[]} props.modeOptions - Available mode options
 * @param {(mode: string) => void} props.onModeChange - Handler for mode changes
 */
export const ActionBar = observer(function ActionBar({
  fileTypeName,
  selectedMode,
  status,
  isReadOnly,
  loading = null,
  onViewVersion = null,
  onExport = null,
  modeOptions = null,
  onModeChange = null
}) {
  const selectedIndex = modeOptions?.findIndex((mode) => mode.value === selectedMode) ?? -1;
  const selectMode = useCallback(
    ({ selectedIndex }) => {
      if (selectedIndex === -1) {
        return;
      }

      return onModeChange(modeOptions[selectedIndex].value);
    },
    [onModeChange, modeOptions]
  );

  return (
    <Styled.ActionBarContainer data-test="action-bar">
      <Styled.ActionBar>
        <Styled.LeftSection>
          {modeOptions?.length > 0 && (
            <Tabs selectedIndex={selectedIndex} onChange={selectMode}>
              <Styled.TabList aria-label="List of modes" activation="manual">
                {modeOptions.map(({ value, label }) => (
                  <Tab
                    className={MODES_TABS_CLASSNAME}
                    key={`view-mode-${value.toLowerCase()}`}
                    title={label}
                    data-test={`mode-tab-${value.toLowerCase()}`}
                  >
                    {label}
                  </Tab>
                ))}
              </Styled.TabList>
            </Tabs>
          )}

          {Boolean(status) && (
            <Styled.Status data-test="autosave">
              <StatusIcon status={status.type} />
              {status.message}
            </Styled.Status>
          )}

          {isReadOnly && !loading && (
            <Styled.Status>
              <Info width="14" height="14" /> Only collaborators with write access may modify {fileTypeName}s.
            </Styled.Status>
          )}
        </Styled.LeftSection>

        {onViewVersion && (
          <IconButton title="Show versions" data-test="versions-list" onClick={onViewVersion}>
            <Milestone width="24" height="24" />
          </IconButton>
        )}

        {onExport && (
          <IconButton disabled={loading} onClick={onExport} title={`Download ${fileTypeName}`}>
            <Download width="25" height="24" />
          </IconButton>
        )}
      </Styled.ActionBar>
    </Styled.ActionBarContainer>
  );
});
