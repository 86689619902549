/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from 'components';
import { notificationStore } from 'components/NotificationSystem';
import { confirmActionStore } from 'stores';

import { deleteService } from './DeleteService';

function DeleteListItem({ versionId, reloadVersions, closeVersionMenuDropdown }) {
  const confirmProps = {
    title: 'Delete version',
    text: 'If you delete this version, it will no longer be available in your history.',
    isDangerous: true,
    confirmLabel: `Delete version`
  };
  return (
    // @ts-expect-error TS2554
    <Dropdown.ListItem
      onClick={async () => {
        try {
          closeVersionMenuDropdown();
          const confirmed = await confirmActionStore.confirm(confirmProps);
          if (!confirmed) {
            return;
          }
          await deleteService.deleteProcessApplicationVersion(versionId);
          notificationStore.showSuccess('Process application version was deleted.');
          reloadVersions();
        } catch (error) {
          // Deleting a version of the process application with organization-public connector templates
          // returns a warning for users without elevated access equivalent to outside process apps
          let errorMsg = 'Failed to delete the process application version.';
          if (error?.status === 403 && error?.[0]?.reason === 'INVALID_OPERATION') {
            errorMsg = error[0].message;
          }
          notificationStore.showError(errorMsg);
        }
      }}
    >
      Delete
    </Dropdown.ListItem>
  );
}

export { DeleteListItem };
