/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { FileUploaderItem } from '@carbon/react';

export function CreateNewElementTemplateFileUploadCommonFileItem({ fileData, setFileData, onDeleteFn }) {
  return (
    <>
      {fileData !== undefined && (
        <FileUploaderItem
          key={fileData.uuid}
          uuid={fileData.uuid}
          name={fileData.name}
          errorSubject={fileData.errorSubject}
          errorBody={fileData.errorBody}
          size={'md'}
          status={fileData.status}
          iconDescription={fileData.iconDescription}
          invalid={fileData.invalid}
          onDelete={() => {
            if (onDeleteFn) {
              onDeleteFn();
            } else {
              setFileData();
            }
          }}
        />
      )}
    </>
  );
}

export default observer(CreateNewElementTemplateFileUploadCommonFileItem);
