/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { organizationStore } from 'stores';
import config from 'utils/config';
import { defineStagesModalStore } from 'App/Pages/Diagram/Deployment/defineStagesModal';
import { modes } from 'App/Pages/Diagram/features/deployment/utils';

import { ActionableNotification } from './PlayNotification.styled';

export default function PlayPerformanceNotification({
  onClose,
  shouldLinkToConsole,
  canSelectAnotherCluster,
  hasTopMargin,
  selectedClusterId
}) {
  return (
    <ActionableNotification
      inline
      title="Selected cluster will affect Play’s performance"
      subtitle={
        shouldLinkToConsole
          ? canSelectAnotherCluster
            ? 'Change the selected cluster or enable the low-latency flag for your cluster within Console to increase performance.'
            : 'To increase performance, enable the low-latency flag for your cluster within Console.'
          : 'Change the selected development cluster via the stage settings.'
      }
      kind="info"
      lowContrast
      hideCloseButton
      actionButtonLabel={shouldLinkToConsole ? 'Open console' : 'Open settings'}
      onActionButtonClick={() => {
        if (shouldLinkToConsole) {
          return window.open(
            `https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/cluster/${selectedClusterId}/settings`
          );
        }

        onClose();
        defineStagesModalStore.open({ executeModalMode: modes.PLAY });
      }}
      $hasTopMargin={hasTopMargin}
    />
  );
}
