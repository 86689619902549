/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { css } from 'styled-components';

import {
  lightGreen,
  lightOrange,
  lightRed,
  diffingAddedStyles,
  diffingChangedStyles,
  diffingRemovedStyles
} from 'utils/diffing';

export const diffingStyles = css`
  .added {
    background-color: ${lightGreen};
  }
  .changed {
    background-color: ${lightOrange};
  }

  .removed {
    background-color: ${lightRed};
  }

  .rounded {
    border-radius: 50%;
    padding: 6px;
    color: white;
    width: 28px;
    height: 28px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    border: 2px solid white;
  }

  ${diffingAddedStyles};
  ${diffingChangedStyles};
  ${diffingRemovedStyles};
`;
