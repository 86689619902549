/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339

import styled from 'styled-components';

import { MARKDOWN_VIEW_MODES } from './MarkdownEditor';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden; // Prevent container overflow
`;

export const EditorContainer = styled.div`
  flex: ${({ $viewMode }) => ($viewMode === MARKDOWN_VIEW_MODES.SPLIT ? '0 1 50%' : '1 1 100%')};
  min-width: 0; // Allow shrinking below content size
  height: 100%;
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
`;

export const PreviewContainer = styled.div`
  flex: ${({ $viewMode }) => ($viewMode === MARKDOWN_VIEW_MODES.SPLIT ? '0 1 50%' : '1 1 100%')};
  min-width: 0; // Allow shrinking below content size
  height: 100%;
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
  border-left: ${({ $viewMode }) =>
    $viewMode === MARKDOWN_VIEW_MODES.SPLIT ? '1px solid var(--silver-darken-87)' : 'none'};
`;
