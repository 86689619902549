/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Wrapper = styled.div`
  --color: ${({ $backgroundColor }) => $backgroundColor};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
  box-sizing: content-box;
  text-transform: uppercase;
  flex-shrink: 0;
  position: relative;
  color: ${({ $isTextDark }) => ($isTextDark ? '#343536;' : 'white;')}
  background-color: var(--color);

  > svg {
    position: absolute;
    left: 0;
    bottom: 0;
    color: var(--color);
  }

  ${({ size }) => {
    if (size == 'small') {
      return `
        width: 30px;
        height: 30px;
        font-weight: bold;
        font-size: 12px;
      `;
    }

    return `
      width: 40px;
      height: 40px;
      font-size: 19px;
    `;
  }}
`;

export const PendingIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px dashed #bababa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;

  > svg {
    fill: #bababa;
  }
`;
