/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tag } from '@carbon/react';
import { Connect as CarbonConnector, Folder as CarbonFolder } from '@carbon/icons-react';

import { Diagram, DiagramWithFolder, Form, IdpDocument, Project, Table } from 'icons';
import { Avatar } from 'primitives';
import { userStore } from 'stores';
import {
  BPMN,
  CONNECTOR_TEMPLATE,
  DMN,
  FOLDER,
  FOLDER_DEFAULT,
  FORM,
  ID_ALREADY_EXISTS,
  IDP_APPLICATION,
  OIDC_TYPE,
  PAGE_TITLE_DIVIDER,
  PAGE_TITLE_PREFIX,
  PROCESS_APPLICATION,
  PROJECT,
  TARGET_ENV
} from 'utils/constants';
import buildSlug from 'utils/buildSlug';
import config from 'utils/config';
import { getReadableTimestamp } from 'utils/date-format';

/**
 * @returns {string} The environment where the application is running
 */
export const getEnv = () => {
  return TARGET_ENV.INT === config.targetEnv || TARGET_ENV.PROD === config.targetEnv
    ? config.targetEnv
    : TARGET_ENV.DEV;
};

/**
 * Determines whether a given file is a Camunda form.
 *
 * @param {Object} file The file to check.
 * @returns {Boolean}
 */
export const isForm = (file) => {
  return file.type === FORM;
};

/**
 * Determines whether a given file is a Connector template.
 *
 * @param {Object} file The file to check.
 * @returns {Boolean}
 */
export const isTemplate = (file) => {
  return file.type === CONNECTOR_TEMPLATE;
};

/**
 * Determines whether a given file is a BPMN or DMN diagram.
 *
 * @param {Object} file The file to check.
 * @returns {Boolean}
 */
export const isDiagram = (file) => {
  return file.type === BPMN || file.type === DMN;
};

/**
 * Determines whether a given diagram is DMN or not.
 *
 * @param {Object} file
 * @param {String} [file.type]
 * @returns {Boolean}
 */
export const isDMN = (file) => {
  return file.type === 'DMN';
};

/**
 * Determines whether a given diagram is BPMN or not.
 *
 * @param {Object} file
 * @param {String} [file.type]
 * @returns {Boolean}
 */
export const isBPMN = (file) => {
  return file.type === 'BPMN';
};

/**
 * Determines whether a given entity is a main process
 *
 * @param {Object} entity
 * @return {Boolean}
 */
export const isMainProcess = (entity) => {
  return entity.isMainProcess ?? false;
};

/**
 * Determines whether a given entity is a valid file type and not a folder.
 *
 * @param {Object} entity The entity to check.
 * @param {String} [entity.type] The entity type as provided by the backend.
 * @returns {Boolean}
 */
export const isFile = (entity) => {
  return [BPMN, DMN, FORM, CONNECTOR_TEMPLATE].includes(entity.type);
};

/**
 * Determines whether a given entity is a folder.
 *
 * @param {Object} entity The entity to check.
 * @param {String} [entity.type] The entity type hydrated from the frontend.
 * @param {String} [entity.folderType] The entity folder type as provided by the backend.
 * @returns {Boolean}
 */
export const isFolder = (entity) => {
  const value = entity?.folderType || entity?.type;
  return value === FOLDER || value === FOLDER_DEFAULT;
};

/**
 * Determines whether a given entity is a process application.
 * @param {Object} entity The entity to check.
 * @returns {boolean}
 */
export const isProcessApplication = (entity) => {
  const value = entity?.folderType || entity?.type;
  return value === PROCESS_APPLICATION;
};

/**
 * Determines whether a given entity is an IDP application.
 * @param {Object} entity The entity to check.
 * @returns {boolean}
 */
export const isIdpApplication = (entity) => {
  const value = entity?.folderType || entity?.type;
  return value === IDP_APPLICATION;
};

export const isFolderOrProcessApplication = (entity) => {
  return isFolder(entity) || isProcessApplication(entity);
};

/**
 * Determines whether a given entity is contained in a process application.
 * @param {Object} entity The entity to check.
 * @return {boolean}
 */
export const isContainedInProcessApplication = (entity) => {
  return entity?.folderType === PROCESS_APPLICATION;
};

/**
 * Checks that a given error is a duplicate resource error.
 * @param error
 * @returns {boolean}
 */
export const isDuplicateResourceInProcessApplicationError = (error) => {
  return error?.[0]?.reason === ID_ALREADY_EXISTS;
};

/**
 * Returns the error message for a duplicate resource in a process application.
 * @param error
 * @returns {string}
 */
export const getDuplicateResourceInProcessApplicationErrorMessage = (error) => {
  return error?.[0]?.detail || 'The file already exists in the process application.';
};

/**
 * Builds the parent link for the given entity.
 * @param {Object} entity
 * @return {string}
 */
export const getParentLink = (entity) => {
  if (!entity || !entity.folder) {
    return '/';
  }
  const slug = buildSlug(entity.folder.id, entity.folder.name);
  if (isContainedInProcessApplication(entity)) {
    return `/process-applications/${slug}`;
  }
  return `/folders/${slug}`;
};

/**
 * Returns the sorting position for a given entity, based on its type.
 * @param {Object} entity
 * @returns {number} The sorting position
 */
export const getSortingPositionByEntity = (entity) => {
  if (isFolder(entity)) {
    return 1;
  } else if (isProcessApplication(entity)) {
    return 2;
  } else if (isMainProcess(entity)) {
    return 3;
  } else {
    return 4;
  }
};

export const sanitizeName = (name = '', fallback = 'new-diagram') => {
  const sanitized = name
    .normalize()
    // replace any non word characters (including non-latin characters), except underscore
    .replace(/[^_0-9\p{Letter}\p{Mark}]+/gu, '-')
    // replace consecutive dashes with a single one
    .replace(/-+/g, '-')
    // remove leading and trailing dashes
    .replace(/(^-|-$)/g, '')
    .toLowerCase();

  // If the diagram name is empty we provide a fallback filename.
  if (sanitized === '') {
    return fallback;
  }

  return sanitized;
};

/**
 * Computes the file name to be assigned to the downloaded file
 *
 * @param {Array} files
 * @param {Array} folders
 * @returns {String}
 */
export const computeNameForDownloadedFile = (files = [], folders = []) => {
  if (files.length === 1 && folders.length === 0) {
    return files[0].name;
  } else if (folders.length === 1 && files.length === 0) {
    return `${folders[0].name}.zip`;
  }
  return `modeler-files-${getReadableTimestamp()}.zip`;
};

/**
 * Returns the corresponding export type for the given entity type
 *
 * @param {string} entityType
 * @return {string}
 */
export const getExportType = (entityType) => {
  if ([CONNECTOR_TEMPLATE, FORM].includes(entityType)) {
    return 'json';
  } else if (entityType === FOLDER || entityType === PROCESS_APPLICATION) {
    return 'zip';
  } else {
    return 'xml';
  }
};

/**
 * Checks if a given string is empty, undefined, or null.
 *
 * @param {String} str
 * @returns {Boolean}
 */
export const isEmpty = (str) => {
  if (typeof str != 'string') {
    return true;
  }

  if (str.trim() === '') {
    return true;
  }

  return false;
};

/**
 * Checks if a given string can be parsed as JSON.
 *
 * @param {String} str
 * @returns {Boolean}
 */
export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

export const getEntityIcon = (entity) => {
  const icons = {
    DMN: <Table width="20" height="20" />,
    BPMN: <Diagram width="20" height="20" />,
    FOLDER: <CarbonFolder width="20" height="20" />,
    FORM: <Form width="20" height="20" />,
    CONNECTOR_TEMPLATE: <CarbonConnector width="20" height="20" />,
    PROCESS_APPLICATION: <DiagramWithFolder width="20" height="20" />,
    IDP_APPLICATION: <IdpDocument width="20" height="20" />
  };

  return icons[entity.type] || <Project width="24" height="24" />;
};

export const getEntityName = (entity) => {
  const pretitles = {
    BPMN: 'BPMN diagram',
    DMN: 'DMN diagram',
    FOLDER: '',
    FORM: 'Form',
    CONNECTOR_TEMPLATE: 'Connector template',
    PROCESS_APPLICATION: 'Process application',
    IDP_APPLICATION: 'IDP Application'
  };

  return {
    content: entity.name,
    pretitle: isProject(entity) ? 'Project' : pretitles[entity.type]
  };
};

export const getEntityAvatar = (collaborator) => {
  return (
    <Avatar
      hasStar={collaborator.permissionAccess === 'ADMIN'}
      isInvited={collaborator.permissionAccess === 'NONE' || Boolean(collaborator.status)}
      isPending={isOrganizationInvitePending(collaborator)}
      size="small"
      fullname={collaborator.name || collaborator.email}
      disableTooltip
    />
  );
};

export const isOrganizationInvitePending = (collaborator) => collaborator.invitationPending === true;

export const getCollaboratorName = (collaborator) => {
  const data = {
    content: userStore.isCurrentUser(collaborator) ? 'You' : collaborator.name || collaborator.email,
    pretitle: isOrganizationInvitePending(collaborator) ? (
      <Tag size="sm">pending</Tag>
    ) : (
      collaborator.name && (collaborator.email ?? 'no email')
    )
  };

  return data;
};

export const getAuthorName = (entity) => {
  if (isFile(entity)) {
    return userStore.isCurrentUser(entity.author) ? 'You' : entity.author.name;
  }

  return userStore.isCurrentUser(entity.createdBy) ? 'You' : entity.createdBy.name;
};

export const getEntityLink = (entity) => {
  if (isFile(entity)) {
    if (isForm(entity)) {
      return `/forms/${buildSlug(entity.id, entity.name)}/`;
    } else if (isTemplate(entity)) {
      return `/connector-templates/${buildSlug(entity.id, entity.name)}/`;
    } else {
      return `/diagrams/${buildSlug(entity.id, entity.name)}/`;
    }
  } else if (isProcessApplication(entity)) {
    return `/process-applications/${buildSlug(entity.id, entity.name)}/`;
  } else if (isIdpApplication(entity)) {
    return `/idp-applications/${buildSlug(entity.id, entity.name)}/`;
  }

  return `/folders/${buildSlug(entity.id, entity.name)}/`;
};

export const getMilestoneViewLink = (entity) => {
  return `/connector-templates/${entity.id}/versions`;
};

export const getInitialsForName = (name) => {
  if (typeof name === 'string') {
    const [firstname, lastname = ''] = name.split(' ');

    return `${firstname.charAt(0)}${lastname.charAt(0)}`.toUpperCase();
  }

  return name;
};

/**
 * Enriches the optional input string with a default prefix and separator.
 * @param {string} pageTitle The title of the specific page. It can be omitted.
 * @returns A string containing a default title prefix and the given specific page title
 */
export const getPageTitle = (pageTitle) =>
  pageTitle?.length > 0 ? `${PAGE_TITLE_PREFIX} ${PAGE_TITLE_DIVIDER} ${pageTitle}` : PAGE_TITLE_PREFIX;

/**
 * Returns the list of a FORM diagram fields (recursively).
 * @param {Object} diagram The FORM diagram
 * @returns The list of fields. Returns *undefined* in case the given diagram type is not a FORM or in case the JSON is corrupted
 */
export const getFormFields = (diagram) => {
  if (!isForm(diagram)) {
    return undefined;
  }

  function getComponents(components = []) {
    const formFields = [];

    components.forEach((component) => {
      formFields.push(component);

      if (component.components?.length > 0) {
        formFields.push(...getComponents(component.components));
      }
    });

    return formFields;
  }

  try {
    const parsedDiagram = JSON.parse(diagram.content);
    return getComponents(parsedDiagram.components);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

/**
 * Returns a list of counted form field types.
 * @param {Array} formFields A list of form fields
 * @returns A list of counted form field types. Returns *undefined* in case the given list is empty or undefined
 */
export const getFormFieldTypeCounts = (formFields) => {
  if (!formFields || formFields.length === 0) {
    return;
  }

  const typeCounts = {};

  formFields.forEach((component) => {
    const { type } = component;

    if (typeCounts[type]) {
      typeCounts[type]++;
    } else {
      typeCounts[type] = 1;
    }
  });

  return typeCounts;
};

/**
 * Returns the list of a diagram flow nodes.
 * @param {Object} diagram The BPMN diagram
 * @param {Object} modeler the Modeler
 * @returns The list of flow nodes. Returns *undefined* in case the given diagram type is not BPMN or DMN
 */
export const getFlowNodes = (diagram, modeler) => {
  if (!isBPMN(diagram) && !isDMN(diagram)) {
    return undefined;
  }

  return modeler?.get('elementRegistry').getAll();
};

/**
 * Returns if the given item is a project.
 * @param {*} item
 * @returns {Boolean}
 */
export const isProject = (item) => {
  return item.type === PROJECT;
};

/**
 * @returns {boolean}
 */
export const isKeycloakAuthTypeEnabled = () => {
  return config?.oAuth2?.type === OIDC_TYPE.KEYCLOAK;
};
