/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import URL from './url';
import TopBarCommon from './TopBar.common';

const ForwardRefLink = forwardRef((props, ref) => {
  return <Link {...props} to={props.to || ''} ref={ref} />;
});

const TopBar = ({ isInShare }) => {
  const notInShareMode = isInShare !== true;

  return (
    <TopBarCommon
      showAppBar={notInShareMode}
      showOrganizations={notInShareMode}
      showCookiePreferences={notInShareMode}
      showInfoBar={notInShareMode}
      showUserBar={notInShareMode}
      showHelpCenterAndNotifications={notInShareMode}
      termsOfUseUrl={URL.TOS_SAAS}
      forwardRefLink={ForwardRefLink}
    />
  );
};

TopBar.Breadcrumbs = TopBarCommon.Breadcrumbs;
TopBar.SubHeader = TopBarCommon.SubHeader;

export default TopBar;
