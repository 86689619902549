/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Button } from '@carbon/react/lib/components/Button';

export const Container = styled.main`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  padding: 40px;
`;

export const EmptyStateDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 15px;
`;

export const EmptyStateText = styled.div`
  b,
  span {
    display: block;
  }
`;

export const CreateIdpProjectMenuItem = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const NoWrapButton = styled.div`
  white-space: nowrap;
`;

export const SettingsButton = styled(Button)`
  justify-content: space-between;
  align-items: center;
  padding-block: 14px;
`;
