/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplication} ProcessApplication
 */

import { useState, useEffect } from 'react';

import { processApplicationService } from 'services';
/**
 * @param {String} processApplicationId
 * @returns {ProcessApplication}
 */
const useLoadProcessApplication = (processApplicationId) => {
  const [processApplication, setProcessApplication] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const processApplication = await processApplicationService.fetchProcessApplication(processApplicationId);
        setProcessApplication(processApplication);
      } catch {
        console.error('Failed to fetch process application!');
      }
    })();
  }, [processApplicationId]);

  return processApplication;
};

export default useLoadProcessApplication;
