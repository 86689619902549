/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { sanitize } from 'dompurify';

import { validateFiles } from 'utils/file-io';

export function extractFileNameFromResourceUrl(_template, resource) {
  const url = new URL(resource.source);
  const pathname = url.pathname;
  const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
  const decodedResult = decodeURIComponent(fileName);
  const sanitizedResult = sanitize(decodedResult);
  return sanitizedResult
    .replace(/&nbsp;|-|_|\+/g, ' ') // replace encoded whitespace with space
    .replace(/\s{2,}/g, ' ') // replace any duplicate spaces with a single space
    .replace(/\.[^/.]+$/, '') // remove file extension
    .substring(0, 255); // use only first 255 characters
}

export async function prepareOutputForSingleTemplate(template, type) {
  let resultTemplate = template;
  let files = [
    {
      size: new Blob([resultTemplate]).size,
      type,
      content: resultTemplate
    }
  ];

  const response = await validateFiles(files, [type]);

  let { valid: validFiles, invalid: invalidFiles } = response;

  if (validFiles?.length > 0) {
    return {
      valid: true,
      error: undefined,
      reasons: undefined,
      templates: [template],
      type
    };
  } else if (invalidFiles?.length > 0) {
    return {
      valid: false,
      error: 'Invalid content',
      reasons: ['File validation failed'],
      templates: [template],
      type
    };
  }
}
