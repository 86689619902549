/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex: 1;
  overflow-x: hidden;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;
