/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link } from '@carbon/react';

import { notificationStore } from 'components/NotificationSystem';

export function showSuccessNotification({ isConnectorTemplatePublish, isShowLinkToVersionsPage, onVersionsListClick }) {
  const notification = notificationStore.showSuccess(
    <>
      New process application version saved to versions list
      {isConnectorTemplatePublish && ', and connector template published to project'}.
      {isShowLinkToVersionsPage && (
        <>
          <br />
          <br />
          You can request a review or compare versions in the{' '}
          <Link
            href="#"
            onClick={() => {
              notificationStore.disposeNotification(notification.id, 'success');
              onVersionsListClick();
            }}
            inline
          >
            versions list
          </Link>
          .
        </>
      )}
    </>
  );
}
