/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@carbon/react';

import { processApplicationStore, projectStore } from 'stores';
import config from 'utils/config';
import { trackingService } from 'services';

import { BrowseBlueprintsModal } from './BrowseBlueprintsModal';
import ProjectActionButton from './ProjectActionButton';

export function EmptyStateActionButtons() {
  const { uploadFiles } = projectStore;

  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  const openTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };

  return (
    <>
      <Button onClick={() => processApplicationStore.startCreation()}>Create process application</Button>
      <Button
        kind="tertiary"
        onClick={() => {
          openTemplateModal();
          trackingService.trackBlueprintsOpening({ from: 'emptyState' });
        }}
        data-test="browse-process-blueprints-button"
      >
        Browse blueprints
      </Button>
      <ProjectActionButton variant="carbon" onUpload={uploadFiles} label="Create new" />
      {config.marketplace?.enabled && (
        <BrowseBlueprintsModal isOpen={isTemplateModalVisible} onClose={() => setIsTemplateModalVisible(false)} />
      )}
    </>
  );
}

export default observer(EmptyStateActionButtons);
