/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { transportCarrier } from 'transports';

export default class RealtimeChannel {
  constructor(channelName, events) {
    this.channelName = channelName;
    this.events = events;
    this.socket = transportCarrier.socket;
  }

  subscribe = () => {
    if (!this.socket) {
      return;
    }

    this.channel = this.socket.subscribe(this.channelName);
    this.listenToEvents();
  };

  listenToEvents = () => {
    Object.keys(this.events).forEach((eventName) => {
      this.channel?.bind(eventName, this.events[eventName]);
    });
  };

  unsubscribe = () => {
    if (!this.socket) {
      return;
    }

    this.socket.unsubscribe(this.channelName);
  };
}
