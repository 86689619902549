/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { ProcessApplicationModalWrapper } from './ProcessApplication';

export const Container = ProcessApplicationModalWrapper;

export const Title = styled.span`
  margin: 0 auto 0 0;
  font-weight: 400;
  font-size: 21px;
  color: rgb(51, 51, 51);
  display: flex;
  align-items: center;
  min-width: 0;

  > svg {
    margin-right: 13px;
  }

  > span {
    flex: 1;
  }
`;
