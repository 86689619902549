/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { userStore } from 'stores';

import { Service } from './index';

class OrganizationService extends Service {
  fetchTemplates(organizationId) {
    const superUserParam = userStore.isSuperUserModeActive ? '?superUserModeEnabled=true' : '';

    return this.get(`/internal-api/organizations/${organizationId}/connector-templates${superUserParam}`);
  }
}

export default new OrganizationService();
