/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Link } from '@carbon/react';
import { useParams } from 'react-router-dom';
import { Settings } from '@carbon/icons-react';

import { idpApplicationStore, idpProjectStore } from 'stores';
import { EmptyState, EmptyState as EmptyStatePrimitive, Spinner } from 'primitives';
import { IdpApplication, IdpDocument, SadFace } from 'icons';
import * as Shared from 'App/Pages/Project/Shared.styled';
import { EntityTable } from 'components';
import { IdpProjectCreationModal } from 'App/Pages/Project/IdpApplication/IdpProject';
import { NameRenderer } from 'components/EntityTable/CellRenderer';
import getBatchActions from 'App/Pages/Project/BatchActions';
import { IDP_PROJECT } from 'utils/constants';
import { getAuthorName, getEntityLink, getEntityName, getSortingPositionByEntity } from 'utils/helpers';
import { getFileDate } from 'App/Pages/Project/utils';
import EntityAction from 'App/Pages/Project/EntityAction';
import capitalize from 'utils/capitalize';
import { IDP_PROJECT_TYPE } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';
import { SettingsModal } from 'App/Pages/Project/IdpApplication/settings-modal';

import IdpApplicationHeader from './IdpApplicationHeader';
import * as Styled from './IdpApplication.styled';

const IdpApplicationPage = () => {
  const [loading, setLoading] = useState(true);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  // @ts-expect-error TS2339
  const { slug } = useParams();
  const { idpApplication } = idpApplicationStore;
  const { targetSelector } = getBatchActions();

  const reset = () => {
    idpApplicationStore.reset();
  };

  useEffect(() => {
    const init = async () => {
      await idpApplicationStore.init(slug.split('--')[0]);
      setLoading(false);
    };

    init();

    return () => reset();
  }, [slug]);

  const handleCreateIdpProject = (idpProjectType) => {
    idpProjectStore.startCreation(idpProjectType);
  };

  const IdpApplicationNotFound = () => {
    return (
      // @ts-expect-error TS2739
      <EmptyState
        title="IDP application not found!"
        description="Sorry, your IDP application might have been deleted."
        icon={<SadFace width="48" height="48" />}
      />
    );
  };

  const CreateNewIdpProject = ({ label }) => {
    return <Button onClick={() => handleCreateIdpProject(IDP_PROJECT_TYPE.EXTRACTION)}>{label}</Button>;
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case IDP_PROJECT_TYPE.EXTRACTION:
        return 'Document Extraction';
      case IDP_PROJECT_TYPE.DOCUMENT_AUTOMATION:
        return 'Document Automation';
      default:
        return capitalize(type.toLowerCase());
    }
  };

  const NoIdpProjectsInApplication = () => {
    return (
      <EmptyStatePrimitive
        icon={<IdpApplication height="114" />}
        title="Get started with IDP"
        description={
          <Styled.EmptyStateDescriptionContainer>
            <Styled.EmptyStateText>
              <span>
                Use{' '}
                <Link href="https://docs.camunda.io/docs/8.7/components/modeler/web-modeler/idp/" target="_blank">
                  Intelligent Document Processing (IDP)
                </Link>{' '}
                to automate document handling within your BPMN processes.
              </span>
            </Styled.EmptyStateText>
            <Styled.EmptyStateText>
              <b>Create a document extraction template</b>
              <span>
                Document extraction forms the basis for IDP and enables the definition of extraction fields to identify
                and extract data from a specific type of structured or unstructured document (e.g., an invoice or a
                contract).
              </span>
            </Styled.EmptyStateText>
          </Styled.EmptyStateDescriptionContainer>
        }
        action={
          <Styled.NoWrapButton>
            <Button onClick={() => handleCreateIdpProject(IDP_PROJECT_TYPE.EXTRACTION)}>
              Create document extraction template
            </Button>
          </Styled.NoWrapButton>
        }
        link={undefined}
      />
    );
  };

  const SettingsButton = ({ tabIndex }) => {
    return (
      <Styled.SettingsButton
        kind="ghost"
        size="md"
        onClick={() => setIsSettingsDialogOpen(true)}
        renderIcon={Settings}
        data-test="open-settings"
        tabIndex={tabIndex}
      >
        <span>Settings</span>
      </Styled.SettingsButton>
    );
  };

  return (
    <>
      <IdpApplicationHeader />
      {loading ? (
        <Spinner fullHeight />
      ) : idpApplication?.idpProjects ? (
        <>
          <Styled.Container>
            <EntityTable
              action={({ tabIndex }) => (
                <>
                  <SettingsButton tabIndex={tabIndex} />
                  <CreateNewIdpProject label="Create document extraction" />
                </>
              )}
              // @ts-expect-error TS2741
              emptyState={<NoIdpProjectsInApplication />}
              columns={[
                {
                  key: 'name',
                  header: 'Name',
                  renderer: NameRenderer,
                  sortable: true
                },
                {
                  key: 'projectType',
                  header: 'Type',
                  sortable: true
                },
                {
                  key: 'createdBy',
                  header: 'Creator',
                  width: '150px',
                  sortable: true
                },
                {
                  key: 'lastChanged',
                  header: 'Last changed',
                  width: '200px',
                  sortable: true
                },
                {
                  key: 'entityAction',
                  header: '',
                  width: '50px',
                  noPadding: true,
                  renderer: (entity) => <EntityAction entity={entity} />
                }
              ]}
              title={
                <Shared.Title data-test="idp-application-name">
                  <IdpDocument width="24" height="24" />
                  {idpApplication.name}
                </Shared.Title>
              }
              toolbarPlaceholder="Type IDP project name to search"
              rows={idpApplication.idpProjects.map((entity) => {
                const extendedEntity = {
                  ...entity,
                  type: IDP_PROJECT,
                  changed: entity.lastChanged
                };

                return {
                  __sortingPosition: getSortingPositionByEntity(entity),
                  id: entity.id,
                  name: {
                    name: getEntityName(entity),
                    link: getEntityLink(extendedEntity)
                  },
                  projectType: getTypeLabel(entity.type),
                  createdBy: getAuthorName(entity),
                  lastChanged: getFileDate(extendedEntity),
                  entityAction: extendedEntity
                };
              })}
            />
            {targetSelector}
          </Styled.Container>
          <SettingsModal
            isOpen={isSettingsDialogOpen}
            onClose={() => setIsSettingsDialogOpen(false)}
            clusterId={idpApplication?.clusterId}
          />
          <IdpProjectCreationModal />
        </>
      ) : (
        <IdpApplicationNotFound />
      )}
    </>
  );
};

export default observer(IdpApplicationPage);
