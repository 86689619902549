/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import moment from 'moment';

import { getSearchParams, replaceSearchParams } from 'utils/url-params';
import { fileService, folderService, projectService, tracingService, trackingService } from 'services';
import {
  breadcrumbStore,
  confirmActionStore,
  milestoneStore,
  notificationStore,
  organizationStore,
  userStore
} from 'stores';
import history from 'utils/history';
import throttle from 'utils/throttle-async';
import { validateFiles } from 'utils/file-io';
import { getDefinitions, getExecutableProcessId, parseXML, stringifyXML } from 'utils/web-modeler-diagram-parser';
import createPermission from 'utils/createPermission';
import {
  BPMN,
  DEFAULT,
  DEFAULT_ZEEBE_VERSION,
  DMN,
  EXECUTION_PLATFORM,
  FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION,
  FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION,
  FOLDER,
  FOLDER_DEFAULT,
  NO_PROCESS_ID,
  ORIGINAL_RELEASE_DATE,
  PROCESS_APPLICATION
} from 'utils/constants';
import hasAccess, { actions } from 'utils/user-access';
import { sanitizeBeforeImport } from 'utils/validate-diagram';
import { detectDecisions } from 'App/Pages/Diagram/model-parser-util';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { checkAndSyncProcessName } from 'App/Pages/Diagram/modeler-util';
import { isCamunda7File } from 'utils/file-io/validate-files';
import {
  getDuplicateResourceInProcessApplicationErrorMessage,
  isDuplicateResourceInProcessApplicationError
} from 'utils/helpers';

const EMPTY_PROJECT = {
  name: 'Loading...',
  diagrams: [],
  collaborators: [],
  invitations: []
};

const DEFAULT_STATE = {
  isFetching: true,
  isLoadingModeler: true,
  isShowingTemplate: true,
  isLegacyDiagram: false,
  diagram: null,
  templates: [],
  project: EMPTY_PROJECT,
  lastSaved: null,
  currentDate: null,
  currentVisibleMenu: {
    elementId: null,
    menuType: null
  },
  modelerViewboxScale: 1
};

const SAVING_MESSAGE_DURATION_IN_MILLIS = 300;
const SAVE_CONTENT_DEBOUNCE = 100;
const CHANGE_PARAMS_DEBOUNCE = 600;

class CurrentDiagramStore {
  state = Object.assign({}, DEFAULT_STATE);
  executionPlatformVersion = DEFAULT_ZEEBE_VERSION;

  modeler = null;
  isDraggingActive = false;
  hasReceivedUpdateWhileDragging = false;
  hasFetchedDiagramWhileDragging = false;
  initialViewbox = null;
  isErrorPanelCollapsed = (() => {
    if (!localStorage.getItem('errorpanel_collapsed')) {
      localStorage.setItem('errorpanel_collapsed', 'true');
      return true;
    } else {
      return localStorage.getItem('errorpanel_collapsed') === 'true';
    }
  })();
  healthState = {
    error: null,
    warnings: [],
    hasBeenValidated: false,
    isNotFound: false,
    isValid: false
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      executionPlatformVersion: observable,
      isErrorPanelCollapsed: observable,
      healthState: observable,
      iconScale: computed,
      reset: action('reset store'),
      setDiagram: action('set diagram'),
      setProject: action('set project'),
      setIsFetching: action('set is fetching'),
      setIsShowingTemplate: action('set is showing template'),
      setModeler: action('set modeler '),
      updateLastSaved: action,
      resetModeler: action,
      saveLatestDiagramState: action,
      refreshCurrentDate: action,
      discardTemplateGuide: action,
      initExecutionPlatformVersion: action,
      setExecutionPlatformVersion: action,
      setIsErrorPanelCollapsed: action,
      setHealthState: action,
      resetHealthState: action,
      autosaveMessage: computed,
      isBPMN: computed,
      admins: computed,
      calledBy: computed,
      isProcessTemplate: computed,
      isValid: computed,
      hasBeenValidated: computed,
      isNotFound: computed,
      validationProblems: computed
    });
  }

  get iconScale() {
    return this.state.modelerViewboxScale < 0.8 ? calculateIconScale(this.state.modelerViewboxScale) : 1;
  }

  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE);
    this.executionPlatformVersion = DEFAULT_ZEEBE_VERSION;
    this.modeler = null;
    this.elementRegistry = null;
    this.canvas = null;
    this.selection = null;
    this.initialViewbox = null;
    this.resetHealthState();
  };

  resetHealthState = () => {
    this.healthState = {
      error: null,
      warnings: [],
      hasBeenValidated: false,
      isNotFound: false,
      isValid: false
    };
  };

  setDiagram = async (diagram, { validate = true }) => {
    const storeDiagram = () => {
      runInAction(() => {
        this.state.diagram = diagram;
        this.state.isLegacyDiagram = diagram.created < ORIGINAL_RELEASE_DATE;
      });
    };

    let content;

    if (validate) {
      const { content: safeContent } = await this.#handleContentSanitization(diagram.content, diagram.type);
      content = safeContent;
    } else {
      content = diagram.content;
    }

    if (!content) {
      // We store the diagram even if it's invalid, so we can still edit it in the XML editor,
      // and render the diagram page anyway.
      storeDiagram();
      return;
    }

    diagram.content = content;

    if (diagram.type === DMN) {
      const decisions = await detectDecisions(diagram.content);
      diagram.decisionIds = decisions.map((decision) => decision.id);
    }

    storeDiagram();
  };

  setTemplates = (templates) => {
    this.state.templates = templates;
  };

  setProject = (project) => {
    this.state.project = project;
  };

  setTooltips = (tooltips) => {
    this.state.tooltips = tooltips;
  };

  unsetTooltips = () => {
    this.state.tooltips = undefined;
  };

  setIsFetching = (value) => {
    this.state.isFetching = value;
  };

  setIsShowingTemplate = (value) => {
    this.state.isShowingTemplate = value;
  };

  setModeler = async (modeler) => {
    this.modeler = modeler;
    this.elementRegistry = modeler.get('elementRegistry');
    this.canvas = modeler.get('canvas');
    this.selection = modeler.get('selection');

    await this.#upsertDiagramExecutionPlatformToC8();

    // initializes the value of the executionPlatformVersion store field
    this.initExecutionPlatformVersion();

    runInAction(() => {
      this.state.isLoadingModeler = false;
    });
  };

  /**
   * Upserts the execution platform and version to the diagram XML. If the content is a Camunda 7 resource, it will be converted to C8.
   * @returns {Promise<void>}
   */
  #upsertDiagramExecutionPlatformToC8 = async () => {
    try {
      const permission = createPermission(this.state.project && this.state.project.permissionAccess);
      if (permission.is(['WRITE', 'ADMIN'])) {
        let executionPlatformHelper;
        if (this.state.diagram.type === BPMN) {
          executionPlatformHelper = this.modeler.get('executionPlatform');
        } else {
          executionPlatformHelper = this.modeler.getActiveViewer().get('executionPlatform');
        }

        const executionPlatform = executionPlatformHelper.getExecutionPlatform();
        const isC8Diagram = executionPlatform?.name === EXECUTION_PLATFORM;
        const hasValidExecutionPlatformVersion = executionPlatform?.version;

        if (!isC8Diagram || !hasValidExecutionPlatformVersion) {
          executionPlatformHelper.setExecutionPlatform({
            name: EXECUTION_PLATFORM,
            version: DEFAULT_ZEEBE_VERSION
          });

          await this.saveContent();
        }
      }
    } catch (err) {
      tracingService.traceError(err);
    }
  };

  initExecutionPlatformVersion = () => {
    try {
      const permission = createPermission(this.state.project && this.state.project.permissionAccess);
      const isExecutionPlatformExtensionPresent = this.isBPMN
        ? permission.is(['WRITE', 'ADMIN', 'COMMENT'])
        : permission.is(['WRITE', 'ADMIN']);

      if (isExecutionPlatformExtensionPresent) {
        if (this.modeler) {
          const executionPlatformHelper = this.modeler.get('executionPlatform');
          const executionPlatform = executionPlatformHelper.getExecutionPlatform();
          if (executionPlatform?.version) {
            this.executionPlatformVersion = executionPlatform?.version;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Sets the executionPlatformVersion in the diagram and in the store field
  setExecutionPlatformVersion = async (version) => {
    if (this.isAdminOrEditor && this.modeler) {
      this.executionPlatformVersion = version; // store field

      // diagram
      let executionPlatformHelper;
      executionPlatformHelper = this.modeler.get('executionPlatform');
      const executionPlatform = executionPlatformHelper.getExecutionPlatform();
      if (executionPlatform?.version !== version) {
        executionPlatformHelper.setExecutionPlatform({
          name: EXECUTION_PLATFORM,
          version: version
        });
        await this.saveContent();
        trackingService.trackTargetEngineVersion(this.state.diagram, version);
      }
    }
  };

  setIsErrorPanelCollapsed = (val) => {
    this.isErrorPanelCollapsed = val;
    localStorage.setItem('errorpanel_collapsed', val ? 'true' : 'false');
  };

  renameDiagram = async (newName) => {
    const diagram = this.state.diagram;

    if (newName === diagram.name) {
      return;
    }

    fileService
      .update(diagram.id, {
        name: newName,
        revision: diagram.revision,
        originAppInstanceId: userStore.originAppInstanceId
      })
      .then((file) => {
        runInAction(() => {
          this.state.diagram.name = newName;
          this.state.diagram.revision = file.revision;
          this.state.diagram.calledBy = file.calledBy;

          checkAndSyncProcessName(this.modeler, newName);
        });
      })
      .catch(() => notificationStore.showError('Could not rename your diagram.'));
  };

  /**
   * From a given list of tooltips, stores only those who can be applied to existing elements in the given diagram content.
   *
   * @param {String} content : The XML content of the diagram;
   * @param {Object} tooltips The list of tooltips
   */
  async #setValidTooltips(content, tooltips) {
    if (!tooltips) {
      this.unsetTooltips();
      return;
    }

    const definitions = await getDefinitions(content);

    // Get the ids of all the elements in the diagram
    const elementsIds = definitions.diagrams
      .flatMap((diagram) => diagram.plane?.planeElement?.flat())
      .map((element) => element?.bpmnElement.id);

    // Filter out the tooltips that are not applicable to the current diagram
    tooltips = tooltips.filter((tooltip) => elementsIds.includes(tooltip.id));

    if (tooltips.length) {
      this.setTooltips(tooltips);
    } else {
      this.discardTemplateGuide(false, false);
      this.unsetTooltips();
    }
  }

  fetchDiagramById = async (fileId) => {
    this.setIsFetching(true);
    this.resetHealthState();

    try {
      const file = await fileService.fetchById(fileId);
      const requests = [
        projectService.fetchById({
          projectId: file.projectId,
          includeFolders: true,
          includeFiles: true
        })
      ];

      if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }

      const [project, folder] = await Promise.all(requests);

      if (file?.type === 'BPMN' && hasAccess(project, actions.VIEW_PROPERTIES)) {
        await this.loadElementTemplates(file.projectId);
      }

      organizationStore.compareAndSwitchOrganization(file.organizationId);

      this.setHealthState({ isNotFound: false });

      await this.setDiagram({ ...file, folder }, { validate: true });
      this.setProject(project);
      if (file?.templateTooltips) {
        this.#setValidTooltips(file.content, JSON.parse(file.templateTooltips));
      }
    } catch (ex) {
      this.setHealthState({ isNotFound: true });
      notificationStore.showError('Yikes! Could not fetch the diagram. Please try again later.');
      tracingService.traceError(ex);
    } finally {
      this.setIsFetching(false);
    }
  };

  async loadElementTemplates(projectId) {
    const templates = await projectService.fetchTemplates(projectId);
    if (templates) {
      runInAction(() => {
        this.setTemplates(templates);
      });
    }
  }

  trackDiagramView = (origin, extraProps) => {
    const user = userStore?.userId || this.admins[0]?.id;
    const file = this.state.diagram;

    if (file) {
      trackingService.trackViewFile(origin, user, this.state.diagram, this.modeler, {
        ...extraProps,
        projectAccess: this.state.project?.permissionAccess?.toLowerCase()
      });
    }
  };

  updateProject = async (projectId) => {
    const project = await projectService.fetchById({
      projectId,
      includeFolders: true,
      includeFiles: true
    });
    this.setProject(project);
    return project;
  };

  saveLatestDiagramState = async () => {
    if (!this.modeler) {
      return;
    }

    try {
      const { xml } = await this.modeler.saveXML({ format: true });
      runInAction(() => {
        this.state.diagram.content = xml;
      });
    } catch {
      return;
    }
  };

  /**
   * We need to throttle the saveContent method, so multiple actions like undo/redo or moving elements with the keyboard
   * can get queued up and don't provoke a conflict with the modeling client (customer using the app) itself.
   */
  saveContent = throttle(async () => {
    if (!this.modeler) {
      console.info('Tried to save XML, but navigated away from modeler already, content was not available anymore.');
      return;
    }

    const { xml } = await this.modeler.saveXML({ format: true });
    const comparisonContent = stringifyXML(parseXML(xml));

    if (this.lastReloadedContent && comparisonContent === this.lastReloadedContent) {
      this.notifyConflict();
      return;
    }

    const currentModelDefinitions = this.modeler._definitions;

    const payload = {
      content: xml,
      revision: this.state.diagram.revision,
      originAppInstanceId: userStore.originAppInstanceId
    };

    try {
      const file = await fileService.update(this.state.diagram.id, payload);
      trackingService.trackEditFile({
        fileId: this.state.diagram.id,
        type: this.state.diagram.type,
        mode: this.isBPMN ? dedicatedModesStore.selectedModeLabel : undefined
      });

      if (file?.templateTooltips) {
        this.#setValidTooltips(file.content, JSON.parse(file.templateTooltips));
      }

      this.updateLastSaved();

      if (file) {
        const decisions = this.isDMN ? await detectDecisions(xml) : [];
        runInAction(() => {
          if (this.isBPMN) {
            this.state.diagram.processId = getExecutableProcessId(currentModelDefinitions) || NO_PROCESS_ID;
            this.state.diagram.calledBy = file.calledBy;
          } else {
            this.state.diagram.decisionIds = decisions.map((decision) => decision.id);
          }
          this.state.diagram.revision = file.revision;
        });
        this.updateProject(file.projectId);
      }
    } catch (error) {
      if (isDuplicateResourceInProcessApplicationError(error)) {
        notificationStore.showError(getDuplicateResourceInProcessApplicationErrorMessage(error));
        await this.reloadDiagram();
      } else if (error.status === 409) {
        if (this.isDraggingActive) {
          this.hasReceivedUpdateWhileDragging = true;
        } else {
          await this.reloadDiagram();
          this.notifyConflict();
        }
      } else {
        await this.reloadDiagram();
        notificationStore.showError(FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION);
      }
    }
  });

  notifyConflict = () => {
    notificationStore.showNotification({
      message: FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION,
      variant: 'error'
    });
  };

  debouncedSaveContent = debounce(this.saveContent, SAVE_CONTENT_DEBOUNCE, {
    trailing: true
  });

  /**
   *
   * @type {DebouncedFuncLeading<replaceSearchParams> | DebouncedFunc<replaceSearchParams>}
   */
  debouncedReplaceParams = debounce(replaceSearchParams, CHANGE_PARAMS_DEBOUNCE, { trailing: true });

  updateLastSaved = () => {
    this.state.lastSaved = new Date();
    this.refreshCurrentDate();
    setTimeout(this.refreshCurrentDate, SAVING_MESSAGE_DURATION_IN_MILLIS);
  };

  refreshCurrentDate = () => {
    this.state.currentDate = new Date();
  };

  get autosaveMessage() {
    const lastSavedMilliSecondsAgo = this.state.currentDate - this.state.lastSaved;

    if (this.state.lastSaved == null) {
      return null;
    } else if (lastSavedMilliSecondsAgo < SAVING_MESSAGE_DURATION_IN_MILLIS) {
      return {
        status: 'progress',
        message: 'Saving...'
      };
    } else {
      return {
        status: 'done',
        message: `Autosaved at ${moment().format('HH:mm:ss')}`
      };
    }
  }

  async reloadDiagram() {
    fileService
      .fetchById(this.state.diagram.id)
      .then(async (file) => {
        if (file && file.content) {
          this.lastReloadedContent = stringifyXML(parseXML(file.content));

          runInAction(() => {
            this.state.diagram.revision = file.revision;
          });

          if (this.isDraggingActive) {
            this.hasFetchedDiagramWhileDragging = true;
          } else {
            await this.importContent(file.content);
            this.initExecutionPlatformVersion();
          }
        }
      })
      .catch(() => notificationStore.showError('Could not fetch your diagram.'));
  }

  /**
   * Imports the given content into the modeler.
   * @param content {String} The content to be imported.
   * @returns {Promise<{error: Error | boolean, warnings: string[], safeContent: String | null}>} The result of the import.
   */
  importContent = async (content) => {
    this.resetHealthState();

    const {
      error,
      warnings,
      content: safeContent
    } = await this.#handleContentSanitization(content, this.state?.diagram?.type);
    const canBeImported = safeContent && !error;

    if (canBeImported) {
      // If the content is valid, we store it in the diagram and import it into the modeler.
      await this.setDiagram({ ...this.state.diagram, content: safeContent }, { validate: false });
      return this.modeler.importXML(safeContent);
    } else {
      // If the content is invalid, we still store it in the diagram, so we can edit it in the XML editor.
      await this.setDiagram({ ...this.state.diagram, content }, { validate: false });
      return { error, warnings, safeContent };
    }
  };

  /**
   * Handles the content sanitization and returns the sanitized content, warnings and error.
   * This will mark the content as invalid if there is an error or warnings.
   * @private
   * @param content {String} The content to be sanitized.
   * @param type {String} The type of the diagram.
   * @returns {Promise<{error: Error | boolean, warnings: string[], content: String | null}>} The result of the validation and sanitization.
   */
  async #handleContentSanitization(content, type) {
    const { error, warnings, content: safeContent } = await sanitizeBeforeImport(content, type);

    if (error) {
      this.setHealthState({
        error,
        warnings,
        isValid: false,
        hasBeenValidated: true
      });

      return { error, warnings, content: null };
    }

    this.setHealthState({
      error: null,
      warnings: warnings || [],
      isValid: true,
      hasBeenValidated: true
    });

    return { error, warnings, content: safeContent };
  }

  setInitialViewbox = () => {
    const searchParams = getSearchParams();

    if (searchParams.v) {
      const viewboxParams = searchParams.v.split(',');

      this.initialViewbox = {
        x: viewboxParams[0],
        y: viewboxParams[1],
        scale: viewboxParams[2]
      };
    }
  };

  resetInitialViewbox = () => {
    this.initialViewbox = null;
  };

  resetModeler = () => {
    this.state.isLoadingModeler = true;
    this.modeler = null;
  };

  // NOTE: This is used in BPMNViewer even though some IDEs might not recognize it
  handleViewboxChange = (viewbox) => {
    const { x, y, scale } = viewbox;

    const viewboxString = `?v=${x},${y},${scale}`;

    this.initialViewbox = viewbox;

    // append viewbox information to URL
    this.debouncedReplaceParams(history, viewboxString);
  };

  deleteCurrentDiagram = async () => {
    const WARNINGS = {
      LINKED_DIAGRAM: 'Links to or from the diagrams will be removed.',
      COMMENTS: 'All comments will be deleted.',
      SHARED_LINK: 'Shared links will be inaccessible.'
    };

    breadcrumbStore.toggleDropdownVisibility();

    const { diagram, project } = this.state;

    let warnings = [];
    try {
      warnings = (await fileService.destroyDryRun([diagram.id])).warnings;
    } catch (error) {
      if (error.status == 404) {
        return notificationStore.showError("You don't have permission to delete this diagram.");
      } else {
        return notificationStore.showError("Your diagram couldn't be deleted. Please try again later.");
      }
    }

    const confirmed = await confirmActionStore.confirm({
      title: 'Deleting diagram',
      text: (
        <Fragment>
          Are you sure you want to delete this diagram from the project?
          {warnings.length > 0 && (
            <ul>
              {warnings.map((warning) => (
                <li key={warning}>{WARNINGS[warning]}</li>
              ))}
            </ul>
          )}
        </Fragment>
      ),
      confirmLabel: 'Delete diagram',
      isDangerous: true
    });

    if (confirmed) {
      fileService
        .destroy([diagram.id])
        .then(() => {
          notificationStore.showSuccess('Your diagram has been deleted.');
          trackingService.trackDeleteEntities(DEFAULT, 1);

          if (this.ofProcessApplication) {
            history.push(`/process-applications/${diagram.folder.id}`);
          } else if (this.ofFolder) {
            history.push(`/folders/${diagram.folder.id}`);
          } else {
            history.push(`/projects/${project.id}`);
          }
        })
        .catch(() => notificationStore.showError("Yikes! Couldn't remove your diagram. Please try again later."));
    }
  };

  uploadFiles = async (files) => {
    breadcrumbStore.toggleDropdownVisibility();

    if (files.length > 1) {
      return notificationStore.showError('You can only upload one single file to replace this diagram.');
    }

    const { diagram } = this.state;
    const { valid, invalid } = await validateFiles(files, [this.state.diagram.type]);
    const previousDiagram = { ...diagram };
    const previousAutosaveTimestamp = this.state.lastSaved;

    if (invalid.length > 0) {
      notificationStore.showError(
        `"${invalid[0].name}" is not a valid Zeebe ${this.state.diagram.type} diagram and can't be uploaded. Please choose another file.`
      );
    }

    if (valid.length > 0) {
      const isC7File = isCamunda7File(files[0]);
      if (isC7File) {
        this.#notifyC7Import(files[0]);
      } else {
        this.#notifyImport(files[0]);
      }

      // Create an "Autosaved" milestone if the diagram has changed since
      // the creation of the last milestone
      if (
        await milestoneStore.createAutosaved(previousDiagram, 'upload', {
          name: 'Autosaved during upload'
        })
      ) {
        notificationStore.showSuccess('The previous diagram content has been saved as a milestone.');
      }

      await this.importContent(files[0].content);
      await this.#upsertDiagramExecutionPlatformToC8();
      await this.saveContent();

      // The `saveContent` is throttled, and it doesn't return a value, so we need to check if the diagram was saved
      // by comparing the last saved timestamp with the previous one.
      const saveSucceed = this.state.lastSaved !== previousAutosaveTimestamp;

      if (saveSucceed) {
        let parentType = DEFAULT;
        if (this.#isDiagramOfProcessApplication(diagram)) {
          parentType = PROCESS_APPLICATION;
        } else if (this.#isDiagramOfFolder(diagram)) {
          parentType = FOLDER;
        }

        trackingService.trackCreateFile(diagram.id, 'upload', diagram.type, parentType, files[0].content);

        milestoneStore
          .create({
            file: diagram,
            origin: 'upload',
            name: `${files[0].name.replace(/(\.bpmn|\.xml)/, '')} (upload)`
          })
          .catch(() =>
            notificationStore.showError(
              'Yikes! There was a problem saving the previous diagram content as a milestone.'
            )
          );
      }
    }
  };

  #notifyImport(file) {
    notificationStore.showSuccess(`"${file.name}" is being uploaded to replace this diagram.`);
  }

  #notifyC7Import(file) {
    notificationStore.showSuccess(
      <>
        <span>
          "{file.name}" is a C7 resource and is being uploaded to replace this diagram. Some attributes might have
          changed.{' '}
          <a href="https://docs.camunda.io/docs/guides/migrating-from-camunda-7/" target="blank">
            Learn more
          </a>{' '}
          about migrating from Camunda 7.
        </span>
      </>,
      8000
    );
  }

  discardTemplateGuide(shouldUnsetTooltips = true, shouldShowError = true) {
    const diagram = this.state.diagram;

    fileService
      .update(diagram.id, {
        processTemplateId: null,
        revision: diagram.revision,
        originAppInstanceId: userStore.originAppInstanceId
      })
      .then(() => {
        if (shouldUnsetTooltips) {
          this.unsetTooltips();
        }
        this.setIsShowingTemplate(false);
      })
      .catch(async (error) => {
        if (!shouldShowError) {
          return;
        }

        if (error.status === 409) {
          if (this.isDraggingActive) {
            this.hasReceivedUpdateWhileDragging = true;
          } else {
            await this.reloadDiagram();
            this.notifyConflict();
          }
        } else {
          notificationStore.showError(FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION);
        }
      });
  }

  setHealthState = (healthState) => {
    this.healthState = {
      ...this.healthState,
      ...healthState
    };
  };

  get isBPMN() {
    return this.state.diagram?.type === BPMN;
  }

  get isDMN() {
    return this.state.diagram?.type === DMN;
  }

  get admins() {
    return this.state.project?.collaborators?.filter(({ permissionAccess }) => permissionAccess === 'ADMIN') || [];
  }

  get isAdminOrEditor() {
    return permission(this.state.project).is(['ADMIN', 'WRITE']);
  }

  get isCommenter() {
    return permission(this.state.project).is(['COMMENT']);
  }

  get isCommenterOrViewer() {
    return permission(this.state.project).is(['COMMENT', 'READ']);
  }

  get calledBy() {
    return this.state.diagram?.calledBy;
  }

  get isProcessTemplate() {
    return this.state.diagram?.templateTooltips;
  }

  get isValid() {
    return this.healthState.isValid && this.hasBeenValidated;
  }

  get hasBeenValidated() {
    return this.healthState.hasBeenValidated;
  }

  get isNotFound() {
    return this.healthState.isNotFound;
  }

  /**
   * Returns the validation problems of the current diagram.
   * @returns {{errors: Array, warnings: Array}} The validation problems.
   */
  get validationProblems() {
    return {
      errors: this.healthState.error ? [this.healthState.error] : [],
      warnings: this.healthState.warnings || []
    };
  }

  /**
   * Returns whether the current diagram is part of a process application or a folder.
   * @returns {boolean}
   */
  get ofProcessApplicationOrFolder() {
    return this.ofProcessApplication || this.ofFolder;
  }

  /**
   * Returns whether the current diagram is part of a process application.
   * @returns {boolean}
   */
  get ofProcessApplication() {
    return this.#isDiagramOfProcessApplication(this.state.diagram);
  }

  /**
   * Returns whether the current diagram is part of a folder.
   * @returns {boolean}
   */
  get ofFolder() {
    return this.#isDiagramOfFolder(this.state.diagram);
  }

  #isDiagramOfProcessApplication = (diagram) => {
    return Boolean(diagram?.folderId && diagram?.folderType === PROCESS_APPLICATION);
  };

  #isDiagramOfFolder = (diagram) => {
    return Boolean(diagram?.folderId && (diagram?.folderType === FOLDER || diagram?.folderType === FOLDER_DEFAULT));
  };
}

export default new CurrentDiagramStore();

const calculateIconScale = (viewboxScale) => {
  if (viewboxScale >= 0.6) {
    return 1.25;
  }
  if (viewboxScale >= 0.4) {
    return 2;
  }
  if (viewboxScale >= 0.2) {
    return 3;
  }
};

const permission = (project) => {
  return createPermission(project?.permissionAccess);
};
