/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, runInAction } from 'mobx';

import { breadcrumbStore, confirmActionStore, notificationStore, organizationStore, userStore } from 'stores';
import { projectService, tracingService, trackingService } from 'services';
import RealtimeChannel from 'utils/RealtimeChannel';
import history from 'utils/history';
import pluralize from 'utils/pluralize';
import getDeleteKey from 'utils/invitation/get-delete-key';
import { IMPORT_DIALOG_SOURCE } from 'utils/constants';

class HomeStore {
  projects = [];
  loading = true;
  permissionChannel = null;
  disposer = null;
  isCreatingProject = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Initializes the realtime channel and fetches the project data.
   */
  init() {
    if (userStore.isAuthenticated) {
      this.permissionChannel = new RealtimeChannel(`private-user-${userStore.userId}`, {
        'permission:add': this.handlePermissionAdded,
        'permission:remove': this.handlePermissionRemoved
      });
      this.permissionChannel.subscribe();

      this.fetchProjects();
    }
  }

  /**
   * Resets the realtime channel and store state.
   */
  reset() {
    if (userStore.isAuthenticated && this.permissionChannel) {
      this.permissionChannel.unsubscribe();
    }

    this.projects = [];
    this.loading = true;
  }

  /**
   * Fetches all projects in an organization and sets them to the store state.
   */
  async fetchProjects() {
    try {
      const includeDetails = true;
      const projects = await projectService.fetchByOrganizationId(
        organizationStore.currentOrganizationId,
        includeDetails
      );

      runInAction(() => (this.projects = projects));
    } catch (ex) {
      notificationStore.showError('Yikes! Could not fetch your projects. Please try again later.');
      tracingService.traceError(ex, 'Failed to fetch projects');
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  /**
   * Creates a new project.
   */
  async createProject() {
    runInAction(() => {
      this.isCreatingProject = true;
    });

    try {
      const newProject = await projectService.create({
        name: 'New Project',
        organizationId: organizationStore.currentOrganizationId
      });

      breadcrumbStore.toggleEditingFor('project');

      history.push(`/projects/${newProject.id}`);
      trackingService.trackCreateProject('button');
    } catch (ex) {
      notificationStore.showError('Yikes! Could not create the new project. Please try again later.');
      tracingService.traceError(ex, 'Failed to create project');
    } finally {
      runInAction(() => {
        this.isCreatingProject = false;
      });
    }
  }

  async createProjectFromImport(projectName = 'New Project') {
    runInAction(() => {
      this.isCreatingProject = true;
    });

    try {
      const newProject = await projectService.create({
        name: projectName,
        organizationId: organizationStore.currentOrganizationId
      });

      trackingService.trackCreateProject(IMPORT_DIALOG_SOURCE);

      return newProject;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not create the new project. Please try again later.');
      tracingService.traceError(ex, 'Failed to create project');
    } finally {
      runInAction(() => {
        this.isCreatingProject = false;
      });
    }
  }

  /**
   * Deletes a number of given projects.
   *
   * @param {Object|Object[]} projects The project or projects to delete.
   */
  async deleteProjects(projects) {
    if (!Array.isArray(projects)) {
      projects = [projects];
    }

    const confirmed = await confirmActionStore.confirm({
      title: `Deleting ${pluralize('project', projects.length)}`,
      text: `You're about to delete ${
        projects.length > 1
          ? `${projects.length} projects and their`
          : `the project "${projects[0]?.name?.value ?? projects[0]?.name}" and its`
      } files. Collaborators won't be able to access them afterwards.`,
      confirmLabel: `Delete ${pluralize('project', projects.length)}`,
      isDangerous: true
    });

    if (confirmed) {
      const projectIds = projects.map((project) => project.id);

      try {
        await projectService.destroyMany(projectIds);

        runInAction(() => {
          this.projects = this.projects.filter((project) => {
            return !projectIds.includes(project.id);
          });
        });

        notificationStore.showSuccess(
          `Your ${pluralize('project', projectIds.length)} ${projectIds.length == 1 ? 'has' : 'have'} been deleted.`
        );
      } catch (ex) {
        notificationStore.showError(
          `Yikes! Could not delete your ${pluralize('project', projectIds.length)}. Please try again later.`
        );
        tracingService.traceError(ex, 'Failed to delete projects');
      }
    }
  }

  /**
   * Removes the collaborator from a number of given projects.
   *
   * @param {Object|Object[]} projects The project or projects to remove the collaborator from.
   */
  async leaveProjects(projects) {
    if (!Array.isArray(projects)) {
      projects = [projects];
    }

    const confirmed = await confirmActionStore.confirm({
      title: `Leave ${pluralize('project', projects.length)}?`,
      text: `Are you sure you want to stop collaborating on the selected ${pluralize('project', projects.length)}?`,
      confirmLabel: `Leave ${pluralize('project', projects.length)}?`,
      isDangerous: true
    });

    if (confirmed) {
      try {
        const promises = projects.map((project) =>
          projectService.destroyCollaborator(
            project.id,
            getDeleteKey({
              email: userStore.userEmail,
              iamId: userStore.userAuthProviderId
            })
          )
        );

        await Promise.all(promises);

        runInAction(() => {
          this.projects = this.projects.filter((project) => {
            return !projects.find((leftProject) => leftProject.id === project.id);
          });
        });

        notificationStore.showSuccess(`You have left the ${pluralize('project', projects.length)}.`);
      } catch (ex) {
        notificationStore.showError(
          `Yikes! Something went wrong leaving the ${pluralize('project', projects.length)}. Please try again later.`
        );
        tracingService.traceError(ex, 'Failed to leave projects');
      }
    }
  }

  /**
   * Reloads the project list after receiving a realtime update.
   *
   * @param {Object} param
   * @param {String} [param.payload] The Socket payload, containing the new project.
   */
  handlePermissionAdded = ({ payload }) => {
    const { projectId } = JSON.parse(payload);
    if (!this.projects.find((project) => project.id === projectId)) {
      this.fetchProjects();
    }
  };

  /**
   * Removes a project from the project list after receiving a realtime update.
   *
   * @param {Object} param
   * @param {String} [param.payload] The Socket payload, containing the project.
   */
  handlePermissionRemoved = ({ payload }) => {
    const { projectIds, intent } = JSON.parse(payload);

    if (intent === 'remove') {
      const projectToRemove = this.projects.find((project) => project.id === projectIds[0]);

      if (projectToRemove) {
        this.projects.remove(projectToRemove);

        notificationStore.showSuccess(`Your access to "${projectToRemove.name}" has been removed.`);
      }
    }
  };
}

export default new HomeStore();
