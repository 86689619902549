/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

import reviewStore from 'App/Pages/ProcessApplicationVersions/features/review/ReviewStore';

/**
 * @param {string} versionId
 * @param {boolean} isVersionByCurrentUser
 * @param {boolean} hasElevatedOrganizationPermissions
 */
export default function useCanEditReview(versionId, isVersionByCurrentUser, hasElevatedOrganizationPermissions) {
  const [canEditReview, setCanEditReview] = useState(
    reviewStore.hasReviewBeenCompleted(versionId) && (!isVersionByCurrentUser || hasElevatedOrganizationPermissions)
  );

  useEffect(() => {
    return autorun(() => {
      setCanEditReview(
        reviewStore.hasReviewBeenCompleted(versionId) && (!isVersionByCurrentUser || hasElevatedOrganizationPermissions)
      );
    });
  }, [versionId, isVersionByCurrentUser, hasElevatedOrganizationPermissions]);

  return canEditReview;
}
