/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, ProgressBar } from '@carbon/react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { truncate } from 'lodash';
import moment from 'moment';

import { Dropdown, FormPreview, TargetSelector } from 'components';
import { commentsStore, currentDiagramStore, notificationStore } from 'stores';
import { selectElementAndFocusField } from 'App/Pages/Diagram/properties-panel-util';
import { fileService, folderService, tracingService, trackingService } from 'services';
import buildSlug from 'utils/buildSlug';
import {
  createOrUpdateExtensionElement,
  removeExtensionElement
} from 'utils/web-modeler-diagram-parser/extension-elements-util';
import * as LinkOverlayStyled from 'App/Pages/Diagram/LinkOverlay.styled';
import Tooltip from 'App/Pages/Diagram/Tooltip';

import formLinkStore from './FormLinkStore';
import * as Styled from './FormLinkOverlay.styled';

const Overlay = observer(({ element, readOnly }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextDetails, setContextDetails] = useState(null);
  const [linkedForms, setLinkedForms] = useState([]);
  const [formState, setFormState] = useState({
    isValidLink: false,
    isEmbedded: false,
    parsingFailed: false,
    content: null
  });
  const { project } = currentDiagramStore.state;
  const { isLoading } = formLinkStore;

  const modeler = currentDiagramStore.modeler;

  const contextPad = modeler.get('contextPad'),
    eventBus = modeler.get('eventBus');

  eventBus.on('contextPad.linkResource', (context) => {
    const { element, originalEvent } = context;

    if (contextPad.isOpen(element)) {
      handleClick(originalEvent);
    }
  });

  const getLinkedFormsAndStore = async (element) => {
    try {
      const forms = await formLinkStore.getExistingLinks(element);
      setLinkedForms(forms);
    } catch (ex) {
      notificationStore.showError('Yikes! Could not fetch linked forms. Please try again later.');
      tracingService.traceError(ex, 'Failed to fetch linked forms');
      setFormState((prevState) => ({ ...prevState, parsingFailed: true }));
    }
  };

  const getProjectDetails = async () => {
    setContextDetails(await currentDiagramStore.updateProject(project.id));
  };

  const getBrowsableContextDetails = async () => {
    const context = await folderService.fetchById(currentDiagramStore.state.diagram.folderId);
    setContextDetails(context);
  };

  const parseEmbededdFormAndSetState = ({ isValidLink, isEmbedded }) => {
    const trimmedFormBody = formLinkStore.getEmbeddedForm(element)?.trim();

    try {
      const parsedContent = JSON.parse(trimmedFormBody);
      setFormState((prevState) => ({
        ...prevState,
        isValidLink,
        isEmbedded,
        content: parsedContent
      }));
    } catch (e) {
      tracingService.traceError(e, 'Failed to parse form schema');
      setFormState((prevState) => ({ ...prevState, parsingFailed: true }));
      notificationStore.showError('Failed to parse form schema.');
    }
  };

  useEffect(() => {
    let isValidLink = false;
    let isEmbedded = false;

    if (element) {
      isValidLink = formLinkStore.isElementLinked(element);
      isEmbedded = formLinkStore.hasEmbeddedForm(element);
    }

    if (anchorEl) {
      if (isValidLink) {
        if (isEmbedded) {
          parseEmbededdFormAndSetState({ isValidLink, isEmbedded });
        } else {
          getLinkedFormsAndStore(element);
        }
      } else if (currentDiagramStore.ofProcessApplicationOrFolder) {
        getBrowsableContextDetails();
      } else {
        getProjectDetails();
      }
    } else {
      setLinkedForms([]);
      setFormState({ isValidLink, isEmbedded, content: null });
    }
  }, [anchorEl, element]);

  const trackResourceLinking = (action) => {
    trackingService.trackResourceLinking({
      action,
      resource: 'form',
      from: 'BPMN'
    });
  };

  const linkForm = async (form) => {
    const { content } = await fileService.fetchById(form.id);

    try {
      const form = JSON.parse(content);

      const { id: formId } = form;

      createOrUpdateExtensionElement(element, 'zeebe:FormDefinition', { formId }, modeler);

      focusDecisionIdField();

      notificationStore.showSuccess('Form has been successfully linked.');

      trackResourceLinking('link');

      setAnchorEl(null);
    } catch (ex) {
      tracingService.traceError(ex, 'Failed to link form');

      notificationStore.showError('Failed to link form.');
    }
  };

  const unlinkForm = async () => {
    removeExtensionElement(element, 'zeebe:FormDefinition', modeler);

    focusDecisionIdField();

    notificationStore.showSuccess('Form has been unlinked.');

    trackResourceLinking('unlink');

    setAnchorEl(null);
  };

  const focusDecisionIdField = async () => {
    // TODO(philippfromme): layout should be kept in a separate store
    commentsStore.makePropertiesVisible();
    await selectElementAndFocusField({
      modeler: currentDiagramStore.modeler,
      element: element,
      groupId: 'group-form',
      fieldId: '#bio-properties-panel-formId'
    });
  };

  const openForm = async () => {
    if (linkedForms?.length === 1) {
      history.push(`/forms/${buildSlug(linkedForms[0].fileId, linkedForms[0].fileName)}`);
    }
  };

  const handleClick = (event) => {
    // directEditing and popupMenu are not available in navigated viewer (used in read-only mode)
    currentDiagramStore.modeler.get('directEditing', false)?.complete();
    currentDiagramStore.modeler.get('popupMenu', false)?.close();

    if (!anchorEl) {
      trackResourceLinking('openMenu');
    }

    let target = event.delegateTarget || event.target;

    if (target.closest('.djs-context-pad')) {
      target = target.closest('.djs-context-pad');
    }

    setAnchorEl(anchorEl ? null : target);
  };

  const canShowActions = !readOnly || !formState.isEmbedded;
  const hasOnlyOneForm = formState.content || (linkedForms?.length === 1 && linkedForms[0].content);
  const hasMultipleForms = linkedForms?.length > 1;
  const hasLinkedFormButNoFiles = formState.isValidLink && !formState.isEmbedded && linkedForms?.length === 0;
  const formName = linkedForms?.[0]?.name;
  const formContent = formState.content || linkedForms?.[0]?.content;
  const formLastUpdate = linkedForms?.[0]?.lastUpdate;
  const formPreviewTitle = hasLinkedFormButNoFiles || hasMultipleForms ? 'Linked form' : formName || 'Form Preview';

  return (
    <LinkOverlayStyled.Container>
      <Tooltip label="Link form">
        <LinkOverlayStyled.LinkButton
          data-test="form-link-icon"
          role="button"
          aria-label="Link form"
          onClick={handleClick}
          title={`${formState.isValidLink ? 'Click to see the form configuration' : 'Click to add a form'}`}
        />
      </Tooltip>

      {formState.isValidLink ? (
        <Dropdown
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          width={360}
          align="left"
          noMargin
          noPadding
        >
          <Dropdown.Title noPadding>
            {formPreviewTitle}{' '}
            {formLastUpdate && (
              <Styled.FormLastUpdated>
                Last updated on{' '}
                <span title={moment(formLastUpdate).calendar()}>{moment(formLastUpdate).format("MMM Do 'YY")}</span>
              </Styled.FormLastUpdated>
            )}
          </Dropdown.Title>

          {isLoading ? (
            <Styled.FormBody>
              <ProgressBar label="Loading" helperText="Fetching linked forms" />
            </Styled.FormBody>
          ) : (
            <>
              {formState.parsingFailed ? (
                <Styled.FormBody>Form schema invalid</Styled.FormBody>
              ) : (
                <>
                  {hasLinkedFormButNoFiles && (
                    <>
                      <LinkOverlayStyled.InfoResourceId>
                        {formLinkStore.getLinkedFormId(element)}
                      </LinkOverlayStyled.InfoResourceId>
                      <LinkOverlayStyled.InfoMessage>
                        A form with this ID could not be found in the current project.
                      </LinkOverlayStyled.InfoMessage>
                    </>
                  )}

                  {hasMultipleForms && (
                    <>
                      <LinkOverlayStyled.LinkContainer>
                        <ul>
                          {linkedForms?.map((form) => (
                            <li key={form.id}>
                              <LinkOverlayStyled.Link
                                data-test={`linked-diagram-${form.name}`}
                                onClick={() => {
                                  history.push(`/forms/${buildSlug(form.id, form.name)}`);
                                }}
                              >
                                {form.name}
                              </LinkOverlayStyled.Link>
                            </li>
                          ))}
                        </ul>
                      </LinkOverlayStyled.LinkContainer>

                      <LinkOverlayStyled.InfoMessage>
                        There are {linkedForms.length} forms with the same id:{' '}
                        <span title={linkedForms[0].formId}>{truncate(linkedForms[0].formId, { length: 20 })}</span>.
                        Please consider updating the forms to have unique identifiers.
                      </LinkOverlayStyled.InfoMessage>
                    </>
                  )}
                  {hasOnlyOneForm && formContent && <FormPreview formDefinition={formContent} scale={0.8} readOnly />}
                </>
              )}
            </>
          )}

          {canShowActions && (
            <LinkOverlayStyled.Footer>
              <LinkOverlayStyled.FooterActions>
                {!formState.isEmbedded && (
                  <>
                    {!readOnly && (
                      <Button kind="danger--ghost" size="sm" onClick={unlinkForm}>
                        Unlink
                      </Button>
                    )}

                    {hasOnlyOneForm && (
                      <Button kind="ghost" size="sm" onClick={openForm}>
                        Open in form editor
                      </Button>
                    )}
                  </>
                )}

                {formState.isEmbedded && !readOnly && (
                  <Button kind="danger--ghost" size="sm" onClick={unlinkForm}>
                    Remove form
                  </Button>
                )}
              </LinkOverlayStyled.FooterActions>
            </LinkOverlayStyled.Footer>
          )}
        </Dropdown>
      ) : (
        <>
          {contextDetails && (
            <TargetSelector
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              startingPoint={contextDetails}
              onSubmit={linkForm}
              action="form-link"
              showFiles
              description="Add form"
              align="bottom"
            />
          )}
        </>
      )}
    </LinkOverlayStyled.Container>
  );
});

const FormLinkOverlay = ({ ...props }) => {
  const selector = `.djs-overlays[data-container-id="${props.element?.id}"] .form-link-menu`;
  const container = document.querySelector(selector);

  if (!container) {
    console.warn('No container found for FormLinkOverlay');
    return null;
  }

  return ReactDOM.createPortal(<Overlay {...props} />, container);
};

export default FormLinkOverlay;
