/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { CheckmarkFilled, ErrorFilled } from '@carbon/icons-react';

export const SummaryContainer = styled.div`
  .meter-chart-container {
    width: 380px;
    height: 60px;
    .meter-chart-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .meter-chart-percentage-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
    }
  }
`;

export const GreenCheckmarkFilled = styled(CheckmarkFilled)`
  fill: var(--cds-support-success, #24a148);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;
export const RedErrorFilled = styled(ErrorFilled)`
  fill: var(--cds-support-error, #da1e28);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;
export const StatusOverviewContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 32px;
`;
