/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

/**
 * @typedef {object} IdpConnectorTemplateImportDto
 * @property {string} versionName - The name of the published version.
 * @property {string} versionDescription - The description of the published version.
 * @property {string} llmModelId - The LLM model ID.
 */

/**
 * @typedef {object} IdpExtractionFieldImportDto
 * @property {string} name - The name of the extraction field.
 * @property {string} type - The extraction field type (STRING/NUMBER/BOOLEAN).
 * @property {string} [llmPromptDescription] - The prompt description for LLM.
 */

/**
 * @typedef {object} IdpProjectImportDto
 * @property {string} name - Name of the project.
 * @property {string} [description] - Description of the project.
 * @property {string} type - Project type (EXTRACTION/DOCUMENT_AUTOMATION).
 * @property {string} extractionMethod - Extraction method (FORM/UNSTRUCTURED_DATA).
 * @property {IdpExtractionFieldImportDto[]} [idpExtractionFields] - List of extraction fields.
 * @property {IdpConnectorTemplateImportDto} [publishedConnectorTemplate] - Latest published IDP project version
 */

/**
 * @typedef {object} IdpApplicationImportDto
 * @property {string} name - Application name.
 * @property {string} clusterId - Associated cluster ID.
 * @property {IdpProjectImportDto[]} [idpProjects] - Projects belonging to this application.
 */

/**
 * @typedef {object} IdpApplicationsImportDto
 * @property {string} targetProjectId - The ID of the project where applications should be imported.
 * @property {string} [targetFolderId] - The ID of the folder where applications should be imported.
 * @property {IdpApplicationImportDto[]} idpApplications - A list of applications (and their projects/fields) to be imported.
 */

class IdpApplicationService extends Service {
  constructor() {
    super();
  }

  /**
   * Creates a new IDP application in the given project, or in the given folder.
   *
   * @param {Object} params
   * @param {String} [params.name] the name of the IDP application
   * @param {String} [params.projectId] the project id
   * @param {String} [params.folderId] the folder id
   * @param {String} [params.clusterId] the cluster that can be set as default in the context of this IDP application
   * @return {Promise}
   */
  createIdpApplication({ name, projectId, folderId, clusterId }) {
    const payload = {
      name,
      projectId,
      folderId,
      clusterId
    };
    return this.post(`/internal-api/idp-applications`, payload);
  }

  /**
   * Returns the details related to the given IDP application
   *
   * @param {String} idpApplicationId the IDP application id
   * @return {Promise}
   */
  fetchIdpApplication(idpApplicationId) {
    return this.get(`/internal-api/idp-applications/${idpApplicationId}`);
  }

  /**
   * Updates the name/cluster id for the given IDP application
   *
   * @param {String} idpApplicationId the IDP application id
   * @param {Object} payload JSON object of the payload
   * @param {String} [payload.name] the new name for the IDP application
   * @param {String} [payload.clusterId] the new cluster id for the IDP application
   * @return {Promise}
   */
  updateIdpApplication(idpApplicationId, payload) {
    return this.patch(`/internal-api/idp-applications/${idpApplicationId}`, payload);
  }

  /**
   * Removes an IDP application.
   *
   * @param {String} idpApplicationId the IDP application id
   * @returns {Promise}
   */
  deleteIdpApplication(idpApplicationId) {
    return this.delete(`/internal-api/idp-applications/${idpApplicationId}`);
  }

  /**
   * Moves IDP applications into a different project or folder.
   *
   * @param {String} targetProjectId Target project ID
   * @param {String} targetFolderId Target folder ID, if given.
   * @param {String[]} idpApplicationIds An array of IDP application IDs.
   * @return {Promise}
   */
  moveIdpApplications(idpApplicationIds, targetProjectId, targetFolderId) {
    const payload = {
      targetProjectId,
      targetFolderId,
      idpApplicationIds
    };
    return this.post(`/internal-api/idp-applications/move`, payload);
  }

  /**
   * Import IDP applications.
   *
   * @param {IdpApplicationsImportDto} payload - The import payload
   * @returns {Promise} A promise that resolves with the server response.
   */
  importIdpApplications(payload) {
    return this.post(`/internal-api/idp-applications/import`, payload);
  }

  /**
   * Exports an IDP application by its ID.
   *
   * @param {string} idpApplicationId - The ID of the IDP application to export.
   * @returns {Promise<IdpApplicationExportDto>} A promise that resolves to the exported application data.
   *
   * @typedef {Object} IdpApplicationExportDto
   * @property {string} name - The name of the IDP application.
   * @property {string} clusterId - The cluster ID associated with the application.
   * @property {IdpProjectExportDto[]} idpProjects - A list of IDP projects belonging to this application.
   *
   * @typedef {Object} IdpProjectExportDto
   * @property {string} name - The name of the project.
   * @property {string} description - A brief description of the project.
   * @property {string} type - The type of the project. (EXTRACTION/DOCUMENT_AUTOMATION)
   * @property {string} extractionMethod - The extraction method used for this project. (FORM/UNSTRUCTURED_DATA)
   * @property {IdpExtractionFieldExportDto[]} idpExtractionFields - A list of extraction fields defined for this project.
   * @property {IdpConnectorTemplateExportDto} [publishedConnectorTemplate] - The published connector template associated with this project.
   *
   * @typedef {Object} IdpExtractionFieldExportDto
   * @property {string} name - The name of the extraction field.
   * @property {string} type - The type of the extraction field. (STRING/NUMBER/BOOLEAN)
   * @property {string} llmPromptDescription - A description used for LLM prompts.
   *
   * @typedef {Object} IdpConnectorTemplateExportDto
   * @property {string} versionName - The version name of the connector template.
   * @property {string} versionDescription - A short description of the connector template version.
   * @property {string} llmModelId - The LLM model ID the connector template references.
   */
  exportIdpApplication(idpApplicationId) {
    return this.get(`/internal-api/idp-applications/${idpApplicationId}/export`);
  }
}

export default new IdpApplicationService();
