/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from 'components';
import { userStore } from 'stores';
import { notificationStore } from 'components/NotificationSystem';
import restoreService from 'App/Pages/ProcessApplicationVersions/features/restore/RestoreService';

function RestoreListItem({ versionId, reloadVersions, closeVersionMenuDropdown }) {
  return (
    // @ts-expect-error TS2554
    <Dropdown.ListItem
      onClick={async () => {
        try {
          closeVersionMenuDropdown();
          await restoreService.restoreProcessApplicationVersion(versionId, userStore.originAppInstanceId);
          notificationStore.showSuccess('Process application version restored.');
          reloadVersions();
        } catch {
          notificationStore.showError('Failed to restore the process application version.');
        }
      }}
    >
      Restore as latest
    </Dropdown.ListItem>
  );
}

export default RestoreListItem;
