/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from '@carbon/react';
import { C3ClusterTag } from '@camunda/camunda-composite-components';

import PlayPerformanceNotification from 'App/Pages/Diagram/Deployment/PlayPerformanceNotification';
import PlayBillingNotification from 'App/Pages/Diagram/Deployment/PlayBillingNotification';
import { isPlayMode } from 'App/Pages/Diagram/Deployment/executeModal/modeUtils';
import capitalize from 'utils/capitalize';
import getClusterGeneration from 'utils/cluster/get-cluster-generation';

import * as Styled from './DefineStagesModal.styled';
import { defineStagesModalStore } from './';

export default function StageClusterSection({
  clusters,
  selectedClusterId,
  onClusterSelect,
  stageName,
  tagName,
  tooltipDefinition
}) {
  const selectClusterLabel = `[Select a ${stageName} cluster]`;
  const dropdownItems = [{ uuid: null, name: selectClusterLabel }, ...clusters];
  const selectedItem = dropdownItems.find((cluster) => selectedClusterId === cluster.uuid) || dropdownItems[0];

  return (
    <section data-test={`section-${stageName}`}>
      <Styled.NameWithTag>
        <Styled.DefinitionTooltip align="right" openOnHover definition={tooltipDefinition}>
          {capitalize(stageName)}
        </Styled.DefinitionTooltip>
        <C3ClusterTag stage={tagName} />
      </Styled.NameWithTag>
      {clusters?.length ? (
        <Dropdown
          id={`dropdown-${stageName}-cluster`}
          titleText={false}
          onChange={({ selectedItem }) => onClusterSelect(selectedItem?.uuid)}
          selectedItem={selectedItem}
          label={selectClusterLabel}
          items={dropdownItems}
          itemToString={(cluster) => (cluster ? `${cluster.name} ${getClusterGeneration(cluster)}` : '')}
        />
      ) : (
        <Styled.NoClusterText>No {stageName} cluster available</Styled.NoClusterText>
      )}
      {stageName === 'development' &&
        isPlayMode(defineStagesModalStore.executeModalMode) &&
        selectedItem !== undefined &&
        selectedItem.uuid !== null && (
          <>
            {!selectedItem.playMode && (
              <PlayPerformanceNotification
                shouldLinkToConsole
                canSelectAnotherCluster
                hasTopMargin
                selectedClusterId={selectedItem.uuid}
              />
            )}
            <PlayBillingNotification hasTopMargin />
          </>
        )}
    </section>
  );
}
