/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Button, Tag } from '@carbon/react';
import { CaretLeft, CaretRight } from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { processApplicationStore } from 'stores';

import fileSwitcherStore from './FileSwitcherStore';
import * as Styled from './FileSwitcher.styled';

function FileSwitcher({ fileId }) {
  const history = useHistory();

  const files = processApplicationStore?.processApplication?.files || [];
  const { selectedFileIndex } = fileSwitcherStore;

  const fileName = files[selectedFileIndex]?.name;

  const isMainProcess = files[selectedFileIndex]?.id === processApplicationStore?.processApplication?.mainProcessFileId;

  useEffect(() => {
    const index = files.findIndex((file) => file.id === fileId);
    fileSwitcherStore.setSelectedFileIndex(index);
  }, []);

  const openMilestoneHistory = (index) => {
    const file = files[index];
    if (file.type === 'BPMN' || file.type === 'DMN') {
      history.push(`/diagrams/${file.id}/milestones`);
    } else if (file.type === 'FORM') {
      history.push(`/forms/${file.id}/milestones`);
    } else if (file.type === 'CONNECTOR_TEMPLATE') {
      history.push(`/connector-templates/${file.id}/versions`);
    }
  };

  const selectFile = (index) => {
    fileSwitcherStore.setSelectedFileIndex(index);
    openMilestoneHistory(index);
  };

  const selectNextFile = () => {
    selectFile(selectedFileIndex + 1);
  };

  const selectPreviousFile = () => {
    selectFile(selectedFileIndex - 1);
  };

  return (
    <Styled.Container data-test="file-switcher">
      <Styled.Content>
        <Styled.FileName>
          {fileName}
          {isMainProcess && <Tag size="sm">Main process</Tag>}
        </Styled.FileName>
        <Styled.FilesList>
          <Button
            size="sm"
            kind="ghost"
            aria-label="Previous file"
            renderIcon={CaretLeft}
            iconDescription="Previous file"
            tooltipPosition="bottom"
            hasIconOnly
            onClick={selectPreviousFile}
            disabled={selectedFileIndex === 0}
          />
          <Styled.SelectedFile>{`${selectedFileIndex + 1}/${files.length} files`}</Styled.SelectedFile>
          <Button
            size="sm"
            kind="ghost"
            aria-label="Next file"
            renderIcon={CaretRight}
            iconDescription="Next file"
            tooltipPosition="bottom"
            hasIconOnly
            onClick={selectNextFile}
            disabled={selectedFileIndex + 1 === files.length}
          />
        </Styled.FilesList>
      </Styled.Content>
    </Styled.Container>
  );
}

export default observer(FileSwitcher);
