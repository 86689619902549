/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';

import { organizationStore } from 'stores';
import config from 'utils/config';
import { TARGET_ENV } from 'utils/constants';

export default function WithStatsig({ children, isEnabled, hashedUserId }) {
  if (!isEnabled) {
    return children;
  } else {
    const { currentOrganizationId, currentOrganizationInfo } = organizationStore;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { client } = useClientAsyncInit(
      config.statsig.sdkKey,
      {
        userID: hashedUserId,
        customIDs: {
          orgId: currentOrganizationId
        },
        custom: {
          orgId: currentOrganizationId,
          salesPlan: currentOrganizationInfo?.salesPlan.type
        }
      },
      {
        environment: { tier: getTier() },
        networkConfig: { api: `${config.modelerUrl}/statsig` }
      }
    );

    return <StatsigProvider client={client}>{children}</StatsigProvider>;
  }
}

const getTier = () => {
  let tier = 'development';
  if (TARGET_ENV.INT === config.targetEnv) {
    tier = 'staging';
  } else if (TARGET_ENV.PROD === config.targetEnv) {
    tier = 'production';
  }
  return tier;
};
