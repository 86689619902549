/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { currentDiagramStore, processApplicationStore } from 'stores';
import { projectService } from 'services';
import { BPMN, DMN, FORM } from 'utils/constants';

export class ResourcesProvider {
  constructor(eventBus, resourceLoader) {
    this.eventBus = eventBus;
    this.resourceLoader = resourceLoader;

    this._resources = [];

    resourceLoader.register(this);

    eventBus.on('diagram.init', () => {
      this.init();
    });
  }

  async init() {
    const { project } = currentDiagramStore.state;

    const projectFiles = await this.fetchProjectFiles(project.id);
    const processApplicationFiles = processApplicationStore?.processApplication?.files || [];

    const files = projectFiles.concat(processApplicationFiles);

    this._resources = files
      .map((file) => {
        switch (file.type) {
          case BPMN:
            return handleBpmnFile(file);
          case DMN:
            return handleDmnFile(file);
          case FORM:
            return handleFormFile(file);
          default:
            return null;
        }
      })
      .filter(Boolean)
      .flat();

    this.resourceLoader.reload();
  }

  getResources() {
    return this._resources;
  }

  /**
   * @param {string} projectId
   */
  async fetchProjectFiles(projectId) {
    try {
      const { files } = await projectService.fetchById({ projectId, includeFiles: true });

      return files;
    } catch (error) {
      console.error('Failed to fetch project files', error);
      return [];
    }
  }
}

ResourcesProvider.$inject = ['eventBus', 'resources.resourceLoader'];

/**
 * @param {{ name: string, processId: string }} file
 */
function handleBpmnFile(file) {
  return {
    name: file.name,
    type: 'bpmnProcess',
    processId: file.processId
  };
}

/**
 * @param {{ decisions: Array<{ name: string, id: string }> }} file
 */
function handleDmnFile(file) {
  return file?.decisions?.map((decision) => ({
    name: decision.name?.trim() || decision.id,
    type: 'dmnDecision',
    decisionId: decision.id
  }));
}

/**
 * @param {{ name: string, formId: string }} file
 */
function handleFormFile(file) {
  return {
    name: file.name,
    type: 'form',
    formId: file.formId
  };
}
