/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

export default function useFocusTrap({ open, ref, selectorPrimaryFocus }) {
  useEffect(() => {
    if (open) {
      if (selectorPrimaryFocus) {
        const element = ref.current.querySelector(selectorPrimaryFocus);

        if (element) {
          element.focus();

          return;
        }
      }

      const elements = getFocusable(ref);

      if (elements.length > 0) {
        elements[0].focus();
      }
    }
  }, [open, selectorPrimaryFocus]);
}

const focusable = ['select', 'input', 'textarea', 'button', 'a'];

export const getFocusable = (ref) => {
  if (ref.current) {
    return Array.from(ref.current.querySelectorAll(focusable.join(', '))).filter((element) => !element.disabled);
  }

  return [];
};
