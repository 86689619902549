/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable, action, runInAction, makeObservable } from 'mobx';

import Form, { TextField } from 'components/Form';
import { IconButton, Dialog, Typography } from 'primitives';
import { Hide, Show } from 'icons';
import { shareStore } from 'stores';
import { PASSWORD_VARCHAR_MAX } from 'utils/constants';

class PasswordControl extends Component {
  isPasswordVisible = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      isPasswordVisible: observable,
      togglePasswordVisibility: action
    });
  }

  handleSubmit = async (formData) => {
    await shareStore.saveSharePassword(shareStore.state.diagram.shareId, formData.password);

    runInAction(() => {
      shareStore.state.diagram.passwordProtected = true;
    });

    this.props.onCreatePassword();
    this.props.onClose();
  };

  togglePasswordVisibility = (evt) => {
    evt.preventDefault();

    this.isPasswordVisible = !this.isPasswordVisible;
  };

  render() {
    return (
      <Fragment>
        <Dialog.Header>
          <Dialog.Title data-test="share-title">Password protect</Dialog.Title>
        </Dialog.Header>
        <Dialog.Content>
          <Typography>
            All instances of the share link will require password input to access, regardless of when they were shared.
          </Typography>

          <Form buttonText="Save password" onCancel={this.props.onClose} onSubmit={this.handleSubmit}>
            <TextField
              label="Enter a password"
              type={this.isPasswordVisible ? 'text' : 'password'}
              name="password"
              rules="required"
              errorMessage="Please enter a password."
              data-test="share-password-input"
              maxLength={PASSWORD_VARCHAR_MAX}
              addon={
                <IconButton
                  aria-label="Toggle password visibility"
                  data-test="share-password-input-adornment"
                  onClick={this.togglePasswordVisibility}
                >
                  {this.isPasswordVisible ? <Show height="24" /> : <Hide height="24" />}
                </IconButton>
              }
            />
          </Form>
        </Dialog.Content>
      </Fragment>
    );
  }
}

export default observer(PasswordControl);
