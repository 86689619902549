/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { notificationStore } from 'components/NotificationSystem';
import { processApplicationService } from 'services';

/**
 * @param {Object} history
 * @param {string} processApplicationId
 * @param {string} versionName
 */
function showNotification(history, processApplicationId, versionName) {
  //Todo @yT0n1: replace with https://github.com/camunda/web-modeler/issues/12021
  const disposer = { dispose: null };
  const notification = notificationStore.info(
    {
      title: 'Review requested',
      content: versionName ? `Version "${versionName}" is ready for review.` : 'A version is ready for review.'
    },
    {
      shouldPersist: true,
      action: {
        //@ts-expect-error TS2769
        label: 'Review',
        onClick: () => {
          notificationStore.unsetActiveNotification();
          disposer.dispose?.();
          history.push(`/process-applications/${processApplicationId}/versions`);
        }
      }
    }
  );
  disposer.dispose = () => notificationStore.disposeNotification(notification.id, 'info', true);
  return () => disposer.dispose?.();
}

/**
 * @param {Object} params
 * @param {string} params.processApplicationId
 * @param {Object} params.history
 * @param {boolean} params.canSeeVersions - Whether the user can see process app versions.
 * @param {string} params.processApplicationVersionId
 * @param {string} params.userId
 * @param {string} params.reviewRequestedByUserId
 * @param {boolean} params.hasElevatedOrganizationPermissions
 */
function useReviewRequestedNotification({
  processApplicationId,
  canSeeVersions,
  history,
  userId,
  reviewRequestedByUserId,
  processApplicationVersionId,
  hasElevatedOrganizationPermissions
}) {
  const isReviewRequested = Boolean(processApplicationVersionId);
  const version = useProcessApplicationVersion(processApplicationVersionId);
  // Currently no version creator is attached to the version. Todo: @yT0n1 opens a ticket to clean this up
  const isVersionByCurrentUser = version?.milestones[0]?.authorId === userId;
  const reviewRequestedByThisUser = userId === reviewRequestedByUserId;
  const canUserReview = !isVersionByCurrentUser || hasElevatedOrganizationPermissions;
  const versionName = version?.name;
  useEffect(() => {
    if (!canSeeVersions || !isReviewRequested || !canUserReview || reviewRequestedByThisUser || !version) {
      return;
    }
    return showNotification(history, processApplicationId, versionName);
  }, [
    isReviewRequested,
    history,
    canUserReview,
    canSeeVersions,
    processApplicationVersionId,
    processApplicationId,
    versionName
  ]);
}

export default useReviewRequestedNotification;

/**
 *
 * @param {string} versionId
 * @returns {import('services/ProcessApplicationService.common').ProcessApplicationVersion|null}
 */
function useProcessApplicationVersion(versionId) {
  const [version, setVersion] = useState(null);

  useEffect(() => {
    if (!versionId) {
      return;
    }
    (async () => {
      try {
        const version = await processApplicationService.fetchProcessApplicationVersion(versionId);
        setVersion(version);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [versionId]);

  return version;
}
