/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { Dialog, Button } from 'primitives';
import { confirmActionStore } from 'stores';

import * as Styled from './ConfirmActionDialog.styled';

const ConfirmActionDialog = () => {
  const { title, text, confirmLabel, isDangerous, isFileDeletion, hideCancel } = confirmActionStore.state.dialogProps;

  if (!confirmActionStore.state.isDialogVisible) {
    return null;
  }

  return (
    // @ts-expect-error TS2739
    <Dialog
      open
      // @ts-expect-error TS2339
      onClose={confirmActionStore.hideDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-test="confirm-dialog"
      maxWidth={650}
    >
      <Dialog.Header>
        <Dialog.Title data-test="dialog-title" id="alert-dialog-title">
          {title}
        </Dialog.Title>
      </Dialog.Header>

      <Dialog.Content data-test="dialog-content">
        {toJS(text)}

        {isDangerous && (
          <Styled.Warning
            titleId="unsafe-action-warning"
            title={
              isFileDeletion
                ? 'Warning: The file(s) and any previously saved versions will not be recoverable.'
                : 'Warning: This action cannot be undone'
            }
            kind="warning"
            lowContrast
          />
        )}
      </Dialog.Content>

      <Dialog.Footer>
        {!hideCancel && (
          // @ts-expect-error TS2322
          <Button onClick={confirmActionStore.cancel} variant="secondary">
            Cancel
          </Button>
        )}
        {/* @ts-expect-error TS2322 */}
        <Button
          onClick={confirmActionStore.accept}
          variant={isDangerous ? 'danger-primary' : 'primary'}
          data-test="confirm-button"
        >
          {confirmLabel}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default observer(ConfirmActionDialog);
