/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Tag } from '@carbon/react';

import reviewStore from 'App/Pages/ProcessApplicationVersions/features/review/ReviewStore';
import RequestReview from 'App/Pages/ProcessApplicationVersions/features/review/request-review/RequestReview';
import PerformReview from 'App/Pages/ProcessApplicationVersions/features/review/perform-review/PerformReview';
import ReviewStatus from 'App/Pages/ProcessApplicationVersions/features/review/status/ReviewStatus';
import { REVIEW_STATUS } from 'App/Pages/ProcessApplicationVersions/features/review/constants';

/**
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplicationVersionListEntryDto} ProcessApplicationVersionListEntryDto
 */

/**
 * Component to display the review section for a version of a process application.
 *
 * @param {Object} props
 * @param {boolean} props.isNewestVersion - Whether this version is the newest milestone.
 * @param {string} props.versionId
 * @param {string} props.versionCreatorUserId
 * @param {string} props.currentUserId
 * @param {boolean} props.hasElevatedOrganizationPermissions
 * @returns {JSX.Element|null} The review component or null if no review actions are available.
 */
function Review({
  isNewestVersion,
  versionId,
  versionCreatorUserId,
  currentUserId,
  hasElevatedOrganizationPermissions
}) {
  const isVersionByCurrentUser = versionCreatorUserId === currentUserId;
  const review = reviewStore.getReviewFor(versionId);
  const isReviewByCurrentUser = review?.createdBy?.id === currentUserId;
  const reviewRequest = reviewStore.getReviewRequestFor(versionId);

  const canRequestReview = isNewestVersion && !reviewRequest && !review;
  const shouldShowReviewInProgress = isVersionByCurrentUser && !hasElevatedOrganizationPermissions && reviewRequest;
  const canPerformReview = !shouldShowReviewInProgress && reviewRequest && !review;
  const shouldShowStatus =
    review?.status === REVIEW_STATUS.APPROVED || review?.status === REVIEW_STATUS.CHANGES_REQUESTED;

  if (!canRequestReview && !shouldShowStatus && !canPerformReview && !shouldShowReviewInProgress) {
    return null;
  }

  return (
    <Row>
      {canRequestReview && <RequestReview versionId={versionId} />}
      {canPerformReview && <PerformReview versionId={versionId} isAdminSelfReview={isVersionByCurrentUser} />}
      {shouldShowStatus && (
        <ReviewStatus
          reviewStatus={review.status}
          comment={review.comment}
          isReviewerCurrentUser={isReviewByCurrentUser}
          reviewDate={review.created}
          reviewerName={review.createdBy.name}
        />
      )}
      {shouldShowReviewInProgress && (
        <Tag size="sm" type="gray">
          Review requested
        </Tag>
      )}
    </Row>
  );
}

const Row = styled.section`
  margin-left: 15px;
  margin-top: var(--cds-spacing-02, 4px);
`;

export default observer(Review);
