/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autorun } from 'mobx';

import BaseDedicatedModesStore, { MODES_INDEXES } from './DedicatedModesStore.common';

class DedicatedModesStore extends BaseDedicatedModesStore {
  #storeObserverDisposer = null;

  constructor() {
    super();
  }

  init(isPlayC8Enabled) {
    super.init(isPlayC8Enabled);
    this.#storeObserverDisposer = autorun(() => {
      if (this.userCanAccessPlayMode) {
        this.allowPlayMode();
      } else {
        this.disallowPlayMode();
      }
    });
  }

  reset() {
    super.reset();

    this.#storeObserverDisposer?.();
  }
}

export default new DedicatedModesStore();
export { MODES_INDEXES };
