/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autorun, observe } from 'mobx';

import { currentDiagramStore } from 'stores';
import tokenSimulationStore from 'App/Pages/Diagram/BpmnJSExtensions/tokenSimulationExtension/TokenSimulationStore';

let shouldResetGuideTooltips = false;
let overlayIds = [];
let currentDiagramStoreDisposer;
let tokenSimulationDisposer;
let lastVisibleTooltip;

const OVERLAY_TYPE = 'template-guide';
const diagramContainerClassName = 'bjs-container';
const tooltipTriggerClassName = 'template-guide-trigger';
const tooltipClassName = 'template-guide-tooltip';
const tooltipDismissClassName = 'template-guide-dismiss';
const foregroundTooltipClassName = 'foreground';
const djsOverlayClassName = 'djs-overlay';

const triggerPosition = {
  top: -12,
  left: -12
};
const triggerShow = {
  minZoom: 0.5,
  maxZoom: 1.5
};
const triggerScale = {
  min: 1,
  max: 1
};

export default function TemplateExtension(overlays, eventBus) {
  eventBus.on('import.done', () => handleImportDone(overlays));
  eventBus.on('canvas.init', handleCanvasInit);
  eventBus.on('canvas.destroy', handleCanvasDestroy);
  eventBus.on('selection.changed', handleDiagramSelectionChanged);
}
TemplateExtension.$inject = ['overlays', 'eventBus'];

function removeExistingOverlays(overlays) {
  try {
    overlays.remove({ type: OVERLAY_TYPE });
    overlayIds = [];
  } catch {
    // Object not found
  }
}

function renderTooltip(tooltip, overlays) {
  try {
    const overlayId = overlays.add(tooltip.id, OVERLAY_TYPE, {
      position: triggerPosition,
      show: triggerShow,
      scale: triggerScale,
      html: `<div class="${tooltipTriggerClassName}"></div><div class="${tooltipClassName}">${tooltip.text} <a class="${tooltipDismissClassName}">Dismiss guide permanently</a></div>`
    });
    overlayIds.push(overlayId);
  } catch {
    // Element not found, diagram was modified
  }
}

function disposeCurrentDiagramObserver() {
  if (currentDiagramStoreDisposer) {
    currentDiagramStoreDisposer();
  }
}

function disposeTokenSimulationObserver() {
  if (tokenSimulationDisposer) {
    tokenSimulationDisposer();
  }
}

function handleImportDone(overlays) {
  disposeCurrentDiagramObserver();

  currentDiagramStoreDisposer = autorun(() => {
    if (currentDiagramStore.state.tooltips) {
      removeExistingOverlays(overlays);
      disposeMouseEvents();

      currentDiagramStore.state.tooltips.forEach((tooltip) => renderTooltip(tooltip, overlays));

      registerMouseEvents();
    }
  });
}

function handleCanvasInit() {
  observeTokenSimulation();
}

function handleCanvasDestroy() {
  disposeTokenSimulationObserver();
}

function handleDiagramMouseOver(event) {
  if (event.target.classList.contains(tooltipTriggerClassName)) {
    computeTooltipPosition(event.target);
  }
}

function handleDiagramMouseDown(event) {
  if (event.target.classList.contains(tooltipDismissClassName)) {
    currentDiagramStore.discardTemplateGuide();
    event.target.closest(`.${tooltipClassName}`)?.remove();
    return;
  }

  blurVisibleTooltip();
}

function handleDiagramSelectionChanged() {
  blurVisibleTooltip();
}

function observeTokenSimulation() {
  tokenSimulationDisposer = observe(tokenSimulationStore, 'isTokenSimulationOn', (change) => {
    if (
      currentDiagramStore.state.tooltips?.length &&
      (currentDiagramStore.state.isShowingTemplate || shouldResetGuideTooltips)
    ) {
      currentDiagramStore.setIsShowingTemplate(!change.newValue);
      shouldResetGuideTooltips = !shouldResetGuideTooltips;
    }
  });
}

function registerMouseEvents() {
  const diagramContainer = document.getElementsByClassName(diagramContainerClassName)[0];
  if (diagramContainer) {
    diagramContainer.addEventListener('mouseover', handleDiagramMouseOver);
    diagramContainer.addEventListener('mousedown', handleDiagramMouseDown);
  }
}

function disposeMouseEvents() {
  const diagramContainer = document.getElementsByClassName(diagramContainerClassName)[0];
  if (diagramContainer) {
    diagramContainer.removeEventListener('mouseover', handleDiagramMouseOver);
    diagramContainer.removeEventListener('mousedown', handleDiagramMouseDown);
  }
}

function computeTooltipPosition(trigger) {
  const tooltipTopAlignmentOffset = 10;
  const tooltipLeftAlignementOffest = 20;
  const tooltipRightAlignmentOffset = 43;

  const diagramContainer = document.getElementsByClassName(diagramContainerClassName)[0];
  const tooltip = getVisibleTooltip();

  if (diagramContainer && tooltip) {
    const triggerRect = trigger.getBoundingClientRect();

    tooltip.style.top = `${triggerRect.height + tooltipTopAlignmentOffset}px`;
    tooltip.style.left = `-${tooltipLeftAlignementOffest}px`;

    adjustTooltipPositionAndAddArrow({
      diagramContainer,
      triggerRect,
      tooltip,
      tooltipTopAlignmentOffset,
      tooltipLeftAlignementOffest,
      tooltipRightAlignmentOffset
    });

    moveTooltipToForeground(tooltip);
  }
}

function adjustTooltipPositionAndAddArrow({
  diagramContainer,
  triggerRect,
  tooltip,
  tooltipTopAlignmentOffset,
  tooltipLeftAlignementOffest,
  tooltipRightAlignmentOffset
}) {
  const diagramContainerRect = diagramContainer.getBoundingClientRect();
  const tooltipRect = tooltip.getBoundingClientRect();
  const { overTop, overBottom, overLeft, overRight } = isTooltipOutOfViewport(diagramContainerRect, tooltipRect);

  if (overTop) {
    tooltip.classList.remove('bottom-arrow');
    tooltip.classList.add('top-arrow');
    tooltip.style.top = `${triggerRect.height + tooltipTopAlignmentOffset}px`;
  } else if (overBottom) {
    tooltip.classList.remove('top-arrow');
    tooltip.classList.add('bottom-arrow');
    tooltip.style.top = `${-(tooltipRect.height + tooltipTopAlignmentOffset)}px`;
  } else {
    tooltip.classList.remove('bottom-arrow');
    tooltip.classList.add('top-arrow');
  }

  if (overLeft) {
    tooltip.classList.remove('right-arrow');
    tooltip.classList.add('left-arrow');
    tooltip.style.left = `-${tooltipLeftAlignementOffest}px`;
  } else if (overRight) {
    tooltip.classList.remove('left-arrow');
    tooltip.classList.add('right-arrow');
    tooltip.style.left = `${-tooltipRect.width + tooltipRightAlignmentOffset}px`;
  } else {
    tooltip.classList.remove('right-arrow');
    tooltip.classList.add('left-arrow');
  }
}

/**
 * Adds a "foreground" class to the given tooltip. If there is a previous tooltip reference saved, the same class gets removed from it.
 * @param {Object} tooltip The visible tooltip DOM reference
 */
function moveTooltipToForeground(tooltip) {
  if (lastVisibleTooltip) {
    lastVisibleTooltip.closest(`.${djsOverlayClassName}`)?.classList.remove(foregroundTooltipClassName);
  }

  tooltip.closest(`.${djsOverlayClassName}`)?.classList.add(foregroundTooltipClassName);

  lastVisibleTooltip = tooltip;
}

function blurVisibleTooltip() {
  const tooltip = getVisibleTooltip();
  if (!tooltip) {
    const foregroundTooltip = document.getElementsByClassName(foregroundTooltipClassName)[0];
    if (foregroundTooltip) {
      foregroundTooltip.classList.remove(foregroundTooltipClassName);
    }
  }
}

function getVisibleTooltip() {
  let elements = document.getElementsByClassName(tooltipClassName);
  for (const element of elements) {
    if (window.getComputedStyle(element).display !== 'none') {
      return element;
    }
  }
}

function isTooltipOutOfViewport(viewportRect, tooltipRect) {
  return {
    overTop: tooltipRect.top < viewportRect.top,
    overBottom: tooltipRect.top + tooltipRect.height > viewportRect.bottom,
    overLeft: tooltipRect.left < viewportRect.left,
    overRight: tooltipRect.left + tooltipRect.width > viewportRect.right
  };
}
