/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';
import { Keyboard } from '@carbon/icons-react';
import { observer } from 'mobx-react';

import { diagramControlStore, attentionGrabberStore, diagramExtensionStore } from 'stores';
import { Tooltip } from 'primitives';
import { Map, Plus, Minus, Target, EnableFullscreen, DisableFullscreen, AttentionGrabber } from 'icons';
import BrowserDetector from 'utils/browser-detector';
import { trackingService } from 'services';

import * as Styled from './DiagramControls.styled';

const browserDetector = new BrowserDetector();

const DiagramControls = ({ hasAttentionGrabber = false, hasKeyboardShortcuts = false }) => {
  const { handleFullscreenToggle, handleZoomIn, handleZoomOut, handleViewboxReset, toggleMinimapActive } =
    diagramControlStore;
  const { isFullscreenActive, isMinimapActive, hasModeler, hasMinimap, hasResetViewport, hasFullscreen } =
    diagramControlStore.state;
  const { toggleAttentionGrabberActive } = attentionGrabberStore;
  const { isAttentionGrabberActive } = attentionGrabberStore.state;

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (browserDetector.isSafari) {
        if (!document.webkitFullscreenElement) {
          diagramControlStore.exitFullscreen();
        }
      } else {
        if (!document.fullscreenElement) {
          diagramControlStore.exitFullscreen();
        }
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <Styled.DiagramControlsWrapper>
      {hasKeyboardShortcuts && (
        <Tooltip align="top" title="Keyboard shortcuts">
          <Styled.DiagramControlButton
            data-test="keyboard-shortcuts"
            onClick={() => {
              trackingService.trackTopBarAction('shortcuts', 'info');
              diagramExtensionStore.showShortcutModal();
            }}
            title="Browse keyboard shortcuts"
          >
            <Keyboard width="20" height="20" />
          </Styled.DiagramControlButton>
        </Tooltip>
      )}

      {hasAttentionGrabber && (
        <Tooltip align="top" title="Click to drop an attention point observable by your collaborators">
          <Styled.DiagramControlButton
            data-test="toggle-attentiongrabber"
            onClick={toggleAttentionGrabberActive}
            title="Toggle attention grabber"
          >
            <AttentionGrabber
              width="15"
              height="15"
              style={{
                color: isAttentionGrabberActive && 'var(--orange-base-60)'
              }}
            />
          </Styled.DiagramControlButton>
        </Tooltip>
      )}

      {hasFullscreen && (
        <Tooltip title={isFullscreenActive ? 'Disable fullscreen' : 'Enable fullscreen'} align="top">
          <Styled.DiagramControlButton
            data-test="toggle-fullscreen"
            onClick={handleFullscreenToggle}
            title="Toggle fullscreen mode"
          >
            {isFullscreenActive ? (
              <DisableFullscreen width="14" height="14" />
            ) : (
              <EnableFullscreen width="14" height="14" />
            )}
          </Styled.DiagramControlButton>
        </Tooltip>
      )}

      {hasModeler && hasMinimap && (
        <Tooltip title="Toggle minimap" align="top">
          <Styled.DiagramControlButton
            data-selector="minimap-container"
            data-test="toggle-minimap"
            onClick={toggleMinimapActive}
            title="Toggle minimap"
          >
            <Map
              height="18"
              style={{
                color: isMinimapActive && 'var(--orange-base-60)'
              }}
            />
          </Styled.DiagramControlButton>
        </Tooltip>
      )}

      {hasModeler && hasResetViewport && (
        <Tooltip title="Reset viewport" align="top">
          <Styled.DiagramControlButton onClick={handleViewboxReset} data-test="viewport-reset" title="Reset viewport">
            <Target width="15" height="15" />
          </Styled.DiagramControlButton>
        </Tooltip>
      )}

      <Tooltip title="Zoom out" align="top">
        <Styled.DiagramControlButton onClick={handleZoomOut} data-test="zoom-out" title="Zoom out">
          <Minus width="12" height="12" />
        </Styled.DiagramControlButton>
      </Tooltip>
      <Tooltip title="Zoom in" align="top">
        <Styled.DiagramControlButton onClick={handleZoomIn} data-test="zoom-in" title="Zoom in">
          <Plus width="20" height="20" />
        </Styled.DiagramControlButton>
      </Tooltip>
    </Styled.DiagramControlsWrapper>
  );
};

export default observer(DiagramControls);
