/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

import { deploymentPermissionsStore, deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import { currentDiagramStore, processApplicationStore, notificationStore } from 'stores';
import { tracingService } from 'services';

/**
 * Initializes various stores required for process application version deployment based on the provided process application and BPMN viewer.
 *
 * @param {Object} processApplication - The process application object.
 * @param {Object|null} bpmnViewer - The BPMN viewer instance.
 *
 * @returns {void}
 */
export function usePrepareStoresForDeployment(processApplication, bpmnViewer) {
  useEffect(() => {
    (async () => {
      if (processApplication.mainProcessFileId === null) {
        return;
      }

      const results = await Promise.allSettled([
        currentDiagramStore.fetchDiagramById(processApplication.mainProcessFileId),
        processApplicationStore.init(processApplication.id, false)
      ]);

      const hasRejections = results.some((result) => result.status === 'rejected');
      if (hasRejections) {
        notificationStore.showError(
          'Yikes! Something went wrong while preparing your deployment. Please try again later.'
        );
        results.forEach((result) => {
          if (result.status === 'rejected') {
            const error = result.reason;
            tracingService.traceError(error, `Error while initializing deployment`);
          }
        });
        return;
      }

      deploymentStore.init?.(currentDiagramStore.state.diagram.id, currentDiagramStore.state.diagram.processId);
      deploymentPermissionsStore.init?.();
    })();
  }, [processApplication.id, processApplication.mainProcessFileId]);

  useEffect(() => {
    if (bpmnViewer) {
      currentDiagramStore.setModeler(bpmnViewer);
    }
  }, [bpmnViewer]);

  useEffect(() => {
    return () => {
      currentDiagramStore.reset();
      processApplicationStore.reset();
      deploymentPermissionsStore.reset?.();
      deploymentStore.reset?.();
    };
  }, []);
}
