/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tag } from '@carbon/react';
import { Enterprise, Folders } from '@carbon/icons-react';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { PUBLISHED_ON } from 'utils/milestones/published-on';
import { Avatar, Sidebar, Tooltip } from 'primitives';

import * as Styled from './Milestone.styled';

export default function Milestone({
  milestone,
  name,
  author,
  authorFullName,
  isPrimarySelection,
  isSecondarySelection,
  isEditing,
  isTemplate,
  selectMilestone,
  setEditingMilestone,
  renameMilestone,
  children
}) {
  if (typeof milestone === 'string') {
    return (
      <div role="listitem" aria-disabled>
        <Sidebar.Separator>
          <span>{milestone}</span>
        </Sidebar.Separator>
      </div>
    );
  }

  return (
    <Styled.Milestone
      $isPrimary={isPrimarySelection}
      $isSecondary={isSecondarySelection}
      onClick={() => selectMilestone(milestone.id)}
      data-test="milestone"
      role="listitem"
      aria-label={milestone.name}
      data-selected={isPrimarySelection || isSecondarySelection ? true : false}
    >
      {authorFullName ? (
        <Avatar size="small" fullname={authorFullName} disableTooltip />
      ) : (
        <Styled.PlaceholderAvatar $isPrimary={isPrimarySelection} />
      )}

      <Styled.MilestoneContent $isTemplate={isTemplate}>
        <div>
          <Styled.MilestoneTitle
            $isTemplate={isTemplate}
            initialValue={name || 'Milestone name'}
            isEditing={isEditing}
            onSubmit={(name) => renameMilestone(milestone, name)}
            onCancel={() => setEditingMilestone()}
          >
            {milestone.name || 'Untitled milestone'}
          </Styled.MilestoneTitle>

          <Styled.MilestoneParagraph>
            <time dateTime={milestone.created}>{moment(milestone.created).format('HH:mm')}</time>
            {author && ` - Created by ${author}`}
          </Styled.MilestoneParagraph>
        </div>
        {isTemplate && milestone.publishedOn !== null && (
          <Tooltip title={`Published to ${milestone.publishedOn}`}>
            <Tag
              data-test={`published-to-${milestone.publishedOn}`}
              renderIcon={milestone.publishedOn === PUBLISHED_ON.ORGANIZATION ? Enterprise : Folders}
              type="high-contrast"
            >
              <span>Published</span>
            </Tag>
          </Tooltip>
        )}
      </Styled.MilestoneContent>

      {children}
    </Styled.Milestone>
  );
}

Milestone.propTypes = {
  milestone: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  name: PropTypes.string,
  author: PropTypes.string,
  authorFullName: PropTypes.string,
  isPrimarySelection: PropTypes.bool.isRequired,
  isSecondarySelection: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  selectMilestone: PropTypes.func.isRequired,
  setEditingMilestone: PropTypes.func.isRequired,
  renameMilestone: PropTypes.func.isRequired
};
