/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { FOLDER, PROCESS_APPLICATION } from 'utils/constants';

import Service from './Service';

class FolderService extends Service {
  /**
   * Fetches a single folder, including its content.
   *
   * @param {String} folderId
   * @returns {Promise}
   */
  async fetchById(folderId) {
    const response = await this.get(`/internal-api/folders/${folderId}`);
    return {
      ...response,
      children: response.children?.map((folder) => ({
        ...folder,
        type: FOLDER
      })),
      processApplications: response.processApplications?.map((processApplication) => ({
        ...processApplication,
        type: PROCESS_APPLICATION
      }))
    };
  }

  /**
   * Creates a new folder.
   *
   * @param {Object} payload
   * @param {String} payload.name The new folder's name.
   * @param {String} [payload.projectId] The project ID. Only required if the parent is a project.
   * @param {String} [payload.parentId] The parent folder ID. Only required if the parent is a folder.
   * @returns {Promise}
   */
  create(payload) {
    return this.post(`/internal-api/folders`, payload);
  }

  /**
   * Renames a specific folder.
   *
   * @param {String} folderId
   * @param {Object} payload
   * @param {String} payload.name The new folder name.
   * @returns {Promise}
   */
  rename(folderId, payload) {
    return this.patch(`/internal-api/folders/${folderId}`, payload);
  }

  /**
   * Moves folders into a different project or folder.
   *
   * @param {Object} payload
   * @param {String} payload.projectId Target project ID
   * @param {String} payload.parentId Target folder ID, if given.
   * @param {String[]} payload.folderIds An array of folder IDs.
   * @returns {Promise}
   */
  move(payload) {
    return this.post('/internal-api/folders/move', payload);
  }

  /**
   * Removes a specific folder.
   *
   * @param {String} folderId
   * @returns {Promise}
   */
  destroy(folderId) {
    return this.delete(`/internal-api/folders/${folderId}`);
  }
}

export default new FolderService();
