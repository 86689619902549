/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import BaseProcessApplicationService from './ProcessApplicationService.common';

class ProcessApplicationService extends BaseProcessApplicationService {
  /**
   * Deploys the given process application
   *
   * @param {String} clusterId The id of the cluster to deploy the process application to
   * @param {String} processApplicationId The id of the process application to deploy
   * @param {Object} formResolutions A list of the form resolutions for the new deployment
   * @return {Promise<import('services/ProcessApplicationService.common').ProcessApplicationDeployment>}
   */
  deployProcessApplication(clusterId, processApplicationId, formResolutions) {
    return this.post(`/internal-api/process-applications/${processApplicationId}/deploy`, {
      clusterId,
      formResolutions
    });
  }

  /**
   * Deploys and executes the given process application
   *
   * @param {String} clusterId The id of the cluster to deploy the process application to
   * @param {Object} payload JSON object of the payload for the new instance
   * @param {String} processApplicationId
   * @param {Object} formResolutions A list of the form resolutions for the new deployment
   * @return {Promise<import('services/ProcessApplicationService.common').SaasFileExecute>}
   */
  executeProcessApplication(clusterId, payload, processApplicationId, formResolutions) {
    return this.post(`/internal-api/process-applications/${processApplicationId}/execute`, {
      clusterId,
      variables: payload,
      formResolutions
    });
  }

  /**
   * Deploys the given process application version
   *
   * @param {String} clusterId The id of the cluster to deploy the process application to
   * @param {String} processApplicationVersionId The id of the process application version to deploy
   * @param {Object} formResolutions A list of the form resolutions for the new deployment
   * @return {Promise<import('services/ProcessApplicationService.common').ProcessApplicationDeployment>}
   */
  deployProcessApplicationVersion(clusterId, processApplicationVersionId, formResolutions) {
    return this.post(`/internal-api/process-application-versions/${processApplicationVersionId}/deploy`, {
      clusterId,
      formResolutions
    });
  }

  /**
   * Deploys and executes the given process application version
   *
   * @param {String} clusterId The id of the cluster to deploy the process application to
   * @param {Object} payload JSON object of the payload for the new instance
   * @param {String} processApplicationVersionId
   * @param {Object} formResolutions A list of the form resolutions for the new deployment
   * @return {Promise<import('services/ProcessApplicationService.common').SaasFileExecute>}
   */
  executeProcessApplicationVersion(clusterId, payload, processApplicationVersionId, formResolutions) {
    return this.post(`/internal-api/process-application-versions/${processApplicationVersionId}/execute`, {
      clusterId,
      variables: payload,
      formResolutions
    });
  }
}

export default new ProcessApplicationService();
