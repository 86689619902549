/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

export default function usePositioning({ dialogEl, anchorEl, justify, alignment }) {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (anchorEl && dialogEl) {
      const { top, bottom, left, width } = anchorEl.getBoundingClientRect();

      const getLeftPosition = () => {
        switch (justify) {
          case 'left':
            return left;
          case 'right':
            return left + width - dialogEl.offsetWidth;
          default:
            return left - dialogEl.offsetWidth / 2 + width / 2;
        }
      };

      const getTopPosition = () => {
        if (alignment == 'top') {
          return top - dialogEl.offsetHeight;
        }

        return bottom;
      };

      setPosition({
        top: getTopPosition(),
        left: getLeftPosition()
      });
    }
  }, [anchorEl, dialogEl, anchorEl?.getBoundingClientRect()?.top, anchorEl?.getBoundingClientRect()?.left]);

  return position;
}
