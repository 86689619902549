/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { FORM } from 'utils/constants';
import { notificationStore } from 'stores';
import { tracingService, trackingService } from 'services';

import saveFile from './save-file';

/**
 * Downloads a given form's JSON to the user's computer.
 *
 * @param {Object} form
 * @param {String} [form.id] The form's id
 * @param {String} [form.content] The form's stringified JSON content.
 * @param {String} [form.name] The form's name.
 */
export default function exportForm({ id, content, name }) {
  try {
    const fileName = `${name}.form`;

    saveFile(content, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport({
      fileId: id,
      fileTypeKey: FORM,
      exportType: 'json',
      from: 'form',
      fileCount: 1,
      fileSize: content.length
    });
  } catch (ex) {
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
    tracingService.traceError(ex, 'Failed to export form');
  }
}
