/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import config from 'utils/config';

export default class MixpanelIntegration {
  #userIdentityId = undefined;

  registerUser(payload) {
    if (this.isMixpanelEnabled) {
      this.#userIdentityId = payload?.user?.sub;

      if (this.#userIdentityId) {
        window.mixpanel.register({
          distinct_id: this.#userIdentityId
        });

        window.mixpanel.identify(this.#userIdentityId);
      }
    }
  }

  get isMixpanelEnabled() {
    return config.mixpanel.enabled;
  }

  get isAppcuesEnabled() {
    return config.appcues.enabled;
  }

  get stage() {
    return config.mixpanel.stage;
  }

  setOrganization(organization) {
    if (this.isMixpanelEnabled) {
      window.mixpanel.register({
        distinct_id: this.#userIdentityId ?? organization.id,
        stage: this.stage,
        org_id: organization.id,
        orgId: organization.id,
        license: organization.license ? organization.license.displayName : 'Free',
        orgOwner: organization.admins?.[0]?.id
      });
    }
  }

  #verifyOptIn() {
    const isOptedIn = window.Osano?.cm?.analytics;

    if (this.hasOptedIn !== isOptedIn) {
      // if the opt in setting has changed
      this.hasOptedIn = isOptedIn;

      if (isOptedIn) {
        window.mixpanel.opt_in_tracking();
      } else {
        window.mixpanel.opt_out_tracking();
      }
    }
  }

  #verifyUserIdentityId() {
    if (!this.#userIdentityId) {
      this.#userIdentityId = 'anonymous';
      window.mixpanel.identify(this.#userIdentityId);
    }
  }

  track(name, props) {
    if (this.isMixpanelEnabled) {
      this.#verifyUserIdentityId();
      this.#verifyOptIn();
      window.mixpanel.track(name, props);
    }
  }

  increment(name, count = 1) {
    if (this.isMixpanelEnabled) {
      this.#verifyUserIdentityId();
      this.#verifyOptIn();
      window.mixpanel.people.increment(name, count);
    }
  }
}
