/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Parser } from 'saxen';
import { findIndex } from 'min-dash';

import {
  BPMN,
  DEFAULT_ZEEBE_VERSION,
  DEFAULT_ZEEBE_VERSION_FORMS,
  DMN,
  EXECUTION_PLATFORM,
  FORM
} from 'utils/constants';
import {
  hasValidExecutionPlatformAndVersion as hasValidDiagramExecutionPlatformAndVersion,
  isCamunda7Diagram,
  isCamunda8Diagram
} from 'utils/validate-diagram';
import {
  hasValidExecutionPlatform as hasValidFormExecutionPlatform,
  hasValidExecutionPlatformVersion as hasValidFormExecutionPlatformVersion,
  isCamunda7Form,
  isCamunda8Form
} from 'utils/validate-form';

import parseXML from './parse-xml';
import stringifyXML from './stringify-xml';

const MODELER_NAMESPACE = 'http://camunda.org/schema/modeler/1.0';

/**
 * Upserts the execution platform and version to the diagram XML or to the form JSON. If the content is a Camunda 7 resource, it will be converted to C8.
 * If there is no execution platform details, the default C8 execution platform and version will be used.
 * @param {String} type
 * @param {String} content
 * @returns {Promise<String>} The updated content as a string
 */
// NOTE: This does not use the ExecutionPlatform module because this utility could run in contexts where the `bpmn-js` modeler instance is not available.
export default async function upsertExecutionPlatformToC8(type, content) {
  if (!type) {
    throw new Error('No type provided.');
  }

  if (!content || content === '') {
    throw new Error('No content provided.');
  }

  if (type === BPMN || type === DMN) {
    return await upsertDiagramExecutionPlatformToC8(content);
  }

  if (type === FORM) {
    return upsertFormExecutionPlatformToC8(content);
  }

  return content;
}

/**
 * Upserts the execution platform and version to the diagram XML. If the content is a Camunda 7 resource, it will be converted to C8.
 * If there is no execution platform details, the default C8 execution platform and version will be used.
 * @param {String} content The diagram XML
 * @returns {Promise<String>} The updated content as a string
 */
export const upsertDiagramExecutionPlatformToC8 = async (content) => {
  if (isCamunda8Diagram(content)) {
    return content;
  }

  const shouldOverrideExecutionPlatform =
    isCamunda7Diagram(content) || !hasValidDiagramExecutionPlatformAndVersion(content);
  if (shouldOverrideExecutionPlatform) {
    return await setExecutionPlatformDetails(content, EXECUTION_PLATFORM, DEFAULT_ZEEBE_VERSION);
  }

  return content;
};

/**
 * Upserts the execution platform and version to the form JSON. If the content is a Camunda 7 resource, it will be converted to C8.
 * If there is no execution platform details, the default C8 execution platform and version will be used.
 * @param {String} content
 * @returns {String} The updated content as a string
 */
export const upsertFormExecutionPlatformToC8 = (content) => {
  const jsonContent = JSON.parse(content);

  if (isCamunda8Form(jsonContent)) {
    return content;
  }

  const shouldOverrideExecutionPlatform =
    isCamunda7Form(jsonContent) ||
    !hasValidFormExecutionPlatform(jsonContent) ||
    !hasValidFormExecutionPlatformVersion(jsonContent);

  if (shouldOverrideExecutionPlatform) {
    jsonContent.executionPlatform = EXECUTION_PLATFORM;
    jsonContent.executionPlatformVersion = DEFAULT_ZEEBE_VERSION_FORMS;
    return JSON.stringify(jsonContent, null, '  ');
  }

  return content;
};

async function setExecutionPlatformDetails(xml, executionPlatform, executionPlatformVersion) {
  return new Promise((resolve) => {
    let newXml;

    const parser = new Parser();

    parser.on('error', function () {
      parser.stop();
    });

    parser.on('openTag', function (elementName, attrGetter) {
      // continue only if the first tag is definitions
      if (isDefinitions(elementName)) {
        const attrs = attrGetter();
        newXml = setExecutionPlatformDetailsInXml(xml, attrs, executionPlatform, executionPlatformVersion);
      }

      // only parse first tag
      parser.stop();

      resolve(newXml || xml);
    });

    parser.parse(xml);
  });
}

function setExecutionPlatformDetailsInXml(xml, attrs, executionPlatform, executionPlatformVersion) {
  const prefix = getModelerNamespacePrefix(attrs) || 'modeler:';

  attrs[`${prefix}executionPlatform`] = executionPlatform;
  attrs[`${prefix}executionPlatformVersion`] = executionPlatformVersion;

  const dom = parseXML(xml);
  const root = dom.firstElementChild;
  root.setAttribute(`${prefix}executionPlatform`, executionPlatform);
  root.setAttribute(`${prefix}executionPlatformVersion`, executionPlatformVersion);

  return stringifyXML(dom);
}

function isDefinitions(elementName) {
  let unwrappedName;

  // bpmn:definitions
  // dmn:Definitions
  if (elementName.indexOf(':') !== -1) {
    unwrappedName = elementName.split(':')[1];
  } else {
    // definitions
    // Definitions
    unwrappedName = elementName;
  }

  return unwrappedName.toLowerCase() === 'definitions';
}

/**
 *
 * @param {object} attrs
 * @returns {null | '' | `${string}:`}
 */
function getModelerNamespacePrefix(attrs) {
  const wrappedPrefix = findIndex(attrs, MODELER_NAMESPACE);

  if (!wrappedPrefix) {
    return null;
  }

  if (wrappedPrefix === 'xmlns') {
    return '';
  }

  const [, prefix] = wrappedPrefix.split(':');

  return `${prefix}:`;
}
