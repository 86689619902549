/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, Link } from '@carbon/react';
import styled from 'styled-components';

export const BlueprintCard = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #f4f4f4;
  display: flex;
`;

export const ResourceLink = styled.div`
  color: inherit;

  svg {
    vertical-align: middle;
  }
`;

export const ExternalLink = styled(Link)`
  cursor: pointer;
  & ${ResourceLink} {
    visibility: hidden;
    margin-left: 4px;
  }

  &:hover ${ResourceLink} {
    visibility: visible;
  }
`;

export const SupportLink = styled(Link)`
  color: var(--cds-text-primary, #161616) !important;
  text-decoration: underline !important;
`;

export const NoBlueprintsFoundText = styled.div`
  margin-top: 30px;
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const BlueprintDetails = styled.div`
  position: relative;
  padding-bottom: 48px;
  flex-basis: 33.33%;
  padding-right: 16px;
`;

export const BlueprintName = styled.div`
  color: #161616;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  padding-bottom: 8px;
`;

export const BlueprintCreatedBy = styled.div`
  font-weight: 400;
`;

export const BlueprintDescription = styled.div`
  width: 100%;
  padding-top: 8px;
  color: #525252;
`;

export const BlueprintDetailLink = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const BlueprintPreview = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 66.66%;
  align-items: center;
  background-color: #ffffff;
  padding: 8px;
`;

export const BlueprintImage = styled.img`
  width: auto;
  height: auto;
  max-height: 240px;
  max-width: 100%;
`;

export const UseBlueprintContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 16px;
`;

export const UseBlueprintButton = styled(Button)`
  flex: 1;
  max-width: 100%;
  width: 100%;
`;
