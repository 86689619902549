/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

const COLOR = 'var(--grey-darken-33)';

export const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid ${COLOR};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: 3px;
  }
  &:checked {
    &:after {
      background-color: ${COLOR};
    }
  }
  vertical-align: middle;
  cursor: pointer;
`;

export const Label = styled.label`
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
`;
