/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { TopBar, Breadcrumb, Dropdown } from 'components';

export const Header = observer(function Header({ loading, breadcrumbs }) {
  return (
    <TopBar.Breadcrumbs>
      {loading ? (
        <Breadcrumb title="Loading..." variant="text" />
      ) : (
        <>
          {breadcrumbs.map((breadcrumb, index) => (
            <Breadcrumb
              key={breadcrumb.title}
              title={breadcrumb.title}
              variant={breadcrumb.variant || 'text'}
              to={breadcrumb.to}
              isBold={index === breadcrumbs.length - 1}
              data-test={`breadcrumb-${breadcrumb.title.toLowerCase()}`}
            >
              {breadcrumb.onDelete && (
                /* @ts-expect-error TS2739 */
                <Dropdown.ListItem onClick={breadcrumb.onDelete} data-test={`delete-${breadcrumb.title.toLowerCase()}`}>
                  Delete
                </Dropdown.ListItem>
              )}
            </Breadcrumb>
          ))}
        </>
      )}
    </TopBar.Breadcrumbs>
  );
});
