/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { ReadOnlyPill, ShareView } from 'components';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import ActionButton from 'App/Pages/Project/ActionButton';
import getBatchActions from 'App/Pages/Project/BatchActions';
import { diagramExtensionStore, processApplicationStore, organizationStore, projectStore } from 'stores';
import { NameRenderer } from 'components/EntityTable/CellRenderer';
import EntityAction from 'App/Pages/Project/EntityAction';
import { getAuthorName, getEntityIcon, getEntityLink, getEntityName, getSortingPositionByEntity } from 'utils/helpers';
import hasAccess, { actions } from 'utils/user-access';
import { dedicatedModesStore, deploymentErrorsStore, publicationStore } from 'App/Pages/Diagram/stores';
import { getFileDate } from 'App/Pages/Project/utils';
import { EmptyState, Spinner } from 'primitives';
import { DiagramWithFolder, SadFace } from 'icons';
import * as Shared from 'App/Pages/Project/Shared.styled';
import { GitSync, useGitSyncFeatureEnabled } from 'features/git-sync';
import { FolderExplorer, useFetchReadme } from 'features/folder-exploring';
import { LandscapeView } from 'features/landscape-view';

import ProcessApplicationHeader from './ProcessApplicationHeader';
import * as Styled from './ProcessApplication.styled';

const ProcessApplicationPage = () => {
  const [isStoreLoading, setIsStoreLoading] = useState(true);

  // @ts-expect-error TS2339
  const { slug } = useParams();
  const { processApplication } = processApplicationStore;
  const { project } = projectStore;
  const { hasElevatedOrganizationPermissions } = organizationStore;

  const { batchActions, targetSelector } = getBatchActions();

  const hasGitSyncFeatureEnabled = useGitSyncFeatureEnabled();
  const isAdmin = hasAccess(project, actions.MODIFY_PROJECT);
  const isEditor = hasAccess(project, actions.MODIFY_DIAGRAM);
  const hasGitSyncAccess = hasGitSyncFeatureEnabled && (isAdmin || isEditor || hasElevatedOrganizationPermissions);

  const { currentReadme, isReadmePresent, isReadmeLoading } = useFetchReadme({
    entities: isStoreLoading ? null : processApplication?.files
  });

  const reset = () => {
    processApplicationStore.reset();
    deploymentErrorsStore.reset();
    publicationStore.reset();
    formLinkStore.reset();
  };

  useEffect(() => {
    const init = async () => {
      await processApplicationStore.init(slug.split('--')[0]);
      setIsStoreLoading(false);
    };

    init();

    return () => reset();
  }, [slug]);

  const processApplicationNotFound = (
    // @ts-expect-error TS2739
    <EmptyState
      title="Process application not found!"
      description="Sorry, your process application might have been deleted."
      icon={<SadFace width="48" height="48" />}
    />
  );

  return (
    <Fragment>
      <ProcessApplicationHeader />
      {isStoreLoading || isReadmeLoading ? (
        <Spinner fullHeight />
      ) : processApplication.files ? (
        <Styled.Container>
          <FolderExplorer
            action={({ tabIndex }) => (
              <>
                <LandscapeView project={project} tabIndex={tabIndex} />
                <ActionButton
                  label="Create new"
                  variant="carbon"
                  processApplicationId={processApplication.id}
                  isReadmePresent={isReadmePresent}
                  onUpload={projectStore.uploadFiles}
                />
              </>
            )}
            batchActions={batchActions}
            currentReadme={currentReadme}
            canEditReadme={hasAccess(project, actions.MODIFY_README)}
            emptyState={processApplicationNotFound}
            columns={[
              {
                key: 'name',
                header: 'Name',
                renderer: NameRenderer,
                minWidth: '150px',
                noPadding: true,
                sortable: true
              },
              // { key: 'errors', header: '', width: '250px' }, TODO https://github.com/camunda/web-modeler/issues/9129
              {
                key: 'creator',
                header: 'Creator',
                width: '150px',
                sortable: true
              },
              {
                key: 'lastChanged',
                header: 'Last changed',
                width: '200px',
                sortable: true
              },
              {
                key: 'entityAction',
                header: '',
                width: '50px',
                noPadding: true,
                renderer: (entity) => <EntityAction entity={entity} />
              }
            ]}
            onDrop={hasAccess(projectStore.project, actions.MODIFY_DIAGRAM) && projectStore.uploadFiles}
            title={
              <Shared.TitleContainer>
                <Shared.Title data-test="process-application-name">
                  <DiagramWithFolder width="24" height="24" />
                  {processApplication.name}
                </Shared.Title>
                {!(isAdmin || isEditor) && (
                  <div>
                    <ReadOnlyPill />
                  </div>
                )}
                {hasGitSyncAccess && <GitSync />}
              </Shared.TitleContainer>
            }
            toolbarPlaceholder="Search within the application"
            rows={processApplication.files.map((entity) => {
              const isMainProcess = entity.id === processApplication.mainProcessFileId;
              const extendedEntity = { ...entity, isMainProcess };

              return {
                __sortingPosition: getSortingPositionByEntity(extendedEntity),
                id: entity.id,
                isMainProcess,
                name: {
                  name: getEntityName(entity),
                  icon: getEntityIcon(entity),
                  link: getEntityLink(entity),
                  ...(isMainProcess
                    ? {
                        tag: 'Main process',
                        title:
                          'The main process cannot be moved or deleted. However, you can assign a different BPMN process as the main process.'
                      }
                    : undefined),
                  onClick: () => {
                    if (entity.type === 'BPMN') {
                      dedicatedModesStore.resetViewModeIndexForPlay();
                    }
                  }
                },
                // errors: '-', TODO https://github.com/camunda/web-modeler/issues/9129
                creator: getAuthorName(entity),
                lastChanged: getFileDate(entity),
                entityAction: extendedEntity
              };
            })}
          />

          {diagramExtensionStore.state.isShareModalVisible && (
            <ShareView diagram={diagramExtensionStore.state.diagram} onClose={diagramExtensionStore.hideShareModal} />
          )}

          {targetSelector}
        </Styled.Container>
      ) : (
        processApplicationNotFound
      )}
    </Fragment>
  );
};

export default observer(ProcessApplicationPage);
