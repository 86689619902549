/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { fileService, trackingService, tracingService } from 'services';
import { notificationStore, confirmActionStore, breadcrumbStore } from 'stores';
import { DEFAULT, FOLDER, FOLDER_DEFAULT, PROCESS_APPLICATION } from 'utils/constants';

import { handleDeleteError } from './readmeErrorHandling';

/**
 * @typedef {Object} DeleteResult
 * @property {boolean} [deleted] - Whether the readme was deleted
 * @property {boolean} [navigated] - Whether navigation occurred after deletion
 */

/**
 * Deletes a readme with a confirmation dialog.
 *
 * @param {Object} readme - The readme entity to delete.
 * @param {Object} history - The history object for navigation.
 * @param {Object} project - The project object for fallback navigation.
 * @returns {Promise<DeleteResult>} - The result of the deletion process.
 */
export async function tryDeleteReadmeWithConfirmation(readme, history, project) {
  if (breadcrumbStore && typeof breadcrumbStore.toggleDropdownVisibility === 'function') {
    breadcrumbStore.toggleDropdownVisibility();
  }

  try {
    await fileService.destroyDryRun([readme.id]);
  } catch (error) {
    handleDeleteError(error);
    return {};
  }

  const confirmed = await confirmActionStore.confirm({
    title: 'Deleting readme',
    text: 'Are you sure you want to delete this readme from the project?',
    confirmLabel: 'Delete readme',
    isDangerous: true,
    isFileDeletion: true
  });
  if (!confirmed) {
    return {};
  }

  try {
    await fileService.destroy([readme.id]);
    notificationStore.showSuccess('Your readme has been deleted.');
    trackingService.trackDeleteEntities(DEFAULT, 1);

    const isInProcessApplication = Boolean(readme.folderId && readme.folderType === PROCESS_APPLICATION);
    const isInFolder = Boolean(
      readme.folderId && (readme.folderType === FOLDER || readme.folderType === FOLDER_DEFAULT)
    );

    if (isInProcessApplication) {
      history.push(`/process-applications/${readme.folder.id}`);
    } else if (isInFolder) {
      history.push(`/folders/${readme.folder.id}`);
    } else {
      history.push(`/projects/${project.id}`);
    }
  } catch (ex) {
    notificationStore.showError("Yikes! Couldn't remove your readme. Please try again later.");
    tracingService.traceError(ex, 'Failed to delete readme');
    return { deleted: true };
  }

  return { deleted: true, navigated: true };
}
