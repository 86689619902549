/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tooltip } from 'primitives';
import { ChevronRight } from 'icons';

import WithLink from './WithLink';
import * as Styled from './styled';

export default function ListItem({ children, hasSubDropdown, to, noPadding, target, disabled, ...props }) {
  if (hasSubDropdown) {
    return (
      <Styled.ListItem
        $hasSubDropdown={hasSubDropdown}
        disabled={disabled}
        tabIndex="0"
        role="menuitem"
        $noPadding={noPadding}
        {...props}
      >
        <div>{children}</div>

        <ChevronRight width="22" height="22" />
      </Styled.ListItem>
    );
  }

  return (
    <Styled.ListItem disabled={disabled} tabIndex="0" role="menuitem" $noPadding={noPadding || Boolean(to)} {...props}>
      <Tooltip title={children} showOnlyOnOverflow>
        <div className="overflow-ellipsis">
          <WithLink to={to} target={target}>
            {children}
          </WithLink>
        </div>
      </Tooltip>
    </Styled.ListItem>
  );
}
