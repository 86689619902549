/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Sidebar } from 'primitives';
import { MilestonesDiffingToggle, MilestonesSection } from 'components';

import * as Styled from './Sidebar.styled';

export default function MilestoneSidebar() {
  return (
    <Sidebar $isVisible>
      <Sidebar.Inner>
        <Styled.SidebarSection>
          <Sidebar.Title>Milestones</Sidebar.Title>
          <MilestonesSection />
        </Styled.SidebarSection>
      </Sidebar.Inner>
      <MilestonesDiffingToggle />
    </Sidebar>
  );
}
