/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ModalFooter, ModalHeader } from '@carbon/react';
import { observer } from 'mobx-react';

import * as Styled from 'components/ImportModal/ImportModal.styled';
import ActionsButtons from 'components/ImportModal/ActionsButtons';

import { unpublishResourceModalStore } from './stores';

const UnpublishResourceModal = () => {
  const handleOnClose = () => {
    unpublishResourceModalStore.closeUnpublishModal();
  };

  if (!unpublishResourceModalStore.shouldShowUnpublishModal) {
    return null;
  }

  return (
    <Styled.ImportDialog
      open
      onClose={handleOnClose}
      size="sm"
      className="overflow-visible"
      data-test="unpublish-resource-modal"
    >
      <ModalHeader>
        <Styled.ModalTitle>Unpublish shared resource</Styled.ModalTitle>
      </ModalHeader>

      {unpublishResourceModalStore.resource && (
        <Styled.ImportModalBody>
          <p>
            This action will remove <strong>{unpublishResourceModalStore.resource.name}</strong> from the organization
            and make it unavailable for others to use.
          </p>
          <br />
          <p>The resource will no longer receive updates from the source file.</p>
        </Styled.ImportModalBody>
      )}

      <ModalFooter>
        <ActionsButtons
          showCancel
          showUnpublish
          onCancel={handleOnClose}
          onUnpublish={() => {
            unpublishResourceModalStore.unpublishResource();
          }}
        />
      </ModalFooter>
    </Styled.ImportDialog>
  );
};

export default observer(UnpublishResourceModal);
