/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Loader = styled.span`
  display: inline-block;
  width: ${({ size }) => (size == 'small' ? '20px' : '40px')};
  height: ${({ size }) => (size == 'small' ? '20px' : '40px')};
  border-radius: 50%;
  border: ${({ size }) => (size == 'small' ? '2px' : '4px')} solid
    ${({ $light }) => ($light ? 'white' : 'var(--cds-blue-60)')};
  border-bottom-color: transparent;
  animation: ${spin} 1.2s linear infinite;
`;
