/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import moment from 'moment';

import { Avatar } from 'primitives';

import * as Styled from './Version.styled';

/**
 * @typedef {import('components/VersionsList/types').VersionListEntryDto} VersionListEntryDto
 */

/**
 * Component to display a version of an entity
 *
 * @param {Object} props - The component props.
 * @param {VersionListEntryDto} props.version
 * @param {Boolean} props.isPrimarySelection
 * @param {Boolean} props.isSecondarySelection
 * @param {Boolean} props.isDiffingEnabled
 * @param {Function} props.reloadVersions
 * @param {Function} props.setSelectedVersionId
 * @param {Boolean} props.isCurrentUser
 * @param {Function} props.renderVersionMenu
 * @param {Function} props.onRenameVersion
 * @param {Function} [props.renderPublishedTag]
 * @param {Function} [props.renderReview]
 * @returns {JSX.Element}
 */
export default function Version({
  version,
  isPrimarySelection,
  isSecondarySelection,
  setSelectedVersionId,
  isDiffingEnabled,
  reloadVersions,
  isCurrentUser,
  renderVersionMenu,
  onRenameVersion,
  renderPublishedTag = null,
  renderReview = null
}) {
  const [isEditing, setIsEditing] = useState(false);
  const { createdBy } = version;
  const authorName = createdBy.name;

  const createdByLabel = isCurrentUser ? 'you' : createdBy?.name;

  return (
    <Styled.Version
      // @ts-expect-error TS2769
      $isPrimary={isPrimarySelection}
      $isSecondary={isSecondarySelection && isDiffingEnabled}
      onClick={() => setSelectedVersionId(version)}
      role="listitem"
      aria-label={version.name}
      data-selected={isPrimarySelection || isSecondarySelection}
    >
      <Avatar size="small" fullname={authorName} disableTooltip />
      <Styled.VersionColumn>
        <Styled.VersionRow>
          <Styled.VersionContent>
            <Styled.VersionTitle
              initialValue={version.name || 'Version name'}
              isEditing={isEditing}
              onSubmit={(name) => onRenameVersion({ version, name, reloadVersions, setIsEditing })}
            >
              {version.name || 'Untitled version'}
            </Styled.VersionTitle>

            <Styled.VersionParagraph>
              <time dateTime={`${version.created}`}>{moment(version.created).format('HH:mm')}</time>
              {createdByLabel && ` - Created by ${createdByLabel}`}
            </Styled.VersionParagraph>
          </Styled.VersionContent>
          {renderPublishedTag?.({ version })}
          {renderVersionMenu({ version, setIsEditing, isCurrentUser })}
        </Styled.VersionRow>
        {renderReview?.({ version })}
      </Styled.VersionColumn>
    </Styled.Version>
  );
}
