/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

class SelectedElementExtensionStore {
  selectedElement = null;
  needsRefresh = false;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.selectedElement = null;
  }

  setSelectedElement(element) {
    this.selectedElement = element;
  }

  setNeedsRefresh(flag) {
    this.needsRefresh = flag;
  }
}
export default new SelectedElementExtensionStore();
