/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { diff } from 'deep-object-diff';

import { isCalledElement } from 'utils/web-modeler-diagram-parser';
import capitalize from 'utils/capitalize';
import { EXTENSION_ELEMENTS_PROPERTY } from 'utils/diffing/constants';

import sanitizeTechnicalTerms from './sanitize-technical-terms';
import getElements from './get-elements';

const cleanUpChanges = (dirtyChanges) => {
  const changes = [];

  dirtyChanges.forEach((dirtyChange) => {
    checkIfExtensionElementsChanged(dirtyChange, changes);

    if (typeof dirtyChange.after == 'object') {
      if (dirtyChange.property === 'Artifacts') {
        return;
      }
      let firstTypeInValues;
      let iterable;

      if (dirtyChange.after.$type && dirtyChange.after.values) {
        iterable = dirtyChange.after.values;
      }

      if (dirtyChange.after.length) {
        iterable = dirtyChange.after;
      }

      if (iterable) {
        iterable.forEach((el) => {
          if (!firstTypeInValues && el.$type) {
            firstTypeInValues = el.$type;
          }
        });

        const property = capitalize(sanitizeTechnicalTerms(firstTypeInValues));
        const after = true;

        changes.push({
          property,
          after
        });
      } else {
        Object.keys(dirtyChange.after).forEach((item) => {
          if (item !== 'values') {
            const property = capitalize(sanitizeTechnicalTerms(item));
            const before = typeof dirtyChange.before == 'object' ? dirtyChange.before[item] : null;
            const after = dirtyChange.after[item];

            changes.push({
              property,
              before,
              after
            });
          } else {
            // Add change in calledElement if present
            const type = dirtyChange.before?.values?.[0]?.$type;
            if (isCalledElement(type)) {
              const property = capitalize(sanitizeTechnicalTerms(type));
              const before = dirtyChange.before?.values[0]?.processId;
              const after = dirtyChange.after?.values[0]?.processId;
              if (before || after) {
                changes.push({
                  property,
                  before,
                  after
                });
              }
            }
          }
        });
      }
    } else if (typeof dirtyChange.before != 'object') {
      changes.push(dirtyChange);
    }
  });

  return changes;
};

export default function diffModdleDefinitions(base, top) {
  const diffResponse = {
    targetEngineVersion: diffTargetEngineVersion(base, top), // changes in the definition tag
    new: [], // new elements
    modified: [] // modified elements
  };

  let baseElements = {};
  let topElements = {};

  base.definition?.rootElements?.forEach((element) => getElements(baseElements, element));
  top.definition?.rootElements?.forEach((element) => getElements(topElements, element));

  const newElements = [];
  const modifiedElements = [];

  // iterate over all the elements in the topDefinitions
  Object.keys(topElements).forEach((topElementId) => {
    // if a topElement did not yet existed in the baseDiagram -> they are 'new'.
    const id = top.oldIdMap[topElementId] ? top.oldIdMap[topElementId] : topElementId;
    if (!baseElements[id]) {
      const elementToAdd = {
        id,
        type: topElements[topElementId].$type
      };
      // if the element's type is not Collaboration or Process
      if (!['bpmn:Collaboration', 'bpmn:Process'].includes(elementToAdd.type)) {
        newElements.push(elementToAdd);
      }
    } else {
      // otherwise, the element existed before and we want to check if something changed
      const baseElement = baseElements[topElementId];
      const topElement = topElements[topElementId];

      baseElement.attrs = baseElement.$attrs;
      topElement.attrs = topElement.$attrs;

      if (topElement.$type === 'bpmn:SequenceFlow') {
        baseElement.Source = baseElement.sourceRef.id;
        baseElement.Target = baseElement.targetRef.id;
        baseElement.isConditional = !!baseElement.conditionExpression;

        topElement.Source = topElement.sourceRef.id;
        topElement.Target = topElement.targetRef.id;
        topElement.isConditional = !!topElement.conditionExpression;
      }

      const modificationsObject = diff(baseElement, topElement);

      const modificationsKeys = Object.keys(modificationsObject).filter((keyName) => keyName !== 'flowElements');

      let iconPosition;

      if (modificationsKeys.length > 0) {
        // there are differences between the baseElement and the topElement

        const dirtyChanges = modificationsKeys
          .map((modificationKey) => {
            let removed;

            // if the source or Target changed, check if the base element was removed from the diagram
            if (
              (modificationKey === 'Target' || modificationKey === 'Source') &&
              !topElements[baseElement[modificationKey]]
            ) {
              removed = true;
            }

            if (modificationKey === 'Target') {
              iconPosition = 'end';
            }
            if (modificationKey === 'Source') {
              iconPosition = 'start';
            }

            const property = capitalize(sanitizeTechnicalTerms(modificationKey));

            /**
             * Set before and after values
             * - if we have a change for the Source or Target we want the label of the source and target refs instead of the ids, so we try to get them
             * - if we were not able to get any labels, we use the ids instead
             */
            let after;
            let before;

            if (modificationKey === 'Source') {
              after = topElement.sourceRef.name;
              before = baseElement.sourceRef.name;
            }

            if (modificationKey === 'Target') {
              after = topElement.targetRef.name;
              before = baseElement.targetRef.name;
            }

            if (!after) after = sanitizeTechnicalTerms(modificationsObject[modificationKey]);
            if (!before) before = sanitizeTechnicalTerms(baseElement[modificationKey]);

            return {
              property,
              after,
              before,
              removed
            };
          })
          .filter((change) => {
            // clean up list of changes, apply some rules:
            // - if the artifacts property changed, and it was empty before, all those in after are now added elmenents and shall be removed from the changes array
            if (change.property === 'artifacts' && change.before === '') {
              change.after.forEach((element) => {
                newElements.push({ id: element.id, type: element.$type });
              });
              return false;
            }

            return true;
          });

        const changes = cleanUpChanges(dirtyChanges);

        if (changes.length > 0) {
          const id = top.oldIdMap[topElementId] ? top.oldIdMap[topElementId] : topElementId;
          const type = topElement.$type;

          modifiedElements.push({
            id,
            type,
            changes,
            iconPosition
          });
        }
      }
    }
  });

  baseElements = null;
  topElements = null;

  diffResponse.new = newElements;
  diffResponse.modified = modifiedElements;

  return diffResponse;
}

const getShortVersion = (version) => {
  if (version?.length > 3) {
    return version.substring(0, 3);
  }
  return null;
};

const diffTargetEngineVersion = (base, top) => {
  const baseDefinition = base.definition;
  const topDefinition = top.definition;
  if (baseDefinition?.executionPlatformVersion !== topDefinition?.executionPlatformVersion) {
    return {
      after: getShortVersion(topDefinition?.executionPlatformVersion),
      before: getShortVersion(baseDefinition?.executionPlatformVersion)
    };
  }
  return {};
};

const checkIfExtensionElementsChanged = (dirtyChange, changes) => {
  const isExtensionElementsChanged = dirtyChange?.property === EXTENSION_ELEMENTS_PROPERTY;
  if (isExtensionElementsChanged) {
    changes.push({
      property: dirtyChange.property
    });
  }
};
