/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { styled } from 'styled-components';

export const SidePanelHeaderContainer = styled.div`
  background-color: var(--cds-background-selected);
  width: 100%;
  padding: 1rem;
`;

export const SidePanelHeader = styled.h4`
  font-size: 16px;
  line-height: 18px;
`;

/* Note: we are intentionally not using carbon design system headers
/ as we're rendering user markdown content in a restricted space */
export const SidePanelEntryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem;
  margin-bottom: 2rem;

  h1 {
    font-size: 32px;
    line-height: 40px;
  }

  h2 {
    font-size: 28px;
    line-height: 36px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    font-size: 20px;
    line-height: 28px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-size: 14px;
    line-height: 20px;
  }

  font-size: 14px;
  line-height: 18px;
`;

export const SidePanelHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const SidePanelHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SidePanelInfoText = styled.p`
  font-size: 12px;
  line-height: 16px;
`;

export const NodeLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0f62fe;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
`;

export const DisabledText = styled.span`
  color: var(--cds-text-disabled);
  font-style: italic;
`;
