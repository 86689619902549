/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { Dropdown, Stack, TextArea, TextInput } from '@carbon/react';
import { observer } from 'mobx-react';

import { EXTRACTION_FIELD_TYPES } from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';
import { idpProjectStore } from 'stores';
import { getExtractionFieldNameInvalidText } from 'App/Pages/Project/IdpApplication/idp-project/utils/utils';

import * as Styled from './EditExtractionFieldModal.styled';

function EditExtractionFieldModal({ isOpen, editingExtractionField, onSaveChanges, onCloseModal }) {
  const [extractionField, setExtractionField] = useState(editingExtractionField);
  const extractionFieldsExcludingEditField = idpProjectStore.idpExtractionFields.filter(
    (extractionField) => extractionField.id !== editingExtractionField?.id
  );

  useEffect(() => {
    if (editingExtractionField) {
      setExtractionField(editingExtractionField);
    }
  }, [editingExtractionField]);

  const handleRequestSubmit = () => {
    onSaveChanges(extractionField);
    onCloseModal();
  };

  const isExtractionFieldInvalid = () => {
    return (
      !extractionField ||
      !extractionField.name ||
      !extractionField.type ||
      !extractionField.llmPromptDescription ||
      !!getExtractionFieldNameInvalidText(extractionField.name, extractionFieldsExcludingEditField) ||
      (editingExtractionField.name === extractionField.name &&
        editingExtractionField.llmPromptDescription === extractionField.llmPromptDescription &&
        editingExtractionField.type === extractionField.type)
    );
  };

  const findExtractionFieldTypeByTypeId = (id) => {
    return EXTRACTION_FIELD_TYPES.find((editingExtractionField) => editingExtractionField.id === id);
  };

  return (
    <>
      {extractionField && editingExtractionField && (
        <Styled.EditExtractionFieldModal
          open={isOpen}
          size="md"
          modalHeading="Edit field"
          primaryButtonText="Save changes"
          primaryButtonDisabled={isExtractionFieldInvalid()}
          secondaryButtonText="Cancel"
          onRequestSubmit={handleRequestSubmit}
          onRequestClose={onCloseModal}
          preventCloseOnClickOutside
        >
          <Stack gap={5}>
            <TextInput
              autoComplete="off"
              data-modal-primary-focus
              enableCounter
              id="extraction-field-name"
              labelText="Field name"
              value={extractionField.name}
              maxCount={100}
              onChange={(event) => {
                setExtractionField((field) => ({
                  ...field,
                  name: event.target.value
                }));
              }}
              invalid={!!getExtractionFieldNameInvalidText(extractionField.name, extractionFieldsExcludingEditField)}
              invalidText={getExtractionFieldNameInvalidText(extractionField.name, extractionFieldsExcludingEditField)}
            />
            <TextArea
              autoComplete="off"
              data-modal-primary-focus
              enableCounter
              id="extraction-field-prompt"
              labelText="Prompt description"
              value={extractionField.llmPromptDescription}
              maxCount={255}
              onChange={(event) => {
                setExtractionField((field) => ({
                  ...field,
                  llmPromptDescription: event.target.value
                }));
              }}
            />
            <Dropdown
              id="extraction-field-type"
              titleText="Type"
              label=""
              aria-label="Type"
              initialSelectedItem={findExtractionFieldTypeByTypeId(extractionField.type)}
              selectedItem={findExtractionFieldTypeByTypeId(extractionField.type)}
              items={EXTRACTION_FIELD_TYPES}
              itemToString={(type) => (type ? type.name : '')}
              onChange={({ selectedItem }) => {
                setExtractionField((field) => ({
                  ...field,
                  type: selectedItem.id
                }));
              }}
            />
          </Stack>
        </Styled.EditExtractionFieldModal>
      )}
    </>
  );
}

export default observer(EditExtractionFieldModal);
