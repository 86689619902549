/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { fileService } from 'services';
import { isEmpty } from 'utils/helpers';
import throttle from 'utils/throttle-async';
import hasAccess, { actions } from 'utils/user-access';

import { handleError, handleUpdateError } from './readmeErrorHandling';
import { fetchReadmeInfo, updateReadme } from './readmeService';

export class ReadmeEditorStore {
  project = null;
  readme = null;
  loading = true;
  saving = false;
  lastSaved = null;

  constructor(readmeId, history) {
    makeObservable(this, {
      project: observable,
      readme: observable,
      loading: observable,
      saving: observable,
      lastSaved: observable,
      status: computed,
      isReadOnly: computed,
      updateReadmeContent: action,
      reloadReadme: action
    });

    this._history = history;
    this._init(readmeId);
  }

  _init = async (readmeId) => {
    try {
      const { readme, project } = await fetchReadmeInfo(readmeId);
      runInAction(() => {
        this.readme = { ...readme, content: trimEmptyToken(readme.content) };
        this.project = project;
      });
    } catch (ex) {
      handleError(ex, 'Could not load the readme. Please try again later.');
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateReadmeContent = throttle(async (newContent) => {
    runInAction(() => {
      this.saving = true;
    });
    try {
      const updatedFile = await updateReadme(this.readme, supplementEmptyToken(newContent));
      runInAction(() => {
        this.lastSaved = new Date();
        this.readme = {
          ...this.readme,
          revision: updatedFile.revision,
          content: trimEmptyToken(updatedFile.content)
        };
      });
    } catch (error) {
      handleUpdateError(error);
      await this.reloadReadme();
    } finally {
      runInAction(() => {
        this.saving = false;
      });
    }
  });

  clearReadme = () => {
    runInAction(() => {
      this.readme = null;
    });
  };

  reloadReadme = async () => {
    try {
      const file = await fileService.fetchById(this.readme.id);
      if (file && file.content) {
        runInAction(() => {
          this.readme = {
            ...this.readme,
            revision: file.revision,
            content: trimEmptyToken(file.content)
          };
        });
      }
    } catch (error) {
      handleError(error, 'Could not reload the readme. Please try again later.');
    }
  };

  get status() {
    if (this.loading) {
      return null;
    }
    if (this.saving) {
      return { status: 'progress', message: 'Saving...' };
    }
    if (this.lastSaved) {
      const time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(this.lastSaved);
      return { status: 'done', message: `Autosaved at ${time}` };
    }
    return null;
  }

  get isReadOnly() {
    return !hasAccess(this.project, actions.MODIFY_README);
  }

  get canViewVersions() {
    return hasAccess(this.project, actions.VIEW_VERSIONS);
  }
}

// todo: remove when the backend supports empty markdown files properly

const EMPTY_TOKEN = '<!-- EMPTY_MARKDOWN -->';

const trimEmptyToken = (content) => {
  return content.replace(EMPTY_TOKEN, '');
};

const supplementEmptyToken = (content) => {
  return isEmpty(content) ? EMPTY_TOKEN + content : content;
};
