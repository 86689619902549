/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { CodeSnippet } from '@carbon/react';
import moment from 'moment';

import capitalize from 'utils/capitalize';

import * as Styled from './InboundConnector.styled';

const ERROR_SEVERITY = 'ERROR';
const WARNING_SEVERITY = 'WARNING';

export const InboundConnectorLog = ({ log }) => {
  const getMomentDate = (log) => {
    if (typeof log?.timestamp === 'string') {
      return moment(new Date(log?.timestamp));
    } else {
      return moment(log?.timestamp * 1000);
    }
  };

  const getIcon = (log) => {
    if (log.severity === ERROR_SEVERITY) {
      return <Styled.RedErrorFilled size={16} />;
    } else if (log.severity === WARNING_SEVERITY) {
      return <Styled.YellowWarningFilled size={16} />;
    } else {
      return <Styled.GreenCheckmarkFilled size={16} />;
    }
  };

  const getTag = (log) => {
    return capitalize(log.tag);
  };

  return (
    <Styled.LogContainer>
      <Styled.LogMetaInfo>
        <Styled.IconContainer>{log && getIcon(log)}</Styled.IconContainer>
        <div>
          <Styled.LogTag size="sm" type="warm-gray" title={log?.tag}>
            {log?.tag && log.tag.length > 0 && getTag(log)}
          </Styled.LogTag>
        </div>
        <Styled.LogDate>{getMomentDate(log).format('YYYY-MM-DD HH:mm:ss')}</Styled.LogDate>
      </Styled.LogMetaInfo>
      <Styled.CodeSnippetContainer>
        <CodeSnippet
          type="multi"
          feedback="Copied!"
          align="bottom-right"
          wrapText
          maxCollapsedNumberOfRows={2}
          minExpandedNumberOfRows={0}
        >
          {log?.message}
        </CodeSnippet>
      </Styled.CodeSnippetContainer>
    </Styled.LogContainer>
  );
};

export default observer(InboundConnectorLog);
