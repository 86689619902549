/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { createPortal } from 'react-dom';
import { Modal } from '@carbon/react';
import styled from 'styled-components';

/**
 * @param {{ comment: string, open: boolean, onRequestClose: () => void }} props
 * @returns {JSX.Element}
 */
export function ReviewCommentModal({ comment, open, onRequestClose }) {
  return createPortal(
    <Modal
      open={open}
      passiveModal
      modalHeading="Review comment"
      aria-label="Review comment"
      closeButtonLabel="Close"
      hasScrollingContent
      onRequestClose={onRequestClose}
      size="md"
    >
      <P>{comment}</P>
    </Modal>,
    document.body
  );
}

const P = styled.p`
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;
