/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Callout, InlineNotification } from '@carbon/react';
import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { notificationStore } from 'components/NotificationSystem';
import { clustersStore, organizationStore } from 'stores';
import { CLUSTER_STATUS } from 'utils/cluster/constants';
import { useClusterStatus, useReloadClustersOnClusterUpdate } from 'App/Pages/Project/IdpApplication/hooks';
import { clusterService } from 'services';
import { IDP_EXTRACTION_CONNECTOR_SECRETS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

import * as Styled from './ClusterNotification.styled';

function ClusterNotificationSaas({ clusterId, areIdpConnectorSecretsMissing }) {
  const { clusters } = clustersStore;
  const selectedCluster = clusters?.find((cluster) => cluster.uuid === clusterId);
  const notificationPropsMap = {
    [CLUSTER_STATUS.RESUMING]: {
      title: 'Your cluster is being resumed: ',
      subtitle: `This will take a few minutes.`,
      kind: 'info'
    },
    [CLUSTER_STATUS.UPDATING]: {
      title: 'Cluster Updating: ',
      subtitle: `Cluster '${selectedCluster?.name}' is being updated.`,
      kind: 'warning'
    },
    [CLUSTER_STATUS.SUSPENDED]: {
      title: 'Cluster Unavailable: ',
      subtitle: `The cluster '${selectedCluster?.name}' is paused. To continue, select 'Resume cluster'.`,
      kind: 'warning',
      actionButtonLabel: 'Resume cluster',
      onActionButtonClick: async () => {
        try {
          await clusterService.resumeCluster(organizationStore.currentOrganizationId, clusterId);
        } catch {
          notificationStore.showError('Unable to resume cluster. Please wait a few minutes and try again.');
        }
      }
    },
    [CLUSTER_STATUS.UNHEALTHY]: {
      title: 'Cluster Unavailable: ',
      subtitle: `Cluster '${selectedCluster?.name}' is unhealthy. To continue, select 'Manage cluster' to resolve the issue in the Console.`,
      kind: 'warning',
      actionButtonLabel: 'Manage cluster',
      onActionButtonClick: () => {
        window.open(`${organizationStore.consoleDashboardPageUrl}/cluster/${selectedCluster?.uuid}`, '_blank');
      }
    },
    [CLUSTER_STATUS.MISSING]: {
      title: 'Cluster Unavailable: ',
      subtitle: `Cluster is missing. If the cluster has been deleted, it could result in content loss. Please select 'Manage cluster' to review and address the issue in the Console.`,
      kind: 'error',
      actionButtonLabel: 'Manage cluster',
      onActionButtonClick: () => {
        window.open(`${organizationStore.consoleDashboardPageUrl}/cluster/${selectedCluster?.uuid}`, '_blank');
      }
    },
    [CLUSTER_STATUS.HEALTHY]: {
      title: 'Your cluster is healthy: ',
      subtitle: `you can proceed with your project.`,
      kind: 'success'
    }
  };

  const status = useClusterStatus(clusterId);
  const notificationProps = notificationPropsMap[status] || {};
  const previousStatus = useRef(status);
  useReloadClustersOnClusterUpdate(clusterId);

  useEffect(() => {
    previousStatus.current = status;
  }, [status]);

  return (
    <>
      {(previousStatus.current === CLUSTER_STATUS.RESUMING && status === CLUSTER_STATUS.HEALTHY) ||
        (status !== CLUSTER_STATUS.HEALTHY && (
          <Styled.NotificationContainer>
            {status === CLUSTER_STATUS.RESUMING ||
            (previousStatus.current === CLUSTER_STATUS.RESUMING && status === CLUSTER_STATUS.HEALTHY) ? (
              <InlineNotification
                hideCloseButton={status === CLUSTER_STATUS.RESUMING}
                // @ts-expect-error TS2339
                title={notificationProps.title}
                // @ts-expect-error TS2339
                subtitle={notificationProps.subtitle}
                // @ts-expect-error TS2339
                kind={notificationProps.kind}
              />
            ) : (
              <Callout titleId="cluster-status-notification-title" {...notificationProps} />
            )}
          </Styled.NotificationContainer>
        ))}
      {areIdpConnectorSecretsMissing && (
        <Styled.NotificationContainer>
          <Callout
            title="Missing IDP extraction requirements"
            titleId="missing-idp-extraction-requirements"
            subtitle={`Make sure the connector secrets (${IDP_EXTRACTION_CONNECTOR_SECRETS.join(', ')}) are added for
             cluster '${selectedCluster?.name}'.`}
            kind="warning"
            {...(organizationStore.hasClustersConnectorSecretCreatePermission
              ? {
                  actionButtonLabel: 'Manage secrets',
                  onActionButtonClick: () =>
                    window.open(
                      `${organizationStore.consoleDashboardPageUrl}/cluster/${selectedCluster?.uuid}/secrets`,
                      '_blank'
                    )
                }
              : {})}
          />
        </Styled.NotificationContainer>
      )}
    </>
  );
}

export default observer(ClusterNotificationSaas);
