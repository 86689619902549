/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import { DropTarget, EntityTable } from 'components';

import * as Styled from './FolderExplorer.styled';
import { FolderReadmeViewer } from './FolderReadmeViewer';

export function FolderExplorer({ onDrop = null, currentReadme, canEditReadme, rows, ...entityTableProps }) {
  return (
    <DropTarget isDisabled={!onDrop} onDrop={onDrop} display="block" preserveRootWhenDisabled>
      <Styled.FolderExplorerContainer>
        <EntityTable {...entityTableProps} rows={rows} />
        {rows.length > 0 && <FolderReadmeViewer currentReadme={currentReadme} canEditReadme={canEditReadme} />}
      </Styled.FolderExplorerContainer>
    </DropTarget>
  );
}

FolderExplorer.propTypes = {
  onDrop: PropTypes.any,
  currentReadme: PropTypes.any,
  ...EntityTable.propTypes
};
