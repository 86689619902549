/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button as CarbonButton } from '@carbon/react';

import { isPlayMode } from 'App/Pages/Diagram/Deployment/executeModal/modeUtils';
import { defineStagesModalStore } from 'App/Pages/Diagram/Deployment/defineStagesModal';

import * as Styled from './StagesHeader.styled';

export default function StagesHeader({ mode, onClose }) {
  return (
    <Styled.Container>
      <span>
        {isPlayMode(mode)
          ? 'To access Play, your diagram needs to be deployed in a development cluster.'
          : 'Your process application will be deployed in the following defined default clusters.'}
      </span>
      <CarbonButton
        size="md"
        kind="tertiary"
        onClick={() => {
          onClose();
          defineStagesModalStore.open({ executeModalMode: mode });
        }}
      >
        Define stages
      </CarbonButton>
    </Styled.Container>
  );
}
