/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import isEqual from 'lodash/isEqual';

import { authService, consoleService } from 'services';
import { JWT_ORGANIZATION_CLAIM } from 'utils/constants';
import { organizationStore } from 'stores';

import BaseUserStore from './UserStore.common';

class UserStore extends BaseUserStore {
  /**
   * Checks if the current user has revoked roles in the current organization.
   * If so, the token is forcefully refreshed.
   * @returns {Promise<Boolean>} `true` if the token was refreshed, `false` otherwise.
   */
  async checkRevokedRoles() {
    if (!organizationStore.currentOrganization) {
      return false;
    }

    await organizationStore.fetchOrganizationInfoFromConsole(organizationStore.currentOrganizationId);

    const members = await consoleService.getOrganizationMembers({
      organizationId: organizationStore.currentOrganizationId,
      useCache: false
    });
    const me = members.filter((member) => member.userId === authService.jwtUser?.sub)[0];
    const jwtOrg = authService.jwtUser[JWT_ORGANIZATION_CLAIM]?.find(
      (org) => org.id === organizationStore.currentOrganizationId
    );

    if (!me) {
      throw new Error('User not found in Console');
    }

    if (!jwtOrg) {
      throw new Error('Organization not found in JWT');
    }

    const { roles } = jwtOrg;
    const { roles: consoleRoles } = me;

    // If the roles do not match the ones in the JWT, the token is forcefully refreshed
    if (roles && consoleRoles && !isEqual([...roles].sort(), [...consoleRoles].sort())) {
      await this.#refreshTokenAndUpdateUser();
      return true;
    }

    return false;
  }

  /**
   * Refreshes the token and updates the user information.
   * This will trigger observers and update the UI.
   */
  #refreshTokenAndUpdateUser = async () => {
    await authService.refreshToken({ useCache: false });
    await authService.fetchJWTUser();
  };
}

export default new UserStore();
