/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const FolderReadmeContainer = styled.div`
  padding: var(--cds-spacing-05) var(--cds-spacing-04);
  background-color: white;
  min-height: 200px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--cds-spacing-06);
  padding: 0 var(--cds-spacing-05);
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: var(--cds-spacing-05);
  font-size: 16px;
  font-weight: 400;
  color: var(--cds-text-primary);
  flex: 1;
`;
