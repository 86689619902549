/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';

import { addBottomLeftOverlay } from 'App/Pages/Diagram/overlay-util';

import { getCallActivitiesWithLinkedProcess } from './utils';
import callActivityLinkStore from './CallActivityLinkStore';

const OVERLAY_TYPE = 'call-activity-menu';

export function CallActivityLinkExtension(bpmnjs, elementRegistry, eventBus, overlays) {
  const addOverlays = () => {
    elementRegistry.forEach((element) => addOverlayIfSupported(element));
  };

  const addOverlayIfSupported = (element) => {
    if (is(element, 'bpmn:CallActivity')) {
      addBottomLeftOverlay({ overlays, overlayType: OVERLAY_TYPE, element });
    }
  };

  const setCallActivitiesWithLinkedProcess = () => {
    const callActivities = getCallActivitiesWithLinkedProcess(bpmnjs);

    callActivityLinkStore.setLinkedCallActivities(callActivities);
  };

  const setSelectedElementIfSupported = (element) => {
    if (element && is(element, 'bpmn:CallActivity')) {
      callActivityLinkStore.setSelectedCallActivity(element);
    } else {
      callActivityLinkStore.setSelectedCallActivity(null);
    }
  };

  eventBus.on('import.done', () => {
    setCallActivitiesWithLinkedProcess();

    addOverlays();
  });

  eventBus.on('shape.added', (context) => {
    const { element } = context;

    addOverlayIfSupported(element);
  });

  eventBus.on('elements.changed', () => {
    setCallActivitiesWithLinkedProcess();
  });

  eventBus.on('selection.changed', (event) => {
    const { newSelection = [] } = event;

    const element = newSelection.length === 1 && newSelection[0];

    setSelectedElementIfSupported(element);
  });
}

CallActivityLinkExtension.$inject = ['bpmnjs', 'elementRegistry', 'eventBus', 'overlays'];

export default {
  __init__: ['callActivityLinkExtension'],
  callActivityLinkExtension: ['type', CallActivityLinkExtension]
};
