/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from 'services/Service';

class CreateNewElementTemplateService extends Service {
  async fetchResource(url) {
    return this.get(`/fetch-resources?url=${url}`);
  }

  async fetchImageResource(url) {
    return this.get(`/fetch-resources?url=${url}`, 'image/*');
  }

  async listMethods(dataSourceContent, type) {
    return this.post(`/internal-api/element-templates/operations`, {
      content: dataSourceContent,
      type: type
    });
  }

  async generateTemplate(dataSourceContent, type, includedMethodIds) {
    return this.post(`/internal-api/element-templates/generate`, {
      content: dataSourceContent,
      type: type,
      selectedOperationIds: includedMethodIds
    });
  }
}

export default new CreateNewElementTemplateService();
