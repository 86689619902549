/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Loading } from '@carbon/react';

import * as Styled from './LandscapeViewModal.styled';

export const LandscapeViewModalLoading = () => {
  const getLoadingColumn = () => {
    return (
      <Styled.LoadingColumn sm={4} md={8} lg={16}>
        <Loading withOverlay={false} />
      </Styled.LoadingColumn>
    );
  };

  return (
    <>
      <Styled.LoadingRow>{getLoadingColumn()}</Styled.LoadingRow>
    </>
  );
};
