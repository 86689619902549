/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { CheckmarkFilled, ErrorFilled, WarningFilled } from '@carbon/icons-react';

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  padding-top: 2px;
`;

export const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const GreenCheckmarkFilled = styled(CheckmarkFilled)`
  fill: var(--cds-support-success, #24a148);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;

export const YellowWarningFilled = styled(WarningFilled)`
  fill: var(--cds-support-warning, #f1c21b);
  [data-icon-path='inner-path'] {
    fill: black;
    opacity: 1;
  }
`;

export const RedErrorFilled = styled(ErrorFilled)`
  fill: var(--cds-support-error, #da1e28);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;

/**
 * @type {import('styled-components').IStyledComponent<'web', {
 *     $isPassed: boolean;
 * } & React.HTMLAttributes<HTMLDivElement>>}
 */
export const StatusLabelContainer = styled.div`
  font-size: 12px;
  color: ${(props) => (props.$isPassed ? 'var(--cds-text-secondary)' : 'var(--cds-support-error, #da1e28)')};
`;
