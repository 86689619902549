/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import CommandInterceptor from 'diagram-js/lib/command/CommandInterceptor';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';

import { publicationStore } from 'App/Pages/Diagram/stores';
import { findExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

export default class PublicationBehavior extends CommandInterceptor {
  constructor(eventBus) {
    super(eventBus);

    this.preExecute(['element.updateProperties', 'element.updateModdleProperties'], ({ context }) => {
      const { element, moddleElement = getBusinessObject(element), properties } = context;

      if (!is(element, 'bpmn:StartEvent')) {
        return;
      }

      if (is(moddleElement, 'bpmn:StartEvent')) {
        const { extensionElements } = properties;

        if (!getBusinessObject(element).get('extensionElements') && extensionElements) {
          const formDefinition = extensionElements.get('values').find((value) => is(value, 'zeebe:FormDefinition'));

          const formId = formDefinition?.get('formId'),
            formKey = formDefinition?.get('formKey');

          if (formId || isCustomFormKey(formKey)) {
            publicationStore.handlePersistedPublicAccessProperty();
          }
        }

        if (getBusinessObject(element).get('extensionElements') && !extensionElements) {
          publicationStore.handlePublicAccessPropertyPersistance(publicationStore.isPublicAccessEnabled);
        }
      }

      if (is(moddleElement, 'bpmn:ExtensionElements')) {
        const { values } = properties;

        if (
          !findExtensionElement(element, 'zeebe:FormDefinition') &&
          values.some((value) => is(value, 'zeebe:FormDefinition'))
        ) {
          const formDefinition = values.find((value) => is(value, 'zeebe:FormDefinition'));

          const formId = formDefinition?.get('formId'),
            formKey = formDefinition?.get('formKey');

          if (formId || isCustomFormKey(formKey)) {
            publicationStore.handlePersistedPublicAccessProperty();
          }
        }

        if (
          findExtensionElement(element, 'zeebe:FormDefinition') &&
          !values.some((value) => is(value, 'zeebe:FormDefinition'))
        ) {
          publicationStore.handlePublicAccessPropertyPersistance(publicationStore.isPublicAccessEnabled);
        }
      }

      if (is(moddleElement, 'zeebe:FormDefinition')) {
        const { formId, formKey } = properties;

        if (formId || isCustomFormKey(formKey)) {
          publicationStore.handlePersistedPublicAccessProperty();
        } else {
          publicationStore.handlePublicAccessPropertyPersistance(publicationStore.isPublicAccessEnabled);
        }
      }
    });
  }
}

PublicationBehavior.$inject = ['eventBus'];

function isCustomFormKey(formKey) {
  return formKey && !formKey.startsWith('camunda-forms:bpmn:');
}
