/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { idpApplicationService } from 'services';

export default function useLoadIdpApplication(idpProject) {
  const [idpApplication, setIdpApplication] = useState(null);

  useEffect(() => {
    if (idpProject) {
      (async () => {
        try {
          const idpApplication = await idpApplicationService.fetchIdpApplication(idpProject.idpApplicationId);
          setIdpApplication(idpApplication);
        } catch {
          console.error('Failed to fetch IDP application!');
        }
      })();
    }
  }, [idpProject]);

  return idpApplication;
}
