/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default function selectAndScrollToElement(modeler, elementId) {
  const canvas = modeler.get('canvas'),
    elementRegistry = modeler.get('elementRegistry'),
    selection = modeler.get('selection');

  const element = elementRegistry.get(elementId);
  if (element !== canvas.getRootElement()) {
    try {
      canvas.scrollToElement(element);
    } catch {
      // Element not present; fail silently
    }
  }

  selection.select(element);
}
