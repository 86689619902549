/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { SideNavItems, SideNavMenuItem, Stack } from '@carbon/react';
import { observer } from 'mobx-react';

import * as Styled from './BrowseBlueprintsModal.styled';

export const BrowseBlueprintsModalLeftFilters = ({
  availableFilters,
  activeButton,
  setActiveButton,
  setSelectedBlueprintType,
  filters,
  setFilters
}) => {
  const leftFilters = availableFilters?.filter((filter) => 'left' === filter.groupName).map((filter) => filter.filters);

  /**
   * Handle checkbox clicks, and update the filters state object.
   */
  const handleFilterCheckBoxOnclick = (filterGroup, filterValue) => {
    if (filters[filterGroup]?.length > 0) {
      // if there is at least one element in this category
      if (filters[filterGroup]?.includes(filterValue)) {
        // remove if we already have it
        filters[filterGroup] = filters[filterGroup].filter((item) => item !== filterValue);
      } else {
        // add if we don't have it yet
        filters[filterGroup].push(filterValue);
      }
    } else {
      // if there is no element in this category
      filters[filterGroup] = [filterValue];
    }
    setFilters(JSON.parse(JSON.stringify(filters)));
  };

  const deleteEveryLeftFilter = () => {
    availableFilters
      ?.filter((filter) => 'left' === filter.groupName)
      .map((leftFilters) => leftFilters.filters)
      .flat()
      .map((filter) => filter.value)
      .forEach((filterValue) => {
        delete filters[filterValue];
      });
    setFilters(JSON.parse(JSON.stringify(filters)));
  };

  const getBlueprintsButton = (filterOption, onClick) => {
    return (
      <Styled.BrowseBlueprintsButton
        key={filterOption.value}
        className={filterOption.value === activeButton ? 'active' : ''}
        kind="secondary"
        onClick={() => {
          setActiveButton(filterOption.value);
          setSelectedBlueprintType(filterOption.label);
          onClick();
        }}
        aria-label={filterOption.label}
      >
        {filterOption.label}
      </Styled.BrowseBlueprintsButton>
    );
  };

  const renderAccordion = (filter, filterGroup) => {
    return (
      <li key={'li_' + filter.value}>
        <Styled.FilterPanelAccordion>
          <Styled.FilterPanelAccordionItem title={filterGroup}>
            <ul key={'ul_' + filter.value}>
              {filter?.options?.map((filterOption) => {
                return (
                  <li key={'li_' + filterOption.value}>
                    <Styled.FilterPanelAccordionButton
                      key={filterOption.value}
                      className={filterOption.value === activeButton ? 'active' : ''}
                      kind="secondary"
                      onClick={() => {
                        setActiveButton(filterOption.value);
                        setSelectedBlueprintType(filterOption.label);
                        deleteEveryLeftFilter();
                        handleFilterCheckBoxOnclick(filter.value, filterOption.value);
                      }}
                      aria-label={filterOption.label}
                    >
                      {filterOption.label}
                    </Styled.FilterPanelAccordionButton>
                  </li>
                );
              })}
            </ul>
          </Styled.FilterPanelAccordionItem>
        </Styled.FilterPanelAccordion>
      </li>
    );
  };

  const renderButton = (filter) => {
    return filter?.options?.map((filterOption) => {
      return (
        <li key={'li_' + filterOption.value}>
          {getBlueprintsButton(filterOption, () => {
            deleteEveryLeftFilter();
            handleFilterCheckBoxOnclick(filter.value, filterOption.value);
          })}
        </li>
      );
    });
  };

  return (
    <div>
      <Styled.BrowseBlueprintsSideNav isFixedNav expanded isChildOfHeader={false} aria-label="Side nav">
        <SideNavItems>
          <SideNavMenuItem>
            <h5>Blueprints</h5>
          </SideNavMenuItem>
          <Styled.FilterPanelMenuItem key="0">
            <Stack>
              <ul key="ul">
                <li key="li">
                  {getBlueprintsButton({ value: '0', label: 'All blueprints' }, () => {
                    deleteEveryLeftFilter();
                  })}
                </li>
                {leftFilters?.map((leftFilter) => {
                  return leftFilter.map((filter) => {
                    const filterGroup = filter?.options[0]?.group;
                    return filterGroup ? renderAccordion(filter, filterGroup) : renderButton(filter);
                  });
                })}
              </ul>
            </Stack>
          </Styled.FilterPanelMenuItem>
        </SideNavItems>
      </Styled.BrowseBlueprintsSideNav>
    </div>
  );
};

export default observer(BrowseBlueprintsModalLeftFilters);
