/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const LINK_TYPES = {
  PARENT_OF: 'parentOf',
  FLOW: 'flow',
  CALL: 'call',
  REF: 'ref_link',
  FEEL: 'feel',
  INVALID: 'invalid'
};

export const PROCESS = 'PROCESS';
export const SUBPROCESS = 'SUBPROCESS';

export const REF = 'REF';

export const NODE_TYPES_MAPPING = {
  [PROCESS]: 'process',
  [SUBPROCESS]: 'subprocess',
  [REF]: 'ref_node'
};
