/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';

import { isContainedInProcessApplication } from 'utils/helpers';
import { milestoneStore, notificationStore, processApplicationStore } from 'stores';
import { projectService, fileService, trackingService, folderService, tracingService } from 'services';
import { Spinner } from 'primitives';
import { CodeEditor } from 'components';
import hasAccess, { actions } from 'utils/user-access';
import FileSwitcher from 'features/cycle-through-files/FileSwitcher';

import Sidebar from './Sidebar';
import Header from './Header';
import * as Styled from './ConnectorTemplateVersions.styled';

export function ConnectorTemplateVersions() {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    trackingService.trackPageView('milestone');

    init();

    return () => {
      milestoneStore.reset();
    };
  }, [params.id]);

  const init = async () => {
    setLoading(true);

    try {
      const file = await fileService.fetchById(params.id);

      const requests = [projectService.fetchById({ projectId: file.projectId })];
      if (isContainedInProcessApplication(file)) {
        const skipParentInitialization = true;
        requests.push(processApplicationStore.init(file.folderId, skipParentInitialization));
      } else if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }
      const [project, folder] = await Promise.all(requests);
      if (!hasAccess(project, actions.VIEW_VERSIONS)) {
        return history.push(`/connector-templates/${params.id}`);
      }
      const versionIdsFromUrl = params.slug?.split('...');

      milestoneStore.init({ ...file, folder }, project, [], versionIdsFromUrl);
      await milestoneStore.fetchAll();

      milestoneStore.trackMilestoneView('milestone-history');

      setLoading(false);
    } catch (ex) {
      notificationStore.showError('Yikes! Could not load the version data. Please try again later.');
      tracingService.traceError(ex, 'Error while loading connector template version data');
    }
  };

  return (
    <Styled.Container>
      {loading ? (
        <Spinner fullHeight />
      ) : (
        <>
          <Header />
          <Styled.LeftSection>
            {processApplicationStore.fromAProcessApplication && <FileSwitcher fileId={params.id} />}
            <CodeEditor
              titles={milestoneStore.titles}
              value={milestoneStore.primaryContent}
              modified={milestoneStore.secondaryContent}
            />
          </Styled.LeftSection>
          <Sidebar />
        </>
      )}
    </Styled.Container>
  );
}

export default observer(ConnectorTemplateVersions);
