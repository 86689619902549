/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { v4 } from 'uuid';

import getWebhookId from './getWebhookId';

export default function GenerateWebhookId(eventBus, modeling) {
  eventBus.on('commandStack.propertiesPanel.zeebe.changeTemplate.postExecute', (e) => {
    const element = e.context?.element;

    // Get reference to Webhook Id
    const webhookId = getWebhookId(element);

    // Set Webhook Id if empty
    if (webhookId && !webhookId?.value) {
      modeling.updateModdleProperties(element, webhookId, { value: v4() });
    }
  });
}

GenerateWebhookId.$inject = ['eventBus', 'modeling'];
