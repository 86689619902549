/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

import { Review, useInitReviewStore } from 'App/Pages/ProcessApplicationVersions/features/review';
import { renameProcessApplicationVersion } from 'App/Pages/ProcessApplicationVersions/features/rename';
import { VersionsList, DRAFT_VERSION, DraftVersion } from 'components';
import { organizationStore, userStore } from 'stores';
import { VersionMenu } from 'App/Pages/ProcessApplicationVersions/features/view-versions-list/version/version-menu';
import { openVersionCreationModal, VersionCreationModal } from 'features/create-process-app-version';

import { EmptyState } from './empty-state';
import { useSelectDraftIfSelectedVersionIsDeleted } from './use-select-draft-if-selected-version-is-deleted';

export default function ProcessApplicationVersionsList({
  versions,
  reloadVersions,
  processApplication,
  selectedVersionId,
  setSelectedVersionId,
  isDiffingEnabled
}) {
  useEffect(() => {
    setSelectedVersionId(DRAFT_VERSION.id);
  }, []);

  useSelectDraftIfSelectedVersionIsDeleted(versions, selectedVersionId, setSelectedVersionId, DRAFT_VERSION);

  const newestVersion = versions[0];

  useInitReviewStore({
    versions
  });

  const renderVersionMenu = ({ version, setIsEditing, isCurrentUser }) => (
    <VersionMenu
      versionId={version.id}
      versionName={version.name}
      processApplicationName={processApplication.name}
      reloadVersions={reloadVersions}
      setIsEditing={setIsEditing}
      isVersionByCurrentUser={isCurrentUser}
      hasElevatedOrganizationPermissions={organizationStore.hasElevatedOrganizationPermissions}
    />
  );

  const renderReview = ({ version }) => {
    const { createdBy } = version;
    const authorId = createdBy.id;

    return (
      <Review
        versionId={version.id}
        isNewestVersion={newestVersion.id === version.id}
        currentUserId={userStore.userId}
        versionCreatorUserId={authorId}
        hasElevatedOrganizationPermissions={organizationStore.hasElevatedOrganizationPermissions}
      />
    );
  };

  const renameVersion = async ({ name, version, reloadVersions, setIsEditing }) =>
    await renameProcessApplicationVersion(version.id, version.name, name, reloadVersions, setIsEditing);

  return (
    <VersionsList
      versions={versions}
      reloadVersions={reloadVersions}
      selectedVersionId={selectedVersionId}
      setSelectedVersionId={(version) => setSelectedVersionId(version.id)}
      isDiffingEnabled={isDiffingEnabled}
      currentUserId={userStore.userId}
      DraftVersion={
        <DraftVersion
          lastModified={processApplication?.files[0]?.changed}
          isPrimarySelection={selectedVersionId === DRAFT_VERSION.id}
          isSecondarySelection={false}
          setSelectedVersionId={setSelectedVersionId}
          onCreateVersion={openVersionCreationModal}
          VersionCreationModal={
            <VersionCreationModal processApplicationId={processApplication?.id} onVersionCreated={reloadVersions} />
          }
        />
      }
      EmptyState={<EmptyState isNoVersionsPresent={versions?.length === 0} />}
      renderVersionMenu={renderVersionMenu}
      renderReview={renderReview}
      onRenameVersion={renameVersion}
    />
  );
}
