/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

import { InlineEditable } from 'components/Form';

export const Version = styled.div`
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;

  font-size: 12px;

  color: var(--grey-darken-33);

  border-left: 6px solid transparent;

  cursor: pointer;

  button {
    margin-left: auto;
    opacity: 0;
    flex-shrink: 0;
  }

  &:hover button {
    opacity: 1;
  }

  .cds--btn,
  .cds--definition-term {
    opacity: 1;
  }

  ${(props) =>
    props.$isPrimary &&
    `
    border-left-color: var(--cds-blue-60);
    background-color: var(--cds-layer-02);
  `}

  ${(props) =>
    props.$isSecondary &&
    `
    border-left-color: var(--cds-blue-60);
    background-color: var(--cds-layer-02);

    &:hover {
      background-color: var(--silver-base-97);
    }
  `}

  ${(props) => {
    if (!props.$isSecondary && !props.$isPrimary) {
      return `
        &:hover {
          background-color: var(--silver-base-97);
        }
      `;
    }
  }}
`;

export const VersionContent = styled.div`
  margin: 0 15px;
  line-height: 1.5;
  flex: 1;
  min-width: 0;
`;

export const VersionTitle = styled(InlineEditable)`
  font-weight: bold;

  input {
    font-size: 12px;
    padding: 5px 8px;
    height: 28px;
  }
`;

export const VersionParagraph = styled.p`
  margin: 0;

  font-size: 11px;

  color: var(--grey-base-40);
`;

export const PlaceholderAvatar = styled.span`
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background-color: ${({ $isPrimary }) => ($isPrimary ? 'white' : 'var(--silver-darken-94)')};
`;

export const VersionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

export const VersionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .cds--tag {
    height: 28px;
    padding-inline: 10px;
  }
`;
