/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tab, TabList, Tabs } from '@carbon/react';
import { useRef } from 'react';
import styled from 'styled-components';

import { useCarbonTabsAriaControls } from 'hooks';

const VIEW_MODES_TABS_CLASSNAME = 'view-mode-tab';

/**
 * @param {Object} props
 * @param {Object} props.VisualViewer
 * @param {Object} props.CodeViewer
 * @param {import('.').ViewModes} props.viewModes
 */
export default function ViewModesTabs({ VisualViewer, CodeViewer, viewModes }) {
  const tabsContainerRef = useRef(null);
  const { view, setView, isVisualView, isCodeView } = viewModes;

  const ariaControls = useCarbonTabsAriaControls(tabsContainerRef, VIEW_MODES_TABS_CLASSNAME, false);

  return (
    <TabsContainer ref={tabsContainerRef}>
      <Tabs selectedIndex={view} onChange={(e) => setView(e.selectedIndex)}>
        <TabList aria-label="List of tabs" activation="manual">
          <Tab className={VIEW_MODES_TABS_CLASSNAME}>Visual view</Tab>
          <Tab className={VIEW_MODES_TABS_CLASSNAME}>Code view</Tab>
        </TabList>
      </Tabs>
      <TabPanels>
        {isVisualView && <TabPanel id={ariaControls[0]}>{VisualViewer}</TabPanel>}
        {isCodeView && <TabPanel id={ariaControls[1]}>{CodeViewer}</TabPanel>}
      </TabPanels>
    </TabsContainer>
  );
}

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TabPanels = styled.div`
  display: flex;
  flex: 1;
`;

export const TabPanel = styled.div`
  display: flex;
  flex: 1;
`;
