/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import * as Styled from './Switch.styled';

export default function Switch({ checked = false, disabled = false, onChange }) {
  return (
    <Styled.Track checked={checked}>
      <Styled.Input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
      <Styled.Thumb checked={checked} />
    </Styled.Track>
  );
}

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};
