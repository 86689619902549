/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import addQueryParameter from 'utils/add-query-parameter';
import { Service } from 'services';

class BrowseBlueprintsService extends Service {
  /**
   * Fetches the filters and returns the output of the process
   * @returns {Promise} - the promise of the fetch process
   */
  fetchFilters() {
    return this.#fetchFilters();
  }

  /**
   * Fetches the blueprints and returns the output of the process
   * @returns {Promise} - the promise of the fetch process
   */
  fetchBlueprints(filters, searchValue) {
    return this.#fetchBlueprints(filters, searchValue);
  }

  /**
   * Fetches the details of a specific blueprint and returns the output of the process
   * @param {*} blueprintId The marketplace id of the blueprint
   * @returns {Promise} - the promise of the fetch process
   */
  fetchBlueprintsDetailsData(blueprintId) {
    return this.#fetchBlueprintDetails(blueprintId);
  }

  async fetchTemplate(blueprintId) {
    const { templates } = await this.#fetchBlueprintDetails(blueprintId);
    if (templates) {
      return templates.filter((template) => template.url).map((template) => template.url);
    }
  }

  async #fetchBlueprintDetails(blueprintId) {
    return this.get(`/marketplace/blueprints/${blueprintId}`);
  }

  async #fetchBlueprints(filters, searchValue) {
    let fetchBlueprintsUrl = `/marketplace/blueprints`;
    fetchBlueprintsUrl = addQueryParameter(fetchBlueprintsUrl, 'includeFilelessBlueprints=true');
    if (filters) {
      Object.keys(filters).forEach((filterGroup) => {
        const queryParam = `${filterGroup}=${filters[filterGroup].toString()}`;
        fetchBlueprintsUrl = addQueryParameter(fetchBlueprintsUrl, queryParam);
      });
    }
    if (searchValue) {
      fetchBlueprintsUrl = addQueryParameter(fetchBlueprintsUrl, `search=${searchValue}`);
    }
    return this.get(fetchBlueprintsUrl);
  }

  async #fetchFilters() {
    return this.get(`/marketplace/blueprint-filters`);
  }
}

export default new BrowseBlueprintsService();
