/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import VariableTab from '@bpmn-io/variable-outline';
import '@bpmn-io/variable-outline/dist/style.css';

import { currentDiagramStore } from 'stores';

import { Container } from './VariablesTab.styled';

export default function VariablesTab() {
  const injector = currentDiagramStore?.modeler?.get?.('injector');
  return (
    injector && (
      <Container className="cds--g10">
        <VariableTab injector={injector} />
      </Container>
    )
  );
}
