/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { createEntity, trackSubResourceCreation } from 'components/TargetSelector/utils';
import { BPMN } from 'utils/constants';
import { fileService, tracingService } from 'services';
import { breadcrumbStore, currentDiagramStore, notificationStore } from 'stores';
import buildSlug from 'utils/buildSlug';
import { callActivityLinkStore } from 'App/Pages/Diagram/CallActivityLinking';
import { createOrUpdateExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

export default async function createBpmnDiagram(target, history) {
  const elementName = getBusinessObject(callActivityLinkStore?.selectedCallActivity)?.name || '';
  const attributes = elementName
    ? {
        name: elementName
      }
    : {};
  const entity = createEntity({
    type: BPMN,
    attributes,
    target
  });

  try {
    const diagram = await fileService.create(entity);
    createOrUpdateExtensionElement(
      callActivityLinkStore?.selectedCallActivity,
      'zeebe:CalledElement',
      { processId: diagram.processId },
      currentDiagramStore.modeler
    );
    await currentDiagramStore.saveContent();

    trackSubResourceCreation({
      newFileId: diagram.id,
      newFileType: BPMN,
      newFileTarget: target
    });
    breadcrumbStore.toggleEditingFor('diagram');
    history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
  } catch (ex) {
    console.error(ex);
    notificationStore.showError('Could not create new BPMN diagram. Please try again.');
    tracingService.traceError(ex, 'Failed to create new BPMN diagram');
  }
}
