/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import * as Styled from './styled';

export default function WithLink({ children, to, target }) {
  if (/^http(s)?:\/\//.test(to)) {
    return (
      <Styled.AnchorLink href={to} target={target}>
        {children}
      </Styled.AnchorLink>
    );
  } else if (to) {
    return <Styled.ReactRouterLink to={to}>{children}</Styled.ReactRouterLink>;
  }

  return children;
}
