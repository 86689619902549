/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import connectorTemplateStore from 'App/Pages/ConnectorTemplate/ConnectorTemplateStore';
import { currentDiagramStore, diagramExtensionStore, formStore, organizationStore } from 'stores';
import { trackingService } from 'services';
import { isDMN } from 'utils/helpers';
import hasAccess, { actions } from 'utils/user-access';
import URL from 'components/TopBar/url';

const infoBarLinks = [
  {
    key: 'docs',
    label: 'Documentation',
    onClick: () => {
      trackingService.trackTopBarAction('documentation', 'info', {
        url: URL.DOCUMENTATION
      });
      window.open(URL.DOCUMENTATION, '_blank');
    }
  },
  {
    key: 'academy',
    label: 'Camunda Academy',
    onClick: () => {
      trackingService.trackTopBarAction('academy', 'info', {
        url: URL.ACADEMY
      });
      window.open(URL.ACADEMY, '_blank');
    }
  },
  {
    key: 'feedbackAndSupport',
    label: 'Feedback and Support',
    onClick: () => {
      if (organizationStore.isEnterprise || organizationStore.isProfessional) {
        trackingService.trackTopBarAction('jira-support', 'info', {
          url: URL.JIRA_SUPPORT
        });
        window.open(URL.JIRA_SUPPORT, '_blank');
      } else {
        trackingService.trackTopBarAction('forum-support', 'info', {
          url: URL.FORUM_SUPPORT
        });
        window.open(URL.FORUM_SUPPORT, '_blank');
      }
    }
  },
  {
    key: 'slackCommunityChannel',
    label: 'Slack Community Channel',
    onClick: () => {
      trackingService.trackTopBarAction('slack', 'info', { url: URL.SLACK });
      window.open(URL.SLACK, '_blank');
    }
  }
];

export default ({ showInfoBar }) => {
  if (!showInfoBar) {
    return null;
  }

  const { diagram, project } = currentDiagramStore.state;
  const { connectorTemplate } = connectorTemplateStore;
  const { form } = formStore;

  const [elements, setElements] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // Populates the infobar with elements for the diagrams
  useEffect(() => {
    const elements = [];

    if (diagram) {
      const isDMNDiagram = isDMN(diagram);

      elements.push({
        key: 'appGuide',
        label: isDMNDiagram ? 'DMN Guide' : 'BPMN Guide',
        onClick: () => {
          const url = isDMNDiagram ? URL.DMN_GUIDE : URL.BPMN_GUIDE;

          setIsOpen(false);
          trackingService.trackTopBarAction('guide', 'info', { url });
          window.open(url, '_blank');
        }
      });
    }

    if (project && hasAccess(project, actions.MODIFY_DIAGRAM)) {
      elements.push({
        key: 'appShortcuts',
        label: 'Shortcuts',
        onClick: () => {
          trackingService.trackTopBarAction('shortcuts', 'info');
          diagramExtensionStore.showShortcutModal();
        }
      });
    }

    if (elements.length) {
      setElements(elements);
    }
  }, [project, diagram]);

  // Populates the infobar with elements for the forms
  useEffect(() => {
    const elements = [];

    if (form) {
      elements.push({
        key: 'appGuide',
        label: 'Form Guide',
        onClick: () => {
          setIsOpen(false);
          trackingService.trackTopBarAction('guide', 'info', {
            url: URL.FORM_REFERENCE
          });
          window.open(URL.FORM_REFERENCE, '_blank');
        }
      });
    }

    if (elements.length) {
      setElements(elements);
    }
  }, [form]);

  // Populates the infobar with elements for the Connector templates
  useEffect(() => {
    const elements = [];

    if (connectorTemplate) {
      elements.push({
        key: 'appGuide',
        label: 'Connector template guide',
        onClick: () => {
          setIsOpen(false);
          trackingService.trackTopBarAction('guide', 'info', {
            url: URL.CONNECTOR_TEMPLATE_GUIDE
          });
          window.open(URL.CONNECTOR_TEMPLATE_GUIDE, '_blank');
        }
      });
    }

    if (elements.length) {
      setElements(elements);
    }
  }, [connectorTemplate]);

  return {
    type: 'info',
    isOpen,
    ariaLabel: 'Info',
    elements: [...infoBarLinks, ...elements]
  };
};
