/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplicationWithOnlyDevClusterId} ProcessApplicationWithOnlyDevClusterId
 */

import { observer } from 'mobx-react';

import ExecuteModal from 'App/Pages/Diagram/Deployment/executeModal';
import DefineStagesModal, { defineStagesModalStore } from 'App/Pages/Diagram/Deployment/defineStagesModal';
import DeploymentErrorsModal from 'App/Pages/Diagram/Deployment/deploymentErrorsModal';
import { deploymentErrorsStore, executeModalStore } from 'App/Pages/Diagram/stores';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import { ClusterUpdateNotification } from 'components';

import { usePrepareStoresForDeployment } from './use-prepare-stores-for-deployment';

/**
 * Component for handling the deployment of a process application version.
 *
 * @param {Object} props - The component props.
 * @param {ProcessApplicationWithOnlyDevClusterId} props.processApplication - The process application object.
 * @param {Object|null} props.bpmnViewer - The BPMN viewer instance. If the bpmnViewer is null it means that the diagram is not loaded yet.
 *
 * @returns {JSX.Element} The rendered component with all the necessary component for the deployment.
 */
function ProcessApplicationVersionDeployModal({ processApplication, bpmnViewer }) {
  usePrepareStoresForDeployment(processApplication, bpmnViewer);
  const { deploymentErrors, isErrorsModalVisible, errorsModalTitle, errorsModalNotification } = deploymentErrorsStore;
  const { setMode: setExecuteModalMode } = executeModalStore;

  return (
    <ClusterUpdateNotification isModalOpen latestDeployedClusterId={deploymentStore.latestDeployedCluster?.uuid}>
      <DefineStagesModal
        onClose={() => {
          // Introducing a delay between transitioning from DefineStagesModal back to ExecuteModal
          setTimeout(() => {
            setExecuteModalMode(defineStagesModalStore.executeModalMode);
          }, 500);
        }}
      />
      <DeploymentErrorsModal
        open={isErrorsModalVisible}
        onClose={() => {
          deploymentErrorsStore.hideErrorsModal();
        }}
        errors={deploymentErrors?.errors}
        title={errorsModalTitle}
        notification={errorsModalNotification}
      />
      <ExecuteModal
        open={Boolean(executeModalStore.isVisible)}
        mode={executeModalStore.mode}
        onClose={() => {
          executeModalStore.close();
        }}
        deployTarget={'process application'}
      />
    </ClusterUpdateNotification>
  );
}

export default observer(ProcessApplicationVersionDeployModal);
