/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { StructuredListCell } from '@carbon/react';

export const NestedTableWrapper = styled.div`
  /* Reset the padding for nested table cells */
  margin-bottom: 10px;
  .cds--structured-list-th,
  .cds--structured-list-td {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
`;

export const StructuredListCellName = styled(StructuredListCell)`
  width: 50%;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: flex-start;
`;

export const StructuredListCellValue = styled(StructuredListCell)`
  width: 50%;
  white-space: normal;
  word-wrap: break-word;
  align-content: flex-start;
`;

export const ExtractedValueCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
