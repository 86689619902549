/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { actions, permissions } from './constants';

const mappings = new Map();

mappings.set(actions.MODIFY_PROJECT, [permissions.ADMIN]);

mappings.set(actions.VIEW_PROJECT, [permissions.READ, permissions.COMMENT, permissions.WRITE, permissions.ADMIN]);

mappings.set(actions.DELETE_PROJECT, [permissions.ADMIN]);

mappings.set(actions.LEAVE_PROJECT, [permissions.ADMIN, permissions.WRITE, permissions.READ, permissions.COMMENT]);

mappings.set(actions.VIEW_PROJECT_DROPDOWN, [
  permissions.ADMIN,
  permissions.WRITE,
  permissions.READ,
  permissions.COMMENT
]);

mappings.set(actions.VIEW_COLLABORATORS, [permissions.ADMIN, permissions.WRITE, permissions.COMMENT]);

mappings.set(actions.MODIFY_COLLABORATORS, [permissions.ADMIN]);

mappings.set(actions.CREATE_DIAGRAM, [permissions.ADMIN, permissions.WRITE]);

mappings.set(actions.DELETE_DIAGRAM, [permissions.ADMIN, permissions.WRITE]);

mappings.set(actions.MODIFY_DIAGRAM, [permissions.ADMIN, permissions.WRITE]);

mappings.set(actions.SHARE_DIAGRAM, [permissions.ADMIN, permissions.WRITE]);

mappings.set(actions.DELETE_MILESTONE, [permissions.ADMIN, permissions.WRITE]);

mappings.set(actions.VIEW_VERSIONS, [permissions.ADMIN, permissions.WRITE]);

mappings.set(actions.MODIFY_FORM, [permissions.ADMIN, permissions.WRITE]);
mappings.set(actions.VIEW_FORM_PROPERTIES, [permissions.COMMENT]);

mappings.set(actions.MODIFY_CONNECTOR_TEMPLATE, [permissions.ADMIN, permissions.WRITE]);

mappings.set(actions.VIEW_PROPERTIES, [permissions.ADMIN, permissions.WRITE, permissions.COMMENT]);
mappings.set(actions.VIEW_INBOUND_CONNECTOR_TAB, [permissions.ADMIN, permissions.WRITE]);
mappings.set(actions.VIEW_PUBLICATION_TAB, [permissions.ADMIN, permissions.WRITE]);

export default function hasAccess(project, action) {
  if (!project) {
    return false;
  }

  if (mappings.has(action)) {
    const mapping = mappings.get(action);
    return mapping.includes(project.permissionAccess);
  }

  return false;
}
