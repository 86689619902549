/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const DiagramControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: var(--grey-darken-33);
  padding: 5px;
  background-color: var(--cm-color-white-base);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  height: 32px;
`;

export const DiagramControlButton = styled.button`
  margin: 0px 5px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  height: 20px;
  width: 20px;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
