/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { notificationStore } from 'components/NotificationSystem';
import { confirmActionStore, idpApplicationStore, userStore } from 'stores';
import buildSlug from 'utils/buildSlug';
import { tracingService, idpProjectService } from 'services';
import history from 'utils/history';
import {
  EXTRACTION_MODELS,
  EXTRACTION_NOTIFICATION_STEPS,
  IDP_PROJECT_TYPE,
  INVALID_IDP_DOCUMENT_REASONS
} from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';
import { ConnectorError, extractDocument } from 'App/Pages/Project/IdpApplication/IdpProject/utils';
import localStorage from 'utils/localstorage';
import { isValidJSON } from 'utils/helpers';
import {
  findLlmModelIdWithLatestTestCaseResults,
  validateIdpDocuments
} from 'App/Pages/Project/IdpApplication/IdpProject/utils/utils';
import { ValidationError } from 'App/Pages/Project/IdpApplication/IdpProject/utils/ValidationError';

class IdpProjectStore {
  isExtractionProjectCreationModalVisible = false;
  isDocumentAutomationProjectCreationModalVisible = false;
  idpProject = null;
  idpDocuments = [];
  idpExtractionFields = [];
  loading = false;
  activeExtractionIdpDocument = null;
  extractionStepNotification = null;
  isOverrideTestCaseModalVisible = false;
  activeExtractionModel = null;
  modelTestCaseResultsMap = new Map();
  idpPublishedVersions = [];
  #idpExtractionNotificationFlagStorageKey = 'idp_extraction_notification_shown_once';

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(loading) {
    this.loading = loading;
  }

  reset() {
    this.setLoading(true);
    this.setIdpProject({});
    this.setIdpDocuments([]);
    this.setIdpExtractionFields([]);
    this.setExtractionStepNotification(null);
    this.setActiveExtractionIdpDocument(null);
    this.setActiveExtractionModel(null);
    idpApplicationStore.reset();
  }

  setIdpPublishedVersions(idpPublishedVersions) {
    this.idpPublishedVersions = idpPublishedVersions;
  }

  addModelTestCaseResults(modelId, testCaseResults) {
    this.modelTestCaseResultsMap.set(modelId, testCaseResults);
  }

  doesTestCaseResultExistForModelId(modelId) {
    return this.modelTestCaseResultsMap.has(modelId);
  }

  getModelTestCaseResults(modelId) {
    return this.modelTestCaseResultsMap.get(modelId);
  }

  resetModelTestCaseResultsMap() {
    this.modelTestCaseResultsMap = new Map();
  }

  setIsExtractionProjectCreationModalVisible(isExtractionProjectCreationModalVisible) {
    this.isExtractionProjectCreationModalVisible = isExtractionProjectCreationModalVisible;
  }

  setIsDocumentAutomationProjectCreationModalVisible(isDocumentAutomationProjectCreationModalVisible) {
    this.isDocumentAutomationProjectCreationModalVisible = isDocumentAutomationProjectCreationModalVisible;
  }

  setIdpExtractionFields(idpExtractionFields) {
    this.idpExtractionFields = idpExtractionFields;
  }

  setActiveExtractionIdpDocument(activeExtractionIdpDocument) {
    this.activeExtractionIdpDocument = activeExtractionIdpDocument;
  }

  setExtractionStepNotification(extractionNotificationStep) {
    if (localStorage.getItem(this.#idpExtractionNotificationFlagStorageKey) !== 'false') {
      this.extractionStepNotification = extractionNotificationStep;
    } else {
      this.extractionStepNotification = null;
    }
    localStorage.setItem(
      this.#idpExtractionNotificationFlagStorageKey,
      extractionNotificationStep === EXTRACTION_NOTIFICATION_STEPS.REPEAT_FOR_ALL_DOCUMENTS ? 'false' : 'true'
    );
  }

  setIdpProject(idpProject) {
    this.idpProject = idpProject;
  }

  setIdpDocuments(idpDocuments) {
    this.idpDocuments = idpDocuments;
  }

  setActiveExtractionModel(extractionModel) {
    this.activeExtractionModel = extractionModel;
  }

  setIsOverrideTestCaseModalVisible(isOverrideTestCaseModalVisible) {
    this.isOverrideTestCaseModalVisible = isOverrideTestCaseModalVisible;
  }

  startCreation(idpProjectType) {
    switch (idpProjectType) {
      case IDP_PROJECT_TYPE.EXTRACTION:
        this.setIsExtractionProjectCreationModalVisible(true);
        this.setIsDocumentAutomationProjectCreationModalVisible(false);
        break;
      case IDP_PROJECT_TYPE.DOCUMENT_AUTOMATION:
        this.setIsExtractionProjectCreationModalVisible(false);
        this.setIsDocumentAutomationProjectCreationModalVisible(true);
        break;
    }
  }

  async init(idpProjectId, skipParentInitialization = false) {
    let idpProjectFile;
    if (userStore.isAuthenticated) {
      this.setLoading(true);
      try {
        idpProjectFile = await idpProjectService.fetchIdpProject(idpProjectId);
        this.setIdpProject(idpProjectFile);
        const documents = await idpProjectService.fetchIdpDocuments(idpProjectId);
        this.setIdpDocuments(documents);
        if (documents.length > 0) {
          const [document] = documents;
          this.setActiveExtractionIdpDocument(document);
          await this.fetchIdpExtractionFields(document.id);
        }
        if (!skipParentInitialization) {
          await idpApplicationStore.init(this.idpProject.idpApplicationId);
        }
        await this.getExtractionProjectVersions(idpProjectId);
        await this.determineAndSetActiveExtractionModel((llmModelId) => this.setActiveExtractionModel(llmModelId));
      } catch (err) {
        notificationStore.showError("Yikes! Couldn't retrieve your IDP project. Please try again later.");
        tracingService.traceError(err, 'Failed to retrieve IDP project');
      } finally {
        this.setLoading(false);
      }
    }
    return idpProjectFile;
  }

  async finalizeCreation(name, description, type, extractionMethod) {
    try {
      const idpApplicationId = idpApplicationStore.idpApplication.id;

      this.setIdpProject(
        await idpProjectService.createIdpProject({
          idpApplicationId,
          name,
          description,
          type,
          extractionMethod
        })
      );

      history.push(`/idp-projects/${buildSlug(this.idpProject.id, this.idpProject.name)}`);
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't create your IDP project. Please try again later.");
      tracingService.traceError(err, 'Failed to create IDP project');
    }
  }

  async determineAndSetActiveExtractionModel(setActiveExtractionModelFn) {
    if (this.idpPublishedVersions.length > 0) {
      // Since `getExtractionProjectVersions` already sorts the version by `created`. We just take the `llmModelId`
      // from the first version in the list.
      const [{ llmModelId }] = this.idpPublishedVersions;
      setActiveExtractionModelFn(llmModelId);
    } else {
      await Promise.all(
        EXTRACTION_MODELS.map((model) => this.fetchIdpExtractionFieldTestcaseResults(this.idpProject.id, model.id))
      );
      const llmModelIdWithLatestTestCaseResults = findLlmModelIdWithLatestTestCaseResults(this.modelTestCaseResultsMap);
      setActiveExtractionModelFn(llmModelIdWithLatestTestCaseResults ?? EXTRACTION_MODELS[0].id);
    }
  }

  async rename(name) {
    if (name.trim().length === 0 || this.idpProject.name === name) {
      return;
    }
    try {
      await idpProjectService.renameIdpProject(this.idpProject.id, name);
      this.setIdpProject({ ...this.idpProject, name });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not rename your IDP project. Please try again later.');
      tracingService.traceError(ex, 'Failed to rename IDP project');
    }
  }

  async delete(idpProjectId) {
    try {
      const confirmed = await confirmActionStore.confirm({
        title: `Deleting IDP project`,
        text: (
          <>
            You're about to delete the IDP project "{this.idpProject.name}" and its files. Collaborators won't be able
            to access them afterwards.
          </>
        ),
        confirmLabel: 'Delete',
        isDangerous: true
      });

      if (!confirmed) {
        return false;
      }

      await idpProjectService.deleteIdpProject(idpProjectId);

      notificationStore.showSuccess('Your IDP project has been deleted.');
      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not delete your IDP project. Please try again later.');
      tracingService.traceError(ex, 'Failed to delete IDP project');

      return false;
    }
  }

  getIdpDocument = async (idpDocumentId) => {
    try {
      return await idpProjectService.getIdpDocument(idpDocumentId);
    } catch (ex) {
      notificationStore.showError('Yikes! Could not fetch your IDP document. Please try again later.');
      tracingService.traceError(ex, 'Failed to fetch IDP document');
    }
  };

  uploadIdpDocuments = async (documents, onDocumentsUploadProgress) => {
    const uploadingDocuments = Array.from(documents).map((document) => {
      return {
        // This ID is temporary, it will be replaced with actual ID from backend once upload is complete.
        // It is added as we need to show the documents in the table even when documents are being uploaded
        // where it serves as a key.
        id: uuidv4(),
        name: document.name,
        isUploading: true
      };
    });
    try {
      validateIdpDocuments(Array.from(documents));

      this.setIdpDocuments(this.idpDocuments.concat(uploadingDocuments));

      const uploadedDocuments = await idpProjectService.uploadDocuments(
        this.idpProject.id,
        documents,
        onDocumentsUploadProgress
      );

      this.setIdpDocuments(
        this.idpDocuments.filter((idpDocument) => !idpDocument.isUploading).concat(uploadedDocuments)
      );

      const notificationProp = {
        title: documents?.length > 1 ? 'All documents successfully uploaded' : 'Document successfully uploaded',
        content:
          'Next, navigate to the Extract Data tab to define the extraction fields and create a structured template to test.'
      };
      const successNotification = notificationStore.notifications.find(
        (notification) => notification.props.title === notificationProp.title
      );
      if (successNotification) {
        notificationStore.disposeNotification(successNotification.id, 'success');
      }
      notificationStore.success(notificationProp);
    } catch (err) {
      this.setIdpDocuments(this.idpDocuments.filter((idpDocument) => !idpDocument.isUploading));

      if (
        err instanceof ValidationError ||
        (isValidJSON(err?.response) && JSON.parse(err.response)?.errors?.length > 0)
      ) {
        const [{ reason, detail }] = JSON.parse(err.response).errors;
        notificationStore.error({
          title: INVALID_IDP_DOCUMENT_REASONS[reason]?.title ?? 'Document upload failed',
          content: detail ?? 'Please try again. If the problem persists, please get in touch with the support team.'
        });
      } else {
        if (documents.length === 1) {
          const [{ name }] = documents;
          notificationStore.error({
            title: 'Document upload failed',
            content: `${name} upload failed. Please check the file and try again.`
          });
        } else {
          notificationStore.error({
            title: 'Documents upload failed',
            content: 'Please try again. If the problem persists, please get in touch with the support team.'
          });
        }
      }
      tracingService.traceError(err, 'Failed to upload documents to IDP project');
    }
  };

  deleteIdpDocument = async (idpDocumentId) => {
    try {
      await idpProjectService.deleteIdpDocument(idpDocumentId);
      this.setIdpDocuments(this.idpDocuments.filter((idpDocument) => idpDocument.id !== idpDocumentId));
      if (this.activeExtractionIdpDocument?.id === idpDocumentId) {
        if (this.idpDocuments.length > 0) {
          this.setActiveExtractionIdpDocument(this.idpDocuments[0]);
        } else {
          this.setActiveExtractionIdpDocument(null);
          await Promise.all(this.idpExtractionFields.map((field) => this.deleteIdpExtractionField(field.id), false));
        }
      }
      notificationStore.showSuccess('IDP document has been deleted.');
    } catch (ex) {
      notificationStore.showError('Yikes! Could not delete your IDP document. Please try again later.');
      tracingService.traceError(ex, 'Failed to delete IDP document');
    }
  };

  extractIdpDocuments = async (idpProjectId, clusterId, idpDocumentIds, idpExtractionFields, modelId) => {
    try {
      const idpDocumentById = new Map(this.idpDocuments.map((idpDocument) => [idpDocument.id, idpDocument]));
      const uploadedIdpDocuments = await Promise.all(
        idpDocumentIds.map(async (idpDocumentId) => {
          const idpDocument = idpDocumentById.get(idpDocumentId);
          // Use the `idpDocument` if document with `clusterDocumentId` exists in `idpDocuments` and is not expired.
          if (
            !!idpDocument &&
            !!idpDocument.clusterDocumentId &&
            !!idpDocument.clusterDocumentExpiresAt &&
            moment(new Date(idpDocument.clusterDocumentExpiresAt)).isAfter()
          ) {
            return idpDocument;
          } else {
            return await idpProjectService.uploadIdpDocumentToCluster(idpDocument.id);
          }
        })
      );

      const uploadedIdpDocumentById = new Map(
        uploadedIdpDocuments.map((uploadedIdpDocument) => [uploadedIdpDocument.id, uploadedIdpDocument])
      );
      this.setIdpDocuments(
        this.idpDocuments.map((idpDocument) => {
          if (uploadedIdpDocumentById.has(idpDocument.id)) {
            return uploadedIdpDocumentById.get(idpDocument.id);
          } else {
            return idpDocument;
          }
        })
      );
      if (uploadedIdpDocumentById.has(this.activeExtractionIdpDocument?.id)) {
        const uploadedIdpDocument = uploadedIdpDocumentById.get(this.activeExtractionIdpDocument?.id);
        this.setActiveExtractionIdpDocument(uploadedIdpDocument);
      }
      const taxonomy = idpExtractionFields.map((idpExtractionField) => ({
        name: idpExtractionField.name,
        prompt: idpExtractionField.llmPromptDescription
      }));

      const extractionResults = await extractDocument({
        idpProjectId,
        clusterId,
        idpDocuments: uploadedIdpDocuments,
        taxonomy,
        modelId
      });

      if (extractionResults?.length > 0) {
        const activeIdpDocumentExtractionResult = extractionResults.find(
          (extractionResult) =>
            extractionResult.clusterDocumentId === this.activeExtractionIdpDocument?.clusterDocumentId
        );

        if (activeIdpDocumentExtractionResult) {
          this.setIdpExtractionFields(
            idpExtractionFields.map((idpExtractionField) => ({
              ...idpExtractionField,
              extractedValue:
                activeIdpDocumentExtractionResult?.variables?.[idpExtractionField.name] ??
                idpExtractionField.extractedValue
            }))
          );
          if (idpExtractionFields.every((idpExtractionField) => !idpExtractionField.expectedValue)) {
            this.setExtractionStepNotification(EXTRACTION_NOTIFICATION_STEPS.SAVE_AS_TEST_CASE);
          }
        }

        return extractionResults;
      } else {
        throw new Error('IDP Extraction result is empty.');
      }
    } catch (ex) {
      if (ex instanceof ConnectorError) {
        notificationStore.showError(ex.message);
      } else {
        notificationStore.showError('Yikes! Could not extract your IDP document(s). Please try again later.');
      }
      tracingService.traceError(ex, 'Failed to extract IDP document(s)');
    }
  };

  updateIdpTestCaseResults = async (idpProjectId, testCaseResults) => {
    await idpProjectService.updateIdpTestCaseResults(idpProjectId, { testCaseResults });
  };

  fetchIdpExtractionFields = async (idpDocumentId) => {
    const extractionFields = await idpProjectService.fetchIdpExtractionFields(idpDocumentId);
    this.setIdpExtractionFields(extractionFields);
  };

  addIdpExtractionField = async (idpProjectId, name, type, llmPromptDescription) => {
    try {
      const newIdpExtractionField = await idpProjectService.addIdpExtractionField(
        idpProjectId,
        name,
        type,
        llmPromptDescription
      );
      this.setIdpExtractionFields(this.idpExtractionFields.concat([newIdpExtractionField]));
      return this.idpExtractionFields;
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't create your IDP extraction field. Please try again later.");
      tracingService.traceError(err, 'Failed to create your IDP extraction field');
    }
  };

  updateIdpExtractionField = async (id, name, type, llmPromptDescription, triggerSuccessNotification = true) => {
    try {
      const updatedIdpExtractionField = await idpProjectService.updateIdpExtractionField(
        id,
        name,
        type,
        llmPromptDescription
      );
      this.setIdpExtractionFields(
        this.idpExtractionFields.map((extractionField) =>
          updatedIdpExtractionField.id === extractionField.id
            ? {
                ...extractionField,
                ...updatedIdpExtractionField
              }
            : extractionField
        )
      );
      if (triggerSuccessNotification) {
        notificationStore.showSuccess('Your IDP extraction field has been updated.');
      }
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't update your IDP extraction field. Please try again later.");
      tracingService.traceError(err, 'Failed to update your IDP extraction field');
    }
  };

  updateIdpExtractionFieldValues = async (idpProjectId, idpDocumentId, extractionFields, llmModelId) => {
    try {
      const newValues = extractionFields.map((extractionField) => ({
        id: extractionField.id,
        expectedValue: extractionField.extractedValue
      }));
      const updatedIdpExtractionFieldValues = await idpProjectService.updateIdpExtractionFieldValues(idpDocumentId, {
        newValues
      });
      const updatedIdpExtractionFieldValueById = new Map(
        updatedIdpExtractionFieldValues.map((updatedIdpExtractionFieldValue) => [
          updatedIdpExtractionFieldValue.id,
          updatedIdpExtractionFieldValue
        ])
      );
      const extractedValueById = new Map(extractionFields.map(({ id, extractedValue }) => [id, extractedValue]));
      this.setIdpExtractionFields(
        this.idpExtractionFields.map((extractionField) =>
          updatedIdpExtractionFieldValueById.has(extractionField.id)
            ? {
                ...updatedIdpExtractionFieldValueById.get(extractionField.id),
                extractedValue: extractedValueById.get(extractionField.id)
              }
            : extractionField
        )
      );

      const testCaseResults = this.idpExtractionFields
        .filter((idpExtractionField) => !!idpExtractionField.extractedValue && !!idpExtractionField.expectedValue)
        .map((idpExtractionField) => ({
          idpDocumentId,
          idpExtractionFieldId: idpExtractionField.id,
          llmModelId,
          status:
            idpExtractionField.extractedValue.toString() === idpExtractionField.expectedValue.toString()
              ? 'PASS'
              : 'FAIL',
          extractedValue: idpExtractionField.extractedValue
        }));
      if (testCaseResults.length > 0) {
        await this.updateIdpTestCaseResults(idpProjectId, testCaseResults);
        // Need to fetch the saved IdpTestCaseResults to acquire complete object and update `modelTestCaseResultsMap`
        await this.fetchIdpExtractionFieldTestcaseResults(idpProjectId, llmModelId);
      }

      notificationStore.success({
        title: 'Test case successfully saved',
        content: 'Next, test other models to compare their results, or proceed to the next document.'
      });
    } catch (err) {
      notificationStore.error({
        title: 'Saving test case failed',
        content: 'Please try again. If the problem persists, please get in touch with the support team.'
      });
      tracingService.traceError(err, 'Failed to update your IDP extraction field values');
    }
  };

  deleteIdpExtractionField = async (idpExtractionFieldId, showSuccessNotification = true) => {
    try {
      await idpProjectService.deleteIdpExtractionField(idpExtractionFieldId);
      this.setIdpExtractionFields(
        this.idpExtractionFields.filter((idpExtractionField) => idpExtractionField.id !== idpExtractionFieldId)
      );
      if (showSuccessNotification) {
        notificationStore.showSuccess('Your IDP extraction field has been deleted.');
      }
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't delete your IDP extraction fields. Please try again later.");
      tracingService.traceError(err, 'Failed to delete your IDP extraction field');
    }
  };

  syncTestcaseResults = (idpTestcaseResults = [], syncExtractedValues = true) => {
    // This logic syncs the `idpExtractionFields` with values obtained from test case results for `activeExtractionIdpDocument`
    const idpTestcaseResultsByIdpExtractionFieldId = new Map(
      idpTestcaseResults.map((idpTestcaseResult) => {
        const activeDocumentTestCaseResult = idpTestcaseResult.testCaseResults.find(
          (testCaseResult) => testCaseResult.idpDocumentId === this.activeExtractionIdpDocument?.id
        );
        return [idpTestcaseResult.idpExtractionFieldId, activeDocumentTestCaseResult];
      })
    );
    this.setIdpExtractionFields(
      this.idpExtractionFields.map((idpExtractionField) => {
        const testCaseResult = idpTestcaseResultsByIdpExtractionFieldId.get(idpExtractionField.id);
        return {
          ...idpExtractionField,
          expectedValue: testCaseResult?.expectedValue ?? '',
          extractedValue: syncExtractedValues ? (testCaseResult?.extractedValue ?? '') : ''
        };
      })
    );
  };

  fetchIdpExtractionFieldTestcaseResults = async (idpProjectId, llmModelId) => {
    try {
      const idpTestcaseResults = await idpProjectService.fetchIdpExtractionFieldTestcaseResults(
        idpProjectId,
        llmModelId
      );
      this.addModelTestCaseResults(llmModelId, idpTestcaseResults);
      return idpTestcaseResults;
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't retrieve IDP test case results. Please try again later.");
      tracingService.traceError(err, 'Failed to fetch IDP test case results');
    }
  };

  publishIdpExtractionProject = async (
    idpProjectId,
    versionName,
    versionDescription,
    llmModelId,
    organizationPublic
  ) => {
    try {
      await idpProjectService.publishIdpExtractionProject(idpProjectId, {
        versionName,
        versionDescription,
        llmModelId,
        organizationPublic
      });
      await this.getExtractionProjectVersions(idpProjectId);
      notificationStore.showSuccess('IDP project published successfully.');
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't publish your IDP extraction project. Please try again later.");
      tracingService.traceError(err, 'Failed to publish your IDP extraction project');
    }
  };

  getExtractionProjectVersions = async (idpProjectId) => {
    try {
      const idpPublishedVersions = (await idpProjectService.getExtractionProjectVersions(idpProjectId))?.sort(
        (a, b) => b.created - a.created
      );
      this.setIdpPublishedVersions(idpPublishedVersions);
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't fetch your IDP project versions. Please try again later.");
      tracingService.traceError(err, 'Failed to fetch your IDP project versions');
    }
  };
}

export default new IdpProjectStore();
