/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';

import { RestoreListItem } from 'App/Pages/IdpProjectVersions/features/restore';
import { RenameListItem } from 'App/Pages/IdpProjectVersions/features/rename';
import { DeleteListItem } from 'App/Pages/IdpProjectVersions/features/delete';
import { PublishToOrganizationListItem } from 'App/Pages/IdpProjectVersions/features/publish-to-organization';
import { Dots } from 'icons';
import { IconButton } from 'primitives';
import { Dropdown } from 'components';

const VersionMenu = ({ milestoneId, canRestoreAsLatest, reloadVersions, setIsEditing, canPublishToOrganization }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const listItems = [
    <RenameListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      key={`rename-${milestoneId}`}
      setIsEditing={setIsEditing}
    />,
    <RestoreListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      milestoneId={milestoneId}
      disabled={!canRestoreAsLatest}
      key={`restore-${milestoneId}`}
      reloadVersions={reloadVersions}
    />,
    <DeleteListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      milestoneId={milestoneId}
      key={`delete-${milestoneId}`}
      reloadVersions={reloadVersions}
    />
  ];

  const title = listItems.length ? 'Browse version actions' : 'No version actions available';
  const disabled = !listItems.length;

  return (
    <>
      <IconButton
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          evt.stopPropagation();
        }}
        aria-haspopup="true"
        aria-owns={anchorEl ? 'version-menu' : undefined}
        title={title}
        disabled={disabled}
      >
        <Dots />
      </IconButton>
      <Dropdown
        size="small"
        id="version-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {canPublishToOrganization && (
          <>
            <PublishToOrganizationListItem
              closeVersionMenuDropdown={() => setAnchorEl(null)}
              milestoneId={milestoneId}
              key={`publish-to-org-${milestoneId}`}
              reloadVersions={reloadVersions}
            />
            <Dropdown.ListItemDivider />
          </>
        )}
        {listItems}
      </Dropdown>
    </>
  );
};

export default VersionMenu;
