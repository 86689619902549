/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { InlineNotification } from '@carbon/react';

import { Input } from 'primitives';

export const ImageContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  img {
    width: 20px;
    height: 20px;
  }
`;

export const WarningText = styled.div`
  color: var(--grey-darken-33);
  vertical-align: top;
  width: calc(100% - 40px);
  margin-left: var(--spacing-medium);
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
`;

export const WarningContainer = styled.div`
  padding-top: var(--spacing-medium);
`;

export const Centered = styled.div`
  text-align: center;
`;

export const EmailGrid = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ColumnLeft = styled.div`
  flex-grow: 1;
  margin-right: 10px;
`;

export const ButtonGrid = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;

  > button:last-child {
    margin-left: 10px;
  }
`;

export const DialogActions = styled.div`
  padding: 15px 25px;
  border-top: 1px solid var(--silver-darken-87);
  background-color: var(--silver-darken-94);
  justify-content: flex-start;
  display: flex;
  align-items: center;
`;

export const HiddenTextarea = styled.textarea`
  position: fixed;
  top: -999999px;
`;

export const EmailTextInput = styled(Input)`
  margin-top: var(--spacing-small);
`;

export const InfoMessage = styled(InlineNotification)`
  margin-bottom: var(--spacing-medium);
`;
