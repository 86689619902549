/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  ${(props) => (props.layout === 'inline' ? 'align-items: flex-end' : 'flex-direction: column')};
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  ${(props) => props.layout !== 'inline' && `margin-top: var(--spacing-small)`};

  > button:last-child {
    margin-left: var(--spacing-tiny);
  }
`;
