/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, Tooltip } from 'primitives';

export default function InlineEditable({
  isEditing,
  className,
  initialValue,
  placeholder,
  onSubmit,
  onCancel,
  children
}) {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);

  const handleSubmit = () => {
    setValue(initialValue);
    onSubmit(value.trim());
  };

  const handleKeyDown = (evt) => {
    if (evt.key == 'Enter') {
      handleSubmit();
    } else if (evt.key == 'Escape') {
      setValue(initialValue);
      onCancel?.();
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, initialValue.length);
    }
  }, [isEditing]);

  useEffect(() => {
    setValue(initialValue);

    // `setTimeout` is needed here because the `setValue` call is asynchronous. Since we only
    // want to set the range after the value has been set properly, we need to "delay" the action.
    setTimeout(() => {
      inputRef.current?.setSelectionRange(0, initialValue.length);
    });
  }, [initialValue]);

  return (
    <div className={className}>
      {isEditing ? (
        <Input
          ref={inputRef}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={value}
          onBlur={handleSubmit}
          data-test="editable-input"
        />
      ) : (
        <Tooltip title={children} showOnlyOnOverflow>
          <div data-test="editable-text" className="overflow-ellipsis">
            {children}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

InlineEditable.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};
