/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

class IdpProjectService extends Service {
  constructor() {
    super();
  }

  /**
   * Creates a new IDP project in the given IDP application.
   *
   * @param {String} idpApplicationId the IDP application id
   * @param {String} name the name of the IDP project
   * @param {String} type the type of the IDP project
   * @param {String} extractionMethod the extraction method of the IDP project
   * @return {Promise}
   */
  createIdpProject({ idpApplicationId, name, type, extractionMethod }) {
    const payload = {
      idpApplicationId,
      name,
      type,
      extractionMethod
    };
    return this.post(`/internal-api/idp-projects`, payload);
  }
}

export default new IdpProjectService();
