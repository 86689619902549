/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { fileService } from 'services';
import { notificationStore } from 'components/NotificationSystem';

export const fetchReadme = async (fileId) => {
  try {
    return await fileService.fetchById(fileId);
  } catch (e) {
    notificationStore.error({
      title: 'Error',
      content: `Oops! We couldn't fetch the README.`,
      duration: 3000
    });
    console.error(e);
    return null;
  }
};
