/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { feedbackDialogStore, FeedbackDialog } from './FeedbackDialog';
import * as Styled from './SendFeedbackButton.styled';

export default function SendFeedbackButton({ feedbackButtonSlotRef, from }) {
  return (
    <>
      <Styled.SendFeedbackButton
        role="button"
        aria-label="Send feedback"
        onClick={() => {
          if (feedbackDialogStore.state.isOpen) {
            feedbackDialogStore.close();
          } else {
            feedbackDialogStore.open({
              message: 'How was your overall experience using Modeler?',
              type: 'Modeler'
            });
          }
        }}
      >
        Send feedback
      </Styled.SendFeedbackButton>
      <FeedbackDialog feedbackButtonSlotRef={feedbackButtonSlotRef} from={from} />
    </>
  );
}
