/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, makeObservable, observable } from 'mobx';
import BPMNModdle from 'bpmn-moddle';

import { notificationStore, breadcrumbStore, projectStore, organizationStore } from 'stores';
import { clusterService, tracingService } from 'services';
import { BPMN, DEFAULT_ZEEBE_VERSION } from 'utils/constants';
import history from 'utils/history';
import buildSlug from 'utils/buildSlug';
import generateId from 'utils/generate-id';
import { getProcessIdFromContent, getDefinitions } from 'utils/web-modeler-diagram-parser';

class ProcessTemplateStore {
  loading = true;
  availableTemplates = [];

  constructor() {
    makeObservable(this, {
      loading: observable,
      availableTemplates: observable,
      reset: action,
      createDiagramFromSelectedTemplate: action
    });
  }

  /**
   * Resets the store to its initial values
   */
  reset = () => {
    this.loading = true;
    this.availableTemplates = [];
  };

  /**
   * If a template is given, retrieves the information about the template and creates a new diagram using those. If template is not given, a blank diagram is created.
   * If a projectId is given, it creates the template in that project.
   * If a source is given, it is used to track where the diagram was created from.
   *
   * @param {String} template
   * @param {String} projectId
   * @param {String} source
   */
  async createDiagramFromSelectedTemplate(template, projectId, source = 'template-dialog') {
    let diagram;

    if (template) {
      try {
        const { id: processTemplateId, name: templateName, content } = template;
        const initialProcessId = await getProcessIdFromContent(content);
        const contentWithProcessIdsReplaced = await this.appendUniqueIdToProcessIds(content);
        const replacedProcessId = await getProcessIdFromContent(contentWithProcessIdsReplaced);
        const newFileContent = {
          processId: replacedProcessId,
          processTemplateId,
          name: templateName,
          content: await this.setLatestExecutionPlatformVersion(contentWithProcessIdsReplaced)
        };

        const { currentOrganizationId, isUsingTrial, isTrialExpired } = organizationStore;

        if (projectId) {
          await projectStore.initProject(projectId);
        }

        await clusterService.createClusterIfNecessary({
          organizationId: currentOrganizationId,
          isUsingTrial,
          isTrialExpired
        });

        diagram = await projectStore.createFile({
          type: BPMN,
          file: newFileContent,
          source: source,
          processTemplateUsed: initialProcessId
        });
      } catch (ex) {
        notificationStore.showError(
          'Yikes! Could not fetch the selected template information. Please try again later.'
        );
        tracingService.traceError(ex, 'Failed to fetch the selected template information');
      }
    } else {
      try {
        diagram = await projectStore.createFile({ type: BPMN });
      } catch (ex) {
        notificationStore.showError('Yikes! Could not create the diagram. Please try again later.');
        tracingService.traceError(ex, 'Failed to create diagram from process template');
      }
    }

    if (diagram) {
      breadcrumbStore.toggleEditingFor('diagram');
      history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
    }
  }

  async appendUniqueIdToProcessIds(xml) {
    const definitions = await getDefinitions(xml);
    const processes = definitions?.rootElements?.filter((element) => element.$type === 'bpmn:Process');
    processes?.forEach((process) => {
      process.set('id', generateId({ prefix: `${process.get('id')}-` }));
    });
    const { xml: outputXML } = await new BPMNModdle().toXML(definitions);
    return outputXML;
  }

  async setLatestExecutionPlatformVersion(xml) {
    const definitions = await getDefinitions(xml);
    definitions.set('modeler:executionPlatform', 'Camunda Cloud');
    definitions.set('modeler:executionPlatformVersion', DEFAULT_ZEEBE_VERSION);
    const { xml: outputXML } = await new BPMNModdle().toXML(definitions);
    return outputXML;
  }
}

export default new ProcessTemplateStore();
