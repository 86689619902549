/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import config from 'utils/config';

const EXPERIMENT_NAME = 'bpmn_diagram_empty_state';

/**
 * @param {function} getExperiment from useStatsigClient()
 * @returns {boolean}
 */
export default function isBpmnCopilotEmptyStateExperiment(getExperiment) {
  const experiment = getExperiment(EXPERIMENT_NAME);

  return config?.statsig?.enabled && experiment?.value?.enable_feature;
}
