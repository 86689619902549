/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Helmet } from 'react-helmet-async';
import { SideNav, SideNavItems, SideNavLink, Content } from '@carbon/react';
import { Link as NavLink, Route, Switch, Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { TopBar, Breadcrumb } from 'components';
import { getPageTitle } from 'utils/helpers';

import {
  PA_DEPLOYMENT_SETTINGS_PATH_SUFFIX,
  ProcessApplicationDeploymentSettings
} from './process-application-deployment-settings';
import { EMAIL_SETTINGS_PATH_SUFFIX, EmailNotificationSettings } from './email-settings';

const Settings = () => {
  /** @type {{setting: string}} */
  const { setting } = useParams();
  const PA_DEPLOYMENT_SETTINGS_PATH = buildSettingsPath(PA_DEPLOYMENT_SETTINGS_PATH_SUFFIX);
  const EMAIL_SETTINGS_PATH = buildSettingsPath(EMAIL_SETTINGS_PATH_SUFFIX);

  return (
    <>
      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" to="/" />
        <Breadcrumb title="Settings" variant="text" isBold />
      </TopBar.Breadcrumbs>

      <Helmet>
        <title>{getPageTitle('Settings')}</title>
      </Helmet>
      <SideNav isFixedNav expanded aria-label="Settings side navigation">
        <SideNavItems isSideNavExpanded>
          <SideNavLink
            large
            isSideNavExpanded
            as={NavLink}
            to={EMAIL_SETTINGS_PATH}
            isActive={setting === EMAIL_SETTINGS_PATH_SUFFIX}
          >
            Email notifications
          </SideNavLink>
          <SideNavLink
            large
            isSideNavExpanded
            as={NavLink}
            to={PA_DEPLOYMENT_SETTINGS_PATH}
            isActive={setting === PA_DEPLOYMENT_SETTINGS_PATH_SUFFIX}
          >
            Process application deployment
          </SideNavLink>
        </SideNavItems>
      </SideNav>

      <StyledContent>
        <Switch>
          <Route path={EMAIL_SETTINGS_PATH} component={EmailNotificationSettings} />
          <Route path={PA_DEPLOYMENT_SETTINGS_PATH} component={ProcessApplicationDeploymentSettings} />

          {/* As there is no default settings home, we redirect to email settings to not end up on a broken page */}
          <Route path={'/settings/:slug?'}>
            <Redirect to={EMAIL_SETTINGS_PATH} />
          </Route>
        </Switch>
      </StyledContent>
    </>
  );
};

/**
 * @param {string} suffix
 */
function buildSettingsPath(suffix) {
  return `/settings/${suffix}`;
}

const StyledContent = styled(Content)`
  background: var(--cds-layer-01);
  overflow-y: auto;
  flex-grow: 1;
`;

export default Settings;
