/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

class InvalidDiagramDialogStore {
  isOpen = false;
  hasBeenDismissed = false;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.isOpen = false;
    this.hasBeenDismissed = false;
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
    this.setHasBeenDismissed();
  }

  setHasBeenDismissed() {
    this.hasBeenDismissed = true;
  }
}

export default new InvalidDiagramDialogStore();
