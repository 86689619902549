/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

const BPMN_PROCESS_ELEMENT_TYPE = 'bpmn:Process';

export default function getExecutableProcess(definitions) {
  if (!definitions) {
    throw new Error('You need to pass modeler definitions to get the processId of the executable process');
  }

  let executableProcess, firstProcessFound;

  const rootElements = definitions.rootElements;

  if (!rootElements) {
    return;
  }

  rootElements.forEach((rootElement) => {
    if (rootElement.$type === BPMN_PROCESS_ELEMENT_TYPE) {
      if (!firstProcessFound) {
        firstProcessFound = rootElement;
      }
      if (!executableProcess) {
        if (rootElement.isExecutable) {
          executableProcess = rootElement;
        }
      }
    }
  });

  // if after iterating we did not got the processId of an executable process,
  // we use the processId of the first process we found
  if (!executableProcess) {
    executableProcess = firstProcessFound;
  }

  return executableProcess;
}
