/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { html } from 'diagram-js/lib/ui';

import MarketplaceIcon from './MarketplaceIcon';
import {
  BROWSE_MARKETPLACE_ONCLICK_EVENT,
  BROWSE_MARKETPLACE_ONDESTROY_EVENT,
  browseMarketplaceButtonEventManager
} from './';

const DISALLOWED_TYPES = [
  'bpmn:TextAnnotation',
  'bpmn:EndEvent',
  'bpmn:DataObjectReference',
  'bpmn:DataStoreReference',
  'bpmn:Group',
  'bpmn:Participant',
  'bpmn:Association'
];

class BrowseMarketplaceProvider {
  #popupMenu;
  #eventBus;

  constructor(popupMenu, eventBus) {
    this.#popupMenu = popupMenu;
    this.#eventBus = eventBus;

    this.#popupMenu.registerProvider('bpmn-create', this);
    this.#popupMenu.registerProvider('bpmn-replace', this);
    this.#popupMenu.registerProvider('bpmn-append', this);
    this.#popupMenu.registerProvider('element-template-chooser', this);

    this.#init();
  }

  getPopupMenuEntries() {
    return {};
  }

  getPopupMenuHeaderEntries(target) {
    if (!this.#isAllowedType(target)) {
      return {};
    }

    return {
      browseConnectors: {
        action: () => {
          const { providerId } = this.#popupMenu._current;
          browseMarketplaceButtonEventManager.emit(BROWSE_MARKETPLACE_ONCLICK_EVENT, providerId);
        },
        title: 'Browse Marketplace for more Connectors',
        className: 'btn-browse-marketplace',
        imageUrl: MarketplaceIcon,
        group: 'marketplace'
      }
    };
  }

  getEmptyPlaceholder() {
    return (search) => {
      const { providerId } = this.#popupMenu._current;
      return html`<div>
        <span>No matching entries found.</span>
        <div style=${{ marginTop: '5px' }}>
          <a
            href="#"
            title="Browse Marketplace for more Connectors."
            onClick=${() =>
              browseMarketplaceButtonEventManager.emit(BROWSE_MARKETPLACE_ONCLICK_EVENT, providerId, search)}
          >
            Browse Marketplace for more Connectors.
          </a>
        </div>
      </div>`;
    };
  }

  #refreshPopupMenu = () => {
    this.#popupMenu.refresh();
  };

  #isAllowedType(target) {
    return DISALLOWED_TYPES.every((disallowedType) => !is(target, disallowedType));
  }

  #init() {
    this.#eventBus.on('elementTemplates.changed', this.#refreshPopupMenu);
    browseMarketplaceButtonEventManager.subscribe(BROWSE_MARKETPLACE_ONDESTROY_EVENT, this.#reset);
  }

  #reset = () => {
    this.#eventBus.off('elementTemplates.changed', this.#refreshPopupMenu);
    browseMarketplaceButtonEventManager.unsubscribe(BROWSE_MARKETPLACE_ONDESTROY_EVENT, this.#reset);
  };
}

BrowseMarketplaceProvider.$inject = ['popupMenu', 'eventBus'];

export default {
  __init__: ['browseMarketplace'],
  browseMarketplace: ['type', BrowseMarketplaceProvider]
};
