/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';

import {
  EditReviewListItem,
  EditReviewModals,
  useCanEditReview
} from 'App/Pages/ProcessApplicationVersions/features/review';
import { RestoreListItem } from 'App/Pages/ProcessApplicationVersions/features/restore';
import { RenameListItem } from 'App/Pages/ProcessApplicationVersions/features/rename';
import { DeployListItem } from 'App/Pages/ProcessApplicationVersions/features/deploy';
import { DeleteListItem } from 'App/Pages/ProcessApplicationVersions/features/delete';
import { CopyListItem } from 'App/Pages/ProcessApplicationVersions/features/copy';
import { DownloadListItem } from 'App/Pages/ProcessApplicationVersions/features/download';
import { Dots } from 'icons';
import { IconButton } from 'primitives';
import { Dropdown } from 'components';

const VersionMenu = ({
  versionId,
  versionName,
  processApplicationName,
  reloadVersions,
  setIsEditing,
  isVersionByCurrentUser,
  hasElevatedOrganizationPermissions
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const canEditReview = useCanEditReview(versionId, isVersionByCurrentUser, hasElevatedOrganizationPermissions);
  const listItems = [
    <RestoreListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      versionId={versionId}
      key={`restore-${versionId}`}
      reloadVersions={reloadVersions}
    />,
    <RenameListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      key={`rename-${versionId}`}
      setIsEditing={setIsEditing}
    />,
    <DeployListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      versionId={versionId}
      key={`deploy-${versionId}`}
    />,
    <DownloadListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      versionId={versionId}
      versionName={versionName}
      processApplicationName={processApplicationName}
      key={`download-${versionId}`}
    />,
    <DeleteListItem
      closeVersionMenuDropdown={() => setAnchorEl(null)}
      versionId={versionId}
      key={`delete-${versionId}`}
      reloadVersions={reloadVersions}
    />,
    <CopyListItem versionId={versionId} key={`copy-${versionId}`} closeVersionMenuDropdown={() => setAnchorEl(null)} />
  ];

  if (canEditReview) {
    listItems.push(
      <EditReviewListItem
        closeVersionMenuDropdown={() => setAnchorEl(null)}
        versionId={versionId}
        key={`edit-review-${versionId}`}
      />
    );
  }

  const title = listItems.length ? 'Browse version actions' : 'No version actions available';
  const disabled = !listItems.length;

  return (
    <>
      {canEditReview && (
        <EditReviewModals
          versionId={versionId}
          isAdminSelfReview={isVersionByCurrentUser && hasElevatedOrganizationPermissions}
        />
      )}
      <IconButton
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          evt.stopPropagation();
        }}
        aria-haspopup="true"
        aria-owns={anchorEl ? 'version-menu' : undefined}
        title={title}
        disabled={disabled}
      >
        <Dots />
      </IconButton>
      <Dropdown
        size="small"
        id="version-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {listItems}
      </Dropdown>
    </>
  );
};

export default VersionMenu;
