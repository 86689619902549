/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';

import * as Shared from 'App/Pages/Project/Shared.styled';
import { idpProjectStore, projectStore } from 'stores';
import { EmptyState, Spinner } from 'primitives';
import { SadFace } from 'icons';
import { IDP_PROJECT_EXTRACTION_METHODS } from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';
import { ReadOnlyPill } from 'components';
import history from 'utils/history';
import hasAccess, { actions } from 'utils/user-access';
import { SettingsModal } from 'App/Pages/Project/IdpApplication/idp-project/settings-modal';

import { IdpExtractionProject } from './idp-extraction-project';
import * as Styled from './IdpProject.styled';
import IdpProjectHeader from './IdpProjectHeader';

const IdpProjectPage = () => {
  const [loading, setLoading] = useState(true);

  // @ts-expect-error TS2339
  const { slug } = useParams();
  const { project } = projectStore;
  const { idpProject, idpDocuments, idpPublishedVersions } = idpProjectStore;
  const [isSettingModalOpen, setIsSettingsModalOpen] = useState(false);
  const canUserModifyProject = hasAccess(project, actions.MODIFY_PROJECT) || hasAccess(project, actions.MODIFY_DIAGRAM);

  const reset = () => {
    idpProjectStore.reset();
  };

  useEffect(() => {
    const init = async () => {
      await idpProjectStore.init(slug.split('--')[0]);
      setLoading(false);
    };

    init();
    return () => reset();
  }, [slug]);

  const IdpProjectNotFound = () => {
    return (
      // @ts-expect-error TS2739
      <EmptyState
        title="IDP project not found!"
        description="Sorry, your IDP project might have been deleted."
        icon={<SadFace width="48" height="48" />}
      />
    );
  };

  const TitleContainer = () => {
    return (
      <Shared.TitleContainer>
        <Styled.TitleContainer>
          <Styled.TitleHeader>
            <Styled.Title data-test="idp-project-name">{idpProject.name}</Styled.Title>
            {/* @ts-expect-error TS2322 */}
            <OverflowMenu size="md" aria-label="IDP Project Context Action" aria-haspopup="true" align="right">
              <OverflowMenuItem
                data-test="idp-project-context-menu-settings"
                itemText="Settings"
                onClick={() => setIsSettingsModalOpen(true)}
              />
              <OverflowMenuItem
                data-test="idp-project-context-menu-version"
                itemText="Versions"
                disabled={idpPublishedVersions.length === 0}
                onClick={handleNavigationToVersions}
              />
            </OverflowMenu>
          </Styled.TitleHeader>
          <Styled.Subtitle data-test="idp-project-extractionMethod">
            <Styled.SubtitleContainer>
              {idpProject.description && (
                <div data-test="idp-project-description">{idpProject.description} -&nbsp;</div>
              )}
              <div>{getIdpProjectExtractionMethod(idpProject.extractionMethod)}</div>
            </Styled.SubtitleContainer>
          </Styled.Subtitle>
        </Styled.TitleContainer>
        {!canUserModifyProject && (
          <div>
            <ReadOnlyPill />
          </div>
        )}
      </Shared.TitleContainer>
    );
  };

  const getIdpProjectExtractionMethod = (value) => {
    return IDP_PROJECT_EXTRACTION_METHODS.find((method) => method.value === value)?.title;
  };

  const handleNavigationToVersions = () => {
    history.push(`/idp-projects/${idpProject.id}/versions`);
  };

  return (
    <>
      <IdpProjectHeader />
      {loading ? (
        <Spinner fullHeight />
      ) : idpProject ? (
        <Styled.Container>
          {idpDocuments.length > 0 && <TitleContainer />}
          <IdpExtractionProject
            canUserModifyProject={canUserModifyProject}
            extractionMethod={idpProject.extractionMethod}
            handleNavigationToVersions={handleNavigationToVersions}
            renderTitleContainer={<TitleContainer />}
          />
          <SettingsModal isOpen={isSettingModalOpen} onClose={() => setIsSettingsModalOpen(false)} />
        </Styled.Container>
      ) : (
        <IdpProjectNotFound />
      )}
    </>
  );
};

export default observer(IdpProjectPage);
