/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Callout } from '@carbon/react';

import { EmptyState, Sidebar } from 'primitives';
import { EntityTable, InvitationModal } from 'components';
import { User } from 'icons';
import { invitationModalStore, projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { getRoleDetails } from 'utils/member-roles';
import { getCollaboratorName, getEntityAvatar } from 'utils/helpers';
import localStorage from 'utils/localstorage';
import { NameRenderer, NameWithoutLinkRenderer } from 'components/EntityTable/CellRenderer';

import CollaboratorsAction from './CollaboratorsAction';
import { CollaboratorsLimit } from './CollaboratorsLimit';
import * as Styled from './Collaborators.styled';
import { AddUserButton } from './AddUserButton';

const COLLABORATOR_SIDEBAR_LOCALSTORAGE_KEY = 'modeler.collaborator_sidebar_visible';

export const Collaborators = () => {
  const [isVisible, setIsVisible] = useState(() => {
    return localStorage.getItem(COLLABORATOR_SIDEBAR_LOCALSTORAGE_KEY) !== 'false';
  });
  const { project, collaborators } = projectStore;

  const handleToggleSidebar = () => {
    setIsVisible(!isVisible);

    localStorage.setItem(COLLABORATOR_SIDEBAR_LOCALSTORAGE_KEY, !isVisible);
  };

  const isCollaboratorWithoutEmailPresent = collaborators.filter((collaborator) => !collaborator.email).length > 0;

  const shouldShowNoEmailNotification =
    hasAccess(project, actions.MODIFY_COLLABORATORS) && isCollaboratorWithoutEmailPresent;

  return (
    <Styled.SidebarOuter $isVisible={isVisible}>
      <Sidebar.Toggle
        onClick={handleToggleSidebar}
        type="button"
        title={isVisible ? 'Close collaborators' : 'Open collaborators'}
        data-test="collaborator-toggle"
      >
        <User width="20" height="20" />
        Collaborators
      </Sidebar.Toggle>

      {isVisible && (
        <Sidebar.Inner>
          <Styled.CollaboratorSidebarTitle>Collaborators</Styled.CollaboratorSidebarTitle>

          <CollaboratorsLimit />

          {shouldShowNoEmailNotification && (
            <Styled.NotificationContainer>
              <Callout
                titleId="non-existing-title"
                subtitle="Users without emails will not receive email notifications"
                actionButtonLabel="Learn more"
                onActionButtonClick={() =>
                  window.open(
                    'https://docs.camunda.io/docs/next/components/modeler/web-modeler/collaboration/#inviting-users-to-projects'
                  )
                }
                kind="info"
                lowContrast
              />
            </Styled.NotificationContainer>
          )}

          <Styled.CollaboratorSidebarContent>
            <Styled.EntityTableContainer>
              <EntityTable
                noTablePadding
                noMinWidth
                tableSize="sm"
                isSearchPersistent={false}
                toolbarPlaceholder="Search collaborators"
                columns={[
                  {
                    key: 'name',
                    header: 'Name',
                    sortable: true,
                    noPadding: true,
                    renderer: Object.assign(
                      function ({ name, icon }) {
                        return <NameWithoutLinkRenderer name={name} icon={icon} customIconPadding="10px" />;
                      },
                      {
                        containsSearchWord: NameRenderer.containsSearchWord,
                        getComparableValue: NameRenderer.getComparableValue
                      }
                    )
                  },
                  { key: 'role', header: 'Role', sortable: true },
                  {
                    key: 'entityAction',
                    header: '',
                    width: '50px',
                    noPadding: true,
                    renderer: (collaborator) => <CollaboratorsAction collaborator={collaborator} />
                  }
                ]}
                rows={collaborators.map((collaborator) => ({
                  id: collaborator.id || collaborator.email,
                  name: {
                    name: getCollaboratorName(collaborator),
                    icon: getEntityAvatar(collaborator)
                  },
                  role: getRoleDetails(collaborator.permissionAccess).title,
                  entityAction: collaborator
                }))}
                action={() =>
                  hasAccess(project, actions.MODIFY_COLLABORATORS) && (
                    <AddUserButton onClick={invitationModalStore.open} />
                  )
                }
              />
            </Styled.EntityTableContainer>
            {collaborators?.length === 1 && (
              <EmptyState
                title="You don't have to do it alone"
                description={
                  <>
                    Click <strong>Add user</strong> to invite a collaborator.
                  </>
                }
              />
            )}
          </Styled.CollaboratorSidebarContent>
        </Sidebar.Inner>
      )}

      {invitationModalStore.state.isInvitationModalVisible && <InvitationModal />}
    </Styled.SidebarOuter>
  );
};

export default observer(Collaborators);
