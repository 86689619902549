/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import { notificationStore } from 'components/NotificationSystem';
import { idpApplicationService, tracingService, trackingService } from 'services';
import { confirmActionStore, projectStore, userStore } from 'stores';
import buildSlug from 'utils/buildSlug';
import history from 'utils/history';
import { DEFAULT, FILE_TYPE_MAPPING, FOLDER, IDP_APPLICATION } from 'utils/constants';

class IdpApplicationStore {
  isCreationModalVisible = false;
  idpApplication = {};
  loading = false;
  loadingPromise = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIdpApplication(idpApplication) {
    this.idpApplication = idpApplication;
  }

  setIsCreationModalVisible(val) {
    this.isCreationModalVisible = val;
  }

  startCreation() {
    this.setIsCreationModalVisible(true);
  }

  setLoading(loading) {
    this.loading = loading;
    if (loading) {
      this.loadingPromise = new Promise((resolve) => {
        this._resolveLoading = resolve;
      });
    } else if (this._resolveLoading) {
      this._resolveLoading();
      this.loadingPromise = null;
    }
  }

  reset() {
    this.setLoading(true);
    this.setIdpApplication({});
    projectStore.reset();
  }

  async init(idpApplicationId, skipParentInitialization = false) {
    let idpApplication;
    if (userStore.isAuthenticated) {
      this.setLoading(true);
      try {
        idpApplication = await idpApplicationService.fetchIdpApplication(idpApplicationId);
        this.setIdpApplication(idpApplication);
        if (!skipParentInitialization) {
          const type = this.idpApplication.folderId ? 'folder' : 'project';
          const initId = this.idpApplication.folderId || this.idpApplication.projectId;
          await projectStore.init(type, initId, undefined, true);
        }
      } catch (err) {
        notificationStore.showError("Yikes! Couldn't retrieve your IDP application. Please try again later.");
        tracingService.traceError(err, 'Failed to retrieve IDP application');
      } finally {
        this.setLoading(false);
      }
    }
    return idpApplication;
  }

  async finalizeCreation(name = '', clusterId) {
    try {
      const folderId = projectStore.folder.id;
      const projectId = projectStore.project.id;

      this.setIdpApplication(
        await idpApplicationService.createIdpApplication({
          name,
          projectId,
          folderId,
          clusterId
        })
      );

      trackingService.trackCreateFolder({
        from: 'button',
        parentType: projectStore.isFolder ? FILE_TYPE_MAPPING[FOLDER] : FILE_TYPE_MAPPING[DEFAULT],
        folderType: FILE_TYPE_MAPPING[IDP_APPLICATION],
        defaultDevClusterId: clusterId
      });

      history.push(`/idp-applications/${buildSlug(this.idpApplication.id, this.idpApplication.name)}`);
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't create your IDP application. Please try again later.");
      tracingService.traceError(err, 'Failed to create IDP application');
    }
  }

  async rename(name) {
    if (name.trim().length === 0 || this.idpApplication.name === name) {
      return;
    }

    try {
      await idpApplicationService.renameIdpApplication(this.idpApplication.id, name);

      this.setIdpApplication({ ...this.idpApplication, name });
    } catch (err) {
      notificationStore.showError('Yikes! Could not rename your IDP application. Please try again later.');
      tracingService.traceError(err, 'Failed to rename IDP application');
    }
  }

  async delete(idpApplicationId) {
    try {
      const confirmed = await confirmActionStore.confirm({
        title: `Deleting IDP application`,
        text: (
          <>
            You're about to delete the IDP application "{this.idpApplication.name}". Collaborators won't be able to
            access this content any longer.
          </>
        ),
        confirmLabel: 'Delete',
        isDangerous: true
      });

      if (!confirmed) {
        return false;
      }

      await idpApplicationService.deleteIdpApplication(idpApplicationId);

      trackingService.trackDeleteEntities(IDP_APPLICATION, this.idpApplication.idpProjects);

      notificationStore.showSuccess('Your IDP application has been deleted.');
      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not delete your IDP application. Please try again later.');
      tracingService.traceError(ex, 'Failed to delete IDP application');

      return false;
    }
  }
}

export default new IdpApplicationStore();
