/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * Throttle async function calls with one trailing execution for
 * all queued calls.
 * @param  {Function} fn Awaitable function (thunk)
 */
export default function throttleAsync(fn) {
  const limit = 1;
  let queue = 0;
  let latestArgs;
  async function ThrottleAsync(...args) {
    latestArgs = args;
    // already one processing and one pending
    if (queue > limit) {
      return;
    } else if (queue === limit) {
      // increment request queue and exit
      queue++;
      return;
    }

    // no pending requests
    queue++;

    while (queue > 0) {
      try {
        await fn(...latestArgs);
        queue--;
      } catch (ex) {
        queue = 0;
        throw ex;
      }
    }
  }
  return ThrottleAsync;
}
