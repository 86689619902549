/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { InlineLoading, Link, RadioButton, RadioButtonGroup } from '@carbon/react';
import { ArrowRight, InformationFilled } from '@carbon/icons-react';
import { truncate } from 'lodash';

import { currentDiagramStore, processApplicationStore } from 'stores';
import { Form } from 'icons';
import { formLinkStore, getElementsWithForm } from 'App/Pages/Diagram/FormLinking';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import pluralize from 'utils/pluralize';
import buildPath from 'utils/build-path';
import URL from 'components/TopBar/url';
import { WithExternalLinkIcon } from 'primitives';
import * as StyledDialog from 'App/Pages/Diagram/Deployment/executeModal/DeployLinkedDiagramInfo/DeployLinkedDiagramInfo.styled';

import * as Styled from './LinkedFormsList.styled';

const LinkedFormsList = () => {
  // The difference between linkedElements and linkedForms is that linkedElements
  // contains all elements that are linked to a form, being them embedded or linked.
  // linkedForms contains only the linked forms.
  const { linkedElements, diagramHasOnlyEmbeddedForms, diagramHasOnlyLinkedForms, isLoading } = formLinkStore;
  const [linkedFormsState, setLinkedFormsState] = useState({
    isLoading: false,
    linkedFormFiles: [],
    groups: [],
    uniqueFiles: [],
    hasOnlyLinkedForm: false,
    hasOnlyEmbeddedForm: false,
    hasLinkedAndEmbeddedForms: false,
    linkedFormsCount: 0,
    embeddedFormsCount: 0
  });
  const [shouldShowConversionMessage, setShouldShowConversionMessage] = useState(false);
  const target = processApplicationStore.fromAProcessApplication ? 'process application' : 'diagram';

  /**
   * This effect is responsible for setting the state of the linked forms,
   * belonging to a process application
   */
  useEffect(() => {
    (async () => {
      if (processApplicationStore.externalForms?.length && currentDiagramStore.modeler) {
        const { startEvents, userTasks } = getElementsWithForm(currentDiagramStore.modeler);

        formLinkStore.setLinkedElements([...startEvents, ...userTasks, ...processApplicationStore.externalForms]);
      }
    })();
  }, [processApplicationStore.externalForms, currentDiagramStore.modeler]);

  /**
   * This effect is responsible for setting the state of the linked forms.
   * E.g. if the diagram has only linked forms, or only embedded forms, or both.
   */
  useEffect(() => {
    (async () => {
      if (!linkedElements?.length) {
        setLinkedFormsState({
          linkedFormFiles: [],
          groups: [],
          uniqueFiles: [],
          hasOnlyLinkedForm: false,
          hasOnlyEmbeddedForm: false,
          hasLinkedAndEmbeddedForms: false,
          linkedFormsCount: 0,
          embeddedFormsCount: 0
        });

        return;
      }

      const embeddedForms = formLinkStore.embeddedForms;
      const embeddedFormsCount = embeddedForms.length;

      setLinkedFormsState({
        embeddedFormsCount,
        hasOnlyLinkedForm: diagramHasOnlyLinkedForms,
        hasOnlyEmbeddedForm: diagramHasOnlyEmbeddedForms,
        hasLinkedAndEmbeddedForms: !diagramHasOnlyLinkedForms && !diagramHasOnlyEmbeddedForms
      });
    })();
  }, [linkedElements]);

  /**
   * This effect is responsible for getting the linked form files, and grouping them if necessary.
   */
  useEffect(() => {
    (async () => {
      if (linkedFormsState.hasOnlyLinkedForm || linkedFormsState.hasLinkedAndEmbeddedForms) {
        const groups = [];
        const uniqueFiles = [];
        const linkedFormFiles = await formLinkStore.getLinkedFormFiles();

        linkedFormFiles?.forEach((files, formId) => {
          if (files.length === 0) {
            return;
          }

          if (files.length === 1) {
            uniqueFiles.push(files[0]);
          } else {
            groups.push({ formId, files });
          }
        });

        setLinkedFormsState((prevState) => ({
          ...prevState,
          linkedFormFiles,
          linkedFormsCount: linkedFormFiles.size,
          groups,
          uniqueFiles
        }));
      }
    })();
  }, [linkedFormsState.hasOnlyLinkedForm, linkedFormsState.hasLinkedAndEmbeddedForms]);

  /**
   * This effect is responsible for checking if the diagrams supports the link to form feature.
   */
  useEffect(() => {
    if (deploymentStore.selectedClusterVersion) {
      setShouldShowConversionMessage(!formLinkStore.supportsLinkToForm);
    }
  }, [deploymentStore.selectedClusterVersion]);

  const goToForm = (form) => {
    window.open(buildPath(`/forms/${form.id}`), '_blank');
  };

  /**
   * This function is responsible for grouping the linked forms by formID.
   * E.g. if there are two files with same formID, they should be grouped.
   */
  const renderGroupedLinkedFormsList = () => {
    /**
     * Renders the single linked form.
     * If isAnOption is true, it will render a radio button.
     * Otherwise, it will render the form icon.
     * @param {*} file The file to be rendered
     * @param {*} refId The form id used as a reference to the radio button group
     * @param {*} isAnOption If the form should be rendered as a radio button
     * @returns The rendered form
     */
    const getListedItem = (file, refId, isAnOption = false) => {
      return (
        <Styled.LinkedForm key={file.id} $isAnOption={isAnOption}>
          {!isAnOption ? (
            <>
              <Form width={18} height={18} />
              <Styled.FormName onClick={() => goToForm(file)}>
                <span>{file.name}</span>
                <ArrowRight className="link-icon" />
              </Styled.FormName>
            </>
          ) : (
            <>
              <RadioButton
                data-test={`radio-${file.id}`}
                hideLabel
                labelText=""
                value={file.id}
                id={`radio-${file.id}`}
                name={`${refId}-radio-button-group`}
                onChange={(value) => {
                  formLinkStore.resolveConflict(refId, value);
                }}
              />
              <Styled.FormName onClick={() => goToForm(file)}>
                <span>{file.name}</span>
                <ArrowRight className="link-icon" />
              </Styled.FormName>
            </>
          )}
        </Styled.LinkedForm>
      );
    };

    const { groups, uniqueFiles } = linkedFormsState;
    if (!groups?.length && !uniqueFiles?.length) {
      return null;
    }

    return (
      <>
        {[...groups, ...uniqueFiles].map((item) => {
          return item.hasOwnProperty('files') ? (
            // If there are more than one file, we need to render a group of linked forms.
            // The user will then decide which one to deploy via a radio button.
            <Styled.MultipleFormsGroup key={item.formId}>
              <Styled.MultipleFormsGroupTitle>
                <InformationFilled size="18" />
                {item.files.length} forms have the same ID:&nbsp;
                <strong title={item.formId}>{truncate(item.formId, { length: 22 })}</strong>. Which form should be used?
              </Styled.MultipleFormsGroupTitle>

              <RadioButtonGroup
                legendText=""
                name={`${item.formId}-radio-button-group`}
                orientation="vertical"
                defaultSelected="none"
              >
                {item.files.map((file) => getListedItem(file, item.formId, true))}
              </RadioButtonGroup>
            </Styled.MultipleFormsGroup>
          ) : (
            // If there is only one file, we can render it as a single linked form.
            getListedItem(item)
          );
        })}
      </>
    );
  };

  const formString = pluralize('form', linkedFormsState.linkedFormsCount);
  const embeddedFormString = pluralize('embedded form', linkedFormsState.embeddedFormsCount);
  const hasValidFiles =
    linkedFormsState.uniqueFiles?.length > 0 ||
    linkedFormsState.groups?.length > 0 ||
    linkedFormsState.embeddedFormsCount > 0;
  const shouldShowLinkedFormsList =
    (linkedFormsState.hasOnlyLinkedForm || linkedFormsState.hasLinkedAndEmbeddedForms) &&
    linkedFormsState.linkedFormsCount > 0;

  // We hide the "Related Forms" section header in the process application deploy/run modal
  const shouldShowSectionHeader = !processApplicationStore?.fromAProcessApplication;

  return (
    <>
      {isLoading && <InlineLoading status="active" iconDescription="Loading" description="Loading data..." />}

      {hasValidFiles && (
        <Styled.LinkedFormsContainer>
          {shouldShowSectionHeader && <StyledDialog.SectionHeader>Related Forms</StyledDialog.SectionHeader>}

          {shouldShowLinkedFormsList && (
            <>
              <StyledDialog.SectionSubHeader>
                {shouldShowConversionMessage
                  ? `The following ${formString} are used in the ${target}:`
                  : `The following ${formString} will be automatically deployed with the ${target}:`}
              </StyledDialog.SectionSubHeader>

              <Styled.LinkedFormsList>
                {isLoading && <InlineLoading status="active" iconDescription="Loading" description="Loading data..." />}

                {renderGroupedLinkedFormsList()}
              </Styled.LinkedFormsList>
            </>
          )}

          {shouldShowConversionMessage &&
            (linkedFormsState.hasOnlyLinkedForm || linkedFormsState.hasLinkedAndEmbeddedForms) && (
              <Styled.InfoMessage kind="info" inline lowContrast hideCloseButton>
                <div>
                  The selected cluster version does not support linked forms. The {formString} will be embedded instead.
                  Read more about form embedding{' '}
                  <Link href={URL.FORM_EMBEDDING} target="_blank" rel="noreferrer">
                    here
                    <WithExternalLinkIcon />
                  </Link>
                  .
                </div>
              </Styled.InfoMessage>
            )}

          {(linkedFormsState.hasOnlyEmbeddedForm ||
            (!shouldShowConversionMessage && linkedFormsState.hasLinkedAndEmbeddedForms)) && (
            <Styled.InfoMessage kind="info" inline lowContrast hideCloseButton>
              The {target} contains {linkedFormsState.embeddedFormsCount} {embeddedFormString}.
            </Styled.InfoMessage>
          )}
        </Styled.LinkedFormsContainer>
      )}
    </>
  );
};

export default observer(LinkedFormsList);
