/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import isBpmnTextCopilotEnabled from 'experiments/ai-features/bpmn-copilot/is-bpmn-text-copilot-enabled';

import isBpmnCopilotEnabled from './is-bpmn-copilot-enabled';

/**
 * @param {object} currentDiagramStore instance of CurrentDiagramStore
 * @param {function} checkGate from useStatsigClient()
 * @return {boolean} whether the BPMN Copilot should be visible to the user
 */
export default function shouldShowBpmnCopilot(currentDiagramStore, checkGate) {
  const { isAdminOrEditor, isBPMN: isDiagramBpmn } = currentDiagramStore;

  return isDiagramBpmn && (isAdminOrEditor || isBpmnTextCopilotEnabled(checkGate)) && isBpmnCopilotEnabled(checkGate);
}
