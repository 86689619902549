/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

const disallowedDomains = ['localhost', '127.0.0.1', '0.0.0.0', 'camunda.com'];

export const isDisallowedDomain = (resourceUrl, extraDisallowedDomains = []) => {
  const url = new URL(resourceUrl);
  return [...disallowedDomains, ...extraDisallowedDomains].some((domain) => url.hostname.includes(domain));
};

export const isValidUrl = (resourceUrl) => {
  try {
    return Boolean(new URL(resourceUrl));
  } catch {
    return false;
  }
};

export const isProtocolAllowedOrMissing = (resourceUrl) => {
  const regex = /^(http:\/\/|https:\/\/)?/;
  return regex.test(resourceUrl);
};

export const prependHttpsIfMissing = (resourceUrl) => {
  if (!/^https?:\/\//i.test(resourceUrl)) {
    resourceUrl = `https://${resourceUrl}`;
  }

  return resourceUrl;
};
