/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

/**
 * @typedef {Object} Setting
 * @property {String} key
 * @property {String} value
 */

/**
 * @typedef {Object} DeploymentPolicy
 * @property {"DEPLOYMENT_POLICY"} key
 * @property { "ADMIN_ONLY" | "REVIEW_REQUIRED" } value
 */

class SettingsService extends Service {
  /**
   *
   * @param {string} organizationId
   * @param {string} key
   * @returns {Promise<Setting>}
   */
  fetchByOrganizationIdandKey(organizationId, key) {
    return this.get(`/internal-api/settings/organization/${organizationId}/key/${key}`);
  }

  /**
   * @param {string} organizationId
   * @returns {Promise<DeploymentPolicy>}
   */
  fetchDeploymentPolicy(organizationId) {
    return /** @type Promise<DeploymentPolicy> */ (
      this.fetchByOrganizationIdandKey(organizationId, 'DEPLOYMENT_POLICY')
    );
  }

  /**
   * @param {string} organizationId
   * @param {string} key
   * @param {string} value
   * @returns {Promise<Setting>}
   */
  putByOrganizationIdandKey(organizationId, key, value) {
    return this.put(`/internal-api/settings/organization/${organizationId}`, { key, value });
  }

  /**
   * @param {string} organizationId
   * @param {DeploymentPolicy["value"]} value
   * @returns {Promise<DeploymentPolicy>}
   */
  updateDeploymentPolicy(organizationId, value) {
    return /** @type Promise<DeploymentPolicy> */ (
      this.putByOrganizationIdandKey(organizationId, 'DEPLOYMENT_POLICY', value)
    );
  }
}

export default new SettingsService();
