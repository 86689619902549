/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Toggle } from '@carbon/react';
import { InformationFilled } from '@carbon/icons-react';

import { publicationStore } from 'App/Pages/Diagram/stores';

import * as Styled from './PublicationGroupComponent.styled';

const PublicationGroupComponent = () => {
  const { isStartEventAttachedToForm, isPublicAccessEnabled } = publicationStore;
  const isFeatureEnabled = isStartEventAttachedToForm;

  return (
    <Styled.PublicationGroup>
      <Styled.PublicationInformativeText>
        Allow anyone to start a process instance using a Form published on a public web link.
      </Styled.PublicationInformativeText>

      {!isFeatureEnabled && (
        <Styled.FormLinkSuggestion>
          <InformationFilled />
          First, link a form before publishing
        </Styled.FormLinkSuggestion>
      )}

      <Toggle
        id="publication-toggle"
        size="sm"
        aria-labelledby="Enable public access"
        title="Toggle public access"
        labelA="Public access disabled"
        labelB="Public access enabled"
        toggled={isPublicAccessEnabled}
        disabled={!isFeatureEnabled}
        onToggle={(flag) => {
          if (!isFeatureEnabled) {
            return;
          }
          publicationStore.setIsPublicAccessEnabled(flag);
        }}
      />
    </Styled.PublicationGroup>
  );
};

export default observer(PublicationGroupComponent);
