/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

import { clustersStore } from 'stores';

export default function useAppCues({
  history,
  currentOrganizationInfo,
  config,
  organizationStore,
  userAuthProviderId
}) {
  useEffect(() => {
    return history.listen(() => {
      trackAppCuesPageAndIdentity();
    });
  }, [history]);

  useEffect(() => {
    trackAppCuesPageAndIdentity();
  }, [currentOrganizationInfo?.uuid]);

  function trackAppCuesPageAndIdentity() {
    if (config.appcues?.enabled) {
      // the page call has to be called before identify
      window.Appcues?.page();

      // get values directly from store to avoid stale closure problem that leads to outdated state
      const organization = organizationStore.currentOrganization;
      const organizationInfo = organizationStore.currentOrganizationInfo;
      const clusters = clustersStore.clusters;

      if (organization && userAuthProviderId) {
        const appCuesAttributes = {
          orgId: organization?.id,
          salesPlanType: organizationInfo?.salesPlan?.type,
          roles: organization?.roles?.join('|'),
          clusters: clusters?.map((cluster) => cluster.uuid).join('|')
        };

        window.Appcues?.identify(userAuthProviderId, appCuesAttributes);
      }
    }
  }
}
