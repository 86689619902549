/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';

class ConnectorService extends Service {
  getActiveInboundConnectors(endpoint, processId, elementId) {
    return this.post('/connectors/inbound', { endpoint, processId, elementId });
  }
  getActiveInboundConnectorLogs(endpoint, tenantId, processId, elementId) {
    return this.post('/connectors/inbound/logs', {
      endpoint,
      tenantId,
      processId,
      elementId
    });
  }
}

export default new ConnectorService();
