/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { detailsPanelTabsStore } from 'App/Pages/Diagram/stores';
import { commentsStore } from 'stores';
import Tooltip from 'App/Pages/Diagram/Tooltip';

import * as Styled from './DocumentationOverlay.styled';

const DocumentationOverlay = ({ element, bpmnjs }) => {
  const openSidebarAndSwitchToPropertiesTab = () => {
    const { isSidebarVisible } = commentsStore.state;
    const { isPropertiesTabSelected } = detailsPanelTabsStore;

    const isClickedElementAlreadySelected = bpmnjs.get('selection').isSelected(element);
    if (!isSidebarVisible || (isClickedElementAlreadySelected && isPropertiesTabSelected)) {
      commentsStore.toggleSidebar();
    }

    bpmnjs.get('selection').select(element);
    detailsPanelTabsStore.switchTab(detailsPanelTabsStore.TABS.PROPERTIES);
  };
  return (
    <Tooltip label="Show documentation">
      <Styled.DocumentationIcon
        onClick={openSidebarAndSwitchToPropertiesTab}
        role="button"
        aria-label="Open properties tab"
        data-test="documentation-overlay"
      />
    </Tooltip>
  );
};

export default DocumentationOverlay;
