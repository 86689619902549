/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { shouldShowBpmnCopilot } from 'experiments/ai-features/bpmn-copilot';
import { currentDiagramStore } from 'stores';

import isBpmnCopilotEmptyStateExperiment from './is-bpmn-copilot-empty-state-experiment';

/**
 * @param {boolean} diagramContainsSingleStartEvent
 * @param {boolean} areAiFeaturesEnabled
 * @param {object} statsigClient from useStatsigClient()
 */
export default function shouldShowBpmnCopilotEmptyState(
  diagramContainsSingleStartEvent,
  areAiFeaturesEnabled,
  statsigClient
) {
  return (
    diagramContainsSingleStartEvent &&
    areAiFeaturesEnabled &&
    shouldShowBpmnCopilot(currentDiagramStore, statsigClient.checkGate) &&
    isBpmnCopilotEmptyStateExperiment(statsigClient.getExperiment)
  );
}
