/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import ReactDOM from 'react-dom';

import { trackingService } from 'services';

import { LandscapeViewButton } from './button';
import { LandscapeViewModal } from './modal';

export function LandscapeView({ project, tabIndex = 0 }) {
  const [isLandscapeViewModalOpened, setIsLandscapeViewModalOpened] = useState(false);

  const handleLandscapeOpen = () => {
    trackingService.trackLandscapeOpened({ scope: 'project' });
    setIsLandscapeViewModalOpened(true);
  };

  return (
    <>
      <LandscapeViewButton onClick={handleLandscapeOpen} tabIndex={tabIndex} />
      {ReactDOM.createPortal(
        <LandscapeViewModal
          isOpen={isLandscapeViewModalOpened}
          onClose={() => setIsLandscapeViewModalOpened(false)}
          project={project}
        />,
        document.body
      )}
    </>
  );
}
