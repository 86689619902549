/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { WarningFilled } from '@carbon/icons-react';

const ResourceEllipsed = `
  max-width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ResourceIconSize = '16px';

export const ResourceLogo = styled.img`
  width: ${ResourceIconSize};
  height: ${ResourceIconSize};
`;

export const ResourceName = styled.div`
  font-weight: 600;
  ${ResourceEllipsed}
`;

export const ResoureUrl = styled.div`
  color: var(--grey-lighten-60);
  ${ResourceEllipsed}
`;

export const ResourceLink = styled.div`
  color: inherit;

  svg {
    vertical-align: middle;
  }
`;

export const YellowWarningIcon = styled(WarningFilled)`
  fill: var(--cds-support-warning, #f1c21b);
  [data-icon-path='inner-path'] {
    fill: black;
    opacity: 1;
  }
`;

export const ResourceItem = styled.a`
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  line-height: ${ResourceIconSize};
  color: inherit;
  text-decoration: none;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: var(--spacing-small);
  }

  & ${ResourceLink} {
    visibility: hidden;
  }

  &:hover ${ResourceLink} {
    visibility: visible;
  }
`;
