/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { ActionableNotification } from '@carbon/react';

import { FormPreview } from 'components';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';

import * as Styled from './StartForm.styled';

const StartForm = ({ setPayload }) => {
  const startEventWithConflicts = formLinkStore.startEventHasUnresolvedFormConflicts;
  const formContent = formLinkStore.startEventFormContent;

  const handleFormPreviewChange = ({ data, errors }) => {
    // the error object of forms with dynamic lists is not empty even if the form is valid
    // e.g. {"Field1":[],"Field2":[[]]} which is why we have to deep check the object
    const isEmpty = (value) => {
      if (value == null || value === '') {
        return true;
      }

      if (Array.isArray(value)) {
        return value.length === 0 || value.every(isEmpty);
      }

      return false;
    };

    if (Object.values(errors).every(isEmpty)) {
      setPayload(JSON.stringify(data));
    }
  };

  if (startEventWithConflicts) {
    return (
      <div>
        <ActionableNotification kind="warning" title="Unable to render form preview" inline lowContrast hideCloseButton>
          The form ID associated with the start event is linked to multiple forms sharing the same ID. To proceed,
          select the form you intend to use as the start event.
        </ActionableNotification>
      </div>
    );
  }

  return formContent ? (
    <div>
      <Styled.FormPreviewContainer>
        <FormPreview bordered formDefinition={formContent} onChange={handleFormPreviewChange} />
      </Styled.FormPreviewContainer>
    </div>
  ) : (
    ''
  );
};

export default observer(StartForm);
