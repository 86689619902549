/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Wrapper = styled.span`
  border: 1px solid var(--silver-darken-80);
  height: 32px;
  margin: 0 var(--spacing-tiny) var(--spacing-tiny) 0;
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  padding: 0 12px;
  background-color: white;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: white;
  background-color: var(--silver-darken-80);
  border-radius: 11px;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0 -5px 0 5px;
  outline: none;
  &:hover,
  &:focus {
    background-color: var(--grey-lighten-56);
  }
`;
