/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Container = styled.div`
  // 40 is the height offset for the list header
  height: calc(100% - 40px);
`;

export const ModelingGuidelinesInfo = styled.span`
  padding: 6px 10px;
  line-height: 24px;
  display: inline-block;
`;
