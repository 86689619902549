/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { createRoot } from 'react-dom/client';

import DocumentationOverlay from './DocumentationOverlay';

export function DocumentationOverlayModule(eventBus, elementRegistry, overlays) {
  eventBus.on('import.done', () => {
    const elementsWithDocumentation = elementRegistry.filter((element) => {
      if (element.type === 'label') {
        return false;
      }

      return element.businessObject.get('documentation').length > 0;
    });

    elementsWithDocumentation.forEach((element) => {
      addOverlay(element, overlays);
    });
  });
}

DocumentationOverlayModule.$inject = ['eventBus', 'elementRegistry', 'overlays'];

export default {
  __init__: ['documentationOverlayModule'],
  documentationOverlayModule: ['type', DocumentationOverlayModule]
};

const addOverlay = (element, overlays) => {
  const html = document.createElement('div');
  html.setAttribute('role', 'button');
  html.setAttribute('aria-label', 'Documentation indicator');
  createRoot(html).render(<DocumentationOverlay element={element} />);

  if (is(element, 'bpmn:Process') || is(element, 'bpmn:Collaboration')) {
    html.style.position = 'absolute';
    html.style.top = '7px';
    html.style.left = '12px';

    document.querySelector('.djs-container').appendChild(html);
  } else {
    overlays.add(element, 'documentation-overlay', {
      position: { bottom: -10, right: 6 },
      show: { minZoom: 0.7 },
      html
    });
  }
};
