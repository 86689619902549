/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const MarkdownWrapper = styled.div`
  padding: 0 var(--cds-spacing-05);
  overflow-y: auto;
  height: 100%;
  width: 100%;

  .markdown-content {
    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    h6:first-child {
      margin-top: var(--cds-spacing-03);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: var(--cds-spacing-06);
      margin-bottom: var(--cds-spacing-03);
      color: var(--text-primary);
    }

    p {
      margin-bottom: var(--cds-spacing-05);
      line-height: 1.5;
    }

    ul,
    ol {
      margin-bottom: var(--cds-spacing-05);
      padding-left: var(--cds-spacing-06);
      line-height: 1.5;
    }

    ul {
      list-style-type: disc;
      ul {
        list-style-type: circle;
        ul {
          list-style-type: square;
        }
      }
    }

    ol {
      list-style-type: decimal;
      ol {
        list-style-type: lower-alpha;
        ol {
          list-style-type: lower-roman;
        }
      }
    }

    code {
      background: var(--silver-darken-87);
      padding: var(--cds-spacing-01) var(--cds-spacing-02);
      border-radius: var(--cds-spacing-01);
      font-family: 'IBM Plex Mono', monospace;
      font-size: 90%;
    }

    pre {
      background: var(--silver-darken-87);
      padding: var(--cds-spacing-05);
      border-radius: var(--cds-spacing-02);
      margin: var(--cds-spacing-05) 0;
      overflow-x: auto;

      code {
        background: none;
        padding: 0;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin: var(--cds-spacing-05) 0;

      th,
      td {
        border: 1px solid var(--silver-darken-87);
        padding: var(--cds-spacing-03) var(--cds-spacing-04);
        text-align: left;
      }

      th {
        background: var(--silver-darken-94);
      }
    }

    blockquote {
      border-left: var(--cds-spacing-02) solid var(--silver-darken-87);
      margin: var(--cds-spacing-05) 0;
      padding-left: var(--cds-spacing-05);
      color: var(--grey-darken-33);
    }

    hr {
      border: 0;
      border-top: 1px solid var(--silver-darken-87);
      margin: var(--cds-spacing-06) 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

export const InlineMarkdownWrapper = styled(MarkdownWrapper)`
  padding: 0;
  overflow: hidden;
`;
