/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import { currentDiagramStore } from 'stores';

const TAB_TYPES = {
  ERRORS: 'errors',
  OUTPUT: 'output',
  VARIABLES: 'variables'
};
class ErrorPanelStore {
  selected = TAB_TYPES.ERRORS;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.selected = TAB_TYPES.ERRORS;
  }

  switchToProblemsTab() {
    this.selected = TAB_TYPES.ERRORS;
    this.expandPanel();
  }

  switchToOutputTab() {
    this.selected = TAB_TYPES.OUTPUT;
    this.expandPanel();
  }

  switchToVariablesTab() {
    this.selected = TAB_TYPES.VARIABLES;
    this.expandPanel();
  }

  expandPanel() {
    if (currentDiagramStore.isErrorPanelCollapsed) {
      currentDiagramStore.setIsErrorPanelCollapsed(false);
    }
  }

  get showProblems() {
    return (
      currentDiagramStore.isBPMN && !currentDiagramStore.isErrorPanelCollapsed && this.selected === TAB_TYPES.ERRORS
    );
  }

  get showOutput() {
    return !currentDiagramStore.isErrorPanelCollapsed && this.selected === TAB_TYPES.OUTPUT;
  }

  get showVariables() {
    return (
      currentDiagramStore.isBPMN && !currentDiagramStore.isErrorPanelCollapsed && this.selected === TAB_TYPES.VARIABLES
    );
  }
}

export default new ErrorPanelStore();
