/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Dialog as PrimitiveDialog } from 'primitives';

export const Dialog = styled(PrimitiveDialog)`
  width: 630px;
`;

export const Content = styled(PrimitiveDialog.Content)`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const CheckmarkItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;
