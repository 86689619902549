/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { ComposedModal } from '@carbon/react';

export const Container = styled.main`
  display: flex;
  flex: 1;
  overflow-x: hidden;
`;

export const ProcessApplicationAdvertisingModal = styled(ComposedModal)`
  .cds--modal-container {
    max-width: 640px;
  }

  .cds--modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cds--btn--ghost {
    background: white;
  }
`;

export const Visual = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  margin-top: 64px;
  margin-bottom: 48px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Title = styled.h2`
  width: 287px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const Message = styled.div`
  margin-top: 24px;
  width: 287px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;

  ul {
    list-style-position: inside;
    margin-left: 8px;
    margin-top: 16px;
    margin-bottom: 16px;

    li {
      margin-bottom: 5px;
      list-style-type: disc;
    }
  }
`;
