/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import React, { Suspense } from 'react';

import { Spinner } from 'primitives';
import handleChunkLoadingError from 'utils/handleChunkLoadingError';

import lintingStore from './LintingStore';

const BpmnRenderer = React.lazy(() => import('./bpmn/BpmnRenderer').catch(handleChunkLoadingError));
const DmnRenderer = React.lazy(() => import('./dmn/DmnRenderer').catch(handleChunkLoadingError));

export default function Modeler({ diagram, ...props }) {
  return (
    <Suspense fallback={<Spinner fullHeight />}>
      {diagram.type === 'DMN' ? <DmnRenderer {...props} /> : <BpmnRenderer {...props} />}
    </Suspense>
  );
}

Modeler.lintingStore = lintingStore;
