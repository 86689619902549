/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link } from 'primitives';
import config from 'utils/config';
import { organizationStore } from 'stores';

import * as Styled from './MultiSelectInput.styled';

export default function UserNotFound() {
  return (
    <>
      <Styled.EmptyStateTitle>User not found!</Styled.EmptyStateTitle>
      <Styled.EmptyStateContent>
        <Styled.EmptyStateSubtitle>
          You can only invite members of the current organization to the project.
        </Styled.EmptyStateSubtitle>
        {organizationStore.hasMemberCreatePermission ? (
          <Styled.EmptyStateParagraph>
            Go to{' '}
            <Link
              href={`https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/management/users`}
              data-test="org-management-link"
              target="_blank"
              onMouseDown={(evt) => evt.preventDefault()}
            >
              Camunda Cloud User Management
            </Link>{' '}
            to invite people as members of the organization. After the invitations have been accepted, you can add them
            to your project.
          </Styled.EmptyStateParagraph>
        ) : (
          <Styled.EmptyStateParagraph>
            Contact the organization owner (see{' '}
            <Link
              href={`https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/management`}
              data-test="org-management-link"
              target="_blank"
              onMouseDown={(evt) => evt.preventDefault()}
            >
              Organization management
            </Link>{' '}
            page) to request adding people to the organization. After accepting the invitation as organization members,
            they can be added to your projects.
          </Styled.EmptyStateParagraph>
        )}
      </Styled.EmptyStateContent>
    </>
  );
}
