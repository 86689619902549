/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default function sanitizeTechnicalTerms(str = '') {
  if (typeof str !== 'string') {
    return str;
  }

  if (str.startsWith('bpmn:')) {
    return str.substring(5);
  } else if (str.startsWith('camunda:')) {
    return str.substring(8);
  } else if (str.startsWith('$')) {
    return str.substring(1);
  } else if (str === 'name') {
    return 'Label';
  } else if (str.startsWith('zeebe:')) {
    return str.substring(6);
  } else {
    return str;
  }
}
