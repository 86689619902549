/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { userStore } from 'stores';
import { fileService, projectService, folderService } from 'services';

export async function fetchReadmeInfo(readmeId) {
  const file = await fileService.fetchById(readmeId);
  const requests = [projectService.fetchById({ projectId: file.projectId })];
  if (file.folderId) {
    requests.push(folderService.fetchById(file.folderId));
  }
  const [project, folder] = await Promise.all(requests);
  return { readme: { ...file, folder }, project };
}

export async function updateReadme(readmeEntity, newContent) {
  return await fileService.update(readmeEntity.id, {
    name: readmeEntity.name,
    content: newContent,
    revision: readmeEntity.revision,
    // @ts-expect-error TS2353
    originAppInstanceId: userStore.originAppInstanceId
  });
}
