/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';

class RestoreService extends Service {
  /**
   * Restore a given process application version.
   * @param {string} versionId - The ID of the process application version.
   * @returns {Promise<import('services/ProcessApplicationService.common').ProcessApplicationVersion>}
   */
  async restoreProcessApplicationVersion(versionId, originAppInstanceId) {
    return this.post(`/internal-api/process-application-versions/${versionId}/restore`, {
      autoSaveName: 'Autosaved during restore',
      originAppInstanceId: originAppInstanceId
    });
  }
}

export default new RestoreService();
