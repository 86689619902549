/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { ActionableNotification } from '@carbon/react';
import { Copy } from '@carbon/icons-react';

import { notificationStore } from 'stores';
import { tracingService } from 'services';

import * as Styled from './InboundConnector.styled';

export const InboundConnectorDetailError = ({ error }) => {
  return (
    error && (
      <Styled.InboundConnectorDetailErrorContainer>
        <ActionableNotification
          kind="error"
          title={error?.code}
          lowContrast
          hideCloseButton
          subtitle={<div>{truncateErrorMessage(error?.message)}</div>}
        >
          {error?.message.length > 100 ? (
            <>
              <Styled.CopySupportInfo onClick={() => copyErrorLogs(error)}>
                Copy error logs for support <Copy />
              </Styled.CopySupportInfo>
            </>
          ) : (
            <></>
          )}
        </ActionableNotification>
      </Styled.InboundConnectorDetailErrorContainer>
    )
  );
};

const truncateErrorMessage = (message) => {
  return message.length > 99 ? message.slice(0, 94) + ' [...]' : message;
};

const copyErrorLogs = async (error) => {
  try {
    await navigator.clipboard.writeText(JSON.stringify(error, null, 2));
    notificationStore.showSuccess('The error logs have been copied into your clipboard as JSON.');
  } catch (ex) {
    notificationStore.showError(
      "Yikes! Couldn't copy the error logs into your clipboard. Make sure that you gave permissions to the browser."
    );
    tracingService.traceError(ex, 'Failed to copy error logs into clipboard');
  }
};

export default observer(InboundConnectorDetailError);
