/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('../types').FileSelection} FileSelection
 */

import minimapModule from 'diagram-js-minimap';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { DiagramControls, Modeler } from 'components';
import { diagramControlStore } from 'stores';
import { DMN } from 'utils/constants';
import { SendFeedbackButton } from 'features/send-feedback';

/**
 * @param {Object} props
 * @param {FileSelection|null} props.fileSelection
 */
export default function DmnViewer({ fileSelection }) {
  const [modeler, setModeler] = useState(null);
  const feedbackButtonSlotRef = useRef();

  const currentContent = fileSelection?.current?.content;

  useUpdateModelerContent(modeler, currentContent);
  useResetDiagramControls();

  return (
    <Container>
      <Modeler
        diagram={{ type: DMN }}
        initialContent={currentContent}
        isViewOnly
        additionalModules={{ drd: [minimapModule] }}
        bottomRight={
          <CanvasBottomRight>
            <FeedbackButtonSlot ref={feedbackButtonSlotRef}>
              <SendFeedbackButton feedbackButtonSlotRef={feedbackButtonSlotRef} from="process-app-versions" />
            </FeedbackButtonSlot>
            <DiagramControls />
          </CanvasBottomRight>
        }
        onModelerLoaded={(modeler) => {
          setModeler(modeler);
          diagramControlStore.setModeler(modeler);
        }}
      />
    </Container>
  );
}
const useUpdateModelerContent = (modeler, content) => {
  useEffect(() => {
    if (content && modeler) {
      modeler.importXML(content);
    }
  }, [content, modeler]);
};

const useResetDiagramControls = () => {
  useEffect(() => {
    return () => {
      diagramControlStore.reset();
    };
  }, []);
};

const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const CanvasBottomRight = styled.div``;

export const FeedbackButtonSlot = styled.div`
  position: absolute;
  right: 0;
  bottom: 32px;
`;
