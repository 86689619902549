/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {Object} Params
 * @property {string} idpProjectId - The ID of the IDP project
 */

import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { VersionsSidebar } from 'components/VersionsSidebar';
import { DiffingToggle, useIsDiffingEnabled } from 'features/toggle-diffing';
import { FileViewer, startLoading, stopLoading } from 'features/view-file';
import history from 'utils/history';
import buildSlug from 'utils/buildSlug';

import { Header } from './features/header';
import { IdpApplicationVersionsList } from './features/view-versions-list';
import { useLoadIdpProject, useLoadIdpProjectVersions, useMilestoneFileSelection } from './hooks';
import * as Styled from './IdpProjectVersions.styled';

const IdpProjectVersions = () => {
  /** @type {Params} */
  const { idpProjectId } = useParams();
  const idpProject = useLoadIdpProject(idpProjectId);
  const { versions, reload, isLoading } = useLoadIdpProjectVersions(idpProjectId);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState(null);
  const { milestoneFileSelection } = useMilestoneFileSelection(
    selectedMilestoneId,
    versions,
    startLoading,
    stopLoading
  );
  const { isDiffingEnabled, toggleDiffing } = useIsDiffingEnabled();

  if (!idpProject) {
    return;
  }

  if (!isLoading && versions.length === 0) {
    history.push(`/idp-projects/${buildSlug(idpProject.id, idpProject.name)}/upload`);
  }

  return (
    <Styled.Container>
      <Header idpProject={idpProject} />
      <Styled.FileContainer>
        <FileViewer fileSelection={milestoneFileSelection} isDiffingEnabled={isDiffingEnabled} />
      </Styled.FileContainer>
      <VersionsSidebar
        isShowDiffingToggle
        VersionsList={
          <IdpApplicationVersionsList
            versions={versions}
            reloadVersions={reload}
            selectedMilestoneId={selectedMilestoneId}
            setSelectedMilestoneId={setSelectedMilestoneId}
            isDiffingEnabled={isDiffingEnabled}
          />
        }
        DiffingToggle={<DiffingToggle isDiffingEnabled={isDiffingEnabled} toggleDiffing={toggleDiffing} />}
      />
    </Styled.Container>
  );
};

export default IdpProjectVersions;
