/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown, InlineLoading } from '@carbon/react';
import { useState, useEffect, useCallback } from 'react';
import { Button as CarbonButton } from '@carbon/react/lib/components/Button';
import { UnknownFilled } from '@carbon/icons-react';

import '@carbon/charts/styles.css';

import TestCaseSummary from 'App/Pages/Project/IdpApplication/IdpProject/test-case-summary/TestCaseSummary';
import ExtractionFieldAction from 'App/Pages/Project/IdpApplication/IdpProject/IdpEvaluateExtraction/ExtractionFieldAction/ExtractionFieldAction';
import { EntityTable } from 'components';
import {
  EXTRACTION_MODELS,
  EXTRACTION_VALIDATION_STATUS
} from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';
import { idpApplicationStore, idpProjectStore } from 'stores';
import { CLUSTER_STATUS } from 'utils/cluster/constants';
import useNavigationGuard from 'App/Pages/Project/IdpApplication/IdpProject/hooks/useNavigationGuard';
import IdpExtractionInProgressWarningModal from 'App/Pages/Project/IdpApplication/IdpProject/idp-extraction-in-progress-warning-modal/IdpExtractionInProgressWarningModal';

import ExtractionExpandedSection from './ExtractionExpandedSection/ExtractionExpandedSection';
import * as Styled from './EvaluateExtraction.styled';
import { ExtractDataLink } from './EvaluateExtraction.styled';

const EvaluateExtraction = ({ clusterStatus, areIdpConnectorSecretsMissing, onSwitchTabToExtractData }) => {
  const [idpExtractionFieldTestcaseResults, setIdpExtractionFieldTestcaseResults] = useState([]);
  const [testingInProgress, setTestingInProgress] = useState(false);

  const { idpProject, idpDocuments, idpExtractionFields, activeExtractionModel } = idpProjectStore;
  const { idpApplication } = idpApplicationStore;
  const {
    isModalOpen: isIdpExtractionInProgressWarningModalOpen,
    handleConfirm,
    handleCancel
  } = useNavigationGuard({
    isDirty: testingInProgress
  });

  const fetchTestcaseResults = useCallback(
    async (llmModelId) => {
      let testcaseResults;
      if (idpProjectStore.doesTestCaseResultExistForModelId(llmModelId)) {
        testcaseResults = idpProjectStore.getModelTestCaseResults(llmModelId);
      } else {
        testcaseResults = await idpProjectStore.fetchIdpExtractionFieldTestcaseResults(idpProject.id, llmModelId);
      }
      setIdpExtractionFieldTestcaseResults(testcaseResults);
    },
    [idpProject.id]
  );

  useEffect(() => {
    idpProjectStore.resetModelTestCaseResultsMap();
  }, []);

  useEffect(() => {
    fetchTestcaseResults(activeExtractionModel);
  }, [activeExtractionModel, fetchTestcaseResults]);

  useEffect(() => {
    if (!activeExtractionModel) {
      idpProjectStore.setActiveExtractionModel(EXTRACTION_MODELS[0].id);
    }
  }, [activeExtractionModel]);

  const handleUpdateExtractionField = async (extractionField) => {
    // Update the extraction field on the backend/store
    await idpProjectStore.updateIdpExtractionField(
      extractionField.id,
      extractionField.name,
      extractionField.type,
      extractionField.llmPromptDescription
    );

    // Update the local test case results for this extraction field
    setIdpExtractionFieldTestcaseResults((prevResults) =>
      prevResults.map((field) =>
        field.idpExtractionFieldId === extractionField.id
          ? {
              ...field,
              idpExtractionFieldName: extractionField.name,
              idpExtractionFieldLlmPromptDescription: extractionField.llmPromptDescription
            }
          : field
      )
    );
  };

  function handleExtractionModelChange(llmModelId) {
    idpProjectStore.setActiveExtractionModel(llmModelId);
    fetchTestcaseResults(llmModelId);
  }

  function isTestButtonDisabled() {
    return (
      testingInProgress ||
      !activeExtractionModel ||
      clusterStatus !== CLUSTER_STATUS.HEALTHY ||
      areIdpConnectorSecretsMissing ||
      idpExtractionFields.length === 0
    );
  }

  const updateTestCaseResultsFromResponse = (apiResponse) => {
    const updatedResults = idpExtractionFieldTestcaseResults.map((field) => {
      const updatedTestCaseResults = field.testCaseResults.map((testCase) => {
        // Instead of directly comparing testCase.idpDocumentId with responseItem.clusterDocumentId,
        // we first obtain the matching document from idpDocuments
        const matchedDoc = idpProjectStore.idpDocuments.find((doc) => doc.id === testCase.idpDocumentId);
        if (matchedDoc) {
          const responseItem = apiResponse.find((item) => item.clusterDocumentId === matchedDoc.clusterDocumentId);
          if (responseItem) {
            const extractedValue = responseItem.variables[field.idpExtractionFieldName]?.toString() ?? '';

            if (testCase.expectedValue != null) {
              return {
                ...testCase,
                extractedValue,
                status:
                  testCase.expectedValue?.toString() === extractedValue
                    ? EXTRACTION_VALIDATION_STATUS.PASS
                    : EXTRACTION_VALIDATION_STATUS.FAIL
              };
            } else {
              return {
                ...testCase,
                extractedValue,
                status: null
              };
            }
          }
        }
        return testCase;
      });

      return {
        ...field,
        testCaseResults: updatedTestCaseResults
      };
    });
    idpProjectStore.addModelTestCaseResults(activeExtractionModel, updatedResults);
    setIdpExtractionFieldTestcaseResults(updatedResults);
    persistUpdatedResults(updatedResults);
  };

  async function testAllDocuments() {
    setTestingInProgress(true);
    // Set all test cases to "IN_PROGRESS"
    setIdpExtractionFieldTestcaseResults(
      idpExtractionFieldTestcaseResults.map((field) => ({
        ...field,
        testCaseResults: field.testCaseResults.map((result) => ({
          ...result,
          status: 'IN_PROGRESS'
        }))
      }))
    );

    try {
      const response = await idpProjectStore.extractIdpDocuments(
        idpProject.id,
        idpApplication.clusterId,
        idpDocuments.map((doc) => doc.id),
        idpExtractionFields,
        activeExtractionModel
      );
      await fetchTestcaseResults(idpProjectStore.activeExtractionModel);
      if (response) {
        updateTestCaseResultsFromResponse(response);
      }
    } finally {
      setTestingInProgress(false);
    }
  }

  async function persistUpdatedResults(updatedResults) {
    const testResults = updatedResults
      .map((field) =>
        field.testCaseResults.map((testCase) => ({
          idpDocumentId: testCase.idpDocumentId,
          idpExtractionFieldId: field.idpExtractionFieldId,
          llmModelId: activeExtractionModel,
          status: testCase.status,
          extractedValue: testCase.extractedValue
        }))
      )
      .flat()
      .filter(
        (field) =>
          field.status === EXTRACTION_VALIDATION_STATUS.PASS || field.status === EXTRACTION_VALIDATION_STATUS.FAIL
      );
    if (testResults.length > 0) {
      await idpProjectStore.updateIdpTestCaseResults(idpProject.id, testResults);
    }
  }

  function setActiveDocumentAndSwitchTab(idpDocumentId) {
    const idpDocument = idpDocuments.find((doc) => doc.id === idpDocumentId);
    onSwitchTabToExtractData(idpDocument);
  }

  function getSelectedExtractionModel() {
    if (activeExtractionModel) {
      return EXTRACTION_MODELS.find((model) => model.id === activeExtractionModel);
    } else {
      return EXTRACTION_MODELS[0];
    }
  }

  const renderTestcaseResults = (testCaseResults) => {
    if (testCaseResults) {
      // calculate pass, fail, missing
      const numberOfPassedCases = testCaseResults.filter(
        (result) => result.status === EXTRACTION_VALIDATION_STATUS.PASS
      ).length;
      const numberOfFailedCases = testCaseResults.filter(
        (result) => result.status === EXTRACTION_VALIDATION_STATUS.FAIL
      ).length;
      const numberOfMissingCases = testCaseResults.filter((result) => result.status == null).length;
      const numberOfInProgressCases = testCaseResults.filter(
        (result) => result.status === EXTRACTION_VALIDATION_STATUS.IN_PROGRESS
      ).length;

      return (
        <Styled.ResultsBundle>
          {numberOfInProgressCases === testCaseResults.length ? (
            <Styled.StatusOverviewContainer>
              <InlineLoading status="active" iconDescription="Loading" description="Testing documents" />
            </Styled.StatusOverviewContainer>
          ) : numberOfMissingCases === testCaseResults.length ? (
            <Styled.StatusOverviewContainer data-test="missing-test-case-results">
              <UnknownFilled size={16} />
              Test documents to see result
            </Styled.StatusOverviewContainer>
          ) : (
            <>
              <Styled.ResultsContainer>
                {numberOfPassedCases > 0 && (
                  <Styled.StatusOverviewContainer data-test="pass-test-case-results">
                    <Styled.GreenCheckmarkFilled size={16} />
                    {numberOfPassedCases}
                  </Styled.StatusOverviewContainer>
                )}
              </Styled.ResultsContainer>
              <Styled.ResultsContainer>
                {numberOfMissingCases > 0 && (
                  <Styled.StatusOverviewContainer data-test="missing-test-case-results">
                    <Styled.YellowWarningFilled size={16} />
                    {numberOfMissingCases}
                  </Styled.StatusOverviewContainer>
                )}
              </Styled.ResultsContainer>
              <Styled.ResultsContainer>
                {numberOfFailedCases > 0 && (
                  <Styled.StatusOverviewContainer data-test="fail-test-case-results">
                    <Styled.RedErrorFilled size={16} />
                    {numberOfFailedCases}
                  </Styled.StatusOverviewContainer>
                )}
              </Styled.ResultsContainer>
            </>
          )}
        </Styled.ResultsBundle>
      );
    }
    return '';
  };

  return (
    <>
      <Styled.EvaluateExtractionSectionContainer data-test="evaluate-extraction-section">
        <Styled.HeaderContainer data-test="header-section">
          <Styled.Header>
            <Styled.DropdownContainer>
              <Dropdown
                id="extraction-model"
                titleText="Document extraction model"
                label="Select extraction model"
                helperText="The selected model will be used in BPMN process."
                items={EXTRACTION_MODELS}
                initialSelectedItem={getSelectedExtractionModel()}
                itemToString={(model) => (model ? model.name : '')}
                onChange={({ selectedItem }) => handleExtractionModelChange(selectedItem.id)}
              />
            </Styled.DropdownContainer>
            <CarbonButton
              size="sm"
              kind="tertiary"
              className={'evaluate-button'}
              disabled={isTestButtonDisabled()}
              onClick={() => testAllDocuments()}
            >
              Test documents
            </CarbonButton>
            <Styled.TestCaseSummaryContainer>
              <TestCaseSummary testCaseResults={idpExtractionFieldTestcaseResults} />
            </Styled.TestCaseSummaryContainer>
          </Styled.Header>
        </Styled.HeaderContainer>

        {idpExtractionFields.length === 0 && (
          <Styled.NoExtractionFieldsContainer>
            <p>
              No extraction fields configured. Please add a new field in the{' '}
              <ExtractDataLink onClick={() => onSwitchTabToExtractData()}>Extract data</ExtractDataLink> tab.
            </p>
          </Styled.NoExtractionFieldsContainer>
        )}
        {activeExtractionModel && idpExtractionFieldTestcaseResults.length > 0 && (
          <Styled.TableContainer>
            <EntityTable
              toolbarPlaceholder={'Search by extraction field'}
              data-test="extraction-field-table"
              columns={[
                { key: 'field', header: 'Field', width: '20%' },
                { key: 'prompt', header: 'Prompt', width: '30%' },
                {
                  key: 'testCaseResults',
                  header: 'Test Case Results',
                  width: '40%',
                  renderer: (field) => renderTestcaseResults(field)
                },
                {
                  key: 'entityAction',
                  header: '',
                  width: '10%',
                  renderer: (entity) => (
                    <ExtractionFieldAction entity={entity} onUpdateExtractionField={handleUpdateExtractionField} />
                  )
                }
              ]}
              expandable
              renderExpanded={(row) => {
                // Find the matching field from the testcase results.
                const field = idpExtractionFieldTestcaseResults.find((field) => field.idpExtractionFieldId === row.id);
                return <ExtractionExpandedSection field={field} onSetActiveDocument={setActiveDocumentAndSwitchTab} />;
              }}
              rows={idpExtractionFieldTestcaseResults.map((entity) => ({
                id: entity.idpExtractionFieldId,
                field: entity.idpExtractionFieldName,
                prompt: entity.idpExtractionFieldLlmPromptDescription,
                testCaseResults: entity.testCaseResults,
                entityAction: entity
              }))}
            />
          </Styled.TableContainer>
        )}
      </Styled.EvaluateExtractionSectionContainer>
      <IdpExtractionInProgressWarningModal
        isOpen={isIdpExtractionInProgressWarningModalOpen}
        modalText={`Extraction is in progress for IDP documents. Navigating away will result in a loss of extraction result.`}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </>
  );
};

export default EvaluateExtraction;
