/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('../types').FileSelection} FileSelection
 */

import minimapModule from 'diagram-js-minimap';
import { useEffect, useRef } from 'react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import ExecutionPlatformModule from '@camunda/execution-platform';
import { CloudElementTemplatesPropertiesProviderModule } from 'bpmn-js-element-templates';
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  ZeebePropertiesProviderModule
} from 'bpmn-js-properties-panel';

import connectorsExtension from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension';
import { DiagramControls, Modeler } from 'components';
import { diagramControlStore, currentDiagramStore } from 'stores';
import { BPMN } from 'utils/constants';
import { SendFeedbackButton } from 'features/send-feedback';

import { DiffingDetailsModal, diffingStyles, shadedDiagram, useDiffing } from './diffing';

/**
 * @param {Object} props
 * @param {FileSelection|null} props.fileSelection
 * @param {Boolean} props.isDiffingEnabled
 * @param {Object} props.bpmnViewer
 * @param {Function} props.setBpmnViewer
 */
export default function BpmnViewer({ fileSelection, isDiffingEnabled, bpmnViewer, setBpmnViewer }) {
  const feedbackButtonSlotRef = useRef();

  const currentContent = fileSelection?.current?.content;
  const previousContent = fileSelection?.previous?.content;

  useUpdateModelerContent(bpmnViewer, currentContent);
  useDiffing(currentContent, previousContent, bpmnViewer, isDiffingEnabled);
  useResetDiagramControls();
  useResetBpmnViewer(setBpmnViewer);

  return (
    <Container>
      <Modeler
        diagram={{ type: BPMN }}
        initialContent={isDiffingEnabled ? shadedDiagram(currentContent) : currentContent}
        isViewOnly
        additionalModules={modelerPlugins()}
        bottomRight={
          <CanvasBottomRight>
            <FeedbackButtonSlot ref={feedbackButtonSlotRef}>
              <SendFeedbackButton feedbackButtonSlotRef={feedbackButtonSlotRef} from="process-app-versions" />
            </FeedbackButtonSlot>
            <DiagramControls />
          </CanvasBottomRight>
        }
        onModelerLoaded={(modeler) => {
          setBpmnViewer(modeler);
          diagramControlStore.setModeler(modeler);
        }}
      />
      <DiffingDetailsModal />
    </Container>
  );
}

const modelerPlugins = () => {
  // the connectors extension requires pure JS objects instead of mobx observables
  // so we need to convert them before passing them to the connectors extension
  const templates = toJS(currentDiagramStore.state.templates);

  return [
    minimapModule,
    CloudElementTemplatesPropertiesProviderModule,
    BpmnPropertiesPanelModule,
    BpmnPropertiesProviderModule,
    ZeebePropertiesProviderModule,
    connectorsExtension(templates),
    ExecutionPlatformModule
  ];
};

const useUpdateModelerContent = (bpmnViewer, content) => {
  useEffect(() => {
    if (content && bpmnViewer) {
      bpmnViewer.importXML(content);
    }
  }, [content, bpmnViewer]);
};

const useResetDiagramControls = () => {
  useEffect(() => {
    return () => {
      diagramControlStore.reset();
    };
  }, []);
};

const useResetBpmnViewer = (setBpmnViewer) => {
  useEffect(() => {
    return () => {
      setBpmnViewer(null);
    };
  }, []);
};

const Container = styled.div`
  display: flex;
  flex: 1;

  ${diffingStyles}
`;

export const CanvasBottomRight = styled.div``;

export const FeedbackButtonSlot = styled.div`
  position: absolute;
  right: 0;
  bottom: 32px;
`;
