/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplicationVersionListEntryDto} ProcessApplicationVersionListEntryDto
 */

import { useState, useEffect } from 'react';

import { processApplicationService } from 'services';

/**
 * @param {String} processApplicationId
 * @returns {{ versions: ProcessApplicationVersionListEntryDto[], reload: () => Promise<void> }} - An object containing the list of process application versions and a reload function.
 */
const useLoadProcessApplicationVersions = (processApplicationId) => {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    loadVersions();
  }, [processApplicationId]);

  const loadVersions = async () => {
    if (processApplicationId) {
      let processApplicationVersions = [];
      try {
        processApplicationVersions = (
          await processApplicationService.fetchProcessApplicationVersions(processApplicationId)
        ).sort((a, b) => b.created - a.created);
      } catch (error) {
        console.error('Error while fetching process application versions', error);
      }

      setVersions(processApplicationVersions);
    }
  };

  return { versions, reload: loadVersions };
};

export default useLoadProcessApplicationVersions;
