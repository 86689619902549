/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { withRouter } from 'react-router-dom';
import minimapModule from 'diagram-js-minimap';
import executableFixModule from 'bpmn-js-executable-fix';
import ExecutionPlatformModule from '@camunda/execution-platform';
import addExporterModule from '@bpmn-io/add-exporter';
import { BpmnJSTracking, BpmnJSTrackingModules } from 'bpmn-js-tracking';
import { CloudElementTemplatesPropertiesProviderModule } from 'bpmn-js-element-templates';
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  ZeebePropertiesProviderModule
} from 'bpmn-js-properties-panel';
import { BpmnImprovedCanvasModule } from '@camunda/improved-canvas';
import RefactoringsModule from '@bpmn-io/refactorings';

import '@camunda/linting/assets/linting.css';

import {
  attentionGrabberStore,
  commentsStore,
  currentDiagramStore,
  diagramControlStore,
  diagramExtensionStore,
  realtimeCollaborationStore
} from 'stores';
import { bpmnJsTrackingService, fileService, projectService, trackingService } from 'services';
import { DropTarget, Modeler } from 'components';
import { dedicatedModesStore, deploymentErrorsStore } from 'App/Pages/Diagram/stores';
import { XMLEditor, XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';
import lintingAnnotationsModule from 'App/Pages/Diagram/LintingAnnotations';
import config from 'utils/config';
import { ResizablePanel } from 'primitives';

import './override.scss';

import DetailsPanel from './DetailsPanel/DetailsPanel';
import * as Styled from './Diagram.styled';
import attentionGrabberExtension from './BpmnJSExtensions/attentionGrabberExtension';
import commentsExtension from './BpmnJSExtensions/commentsExtension';
import clipboardExtension from './BpmnJSExtensions/clipboardExtension';
import conflictPreventionExtension from './BpmnJSExtensions/conflictPreventionExtension';
import templateExtension from './BpmnJSExtensions/templateExtension';
import connectorsExtension, { hasBuiltInTemplates } from './BpmnJSExtensions/connectorsExtension';
import detectUserTasksExtension from './BpmnJSExtensions/detectUserTasksExtension';
import linkedFormVariableProviderExtension from './BpmnJSExtensions/linkedFormVariableProvider';
import publicationTabExtension from './Publication/publicationTabExtension';
import publicationPropertiesProvider from './Publication/publicationPropertiesGroupExtension';
import { tokenSimulationModule, viewerTokenSimulationModule } from './BpmnJSExtensions/tokenSimulationExtension';
import tokenSimulationTrackingExtension from './BpmnJSExtensions/tokenSimulationTrackingExtension';
import { formLinkExtension, FormLinking, noneStartEventCommandInterceptor } from './FormLinking';
import DiagramErrorPanel from './ErrorPanel';
import { callActivityLinkExtension, CallActivityLinking } from './CallActivityLinking';
import { businessRuleTaskLinkExtension, BusinessRuleTaskLinking } from './BusinessRuleTaskLinking';
import { storeSelectedElementExtension } from './BpmnJSExtensions/storeSelectedElementExtension';
import { browseMarketplaceExtension } from './BpmnJSExtensions/browseMarketplaceExtension';
import documentationOnlyPropertiesProvider from './BpmnJSExtensions/documentationOnlyPropertiesProvider';
import documentationTrackingExtension from './BpmnJSExtensions/documentationTrackingExtension';
import documentationOverlayExtension from './BpmnJSExtensions/documentationOverlayExtension';
import undoRedoButtonsExtension from './features/undoRedo';
import CanvasBottomRight from './CanvasBottomRight';

export class BpmnViewer extends Component {
  constructor() {
    super();
    this.convertLinkedFormsToEmbeddedForms = fileService.convertLinkedFormsToEmbeddedForms.bind(fileService);
    this.fetchFilesByDecisionId = projectService.fetchFilesByDecisionId.bind(projectService);
    this.fetchFilesByProcessId = projectService.fetchFilesByProcessId.bind(projectService);
    this.mixpanelTrack = trackingService.track.bind(trackingService);
  }

  componentDidMount() {
    this.init();

    trackingService.trackPageView('diagram');
  }

  componentWillUnmount() {
    this.reset();
  }

  // Reset and init if the URL changes.
  async componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      this.reset();
      this.init();
    }
  }

  reset() {
    diagramExtensionStore.reset();
    commentsStore.reset();
    attentionGrabberStore.reset();
    bpmnJsTrackingService.dispose();
    currentDiagramStore.resetInitialViewbox();
  }

  init() {
    const { diagram, project } = currentDiagramStore.state;

    currentDiagramStore.setInitialViewbox();

    commentsStore.init(diagram, this.props.permission.is(['READ']) ? null : project);

    attentionGrabberStore.currentDiagramId = diagram.id;
    attentionGrabberStore.isInProjectContext = true;
  }

  handleModelerLoaded = (modeler) => {
    attentionGrabberStore.setModeler(modeler);
    diagramControlStore.setModeler(modeler);
    currentDiagramStore.setModeler(modeler);
    commentsStore.setModeler(modeler);
    realtimeCollaborationStore.init({ permission: this.props.permission });
    bpmnJsTrackingService.init(modeler);
  };

  handleModelerInit = () => {
    currentDiagramStore.trackDiagramView('direct');
  };

  get modelerPlugins() {
    const { experiments = {} } = this.props;
    const { aiFeatures = false } = experiments;

    // the connectors extension requires pure JS objects instead of mobx observables
    // so we need to convert them before passing them to the connectors extension
    const templates = toJS(currentDiagramStore.state.templates);

    const modelingPlugins = [
      conflictPreventionExtension,
      detectUserTasksExtension,
      storeSelectedElementExtension,
      BpmnJSTracking,
      BpmnJSTrackingModules,
      linkedFormVariableProviderExtension,
      noneStartEventCommandInterceptor,
      templateExtension,
      tokenSimulationModule,
      tokenSimulationTrackingExtension,
      documentationTrackingExtension,
      undoRedoButtonsExtension,
      BpmnImprovedCanvasModule
    ];

    if (aiFeatures) {
      modelingPlugins.push(RefactoringsModule);
    }

    if (config.marketplace?.enabled) {
      modelingPlugins.push(browseMarketplaceExtension);
    }

    const commentPlugins = [
      attentionGrabberExtension,
      commentsExtension,
      lintingAnnotationsModule,
      connectorsExtension(templates),
      ExecutionPlatformModule,
      publicationTabExtension,
      publicationPropertiesProvider,
      documentationOverlayExtension,
      documentationOnlyPropertiesProvider
    ];

    const viewPlugins = [
      minimapModule,
      executableFixModule,
      clipboardExtension,
      addExporterModule,
      formLinkExtension,
      callActivityLinkExtension,
      businessRuleTaskLinkExtension,
      viewerTokenSimulationModule,
      tokenSimulationTrackingExtension,
      CloudElementTemplatesPropertiesProviderModule,
      BpmnPropertiesPanelModule,
      BpmnPropertiesProviderModule,
      ZeebePropertiesProviderModule
    ];

    if (this.props.permission.is(['WRITE', 'ADMIN'])) {
      return [...modelingPlugins, ...viewPlugins, ...commentPlugins];
    } else if (this.props.permission.is('COMMENT')) {
      return [...viewPlugins, ...commentPlugins];
    } else {
      return [...viewPlugins];
    }
  }

  handleChange = () => {
    if (!XMLEditorStore.isEditorOpen) {
      // prevents double save, when the XML editor is open
      currentDiagramStore.debouncedSaveContent();
    }
  };

  render() {
    const { permission } = this.props;

    const { initialViewbox, handleViewboxChange } = currentDiagramStore;
    const { diagram, isLoadingModeler, isShowingTemplate, templates } = currentDiagramStore.state;
    const { lintingStore } = Modeler;

    const hasConnectors = templates.length > 0 || hasBuiltInTemplates;
    const shouldRenderXMLEditor = permission.is(['WRITE', 'ADMIN']) && XMLEditorStore.isEditorOpen;

    const isViewOnly = permission.is(['COMMENT', 'READ']);

    const getContainerId = () => {
      if (dedicatedModesStore.isDesignMode) {
        return dedicatedModesStore.designModeAriaControl;
      } else if (dedicatedModesStore.isImplementMode) {
        return dedicatedModesStore.implementModeAriaControl;
      }
      return null;
    };

    // if we are not fetching and we have a diagram, show the diagram
    return (
      <>
        <DropTarget
          isDisabled={permission.is(['COMMENT', 'READ'])}
          onDrop={currentDiagramStore.uploadFiles}
          text="Drop to replace current diagram"
          description="Previous diagram will be saved as a milestone"
        >
          <Styled.Wrapper
            className="diagram-container"
            id={getContainerId()}
            $showTemplates={isShowingTemplate}
            $hasConnectors={hasConnectors}
            $isBpmn
            $isViewOnly={isViewOnly}
          >
            <Modeler
              diagram={diagram}
              initialContent={diagram.content}
              initialViewbox={initialViewbox}
              onViewboxChange={handleViewboxChange}
              onChanged={this.handleChange}
              onModelerLoaded={this.handleModelerLoaded}
              onModelerInit={this.handleModelerInit}
              bottomRight={<CanvasBottomRight permission={permission} location="bpmnCanvas" />}
              additionalModules={this.modelerPlugins}
              isViewOnly={permission.is(['READ', 'COMMENT'])}
            />
            {!permission.is(['READ']) && <DetailsPanel permission={permission} showSidebarTitle />}
          </Styled.Wrapper>
          <BusinessRuleTaskLinking isLoadingModeler={isLoadingModeler} permission={permission} />
          <CallActivityLinking isLoadingModeler={isLoadingModeler} permission={permission} />
          <FormLinking isLoadingModeler={isLoadingModeler} permission={permission} />
        </DropTarget>

        {permission.is(['WRITE', 'ADMIN', 'COMMENT']) &&
          (dedicatedModesStore.isDesignMode || dedicatedModesStore.isImplementMode) && (
            <ResizablePanel
              panelKey="error-panel"
              handleAriaLabel="Resize error panel"
              open={!currentDiagramStore.isErrorPanelCollapsed}
              background="white"
              position="bottom"
              minSize={235}
              maxSize={560}
              sizeClosed={35}
              onOpenChange={(state) => currentDiagramStore.setIsErrorPanelCollapsed(state)}
            >
              <DiagramErrorPanel
                lintErrors={lintingStore.lintErrors}
                deployErrors={deploymentErrorsStore.deploymentErrors}
                isDeploymentAllowed={this.props.permission.is(['ADMIN', 'EDIT'])}
              />
            </ResizablePanel>
          )}

        {shouldRenderXMLEditor && <XMLEditor />}
      </>
    );
  }
}

export default withRouter(observer(BpmnViewer));
