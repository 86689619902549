/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import parseXMLtoModdleDefinition from './parse-xml-to-moddle-definition';
import neutralizeProcessIds from './neutralize-process-ids';

export default async function parseDiagramsToDefinitions(arrayOfDiagrams) {
  const moddleDefinitions = await Promise.all(arrayOfDiagrams.map(parseXMLtoModdleDefinition));
  const preparedDefinitions = await Promise.all(moddleDefinitions.map(neutralizeProcessIds));

  return preparedDefinitions;
}
