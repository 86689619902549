/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Modal, TextInput } from '@carbon/react';

export const ProcessApplicationModal = styled(Modal)`
  .cds--modal-container {
    max-width: 740px;
  }

  .cds--modal-content {
    /*
     * Needed for dropdowns inside the modal to render properly
     * See https://github.com/carbon-design-system/carbon/issues/4684#issuecomment-554090767
     */
    overflow: visible;
  }
`;

export const ProcessApplicationModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const NameInput = styled(TextInput)`
  width: 375px;
`;
