/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

const addBottomLeftOverlay = ({ overlays, overlayType, element, bottomPosition = -10 }) => {
  const target = document.createElement('div');
  target.classList.add(overlayType);
  target.style.position = 'relative';

  return overlays.add(element, overlayType, {
    position: {
      bottom: bottomPosition,
      left: 8
    },
    show: {
      minZoom: 0.7
    },
    html: target
  });
};

const removeOverlay = (overlays, overlayType, element) => {
  try {
    overlays.remove({ element: element.id, type: overlayType });
  } catch (err) {
    console.warn(err);
  }
};

const removeOverlayById = (overlays, overlayId) => {
  try {
    overlays.remove(overlayId);
  } catch (err) {
    console.warn(err);
  }
};

export { addBottomLeftOverlay, removeOverlay, removeOverlayById };
