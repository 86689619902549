/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from 'components';
import { milestoneService } from 'services';
import { notificationStore } from 'components/NotificationSystem';

export function PublishToOrganizationListItem({ milestoneId, reloadVersions, closeVersionMenuDropdown }) {
  return (
    // @ts-expect-error TS2554
    <Dropdown.ListItem
      onClick={async () => {
        try {
          closeVersionMenuDropdown();
          await milestoneService.publishToOrganization(milestoneId);
          notificationStore.showSuccess('IDP project version published to organization.');
          reloadVersions();
        } catch {
          notificationStore.showError('Failed to publish the IDP project version.');
        }
      }}
    >
      Publish to organization
    </Dropdown.ListItem>
  );
}
