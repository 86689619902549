/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, TextArea, TextInput } from '@carbon/react';

import { idpProjectStore } from 'stores';
import { EXTRACTION_MODELS } from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';
import TestCaseSummary from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/unstructured-extraction/test-case-summary/TestCaseSummary';

import * as Styled from './IdpExtractionProjectPublishModal.styled';

function IdpExtractionProjectPublishModal({
  selectedLlmModelId,
  isPublishExtractionProjectModalVisible,
  isPublishingOnOrganizationLevel,
  onCloseModal
}) {
  const { idpProject } = idpProjectStore;

  const [versionName, setVersionName] = useState('');
  const [versionDescription, setVersionDescription] = useState('');
  const [extractionModel, setExtractionModel] = useState(null);
  const [testCaseResults, setTestCaseResults] = useState([]);

  const isPublishDisabled = !versionName.trim().length || !versionDescription.trim().length || !extractionModel?.id;

  useEffect(() => {
    handleExtractionModelChange(selectedLlmModelId);
  }, [selectedLlmModelId]);

  useEffect(() => {
    if (isPublishExtractionProjectModalVisible && idpProject?.description) {
      setVersionDescription(idpProject.description);
    }
  }, [isPublishExtractionProjectModalVisible, idpProject?.description]);

  const handleExtractionModelChange = (selectedLlmModelId) => {
    const selectedExtractionModel = EXTRACTION_MODELS.find((model) => model.id === selectedLlmModelId);
    if (selectedExtractionModel) {
      setExtractionModel(selectedExtractionModel);
      setTestCaseResults(idpProjectStore.getModelTestCaseResults(selectedLlmModelId));
    }
  };

  const closeModal = () => {
    setVersionName('');
    setVersionDescription('');
    onCloseModal();
  };

  const performRequest = async () => {
    if (isPublishDisabled) {
      return;
    }

    await idpProjectStore.publishIdpExtractionProject(
      idpProject.id,
      versionName,
      versionDescription,
      extractionModel.id,
      isPublishingOnOrganizationLevel
    );
    closeModal();
  };

  return (
    isPublishExtractionProjectModalVisible && (
      <Styled.PublishModal
        open={isPublishExtractionProjectModalVisible}
        modalHeading="Publish Extraction Project"
        primaryButtonText="Publish"
        primaryButtonDisabled={isPublishDisabled}
        secondaryButtonText="Cancel"
        onRequestSubmit={performRequest}
        onRequestClose={closeModal}
        preventCloseOnClickOutside
      >
        <Styled.PublishModalContent>
          <span>
            {`Publish '${idpProject?.name}' to your ${isPublishingOnOrganizationLevel ? 'organization' : 'project'} to allow members to use
            it in their diagrams.`}
          </span>
          <TextInput
            data-modal-primary-focus
            id="version-name"
            autoComplete="off"
            labelText="Version name"
            placeholder="1.0"
            value={versionName}
            maxCount={255}
            onChange={(event) => setVersionName(event.target.value)}
          />
          <TextArea
            enableCounter
            id="version-description"
            labelText="Version description"
            placeholder={extractionModel?.name ?? ''}
            value={versionDescription}
            maxCount={255}
            onChange={(event) => setVersionDescription(event.target.value)}
          />
          <Dropdown
            id="extraction-model"
            titleText="Extraction model"
            label="Select extraction model"
            aria-label="Extraction model"
            items={EXTRACTION_MODELS}
            selectedItem={extractionModel}
            itemToString={(model) => (model ? model.name : '')}
            onChange={({ selectedItem }) => handleExtractionModelChange(selectedItem.id)}
          />
          {!!testCaseResults?.length && <TestCaseSummary testCaseResults={testCaseResults} />}
        </Styled.PublishModalContent>
      </Styled.PublishModal>
    )
  );
}

export default observer(IdpExtractionProjectPublishModal);
