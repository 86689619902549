/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Launch } from '@carbon/icons-react';

import * as Styled from './WithExternalLinkIcon.styled';

export default function WithExternalLinkIcon({ label, iconColor, className }) {
  return (
    <Styled.Wrapper $iconColor={iconColor} className={className}>
      <Styled.Label>{label}</Styled.Label>
      <Launch size="16" className="externalLinkIcon" />
    </Styled.Wrapper>
  );
}
