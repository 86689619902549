/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { schemaVersion } from '@bpmn-io/form-js';

import { DEFAULT_ZEEBE_VERSION_FORMS, EXPORTER } from 'utils/constants';
import { versionShort } from 'utils/version';
import generateId from 'utils/generate-id';

export default ({ formId }) => `{
  "executionPlatform": "Camunda Cloud",
  "executionPlatformVersion": "${DEFAULT_ZEEBE_VERSION_FORMS}",
  "exporter": {
    "name": "${EXPORTER}",
    "version": "${versionShort}"
  },
  "schemaVersion": ${schemaVersion},
  "components": [],
  "type": "default",
  "id": "${formId || generateId({ prefix: 'Form_' })}"
}
`;
