/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import reviewService from 'App/Pages/ProcessApplicationVersions/features/review/ReviewService';
import { notificationStore } from 'components/NotificationSystem';
import { tracingService } from 'services';

/**
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplicationReviewRequestDto} ProcessApplicationReviewRequestDto
 * @typedef {import('services/ProcessApplicationService.common').ProcessApplicationReviewDto} ProcessApplicationReviewDto
 */

class ReviewStore {
  /**
   * @type {Record<string, ProcessApplicationReviewDto> | null}
   */
  reviews = null;
  /**
   * @type {Record<string,ProcessApplicationReviewRequestDto> | null}
   */
  reviewRequests = null;
  // todo @yT0n1: in slice2, we will allow multiple concurrent reviews in the future, but this requires backend and ui changes

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Requests a review for a given milestone.
   * @param {string} versionId - The ID of the milestone.
   * @returns {Promise<void>}
   */
  async requestReview(versionId) {
    try {
      const reviewRequest = await reviewService.requestReview(versionId);
      this.addReviewRequest(versionId, reviewRequest);
    } catch (e) {
      //@ts-expect-error TS2339
      tracingService.traceError(e);
      notificationStore.error({
        title: 'Something went wrong',
        content: 'Failed to request review.'
      });
    }
  }

  /**
   * Checks if a review has been completed for a given milestone.
   * @param {string} versionId - The ID of the milestone.
   * @returns {boolean} - True if the review has been completed, false otherwise.
   */
  hasReviewBeenCompleted(versionId) {
    return Boolean(this.reviews?.[versionId]);
  }

  /**
   * Adds a review for a given milestone.
   * @param {string} versionId - The ID of the milestone.
   * @param {import('services/ProcessApplicationService.common').ProcessApplicationReviewDto} review
   */
  addReview(versionId, review) {
    delete this.reviewRequests[versionId];
    this.reviews[versionId] = review;
  }
  /**
   * Adds a review for a given milestone.
   * @param {string} versionId - The ID of the milestone.
   * @param {import('services/ProcessApplicationService.common').ProcessApplicationReviewRequestDto} reviewRequest
   */
  addReviewRequest(versionId, reviewRequest) {
    this.reviewRequests = { [versionId]: reviewRequest };
  }

  /**
   * Checks if there is another open review for the process application.
   * @returns {boolean} - True if there is another open review, false otherwise.
   */
  get hasOpenReviewForProcessApplication() {
    return this.openReviewForProcessApplication !== null;
  }

  /**
   * Gets the open review for the process application.
   * @returns - The open review object if found, otherwise null.
   */
  get openReviewForProcessApplication() {
    const isReviewRequested = this.reviewRequests && Object.keys(this.reviewRequests).length > 0;
    if (isReviewRequested) {
      return this.reviewRequests[0];
    } else {
      return null;
    }
  }

  /**
   * Initializes the review store
   * @param {ProcessApplicationReviewDto[]} reviews
   * @param {ProcessApplicationReviewRequestDto[]} reviewRequests
   */
  init(reviews, reviewRequests) {
    this.reset();
    this.reviews = Object.fromEntries(reviews.map((review) => [review.processApplicationVersionId, review]));
    this.reviewRequests = Object.fromEntries(
      reviewRequests
        .filter(({ processApplicationVersionId }) => !this.reviews[processApplicationVersionId])
        .map((reviewRequest) => [reviewRequest.processApplicationVersionId, reviewRequest])
    );
  }

  /**
   * Resets the review store.
   */
  reset() {
    this.reviews = null;
    this.reviewRequests = null;
  }

  /**
   * Approves a review for a given milestone with an optional comment.
   * @param {string} versionId - The ID of the milestone.
   * @param {string} comment - The comment for the review.
   * @returns {Promise<void>}
   */
  async approve(versionId, comment) {
    try {
      const response = await reviewService.approve(versionId, comment || null);
      this.addReview(versionId, response);
      notificationStore.info(
        {
          title: 'Version approved',
          content: 'Want to know how to share a resource with your organization?'
        },
        {
          duration: 8000,
          action: {
            // @ts-expect-error TS2353
            label: 'Learn more',
            onClick: () => {
              window.open(
                'https://docs.camunda.io/docs/guides/use-shared-project-for-organization-wide-collaboration',
                '_blank'
              );
            }
          }
        }
      );
    } catch (e) {
      //@ts-expect-error TS2339
      tracingService.traceError(e);
      notificationStore.error({
        title: 'Something went wrong',
        content: 'Failed to approve the version'
      });
    }
  }

  /**
   * Requests changes for a review for a given milestone with an optional comment.
   * @param {string} versionId - The ID of the milestone.
   * @param {string} comment - The comment for the review.
   * @returns {Promise<void>}
   */
  async requestChanges(versionId, comment) {
    try {
      const response = await reviewService.requestChanges(versionId, comment || null);
      this.addReview(versionId, response);
    } catch (e) {
      //@ts-expect-error TS2339
      tracingService.traceError(e);
      notificationStore.error({
        title: 'Something went wrong',
        content: 'Failed to request changes for the version'
      });
    }
  }

  /**
   * @param {string} versionId
   * @returns {ProcessApplicationReviewDto | undefined}
   */
  getReviewFor(versionId) {
    return this.reviews?.[versionId];
  }

  /**
   * @param {string} versionId
   * @returns {ProcessApplicationReviewRequestDto | undefined}
   */
  getReviewRequestFor(versionId) {
    return this.reviewRequests?.[versionId];
  }
}

export default new ReviewStore();
