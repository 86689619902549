/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { Enterprise, Folders } from '@carbon/icons-react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Dropdown } from 'components';
import { Button, Tooltip } from 'primitives';
import { organizationStore, processApplicationStore } from 'stores';
import { openVersionCreationModal, VersionCreationModal } from 'features/create-process-app-version';
import * as Styled from 'App/Pages/ConnectorTemplate/ActionBar.styled';

import { PublishToOrganizationModal } from './PublishToOrganizationModal';
import { useLastSavedVersion } from './use-last-saved-version';

function PublishConnector({ connectorTemplate, isDisabled }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [orgPublishDialogOpen, setOrgPublishDialogOpen] = useState(false);
  const { lastSavedVersion, reloadLastSavedVersion } = useLastSavedVersion(connectorTemplate);

  const isPublishedToProject = !!lastSavedVersion;
  const isPublishToOrganizationEnabled = !isDisabled && isPublishedToProject && !lastSavedVersion.organizationPublic;

  return (
    <>
      {organizationStore.hasElevatedOrganizationPermissions ? (
        <>
          {/* @ts-expect-error TS2322 */}
          <Button
            dropdown
            aria-haspopup="true"
            onClick={(evt) => setAnchorEl(evt?.currentTarget)}
            disabled={isDisabled}
          >
            Publish
          </Button>
          <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {/* @ts-expect-error TS2739 */}
            <Dropdown.ListItem
              onClick={() => {
                setAnchorEl(null);
                openVersionCreationModal();
              }}
              disabled={isDisabled}
            >
              <Styled.PublishOption>
                <Folders />
                Publish to project
              </Styled.PublishOption>
            </Dropdown.ListItem>
            {/* @ts-expect-error TS2739 */}
            <Dropdown.ListItem
              onClick={() => {
                if (!isPublishToOrganizationEnabled) {
                  return;
                }
                setAnchorEl(null);
                setOrgPublishDialogOpen(true);
              }}
              $isDisabled={!isPublishToOrganizationEnabled}
            >
              <Styled.PublishOption>
                <Enterprise />
                <Tooltip
                  title={getTooltipText(isPublishedToProject, lastSavedVersion?.organizationPublic)}
                  disabled={isPublishToOrganizationEnabled}
                >
                  <TooltipText $isDisabled={!isPublishToOrganizationEnabled}>Publish to organization</TooltipText>
                </Tooltip>
              </Styled.PublishOption>
            </Dropdown.ListItem>
          </Dropdown>
        </>
      ) : (
        // @ts-expect-error TS2322
        <Button onClick={openVersionCreationModal} disabled={isDisabled}>
          Publish
        </Button>
      )}

      {connectorTemplate && (
        <VersionCreationModal
          processApplicationId={processApplicationStore.processApplication.id}
          isConnectorTemplatePublish
          connectorTemplateName={connectorTemplate.name}
          onVersionCreated={reloadLastSavedVersion}
        />
      )}
      {lastSavedVersion && (
        <PublishToOrganizationModal
          milestone={lastSavedVersion}
          onClose={() => setOrgPublishDialogOpen(false)}
          open={orgPublishDialogOpen}
          template={connectorTemplate}
          versionName={lastSavedVersion?.name}
          onPublication={reloadLastSavedVersion}
        />
      )}
    </>
  );
}

export default observer(PublishConnector);

/**
 * @type {import('styled-components').IStyledComponent<'web', {
 *     $isDisabled: Boolean;
 * } & React.HTMLAttributes<HTMLDivElement>>}
 */
const TooltipText = styled.span`
  pointer-events: all;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
`;

const getTooltipText = (isPublishedToProject, isOrganizationPublic) => {
  if (!isPublishedToProject) {
    return 'Publish to project first';
  } else if (isOrganizationPublic) {
    return 'The latest version is already published to the organization';
  }
  return '';
};
