/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { publicationStore } from 'App/Pages/Diagram/stores';
import { isPublicationSupportedByElement } from 'App/Pages/Diagram/FormLinking';

const togglePublicationTab = (element) => {
  if (!element) {
    publicationStore.setStartEventElement(null);
    publicationStore.setParentProcessId(null);
    return;
  }

  if (isPublicationSupportedByElement(element)) {
    publicationStore.setStartEventElement(element);
    publicationStore.setParentProcessId(element?.parent?.id || null);
  } else {
    publicationStore.removePublicAccessFromElement(element);
    publicationStore.setStartEventElement(null);
    publicationStore.setParentProcessId(null);
  }
};

export default function ProcessPublicationTab(eventBus) {
  eventBus.on('canvas.destroy', () => {
    publicationStore.reset();
  });

  eventBus.on('selection.changed', (event) => {
    togglePublicationTab(event.newSelection[0]);
  });

  eventBus.on('commandStack.propertiesPanel.zeebe.changeTemplate.postExecute', (event) => {
    togglePublicationTab(event.context?.element);
  });
}

ProcessPublicationTab.$inject = ['eventBus'];
