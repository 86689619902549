/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import businessRuleTaskLinkStore from './BusinessRuleTaskLinkStore';
import BusinessRuleTaskLinkOverlay from './BusinessRuleTaskLinkOverlay';

const BusinessRuleTaskLinking = ({ isLoadingModeler, permission }) => {
  const { selectedBusinessRuleTask, isElementLinked } = businessRuleTaskLinkStore;

  let showOverlay = !isLoadingModeler && selectedBusinessRuleTask;
  if (permission.is(['COMMENT', 'READ'])) {
    if (!isElementLinked(selectedBusinessRuleTask)) {
      showOverlay = false;
    }
  }

  return (
    <>
      {showOverlay && (
        <BusinessRuleTaskLinkOverlay element={selectedBusinessRuleTask} readOnly={permission.is(['COMMENT', 'READ'])} />
      )}
    </>
  );
};

export default observer(BusinessRuleTaskLinking);
