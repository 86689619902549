/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

/**
 * Custom hook to ensure cleanup effects from the previous component
 * complete before rendering the new component.
 *
 * ## Why is this needed?
 * In React, when navigating between components (e.g., using React Router),
 * the cleanup function of the previous component runs **after** the new
 * component’s function body executes. This means:
 *
 * 1. The new component may start rendering **before** the cleanup of the
 *    previous component completes.
 * 2. This can cause unexpected behavior if the previous component's cleanup
 *    involves critical state updates, e.g. reset of the stores.
 *
 * See https://github.com/camunda/web-modeler/pull/13273
 */
export function useWaitForCleanupsToComplete() {
  const [isCleanupsComplete, setIsCleanupsComplete] = useState(false);

  useEffect(() => {
    setIsCleanupsComplete(true);
  }, []);

  return { isCleanupsComplete };
}
