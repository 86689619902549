/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { organizationStore } from 'stores';
import config from 'utils/config';
import { defineStagesModalStore } from 'App/Pages/Diagram/Deployment/defineStagesModal';
import { deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import { modes } from 'App/Pages/Diagram/features/deployment/utils';

import { ActionableNotification } from './PlayNotification.styled';

function getNotificationDetails({ shouldOpenSettings, isEnterprise, onClose }) {
  if (shouldOpenSettings) {
    return {
      subtitle: 'Change the selected development cluster via the stage settings.',
      actionButtonLabel: 'Open settings',
      onActionButtonClick: () => {
        onClose();
        defineStagesModalStore.open({ executeModalMode: modes.PLAY });
      }
    };
  }

  if (isEnterprise) {
    return {
      subtitle:
        'To avoid additional costs, use a development cluster or ensure your organization is designated as a development organization.',
      actionButtonLabel: 'Learn more',
      onActionButtonClick: () =>
        window.open(
          `https://docs.camunda.io/docs/components/modeler/web-modeler/play-your-process/#play-usage-and-billing-considerations`
        )
    };
  }

  return {
    subtitle: 'Select a development cluster or create another development cluster in Console.',
    actionButtonLabel: 'Open console',
    onActionButtonClick: () =>
      window.open(
        `https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/clusters?create=true`
      )
  };
}

function PlayBillingNotification({ onClose, shouldOpenSettings, hasTopMargin }) {
  if (
    organizationStore.isUsingTrial ||
    organizationStore.isInternal ||
    deploymentStore.hasSelectedClusterDevelopmentPlanType ||
    organizationStore.isEnterpriseDevOrganization
  ) {
    return;
  }

  const { subtitle, actionButtonLabel, onActionButtonClick } = getNotificationDetails({
    shouldOpenSettings,
    isEnterprise: organizationStore.isEnterprise,
    onClose
  });

  return (
    <ActionableNotification
      inline
      title="Selected cluster will affect your billing"
      subtitle={subtitle}
      kind="warning"
      lowContrast
      hideCloseButton
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={onActionButtonClick}
      $hasTopMargin={hasTopMargin}
    />
  );
}

export default observer(PlayBillingNotification);
