/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { MenuButton, MenuItem } from '@carbon/react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { processApplicationStore } from 'stores';
import { VersionPublishDialog } from 'components';
import { openVersionCreationModal, VersionCreationModal } from 'features/create-process-app-version';
import hasAccess, { actions } from 'utils/user-access';

export const VersionsMenu = observer(function ({ project, file, isPrimary, navigateToVersionsPage }) {
  const [isVersionPublishDialogOpen, setIsVersionPublishDialogOpen] = useState(false);

  const openModalForVersionCreation = () => {
    if (processApplicationStore.fromAProcessApplication) {
      openVersionCreationModal();
    } else {
      setIsVersionPublishDialogOpen(true);
    }
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    const handleKeyPress = (evt) => {
      if (evt.shiftKey && evt.key == 'M' && evt.target.tagName === 'BODY') {
        evt.preventDefault();
        if (hasAccess(project, actions.VIEW_VERSIONS)) {
          openModalForVersionCreation();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [project]);

  if (!file || !project) {
    return;
  }

  return (
    hasAccess(project, actions.VIEW_VERSIONS) && (
      <>
        <_MenuButton label="Versions" menuAlignment="bottom-end" size="sm" kind={`${isPrimary ? 'primary' : 'ghost'}`}>
          <MenuItem label="Create version" onClick={openModalForVersionCreation} />
          <MenuItem label="Show versions" onClick={navigateToVersionsPage} />
        </_MenuButton>

        {processApplicationStore.fromAProcessApplication ? (
          <VersionCreationModal
            processApplicationId={processApplicationStore.processApplication?.id}
            isShowLinkToVersionsPage
          />
        ) : (
          // @ts-expect-error TS2741
          <VersionPublishDialog
            open={isVersionPublishDialogOpen}
            onClose={() => setIsVersionPublishDialogOpen(false)}
            file={file}
            origin="breadcrumb"
          />
        )}
      </>
    )
  );
});

const _MenuButton = styled(MenuButton)`
  button {
    min-inline-size: unset !important;
    min-block-size: 30px;
    height: 30px;
    width: 111px;
    padding-top: 5px;

    svg {
      margin-top: 2px !important;
    }
  }
`;
