/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { createRoot } from 'react-dom/client';

import BpmnCopilotEmptyStateContent from 'experiments/ai-features/bpmn-copilot-empty-state/BpmnCopilotEmptyStateContent';

const type = 'bpmn-copilot-empty-state';

export default class BpmnCopilotEmptyState {
  constructor(elementRegistry, eventBus, overlays) {
    eventBus.once('import.done', () => {
      const startEvents = elementRegistry.filter((element) => is(element, 'bpmn:StartEvent'));
      if (startEvents?.length !== 1) {
        return;
      }

      const startEvent = startEvents[0];

      const html = document.createElement('div');

      html.classList.add('bpmn-copilot-empty-state');

      createRoot(html).render(<BpmnCopilotEmptyStateContent />);

      overlays.add(startEvent, type, {
        html,
        position: {
          bottom: -20,
          left: -10
        }
      });
    });

    eventBus.once('commandStack.changed', () => {
      overlays.remove({ type });
    });
  }
}

BpmnCopilotEmptyState.$inject = ['elementRegistry', 'eventBus', 'overlays'];
