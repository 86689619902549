/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import {
  isAnyInboundConnector,
  getSelectedInboundFunctionality,
  getSelectedInboundShareableEntity
} from 'utils/connectors/inbound-connectors';

/**
 * SaaS - the values will be set for SaaS only
 *
 * If the selected element has an inbound connector configured,
 * we get the inbound connector type & the process ID of the parent.
 *
 * Otherwise, the values are null.
 */
class InboundConnectorStore {
  inboundConnectorType = null;
  inboundConnectorId = null;
  selectedElementId = null;
  parentProcessId = null;
  recentlyDeployed = false;
  needsRedeploymentElementIds = new Set();
  lastValidInboundConnectorType = null;
  elementsProperties = new Map();
  initialStatusDataLoadingInProgress = true;
  statusData = [];
  logs = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.inboundConnectorType = null;
    this.lastValidInboundConnectorType = null;
    this.inboundConnectorId = null;
    this.parentProcessId = null;
    this.selectedElementId = null;
    this.elementsProperties = new Map();
    this.initialStatusDataLoadingInProgress = true;
    this.statusData = [];
    this.logs = [];
  }

  setInitialStatusDataLoadingInProgress(initialStatusDataLoadingInProgress) {
    this.initialStatusDataLoadingInProgress = initialStatusDataLoadingInProgress;
  }

  setStatusData(statusData) {
    this.statusData = statusData;
  }

  setLogs(logs) {
    this.logs = logs;
  }

  setInboundConnectorType(type) {
    this.inboundConnectorType = type;

    if (type) {
      this.lastValidInboundConnectorType = type;
    }
  }

  setInboundConnectorId(id) {
    this.inboundConnectorId = id;
  }

  setParentProcessId(id) {
    this.parentProcessId = id;
  }

  setSelectedElementId(id) {
    this.selectedElementId = id;
  }

  setRecentlyDeployed() {
    this.recentlyDeployed = true;
    this.needsRedeploymentElementIds = new Set();
    this.elementsProperties = new Map();

    setTimeout(() => {
      this.recentlyDeployed = false;
    }, 15000);
  }

  markAsDirty(elementId) {
    this.needsRedeploymentElementIds.add(elementId);
  }

  addElementProperties(elementId, elementProperties) {
    this.elementsProperties.set(elementId, elementProperties);
  }

  get isSelectedElementInboundConnector() {
    return isAnyInboundConnector(this.inboundConnectorType);
  }

  get selectedInboundFunctionality() {
    return getSelectedInboundFunctionality(this.inboundConnectorType);
  }

  get selectedInboundShareableEntity() {
    return getSelectedInboundShareableEntity(this.inboundConnectorType);
  }
}

export default new InboundConnectorStore();
