/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { getExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

export default function getWebhookId(element) {
  const bo = getBusinessObject(element);
  const extensionElement = getExtensionElement(bo, 'zeebe:Properties');
  return extensionElement?.properties?.find((el) => el.name === 'inbound.context');
}
