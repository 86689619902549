/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Container = styled.div`
  height: 35px;
  border-bottom: 1px solid var(--silver-darken-87);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled.div`
  flex: 1;
  margin-left: 15px;
`;

export const FilesList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
`;

export const SelectedFile = styled.span`
  margin: 0 10px;
`;
