/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

class UserService extends Service {
  /**
   * Fetches the modeler user object.
   *
   * @returns {Promise}
   */
  fetchMyself() {
    return this.get(`/internal-api/self`);
  }

  /**
   * @param {string} audience
   * @return {Promise<string[]>}
   */
  async getUserPermissions(audience) {
    return await this.get(`/identity/permissions/for-token?audience=${audience}`);
  }

  /**
   * Inserts or updates a modeler user.
   *
   * @returns {Promise}
   */
  upsertUser(data) {
    return this.post('/internal-api/login', data);
  }
}

export default new UserService();
