/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

class CreateNewElementTemplateFileUploadCommon {
  isFileSizeValid(fileData, setFileData, fileSizeLimit) {
    // file size validation
    if (fileData.fileSize > fileSizeLimit) {
      setFileData(
        this.getInvalidFileData(
          fileData,
          'File size exceeds limit',
          `${fileSizeLimit / 1000 - 1}kb max file size. Select a new file and try again.`
        )
      );
      return false;
    }
    return true;
  }

  isFileTypeValid(fileData, setFileData) {
    // file type validation
    if (fileData.invalidFileType) {
      setFileData(
        this.getInvalidFileData(fileData, 'Invalid file type', `"${fileData.name}" does not have a valid file type.`)
      );
      return false;
    }
    return true;
  }

  setFileContentAndShowWithCompleteStatus(fileData, setFileData, fileContent) {
    fileData.fileContent = fileContent;
    // set status to complete
    const updatedFile = {
      ...fileData,
      status: 'complete',
      iconDescription: 'Upload complete'
    };
    setFileData(updatedFile);
  }

  showFileContentWithEditStatus(fileData, setFileData) {
    // show x icon after 1 second
    setTimeout(() => {
      const updatedFile = {
        ...fileData,
        status: 'edit',
        iconDescription: 'Delete file'
      };
      setFileData(updatedFile);
    }, 1000);
  }

  getInvalidFileData(fileData, errorSubject, errorBody) {
    return {
      ...fileData,
      status: 'edit',
      iconDescription: 'Delete file',
      invalid: true,
      errorSubject,
      errorBody
    };
  }

  setInvalidVersionFileData(fileData, setFileData, errorMessage) {
    setFileData(this.getInvalidFileData(fileData, 'Validation error', errorMessage));
  }
}

export default new CreateNewElementTemplateFileUploadCommon();
