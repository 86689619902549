/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { currentDiagramStore } from 'stores';
import { trackingService } from 'services';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';

export default function DocumentationTrackingExtension(eventBus) {
  let isFirstUpdate = true;

  eventBus.on('commandStack.element.updateModdleProperties.preExecute', (event) => {
    if (dedicatedModesStore.isDesignMode && isFirstUpdate) {
      const properties = event?.context?.properties;
      const isDocumentationUpdated = 'text' in properties;

      if (isDocumentationUpdated) {
        trackingService.trackDocumentationUpdate(currentDiagramStore.state.diagram.id);

        isFirstUpdate = false;
      }
    }
  });
}

DocumentationTrackingExtension.$inject = ['eventBus'];
