/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * @typedef {import('../types').FileSelection} FileSelection
 */

import { CodeEditor } from 'components';
import { BPMN, CONNECTOR_TEMPLATE, DMN, FORM } from 'utils/constants';

/**
 * @param {Object} props
 * @param {FileSelection|null} props.fileSelection
 * @param {Boolean} props.isDiffingEnabled
 */
export default function CodeViewer({ fileSelection, isDiffingEnabled }) {
  const currentContent = fileSelection?.current?.content;
  const previousContent = fileSelection?.previous?.content;

  const currentVersionName = fileSelection?.current?.versionName;
  const previousVersionName = fileSelection?.previous?.versionName;

  const isShowSideBySideDiffViewer = isDiffingEnabled && previousContent;

  return (
    /* @ts-expect-error TS2741 */
    <CodeEditor
      titles={[isShowSideBySideDiffViewer ? previousVersionName : '', currentVersionName]}
      value={isShowSideBySideDiffViewer ? previousContent : currentContent}
      modified={isShowSideBySideDiffViewer ? currentContent : null}
      language={getLanguage(fileSelection?.fileType)}
    />
  );
}

const getLanguage = (fileType) => {
  if (fileType === BPMN || fileType === DMN) {
    return 'xml';
  } else if (fileType === FORM || fileType === CONNECTOR_TEMPLATE) {
    return 'json';
  }
  return 'xml';
};
