/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tab, TabList, TabPanels, Tabs } from '@carbon/react';
import { observer } from 'mobx-react';

import { ProjectsTable, SharedResources } from 'components';

import * as Styled from './HomeContentTabs.styled';

const HomeContent = (projectsProps) => {
  return (
    <Styled.TabsContainer>
      <Tabs>
        <TabList aria-label="Projects and shared resources tabs" data-test="entity-type-tabs">
          <Tab data-test="projects-tab">Projects</Tab>
          <Tab data-test="shared-resources-tab">Shared resources</Tab>
        </TabList>
        <TabPanels>
          <Styled.TabPanel data-test="projects-tab-panel">
            <Styled.TabContentContainer data-test="organization-projects-container">
              <ProjectsTable {...projectsProps} excludeTitle noTablePadding />
            </Styled.TabContentContainer>
          </Styled.TabPanel>
          <Styled.TabPanel>
            <Styled.TabContentContainer data-test="shared-resources-container">
              <SharedResources />
            </Styled.TabContentContainer>
          </Styled.TabPanel>
        </TabPanels>
      </Tabs>
    </Styled.TabsContainer>
  );
};

export default observer(HomeContent);
