/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * Pluralizes a given string based on basic english language
 * agnostics. Pluralization only happens if the second paramter
 * is 0 or greater than 1.
 *
 * @param {String} text The text to pluralize.
 * @param {Number} count The multiplier. If 1, the returned string
 *  is still in singular. Otherwise it's in plural.
 * @returns {String}
 */
export default (text, count = 0) => {
  if (count === 1) {
    return text;
  }

  if (text === 'this') {
    return 'these';
  }
  if (text === 'that') {
    return 'those';
  }
  if (text === 'its') {
    return 'their';
  }
  if (text === 'has') {
    return 'have';
  }
  if (text === 'is') {
    return 'are';
  }
  if (text === 'day') {
    return 'days';
  }

  switch (text.charAt(text.length - 1)) {
    case 'y':
      return text.slice(0, text.length - 1) + 'ies';
    case 's':
      if (text.charAt(text.length - 2) === 'u') {
        return text.slice(0, text.length - 2) + 'i';
      }
      return text + 'es';
    default:
      return text + 's';
  }
};
