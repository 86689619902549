/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { DefinitionTooltip, Link } from '@carbon/react';
import styled from 'styled-components';

import { Sidebar } from 'primitives';

export function VersionsTitle() {
  return (
    // @ts-expect-error TS2339
    <Sidebar.Title>
      <DefinitionTooltip
        definition={
          <span role="tooltip">
            View the selected draft or version in read-only mode. Restore, deploy, or download a version, or compare it
            with another version or draft.{' '}
            <TootltipLink
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#process-application-versioning"
              target="_blank"
            >
              Learn more
            </TootltipLink>
          </span>
        }
        openOnHover
        align="bottom-start"
      >
        Versions
      </DefinitionTooltip>
      {/* @ts-expect-error TS2339 */}
    </Sidebar.Title>
  );
}

const TootltipLink = styled(Link)`
  color: var(--tooltip-link) !important;
`;
