/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'primitives';

import * as Styled from './Button.styled';

const Button = forwardRef(
  (
    {
      component = 'button',
      children,
      dropdown = false,
      disabled = false,
      loading = false,
      lightLoader = false,
      loaderSize = 'normal',
      variant = 'primary',
      size = 'normal',
      ...props
    },
    ref
  ) => (
    <Styled.Wrapper
      as={component}
      ref={ref}
      $isDropdown={dropdown}
      disabled={loading || disabled}
      $variant={variant}
      size={size}
      {...props}
    >
      {loading ? (
        <>
          <Loader className="loader" $light={lightLoader} size={loaderSize} />
          <Styled.Content className="is-loading">{children}</Styled.Content>
        </>
      ) : (
        children
      )}
    </Styled.Wrapper>
  )
);

Button.propTypes = {
  dropdown: PropTypes.bool,
  component: PropTypes.any,
  variant: PropTypes.oneOf(['primary', 'danger-primary', 'secondary', 'text']),
  size: PropTypes.oneOf(['small', 'normal']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  lightLoader: PropTypes.bool,
  loaderSize: PropTypes.oneOf(['small', 'normal'])
};

export default Button;
