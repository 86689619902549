/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed, makeObservable } from 'mobx';

import { ArrowForward } from 'icons';
import { commentsStore } from 'stores';

import * as Styled from './CommentForm.styled';
import SuggestionsInput from './SuggestionsInput';

class CommentForm extends Component {
  value = '';

  constructor(props) {
    super(props);

    makeObservable(this, {
      value: observable,
      handleChange: action,
      handleSubmit: action,
      isCommentValid: computed
    });
  }

  handleChange = (evt) => {
    this.value = evt.target.value;
  };

  handleSubmit = (evt) => {
    evt.preventDefault();

    if (this.isCommentValid) {
      commentsStore.createComment(this.value);

      this.value = '';
    }
  };

  get isCommentValid() {
    return this.value.replace(/(?:\r\n|\r|\n)/g, '').trim().length > 0;
  }

  render() {
    return (
      <Styled.NewCommentForm onSubmit={this.handleSubmit}>
        <SuggestionsInput
          value={this.value}
          autofocus={this.props.autofocus}
          onChange={this.handleChange}
          onEnter={this.handleSubmit}
          data-test="comment-input"
        />

        <Styled.NewCommentFormButton
          disabled={!this.isCommentValid}
          variant="primary"
          type="submit"
          aria-label="Send reply"
          data-test="comment-submit"
        >
          <ArrowForward width="24" height="24" />
        </Styled.NewCommentFormButton>
      </Styled.NewCommentForm>
    );
  }
}

export default observer(CommentForm);
