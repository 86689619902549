/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { AiSparkleSmall, ContextPadPlus } from 'icons';

export const Container = styled.div`
  width: 275px;
  color: var(--cds-text-primary);
`;

export const Plus = styled(ContextPadPlus)`
  vertical-align: -7%;
`;

export const AiButton = styled(AiSparkleSmall)`
  vertical-align: -2%;
`;
