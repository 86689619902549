/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Copilot from '@camunda/bpmn-copilot-client';

import bpmnCopilotService from 'experiments/ai-features/bpmn-copilot/BpmnCopilotService';
import { tracingService } from 'services';

const callServiceAndParseResponse = async (requestBody, signal) => {
  let jsonResponse;
  try {
    jsonResponse = await bpmnCopilotService.invokeBpmnCopilot(requestBody, signal);
  } catch (error) {
    if (!signal.aborted) {
      const message = 'Call to BPMN Copilot service failed';
      console.error(message);
      tracingService.traceError(error, message);
    }
    return Promise.reject(error);
  }

  let response;

  try {
    response = new Response(JSON.stringify(jsonResponse));
  } catch (error) {
    let message = 'Parsing BPMN Copilot response failed';
    console.error(message);
    tracingService.traceError(error, message);
    return Promise.reject(error);
  }

  return response;
};

class BpmnCopilotClient {
  invoke({ modeler, createNewVersion, textPrompt, route = undefined, preventModelerEdit }) {
    const invokeFunction = this.#createInvokeFunction(preventModelerEdit, createNewVersion);

    const copilot = new Copilot({
      bpmnjs: modeler,
      invoke: invokeFunction,
      preventBpmnJsEdit: preventModelerEdit
    });

    return copilot.invoke(textPrompt, route);
  }

  #createInvokeFunction(preventModelerEdit, createNewVersion) {
    return async (requestBody, signal) => {
      let response;

      try {
        response = callServiceAndParseResponse(requestBody, signal);
      } catch (e) {
        return Promise.reject(e);
      }

      if (!preventModelerEdit) {
        try {
          await createNewVersion();
        } catch (error) {
          let message = 'Creating new version after BPMN Copilot failed';
          console.error(message);
          tracingService.traceError(error, message);
          return Promise.reject(error);
        }
      }

      return response;
    };
  }

  getRoute({ modeler, textPrompt }) {
    const copilot = new Copilot({
      bpmnjs: modeler,
      invoke: callServiceAndParseResponse,
      preventBpmnJsEdit: true
    });

    return copilot.invoke(textPrompt, 'call_routing');
  }
}

export const bpmnCopilotClient = new BpmnCopilotClient();

export const wasRouteRequestCancelled = (route) => {
  return route === null;
};

export const wouldRouteModifyDiagram = (route) => {
  return route !== undefined && route === 'call_create';
};
