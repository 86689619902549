/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect, useCallback } from 'react';

import { settingsService, tracingService } from 'services';
import { notificationStore } from 'components/NotificationSystem';

/** @typedef {import('services/SettingsService').DeploymentPolicy["value"]} DeploymentPolicyValue */

/**
 * @param {string} organizationId
 * @returns {[boolean, boolean, () => Promise<void>]}
 */
export function useDeploymentPolicy(organizationId) {
  const [deploymentPolicy, setDeploymentPolicy] =
    /** @type {[DeploymentPolicyValue | null, Function]} */ useState(null);
  const isReviewRequired = deploymentPolicy === 'REVIEW_REQUIRED';
  const isLoading = deploymentPolicy === null;

  useEffect(() => {
    let ignoreResult = false;

    const fetchDeploymentPolicy = async () => {
      try {
        const settings = await settingsService.fetchDeploymentPolicy(organizationId);
        if (!ignoreResult) {
          setDeploymentPolicy(settings.value);
        }
      } catch (err) {
        tracingService.traceError(err, 'Failed to fetch deployment policy');
        if (!ignoreResult) {
          notificationStore.error({
            title: 'Something went wrong',
            content: 'Could not fetch settings'
          });
        }
      }
    };

    fetchDeploymentPolicy();
    return () => {
      ignoreResult = true;
    };
  }, [organizationId]);

  const toggleDeploymentPolicy = useCallback(async () => {
    try {
      const newValue = deploymentPolicy === 'ADMIN_ONLY' ? 'REVIEW_REQUIRED' : 'ADMIN_ONLY';
      setDeploymentPolicy(null);
      const response = await settingsService.updateDeploymentPolicy(organizationId, newValue);
      setDeploymentPolicy(response.value);
    } catch (err) {
      notificationStore.error({
        title: 'Something went wrong',
        content: 'Could not update settings'
      });
      tracingService.traceError(err, 'Failed to update deployment policy');
    }
  }, [deploymentPolicy, organizationId]);

  return [isLoading, isReviewRequired, toggleDeploymentPolicy];
}
