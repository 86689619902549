/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';

import * as Styled from './EmptyState.styled';

export default function EmptyState({ title, description, icon, action, link, ...props }) {
  const ariaLabelledBy = 'empty-state-' + kebabCase(title);

  return (
    <Styled.Wrapper data-test="empty-state" {...props}>
      {icon && <Styled.Icon>{icon}</Styled.Icon>}
      <Styled.Content aria-labelledby={ariaLabelledBy}>
        <Styled.Title id={ariaLabelledBy}>{title}</Styled.Title>
        {description && <Styled.Description>{description}</Styled.Description>}
        {action && <Styled.Action>{action}</Styled.Action>}
        {link && <Styled.Link>{link}</Styled.Link>}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  action: PropTypes.node,
  link: PropTypes.node
};
