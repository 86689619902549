/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef } from 'react';

import { DiagramControls } from 'components';
import { CamundaAIButton } from 'experiments/ai-features';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { SendFeedbackButton } from 'features/send-feedback';

import * as Styled from './CanvasBottomRight.styled';

export default function CanvasBottomRight({ permission, location }) {
  const feedbackButtonSlotRef = useRef();

  return (
    <Styled.CanvasBottomRight>
      <Styled.FeedbackButtonSlot ref={feedbackButtonSlotRef}>
        <SendFeedbackButton
          feedbackButtonSlotRef={feedbackButtonSlotRef}
          from={`diagram-${dedicatedModesStore.selectedModeLabel}`}
        />
      </Styled.FeedbackButtonSlot>
      <DiagramControls
        hasKeyboardShortcuts={permission.is(['WRITE', 'ADMIN'])}
        hasAttentionGrabber={permission.is(['WRITE', 'COMMENT', 'ADMIN'])}
        // @ts-expect-error TS2322
        SendFeedbackButton={SendFeedbackButton}
      />
      <CamundaAIButton location={location} />
    </Styled.CanvasBottomRight>
  );
}
