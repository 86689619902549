/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from 'components';
import { notificationStore } from 'components/NotificationSystem';
import { milestoneService } from 'services';

function RestoreListItem({ milestoneId, disabled, reloadVersions, closeVersionMenuDropdown }) {
  return (
    // @ts-expect-error TS2554
    <Dropdown.ListItem
      disabled={disabled}
      onClick={async () => {
        try {
          closeVersionMenuDropdown();
          await milestoneService.restore(milestoneId, {});
          notificationStore.showSuccess('IDP project version restored.');
          reloadVersions();
        } catch {
          notificationStore.showError('Failed to restore the IDP project version.');
        }
      }}
    >
      Restore as latest
    </Dropdown.ListItem>
  );
}

export default RestoreListItem;
