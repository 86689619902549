/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link } from '@carbon/react';
import styled from 'styled-components';

export const Clusters = styled.div`
  margin: var(--spacing-medium) 0;
  ${({ disabled }) => disabled && `opacity: 0.6;`}
`;

export const Cluster = styled.div`
  margin: var(--spacing-small) 0;
`;

export const Label = styled.label`
  & > :first-child {
    vertical-align: top;
    margin-top: 5px;
  }

  ${({ disabled }) => disabled && `cursor: not-allowed;`}

  cursor: pointer;
`;
export const ClusterInfo = styled.span`
  display: inline-block;
  width: 40%;
`;
export const ClusterName = styled.span`
  display: block;
`;

export const ClusterId = styled.span`
  display: block;
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
`;

export const TagContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-top: -5px;
  width: 100px;
`;

export const ClusterManageLink = styled(Link)`
  display: inline-block;
  margin-left: var(--spacing-small);
  vertical-align: top;
`;
