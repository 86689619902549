/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ChevronDown } from '@carbon/react/icons';

import { AISparkle } from 'icons';

import * as Styled from './CamundaAiSparkleButton.styled';

export default function CamundaAiSparkleButton({
  iconDescription,
  buttonRef = null,
  onClick,
  id = '',
  isOpen = false
}) {
  return (
    <Styled.CamundaAIButton
      hasIconOnly
      iconDescription={iconDescription}
      kind="secondary"
      tooltipAlignment="end"
      renderIcon={() => {
        return isOpen ? <ChevronDown /> : <AISparkle />;
      }}
      size="sm"
      ref={buttonRef}
      id={id}
      onClick={onClick}
    />
  );
}
