/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { isPlayMode } from 'App/Pages/Diagram/Deployment/executeModal/modeUtils';
import { processApplicationStore } from 'stores';

import * as Styled from './ClusterSelection.styled';
import StagesHeader from './Stages/StagesHeader';

export const ClusterHeader = ({ mode, disabled, isClusterBeingCreated, onClose }) => {
  const { fromAProcessApplication } = processApplicationStore;
  const target = fromAProcessApplication ? 'process application' : 'diagram';

  function headerContent() {
    if (disabled) {
      return (
        <>
          <Styled.SectionHeader>Your clusters need to be upgraded to support this {target}:</Styled.SectionHeader>
          <Styled.Description>
            These clusters use an engine version below Zeebe 8.0 and are not compatible with DMN.
          </Styled.Description>
        </>
      );
    }

    if (isClusterBeingCreated) {
      return (
        <>
          <Styled.SectionHeader>Your cluster is being created.</Styled.SectionHeader>
          <Styled.Description>
            It will take a few minutes to finish creating the cluster. You cannot deploy the {target} until the cluster
            is ready. You can close this modal and try to deploy again in a few minutes.
          </Styled.Description>
        </>
      );
    }

    if (!fromAProcessApplication) {
      // Note that a similar sentence is present, in the case of the PA, in the <StagesHeader /> component
      return (
        <>
          <Styled.SectionHeader>Select a cluster</Styled.SectionHeader>
          <Styled.Description>
            {isPlayMode(mode)
              ? 'To access Play, your diagram needs to be deployed in a development cluster.'
              : 'Your diagram will be deployed in cluster:'}
          </Styled.Description>
        </>
      );
    }
  }

  return (
    <>
      {fromAProcessApplication && <StagesHeader mode={mode} onClose={onClose} />}
      {headerContent()}
    </>
  );
};

export default observer(ClusterHeader);
