/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { trackingService } from 'services';
import { processApplicationStore } from 'stores';

class GitSyncTrackingEvents {
  static trackDialogOpen() {
    GitSyncTrackingEvents.#trackEvent({ operation: 'show' });
  }

  /**
   *
   * @param {('ours'|'theirs')} conflictResolution - The conflict resolution strategy used.
   */
  static trackPullStart(conflictResolution) {
    GitSyncTrackingEvents.#trackEvent({ operation: 'pull', conflictResolution, step: 'start' });
  }

  static trackPushStart() {
    GitSyncTrackingEvents.#trackEvent({ operation: 'push', step: 'start' });
  }

  /**
   *
   * @param {('ours'|'theirs')} conflictResolution - The conflict resolution strategy used.
   */
  static trackPullComplete(conflictResolution) {
    GitSyncTrackingEvents.#trackEvent({
      operation: 'pull',
      conflictResolution,
      step: 'complete',
      status: 'success'
    });
  }

  static trackPushComplete() {
    GitSyncTrackingEvents.#trackEvent({
      operation: 'push',
      step: 'complete',
      status: 'success'
    });
  }

  static trackPullSkip() {
    GitSyncTrackingEvents.#trackEvent({
      operation: 'pull',
      step: 'skip',
      status: 'success'
    });
  }

  /**
   *
   * @param {string} error - The error message.
   * @param {('ours'|'theirs')=} conflictResolution - The conflict resolution strategy used.
   */
  static trackPullError(error, conflictResolution) {
    GitSyncTrackingEvents.#trackEvent({
      operation: 'pull',
      conflictResolution,
      step: 'complete',
      status: 'fail',
      error
    });
  }

  /**
   *
   * @param {string} error - The error message.
   */
  static trackPushError(error) {
    GitSyncTrackingEvents.#trackEvent({
      operation: 'push',
      step: 'complete',
      status: 'fail',
      error
    });
  }

  static trackCancel() {
    GitSyncTrackingEvents.#trackEvent({ operation: 'cancel' });
  }

  static trackGitConnectionSetup() {
    trackingService.trackGitSetup({ processApplicationId: processApplicationStore.processApplication.id });
  }

  /**
   * @typedef {Object} GitOperationParameters
   * @property {('show'|'cancel'|'push'|'pull')} operation - The operation to track.
   * @property {('start'|'complete'|'skip')} step - The step of the operation.
   * @property {('success'|'fail')} status - The status of the operation.
   * @property {('ours'|'theirs')} conflictResolution - The conflict resolution method.
   * @property {string} error - The error message if the operation failed.
   */
  static #trackEvent({ operation, step, status, conflictResolution, error }) {
    trackingService.trackGitOperation({
      operation,
      processApplicationId: processApplicationStore.processApplication.id,
      step,
      status,
      conflictResolution,
      error
    });
  }
}

export default GitSyncTrackingEvents;
