/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Dropdown, TargetSelector } from 'components';
import { currentDiagramStore } from 'stores';
import { handleCopyVersionToTarget } from 'App/Pages/ProcessApplicationVersions/features/copy/utils';

export const CopyListItem = ({ versionId, closeVersionMenuDropdown }) => {
  const [moveAnchorEl, setMoveAnchorEl] = useState(null);

  const history = useHistory();

  const { diagram, project } = currentDiagramStore.state;

  return (
    <>
      {/*@ts-expect-error TS2554*/}
      <Dropdown.ListItem
        hasSubDropdown
        data-test="version-menu-item-copy"
        onClick={(evt) => setMoveAnchorEl(evt.currentTarget)}
      >
        Copy to...
      </Dropdown.ListItem>
      <TargetSelector
        anchorEl={moveAnchorEl}
        open={Boolean(moveAnchorEl)}
        startingPoint={project}
        selectedEntities={[diagram]}
        invalidTargetIds={[]}
        onSubmit={async (target) => {
          closeVersionMenuDropdown();
          await handleCopyVersionToTarget({ versionId, target, history });
        }}
        action="copy"
      />
    </>
  );
};

export default CopyListItem;
