/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import pluralize from 'utils/pluralize';

import * as Styled from './ContainedResourcesNotification.styled';

export default function ContainedResourcesNotification({ resources = 1 }) {
  return (
    <>
      <Styled.Title>Resources</Styled.Title>
      <Styled.ActionableNotification
        title={`The application contains ${resources} ${pluralize('resource', resources)}`}
        subtitle={
          <span>
            Every resource within the process application will be automatically deployed.{' '}
            <a
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#deploy-a-process-application"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
            .
          </span>
        }
        kind="info"
        lowContrast
        inline
        hideCloseButton
      />
    </>
  );
}
