/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import buildPath from 'utils/build-path';

const MONACO_LOADER_CONFIG = { paths: { vs: buildPath('/min/vs') } };

export { MONACO_LOADER_CONFIG };
