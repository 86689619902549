/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import addQueryParameter from 'utils/add-query-parameter';
import { Service } from 'services';

class DiscoverConnectorsService extends Service {
  /**
   * Fetches the filters and returns the output of the process
   * @returns {Promise} - the promise of the fetch process
   */
  fetchFilters() {
    return this.#fetchFilters();
  }

  /**
   * Fetches the connectors and returns the output of the process
   * @returns {Promise} - the promise of the fetch process
   */
  fetchConnectors(filters, searchValue) {
    return this.#fetchConnectors(filters, searchValue);
  }

  /**
   * Fetches the details of a specific connector and returns the output of the process
   * @param {*} connectorId The marketplace id of the connector
   * @returns {Promise} - the promise of the fetch process
   */
  fetchConnectorsDetailsData(connectorId) {
    return this.#fetchConnectorDetails(connectorId);
  }

  async #fetchConnectorDetails(connectorId) {
    return this.get(`/marketplace/connectors/${connectorId}`);
  }

  async #fetchConnectors(filters, searchValue) {
    let fetchConnectorsUrl = `/marketplace/connectors`;
    if (filters) {
      Object.keys(filters).forEach((filterGroup) => {
        const queryParam = `${filterGroup}=${filters[filterGroup].toString()}`;
        fetchConnectorsUrl = addQueryParameter(fetchConnectorsUrl, queryParam);
      });
    }
    if (searchValue) {
      fetchConnectorsUrl = addQueryParameter(fetchConnectorsUrl, `search=${searchValue}`);
    }
    return this.get(fetchConnectorsUrl);
  }

  async #fetchFilters() {
    return this.get(`/marketplace/connector-filters`);
  }
}

export default new DiscoverConnectorsService();
