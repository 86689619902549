/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeObservable, observable, runInAction } from 'mobx';

import { organizationService } from 'services';
import { notificationStore } from 'stores';

class OrganizationSharedResourcesStore {
  sharedResources = new Map();
  loading = new Map();

  constructor() {
    makeObservable(this, {
      sharedResources: observable, // Resources shared across organization
      loading: observable
    });
  }

  async updateSharedResourcesForOrganization(organizationId) {
    runInAction(() => {
      this.loading.set(organizationId, true);
    });

    try {
      const templates = await organizationService.fetchTemplates(organizationId);
      runInAction(() => {
        this.sharedResources.set(organizationId, templates);
      });
    } catch (e) {
      console.info(e);
      notificationStore.showError('Yikes! Could not fetch your shared resources. Please try again later.');
    } finally {
      runInAction(() => {
        this.loading.set(organizationId, false);
      });
    }
  }

  getSharedResourcesForOrganization(organizationId) {
    return this.sharedResources.get(organizationId);
  }

  getLoadingStateForOrganization(organizationId) {
    return this.loading.get(organizationId);
  }
}

export default new OrganizationSharedResourcesStore();
