/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Stack } from '@carbon/react';

import * as Styled from './NameWithoutLinkRenderer.styled';

function NameWithoutLinkRenderer({ name, icon, customIconPadding = null }) {
  return (
    <Stack orientation="horizontal">
      <Styled.Icon $customIconPadding={customIconPadding}>{icon}</Styled.Icon>
      <Styled.Title orientation="vertical">
        <Styled.ContentWrapper>{name?.content}</Styled.ContentWrapper>
        {name?.pretitle && <Styled.Type title={name.pretitle}>{name.pretitle}</Styled.Type>}
      </Styled.Title>
    </Stack>
  );
}

export default NameWithoutLinkRenderer;
