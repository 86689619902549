/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observable, action, makeObservable } from 'mobx';

const DEFAULT_STATE = {
  isShortcutModalVisible: false,
  isShareModalVisible: false,
  diagram: {}
};

class DiagramExtensionStore {
  state = Object.assign({}, DEFAULT_STATE);

  constructor() {
    makeObservable(this, {
      state: observable,
      reset: action,
      hideShortcutModal: action,
      showShortcutModal: action,
      showShareModal: action,
      setDiagram: action,
      hideShareModal: action
    });
  }

  reset = () => (this.state = Object.assign({}, DEFAULT_STATE));

  hideShortcutModal = () => (this.state.isShortcutModalVisible = false);

  showShortcutModal = () => (this.state.isShortcutModalVisible = true);

  showShareModal() {
    this.state.isShareModalVisible = true;
  }

  setDiagram(diagram) {
    this.state.diagram = diagram;
  }

  hideShareModal = () => {
    this.state.isShareModalVisible = false;
    this.state.diagram = {};
  };
}

export default new DiagramExtensionStore();
