/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { EXTRACTION_VALIDATION_STATUS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';

import * as Styled from './ActionCell.styled';

function ActionCell({ row, onSetActiveDocument }) {
  switch (row.status) {
    case null:
    case undefined:
      if (row.extractedValue && !row.expectedValue) {
        return (
          <Styled.PointerCursorLink data-test="save-test-case-button" onClick={() => onSetActiveDocument(row.id)}>
            Save test case
          </Styled.PointerCursorLink>
        );
      }
      return '';
    case EXTRACTION_VALIDATION_STATUS.FAIL:
      return (
        <Styled.PointerCursorLink data-test="review-document-button" onClick={() => onSetActiveDocument(row.id)}>
          Review document
        </Styled.PointerCursorLink>
      );
    default:
      return '';
  }
}

export default ActionCell;
