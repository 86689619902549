/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { Conversation } from 'icons';
import { Sidebar, Spinner, EmptyState } from 'primitives';
import { commentsStore } from 'stores';

import Comment from './Comment';
import * as Styled from './CommentList.styled';

const CommentList = () => {
  const commentListRef = useRef();
  const { comments } = commentsStore;
  const previousCommentListLength = useRef(comments.length);
  const { isFetchingComments } = commentsStore.state;

  const scrollToBottom = (el) => {
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  useEffect(() => {
    const commentAdded = previousCommentListLength.current < comments.length;

    if (commentAdded) {
      scrollToBottom(commentListRef.current);
    }

    previousCommentListLength.current = comments.length;
  }, [comments]);

  useEffect(() => {
    if (!isFetchingComments) {
      scrollToBottom(commentListRef.current);
    }
  }, [isFetchingComments]);

  if (isFetchingComments) {
    return <Spinner fullHeight />;
  }

  if (comments.length === 0) {
    return (
      <EmptyState
        title="Add comments"
        description="You can add comments about diagrams or specific BPMN elements."
        icon={<Conversation width="60" height="40" />}
      />
    );
  }

  return (
    <Styled.CommentList ref={commentListRef}>
      {comments.map((item, key) => {
        if (typeof item === 'string') {
          return (
            <Sidebar.Separator key={item}>
              <span>{item}</span>
            </Sidebar.Separator>
          );
        }

        return <Comment key={item.id} comment={item} isLast={key + 1 === comments.length} />;
      })}
    </Styled.CommentList>
  );
};

export default observer(CommentList);
