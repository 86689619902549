/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * Bootstrap and glue code for DEV & PROD.
 * This boilerplate MUST be in this place and cannot be modulerazed
 * due to the way HMR works.
 */

/* eslint-disable import/order */
import './configure-webpack-public-path.js';

import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import 'styles/modeling.scss';

import App from './App';
import CustomGlobalStyle from './global-styles';

import { assetUpdateService } from 'services';
import history from 'utils/history';

const render = () => {
  createRoot(document.getElementById('root')).render(
    <Router history={history}>
      <HelmetProvider>
        <CustomGlobalStyle />
        <App />
      </HelmetProvider>
    </Router>
  );
};

// Hot Module Replacement API
// See for details:
// http://gaearon.github.io/react-hot-loader/getstarted/
render();

window.addEventListener('focus', () => {
  assetUpdateService.checkForUpdate().catch(() => console.log("Couldn't check for an app update. Are you offline?"));
});
