/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useCallback, useEffect } from 'react';

export default function ClickOutsideListener({ insideRef, anchorEl, onClickOutside, outsideRef }) {
  const onClick = useCallback(
    (event) => {
      const { target } = event;

      if (!insideRef.current?.contains(target) && !anchorEl?.contains(target)) {
        onClickOutside(event);
      }
    },
    [insideRef, onClickOutside]
  );

  useEffect(() => {
    const element = outsideRef ? outsideRef.current : document;

    element.addEventListener('click', onClick, { capture: true });

    return () => element.removeEventListener('click', onClick, { capture: true });
  }, [insideRef, onClick, outsideRef]);

  return null;
}
