/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { TextInput } from '@carbon/react';

import { idpApplicationStore } from 'stores';

import * as Styled from './CreationModal.styled';

function CreationModal({
  modalContentExtensionSection,
  clusters = [],
  getAdditionalParametersWhenFinalizingCreation = () => [],
  variant,
  learnMoreLink
}) {
  const [idpAppName, setIdpAppName] = useState('');

  const hasValidIdpAppName = idpAppName.trim().length;

  const closeModal = () => {
    idpApplicationStore.setIsCreationModalVisible(false);
    setIdpAppName('');
  };

  const performRequest = async () => {
    if (hasValidIdpAppName) {
      await idpApplicationStore.finalizeCreation(idpAppName, ...getAdditionalParametersWhenFinalizingCreation());
      closeModal();
    }
  };

  return (
    <Styled.IdpApplicationModal
      variant={variant}
      open={idpApplicationStore.isCreationModalVisible}
      modalHeading="Create an IDP application"
      primaryButtonText="Create IDP application"
      primaryButtonDisabled={!hasValidIdpAppName}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
    >
      <Styled.IdpApplicationModalContent>
        {clusters?.length > 0 ? (
          <>
            <TextInput
              data-modal-primary-focus
              id="idp-application-name"
              labelText="Name"
              placeholder="New IDP application"
              value={idpAppName}
              onChange={(event) => {
                setIdpAppName(event.target.value);
              }}
              autoComplete="off"
            />
            {modalContentExtensionSection}
          </>
        ) : (
          <Styled.HelperText>
            <span>Contact your organization admin to create a cluster.</span>
            <br />
            <span>A cluster is needed to store and test your sample documents.</span>
            &nbsp;
            {learnMoreLink}
          </Styled.HelperText>
        )}
      </Styled.IdpApplicationModalContent>
    </Styled.IdpApplicationModal>
  );
}

export default observer(CreationModal);
