/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Link } from '@carbon/react';
import { useParams } from 'react-router-dom';

import { idpApplicationStore, idpProjectStore } from 'stores';
import { EmptyState, EmptyState as EmptyStatePrimitive, Spinner } from 'primitives';
import { IdpApplication, IdpDocument, SadFace } from 'icons';
import * as Shared from 'App/Pages/Project/Shared.styled';
import { EntityTable } from 'components';
import { IdpProjectCreationModal } from 'App/Pages/Project/IdpApplication/IdpProject';

import IdpApplicationHeader from './IdpApplicationHeader';
import * as Styled from './IdpApplication.styled';

const IdpApplicationPage = () => {
  const [loading, setLoading] = useState(true);

  const { slug } = useParams();
  const { idpApplication } = idpApplicationStore;

  const reset = () => {
    idpApplicationStore.reset();
  };

  useEffect(() => {
    const init = async () => {
      await idpApplicationStore.init(slug.split('--')[0]);
      setLoading(false);
    };

    init();

    return () => reset();
  }, [slug]);

  const handleCreateIdpProject = () => {
    idpProjectStore.startCreation();
  };

  const IdpApplicationNotFound = () => {
    return (
      <EmptyState
        title="IDP application not found!"
        description="Sorry, your IDP application might have been deleted."
        icon={<SadFace width="48" height="48" />}
      />
    );
  };

  const NoIdpProjectsInApplication = () => {
    return (
      <EmptyStatePrimitive
        icon={<IdpApplication width="96" height="96" />}
        title="This is the start of your IDP application"
        description={
          <Styled.EmptyStateDescriptionContainer>
            <Styled.EmptyStateText>
              <b>Create an extraction project</b>
              <span>
                Upload sample documents, set up a taxonomy to categorize data, and annotate documents to enable accurate
                data extraction. This step is essential before moving on to document automation.
              </span>
            </Styled.EmptyStateText>
            <Styled.EmptyStateText>
              <b>Set up a document automation project</b>
              <span>After completing the extraction project, create a document automation project.</span>
              <span>You'll need to link at least one extraction project for automation to work effectively.</span>
            </Styled.EmptyStateText>
          </Styled.EmptyStateDescriptionContainer>
        }
        action={<Button onClick={handleCreateIdpProject}>Create extraction project</Button>}
        link={
          <Link
            href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications"
            target="_blank"
          >
            Learn more about IDP
          </Link>
        }
      />
    );
  };

  return (
    <>
      <IdpApplicationHeader />
      {loading ? (
        <Spinner fullHeight />
      ) : idpApplication?.idpProjects ? (
        <>
          <Styled.Container>
            <EntityTable
              action={({ tabIndex }) => (
                <Button tabIndex={tabIndex} onClick={() => idpProjectStore.startCreation()}>
                  Create new
                </Button>
              )}
              emptyState={<NoIdpProjectsInApplication />}
              columns={[]}
              title={
                <Shared.Title data-test="idp-application-name">
                  <IdpDocument width="24" height="24" />
                  {idpApplication.name}
                </Shared.Title>
              }
              toolbarPlaceholder="Type IDP project name to search"
              rows={idpApplication.idpProjects}
            />
          </Styled.Container>
          <IdpProjectCreationModal />
        </>
      ) : (
        <IdpApplicationNotFound />
      )}
    </>
  );
};

export default observer(IdpApplicationPage);
