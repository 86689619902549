/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Stack,
  Column,
  Grid,
  ContentSwitcher,
  Switch,
  RadioButton,
  Button,
  TextInput,
  CheckboxSkeleton,
  Modal
} from '@carbon/react';
import semver from 'semver';
import YAML from 'yaml';

import { notificationStore } from 'components/NotificationSystem';
import {
  CONNECTOR_TEMPLATE_MAX_API_DEFINITION_FILE_SIZE,
  CONNECTOR_TEMPLATE_VALID_DATA_SOURCE_TYPES,
  CONNECTOR_TEMPLATE_MIN_SUPPORTED_OPEN_API_VERSION,
  CONNECTOR_TEMPLATE_SUPPORTED_SWAGGER_VERSION
} from 'utils/constants';

import CreateNewElementTemplateService from './CreateNewElementTemplateService';
import CreateNewElementTemplateFileUpload from './CreateNewElementTemplateFileUpload';
import CreateNewElementTemplateFileUploadCommon from './CreateNewElementTemplateFileUploadCommon';
import MultiLevelCheckbox from './MultiLevelCheckbox';
import * as Styled from './CreateNewElementTemplate.styled';

export function CreateNewElementTemplateDataSource({
  dataSourceTypes,
  dataSourceType,
  setDataSourceType,
  templateGeneratorTypes,
  templateGeneratorType,
  setTemplateGeneratorType,
  dataSourceUrl,
  setDataSourceUrl,
  setDataSourceContent,
  checkboxes,
  setCheckboxes,
  FormElementTitle
}) {
  const defaultDataSourceUrlVersionInvalidMessage =
    'This version is not supported. Please enter a URL for an OpenAPI 3.0 or higher specification file or a Postman 2.1.0 collection.';
  const defaultDataSourceUrlContentInvalidMessage =
    'Unrecognized content. Please enter a URL for an OpenAPI 3.0 or higher specification file or a Postman 2.1.0 collection.';
  const defaultDataSourceFileVersionInvalidMessage =
    'This version is not supported. Please upload a file for an OpenAPI 3.0 or higher specification file or a Postman 2.1.0 collection.';
  const defaultDataSourceContentInvalidMessage =
    'Unrecognized content. Please upload a file for an OpenAPI 3.0 or higher specification file or a Postman 2.1.0 collection.';
  let dataSourceFileInvalidMessage = null;
  const [isMethodsLoading, setIsMethodsLoading] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [dataSourceContentForManualUpload, setDataSourceContentForManualUpload] = useState(null);
  const [dataSourceUrlInvalidMessage, setDataSourceUrlInvalidMessage] = useState(null);

  const isJson = (jsonContent) => {
    try {
      JSON.parse(jsonContent);
      return true;
      // eslint-disable-next-line no-unused-vars
    } catch (e) {
      return false;
    }
  };

  const checkOpenApiVersion = (version) => {
    const coercedVersion = semver.coerce(version);
    return semver.gte(coercedVersion, CONNECTOR_TEMPLATE_MIN_SUPPORTED_OPEN_API_VERSION);
  };

  const checkPostmanVersion = (parsedContent) => {
    return parsedContent?.info?.schema?.includes(`v${CONNECTOR_TEMPLATE_SUPPORTED_SWAGGER_VERSION}`);
  };

  const checkParsedVersion = (parsedContent) => {
    if (parsedContent?.openapi) {
      return checkOpenApiVersion(parsedContent?.openapi);
    } else if (parsedContent?.swagger) {
      return checkOpenApiVersion(parsedContent?.swagger);
    } else if (parsedContent?.info?.schema?.includes('getpostman')) {
      return checkPostmanVersion(parsedContent);
    }
    return true;
  };

  const setCorrectDataTypeSelection = (isOpenAPI, isPostman) => {
    if (isOpenAPI) {
      templateGeneratorType = 'OPEN_API';
      setTemplateGeneratorType('OPEN_API');
    } else if (isPostman) {
      templateGeneratorType = 'POSTMAN';
      setTemplateGeneratorType('POSTMAN');
    }
  };

  const handleUnrecognizedContent = () => {
    if (dataSourceType === 'import-from-url') {
      setDataSourceUrlInvalidMessage(defaultDataSourceUrlContentInvalidMessage);
    } else {
      dataSourceFileInvalidMessage = defaultDataSourceContentInvalidMessage;
    }
  };

  const handleInvalidVersion = () => {
    if (dataSourceType === 'import-from-url') {
      setDataSourceUrlInvalidMessage(defaultDataSourceUrlVersionInvalidMessage);
    } else {
      dataSourceFileInvalidMessage = defaultDataSourceFileVersionInvalidMessage;
    }
  };

  const parseJSONStringContent = (jsonContent) => {
    return isJson(jsonContent) ? JSON.parse(jsonContent) : YAML.parse(jsonContent);
  };

  const getDataSourceInJSONString = async (dataSourceContent) => {
    const dataSourceContentResult = dataSourceContent
      ? dataSourceContent
      : await CreateNewElementTemplateService.fetchResource(dataSourceUrl);
    return typeof dataSourceContentResult === 'object'
      ? JSON.stringify(dataSourceContentResult)
      : dataSourceContentResult;
  };

  const getMethods = async (dataSourceContentResultJSON) => {
    try {
      setDataSourceContent(dataSourceContentResultJSON);
      const methodsResult = await CreateNewElementTemplateService.listMethods(
        dataSourceContentResultJSON,
        templateGeneratorType
      );
      return methodsResult;
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  };

  const createOrGetParentCheckbox = (tag, transformedMethods) => {
    if (!transformedMethods[tag]) {
      // create parent
      transformedMethods[tag] = { children: {}, selected: false, supported: false, indeterminate: false };
    }
    return transformedMethods[tag];
  };

  const addChildCheckbox = (parent, method) => {
    parent.children[method.id] = method;
    parent.children[method.id].selected = method.supported !== false;
    if (parent.children[method.id].selected) {
      // if child is selected, make parent selected as well
      parent.selected = true;
    }
    if (method.supported !== false) {
      // if at least one child is supported, make parent supported as well
      parent.supported = true;
    }
  };

  const transformMethodsToCheckboxes = (methodsResult) => {
    const transformedMethods = {};
    methodsResult.forEach((method) => {
      // we assume that we only have one tag
      const firstNotEmptyTag = getFirstNotEmptyTag(method.tags);
      const parent = createOrGetParentCheckbox(firstNotEmptyTag, transformedMethods);
      addChildCheckbox(parent, method);
    });
    return transformedMethods;
  };

  const listMethods = async (dataSourceContent) => {
    try {
      setIsMethodsLoading(true);
      const dataSourceContentResultJSON = await getDataSourceInJSONString(dataSourceContent);
      const parsedContent = parseJSONStringContent(dataSourceContentResultJSON);
      const isOpenAPI = parsedContent?.openapi || parsedContent?.swagger;
      const isPostman = parsedContent?.info?.schema?.includes('getpostman');
      if (!isOpenAPI && !isPostman) {
        handleUnrecognizedContent();
        return false;
      }
      setCorrectDataTypeSelection(isOpenAPI, isPostman);
      if (!checkParsedVersion(parsedContent)) {
        handleInvalidVersion();
        return false;
      }
      const methodsResult = await getMethods(dataSourceContentResultJSON);
      const transformedMethods = transformMethodsToCheckboxes(methodsResult);
      setCheckboxes(transformedMethods);
      return true;
    } catch (e) {
      notificationStore.error(
        {
          title: 'We are unable to import the data source.',
          content: `Please make sure you're uploading a supported file type. If the problem persists please get in touch with the support team.`
        },
        { shouldPersist: true }
      );
      console.error(e);
      setCheckboxes();
      setTimeout(() => {
        setDataSourceContentForManualUpload();
      }, 1500);
      setDataSourceContent();
      setDataSourceUrl();
      return true;
    } finally {
      setIsMethodsLoading(false);
    }
  };

  const setFileUploadInvalid = (fileData, setFileData) => {
    CreateNewElementTemplateFileUploadCommon.setInvalidVersionFileData(
      fileData,
      setFileData,
      dataSourceFileInvalidMessage
    );
  };

  const getFirstNotEmptyTag = (tags) => {
    let i = 0;
    while (tags?.length > i && (tags[i] === null || tags[i] === '')) {
      i++;
    }
    return tags?.length > i ? tags[i] : 'Collection';
  };

  const isDataSourceImported = () => {
    return !isMethodsLoading && checkboxes;
  };

  return (
    <>
      <Modal
        open={importModalOpen}
        onRequestClose={() => setImportModalOpen(false)}
        onRequestSubmit={() => {
          setDataSourceUrlInvalidMessage(null);
          if (dataSourceType === 'upload-file') {
            setDataSourceContentForManualUpload();
            setCheckboxes();
          } else {
            listMethods();
          }
          setImportModalOpen(false);
        }}
        danger
        modalLabel="New import"
        primaryButtonText="Import new data"
        secondaryButtonText="Cancel"
      >
        <Stack gap={6}>
          <Grid>
            <Column span={16}>
              Re-importing will overwrite all previously imported data. Are you sure you want to continue?{' '}
            </Column>
          </Grid>
          <Grid>
            <Column span={16}>Click “Import new data” to proceed.</Column>
          </Grid>
        </Stack>
      </Modal>
      <Styled.Divider />
      {/* DATA SOURCE */}
      <Stack gap={5}>
        <Grid>
          <Column span={16}>
            <FormElementTitle title={'Import data source'} />
          </Column>
        </Grid>
        <Grid>
          {templateGeneratorTypes.map((option) => (
            <Styled.RadioButtonColumn key={option.id} span={16}>
              <RadioButton
                id={option.id}
                key={option.id}
                value={option.id}
                labelText={option.label}
                checked={option.id === templateGeneratorType}
                disabled={false}
                onChange={(value) => {
                  setTemplateGeneratorType(value);
                }}
              />
            </Styled.RadioButtonColumn>
          ))}
        </Grid>
        <Stack gap={2}>
          <Grid>
            <Column span={8}>
              <ContentSwitcher
                onChange={(data) => setDataSourceType(data.name)}
                selectedIndex={dataSourceType === 'import-from-url' ? 0 : 1}
              >
                {dataSourceTypes.map((sourceType) => (
                  <Switch key={sourceType.id} name={sourceType.id} disabled={false}>
                    {sourceType.label}
                  </Switch>
                ))}
              </ContentSwitcher>
            </Column>
          </Grid>
        </Stack>
        <Stack gap={2}>
          {dataSourceType === 'import-from-url' && (
            <Styled.TextInputWithButtonContainer>
              <Styled.TextInputWithIconContainer>
                <TextInput
                  autocomplete="off"
                  type="text"
                  id="data-source"
                  labelText={''}
                  placeholder={'Enter the URL for an OpenAPI 3.0+ specification file or a Postman 2.1.0 collection'}
                  value={dataSourceUrl}
                  onChange={(event) => {
                    setDataSourceUrl(event.target.value);
                  }}
                  invalid={dataSourceUrlInvalidMessage?.length > 0}
                  invalidText={dataSourceUrlInvalidMessage}
                  disabled={false}
                />
                {isDataSourceImported() && !dataSourceUrlInvalidMessage && <Styled.GreenCheckmarkFilled />}
              </Styled.TextInputWithIconContainer>
              <div>
                {isDataSourceImported() ? (
                  <Button kind="ghost" size="md" disabled={false} onClick={() => setImportModalOpen(true)}>
                    Import new
                  </Button>
                ) : (
                  <Button kind="tertiary" size="md" disabled={!dataSourceUrl} onClick={() => listMethods()}>
                    Import file
                  </Button>
                )}
              </div>
            </Styled.TextInputWithButtonContainer>
          )}
          {dataSourceType === 'upload-file' && (
            <Stack>
              <Grid>
                <Column span={16}>
                  <Stack gap={5}>
                    {`Supported file types are ${CONNECTOR_TEMPLATE_VALID_DATA_SOURCE_TYPES.join(', ')}.
                    Max file size is ${CONNECTOR_TEMPLATE_MAX_API_DEFINITION_FILE_SIZE / 1000} KB.`}
                  </Stack>
                  <Stack gap={5}>
                    <CreateNewElementTemplateFileUpload
                      fileData={dataSourceContentForManualUpload}
                      setFileData={setDataSourceContentForManualUpload}
                      validFileTypes={CONNECTOR_TEMPLATE_VALID_DATA_SOURCE_TYPES}
                      fileSizeLimit={CONNECTOR_TEMPLATE_MAX_API_DEFINITION_FILE_SIZE}
                      onLoadFn={(data) => listMethods(data)}
                      onDeleteFn={() => setImportModalOpen(true)}
                      afterLoadError={(fileData, setFileData) => setFileUploadInvalid(fileData, setFileData)}
                    />
                  </Stack>
                </Column>
              </Grid>
            </Stack>
          )}
        </Stack>
        <Stack gap={2}>
          {isMethodsLoading && (
            <>
              <CheckboxSkeleton /> <CheckboxSkeleton /> <CheckboxSkeleton /> <CheckboxSkeleton /> <CheckboxSkeleton />
            </>
          )}
          {!isMethodsLoading && checkboxes && (
            <MultiLevelCheckbox checkboxes={checkboxes} setCheckboxes={setCheckboxes} />
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default observer(CreateNewElementTemplateDataSource);
