/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';

class DeploymentService extends Service {
  /**
   * Deploys the currently active diagram to cluster with the specified id
   *
   * @param {String} clusterId The id of the cluster to deploy the diagram to
   * @param {String} diagramId The id of the diagram to deploy
   * @param {Object} formResolutions A list of the form resolutions for the new deployment
   * @returns {Promise}
   */
  deploy(clusterId, diagramId, formResolutions) {
    return this.post(`/internal-api/files/${diagramId}/deploy`, {
      clusterId,
      formResolutions
    });
  }

  /**
   * Deploys the currently active diagram to cluster with the specified id, if it is not already deployed there.
   * Then, starts a process instance with the specified payload for the currently active diagram in the specified cluster.
   *
   * @param {String} clusterId The id of the cluster to deploy the diagram to
   * @param {Object} payload JSON object of the payload for the new instance
   * @returns {Promise}
   */
  execute(clusterId, payload, diagramId, formResolutions) {
    return this.post(`/internal-api/files/${diagramId}/execute`, {
      clusterId,
      variables: payload,
      formResolutions
    });
  }

  /**
   * Fetches the deployements based on a given `fileId` in the descending order of last deployed date.
   *
   * @param {String} fileId
   * @returns {Promise}
   */
  fetchDeploymentsById(fileId) {
    return this.get(`/internal-api/files/${fileId}/deployments`);
  }
}

export default new DeploymentService();
