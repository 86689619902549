/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'hooks';

import * as Styled from './ResizablePanel.styled';

const MIN_SIZE = 400;
const MAX_SIZE = MIN_SIZE * 3;
const CLICK_THRESHOLD = 200;
const COLLAPSE_THRESHOLD = 70;

/**
 * Resizable panel component
 * @param {boolean} open - Whether the panel is open
 * @param {React.ReactNode} children - Content of the panel
 * @param {string} position - Position of the panel (left, right, top, bottom)
 * @param {string} background - Background color of the panel
 * @param {string} panelKey - Key to store the size in local storage (${panelKey}_size, e.g. sidebar_size)
 * @param {string} handleAriaLabel - Aria label for the handle
 * @param {number} minSize - Minimum size of the panel
 * @param {number} maxSize - Maximum size of the panel
 * @param {number} sizeClosed - Size of the panel when closed, default is 5 (the handle dimension)
 * @param {function} onResize - Callback when the panel is resized, it will pass the new size as an argument
 * @param {function} onOpenChange - Callback when the panel is opened or closed, it will pass the new open state as an argument. Mandatory because the parent component needs to update the state of the panel.
 */
const ResizablePanel = ({
  open = true,
  children,
  position,
  background,
  panelKey,
  handleAriaLabel = 'Resize',
  minSize = MIN_SIZE,
  maxSize = MAX_SIZE,
  sizeClosed = 5,
  onResize = () => {},
  onOpenChange = () => {},
  ...props
}) => {
  const [size, setSize] = useLocalStorage(`${panelKey}_size`, minSize);
  const [isDragging, setIsDragging] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  let mouseDownTimestamp = useRef(0);
  // We need to keep track of the last event size to compute the direction of the resize during the mouse move events
  let previousSize = useRef(0);

  /**
   * Dispatch a resize event when the size changes, this is needed to update
   * elements that depend on the size of the panel
   */
  useEffect(() => {
    if (typeof Event === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      // for IE and other old browsers
      var evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    }

    onResize(open ? size : sizeClosed);
  }, [size, open]);

  const getMeasure = () => {
    if (position === 'left' || position === 'right') {
      return 'width';
    } else if (position === 'top' || position === 'bottom') {
      return 'height';
    } else {
      throw new Error('Invalid position prop');
    }
  };

  const getActualSize = () => {
    if (!open) {
      return sizeClosed;
    }

    if (size > maxSize && !isDragging) {
      return maxSize;
    }

    if (size < minSize) {
      return minSize;
    }

    return size;
  };

  /**
   * Handle the mouse down event
   * @param e - Mouse event
   */
  const onMouseDown = (e) => {
    e.preventDefault();
    initResize();
  };

  /**
   * Handle the mouse move event; it takes care of the resizing of the panel, and the auto-collapse in case the size is less than the min size
   * @param e - Mouse event
   */
  const onMouseMove = (e) => {
    e.preventDefault();

    const newSize = getSizeByMouseEvent(e);

    if (pauseIfAutoCollapseThresholdReached(newSize, previousSize.current)) {
      return;
    }
    openIfSizeIncreasing(newSize, previousSize.current);
    collapseIfSizeDecreasingAndGoesBelowMinimum(newSize, previousSize.current);

    setSize(newSize > maxSize ? maxSize : newSize);

    previousSize.current = newSize;
  };

  const isSizeIncreasing = (newSize, prevSize) => {
    let resizeDirection = getResizeDirection(newSize, prevSize);
    return resizeDirection === 1;
  };

  const isSizeDecreasing = (newSize, prevSize) => {
    let resizeDirection = getResizeDirection(newSize, prevSize);
    return resizeDirection === -1;
  };

  const openIfSizeIncreasing = (newSize, prevSize) => {
    if (isSizeIncreasing(newSize, prevSize)) {
      setShouldAnimate(false);
      doOpen();
    }
  };

  const pauseIfAutoCollapseThresholdReached = (newSize, prevSize) => {
    if (isSizeDecreasing(newSize, prevSize) && newSize < minSize && minSize - newSize < COLLAPSE_THRESHOLD) {
      setSize(minSize);
      return true;
    }

    return false;
  };

  const collapseIfSizeDecreasingAndGoesBelowMinimum = (newSize, prevSize) => {
    if (isSizeDecreasing(newSize, prevSize) && newSize < minSize) {
      setShouldAnimate(true);
      doClose();
    }
  };

  const initResize = () => {
    mouseDownTimestamp.current = Date.now();

    setIsDragging(true);
    setShouldAnimate(false);

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const cleanupResize = () => {
    setIsDragging(false);
    setShouldAnimate(true);

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  /**
   * Handle the mouse up event; it takes care of the auto-collapse of the panel and the click event to toggle the panel
   */
  const onMouseUp = () => {
    cleanupResize();

    // If the mouse was down for less than the threshold, treat it as a click
    // and toggle the panel
    if (Date.now() - mouseDownTimestamp.current < CLICK_THRESHOLD) {
      if (!open) {
        setSize(size);
        doOpen();
      } else {
        doClose();
      }
    }
  };

  const doClose = () => {
    onOpenChange(true);
  };

  const doOpen = () => {
    onOpenChange(false);
  };

  /**
   * Get the size given a mouse event
   * @param e - Mouse event
   * @returns {number} - Size
   */
  const getSizeByMouseEvent = (e) => {
    const measure = getMeasure();
    let size = 0;

    if (measure === 'width') {
      if (position === 'left') {
        size = e.clientX;
      } else if (position === 'right') {
        size = window.innerWidth - e.clientX;
      }
    } else if (measure === 'height') {
      if (position === 'top') {
        size = e.clientY;
      } else if (position === 'bottom') {
        size = window.innerHeight - e.clientY;
      }
    } else {
      throw new Error('Invalid measure');
    }

    return size;
  };

  /**
   * Get the resize direction based on the position of the panel and the new and previous sizes
   * @param newSize - New size
   * @param prevSize - Previous size
   * @returns {number} - 1 if the size is increasing, -1 if the size is decreasing
   */
  const getResizeDirection = (newSize, prevSize) => {
    if (position === 'left' || position === 'top') {
      if (newSize > prevSize) {
        return -1;
      } else if (newSize < prevSize) {
        return 1;
      } else {
        return 0;
      }
    } else if (position === 'right' || position === 'bottom') {
      if (newSize > prevSize) {
        return 1;
      } else if (newSize < prevSize) {
        return -1;
      } else {
        return 0;
      }
    }
  };

  const Handle = (props) => {
    const handlesMap = {
      left: <Styled.RightHandle {...props} />,
      right: <Styled.LeftHandle {...props} />,
      top: <Styled.BottomHandle {...props} />,
      bottom: <Styled.TopHandle {...props} />
    };

    if (!handlesMap[position]) {
      throw new Error('Invalid position prop');
    }

    return handlesMap[position];
  };

  const actualSize = getActualSize();

  return (
    <>
      <Styled.GlobalStyle $position={position} $isDragging={isDragging} />
      <Styled.Panel
        {...props}
        open={open}
        $background={background}
        $sizeClosed={sizeClosed}
        $position={position}
        $isDragging={isDragging}
        className={shouldAnimate ? 'animate' : ''}
        style={{ [getMeasure()]: `${actualSize}px` }}
      >
        <Handle
          role="separator"
          aria-label={handleAriaLabel}
          aria-orientation={position === 'left' || position === 'right' ? 'vertical' : 'horizontal'}
          aria-valuenow={actualSize}
          aria-valuemin={sizeClosed}
          aria-valuemax={maxSize}
          onMouseDown={onMouseDown}
          open={open}
          $isDragging={isDragging}
          $position={position}
        />
        <div className="content">{children}</div>
      </Styled.Panel>
    </>
  );
};

ResizablePanel.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']).isRequired,
  panelKey: PropTypes.string.isRequired,
  handleAriaLabel: PropTypes.string,
  background: PropTypes.string,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  sizeClosed: PropTypes.number,
  onResize: PropTypes.func,
  onOpenChange: PropTypes.func.isRequired
};

export default ResizablePanel;
