/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { InboundConnectorLog } from './';
import * as Styled from './InboundConnector.styled';

export const InboundConnectorLogs = ({ logs, initialLogLoadingInProgress }) => {
  return (
    <Styled.LogsContainer>
      <Styled.LastActivitiesTitle>Last activities</Styled.LastActivitiesTitle>
      {!initialLogLoadingInProgress ? (
        <>
          {logs?.length > 0 ? (
            <>
              {logs.map((log) => (
                <div key={log?.timestamp}>
                  <InboundConnectorLog log={log} />
                </div>
              ))}
            </>
          ) : (
            <div>No activities recorded</div>
          )}
        </>
      ) : (
        <>
          <Styled.Loading description={`Loading activity logs`} />
        </>
      )}
    </Styled.LogsContainer>
  );
};

export default observer(InboundConnectorLogs);
