/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { TextInput } from '@carbon/react';

import { idpApplicationStore } from 'stores';
import { clusterCreationModalStore } from 'features/create-cluster/index';
import { IdpClusterCreationModal } from 'App/Pages/Project/IdpApplication/idp-cluster-creation-modal/IdpClusterCreationModal';

import * as Styled from './CreationModal.styled';

function CreationModal({
  modalContentExtensionSection,
  clusters = [],
  getAdditionalParametersWhenFinalizingCreation = () => [],
  variant
}) {
  const [idpAppName, setIdpAppName] = useState('');

  const hasValidIdpAppName = idpAppName.trim().length;

  useEffect(() => {
    if (clusters?.length === 0 && idpApplicationStore.isCreationModalVisible) {
      clusterCreationModalStore.open();
    }
  }, [idpApplicationStore.isCreationModalVisible]);

  const closeModal = () => {
    idpApplicationStore.setIsCreationModalVisible(false);
    clusterCreationModalStore.close();
    setIdpAppName('');
  };

  const performRequest = async () => {
    if (!isCreationDisabled()) {
      await idpApplicationStore.finalizeCreation(idpAppName, ...getAdditionalParametersWhenFinalizingCreation());
      closeModal();
    }
  };

  const isCreationDisabled = () => {
    const [selectedClusterId] = getAdditionalParametersWhenFinalizingCreation();
    return !hasValidIdpAppName || !selectedClusterId;
  };

  if (clusters?.length === 0) {
    return <IdpClusterCreationModal onClose={closeModal} onSubmit={closeModal} />;
  }

  return (
    <Styled.IdpApplicationModal
      // @ts-expect-error TS2769
      variant={variant}
      open={idpApplicationStore.isCreationModalVisible}
      modalHeading="Create an IDP application"
      primaryButtonText="Create"
      primaryButtonDisabled={isCreationDisabled()}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
      preventCloseOnClickOutside
    >
      <Styled.IdpApplicationModalContent>
        <TextInput
          data-modal-primary-focus
          id="idp-application-name"
          labelText="Name"
          placeholder="New IDP application"
          value={idpAppName}
          onChange={(event) => {
            setIdpAppName(event.target.value);
          }}
          autoComplete="off"
        />
        {modalContentExtensionSection}
      </Styled.IdpApplicationModalContent>
    </Styled.IdpApplicationModal>
  );
}

export default observer(CreationModal);
