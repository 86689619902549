/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { readFiles } from 'utils/file-io';

import * as Styled from './DropTarget.styled';

let counter = 0;

export default function DropTarget({
  children,
  isDisabled,
  onDrop,
  displayStatus = 'flex',
  text = 'Drop files for upload'
}) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = () => {
    counter = counter + 1;

    setIsDragging(true);
  };

  const handleDragOver = (evt) => evt.preventDefault();

  const handleDragStart = (evt) => evt.preventDefault();

  const handleDragLeave = () => {
    counter = counter - 1;

    if (counter == 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = async (evt) => {
    counter = 0;

    setIsDragging(false);

    onDrop(await readFiles(evt.dataTransfer.files), 'CREATE_DROPPED');
  };

  useEffect(() => {
    window.addEventListener('keydown', handleDragLeave);

    return () => {
      window.removeEventListener('keydown', handleDragLeave);
    };
  }, []);

  if (isDisabled) {
    return children;
  }

  return (
    <Styled.DropTarget
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      $isDragging={isDragging}
      $displayStatus={displayStatus}
      $text={text}
      data-test="drop-target"
    >
      {children}
    </Styled.DropTarget>
  );
}
