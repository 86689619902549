/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';

class RenameService extends Service {
  /**
   * Renames a process application version.
   *
   * @param {string} versionId - The ID of the process application version to rename.
   * @param {string} newName - The new name for the process application version.
   * @returns {Promise<void>}
   */
  async renameProcessApplicationVersion(versionId, newName) {
    return this.patch(`/internal-api/process-application-versions/${versionId}`, {
      name: newName
    });
  }
}

export const renameService = new RenameService();
