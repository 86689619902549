/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Grid, Column, ModalBody, ModalHeader, ComposedModal } from '@carbon/react';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingColumn = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingRow = styled(Row)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LandscapeViewModalContainer = styled(ComposedModal)`
  .cds--modal-container--lg {
    @media (min-width: 66rem) {
      inline-size: 96%;
    }
    .cds--css-grid {
      @media (min-width: 66rem) {
        max-inline-size: none;
      }
    }
  }
`;

export const LandscapeViewModalHeaderContainer = styled(ModalHeader)`
  padding-block: 16px;
  margin: 0;
`;

export const LandscapeViewModalHeader = styled.h3`
  font-size: 20px;
  line-height: 28px;
  color: #161616;
`;

export const LandscapeViewModalBody = styled(ModalBody)`
  padding: 0;
  height: 85vh;
`;

export const LandscapeViewModalGrid = styled(Grid)`
  margin: 0;
  padding-inline: 0;
  height: 100%;
`;

export const LandscapeCanvas = styled.div`
  height: 100%;
  width: 100%;
  background: #f4f4f4;
`;

export const ColumnChart = styled(Column)`
  position: relative;
  padding-inline: 0;
  margin-inline: 0;
`;

export const ColumnSidePanel = styled(Column)`
  margin: 0;
  overflow-y: auto;
  height: 100%;
`;

export const DiagramControlsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: fit-content;
`;
