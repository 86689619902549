/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { useClusterSelection } from 'App/Pages/Project/IdpApplication/hooks';
import { idpApplicationStore } from 'stores';

import CreationModal from './CreationModal.common';
import { ClusterSelection } from './../cluster-selection';

function CreationModalSaaS() {
  const { clusters, selectedCluster, setSelectedCluster } = useClusterSelection({
    reloadClusters: idpApplicationStore.isCreationModalVisible
  });

  return (
    // @ts-expect-error TS2741
    <CreationModal
      clusters={clusters}
      modalContentExtensionSection={
        <ClusterSelection
          clusters={clusters}
          selectedClusterId={selectedCluster?.uuid}
          onClusterIdSelection={(clusterId) =>
            setSelectedCluster(clusters?.find((cluster) => cluster.uuid === clusterId))
          }
        />
      }
      getAdditionalParametersWhenFinalizingCreation={() => [selectedCluster?.uuid]}
    />
  );
}

export default observer(CreationModalSaaS);
