/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useCallback, useEffect } from 'react';

import { clustersStore } from 'stores';

export default function useAppCues({
  history,
  currentOrganizationInfo,
  config,
  organizationStore,
  userAuthProviderId
}) {
  const organization = organizationStore.currentOrganization;
  const organizationInfo = currentOrganizationInfo;
  const appCuesEnabled = config?.appcues?.enabled;
  const orgId = organization?.id;
  const salesPlanType = organizationInfo?.salesPlan?.type;
  const roles = organization?.roles?.join('|');
  const clusters = clustersStore.clusters?.map((cluster) => cluster.uuid).join('|');

  const trackAppCuesCallback = useCallback(
    () =>
      trackAppCuesPageAndIdentity({
        appCuesEnabled,
        clusters,
        userAuthProviderId,
        orgId,
        salesPlanType,
        roles
      }),
    [appCuesEnabled, clusters, userAuthProviderId, orgId, salesPlanType, roles]
  );

  useEffect(() => {
    return history.listen(() => {
      trackAppCuesCallback();
    });
  }, [trackAppCuesCallback, history]);

  useEffect(() => {
    trackAppCuesCallback();
  }, [trackAppCuesCallback]);

  useEffect(() => {
    const onPersonalizationCategoryChanged = (change) => {
      if (change?.PERSONALIZATION === 'ACCEPT') {
        trackAppCuesCallback();
      }
      if (change?.PERSONALIZATION === 'DENY') {
        // @ts-expect-error TS2339
        window.Appcues?.reset?.();
      }
    };

    // see https://developers.osano.com/cmp/javascript-api/developer-documentation-consent-javascript-api#events-consent-saved for more info
    // @ts-expect-error TS2339
    window.Osano?.cm?.addEventListener?.('osano-cm-consent-saved', onPersonalizationCategoryChanged);
    return () => {
      // @ts-expect-error TS2339
      window.Osano?.cm?.removeEventListener?.('osano-cm-consent-saved', onPersonalizationCategoryChanged);
    };
  }, [trackAppCuesCallback]);
}

function trackAppCuesPageAndIdentity({ appCuesEnabled, clusters, userAuthProviderId, orgId, salesPlanType, roles }) {
  // @ts-expect-error TS2339
  const isOptedIn = window.Osano?.cm?.personalization;
  if (appCuesEnabled && isOptedIn) {
    // the page call has to be called before identify
    // @ts-expect-error TS2339
    window.Appcues?.page();

    if (orgId && userAuthProviderId) {
      const appCuesAttributes = {
        orgId,
        salesPlanType,
        roles,
        clusters
      };

      // @ts-expect-error TS2339
      window.Appcues?.identify(userAuthProviderId, appCuesAttributes);
    }
  }
}
