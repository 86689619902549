/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Service } from 'services';
import { downloadFromBackingAnchorElement } from 'utils/file-io/save-file';

class DownloadService extends Service {
  /**
   * Downloads a given process application version.
   * @param {string} processApplicationVersionId - The ID of the process application version.
   * @param {string} fileName - The name of the downloadable zip file.
   * @returns {Promise<number>}
   */
  async downloadProcessApplicationVersion(processApplicationVersionId, fileName) {
    const content = await this.get(
      `/internal-api/process-application-versions/${processApplicationVersionId}/download`
    );

    downloadFromBackingAnchorElement(content, `${fileName}.zip`);

    return content?.size;
  }
}

export const downloadService = new DownloadService();
