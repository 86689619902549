/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { authService } from 'services';

import PusherBatchAuthorizer from './PusherBatchAuthorizer';
import { BaseWebSocket } from './WebSocket.common';

export class WebSocket extends BaseWebSocket {
  createSocket() {
    const pusherOptions = {
      disableStats: true,
      channelAuthorization: {
        endpoint: '/pusher/auth',
        customHandler: (channel, callback) => PusherBatchAuthorizer(channel, callback, pusherOptions)
      },
      auth: async () => ({
        headers: {
          Authorization: `Bearer ${await authService.getToken()}`
        }
      }),
      authDelay: 500,
      forceTLS: true,
      cluster: 'eu'
    };

    this.instanciateSocket(pusherOptions);
  }
}
