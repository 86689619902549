/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default function contentContainsURL(content) {
  return new RegExp(
    `([a-zA-Z0-9.+-]+://|www\\.)([-a-zA-Z0-9+$&@#/%?=~_|!:,;'*()]{0,63}\\.)+([a-zA-Z0-9-:]{1,63})|([-a-zA-Z0-9+$&@#/%?=~_|!:,;'*()]{1,63}\\.)+([a-zA-Z0-9-:]{1,63})/`
  ).test(content);
}
