/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'primitives';
import { Dropdown } from 'components';
import { getAllRoles, getRoleDetails } from 'utils/member-roles';

import * as Styled from './PermissionSelector.styled';

export default function PermissionSelector({ value = 'WRITE', onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Fragment>
      <Button
        aria-owns={anchorEl ? 'permission-menu' : null}
        aria-haspopup="true"
        data-test="permission-button"
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        variant="secondary"
        dropdown
      >
        {getRoleDetails(value).title}
      </Button>

      <Dropdown
        id="permission-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        data-test="permission-menu"
      >
        {getAllRoles().map((role) => (
          <Dropdown.ListItem
            key={role.id}
            onClick={() => {
              onChange(role.id);
              setAnchorEl(null);
            }}
            title={role.description}
            data-test={`${role.id}-permission`}
          >
            <Styled.Icon>{role.icon}</Styled.Icon>
            {role.title}
          </Dropdown.ListItem>
        ))}
      </Dropdown>
    </Fragment>
  );
}

PermissionSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
