/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Modal } from '@carbon/react';

export const ConnectToDiagramModal = styled(Modal)`
  .cds--modal-container {
    max-width: 520px;
  }

  .cds--modal-content {
    overflow: hidden;
  }
`;

export const ConnectFormGuidance = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 24px;
  padding-bottom: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
