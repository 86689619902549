/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { TopBar, Breadcrumb, Dropdown } from 'components';
import { projectStore, breadcrumbStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { getPageTitle } from 'utils/helpers';
import buildSlug from 'utils/buildSlug';

const FolderHeader = () => {
  const history = useHistory();
  const { project, folder, loading } = projectStore;
  const pageTitle = loading ? 'Loading...' : folder.name || 'Folder Not Found';

  const handleFolderDelete = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    if (await projectStore.deleteEntities(folder)) {
      if (folder.parent) {
        history.push(`/folders/${buildSlug(folder.parent.id, folder.parent.name)}`);
      } else {
        history.push(`/projects/${buildSlug(project.id, project.name)}`);
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle(pageTitle)}</title>
      </Helmet>

      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" to="/" data-test="breadcrumb-home" />
        <Breadcrumb
          title={project.name || 'Loading...'}
          variant="link"
          to={`/projects/${buildSlug(project.id, project.name)}`}
          data-test="breadcrumb-project"
        />
        {Boolean(folder.parent) && (
          <Fragment>
            {folder.parent.parentId && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
            <Breadcrumb
              title={folder.parent.name}
              variant="link"
              to={`/folders/${buildSlug(folder.parent.id, folder.parent.name)}`}
              data-test="parent-folder"
            />
          </Fragment>
        )}
        {hasAccess(project, actions.MODIFY_DIAGRAM) ? (
          <Breadcrumb
            title={folder.name || 'Loading...'}
            variant="dropdown"
            isBold
            forPage="folder"
            data-test="breadcrumb-folder-menu"
            handleSubmit={(name) => {
              breadcrumbStore.finishEditing();
              projectStore.renameFolder(name);
            }}
          >
            <Dropdown.ListItem
              key="edit"
              onClick={() => {
                breadcrumbStore.toggleDropdownVisibility();
                breadcrumbStore.toggleEditingFor('folder');
              }}
              data-test="rename-folder"
            >
              Edit name
            </Dropdown.ListItem>
            <Dropdown.ListItem key="delete" onClick={handleFolderDelete} data-test="delete-folder">
              Delete
            </Dropdown.ListItem>
          </Breadcrumb>
        ) : (
          <Breadcrumb title={folder.name || 'Loading...'} variant="text" isBold data-test="breadcrumb-folder" />
        )}
      </TopBar.Breadcrumbs>
    </Fragment>
  );
};

export default observer(FolderHeader);
