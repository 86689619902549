/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observable, action, makeObservable } from 'mobx';

const DEFAULT_STATE = {
  diffingDetailsShown: false
};

class DiffingStore {
  state = Object.assign({}, DEFAULT_STATE);

  diffingDetailsContent = [];

  constructor() {
    makeObservable(this, {
      state: observable,
      hideDiffingDetails: action,
      showDiffingDetails: action
    });
  }

  hideDiffingDetails = () => {
    this.state.diffingDetailsShown = false;
  };

  showDiffingDetails = () => {
    this.state.diffingDetailsShown = true;
  };

  reset = () => {
    this.hideDiffingDetails();
    this.diffingDetailsContent = [];
  };
}

export default new DiffingStore();
