/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { UnstructuredExtraction } from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/unstructured-extraction/UnstructuredExtraction';
import { IDP_PROJECT_EXTRACTION_METHODS } from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';
import { StructuredExtraction } from 'App/Pages/Project/IdpApplication/idp-project/idp-extraction-project/structured-extraction/StructuredExtraction';

export function IdpExtractionProject({
  extractionMethod,
  canUserModifyProject,
  handleNavigationToVersions,
  renderTitleContainer
}) {
  return extractionMethod === IDP_PROJECT_EXTRACTION_METHODS[0].value ? (
    <StructuredExtraction />
  ) : (
    <UnstructuredExtraction
      canUserModifyProject={canUserModifyProject}
      handleNavigationToVersions={handleNavigationToVersions}
      renderTitleContainer={renderTitleContainer}
    />
  );
}
