/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { attentionGrabberStore } from 'stores';

export default class AttentionGrabberExtension {
  constructor(eventBus) {
    this.eventBus = eventBus;

    eventBus.on(['element.click'], this.handleModelerClick);
    eventBus.on(['tool-manager.update', 'drag.start'], this.handleEntryClick);
  }

  handleModelerClick = (event) => {
    if (attentionGrabberStore.state.isAttentionGrabberActive) {
      attentionGrabberStore.publishAttentionGrabberCreation(event);
    }
  };

  handleEntryClick = () => {
    if (attentionGrabberStore.state.isAttentionGrabberActive) {
      attentionGrabberStore.publishAttentionGrabberDeletion();
      attentionGrabberStore.toggleAttentionGrabberActive();
    }
  };
}

AttentionGrabberExtension.$inject = ['eventBus', 'config.attentionGrabberExtension'];
