/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Dropdown } from 'components';

export const ActionItem = styled(Dropdown.ListItem)`
  padding: var(--spacing-tiny) var(--spacing-small);

  & > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: var(--spacing-tiny);
    }
  }
`;
