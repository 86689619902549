/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observable, action, runInAction, computed, makeObservable } from 'mobx';

import isEmailValid from 'utils/validate-email';
import contentContainsURL from 'utils/contentContainsURL';
import { shareService, trackingService } from 'services';
import { notificationStore } from 'stores';

const DEFAULT_STATE = {
  diagram: null,
  isLoading: false,
  error: null,
  isPasswordControlVisible: false,
  isRemovePasswordControlVisible: false,
  isDestroyLinkControlVisible: false,
  isShareByEmailControlVisible: false,
  emailMessage: '',
  emails: [],
  diagramByShareView: null
};

const ERRORS = {
  INVITE_LIMIT_EXCEEDED: 'Oops, it seems that you are trying to send too many emails at the same time.'
};

class ShareStore {
  state = Object.assign({}, DEFAULT_STATE);

  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE);
  };

  /**
   * Sets the the current diagram for the Share View. This
   * method is called after the user opened a shared diagram
   * link (or after entering the correct password).
   */
  setDiagramByShareView = (diagram) => {
    this.state.diagramByShareView = diagram;
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      reset: action('reset'),
      setDiagramByShareView: action,
      shareViewDiagram: computed,
      setDiagram: action('set diagram'),
      setLoading: action('set loading state'),
      loadingError: action('set error state'),
      hasEmails: computed,
      isMainDialogOpen: computed,
      handleEmailMessageChange: action('set email message'),
      hideShareByEmailControl: action('hide share by email control'),
      handleShareEmailSubmit: action
    });
  }

  /**
   * Gets the current diagram for the Share View. This value
   * is served from the store because both, breadcrumbs and share
   * view, need it to display the name and contents.
   */
  get shareViewDiagram() {
    return this.state.diagramByShareView;
  }

  setDiagram = (diagram) => {
    this.state.diagram = diagram;
  };

  setLoading = (isLoading) => {
    this.state.isLoading = isLoading;
  };

  loadingError = (error) => {
    this.state.error = error;
  };

  get hasEmails() {
    return this.state.emails.length > 0;
  }

  get isMainDialogOpen() {
    return (
      !this.state.isPasswordControlVisible &&
      !this.state.isRemovePasswordControlVisible &&
      !this.state.isDestroyLinkControlVisible
    );
  }

  createLink = () => {
    this.setLoading(true);

    shareService
      .create({ fileId: this.state.diagram.id })
      .then((share) => runInAction(() => (this.state.diagram.shareId = share.id)))
      .catch(() => notificationStore.showError('Could not create the share.'))
      .finally(() => this.setLoading(false));

    trackingService.trackShareFile(this.state.diagram.id, this.state.diagram.type);
  };

  /**
   * Validates a given email address and sets the error variable
   * based on the results. Said error variable toggles an error message
   * in the InvitationModal component.
   *
   * @param {String} email The email to be validated
   * @return {String} Returns an error message if any validation failed.
   */
  validate = (email) => {
    if (this.state.emails.includes(email)) {
      return `${email} has already been entered.`;
    }

    if (!isEmailValid(email)) {
      return `${email} is not a valid email address.`;
    }
  };

  togglePasswordControl = (state) =>
    action('set passwordControl open state', () => {
      this.state.isPasswordControlVisible = state;
    });

  toggleRemovePasswordControl = (state) =>
    action('set removePasswordControl open state', () => {
      this.state.isRemovePasswordControlVisible = state;
    });

  toggleDestroyLinkControl = (state) =>
    action('set destroyLinkControl open state', () => {
      this.state.isDestroyLinkControlVisible = state;
    });

  toggleShareByEmailControl = (state) =>
    action('set shareByEmailControl open state', () => {
      this.state.isShareByEmailControlVisible = state;
    });

  handleEmailMessageChange = (event) => {
    this.state.emailMessage = event.currentTarget.value;
  };

  hideShareByEmailControl = () => {
    this.state.isShareByEmailControlVisible = false;
  };

  handleShareEmailSubmit = async () => {
    const { emails, emailMessage } = this.state;

    if (contentContainsURL(emailMessage)) {
      notificationStore.showNotification({
        message: `The share message cannot contain a URL.`,
        variant: 'warning'
      });
      return;
    }

    shareService
      .sendShareEmail(this.state.diagram.shareId, {
        emails,
        message: emailMessage
      })
      .then(() => {
        notificationStore.showSuccess('The email has been sent.');
        runInAction(() => {
          this.state.emails = [];
          this.state.emailMessage = '';
        });
        this.hideShareByEmailControl();
      })
      .catch(({ errors }) => {
        this.handleErrorFromBackend(ERRORS[errors[0].reason]);
      });
  };

  /**
   * Displays an error message to the user.
   *
   * @param {String} error The error message to display.
   */
  handleErrorFromBackend(error) {
    notificationStore.showNotification({
      message: error ? error : 'Oops, there was a problem trying to send share emails, try again or contact us.',
      variant: 'error'
    });
  }

  saveSharePassword = (shareId, password) => {
    return shareService
      .createPassword(shareId, { password })
      .catch(() => notificationStore.showError('Could not create a password for this share.'));
  };
}

export default new ShareStore();
