/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autorun, runInAction } from 'mobx';

import { userStore } from 'stores';
import { authService } from 'services';
import { JWT_ORGANIZATION_CLAIM } from 'utils/constants';

import BaseOrganizationStore from './OrganizationStore.common';

const ELEVATED_ACCESS_ROLES = ['owner', 'admin'];

class OrganizationStore extends BaseOrganizationStore {
  constructor() {
    super();

    autorun((reaction) => {
      if (userStore.isAuthenticated) {
        this.#init();
        reaction.dispose();
      }
    });

    autorun(() => {
      if (this.currentOrganizationId) {
        this.#setHasElevatedOrganizationPermissions();
      }
    });
  }

  /**
   * Performs custom initialization routines
   * @return {void}
   */
  #init() {
    // Retrieving organizations directly from jwtUser, instead of userStore, that could be not updated due to mobx sync
    this.organizations = authService.jwtUser ? authService.jwtUser[JWT_ORGANIZATION_CLAIM] : [];
    this.fetchUserOrganizationsFromConsole();
    this.setOrganization();
    this.#setHasElevatedOrganizationPermissions();
  }

  #setHasElevatedOrganizationPermissions() {
    const userRolesInCurrentOrganization =
      this.organizations?.find((org) => org.id === this.currentOrganizationId)?.roles ?? [];

    runInAction(
      () =>
        (this.hasElevatedOrganizationPermissions = userRolesInCurrentOrganization.some((value) =>
          ELEVATED_ACCESS_ROLES.includes(value)
        ))
    );
  }

  /**
   * Compares the current organization with a given ID and switches
   * the context if they are not identical.
   * @param {string} organizationId
   * @return {void}
   */
  compareAndSwitchOrganization = (organizationId) => {
    if (organizationId !== this.currentOrganization.id) {
      this.setOrganization(organizationId);
    }
  };

  /**
   * True, if the given hostname is allowed to send invites
   * @param {string} hostname
   * @return {boolean}
   */
  isAllowedHostnameForInvites(hostname) {
    const configuredHostnames = this.currentOrganizationInfo?.limits?.allowedHostnamesForInvites;
    return !configuredHostnames ? true : configuredHostnames.split(',').includes(hostname);
  }

  /**
   * True, if the user should see the cluster creation journey, or the Deploy/Run modal directly
   * @return {boolean}
   */
  shouldSeeClusterCreationJourney() {
    return !this.isTrialExpired;
  }
}

export default new OrganizationStore();
