/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as CarbonButton } from '@carbon/react';
import { ChevronDown, Book } from '@carbon/icons-react';

import { Dropdown, FileUpload } from 'components';
import { breadcrumbStore, projectStore, idpApplicationStore, processApplicationStore } from 'stores';
import { Button, Loader } from 'primitives';
import {
  BPMN,
  CONNECTOR_TEMPLATE,
  DMN,
  FOLDER,
  FORM,
  IDP_APPLICATION,
  PROCESS_APPLICATION,
  README
} from 'utils/constants';
import {
  ConnectorTemplate,
  Diagram,
  DiagramWithFolder as ProcessApplicationIcon,
  IdpApplicationIcon,
  FileUpload as FileUploadIcon,
  Folder,
  Form,
  Table
} from 'icons';
import buildSlug from 'utils/buildSlug';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { useIsIdpEnabled } from 'App/Pages/Project/IdpApplication/idpFeatureFlag';
import { getEntityLink } from 'utils/helpers';

import * as Styled from './ProjectActionButton.styled';

export default function ProjectActionButton({
  onUpload,
  variant,
  children,
  label = 'Create new',
  processApplicationId,
  isReadmePresent
}) {
  const entityIconSize = 16;

  /** @type {[string | null, (type: string | null) => void]} */
  const [isCreatingFileType, setIsCreatingFileType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const fileUploadRef = useRef();

  const handleCreateBpmn = async () => {
    setIsCreatingFileType(BPMN);

    const diagram = await projectStore.createFile({ type: BPMN });

    setIsCreatingFileType(null);

    if (diagram) {
      breadcrumbStore.toggleEditingFor('diagram');
      history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
    }
  };

  const handleUpload = (files, source) => {
    setAnchorEl(null);

    onUpload(files, source);
  };

  const handleCreateForm = async () => {
    setIsCreatingFileType(FORM);

    const form = await projectStore.createFile({ type: FORM });

    setIsCreatingFileType(null);

    if (form) {
      breadcrumbStore.toggleEditingFor('form');
      history.push(`/forms/${buildSlug(form.id, form.name)}`);
    }
  };

  const handleCreateConnector = async () => {
    if (processApplicationStore?.processApplication?.id) {
      history.push(
        `/projects/${projectStore.project?.id}/${processApplicationStore.processApplication.id}/create-new-element-template`
      );
    } else {
      history.push(`/projects/${projectStore.project?.id}/create-new-element-template`);
    }
  };

  const handleCreateFolder = async () => {
    setIsCreatingFileType(FOLDER);

    const folder = await projectStore.createFolder();

    setIsCreatingFileType(null);

    if (folder) {
      breadcrumbStore.toggleEditingFor('folder');
      history.push(`/folders/${buildSlug(folder.id, folder.name)}`);
    }
  };

  const handleCreateDecision = async () => {
    setIsCreatingFileType(DMN);

    const diagram = await projectStore.createFile({ type: DMN });

    setIsCreatingFileType(null);

    if (diagram) {
      breadcrumbStore.toggleEditingFor('diagram');
      history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
    }
  };

  const handleCreateReadme = async () => {
    setIsCreatingFileType(README);

    const readme = await projectStore.createFile({ type: README });

    setIsCreatingFileType(null);

    if (readme) {
      history.push(getEntityLink(readme));
    }
  };

  const handleCreateProcessApplication = () => {
    setIsCreatingFileType(PROCESS_APPLICATION);
    setAnchorEl(null);
    processApplicationStore.startCreation();
    setIsCreatingFileType(null);
  };

  const handleCreateIdpApplication = () => {
    setIsCreatingFileType(IDP_APPLICATION);
    setAnchorEl(null);
    idpApplicationStore.startCreation();
    setIsCreatingFileType(null);
  };

  const WithLoader = ({ type, Icon, label }) =>
    isCreatingFileType === type ? (
      <>
        {/* @ts-expect-error TS2769 */}
        <Loader size="small" />
        {label}
      </>
    ) : (
      <>
        <Icon width={entityIconSize} height={entityIconSize} />
        {label}
      </>
    );

  return (
    <Fragment>
      {variant === 'carbon' ? (
        <CarbonButton
          kind="ghost"
          renderIcon={ChevronDown}
          iconDescription={label}
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="diagram-dropdown"
        >
          {label}
        </CarbonButton>
      ) : (
        // @ts-expect-error TS2322
        <Button
          dropdown
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="diagram-dropdown"
          className={variant !== 'primary' && 'project-action'}
          variant={variant}
        >
          {label}
        </Button>
      )}
      <Dropdown open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {children?.({ closeDropdown: () => setAnchorEl(null) })}
        <Dropdown.ListGroup title="Blank resources">
          {!processApplicationId && (
            // @ts-expect-error TS2739
            <Dropdown.ListItem
              data-test="create-process-application"
              onClick={handleCreateProcessApplication}
              disabled={isCreatingFileType === PROCESS_APPLICATION}
            >
              <Styled.ProjectActionItem>
                <WithLoader type={PROCESS_APPLICATION} label="Process application" Icon={ProcessApplicationIcon} />
              </Styled.ProjectActionItem>
            </Dropdown.ListItem>
          )}
          {useIsIdpEnabled() && !processApplicationId && (
            // @ts-expect-error TS2739
            <Dropdown.ListItem
              data-test="create-idp-application"
              onClick={handleCreateIdpApplication}
              disabled={isCreatingFileType === IDP_APPLICATION}
            >
              <Styled.ProjectActionItem>
                <WithLoader type={IDP_APPLICATION} label="IDP application" Icon={IdpApplicationIcon} />
              </Styled.ProjectActionItem>
            </Dropdown.ListItem>
          )}
          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem
            data-test="create-bpmn-diagram"
            onClick={() => {
              dedicatedModesStore.resetViewModeIndexForPlay();
              handleCreateBpmn();
            }}
            disabled={isCreatingFileType === BPMN}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={BPMN} label="BPMN diagram" Icon={Diagram} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>

          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem
            data-test="create-dmn-diagram"
            onClick={handleCreateDecision}
            disabled={isCreatingFileType === DMN}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={DMN} label="DMN diagram" Icon={Table} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>

          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem data-test="create-form" onClick={handleCreateForm} disabled={isCreatingFileType === FORM}>
            <Styled.ProjectActionItem>
              <WithLoader type={FORM} label="Form" Icon={Form} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>

          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem
            data-test="create-connector-template"
            onClick={handleCreateConnector}
            disabled={isCreatingFileType === CONNECTOR_TEMPLATE}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={CONNECTOR_TEMPLATE} label="Connector template" Icon={ConnectorTemplate} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>

          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem
            data-test="create-readme"
            onClick={handleCreateReadme}
            disabled={isReadmePresent || isCreatingFileType === README}
            title={isReadmePresent ? 'This scope already contains a README' : undefined}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={README} label="README" Icon={Book} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>
        </Dropdown.ListGroup>

        {!processApplicationId && (
          // @ts-expect-error TS2741
          <Dropdown.ListGroup>
            {/* @ts-expect-error TS2739 */}
            <Dropdown.ListItem
              data-test="create-folder"
              onClick={handleCreateFolder}
              disabled={isCreatingFileType === FOLDER}
            >
              <Styled.ProjectActionItem>
                <WithLoader type={FOLDER} label="Folder" Icon={Folder} />
              </Styled.ProjectActionItem>
            </Dropdown.ListItem>
          </Dropdown.ListGroup>
        )}

        {/* @ts-expect-error TS2741 */}
        <Dropdown.ListGroup>
          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem onClick={() => fileUploadRef.current.selectFile()}>
            <Styled.ProjectActionItem>
              <FileUploadIcon width={entityIconSize} height={entityIconSize} />
              <Styled.FileUploadContainer>
                <FileUpload
                  ref={fileUploadRef}
                  // @ts-expect-error TS2322
                  onFiles={handleUpload}
                  label="Upload files"
                  multiple
                  accept=".bpmn, .dmn, .xml, .json, .form"
                />
              </Styled.FileUploadContainer>
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>
        </Dropdown.ListGroup>
      </Dropdown>
    </Fragment>
  );
}
