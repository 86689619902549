/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Documentation } from 'icons';

export const DocumentationIcon = styled(Documentation)`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const Content = styled.div`
  overflow-x: auto;
  max-height: 400px;
  scrollbar-color: rgba(255, 255, 255, 0.8) transparent;
  scroll-width: thin;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  @media (max-height: 450px) {
    max-height: 80vh;
  }

  > a {
    color: white;
    text-decoration: none;
    border-bottom: 1px dotted white;

    &:hover {
      border-bottom-style: solid;
    }
  }
`;
