/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autorun, observable, action, computed, makeObservable } from 'mobx';

import RealtimeChannel from 'utils/RealtimeChannel';
import { transportCarrier } from 'transports';
import createPermission from 'utils/createPermission';
import { currentDiagramStore, attentionGrabberStore, userStore, notificationStore, commentsStore } from 'stores';

const DEFAULT_STATE = {
  members: [],
  newCollaborator: null
};

class RealtimeCollaborationStore {
  constructor() {
    makeObservable(this, {
      state: observable,
      onlineCollaborators: computed,
      hasOnlineCollaborators: computed,
      reset: action('reset state'),
      refreshMembers: action('refresh members'),
      addMember: action('add member'),
      removeMember: action('remove member'),
      clearNewCollaborator: action('clear new collaborator')
    });

    this.#observeStores();
  }

  #observeStores() {
    autorun((reaction) => {
      if (userStore.userId) {
        this.#setSocket();
        reaction.dispose();
      }
    });
  }

  #setSocket() {
    this.socket = transportCarrier.socket;
  }

  initializedSocket = false;

  state = Object.assign({}, DEFAULT_STATE);

  get onlineCollaborators() {
    const filteredList = [];

    // This code block tries to avoid duplicate entries
    // in the member list.y
    this.state.members.forEach((member) => {
      if (!filteredList.some((item) => item.id === member.id)) {
        filteredList.push(member);
      }
    });

    return filteredList;
  }

  get hasOnlineCollaborators() {
    // check if members is > 1, because I am a member too
    return this.state.members.length > 1;
  }

  reset = () => {
    this.unsubscribeSocket();
    this.presenceChannel = null;
    this.diagramUpdateChannel = null;
    this.attentionGrabberChannel = null;
    this.state = Object.assign({}, DEFAULT_STATE);
  };

  refreshMembers = (members) => {
    members.each((member) => this.state.members.push(this.createCollaborator(member)));
  };

  addMember = (newMember) => {
    this.notifyMember(newMember, 'joined');

    this.state.members.push(this.createCollaborator(newMember));
  };

  removeMember = (leaver) => {
    this.notifyMember(leaver, 'left');

    this.state.members = this.state.members.filter((existingMember) => !(existingMember.id === leaver.info.userId));

    const attentionGrabberPayload = {
      payload: JSON.stringify({ authorUserId: leaver.info.userId })
    };
    this.deleteAttentionGrabber(attentionGrabberPayload);
  };

  notifyMember = (member, action) => {
    const collaborator = this.createCollaborator(member);
    if (this.permission.is(['ADMIN', 'WRITE', 'COMMENT'])) {
      notificationStore.showNotification({
        message: `${collaborator.fullName} ${action} the diagram!`
      });
    }
  };

  clearNewCollaborator = () => {
    this.state.newCollaborator = undefined;
  };

  init = ({ permission }) => {
    autorun((reaction) => {
      if (userStore.userId) {
        this.reset();
        this.permission = permission;

        // TODO: #1048 fix the order of the initialization to make sure we always have the diagram
        if (currentDiagramStore.state.diagram?.id) {
          this.initSocket();
          this.subscribeChannels();
        }

        reaction.dispose();
      }
    });
  };

  initSocket() {
    const fileId = currentDiagramStore.state.diagram.id;

    this.presenceChannel = new RealtimeChannel(`presence-diagram-${fileId}`, {
      'pusher:subscription_succeeded': this.refreshMembers,
      'pusher:member_added': this.addMember,
      'pusher:member_removed': this.removeMember
    });

    this.diagramUpdateChannel = new RealtimeChannel(`private-diagram-update-${fileId}`, {
      'diagram:update': this.updateDiagram
    });

    this.commentChannel = new RealtimeChannel(`private-comments-${fileId}`, {
      'comment:add': this.updateComment,
      'comment:edit': this.updateComment,
      'comment:remove': this.updateComment
    });

    this.attentionGrabberChannel = new RealtimeChannel(`private-diagram-attention-${fileId}`, {
      'diagram:attention:create': this.createAttentionGrabber,
      'diagram:attention:delete': this.deleteAttentionGrabber
    });

    this.initializedSocket = true;
  }

  subscribeChannels = () => {
    this.presenceChannel.subscribe();
    this.diagramUpdateChannel.subscribe();
    this.attentionGrabberChannel.subscribe();
    this.commentChannel.subscribe();
  };

  unsubscribeSocket = () => {
    if (this.initializedSocket) {
      this.presenceChannel.unsubscribe();
      this.diagramUpdateChannel.unsubscribe();
      this.attentionGrabberChannel.unsubscribe();
      this.commentChannel.unsubscribe();
      this.initializedSocket = false;
    }
  };

  createCollaborator = (member) => {
    return {
      id: member.info.userId,
      fullName: member.info.fullName,
      email: member.info.email
    };
  };

  deleteAttentionGrabber = ({ payload }) => {
    attentionGrabberStore.deleteOverlayFromSocket(payload);
  };

  createAttentionGrabber = ({ payload }) => {
    const { project } = currentDiagramStore.state;
    const permission = createPermission(project && project.permissionAccess);

    if (permission.is(['ADMIN', 'WRITE', 'COMMENT'])) {
      attentionGrabberStore.createOverlayFromSocket(payload);
    }
  };

  updateComment = ({ payload, type }) => {
    const parsedComment = JSON.parse(payload);

    if (userStore.isCurrentOriginAppInstanceId(parsedComment.originAppInstanceId)) {
      return;
    }

    commentsStore.refreshCommentList(parsedComment, type);
  };

  updateDiagram = async (message) => {
    const payload = JSON.parse(message.payload);
    const { fileId, user, originAppInstanceId, revision: updateRevision } = payload;

    // since the originating user also gets this update event,
    // if the event emitter is me, stop here.
    if (userStore.isCurrentOriginAppInstanceId(originAppInstanceId)) {
      return;
    }

    const currentDiagram = currentDiagramStore.state.diagram;

    // only react on updates for current diagram
    if (!currentDiagram || fileId !== currentDiagram.id) {
      return;
    }

    // only react on updates for revisions higher than current
    // update message
    if (currentDiagramStore.isDraggingActive) {
      currentDiagramStore.receivedUpdateWhileDragging = true;
    } else {
      if (parseInt(updateRevision, 10) > parseInt(currentDiagram.revision, 10)) {
        await currentDiagramStore.reloadDiagram(currentDiagram.id);
      }

      notificationStore.showNotification({
        message: `${user.name} modified the diagram!`
      });
    }
  };
}

export default new RealtimeCollaborationStore();
