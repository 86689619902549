/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from 'services/Service';

class BpmnCopilotService extends Service {
  constructor() {
    super();

    this.invokeBpmnCopilot = this.invokeBpmnCopilot.bind(this);
  }

  invokeBpmnCopilot(payload, signal) {
    return this.post(`/bpmncopilot`, payload, null, null, signal);
  }
}

export default new BpmnCopilotService();
