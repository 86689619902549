/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Warning } from 'icons';
import * as Styled from 'components/MultiSelectInput/MultiSelectInput.styled';

export default function getSuggestionSubtitle(suggestion) {
  return (
    suggestion.email ?? (
      <Styled.WarningContainer>
        <div>
          <Warning title="Warning" />
        </div>
        <div>
          <Styled.WarningMessage>{`${
            suggestion.username ?? 'This user'
          } doesn't have an email address associated with their account.`}</Styled.WarningMessage>
          <Styled.WarningAction>
            Please contact your administrator for assistance in adding them to the project.
          </Styled.WarningAction>
        </div>
      </Styled.WarningContainer>
    )
  );
}
