/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Stack, Grid, Column, CheckboxGroup, Checkbox, Link } from '@carbon/react';
import PropTypes from 'prop-types';

import * as Styled from './CreateNewElementTemplate.styled';

/**
 * A React component that manages a hierarchical structure of checkboxes.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.checkboxes - The current state of the checkboxes, structured as a hierarchical object.
 * @param {Object} props.checkboxes.parent1 - The first parent checkbox object.
 * @param {boolean} props.checkboxes.parent1.selected - Indicates if the parent1 checkbox is selected.
 * @param {boolean} props.checkboxes.parent1.indeterminate - Indicates if the parent1 checkbox is in an indeterminate state.
 * @param {Object} props.checkboxes.parent1.children - The children checkboxes under parent1.
 * @param {Object} props.checkboxes.parent1.children.child1 - The first child checkbox under parent1.
 * @param {boolean} props.checkboxes.parent1.children.child1.selected - Indicates if the child1 checkbox is selected.
 * @param {Object} props.checkboxes.parent1.children.child2 - The second child checkbox under parent1.
 * @param {boolean} props.checkboxes.parent1.children.child2.selected - Indicates if the child2 checkbox is selected.
 *
 * @param {Function} props.setCheckboxes - A function to update the state of the checkboxes.
 *
 * @returns {JSX.Element} The rendered component.
 */
export function MultiLevelCheckbox({ checkboxes, setCheckboxes }) {
  const handleParentCheckboxChange = (parentKey) => {
    setCheckboxes((prevState) => {
      const isSelected = !prevState[parentKey].selected;
      const updatedChildren = Object.keys(prevState[parentKey].children).reduce((result, childKey) => {
        const child = prevState[parentKey].children[childKey];
        if (child.supported !== false) {
          child.selected = isSelected;
        }
        result[childKey] = child;
        return result;
      }, {});

      return {
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          selected: isSelected,
          indeterminate: false,
          children: updatedChildren
        }
      };
    });
  };

  const parentCheckboxHasSelectedChild = (parent, childKey) => {
    return Object.keys(parent.children)
      ?.filter((currentChildKey) => currentChildKey !== childKey)
      ?.some((currentChildKey) => parent.children[currentChildKey].selected);
  };

  const parentCheckboxHasUnselectedChild = (parent, childKey) => {
    return Object.keys(parent.children)
      ?.filter((currentChildKey) => currentChildKey !== childKey)
      ?.some((currentChildKey) => !parent.children[currentChildKey].selected);
  };

  const handleChildCheckboxChange = (parentKey, childKey) => {
    const parent = checkboxes[parentKey];
    const newIsChildSelected = !parent.children[childKey].selected;
    let isParentSelected = parent.selected;
    let isParentIndeterminate = parent.indeterminate;
    if (newIsChildSelected) {
      // child checkbox will be set to selected
      // parent is NOT selected
      if (parentCheckboxHasUnselectedChild(parent, childKey)) {
        // has some child which are NOT selected
        // indeterminate state
        isParentIndeterminate = true;
        isParentSelected = true;
      } else {
        // all children are selected
        isParentIndeterminate = false;
        isParentSelected = true;
      }
    } else {
      // child checkbox will be set to NOT selected
      // parent is selected
      if (parentCheckboxHasSelectedChild(parent, childKey)) {
        // has some child which are selected
        // indeterminate state
        isParentIndeterminate = true;
        isParentSelected = true;
      } else {
        // all children are NOT selected
        isParentIndeterminate = false;
        isParentSelected = false;
      }
    }
    setCheckboxes((prevState) => ({
      ...prevState,
      [parentKey]: {
        ...prevState[parentKey],
        selected: isParentSelected,
        indeterminate: isParentIndeterminate,
        children: {
          ...prevState[parentKey].children,
          [childKey]: {
            ...prevState[parentKey].children[childKey],
            selected: !prevState[parentKey].children[childKey].selected
          }
        }
      }
    }));
  };

  const numberOfMethods = (checkboxes) => {
    let numberOfMethods = 0;
    Object.keys(checkboxes).forEach((parentKey) => {
      numberOfMethods += Object.keys(checkboxes[parentKey].children).length;
    });
    return numberOfMethods;
  };

  const numberOfSelectedMethods = (checkboxes) => {
    let numberOfMethods = 0;
    Object.keys(checkboxes).forEach((parentKey) => {
      Object.keys(checkboxes[parentKey].children).forEach((childKey) => {
        if (checkboxes[parentKey].children[childKey].selected) {
          numberOfMethods++;
        }
      });
    });
    return numberOfMethods;
  };

  const selectAllCheckboxes = () => {
    setCheckboxes((prevState) => {
      Object.keys(prevState).forEach((parentKey) => {
        const parent = prevState[parentKey];
        Object.keys(parent.children).forEach((childKey) => {
          if (parent.children[childKey].supported) {
            parent.children[childKey].selected = true;
          }
        });
        if (parentCheckboxHasUnselectedChild(parent, null)) {
          // has some child which are NOT selected
          if (parentCheckboxHasSelectedChild(parent, null)) {
            // indeterminate state
            parent.indeterminate = true;
            parent.selected = true;
          } else {
            // no child is selected
            parent.indeterminate = false;
            parent.selected = false;
          }
        } else {
          // all children are selected
          parent.indeterminate = false;
          parent.selected = true;
        }
      });
      return {
        ...prevState
      };
    });
  };

  const deselectAllCheckboxes = () => {
    setCheckboxes((prevState) => {
      Object.keys(prevState).forEach((parentKey) => {
        Object.keys(prevState[parentKey].children).forEach((childKey) => {
          prevState[parentKey].children[childKey].selected = false;
        });
        prevState[parentKey].selected = false;
        prevState[parentKey].indeterminate = false;
      });
      return {
        ...prevState
      };
    });
  };

  return (
    <div>
      <div>
        <Stack gap={4}>
          <Grid>
            <Column span={16}>
              <Styled.Divider />
            </Column>
          </Grid>
          <Grid>
            <Column span={16}>
              <h4>Select methods</h4>
            </Column>
          </Grid>
          <Grid>
            <Column span={10}>
              {numberOfSelectedMethods(checkboxes)}/{numberOfMethods(checkboxes)} methods selected
            </Column>
            <Column span={6}>
              <Grid>
                <Column span={2}>
                  <Link onClick={() => selectAllCheckboxes()}>Select all</Link>
                </Column>
                <Column span={3}>
                  <Link onClick={() => deselectAllCheckboxes()}>Deselect all</Link>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </Stack>
        <Grid>
          <Column span={16}>
            <Styled.Divider />
          </Column>
        </Grid>
      </div>
      <div>
        {Object.keys(checkboxes).map((parentKey) => (
          <CheckboxGroup key={parentKey} legendText={''}>
            <Checkbox
              disabled={checkboxes[parentKey].supported === false}
              id={parentKey}
              labelText={parentKey}
              checked={checkboxes[parentKey].selected}
              indeterminate={checkboxes[parentKey].indeterminate}
              onChange={() => handleParentCheckboxChange(parentKey)}
            />
            <Styled.NestedCheckboxChildContainer>
              {Object.keys(checkboxes[parentKey].children).map((childKey) => (
                <Styled.MethodCheckbox
                  disabled={checkboxes[parentKey].children[childKey].supported === false}
                  key={childKey}
                  id={childKey}
                  labelText={
                    // @ts-expect-error TS2322
                    <Stack span={16}>
                      <Grid
                        condensed
                        // @ts-expect-error TS2322
                        span={16}
                      >
                        <Column span={2}>
                          <Styled.MethodTag size="sm">
                            {checkboxes[parentKey].children[childKey].method}
                          </Styled.MethodTag>
                        </Column>
                        <Column span={7}>{checkboxes[parentKey].children[childKey].path}</Column>
                        <Column span={7}>
                          {checkboxes[parentKey].children[childKey].supported === false && (
                            <Styled.MethodNotSupported>
                              <Styled.RedErrorFilledIcon /> <span>Method not supported</span>
                            </Styled.MethodNotSupported>
                          )}
                        </Column>
                      </Grid>
                    </Stack>
                  }
                  checked={checkboxes[parentKey].children[childKey].selected}
                  onChange={() => handleChildCheckboxChange(parentKey, childKey)}
                />
              ))}
            </Styled.NestedCheckboxChildContainer>
          </CheckboxGroup>
        ))}
      </div>
    </div>
  );
}

MultiLevelCheckbox.propTypes = {
  checkboxes: PropTypes.object.isRequired,
  setCheckboxes: PropTypes.func.isRequired
};

export default observer(MultiLevelCheckbox);
