/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const isCalledElement = (type) => {
  return type === 'zeebe:CalledElement' || type === 'zeebe:calledElement';
};

export const getCalledElement = (extensionElements) => {
  return extensionElements?.get('values').filter((elem) => {
    return isCalledElement(elem.$type);
  })[0];
};

export const getCalledElementProcessId = (extensionElements) => {
  const calledElement = getCalledElement(extensionElements);
  if (calledElement?.processId) {
    return calledElement?.processId?.trim();
  }
};
