/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Tag } from '@carbon/react';
import { v4 } from 'uuid';
import { observer } from 'mobx-react';
import DOMPurify from 'dompurify';
import { ArrowUp, StopFilledAlt } from '@carbon/icons-react';

import { Cross } from 'icons';
import { tracingService, trackingService } from 'services';
import useFocusTrap from 'hooks/useFocusTrap';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import usePositioning from 'primitives/NonModalDialog/usePositioning';
import {
  bpmnCopilotClient,
  wasRouteRequestCancelled,
  wouldRouteModifyDiagram
} from 'experiments/ai-features/bpmn-copilot/bpmn-copilot-client';
import {
  ATTEMPTED_DIAGRAM_CHANGES_ERROR_MESSAGE,
  getCopilotDescription,
  getErrorMessage,
  getExamplePrompt1,
  getExamplePrompt2,
  getFirstResponseMessage,
  getPlaceholderText
} from 'experiments/ai-features/bpmn-copilot/bpmn-copilot-copy';

import * as Styled from './CamundaAiChatbox.styled';
import Messages from './Messages';

const CamundaAiChatbox = ({
  onClose,
  isOpen,
  location,
  buttonRef,
  modeler,
  saveDiagramContent,
  createNewVersion,
  isAdminOrEditor,
  diagramId,
  isRoutingEnabled = false
}) => {
  const [text, setText] = useState('');
  const [noSubmitsAttempted, setNoSubmitsAttempted] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [messages, setMessages] = useState([]);

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const chatboxBodyRef = useRef(null);
  const cancelFunction = useRef(null);

  useEffect(() => {
    if (!chatboxBodyRef.current) {
      return;
    }

    chatboxBodyRef.current.scrollTop = chatboxBodyRef.current.scrollHeight;
  }, [messages]);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, { id: v4(), ...message }]);
  };

  const submitExamplePrompt = (e, promptNumber) => {
    trackingService.trackBpmnCopilotExamplePromptClick({ from: location, promptNumber });
    prepareRequestAndSubmit(e.target.textContent);
  };

  const handleSubmitButtonClick = async () => {
    if (isProcessing) {
      cancel();
    } else {
      prepareRequestAndSubmit(null);
    }
  };

  const cancel = useCallback(() => {
    setIsProcessing(false);
    if (cancelFunction.current) {
      cancelFunction.current();
    }
  }, []);

  const onSubmit = useCallback(
    async (overrideText) => {
      if (typeof overrideText !== 'string' && text.trim().length === 0) {
        return;
      }

      const textPrompt = typeof overrideText === 'string' ? overrideText : text;

      addMessage({ type: 'human', text: textPrompt });
      setIsProcessing(true);
      setText('');

      if (noSubmitsAttempted) {
        addMessage({ type: 'ai', text: getFirstResponseMessage(isAdminOrEditor) });
        setNoSubmitsAttempted(false);
      }

      let responseMessage;
      try {
        trackingService.trackBpmnCopilotSubmit({ from: location });

        // if routing is not enabled, we cannot support read-only users because
        // the Copilot will default to editing the diagram
        if (!isRoutingEnabled && !isAdminOrEditor) {
          addMessage({ type: 'ai', text: ATTEMPTED_DIAGRAM_CHANGES_ERROR_MESSAGE });
          setIsProcessing(false);
          return;
        }

        let route;
        let areDiagramChangesExpected = true;
        if (isRoutingEnabled) {
          const { response: routeResponse, cancel } = await bpmnCopilotClient.getRoute({
            modeler,
            textPrompt
          });

          cancelFunction.current = cancel;

          route = await routeResponse;

          if (wasRouteRequestCancelled(route)) {
            return;
          }

          areDiagramChangesExpected = wouldRouteModifyDiagram(route);

          if (!isAdminOrEditor && areDiagramChangesExpected) {
            addMessage({ type: 'ai', text: ATTEMPTED_DIAGRAM_CHANGES_ERROR_MESSAGE });
            setIsProcessing(false);
            return;
          }
        }

        const invokeResponse = await bpmnCopilotClient.invoke({
          modeler,
          createNewVersion,
          textPrompt,
          route,
          preventModelerEdit: !isAdminOrEditor
        });

        const { response: copilotResponse, cancel } = invokeResponse;

        cancelFunction.current = cancel;

        responseMessage = await copilotResponse;

        if (areDiagramChangesExpected) {
          saveDiagramContent();
        }
      } catch (e) {
        console.error('Invoking BPMN Copilot failed');
        tracingService.traceError(e, 'Invoking BPMN Copilot failed');
        trackingService.trackBpmnCopilotError({ from: location, error: e });

        responseMessage = getErrorMessage(e);
      }

      if (responseMessage) {
        const sanitizedMessage = DOMPurify.sanitize(responseMessage);
        addMessage({ type: 'ai', text: sanitizedMessage });
      }

      setIsProcessing(false);
    },
    [
      text,
      noSubmitsAttempted,
      isAdminOrEditor,
      location,
      isRoutingEnabled,
      modeler,
      createNewVersion,
      saveDiagramContent
    ]
  );

  const prepareRequestAndSubmit = useCallback(
    (overrideText = null) => {
      onSubmit(overrideText);
    },
    [onSubmit]
  );

  const onTextareaKeydown = (event) => {
    const { code, shiftKey } = event;

    if (code !== 'Enter') {
      return;
    }

    event.preventDefault();

    if (shiftKey) {
      setText((value) => `${value}\n`);
    } else if (!isProcessing) {
      handleSubmitButtonClick();
    }
  };

  const textboxRef = useRef(null);

  const onSubmitKeydown = (event) => {
    const { code, shiftKey } = event;

    if (code !== 'Tab') {
      return;
    }

    event.preventDefault();

    if (shiftKey) {
      textboxRef?.current?.focus();
    } else {
      docsAiRef?.current?.focus();
    }
  };

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const tileRef = useRef();
  useFocusTrap({ open: isOpen, ref: tileRef, selectorPrimaryFocus: 'textarea' });

  const onChatboxKeyDown = useKeyboardTrap({ onClose, ref: tileRef });

  const position = usePositioning({
    dialogEl: tileRef?.current,
    anchorEl: buttonRef?.current,
    justify: 'right',
    alignment: 'top'
  });

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const docsAiRef = useRef();

  return createPortal(
    <Styled.ChatboxTile
      $isOpen={isOpen}
      $height={450}
      $position={position}
      ref={tileRef}
      onKeyDown={onChatboxKeyDown}
      role="dialog"
      aria-label="Camunda AI Copilot"
    >
      <Styled.ChatboxHeader>
        <Styled.HeaderGroup orientation="horizontal" gap={3}>
          <Styled.CamundaCopilotHeader>Camunda Copilot</Styled.CamundaCopilotHeader>
          <Tag type="gray">alpha</Tag>
        </Styled.HeaderGroup>
        <Styled.HeaderSpace />
        <Styled.HeaderGroup orientation="horizontal">
          <Styled.DocsAiLink
            id="camunda-ai-button"
            tabIndex="0"
            ref={docsAiRef}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                docsAiRef?.current?.click();
              }
            }}
            onClick={() => {
              trackingService.trackDocsAIOpen({ enabled: true, from: location });
              onClose();
            }}
          >
            Docs AI
          </Styled.DocsAiLink>
          <Styled.CloseButton
            size="sm"
            kind="ghost"
            iconDescription="Close"
            hasIconOnly
            onClick={onClose}
            renderIcon={() => {
              return <Cross width="16px" height="16px" />;
            }}
          />
        </Styled.HeaderGroup>
      </Styled.ChatboxHeader>
      <Styled.ChatboxBody ref={chatboxBodyRef}>
        {messages.length === 0 && (
          <>
            <Styled.BodyGroup>
              <Styled.TextDescriptionSpan>
                {getCopilotDescription(isAdminOrEditor, diagramId)}
              </Styled.TextDescriptionSpan>
            </Styled.BodyGroup>
            <Styled.HeaderSpace />
            <Styled.BodyGroup>
              <h2>Example prompts</h2>
              <Styled.ExamplePromptGroup $isOpen={isOpen} gap={5} orientation="horizontal">
                <Styled.ExamplePrompt onClick={(e) => submitExamplePrompt(e, 1)}>
                  {getExamplePrompt1(isAdminOrEditor)}
                </Styled.ExamplePrompt>
                <Styled.ExamplePrompt onClick={(e) => submitExamplePrompt(e, 2)}>
                  {getExamplePrompt2(isAdminOrEditor)}
                </Styled.ExamplePrompt>
              </Styled.ExamplePromptGroup>
            </Styled.BodyGroup>
          </>
        )}

        <Messages messages={messages} isProcessing={isProcessing} />
        <Styled.HeaderSpace />
      </Styled.ChatboxBody>
      <Styled.ChatboxForm>
        <Styled.StyledTextArea
          id="copilot-chatbox-textarea"
          aria-label="copilot-chatbox-textarea"
          value={text}
          placeholder={getPlaceholderText(isAdminOrEditor)}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={onTextareaKeydown}
          ref={textboxRef}
        />
        <Styled.ButtonWrapper>
          <Styled.ChatboxSend
            hasIconOnly
            aria-label={isProcessing ? 'Cancel' : 'Send'}
            iconDescription={isProcessing ? 'Cancel' : 'Send'}
            size={'md'}
            kind={'secondary'}
            renderIcon={() => {
              return isProcessing ? <StopFilledAlt /> : <ArrowUp width="16px" height="16px" />;
            }}
            onKeyDown={onSubmitKeydown}
            onClick={handleSubmitButtonClick}
          />
        </Styled.ButtonWrapper>
      </Styled.ChatboxForm>
    </Styled.ChatboxTile>,
    document.body
  );
};

export default observer(CamundaAiChatbox);
