/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableHead,
  TableRow,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow
} from '@carbon/react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { containsSearchWord } from 'components/EntityTable/utils';
import { EmptyState } from 'primitives';

import * as Styled from './EntityTable.styled';

const IDENTITY_FN = (val) => val;

export default function EntityTable({
  action,
  batchActions = false,
  columns = [],
  emptyState = EmptyState,
  isLoading,
  rows = [],
  containerLabel,
  title,
  noMinWidth = false,
  tableSize = 'md',
  toolbarPlaceholder,
  isSearchPersistent = true,
  expandable = false,
  renderExpanded
}) {
  const columnsConfig = columns.reduce((config, column) => {
    config[column.key] = {
      noPadding: column.noPadding,
      overflowVisible: column.overflowVisible,
      renderer: column.renderer
    };
    return config;
  }, {});

  const sortingPositionByRow = rows.reduce((config, row) => {
    config[row.id] = row.__sortingPosition;
    return config;
  }, {});

  function getCellClassNames({ noPadding, overflowVisible }) {
    return `${noPadding ? 'no-padding' : ''}${noPadding && overflowVisible ? ' ' : ''}${overflowVisible ? 'overflow-visible' : ''}`;
  }

  function filterRows({ cellsById, getCellId, headers, inputValue, rowIds }) {
    const searchWord = inputValue.trim().toLowerCase();

    return rowIds.filter((rowId) =>
      headers.some((header) => {
        const cell = cellsById[getCellId(rowId, header.key)];
        if (header.renderer) {
          return header.renderer.containsSearchWord?.(cell?.value, searchWord) ?? false;
        }
        return containsSearchWord(cell?.value, searchWord);
      })
    );
  }

  function sortRows(a, b, { compare, key, rowIds: [rowIdA, rowIdB], sortDirection, sortStates }) {
    const sortingPositionA = sortingPositionByRow[rowIdA];
    const sortingPositionB = sortingPositionByRow[rowIdB];

    if (sortingPositionA !== sortingPositionB) {
      return sortingPositionA - sortingPositionB;
    }

    let compared = 0;

    const renderer = columnsConfig[key].renderer;
    if (renderer) {
      const comparableValueA = renderer.getComparableValue?.(a) ?? 0;
      const comparableValueB = renderer.getComparableValue?.(b) ?? 0;
      compared = compare(comparableValueA, comparableValueB);
    } else {
      compared = compare(a, b);
    }

    if (sortDirection === sortStates.DESC) {
      compared *= -1;
    }

    return compared;
  }

  const getSkeleton = useCallback((headers) => {
    const visibleHeaders = headers.filter((header) => header.header?.length > 0);
    return (
      <DataTableSkeleton
        data-test="entity-table-skeleton"
        columnCount={visibleHeaders.length}
        headers={visibleHeaders}
        showHeader={false}
      />
    );
  }, []);

  const removeKeyFromProps = (props) => {
    const { ...rest } = props;
    delete rest.key;
    return rest;
  };

  return (
    <DataTable
      rows={rows}
      // @ts-expect-error TS2322
      size={tableSize}
      headers={columns}
      filterRows={filterRows}
      // @ts-expect-error TS2322
      sortRow={sortRows}
      expandable={expandable}
    >
      {({
        rows: filteredRows,
        headers,
        getBatchActionProps,
        getHeaderProps,
        getRowProps,
        getSelectionProps,
        getTableProps,
        getToolbarProps,
        onInputChange,
        getTableContainerProps,
        selectAll,
        selectedRows,
        getExpandHeaderProps,
        getExpandedRowProps
      }) => {
        const batchActionProps = getBatchActionProps();
        const selection = selectedRows.map((selectedRow) => rows.find((row) => row.id === selectedRow.id));
        return (
          <Styled.TableContainer
            title={title}
            data-test={`table-container${containerLabel ? `-${containerLabel}` : ''}`}
            $noMinWidth={noMinWidth}
            {...getTableContainerProps()}
          >
            {/* @ts-expect-error TS2769 */}
            {isLoading ? (
              getSkeleton(headers)
            ) : rows.length === 0 ? (
              emptyState
            ) : (
              <>
                <TableToolbar {...getToolbarProps()} aria-label="entity table toolbar">
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      onChange={onInputChange}
                      persistent={isSearchPersistent}
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      placeholder={toolbarPlaceholder}
                    />
                    {action?.({
                      tabIndex: batchActionProps.shouldShowBatchActions ? -1 : 0
                    })}
                  </TableToolbarContent>
                  {batchActions && (
                    <TableBatchActions {...batchActionProps}>
                      {/* @ts-expect-error TS2339 */}
                      {batchActions.map((batchAction) => (
                        <TableBatchAction
                          key={batchAction?.title}
                          renderIcon={() => null}
                          tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                          disabled={!batchAction?.isAllowed?.(selection)}
                          // @ts-expect-error TS2554
                          onClick={(evt) => batchAction?.action?.(selection, () => selectAll(false), evt)}
                        >
                          {batchAction?.title}
                        </TableBatchAction>
                      ))}
                    </TableBatchActions>
                  )}
                </TableToolbar>
                <Table {...getTableProps()} data-test="entity-table" aria-label="entity table">
                  <TableHead>
                    <TableRow>
                      {expandable && <TableExpandHeader enableToggle {...getExpandHeaderProps()} />}
                      {batchActions && <TableSelectAll {...getSelectionProps()} aria-label="entity table select all" />}
                      {headers.map((header) => {
                        const { key, ...headerProps } = getHeaderProps({
                          header,
                          // @ts-expect-error TS2339
                          isSortable: header.sortable
                        });
                        return (
                          <Styled.TableHeader
                            key={key || header.key}
                            // @ts-expect-error TS2769
                            $customWidth={header.width}
                            // @ts-expect-error TS2339
                            $customMinWidth={header.minWidth}
                            {...headerProps}
                          >
                            {header.header}
                          </Styled.TableHeader>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((row) => (
                      <React.Fragment key={row.id}>
                        {expandable ? (
                          <TableExpandRow
                            key={row.id}
                            {...removeKeyFromProps(getRowProps({ row }))}
                            {...removeKeyFromProps(getExpandedRowProps({ row }))}
                            data-test={`expandable-row-${row.id}`}
                          >
                            {batchActions && (
                              <TableSelectRow
                                {...removeKeyFromProps(getSelectionProps({ row }))}
                                aria-label={`entity table select ${row.id}`}
                              />
                            )}
                            {row.cells.map((cell) => {
                              const columnConfig = columnsConfig[cell.info.header];
                              const rendererFn = columnConfig?.renderer ?? IDENTITY_FN;
                              const dataTest = rendererFn.getDataTest?.(cell.value) ?? undefined;
                              const noPadding = columnConfig?.noPadding ?? false;
                              const overflowVisible = columnConfig?.overflowVisible ?? false;
                              return (
                                <Styled.TableCell
                                  key={cell.id}
                                  className={getCellClassNames({
                                    noPadding,
                                    overflowVisible
                                  })}
                                  {...(dataTest ? { 'data-test': dataTest } : undefined)}
                                >
                                  {rendererFn(cell.value)}
                                </Styled.TableCell>
                              );
                            })}
                          </TableExpandRow>
                        ) : (
                          <TableRow
                            key={row.id}
                            {...removeKeyFromProps(getRowProps({ row }))}
                            data-test={`entity-${row.id}`}
                          >
                            {batchActions && (
                              <TableSelectRow
                                {...removeKeyFromProps(getSelectionProps({ row }))}
                                aria-label={`entity table select ${row.id}`}
                              />
                            )}
                            {row.cells.map((cell) => {
                              const columnConfig = columnsConfig[cell.info.header];
                              const rendererFn = columnConfig?.renderer ?? IDENTITY_FN;
                              const dataTest = rendererFn.getDataTest?.(cell.value) ?? undefined;
                              const noPadding = columnConfig?.noPadding ?? false;
                              const overflowVisible = columnConfig?.overflowVisible ?? false;
                              return (
                                <Styled.TableCell
                                  key={cell.id}
                                  className={getCellClassNames({
                                    noPadding,
                                    overflowVisible
                                  })}
                                  {...(dataTest ? { 'data-test': dataTest } : undefined)}
                                >
                                  {rendererFn(cell.value)}
                                </Styled.TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                        {expandable && (
                          <TableExpandedRow
                            key={`${row.id}-expanded`}
                            colSpan={columns.length + 1 + (batchActions ? 1 : 0)}
                            {...removeKeyFromProps(getExpandedRowProps({ row }))}
                          >
                            {renderExpanded?.(row)}
                          </TableExpandedRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </Styled.TableContainer>
        );
      }}
    </DataTable>
  );
}

EntityTable.propTypes = {
  action: PropTypes.func,
  batchActions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  columns: PropTypes.array.isRequired,
  emptyState: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  containerLabel: PropTypes.string,
  title: PropTypes.node,
  noMinWidth: PropTypes.bool,
  isSearchPersistent: PropTypes.bool,
  tableSize: PropTypes.string,
  toolbarPlaceholder: PropTypes.string,
  expandable: PropTypes.bool,
  renderExpanded: PropTypes.func
};
