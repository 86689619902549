/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled, { keyframes } from 'styled-components';

const ARROW_SIZE = 5;

const fade = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: ${({ $position }) => `${$position.top}px`};
  left: ${({ $position }) => `${$position.left}px`};
  border-radius: 4px;
  color: white;
  z-index: var(--z-tooltip);
  padding: 6px 8px;
  max-width: 380px;
  font-weight: 500;
  word-break: break-word;
  animation: ${fade} 0.2s ease-out forwards;

  ${({ color }) => {
    return `
      background-color: ${getColor(color)};
    `;
  }}

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    width: 0;
    height: 0;
    border: 5px solid transparent;

    ${({ $align, color }) => {
      switch ($align) {
        case 'bottom':
          return `
            border-bottom-color: ${getColor(color)};
            bottom: 100%;
          `;
        default:
          return `
            border-top-color: ${getColor(color)};
            top: 100%;
          `;
      }
    }}

    ${({ $justify }) => {
      switch ($justify) {
        case 'left':
          return `left: ${ARROW_SIZE}px;`;
        case 'right':
          return `right: ${ARROW_SIZE}px;`;
        default:
          return `
            left: 50%;
            transform: translateX(-50%);
          `;
      }
    }}
  }
`;

const getColor = (color) => (color === 'blue' ? 'var(--cds-blue-60)' : 'var(--grey-darken-33)');
