/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { v4 } from 'uuid';
import { Button, InlineLoading, TextInput } from '@carbon/react';

import { notificationStore } from 'components/NotificationSystem';
import { CONNECTOR_TEMPLATE_MAX_ICON_SIZE } from 'utils/constants';

import CreateNewElementTemplateFileUploadCommonFileItem from './CreateNewElementTemplateFileUploadCommonFileItem';
import CreateNewElementTemplateFileUploadCommon from './CreateNewElementTemplateFileUploadCommon';
import CreateNewElementTemplateService from './CreateNewElementTemplateService';
import * as Styled from './CreateNewElementTemplate.styled';

export function CreateNewElementTemplateFileUploadFromUrl({
  fileData,
  setFileData,
  fileSizeLimit = CONNECTOR_TEMPLATE_MAX_ICON_SIZE
}) {
  const [fileUrl, setFileUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccessfulImageUpload = (file, fileContent) => {
    // set status to complete
    file.fileContent = fileContent;
    const updatedFile = {
      ...file,
      status: 'complete',
      iconDescription: 'Upload complete'
    };
    setFileData(updatedFile);
    // show x icon after 1 second
    setTimeout(() => {
      const updatedFile = {
        ...file,
        status: 'edit',
        iconDescription: 'Delete file'
      };
      setFileData(updatedFile);
    }, 1000);
    setIsLoading(false);
  };

  const uploadFileFromUrl = async () => {
    try {
      if (!fileUrl) {
        return;
      }
      setIsLoading(true);
      const fetchedResponseAsBlob = await CreateNewElementTemplateService.fetchImageResource(fileUrl);
      const file = {
        uuid: v4(),
        name: fetchedResponseAsBlob?.name ? fetchedResponseAsBlob.name : fileUrl.split('/').pop(),
        fileSize: fetchedResponseAsBlob?.size ? fetchedResponseAsBlob.size : fetchedResponseAsBlob.length,
        status: 'uploading',
        iconDescription: 'Uploading'
      };
      // validations
      if (!CreateNewElementTemplateFileUploadCommon.isFileSizeValid(file, setFileData, fileSizeLimit)) {
        setIsLoading(false);
        return;
      }
      if (typeof fetchedResponseAsBlob === 'string') {
        // if it is a string, then we can safely assume it is an svg
        if (!fetchedResponseAsBlob.startsWith('<?xml') && !fetchedResponseAsBlob.startsWith('<svg')) {
          throw new Error('Fetched file is not a valid SVG!');
        }
        const decodedSvgString = decodeURIComponent(encodeURIComponent(fetchedResponseAsBlob));
        const fileContent = btoa(decodedSvgString);
        handleSuccessfulImageUpload(file, `data:image/svg+xml;base64,${fileContent}`);
      } else {
        // if it is a string, it is a png or jpeg
        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
          const fileContent = e.target.result;
          handleSuccessfulImageUpload(file, fileContent);
        });
        reader.readAsDataURL(fetchedResponseAsBlob);
      }
    } catch (e) {
      console.error(e);
      notificationStore.error(
        {
          title: 'Error',
          content: `Oops! We couldn't import the url. Please make sure the url points to the file.`
        },
        { shouldPersist: true }
      );
      setIsLoading(false);
    }
  };

  const getButton = () => {
    return isLoading ? (
      <InlineLoading size="sm" description="Loading..." status="active" />
    ) : (
      <Button kind="tertiary" size="md" disabled={!fileUrl} onClick={() => uploadFileFromUrl()}>
        Import icon
      </Button>
    );
  };

  return (
    <Styled.TextInputWithButtonContainer>
      <Styled.TextInputWithIconContainer>
        {!fileData && (
          <TextInput
            autocomplete="off"
            value={fileUrl}
            onChange={(event) => setFileUrl(event.target.value)}
            labelText={''}
            type="text"
            id="image-url"
            placeholder={'Add image URL'}
            invalid={false}
            disabled={false}
          />
        )}
        {fileData && <CreateNewElementTemplateFileUploadCommonFileItem fileData={fileData} setFileData={setFileData} />}
      </Styled.TextInputWithIconContainer>
      <div>{!fileData && getButton()}</div>
    </Styled.TextInputWithButtonContainer>
  );
}

export default observer(CreateNewElementTemplateFileUploadFromUrl);
