/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import { readFiles } from 'utils/file-io';

import * as Styled from './FileUpload.styled';

export const FileUpload = forwardRef(({ onFiles, label = 'Upload', multiple = false, accept }, ref) => {
  let fileInputElement = useRef();

  const handleChange = async (evt) => {
    onFiles(await readFiles(evt.target.files));
  };

  useImperativeHandle(ref, () => ({
    selectFile() {
      fileInputElement?.click();
    }
  }));

  return (
    <Fragment>
      <Styled.FileInput
        ref={(element) => (fileInputElement = element)}
        accept={accept}
        id="raised-button-file"
        multiple={multiple}
        type="file"
        onChange={handleChange}
      />
      <Styled.FileLabel htmlFor="raised-button-file">{label}</Styled.FileLabel>
    </Fragment>
  );
});

FileUpload.propTypes = {
  onFiles: PropTypes.func.isRequired,
  onInvalidFiles: PropTypes.func,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  accept: PropTypes.string
};

FileUpload.displayName = 'FileUpload';

export default FileUpload;
