/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default ({ templateId, name, description, icon }) => `{
  "$schema": "https://unpkg.com/@camunda/zeebe-element-templates-json-schema/resources/schema.json",
  "name": "${name}",
  "id": "${templateId}",
  "description": "${description ? description : ''}",
  ${icon ? `"icon": {"contents": "${icon}"},` : ''}
  "appliesTo": ["bpmn:Task"],
  "properties": []
}`;
