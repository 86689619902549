/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { publicationStore } from 'App/Pages/Diagram/stores';
import { isPublicationSupportedByElement } from 'App/Pages/Diagram/FormLinking';

import { createFormGroup, createPublicationGroup } from './utils';

export const getGroups = (injector, registerEvents, unregisterEvents) => (element) => (groups) => {
  if (isPublicationSupportedByElement(element)) {
    const publicationGroup = createPublicationGroup(element);
    const formGroup = createFormGroup(element, injector);

    groups.splice(2, 0, publicationGroup, formGroup);

    registerEvents();
    publicationStore.syncStoreWithExtensionElements();
  } else {
    unregisterEvents();
  }

  return groups;
};
