/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { processApplicationService } from 'services';
import { formStore, milestoneStore, processApplicationStore } from 'stores';

export const autosaveVersion = () => {
  const versionName = 'Autosaved during smart form generation';
  if (processApplicationStore.fromAProcessApplication) {
    processApplicationService.createProcessApplicationVersion(
      processApplicationStore.processApplication.id,
      versionName
    );
  } else {
    milestoneStore.createAutosaved(formStore.form, 'smartFormGeneration', {
      name: versionName
    });
  }
};
