/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { observer } from 'mobx-react';

import { processApplicationStore } from 'stores';
import buildPath from 'utils/build-path';

import * as Styled from './ProcessApplicationModalWrapper.styled';
import CreationModal from './CreationModal';

function ProcessApplicationModalWrapper({ children }) {
  const openDocumentation = () => {
    window.open('https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications');
  };

  const onContinue = () => {
    processApplicationStore.setIsAdvertisingModalVisible(false);
    processApplicationStore.startCreation();
  };

  return (
    <Styled.Container>
      <Styled.ProcessApplicationAdvertisingModal
        open={processApplicationStore.isAdvertisingModalVisible}
        onClose={() => {
          processApplicationStore.setIsAdvertisingModalVisible(false);
        }}
      >
        <ModalHeader label="" title="" />
        <ModalBody>
          <Styled.Visual>
            <img
              src={buildPath('/img/process-application-adv.svg')}
              alt="Simplify your workflow application and deploy entire BPMN projects with all dependencies in just one click."
            />
          </Styled.Visual>
          <Styled.Title>Effortless BPMN Project Deployment</Styled.Title>
          <Styled.Message>
            Simplify your workflow application and deploy entire BPMN projects with all dependencies in just one click.
            <ul>
              <li>Save time</li>
              <li>Minimize errors</li>
              <li>Enhance efficiency</li>
            </ul>
            Start deploying hassle-free today!
          </Styled.Message>
        </ModalBody>
        <ModalFooter>
          <Button kind="ghost" aria-label="Learn more about process applications" onClick={openDocumentation}>
            Learn more
          </Button>
          <Button
            kind="primary"
            aria-label="Continue creating the process application"
            data-modal-primary-focus
            onClick={onContinue}
          >
            Got it
          </Button>
        </ModalFooter>
      </Styled.ProcessApplicationAdvertisingModal>

      <CreationModal />

      {children}
    </Styled.Container>
  );
}

export default observer(ProcessApplicationModalWrapper);
