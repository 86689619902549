/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import { getRoleDetails } from 'utils/member-roles';

import * as Styled from './AvatarList.styled';

const LIMIT = 5;

export default function AvatarList({ collaborators }) {
  const numberOfCollaborators = collaborators.length;
  let limitedCollaborators = collaborators;

  if (collaborators.length > LIMIT) {
    limitedCollaborators = collaborators.slice(0, LIMIT - 1);
  }

  return (
    <Styled.Wrapper>
      {limitedCollaborators.map((collaborator) => {
        const fullname = collaborator.name || collaborator.fullName;
        const role = collaborator.permissionAccess ? ` (${getRoleDetails(collaborator.permissionAccess).title})` : '';

        return (
          <Styled.UserAvatar
            fullname={fullname}
            tooltip={`${collaborator.tooltipName || fullname}${role}`}
            key={collaborator.id}
            hasStar={collaborator.permissionAccess == 'ADMIN'}
          />
        );
      })}

      {numberOfCollaborators > LIMIT && (
        <Styled.UserCount
          fullname={numberOfCollaborators}
          tooltip={collaborators.map((collaborator) => collaborator.name || collaborator.fullName).join(', ')}
        />
      )}
    </Styled.Wrapper>
  );
}

AvatarList.propTypes = {
  collaborators: PropTypes.array.isRequired
};
