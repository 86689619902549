/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import BPMNModdle from 'bpmn-moddle';
import zeebeModdle from 'zeebe-bpmn-moddle/resources/zeebe.json';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { fileService } from 'services';
import { breadcrumbStore, notificationStore, currentDiagramStore } from 'stores';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import { FORM } from 'utils/constants';
import buildSlug from 'utils/buildSlug';
import { createEntity, generateIdFromElementName, trackSubResourceCreation } from 'components/TargetSelector/utils';

export default async function createForm(target, history) {
  const getExtensionElement = (element, type) => {
    return (
      element?.extensionElements?.values?.filter((extensionElement) => extensionElement.$instanceOf(type))[0] ||
      undefined
    );
  };

  /**
   * Links the form to the selected element and saves the diagram.
   * @param {Object} form The form to link to the selected element.
   * @returns {Promise<void>} Resolves when the form is linked and the diagram is saved.
   */
  const linkFormToDiagramAndSave = async (form) => {
    let formContent;
    try {
      formContent = JSON.parse(form.content);
    } catch (ex) {
      console.error(ex);
      notificationStore.showError('Could not parse form content. Please try again.');
    }

    const element = formLinkStore.selectedElement;

    const moddle = new BPMNModdle({ zeebe: zeebeModdle });
    const businessObject = getBusinessObject(element);
    const extensionElements = businessObject?.extensionElements || moddle.create('bpmn:ExtensionElements');

    let formDefinition = getExtensionElement(businessObject, 'zeebe:FormDefinition');
    if (!formDefinition) {
      formDefinition = moddle.create('zeebe:FormDefinition');
      const values = extensionElements.get('values');

      if (!values) {
        throw new Error('Could not find extension element values');
      }

      if (values.length === 0) {
        values.push(formDefinition);
      } else {
        values[0] = formDefinition;
      }
    }
    formDefinition.formKey = undefined;
    formDefinition.formId = formContent.id;

    const modeling = currentDiagramStore.modeler.get('modeling');
    modeling.updateProperties(element, {
      extensionElements
    });

    await currentDiagramStore.saveContent();
  };

  const elementName = formLinkStore.selectedElementName;
  const attributes = elementName
    ? {
        name: elementName,
        formId: generateIdFromElementName(elementName, FORM)
      }
    : {};
  const entity = createEntity({
    type: FORM,
    attributes,
    target
  });

  try {
    const form = await fileService.create(entity);

    await linkFormToDiagramAndSave(form);

    trackSubResourceCreation({
      newFileId: form.id,
      newFileType: FORM,
      newFileTarget: target
    });
    breadcrumbStore.toggleEditingFor('form');
    history.push(`/forms/${buildSlug(form.id, form.name)}`);
  } catch (ex) {
    console.error(ex);
    notificationStore.showError('Could not create new form. Please try again.');
  }
}
