/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Copy } from '@carbon/icons-react';
import { InlineNotification, ModalFooter, ModalHeader, ProgressBar, TextInput } from '@carbon/react';
import { v4 } from 'uuid';

import pluralize from 'utils/pluralize';
import { homeStore, notificationStore, userStore } from 'stores';
import createPermission from 'utils/createPermission';
import { tracingService, trackingService } from 'services';

import Dropdown from './ProjectsDropdown';
import ResourcesList from './ResourcesList';
import ActionsButtons from './ActionsButtons';
import DuplicatesWarning from './DuplicatesWarning';
import * as Styled from './ImportModal.styled';
import { importTemplateServiceHelper, PublishAction } from './';

export const ImportModal = ({
  currentProject = null,
  projects = [],
  showProjects = true,
  autoPublish = false,
  resourcesToImportURLs,
  resourcesToImportMetadata,
  fetchDelay = 0,
  open = false,
  onClose,
  onPublishComplete
}) => {
  const history = useHistory();

  const location = useLocation();

  const importTemplateService = importTemplateServiceHelper.defineService(location);

  const { isCreatingProject } = homeStore;

  const [flagState, setFlagState] = useState({
    isOpen: open,
    canPublish: false,
    isPublishing: false,
    isFetching: false,
    hasFetched: false,
    shouldShowGenericLoader: false,
    shouldShowFooter: false,
    hasActionsDisabled: false,
    hasOnlyOneResource: false,
    resourceString: ''
  });

  const [projectState, setProjectState] = useState({
    selectedProject: null,
    filteredProjects: [],
    hasProjects: false
  });

  const [resourceState, setResourceState] = useState({
    resources: [],
    hasResources: null,
    // `resourcesMetadata` contains all the information we need to render and import the list item.
    resourcesMetadata: [],
    // `resourcesPreviewMetadata` contains only the basic information we need to render the preview.
    resourcesPreviewMetadata: null,
    // `renderableItems` contains the list of normalized items to render. The data provider is `resourcesMetadata` or `resourcesPreviewMetadata`.
    renderableItems: [],
    errors: [],
    hasErrors: false
  });

  const [duplicatesState, setDuplicatesState] = useState({
    duplicates: [],
    hasDuplicates: false,
    hasLoadedDuplicates: false,
    hasOOTBDuplicate: false
  });

  const [creatingNewProject, setCreatingNewProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');

  const updateState = (updateFunc, newState) => {
    updateFunc((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const disposeState = () => {
    // We need to reset the state when the modal is closed, hence the timeout (the modal is animated).
    setTimeout(() => {
      setFlagState({
        isOpen: false,
        canPublish: false,
        isPublishing: false,
        isFetching: false,
        hasFetched: false,
        shouldShowGenericLoader: false,
        shouldShowFooter: false,
        hasActionsDisabled: false,
        hasOnlyOneResource: false,
        resourceString: ''
      });

      updateState(setProjectState, { filteredProjects: [] });

      setResourceState({
        resources: [],
        hasResources: false,
        resourcesMetadata: null,
        resourcesPreviewMetadata: null,
        renderableItems: [],
        errors: [],
        hasErrors: false
      });

      setDuplicatesState({
        duplicates: [],
        hasDuplicates: false,
        hasLoadedDuplicates: false,
        hasOOTBDuplicate: false
      });
    }, 1500);
  };

  const resetProjects = () => {
    setProjectState({
      selectedProject: null,
      filteredProjects: [],
      hasProjects: false
    });
  };

  const resetDuplicatesState = () => {
    setDuplicatesState({
      duplicates: [],
      hasDuplicates: false,
      hasLoadedDuplicates: false,
      hasOOTBDuplicate: false
    });
  };

  useEffect(() => {
    updateState(setFlagState, { isOpen: open });
  }, [open]);

  /**
   * If the projects are fetched, filter them based on the user permissions and set the state.
   */
  useEffect(() => {
    if (projects?.length) {
      // NOTE: We deconstruct the projects array to avoid mutating the original array when we sort it.
      const filteredProjects = userStore.isSuperUserModeActive
        ? [...projects]
        : [...projects].filter((project) => createPermission(project?.permissionAccess).is(['ADMIN', 'WRITE']));
      filteredProjects.sort((a, b) => a.name.localeCompare(b.name));

      const hasRenderableProjects = filteredProjects.length > 0;

      if (hasRenderableProjects) {
        updateState(setProjectState, { hasProjects: true, filteredProjects });
      } else {
        resetProjects();
      }
    } else {
      if (projectState.hasProjects) {
        // If we had projects, but we don't receive any, we reset the state.
        resetProjects();
      }
    }
  }, [projects]);

  useEffect(() => {
    updateState(setProjectState, { selectedProject: currentProject });
  }, [currentProject]);

  /**
   * If we receive a list of resource URLs, we fetch the resources metadata and set the state.
   */
  useEffect(() => {
    (async () => {
      if (resourcesToImportURLs?.length > 0) {
        updateState(setFlagState, { isFetching: true, hasFetched: false });

        const { errors, metadata } = await importTemplateService.fetchResources(resourcesToImportURLs);
        updateState(setResourceState, { errors });
        updateState(setFlagState, { isFetching: false, hasFetched: true });
        updateState(setResourceState, { resourcesMetadata: metadata });

        trackingService.trackResourcesImportOpening({
          urls: resourcesToImportURLs
        });
      }
    })();
  }, [resourcesToImportURLs]);

  /**
   * If we receive a list of resources metadata, we prepare the preview list, and we fetch the resources.
   * Once done, we are ready to import.
   */
  useEffect(() => {
    (async () => {
      if (resourcesToImportMetadata?.length > 0) {
        const urls = [];
        const resourcesPreviewMetadata = [];

        resourcesToImportMetadata.forEach((resource) => {
          urls.push(resource.source || resource.url);

          resourcesPreviewMetadata.push({
            id: resource.id || v4(),
            name: resource.name,
            source: resource.source || resource.url
          });
        });

        updateState(setFlagState, { isFetching: true, hasFetched: false });

        // We set the preview metadata to render the list while we fetch the resources.
        updateState(setResourceState, { resourcesPreviewMetadata });

        // If configured, we wait for a few seconds before fetching the resources, to give the user a chance to see what's happening.
        if (fetchDelay && fetchDelay > 0) {
          await new Promise((resolve) => setTimeout(resolve, fetchDelay));
        }

        const { errors, metadata } = await importTemplateService.fetchResources(urls);
        updateState(setResourceState, { errors, resourcesMetadata: metadata });

        updateState(setFlagState, { isFetching: false, hasFetched: true });
      }
    })();
  }, [resourcesToImportMetadata]);

  /**
   * If the project is selected or creating a new one, check if we have duplicates.
   */
  useEffect(() => {
    (async () => {
      if (
        importTemplateService.duplicatesNotAllowed() &&
        (projectState.selectedProject?.id || creatingNewProject) &&
        resourceState.resourcesMetadata?.length
      ) {
        try {
          const resourceIds = resourceState.resourcesMetadata
            .flatMap((resource) => resource.templates)
            .map((resource) => resource.id);

          const { duplicates, ootbDuplicates } = await importTemplateService.getAllDuplicatedResources(
            projectState.selectedProject?.id,
            resourceIds.toString()
          );

          setDuplicatesState({
            duplicates,
            hasDuplicates: duplicates?.length > 0,
            hasLoadedDuplicates: true,
            hasOOTBDuplicate: ootbDuplicates?.length > 0
          });
        } catch (err) {
          notificationStore.showError(
            "Yikes! Couldn't check for duplicates. Please try again. If the problem persists, please get in touch with the support team"
          );

          tracingService.traceError(err, 'Failed to check for duplicates');

          handleOnClose();
        }
      }
    })();
  }, [projectState.selectedProject, resourceState.resourcesMetadata, creatingNewProject]);

  /**
   * Create a normalized list of items to render, based on the resources metadata or the resources preview metadata.
   */
  useEffect(() => {
    const normalize = (resource, parent) => {
      const output = {
        id: resource.id,
        source: parent?.source || resource.source,
        name: importTemplateService.extractFileName(resource, parent),
        icon: importTemplateService.extractIcon(resource, parent)
      };
      return output;
    };

    const output = resourceState.resourcesMetadata?.length
      ? resourceState.resourcesMetadata.flatMap(
          (resource) => resource.templates?.map((template) => normalize(template, resource)) ?? []
        )
      : (resourceState.resourcesPreviewMetadata?.map(normalize) ?? []);

    updateState(setResourceState, { renderableItems: output });
  }, [resourceState.resourcesMetadata, resourceState.resourcesPreviewMetadata]);

  /**
   * Update the resources and the `hasResources` state  when the resources metadata or the resources preview metadata are set.
   */
  useEffect(() => {
    const { resourcesMetadata, resourcesPreviewMetadata } = resourceState;

    if (resourcesMetadata || resourcesPreviewMetadata) {
      const resources = (resourcesMetadata?.length ? resourcesMetadata : resourcesPreviewMetadata) || [];
      updateState(setResourceState, {
        hasResources: resources.length > 0,
        resources
      });
    }
  }, [resourceState.resourcesMetadata, resourceState.resourcesPreviewMetadata]);

  /**
   * When the resources are fetched, we check if we have errors.
   */
  useEffect(() => {
    if (flagState.hasFetched) {
      updateState(setResourceState, {
        hasErrors: resourceState.errors.length || (resourceState.hasResources != null && !resourceState.hasResources)
      });
    }
  }, [flagState.hasFetched, resourceState.errors, resourceState.hasResources]);

  /**
   * Update the `canPublish` flag when the resources are fetched, the project is selected, and we don't have errors.
   */
  useEffect(() => {
    const isProjectAutoselected = autoPublish && projectState.selectedProject;

    updateState(setFlagState, {
      canPublish:
        resourceState.hasResources &&
        flagState.hasFetched &&
        (!autoPublish || isProjectAutoselected) &&
        !resourceState.hasErrors
    });
  }, [resourceState.hasResources, flagState.hasFetched, projectState.selectedProject, resourceState.hasErrors]);

  /**
   * Auto-publish the resources to the project if the conditions are met.
   */
  useEffect(() => {
    const shouldWaitForDuplicates =
      !duplicatesState.hasLoadedDuplicates || (duplicatesState.hasLoadedDuplicates && duplicatesState.hasDuplicates);

    if (!autoPublish || !flagState.canPublish || shouldWaitForDuplicates) {
      return;
    }

    onPublishToProject(PublishAction.Publish);
  }, [autoPublish, flagState.canPublish, duplicatesState.hasLoadedDuplicates, duplicatesState.hasDuplicates]);

  /**
   * Update the UI flags.
   */
  useEffect(() => {
    updateState(setFlagState, {
      shouldShowGenericLoader: !resourceState.hasResources && !resourceState.hasErrors,
      shouldShowFooter:
        resourceState.hasErrors || duplicatesState.hasDuplicates || (!autoPublish && flagState.canPublish),
      hasActionsDisabled:
        (projectState.selectedProject == null && creatingNewProject === false) ||
        (creatingNewProject === true && newProjectName?.length < 3) ||
        isCreatingProject ||
        flagState.isPublishing ||
        !flagState.canPublish,
      hasOnlyOneResource: resourceState.resources.length === 1,
      resourceString: resourceState.resources.length
        ? pluralize('resource', resourceState.resources.length)
        : 'resource'
    });
  }, [
    resourceState.hasResources,
    resourceState.hasErrors,
    duplicatesState.hasDuplicates,
    autoPublish,
    flagState.canPublish,
    projectState.selectedProject,
    isCreatingProject,
    flagState.isPublishing,
    creatingNewProject,
    newProjectName
  ]);

  const handleOnClose = (evt) => {
    updateState(setFlagState, { isOpen: false });

    disposeState();

    if (showProjects) {
      updateState(setProjectState, { selectedProject: null });
    }

    if (onClose) {
      onClose(evt);
    }

    if (!autoPublish) {
      history.push('/');
    }
  };

  const onCreateNewProject = async () => {
    updateState(setProjectState, { selectedProject: null });
    resetDuplicatesState();
    setCreatingNewProject(true);
  };

  const onPublishToProject = async (publishAction) => {
    try {
      updateState(setFlagState, { isPublishing: true });

      const selectedProject = creatingNewProject
        ? await homeStore.createProjectFromImport(newProjectName)
        : projectState.selectedProject;

      const requestObject = {
        selectedProject: selectedProject,
        resourcesMetadata: resourceState.resourcesMetadata,
        publishAction,
        duplicates: duplicatesState.duplicates
      };

      const importedFiles = await importTemplateService.import(requestObject);

      notificationStore.showSuccess(getPublishSuccessMessage(selectedProject.name));

      if (!autoPublish) {
        importTemplateService.executePostImportAction(history, selectedProject, importedFiles);
      } else {
        handleOnClose();
      }
    } catch (err) {
      notificationStore.showError(
        `There was a problem while trying to add the ${flagState.resourceString}. Please try again.`
      );
      tracingService.traceError(err, 'Failed to import resources');

      handleOnClose();
    } finally {
      if (onPublishComplete) {
        onPublishComplete();
      }

      updateState(setFlagState, { isPublishing: false });
    }
  };

  const goToSupport = () => {
    window.open('https://forum.camunda.io', '_blank');
  };

  const goToMarketplace = () => {
    if (!autoPublish) {
      window.open('https://marketplace.camunda.com', '_self');
    } else {
      handleOnClose({ gotoMarketplace: true });
    }
  };

  const copyErrorLogs = async () => {
    try {
      const errors = resourceState.errors;

      // If we don't have any errors and we don't have any resources, we add a generic error.
      if (!resourceState.hasResources && resourceState.errors.length === 0) {
        errors.push({
          error: 'No resources were found to import'
        });
      }

      await navigator.clipboard.writeText(JSON.stringify(errors, null, 2));
      notificationStore.showSuccess('The error logs have been copied into your clipboard as JSON.');
    } catch (ex) {
      notificationStore.showError(
        "Yikes! Couldn't copy the error logs into your clipboard. Make sure that you gave permissions to the browser."
      );
      tracingService.traceError(ex, 'Failed to copy error logs into clipboard');
    }
  };

  const getPublishSuccessMessage = (projectName) =>
    flagState.hasOnlyOneResource
      ? `'${resourceState.renderableItems[0]?.name}' is available under the '${projectName}' project`
      : `${resourceState.resources.length} resources are available under the '${projectName}' project`;

  return (
    <Styled.ImportDialog open={flagState.isOpen} onClose={handleOnClose} size="sm" className="overflow-visible">
      <ModalHeader>
        <Styled.ModalTitle>Download {flagState.resourceString} from Marketplace</Styled.ModalTitle>
      </ModalHeader>

      <Styled.ImportModalBody>
        {flagState.shouldShowGenericLoader && (
          <ProgressBar label={`Loading ${pluralize('resource', resourceState.resources.length)}`} />
        )}

        {resourceState.hasResources && (
          <ResourcesList
            resources={resourceState.resources}
            items={resourceState.renderableItems}
            isFetching={flagState.isFetching}
            duplicates={duplicatesState.duplicates}
          />
        )}

        {resourceState.hasErrors && (
          <InlineNotification kind="error" title="Download Error" lowContrast hideCloseButton>
            <div>
              An error occurred during the downloading process. Please go back to the marketplace and retry to download
              the resource. If the problem persists, please get in touch with the support team.
            </div>
            <Styled.CopySupportInfo onClick={copyErrorLogs}>
              Copy error logs for support <Copy />
            </Styled.CopySupportInfo>
          </InlineNotification>
        )}

        {flagState.canPublish && showProjects && (
          <Styled.ProjectsListContainer>
            <div>
              <h4>Select a project</h4>

              <p>
                The {pluralize('resource', resourceState.resources.length)} will be available for use within the
                selected project.
              </p>

              <Dropdown
                selectedProject={projectState.selectedProject}
                createProjectSelected={creatingNewProject}
                isCreatingProject={isCreatingProject}
                isPublishing={flagState.isPublishing}
                hasProjects={projectState.hasProjects}
                filteredProjects={projectState.filteredProjects}
                onCreateNewProject={onCreateNewProject}
                onProjectSelected={(item) => {
                  setCreatingNewProject(false);
                  updateState(setProjectState, { selectedProject: item });
                }}
              />
              {creatingNewProject && (
                <TextInput
                  autoFocus
                  id="project-name-input"
                  data-test="project-name-input"
                  value={newProjectName}
                  onChange={(event) => {
                    setNewProjectName(event.target.value);
                  }}
                  type="text"
                  labelText=""
                  helperText=""
                  placeholder="Add a project name"
                />
              )}
            </div>
          </Styled.ProjectsListContainer>
        )}

        {duplicatesState.hasDuplicates && (
          <DuplicatesWarning
            duplicates={duplicatesState.duplicates}
            hasOOTBDuplicate={duplicatesState.hasOOTBDuplicate}
          />
        )}
      </Styled.ImportModalBody>

      {flagState.shouldShowFooter && (
        <ModalFooter>
          <ActionsButtons
            showCancel={!autoPublish || (autoPublish && duplicatesState.hasDuplicates)}
            isCancelGhost={duplicatesState.hasDuplicates}
            showPublish={!autoPublish && flagState.canPublish && !duplicatesState.hasDuplicates}
            showDuplicatesActions={duplicatesState.hasDuplicates}
            hasErrors={resourceState.hasErrors}
            disabled={flagState.hasActionsDisabled}
            onCancel={() => handleOnClose()}
            onPublish={() => {
              onPublishToProject(PublishAction.Publish);
            }}
            onSaveAsCopy={() => {
              onPublishToProject(PublishAction.SaveAsCopy);
            }}
            onReplace={() => {
              onPublishToProject(PublishAction.Replace);
            }}
            onGoToSupport={() => goToSupport()}
            onGoToMarketplace={() => goToMarketplace()}
          />
        </ModalFooter>
      )}
    </Styled.ImportDialog>
  );
};

ImportModal.propTypes = {
  currentProject: PropTypes.object,
  projects: PropTypes.array,
  showProjects: PropTypes.bool,
  autoPublish: PropTypes.bool,
  resourcesToImportURLs: PropTypes.array,
  resourcesToImportMetadata: PropTypes.array,
  fetchDelay: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onPublishComplete: PropTypes.func
};

export default observer(ImportModal);
