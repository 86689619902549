/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { getParentLink } from 'utils/helpers';

import { tryDeleteReadmeWithConfirmation } from '../state-management';

/**
 * @typedef {'link'|'text'|'dropdown'} BreadcrumbVariant
 */

/**
 * @typedef {Object} BreadcrumbItem
 * @property {string} title
 * @property {BreadcrumbVariant} variant
 * @property {string} [to]
 * @property {Function} [onDelete]
 */

/**
 * @returns {BreadcrumbItem[]}
 */
export function useReadmeBreadcrumbs({ readme, project, clearReadme, isReadOnly }) {
  const history = useHistory();

  return useMemo(() => {
    if (!readme || !project) {
      return [];
    }

    /** @type {BreadcrumbItem[]} */
    const items = [
      {
        title: 'Home',
        variant: 'link',
        to: '/'
      },
      {
        title: project.name,
        variant: 'link',
        to: `/projects/${project.id}`
      }
    ];

    if (readme.folder) {
      if (readme.folder.parentId) {
        items.push({
          title: '...',
          variant: 'text'
        });
      }

      items.push({
        title: readme.folder.name,
        variant: 'link',
        to: getParentLink(readme)
      });
    }

    items.push({
      title: readme.name,
      variant: !isReadOnly ? 'dropdown' : 'text',
      onDelete: !isReadOnly
        ? async () => {
            const { deleted } = await tryDeleteReadmeWithConfirmation(readme, history, project);
            if (deleted) {
              clearReadme();
            }
          }
        : undefined
    });

    return items;
  }, [readme, project, isReadOnly, history, clearReadme]);
}
