/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { createPortal } from 'react-dom';

import { projectStore } from 'stores';
import { Dropdown } from 'components';
import { Templates } from 'icons';
import hasAccess, { actions } from 'utils/user-access';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import config from 'utils/config';
import { trackingService } from 'services';

import ProjectActionButton from './ProjectActionButton';
import { BrowseBlueprintsModal } from './BrowseBlueprintsModal';
import * as Styled from './ProjectActionButton.styled';

export default function ActionButton({ onUpload, isReadmePresent, variant, label, processApplicationId = null }) {
  const { project } = projectStore;

  if (!hasAccess(project, actions.CREATE_DIAGRAM)) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  const openTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };

  return (
    <>
      <ProjectActionButton
        label={label}
        variant={variant}
        onUpload={onUpload}
        processApplicationId={processApplicationId}
        isReadmePresent={isReadmePresent}
      >
        {({ closeDropdown }) => (
          // @ts-expect-error TS2741
          <Dropdown.ListGroup>
            {/* @ts-expect-error TS2739 */}
            <Dropdown.ListItem
              data-test="create-from-template"
              onClick={() => {
                dedicatedModesStore.resetViewModeIndexForPlay();
                closeDropdown();
                openTemplateModal();
                if (config.marketplace?.enabled) {
                  trackingService.trackBlueprintsOpening({ from: 'actionButton' });
                }
              }}
            >
              <Styled.ProjectActionItem>
                <Templates width={16} height={16} />
                Browse blueprints
              </Styled.ProjectActionItem>
            </Dropdown.ListItem>
          </Dropdown.ListGroup>
        )}
      </ProjectActionButton>
      {config.marketplace?.enabled &&
        createPortal(
          <BrowseBlueprintsModal isOpen={isTemplateModalVisible} onClose={() => setIsTemplateModalVisible(false)} />,
          document.body
        )}
    </>
  );
}
