/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import tokenSimulationStore from './TokenSimulationStore';

function ToggleOverride(eventBus, toggleMode) {
  eventBus.on('tokenSimulation.toggleMode', function () {
    tokenSimulationStore.toggleTokenSimulation();
  });

  const onTokenSimulationChange = (flag) => {
    if (typeof flag === 'boolean') {
      toggleMode.toggleMode(flag);
    } else {
      toggleMode.toggleMode();
    }
  };
  tokenSimulationStore.setOnTokenSimulationChange(onTokenSimulationChange);

  const cleanup = () => {
    eventBus.fire('tokenSimulation.resetSimulation');
  };
  tokenSimulationStore.setCleanup(cleanup);
}

ToggleOverride.$inject = ['eventBus', 'toggleMode'];

export default {
  __init__: ['toggleOverride'],
  toggleOverride: ['type', ToggleOverride]
};
