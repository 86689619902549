/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { ProgressBar } from '@carbon/react';

import pluralize from 'utils/pluralize';

import TemplateItem from './TemplateItem';
import * as Styled from './ImportModal.styled';

const ResourcesList = ({ resources, items, duplicates, isFetching }) => {
  return (
    <>
      <Styled.ModalSubtitle>
        {items.length === 1
          ? `You are adding the following resource from an external source`
          : `You are adding the following ${resources.length} resources from an external source`}
        :
      </Styled.ModalSubtitle>

      <Styled.ResourcesList>
        {items.map((template) => (
          <TemplateItem
            key={`resource-${template.id || template.name?.replace(' ', ',')}`}
            id={template.id}
            name={template.name}
            icon={template.icon}
            source={template.source}
            duplicates={duplicates}
            hasDuplicates={duplicates?.length > 0}
          />
        ))}
      </Styled.ResourcesList>

      {isFetching && (
        <div style={{ marginBottom: '15px' }}>
          <ProgressBar label={`Downloading ${pluralize('connector', items.length)}`} />
        </div>
      )}
    </>
  );
};

ResourcesList.propTypes = {
  resources: PropTypes.array,
  items: PropTypes.array,
  duplicates: PropTypes.array,
  isFetching: PropTypes.bool
};

export default ResourcesList;
