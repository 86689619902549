/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { getChevronDown } from 'primitives/Button/Button.styled';

export const Wrapper = styled.div`
  background-color: var(--cm-color-white-base);
  padding: var(--spacing-large);
  width: 100%;
  display: flex;
`;

export const Icon = styled.div`
  margin-right: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--cm-color-black-primary-text);
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--cm-color-black-primary-text);
  margin-bottom: 24px;
  letter-spacing: 0.16px;
`;

export const Action = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  button {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }

  button.project-action {
    background-color: var(--cds-button-secondary, #393939);
    background-image: url(${getChevronDown()});
    color: var(--cds-text-on-color, #ffffff);
  }

  button.project-action:active {
    background-color: var(--cds-button-secondary-active, #6f6f6f);
  }

  button.project-action:hover,
  button.project-action:focus {
    background-color: var(--cds-button-secondary-hover, #474747);
  }
`;

export const Link = styled.div`
  a {
    text-decoration: none;
    letter-spacing: 0.16px;

    &:hover {
      text-decoration: underline;
    }
  }
`;
