/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { StructuredListBody, StructuredListWrapper } from '@carbon/react';
import { useEffect, useState } from 'react';
import { diff } from 'bpmn-js-differ';

import { Sidebar } from 'primitives';
import { getDefinitions } from 'utils/web-modeler-diagram-parser';

import ChangeList from './ChangeList';
import { LIST_TYPES } from './helpers';
import * as Styled from './BpmnChangeList.styled';

const EMPTY_CHANGE_LIST = { _removed: {}, _added: {}, _changed: {}, _layoutChanged: {} };

export default function BpmnChangeList({ previousContent, currentContent, bpmnViewer, isDiffingEnabled }) {
  const isNothingToCompare = useIsNothingToCompare(previousContent, isDiffingEnabled);
  const bpmnChangeList = useComputeChangeList(currentContent, previousContent, isDiffingEnabled);

  const { _removed, _added, _changed, _layoutChanged } = bpmnChangeList;

  const totalChangesCount =
    (_removed ? Object.keys(_removed).length : 0) +
    (_added ? Object.keys(_added).length : 0) +
    (_changed ? Object.keys(_changed).length : 0) +
    (_layoutChanged ? Object.keys(_layoutChanged).length : 0);

  const isNoChanges = !isNothingToCompare && totalChangesCount === 0;

  return (
    // @ts-expect-error TS2339
    <Sidebar.Section>
      <Styled.SidebarTitle>{`Changes (${totalChangesCount})`}</Styled.SidebarTitle>
      <Styled.ChangeListContainer>
        <StructuredListWrapper isCondensed selection>
          <StructuredListBody>
            <ChangeList list={_removed} type={LIST_TYPES.REMOVED} bpmnViewer={bpmnViewer} />
            <ChangeList list={_added} type={LIST_TYPES.ADDED} bpmnViewer={bpmnViewer} />
            <ChangeList list={_changed} type={LIST_TYPES.CHANGED} bpmnViewer={bpmnViewer} />
            <ChangeList list={_layoutChanged} type={LIST_TYPES.MOVED} bpmnViewer={bpmnViewer} />
          </StructuredListBody>
        </StructuredListWrapper>
        {isNothingToCompare && <Styled.EmptyState>Nothing to compare</Styled.EmptyState>}
        {isNoChanges && <Styled.EmptyState>No changes</Styled.EmptyState>}
      </Styled.ChangeListContainer>
      {/* @ts-expect-error TS2339 */}
    </Sidebar.Section>
  );
}

const useIsNothingToCompare = (previousContent, isDiffingEnabled) => {
  const [isNothingToCompare, setIsNothingToCompare] = useState(false);

  useEffect(() => {
    if (previousContent && isDiffingEnabled) {
      setIsNothingToCompare(false);
    } else {
      setIsNothingToCompare(true);
    }
  }, [previousContent, isDiffingEnabled]);

  return isNothingToCompare;
};

const useComputeChangeList = (currentContent, previousContent, isDiffingEnabled) => {
  const [bpmnChangeList, setBpmnChangeList] = useState(EMPTY_CHANGE_LIST);

  useEffect(() => {
    if (currentContent && previousContent && isDiffingEnabled) {
      (async () => {
        const oldDefinitions = await getDefinitions(previousContent);
        const newDefinitions = await getDefinitions(currentContent);

        const bpmnChangeList = diff(oldDefinitions, newDefinitions);

        setBpmnChangeList(bpmnChangeList);
      })();
    } else {
      setBpmnChangeList(EMPTY_CHANGE_LIST);
    }
  }, [previousContent, currentContent, isDiffingEnabled]);

  return bpmnChangeList;
};
