/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * ValidationError error type representing IDP document validation errors.
 *
 * @class ConnectorInputError
 */
export class ValidationError extends Error {
  /**
   * Creates an instance of ValidationError.
   *
   * @param {string} message - The error message.
   * @param {string} response - The JSON stringified list of validation errors
   */
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}
