/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Card, Button, Typography } from 'primitives';
import { layoverStore } from 'stores';

export default function Notifications() {
  return (
    <Card
      title="Notifications"
      data-test="notifications-section"
      subtitle="Toggle email notifications for individual projects."
    >
      <Card.Content>
        <Typography>
          Email notifications are sent once another collaborator mentions you in a diagram comment.
        </Typography>
      </Card.Content>
      <Card.Footer>
        <Button
          variant="primary"
          onClick={layoverStore.showLayover('notificationSettings')}
          data-test="manage-notifications"
        >
          Manage notifications
        </Button>
      </Card.Footer>
    </Card>
  );
}
