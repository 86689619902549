/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { KEY_UP, KEY_DOWN } from 'keycode-js';

import { Spinner } from 'primitives';

import * as Styled from './MilestoneList.styled';

export default function MilestoneList({ isLoading, children, navigate }) {
  useMilestonesKeyboardNavigation(navigate);

  if (isLoading) {
    return <Spinner fullHeight />;
  }
  return (
    <Styled.MilestoneList data-test="milestone-list" role="list" title="Milestones">
      {children}
    </Styled.MilestoneList>
  );
}

MilestoneList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired
};

const useMilestonesKeyboardNavigation = (navigate) => {
  useEffect(() => {
    const handleKeyDown = (evt) => {
      switch (evt.keyCode) {
        case KEY_UP:
          navigate('up');
          break;
        case KEY_DOWN:
          navigate('down');
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};
