/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';
import { TabList as CarbonTabList } from '@carbon/react';

export const ActionBarContainer = styled.header`
  border-bottom: 1px solid var(--silver-darken-87);
  background-color: var(--cm-color-white-base) !important;
`;

export const ActionBar = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  color: var(--grey-lighten-50);
  padding: 0 var(--cds-spacing-05);
  button {
    margin-left: var(--cds-spacing-04);
  }
`;

export const LeftSection = styled.div`
  margin: 0 auto 0 var(--cds-spacing-05);
  display: flex;
  align-items: center;

  & .cds--tabs {
    inline-size: auto !important;
    margin-right: var(--cds-spacing-05);
  }
`;

export const Status = styled.p`
  margin: 0 auto 0 var(--cds-spacing-04);
  display: flex;
  color: ${({ $isDanger }) => ($isDanger ? 'var(--red-darken-52)' : 'var(--grey-darken-33)')};
  font-size: unset;
  align-items: center;

  > svg {
    margin-right: var(--cds-spacing-03);
  }
`;

export const TabList = styled(CarbonTabList)`
  user-select: none;
  height: 56px;
`;
