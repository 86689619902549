/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { CheckmarkFilled, ErrorFilled, WarningFilled } from '@carbon/icons-react';
import { Link } from '@carbon/react';

export const EvaluateExtractionSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  .evaluate-button {
    height: 40px;
    padding-top: 10px;
    margin-top: 28px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
`;

export const DropdownContainer = styled.div`
  width: 60%;
`;

export const TestCaseSummaryContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const StatusOverviewContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 32px;
`;

export const ResultsBundle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

export const ResultsContainer = styled.div`
  width: 40px;
`;

export const YellowWarningFilled = styled(WarningFilled)`
  fill: var(--cds-support-warning, #f1c21b);
  [data-icon-path='inner-path'] {
    fill: black;
    opacity: 1;
  }
`;
export const GreenCheckmarkFilled = styled(CheckmarkFilled)`
  fill: var(--cds-support-success, #24a148);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;
export const RedErrorFilled = styled(ErrorFilled)`
  fill: var(--cds-support-error, #da1e28);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;

export const NoExtractionFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const TableContainer = styled.div`
  .cds--expandable-row:not(.cds--parent-row) > td {
    padding-inline-start: 2.5rem !important;
    padding-right: 0 !important;
  }
`;

export const ExtractDataLink = styled(Link)`
  cursor: pointer;
  font-size: 16px;
`;
