/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { organizationStore } from 'stores';
import { clusterService } from 'services';
import { IDP_EXTRACTION_CONNECTOR_SECRETS } from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';

/**
 * @param {String} clusterId - Selected Cluster id
 */
export function useClusterConnectorSecrets(clusterId) {
  const { currentOrganizationId } = organizationStore;
  const [areIdpConnectorSecretsMissing, setAreIdpConnectorSecretMissing] = useState(false);

  useEffect(() => {
    const areIdpConnectorSecretsMissing = async () => {
      try {
        const existingSecrets = (await clusterService.getConnectorSecrets(currentOrganizationId, clusterId)) || {};
        const areIdpConnectorSecretsMissing = IDP_EXTRACTION_CONNECTOR_SECRETS.some(
          (secret) => !existingSecrets[secret]
        );
        setAreIdpConnectorSecretMissing(areIdpConnectorSecretsMissing);
      } catch (error) {
        console.error(error);
      }
    };

    if (currentOrganizationId && clusterId) {
      areIdpConnectorSecretsMissing();
    }
  }, [currentOrganizationId, clusterId]);

  return areIdpConnectorSecretsMissing;
}
