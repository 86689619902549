/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from 'components';
import { notificationStore } from 'components/NotificationSystem';
import { milestoneService } from 'services';

function DeleteListItem({ milestoneId, reloadVersions, closeVersionMenuDropdown }) {
  return (
    // @ts-expect-error TS2554
    <Dropdown.ListItem
      onClick={async () => {
        try {
          closeVersionMenuDropdown();
          await milestoneService.destroy(milestoneId);
          notificationStore.showSuccess('IDP project version was deleted.');
          reloadVersions();
        } catch (error) {
          // Deleting a version of the IDP project with organization-public connector templates
          // returns a warning for users without elevated access equivalent to outside IDP applications
          let errorMsg = 'Failed to delete the IDP project version.';
          if (error?.status === 403 && error?.[0]?.reason === 'INVALID_OPERATION') {
            errorMsg = error[0].message;
          }
          notificationStore.showError(errorMsg);
        }
      }}
    >
      Delete
    </Dropdown.ListItem>
  );
}

export { DeleteListItem };
