/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef } from 'react';

import { drawIcons, drawMarkers, drawTargetEngineVersionChange } from 'utils/diffing';
import BPMNDiff from 'utils/diffing/BPMNDiff';

export default function useDiffing(currentContent, previousContent, bpmnViewer, isDiffingEnabled) {
  const iconsRef = useRef([]);
  const markersRef = useRef([]);

  const resetDiff = () => {
    iconsRef.current.forEach((iconCleanupCallback) => iconCleanupCallback());
    markersRef.current.forEach((markerCleanupCallback) => markerCleanupCallback());

    iconsRef.current = [];
    markersRef.current = [];
  };

  const applyDiff = (diffingResponse) => {
    if (diffingResponse) {
      const engineVersionChangeOverlay = drawTargetEngineVersionChange(diffingResponse.targetEngineVersion);
      const addedIcons = drawIcons(diffingResponse.new, 'added', bpmnViewer);
      const modifiedIcons = drawIcons(diffingResponse.modified, 'changed', bpmnViewer);

      iconsRef.current = addedIcons.concat(modifiedIcons).concat(engineVersionChangeOverlay);
      markersRef.current = drawMarkers(diffingResponse, bpmnViewer.get('canvas'));
    }
  };

  useEffect(() => {
    (async () => {
      if (currentContent && bpmnViewer) {
        resetDiff();

        if (isDiffingEnabled) {
          let diffingResponse = null;
          if (previousContent) {
            diffingResponse = await BPMNDiff(previousContent, currentContent);
          } else {
            diffingResponse = null;
          }

          applyDiff(diffingResponse);
        }
      }
    })();
  }, [currentContent, previousContent, bpmnViewer, isDiffingEnabled]);
}
