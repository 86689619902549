/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineLoading, InlineNotification, Toggle as _Toggle } from '@carbon/react';
import styled from 'styled-components';

export const Publication = styled.div`
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .cds--snippet-container {
    min-height: 0 !important;
  }

  .cds--snippet--multi .cds--snippet-container pre {
    padding-bottom: 0;
  }
`;

export const PublicationTitle = styled.h5`
  color: black;
  margin-bottom: var(--spacing-medium);
`;

export const PublicationInformativeText = styled.div`
  font-size: 14px;
  color: var(--cds-text-01);
  margin: var(--spacing-small) 0 var(--spacing-small) 0;
`;

export const InformativeText = styled(InlineNotification)`
  margin-bottom: var(--spacing-small);
`;

export const Toggle = styled(_Toggle)`
  margin-bottom: var(--spacing-small);
`;

export const FormLinkSuggestion = styled.div`
  font-size: 14px;
  color: var(--cds-text-02);
  margin-bottom: var(--spacing-small);
`;

export const FormLinkSuggestionNotification = styled(InlineNotification)`
  margin-bottom: var(--spacing-small);
`;

export const Loading = styled(InlineLoading)`
  height: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

export const ActivePublicLink = styled.div`
  margin-bottom: var(--spacing-small);
`;
