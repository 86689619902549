/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autorun } from 'mobx';

import { dedicatedModesStore } from 'App/Pages/Diagram/stores';

export default class DocumentationOnlyPropertiesProvider {
  constructor(propertiesPanel, eventBus) {
    /**
     * 50 - Low priority to execute this last
     * Otherwise another plugin will add an additional property after the filtering is done in this one
     */
    propertiesPanel.registerProvider(50, this);

    autorun(() => dispatchChangedEventOnModeChange(eventBus));
  }

  getGroups() {
    return (groups) =>
      dedicatedModesStore.isDesignMode ? groups.filter((group) => group.id === 'documentation') : groups;
  }
}

function dispatchChangedEventOnModeChange(eventBus) {
  if (dedicatedModesStore.isDesignMode || dedicatedModesStore.isImplementMode) {
    eventBus?.fire('propertiesPanel.providersChanged');
  }
}

DocumentationOnlyPropertiesProvider.$inject = ['propertiesPanel', 'eventBus'];
