/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action, runInAction, makeObservable } from 'mobx';
import minimapModule from 'diagram-js-minimap';

import { shareService, trackingService } from 'services';
import { diagramControlStore, shareStore, notificationStore, currentDiagramStore } from 'stores';
import { CamundaLogo } from 'icons';
import { Breadcrumb, TopBar, Modeler, PasswordProtectedShare, DiagramControls } from 'components';
import { Spinner } from 'primitives';
import { getPageTitle } from 'utils/helpers';
import { FILE_TYPE_MAPPING } from 'utils/constants';

import { documentationOverlayModule } from './show-documentation-overlay';

class Share extends Component {
  shareId = this.props.match.params.id;

  loading = true;

  init = async () => {
    trackingService.trackPageView('share');

    shareService
      .fetchById(this.shareId)
      .then((share) => {
        this.loaded(share.file);
      })
      .catch(({ status }) => {
        if (status === 401) {
          runInAction(() => (this.loading = false));
        } else {
          notificationStore.showError('Something went wrong, please try again later.');
        }
      });
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      loading: observable,
      loaded: action
    });
  }

  loaded = async (diagram) => {
    this.loading = false;
    shareStore.setDiagramByShareView(diagram);
    currentDiagramStore.trackDiagramView('share', {
      fileType: FILE_TYPE_MAPPING[diagram.type]
    });
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    diagramControlStore.reset();
  }

  handleModelerLoaded = (modeler) => {
    diagramControlStore.setModeler(modeler);
  };

  get additionalModules() {
    if (shareStore.shareViewDiagram.type === 'DMN') {
      return {
        drd: [minimapModule]
      };
    }

    return [minimapModule, documentationOverlayModule];
  }

  render() {
    const diagram = shareStore.shareViewDiagram;

    if (this.loading) {
      return <Spinner fullHeight />;
    }

    if (!diagram) {
      return <PasswordProtectedShare shareId={this.shareId} onSuccess={this.loaded} />;
    }

    return (
      <Fragment>
        <Helmet>
          <title>{getPageTitle(diagram.name)}</title>
        </Helmet>
        <TopBar.Breadcrumbs>
          <Breadcrumb title={diagram.name} variant="text" data-test="breadcrumb-diagram" />
        </TopBar.Breadcrumbs>
        <Modeler
          fitViewPort
          isViewOnly
          diagram={diagram}
          initialContent={diagram.content}
          additionalModules={this.additionalModules}
          bottomLeft={<CamundaLogo width="32" height="32" />}
          bottomRight={<DiagramControls />}
          onModelerLoaded={this.handleModelerLoaded}
        />
      </Fragment>
    );
  }
}

export default withRouter(observer(Share));
