/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { InlineNotification } from '@carbon/react';

import pluralize from 'utils/pluralize';

const DuplicatesWarning = ({ duplicates, hasOOTBDuplicate }) => {
  const templatePlural = pluralize('template', duplicates.length);
  const idPlural = pluralize('ID', duplicates.length);
  const anString = duplicates.length > 1 ? '' : 'an';
  const verbString = duplicates.length > 1 ? 'have' : 'has';
  const location = hasOOTBDuplicate ? 'globally available Connector template' : 'Connector template in your project';

  const message = `The Connector ${templatePlural} you are trying to import ${verbString} ${anString} identical ${idPlural} as an existing ${location}. To resolve the conflict, choose from the actions below.`;

  return (
    <div>
      <InlineNotification kind="warning" title="Connector already exists" lowContrast hideCloseButton>
        <div>{message}</div>
      </InlineNotification>
    </div>
  );
};

DuplicatesWarning.propTypes = {
  duplicates: PropTypes.array,
  hasOOTBDuplicate: PropTypes.bool
};

export default DuplicatesWarning;
