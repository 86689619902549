/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Layer } from '@carbon/react';

import { Link as BaseLink } from 'primitives';

export const Wrapper = styled(Layer)`
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: ${({ $isTrialBanner }) => ($isTrialBanner ? '3rem' : '4rem')};
  padding: ${({ $isTrialBanner }) => ($isTrialBanner ? '0rem' : '1rem')};
  background: var(--cm-color-white-base);
  border-bottom: 1px solid var(--cds-border-subtle-01);

  inset: 3rem 0 0 0;

  span.cds--popover-container {
    display: inline-block;
    position: absolute;
    right: 8px;
  }
`;

export const Link = styled(BaseLink)`
  text-decoration: none;
  margin-left: 8px;
`;

export const Message = styled.span`
  color: var(--cds-text-primary, #161616);
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px);
`;
