/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { C3ClusterTag } from '@camunda/camunda-composite-components';
import { Link } from '@carbon/react';

export const elevatedPermissionsRequiredText = (
  <>
    Only organization admins and organization owners can directly deploy to <C3ClusterTag stage={'prod'} /> clusters.
    Try a different cluster or request more permissions.{' '}
    <Link
      aria-describedby="elevated-permissions-required-warning"
      href="https://docs.camunda.io/docs/components/modeler/web-modeler/run-or-publish-your-process/"
      target="_blank"
      rel="noreferrer"
    >
      Learn more
    </Link>
    .
  </>
);
