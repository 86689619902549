/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Launch } from '@carbon/icons-react';

import * as Styled from './LandscapeEmptyState.styled';

export const LandscapeEmptyState = () => {
  return (
    <Styled.LandscapeEmptyStateContainer>
      <h4>No process found</h4>
      <Styled.LandscapeEmptyStateDescription>
        Create processes in your project/folder to view the landscape. The landscape enables you to view relations
        between your processes and related sub-processes.
      </Styled.LandscapeEmptyStateDescription>
      <Styled.LandscapeEmptyStateLink
        href="https://docs.camunda.io/docs/components/modeler/web-modeler/process-landscape-visualization/"
        target="_blank"
        renderIcon={() => <Launch aria-label="Launch" />}
      >
        Learn more about process landscapes
      </Styled.LandscapeEmptyStateLink>
    </Styled.LandscapeEmptyStateContainer>
  );
};
