/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { forwardRef, useRef, useState } from 'react';
import { Heading, Section, Tag } from '@carbon/react';

import { useAreAiFeaturesEnabled } from 'experiments/hooks';
import { AISparkle } from 'icons';
import { NonModalDialog } from 'primitives';
import { organizationStore } from 'stores';
import { trackingService } from 'services';

import * as Styled from './CamundaAIButton.styled';

export default function CamundaAIButton({ location }) {
  const areAIFeaturesEnabled = useAreAiFeaturesEnabled();

  return areAIFeaturesEnabled ? (
    <AIWidgetTrigger location={location} />
  ) : (
    <AIWidgetFeatureDiscovery location={location} />
  );
}

const _Button = forwardRef((props, ref) => {
  return (
    <Styled.CamundaAIButton
      hasIconOnly
      iconDescription="Camunda Docs AI"
      kind="secondary"
      renderIcon={() => {
        return <AISparkle />;
      }}
      size="sm"
      ref={ref}
      {...props}
    />
  );
});

const AIWidgetTrigger = ({ location }) => {
  return (
    <_Button
      id="camunda-ai-button"
      onClick={() => trackingService.trackDocsAIOpen({ enabled: true, from: location })}
    />
  );
};

const AIWidgetFeatureDiscovery = ({ location }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const buttonRef = useRef();

  return (
    <>
      <_Button
        onClick={() => {
          toggle();
          trackingService.trackDocsAIOpen({ enabled: false, from: location });
        }}
        ref={buttonRef}
      />
      <NonModalDialog
        maxWidth="350px"
        onClose={() => setIsOpen(false)}
        onSubmit={() => {
          window.open(`${organizationStore.organizationManagementPageUrl}/settings`);
        }}
        open={isOpen}
        primaryButtonText="Enable feature"
        anchorEl={buttonRef?.current}
        selectorPrimaryFocus=".ai-features-docs-link"
        passiveModal={!organizationStore.hasElevatedOrganizationPermissions}
      >
        <Styled.DialogBody>
          <Section level={4}>
            <Tag className="some-class" type="cool-gray">
              Alpha feature
            </Tag>
            <Heading>Camunda Docs AI</Heading>
            <Styled.Description>
              {organizationStore.hasElevatedOrganizationPermissions ? (
                <>
                  Click <strong>Enable feature</strong> to enable smart AI-powered chatbot that answers your Camunda
                  related questions.
                </>
              ) : (
                <>
                  A smart chatbot that answers your Camunda related questions.
                  <br />
                  <br />
                  Contact your admin to enable this feature.
                </>
              )}
            </Styled.Description>
            <Styled.Link
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/advanced-modeling/camunda-docs-ai/"
              aria-label="Read docs"
              target="_blank"
              className="ai-features-docs-link"
            >
              Read docs
            </Styled.Link>
          </Section>
        </Styled.DialogBody>
      </NonModalDialog>
    </>
  );
};
