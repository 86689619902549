/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useCallback } from 'react';
import { observer } from 'mobx-react';

import Tooltip from 'App/Pages/Diagram/Tooltip';

import * as Styled from './CommentsOverlay.styled';

const CommentsOverlay = ({ bpmnjs, element }) => {
  const eventBus = bpmnjs.get('eventBus');

  const onClick = useCallback(
    (event) => {
      eventBus.fire('contextPad.showComments', {
        element,
        originalEvent: event
      });
    },
    [element, eventBus]
  );

  return (
    <Tooltip label="Show comments">
      <Styled.CommentIcon
        onClick={onClick}
        data-test="comment-overlay"
        title="Open comments tab"
        role="button"
        aria-label="Open comments tab"
      />
    </Tooltip>
  );
};

export default observer(CommentsOverlay);
