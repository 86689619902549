/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { folderService } from 'services';

export default function useLoadFolder(folderId) {
  const [folder, setFolder] = useState(null);

  useEffect(() => {
    if (folderId) {
      (async () => {
        try {
          const folder = await folderService.fetchById(folderId);
          setFolder(folder);
        } catch {
          console.error('Failed to fetch folder!');
        }
      })();
    }
  }, [folderId]);

  return folder;
}
