/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { findExtensionElement } from './extension-elements-util';

export default (node, propertyName) => {
  const zeebeProperties = findExtensionElement(node, 'zeebe:Properties');

  if (!zeebeProperties) {
    return false;
  }

  return zeebeProperties.get('properties').find((property) => {
    return property.get('name') === propertyName;
  });
};
