/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import { TopBar, Breadcrumb } from 'components';
import { getPageTitle } from 'utils/helpers';

import * as Styled from './NotificationSettings.styled';
import Notifications from './components/Notifications';

const NotificationSettings = () => {
  return (
    <Styled.Container>
      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" to="/" />
        <Breadcrumb title="Email notification settings" variant="text" isBold />
      </TopBar.Breadcrumbs>

      <Helmet>
        <title>{getPageTitle('Email notification settings')}</title>
      </Helmet>

      <Styled.Title>Email notification settings</Styled.Title>

      <Styled.Grid>
        <Notifications />
      </Styled.Grid>
    </Styled.Container>
  );
};

export default observer(NotificationSettings);
