/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

const MIME_TYPES = {
  form: 'application/json',
  json: 'application/json',
  svg: 'image/svg+xml',
  bpmn: 'application/bpmn+xml',
  dmn: 'application/dmn+xml'
};

/**
 * Uses an anchor element as backing to trigger the download of the file
 *
 * @param {String} content The file content that should be saved to the computer.
 * @param {String} name The file name for the download.
 */
export function downloadFromBackingAnchorElement(content, name) {
  const anchor = document.createElement('a');

  // Convert content to a Blob if it's a JSON object
  const blobContent =
    content instanceof Blob ? content : new Blob([JSON.stringify(content)], { type: 'application/json' });

  anchor.setAttribute('id', 'runtime-download');
  anchor.setAttribute('download', name);
  anchor.setAttribute('href', URL.createObjectURL(blobContent));

  document.body.append(anchor);
  anchor.click();

  setTimeout(() => {
    document.body.removeChild(anchor);
    URL.revokeObjectURL(anchor.href);
  }, 5000);
}

/**
 * Saves a given content string to the user's computer with the
 * correct file extension, based on the file name.
 *
 * @param {String} content The file content that should be saved to the computer.
 * @param {String} name The file name for the download.
 */
export default function saveFile(content, name) {
  if (!name || !content) {
    return false;
  }

  if (!name.endsWith('png')) {
    const ext = name.split(/\./g).pop();
    content = new Blob([content], {
      type: `${MIME_TYPES[ext]};charset=utf-8`
    });
  }

  downloadFromBackingAnchorElement(content, name);

  return true;
}
