/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Modal } from '@carbon/react';
import { spacing05 } from '@carbon/layout';

export const IdpProjectSettingsModal = styled(Modal)`
  .cds--modal-container {
    max-width: 740px;
  }
`;

export const IdpProjectSettingsModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing05};
`;
