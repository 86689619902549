/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

/**
 * Introduced to fix the a11y violation arising because of not using @carbon/react Tabs component
 * as it is supposed to be.
 *
 * The reason we cannot use it in this case is because our Modeler component (probably because of flex: 1)
 * does not render properly inside TabPanel component.
 *
 * Without TabPanel component aria-controls attribute value from Tab component does not have a matching value in the DOM tree.
 * We use this custom hook to get the value and set it to our custom TabPanels component to avoid the violation.
 *
 * Related to https://github.com/camunda/web-modeler/issues/6533#issuecomment-1971373343
 */
export default function useCarbonTabsAriaControls(ref, className, loading) {
  const [ariaControls, setAriaControls] = useState([]);

  useEffect(() => {
    if (!ref || !ref.current || loading) return;

    const buttons = ref.current.querySelectorAll(`button.${className}`);
    const controls = Array.from(buttons).map((button) => button.getAttribute('aria-controls'));

    setAriaControls(controls);
  }, [ref.current, className, loading]);

  return ariaControls;
}
