/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isExecuteMode } from 'App/Pages/Diagram/Deployment/executeModal/modeUtils';
import { Alert, Link } from 'primitives';
import { organizationStore } from 'stores';

export default function NoPermissionMessage({ mode }) {
  const contactOrgManagement = (
    <>
      Contact the organization owner (see{' '}
      <Link href={organizationStore.organizationManagementPageUrl} target="_blank" className="bold">
        Organization management
      </Link>{' '}
      page)
    </>
  );
  return (
    <Alert type="danger">
      {isExecuteMode(mode) ? (
        <>The permission to start a process instance is missing. {contactOrgManagement} to request the permission.</>
      ) : (
        <>
          The permissions to access a cluster or to create a process on a cluster are missing. {contactOrgManagement} to
          request these permissions.
        </>
      )}
    </Alert>
  );
}
