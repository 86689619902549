/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

const AVATAR_COLORS = [
  '#14D890',
  '#FF5400',
  '#FF8B8B',
  '#61BFAD',
  '#E54B4B',
  '#167C80',
  '#B7E3E4',
  '#005397',
  '#32B67A',
  '#FACA0C',
  '#F3C9DD',
  '#0BBCD6',
  '#BFB5D7',
  '#BEA1A5',
  '#F0CF61',
  '#0E38B1',
  '#A6CFE2',
  '#FF1163',
  '#C7C6C4',
  '#DB9AAD',
  '#F1C3B8',
  '#EF3E4A',
  '#C0C2CE',
  '#EEC0DB',
  '#B6CAC0',
  '#F7BC12',
  '#FDF06F',
  '#17C37B',
  '#2C3979',
  '#00C864',
  '#E88565',
  '#FFEFE5',
  '#F4C7EE',
  '#77EEDF',
  '#E57066',
  '#EED974',
  '#FBFE56',
  '#A7BBC3',
  '#3C485E',
  '#055A5B',
  '#D3E8E1',
  '#CBA0AA',
  '#9C9CDD',
  '#20AD65',
  '#E75153',
  '#0096D7',
  '#022EFF',
  '#00B28B',
  '#C6D7C7',
  '#BEF6E9',
  '#776EA7',
  '#EF303B',
  '#1812D6',
  '#F1D3D3',
  '#7DE0E6',
  '#3A745F',
  '#CE7182',
  '#00B091',
  '#002CFC',
  '#75FFC0',
  '#FB9B2A',
  '#FF8FA4',
  '#083EA7',
  '#6E6EF7',
  '#19AAD1',
  '#FC3C2D',
  '#864BFF',
  '#EF5B09',
  '#97B8A3',
  '#FFD101',
  '#C26B6A',
  '#FF4C06',
  '#0696FF',
  '#10CA00',
  '#00A67C',
  '#384D9D',
  '#E10000',
  '#F64A00',
  '#00FDFF',
  '#B18AE0',
  '#96D0FF',
  '#5400C6',
  '#FF6B61',
  '#EEB200',
  '#B396DB',
  '#DCB2A3',
  '#618133',
  '#98C1AE',
  '#A80000',
  '#00458A',
  '#34FF6D',
  '#34C8FF',
  '#A31B00',
  '#FFAD1E',
  '#79DDF2',
  '#9879D0',
  '#003CFE',
  '#FE2A00',
  '#0096D7',
  '#FF5400',
  '#14D890'
];

const getColorIndex = (name) => {
  let num = 0;

  for (let i = 0; i < name.length; i++) {
    num += name.charCodeAt(i);
  }

  return num;
};

export const getColorForName = (name = '') => {
  const colorIndex = getColorIndex(name) % AVATAR_COLORS.length;

  return AVATAR_COLORS[colorIndex] || AVATAR_COLORS[0];
};

export const isColorDark = (hex = '#ffffff') => {
  hex = hex.substring(1, 7);

  const hRed = parseInt(hex.substring(0, 2), 16);
  const hGreen = parseInt(hex.substring(2, 4), 16);
  const hBlue = parseInt(hex.substring(4, 6), 16);

  const brightness = (hRed * 299 + hGreen * 587 + (hBlue + 114)) / 1000;

  return brightness > 130; // 130 is the recommended threshold.
};
