/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineLoading, Accordion, Tag } from '@carbon/react';
import { CircleSolid, CheckmarkFilled, ErrorFilled, WarningFilled } from '@carbon/icons-react';
import styled from 'styled-components';

export const InboundConnector = styled.div`
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .cds--snippet-container {
    min-height: 0 !important;
  }

  .cds--snippet--multi .cds--snippet-container pre {
    padding-bottom: 0;
  }
`;

export const Loading = styled(InlineLoading)`
  height: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

export const ActiveInboundConnectorTitle = styled.div`
  margin-bottom: var(--spacing-small);
`;

export const ActiveInboundConnector = styled.div`
  margin-bottom: var(--spacing-small);
`;

export const ConnectorDeployedMessage = styled.div`
  margin-bottom: var(--spacing-medium);
`;

export const InboundAccordion = styled(Accordion)`
  .cds--accordion__wrapper {
    max-block-size: 0px !important; /* To prevent overflow when content dynamically changes */
  }

  .cds--accordion__content {
    max-block-size: 0px !important; /* To prevent overflow when content dynamically changes */
  }

  .cds--accordion__item--active .cds--accordion__wrapper {
    max-block-size: 100% !important; /* To prevent overflow when content dynamically changes */
  }

  .cds--accordion__item--active .cds--accordion__content {
    max-block-size: 100% !important; /* To prevent overflow when content dynamically changes */
  }

  .cds--accordion__title {
    padding: 8px 0px;
  }

  .cds--accordion__content {
    padding-inline-end: 0 !important;
    padding-inline-start: 3px;
    padding-left: 3px;
  }

  .cds--actionable-notification--toast {
    max-inline-size: 100%;
    min-inline-size: 100%;
  }
`;

export const CopySupportInfo = styled.div`
  margin-top: var(--spacing-small);
  display: flex;
  gap: 5px;
  color: var(--cds-blue-60);
  font-size: 12px;
  padding-bottom: 8px;
  cursor: pointer;
`;

export const LogMetaInfo = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const LogDate = styled.div`
  line-height: 25px;
`;

export const StatusIconGreen = styled(CircleSolid)`
  fill: var(--cds-support-success) !important;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
`;

export const StatusIconYellow = styled(CircleSolid)`
  fill: var(--cds-support-warning) !important;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
`;

export const StatusIconRed = styled(CircleSolid)`
  fill: var(--cds-support-error) !important;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
`;

export const StatusIconLoading = styled(InlineLoading)`
  display: inline-block;
  width: auto;
  height: 12px;
  position: relative;
  top: 2px;
  min-block-size: auto;
`;

export const InboundConnectorDetailErrorContainer = styled.div`
  margin-bottom: 16px;
  .cds--actionable-notification {
    box-shadow: none;
  }
`;

export const DetailContainer = styled.div`
  margin-bottom: 8px;
`;

export const DetailTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;

export const LastActivitiesTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const LogsContainer = styled.div`
  margin-bottom: 30px;
`;

export const LogContainer = styled.div`
  margin-bottom: 16px;
`;

export const CodeSnippetContainer = styled.div`
  margin-top: 8px;
`;

export const LogTag = styled(Tag)`
  margin-left: 0px;
  margin-right: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  margin-right: 8px;
`;

export const GreenCheckmarkFilled = styled(CheckmarkFilled)`
  fill: var(--cds-support-success, #24a148);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;

export const RedErrorFilled = styled(ErrorFilled)`
  fill: var(--cds-support-error, #da1e28);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;

export const YellowWarningFilled = styled(WarningFilled)`
  fill: var(--cds-support-warning, #f1c21b);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;
