/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Properties = styled.div`
  height: 100%;

  &.with-save-as {
    .bio-properties-panel-header-labels {
      margin-right: 96px;
    }
  }

  // Read-only
  .bio-properties-panel-input,
  .bio-properties-panel-feel-editor-container,
  .cm-editor {
    ${({ $readOnly }) =>
      $readOnly && 'cursor: not-allowed; opacity: 0.75; background-color: var(--color-grey-225-10-97);'}
  }
  .bio-properties-panel-group-header-button,
  .bio-properties-panel-remove-entry,
  .bio-properties-panel-open-feel-popup {
    ${({ $readOnly }) => $readOnly && 'display: none !important;'}
  }
  .bio-properties-panel,
  .cds--toggle,
  .cds--toggle__appearance {
    ${({ $readOnly }) => $readOnly && 'cursor: not-allowed !important;'}
  }
  .bio-properties-panel-group-header {
    ${({ $readOnly }) => $readOnly && 'cursor: default;'}
  }
  .bio-properties-panel-select,
  .bio-properties-panel-checkbox,
  .cds--toggle,
  .cds--toggle__appearance {
    ${({ $readOnly }) => $readOnly && 'pointer-events: none;'}
  }

  ${({ $isDocumentationOnly }) =>
    $isDocumentationOnly &&
    `
  .bio-properties-panel-group-header.empty {
    border-bottom: 1px solid var(--group-bottom-border-color);
  }

  .bio-properties-panel-group-header.empty.open {
    border-bottom: 0px;
  }

  .bio-properties-panel-group {
    height: calc(100% - 1px);
    border-bottom: 0px;
  }
  
  .bio-properties-panel-group-entries {
    height: calc(100% - 32px);
  }

  .bio-properties-panel-entry {
    height: 100%;
  }

  .bio-properties-panel-textarea {
    height: calc(100% - 32px);  
  }

  .bio-properties-panel-input {
    height: 100%;
  }
  `}
`;
