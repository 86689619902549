/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { notificationStore } from 'stores';
import { tracingService } from 'services';
import { FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION, FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION } from 'utils/constants';
import {
  getDuplicateResourceInProcessApplicationErrorMessage,
  isDuplicateResourceInProcessApplicationError
} from 'utils/helpers';

export function handleError(error, message) {
  notificationStore.showError(message);
  tracingService.traceError(error, message);
}

export function handleUpdateError(error) {
  if (isDuplicateResourceInProcessApplicationError(error)) {
    notificationStore.showError(getDuplicateResourceInProcessApplicationErrorMessage(error));
  } else if (error.status === 409) {
    notificationStore.showError(FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION);
  } else {
    notificationStore.showError(FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION);
  }
  tracingService.traceError(error, 'Error during update');
}

export function handleDeleteError(error) {
  if (error.status === 404) {
    notificationStore.showError("You don't have permission to delete this readme.");
  } else {
    notificationStore.showError("Yikes! Your readme couldn't be deleted. Please try again later.");
  }
  tracingService.traceError(error, 'Error during deletion');
}
