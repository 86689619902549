/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef, useState } from 'react';

import { CamundaAiSparkleButton } from 'experiments/ai-features';

import { CamundaAiChatbox } from './CamundaAiChatbox';
import * as Styled from './CamundaAiButtonWithCopilot.styled';

export default function CamundaAiButtonWithCopilot({
  location,
  modeler,
  saveDiagramContent,
  createNewVersion,
  isAdminOrEditor,
  diagramId
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatboxOpen = () => setIsOpen(!isOpen);
  const closeChatbox = () => setIsOpen(false);

  const buttonRef = useRef();

  return (
    <Styled.ChatboxGroup>
      <CamundaAiChatbox
        onClose={closeChatbox}
        buttonRef={buttonRef}
        isOpen={isOpen}
        location={location}
        modeler={modeler}
        saveDiagramContent={saveDiagramContent}
        createNewVersion={createNewVersion}
        isAdminOrEditor={isAdminOrEditor}
        diagramId={diagramId}
      />
      <CamundaAiSparkleButton
        iconDescription="Camunda AI"
        isOpen={isOpen}
        buttonRef={buttonRef}
        onClick={toggleChatboxOpen}
      />
    </Styled.ChatboxGroup>
  );
}
