/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import {
  lightGreen,
  lightOrange,
  lightRed,
  diffingAddedStyles,
  diffingChangedStyles,
  diffingRemovedStyles
} from 'utils/diffing';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  .added {
    background-color: ${lightGreen};
  }
  .changed {
    background-color: ${lightOrange};
  }
  .removed {
    background-color: ${lightRed};
  }

  ${diffingAddedStyles};
  ${diffingChangedStyles};
  ${diffingRemovedStyles};
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;
