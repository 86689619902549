/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { observer } from 'mobx-react';

import { Locked, Unlocked } from 'icons';

import * as Styled from './PasswordToggle.styled';

export default observer(({ diagram, togglePasswordControl, toggleRemovePasswordControl }) =>
  diagram.passwordProtected ? (
    <Fragment>
      <Locked height="16" />
      <Styled.Status data-test="share-password-status">Password-protected</Styled.Status>
      <Styled.Action data-test="share-password-toggle" onClick={toggleRemovePasswordControl(true)}>
        Remove
      </Styled.Action>
    </Fragment>
  ) : (
    <Fragment>
      <Unlocked height="16" />
      <Styled.Status data-test="share-password-status">Password protect</Styled.Status>
      <Styled.Action data-test="share-password-toggle" onClick={togglePasswordControl(true)}>
        Add
      </Styled.Action>
    </Fragment>
  )
);
