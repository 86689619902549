/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Avatar } from 'primitives';

import * as Styled from './CollaboratorItem.styled';

export default function CollaboratorItem({
  className,
  displayName,
  subtitle,
  children,
  onMouseDown,
  isInvited,
  isUserGroup
}) {
  return (
    <Styled.Collaborator
      className={className}
      onMouseDown={onMouseDown}
      data-test={`collaborator-${displayName || subtitle}`}
    >
      <Styled.Base>
        <Styled.AvatarContainer>
          {<Avatar fullname={displayName || subtitle} isInvited={isInvited} isUserGroup={isUserGroup} />}
        </Styled.AvatarContainer>
        <Styled.CollaboratorDetails>
          <Styled.CollaboratorName>{displayName}</Styled.CollaboratorName>
          {subtitle && <Styled.CollaboratorSubtitle>{subtitle}</Styled.CollaboratorSubtitle>}
        </Styled.CollaboratorDetails>
      </Styled.Base>
      {children}
    </Styled.Collaborator>
  );
}
