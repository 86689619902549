/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { milestoneService } from 'services';

export function useLastSavedVersion(connectorTemplate) {
  const [lastSavedVersion, setLastSavedVersion] = useState(null);

  const reloadLastSavedVersion = async () => {
    if (connectorTemplate) {
      const milestones = (await milestoneService.fetchByFileId(connectorTemplate.id)).sort(
        (a, b) => b.created - a.created
      );
      if (milestones?.length > 0) {
        setLastSavedVersion(milestones[0]);
      }
    }
  };

  useEffect(() => {
    reloadLastSavedVersion();
  }, [connectorTemplate]);

  return { lastSavedVersion, reloadLastSavedVersion };
}
