/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default function highlightUsers(comment, users) {
  if (users.length === 0) {
    return comment;
  }

  for (let i = 0; i < users.length; i++) {
    const user = users[i].username;
    const regex = new RegExp(`@${user}`, 'gi');

    if (comment.search(regex) !== -1) {
      comment = comment.replace(regex, `<b>@${user}</b>`);
    }
  }

  return comment;
}
