/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { clusterCreationModalStore } from 'features/create-cluster';
import { idpApplicationStore } from 'stores';
import { IdpClusterCreationModal } from 'App/Pages/Project/IdpApplication/idp-cluster-creation-modal/IdpClusterCreationModal';

import * as Styled from './SettingsModal.styled';

function SettingsModalCommon({
  modalContentExtensionSection,
  variant,
  isOpen,
  onClose,
  clusters,
  getAdditionalParametersWhenFinalizingCreation
}) {
  const { idpApplication } = idpApplicationStore;

  useEffect(() => {
    if (isOpen) {
      clusterCreationModalStore.open();
    }
  }, [isOpen]);

  const performRequest = async () => {
    if (!isSaveDisabled()) {
      await idpApplicationStore.updateCluster(...(getAdditionalParametersWhenFinalizingCreation?.() ?? []));
      handleClose();
    }
  };

  const handleClose = () => {
    clusterCreationModalStore.close();
    onClose();
  };

  const isSaveDisabled = () => {
    const [selectedClusterId] = getAdditionalParametersWhenFinalizingCreation?.() ?? [];
    return !selectedClusterId || selectedClusterId === idpApplication.clusterId;
  };

  if (clusters?.length === 0) {
    return <IdpClusterCreationModal onClose={handleClose} onSubmit={handleClose} />;
  }

  return (
    <Styled.IdpApplicationModal
      // @ts-expect-error TS2769
      variant={variant}
      open={isOpen}
      modalHeading="IDP application setting"
      primaryButtonText="Save"
      primaryButtonDisabled={isSaveDisabled()}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={handleClose}
    >
      {modalContentExtensionSection}
    </Styled.IdpApplicationModal>
  );
}

export default observer(SettingsModalCommon);
