/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import history from 'utils/history';

export default function useMainProcessRedirect({ inProcessApplicationContext, isMainProcess }) {
  const shouldShowRedirectModal = !isMainProcess && inProcessApplicationContext;

  const performRedirect = (mainProcessFileId, deployOnly) => {
    history.push(`/diagrams/${mainProcessFileId}?modal=${deployOnly ? 'deploy' : 'execute'}`);
  };

  return {
    shouldShowRedirectModal,
    performRedirect
  };
}
