/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, runInAction } from 'mobx';
import { Link } from '@carbon/react';

import history from 'utils/history';
import { processApplicationStore } from 'stores';
import { notificationStore } from 'components/NotificationSystem';
import gitSyncService, { GitOperationStatus } from 'features/git-sync/GitSyncService';
import GitSyncTrackingEvents from 'features/git-sync/GitSyncTrackingEvents';
import gitSettingsStore from 'features/git-sync/settings/GitSettingsStore';
import { processApplicationService, tracingService } from 'services';

export const GitSyncStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  FINISHED: 'finished',
  ERROR: 'error'
};

export const ConflictResolution = {
  OURS: 'ours',
  THEIRS: 'theirs'
};

const DefaultVersionTag = '1.0';
const DefaultConflictResolution = ConflictResolution.OURS;

class GitSyncStore {
  shouldShowDialog = false;
  versionTag = DefaultVersionTag;
  hasValidVersion = true;

  pullingStatus = GitSyncStatus.INACTIVE;
  hasConflicts = false;
  conflictResolution = DefaultConflictResolution;
  conflictDisclaimerAccepted = false;

  pullMessage = null;
  pushingStatus = GitSyncStatus.INACTIVE;

  versionTagMessage = null;

  #backgroundSyncNotification = null;
  #errorNotifications = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.shouldShowDialog = false;
    this.#disposeInternalState();
  }

  #disposeInternalState() {
    runInAction(() => {
      this.hasConflicts = false;
      this.conflictResolution = DefaultConflictResolution;
      this.conflictDisclaimerAccepted = false;
      this.pullMessage = null;
      this.pushMessage = null;
      this.versionTagMessage = null;
      this.versionTag = DefaultVersionTag;
      this.hasValidVersion = true;
      this.pullingStatus = GitSyncStatus.INACTIVE;
      this.pushingStatus = GitSyncStatus.INACTIVE;
    });
  }

  open({ force = false } = {}) {
    this.shouldShowDialog = true;
    GitSyncTrackingEvents.trackDialogOpen();

    if (force) {
      this.start();
    }
  }

  async start() {
    try {
      await this.#doPull();

      if (this.hasConflicts) {
        // The flow is interrupted here, in case of conflicts, the user must resolve the conflicts via the UI.
        return;
      }

      if (await this.#doPush()) {
        this.#handleCompletion();
      }
    } catch (error) {
      tracingService.traceError(error, 'Failed to start Git sync');
    }
  }

  async resolveConflict() {
    const proceedWithPushAndComplete = async () => {
      this.#markConflictsResolved();

      try {
        await this.#doPush({ shouldTrackErrors: false });
        this.#handleCompletion();
      } catch (error) {
        console.error(error);
        const errorMessage = error.message || 'An error occurred while resolving the conflict.';
        // @ts-expect-error TS2345
        GitSyncTrackingEvents.trackPushError(errorMessage, this.conflictResolution);
      }
    };

    runInAction(() => {
      this.pullMessage = 'Resolving conflict...';
    });

    // @ts-expect-error TS2345
    GitSyncTrackingEvents.trackPullStart(this.conflictResolution);

    if (this.conflictResolution === ConflictResolution.OURS) {
      // Do not pull and move straight to the push
      await proceedWithPushAndComplete();
      return;
    }

    try {
      await this.#doPull({ force: true, shouldTrackErrors: false });
      await proceedWithPushAndComplete();
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || 'An error occurred while resolving the conflict.';
      // @ts-expect-error TS2345
      GitSyncTrackingEvents.trackPullError(errorMessage, this.conflictResolution);
    }
  }

  acceptConflictDisclaimer(value) {
    this.conflictDisclaimerAccepted = value;
  }

  get shouldShowConflictDisclaimer() {
    return this.hasConflicts && this.conflictResolution === ConflictResolution.THEIRS;
  }

  get canResolveConflict() {
    if (this.pullingStatus === GitSyncStatus.ACTIVE) {
      return false;
    }

    return (
      this.hasConflicts &&
      (this.conflictResolution === ConflictResolution.OURS ||
        (this.conflictResolution === ConflictResolution.THEIRS && this.conflictDisclaimerAccepted))
    );
  }

  #markConflictsResolved() {
    // @ts-expect-error TS2345
    GitSyncTrackingEvents.trackPullComplete(this.conflictResolution);

    runInAction(() => {
      this.hasConflicts = false;
      this.pullingStatus = GitSyncStatus.FINISHED;
      this.pullMessage = this.conflictResolution === ConflictResolution.THEIRS ? 'Conflict resolved' : 'Pull skipped';
      this.conflictResolution = DefaultConflictResolution;
      this.conflictDisclaimerAccepted = false;
    });
  }

  #handleCompletion() {
    if (!this.shouldShowDialog) {
      notificationStore.showSuccess('Synchronization has been completed');
      this.#disposeInternalState();
    }

    this.#disposeErrorNotifications();
  }

  renderBackgroundSyncNotification() {
    this.#backgroundSyncNotification = notificationStore.info(
      {
        title: 'Sync in progress',
        content: (
          <>
            Your {gitSettingsStore.providerLabel} sync is ongoing and will continue in the background.
            <br />
            Changes from {gitSettingsStore.providerLabel} may update, and your modifications will sync later.
          </>
        )
      },
      {
        shouldPersist: true,
        action: {
          // @ts-expect-error TS2353
          label: 'Open sync',
          onClick: () => {
            this.open();
          }
        }
      }
    );
  }

  disposeBackgroundSyncNotification() {
    if (this.#backgroundSyncNotification) {
      notificationStore.disposeNotification(this.#backgroundSyncNotification.id, 'info', true);
      this.#backgroundSyncNotification = null;
    }
  }

  #validateVersion() {
    const isValid = this.versionTag.length > 0;

    runInAction(() => {
      this.hasValidVersion = isValid;
      this.versionTagMessage = isValid ? null : 'Please provide a version tag';
    });

    return isValid;
  }

  /**
   * Pulls the changes from the remote repository.
   * @returns {Promise<boolean>}
   */
  async #doPull({ force = false, shouldTrackErrors = true } = {}) {
    runInAction(() => {
      this.pullingStatus = GitSyncStatus.ACTIVE;
    });

    // @ts-expect-error TS2554
    GitSyncTrackingEvents.trackPullStart();

    try {
      const { status, message } = await gitSyncService.pull({
        processApplicationId: processApplicationStore.processApplication.id,
        force
      });

      switch (status) {
        case GitOperationStatus.UP_TO_DATE:
          runInAction(() => {
            this.pullingStatus = GitSyncStatus.FINISHED;
            this.pullMessage = 'Already up to date';
          });

          GitSyncTrackingEvents.trackPullSkip();
          break;

        case GitOperationStatus.SUCCESS:
          runInAction(() => {
            this.pullingStatus = GitSyncStatus.FINISHED;
          });

          // @ts-expect-error TS2554
          GitSyncTrackingEvents.trackPullComplete();
          break;

        case GitOperationStatus.CONFLICT:
          runInAction(() => {
            this.pullingStatus = GitSyncStatus.ERROR;
            this.pullMessage = 'There are conflicts that must be resolved.';
            this.hasConflicts = true;
          });
          break;

        case GitOperationStatus.ERROR:
          throw new Error(message || 'An error occurred while pulling the changes.');

        case GitOperationStatus.TIMEOUT:
          throw new Error('The operation timed out, please try again.');

        default:
          throw new Error('An error occurred while pulling the changes.');
      }

      return true;
    } catch (error) {
      const shortErrorMessage = 'An error occurred';
      const errorMessage = error.message || 'An error occurred while pulling the changes.';

      this.#handleOperationError('pull', shortErrorMessage, errorMessage, () => {
        if (shouldTrackErrors) {
          GitSyncTrackingEvents.trackPullError(errorMessage);
        }
      });

      throw error;
    }
  }

  /**
   * Pushes the changes to the remote repository.
   * @returns {Promise<boolean>}
   */
  async #doPush({ shouldTrackErrors = true } = {}) {
    runInAction(() => {
      this.pushingStatus = GitSyncStatus.ACTIVE;
    });

    GitSyncTrackingEvents.trackPushStart();

    try {
      await this.#handleVersionCreationOnPush();

      const { status, message } = await gitSyncService.push(processApplicationStore.processApplication.id);
      let action = null;
      switch (status) {
        case GitOperationStatus.SUCCESS:
          runInAction(() => {
            this.pushingStatus = GitSyncStatus.FINISHED;
          });

          action = {
            // @ts-expect-error TS2353
            label: 'View version',
            onClick: () => {
              history.push(`/process-applications/${processApplicationStore.processApplication.id}/versions`);
            }
          };

          notificationStore.success(
            {
              title: `Before pushing, a version ${this.versionTag} has been created`
            },
            {
              action
            }
          );

          GitSyncTrackingEvents.trackPushComplete();
          break;

        case GitOperationStatus.ERROR:
          throw new Error(message || 'An error occurred while pushing the changes.');

        case GitOperationStatus.TIMEOUT:
          throw new Error('The operation timed out, please try again.');

        default:
          throw new Error('An error occurred while pushing the changes.');
      }

      return true;
    } catch (error) {
      const shortErrorMessage = 'An error occurred';
      const errorMessage = error.message || 'An error occurred while pushing the changes.';

      this.#handleOperationError('push', shortErrorMessage, errorMessage, () => {
        if (shouldTrackErrors) {
          GitSyncTrackingEvents.trackPushError(errorMessage);
        }
      });

      throw error;
    }
  }

  async close() {
    this.shouldShowDialog = false;

    this.#disposeErrorNotifications();

    if (this.isSyncing) {
      GitSyncTrackingEvents.trackCancel();
      return;
    }

    await this.#reloadIfPullingFinished();

    // Reset the store if the sync is finished, otherwise we keep the state for the next open
    if (this.hasFinished || (!this.isPulling && !this.isPushing)) {
      this.#disposeInternalState();
    }
  }

  async #reloadIfPullingFinished() {
    if (this.pullingStatus === GitSyncStatus.FINISHED) {
      await processApplicationStore.init(processApplicationStore.processApplication.id);
    }
  }

  setVersionTag(versionTag) {
    this.versionTag = versionTag;
    this.#validateVersion();
  }

  setConflictResolution(resolution) {
    this.conflictResolution = resolution;
    this.conflictDisclaimerAccepted = false;
  }

  get status() {
    if (this.pullingStatus === GitSyncStatus.ERROR || this.pushingStatus === GitSyncStatus.ERROR) {
      return GitSyncStatus.ERROR;
    }

    if (this.pullingStatus === GitSyncStatus.FINISHED && this.pushingStatus === GitSyncStatus.FINISHED) {
      return GitSyncStatus.FINISHED;
    }

    return this.isPulling || this.isPushing ? GitSyncStatus.ACTIVE : GitSyncStatus.INACTIVE;
  }

  get isSyncing() {
    return this.status === GitSyncStatus.ACTIVE || this.hasConflicts;
  }

  get hasErrors() {
    return this.status === GitSyncStatus.ERROR;
  }

  get hasFinished() {
    return this.status === GitSyncStatus.FINISHED;
  }

  get isPulling() {
    return this.pullingStatus !== GitSyncStatus.INACTIVE;
  }

  get isPushing() {
    return this.pushingStatus !== GitSyncStatus.INACTIVE;
  }

  async #handleVersionCreationOnPush() {
    try {
      await processApplicationService.createProcessApplicationVersion(
        processApplicationStore.processApplication.id,
        this.versionTag
      );
    } catch (ex) {
      // @ts-expect-error TS2554
      tracingService.traceError(ex);
      throw new Error('An error occurred while creating the version.');
    }
  }

  /**
   * Handles an operation error by setting the status to error and displaying an error
   * @param operation The operation that failed
   * @param shortMessage The short error
   * @param errorMessage The detailed error message
   * @param trackingCallback The callback to execute when the error is tracked
   */
  #handleOperationError(
    operation,
    shortMessage = 'An error occurred',
    errorMessage = 'Something wrong happened.',
    trackingCallback
  ) {
    runInAction(() => {
      if (operation === 'pull') {
        this.pullingStatus = GitSyncStatus.ERROR;
        this.pullMessage = shortMessage;
      } else if (operation === 'push') {
        this.pushingStatus = GitSyncStatus.ERROR;
        this.pushMessage = shortMessage;
      } else {
        throw new Error('Invalid operation');
      }
    });

    trackingCallback?.();
    this.#renderErrorNotification(errorMessage, () => {
      this.start();
    });
  }

  /**
   * Renders an error notification with an optional retry action.
   * @param errorMessage The error message to display.
   * @param [retryCallback] The action to execute when the user clicks on the retry button.
   */
  #renderErrorNotification(errorMessage, retryCallback) {
    let content;
    let action;
    let duration = 3000;
    let shouldPersist = false;

    this.#disposeErrorNotifications();

    if (retryCallback) {
      duration = undefined;
      shouldPersist = true;
      content = (
        <>
          {errorMessage}
          <br />
          <Link href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/git-sync/" target="_blank">
            Learn more
          </Link>
        </>
      );
      action = {
        // @ts-expect-error TS2353
        label: 'Retry',
        onClick: retryCallback
      };
    } else {
      content = errorMessage;
      action = {
        label: 'Learn more',
        onClick: () => {
          window.open('https://docs.camunda.io/docs/next/components/modeler/web-modeler/git-sync/');
        }
      };
    }

    const notification = notificationStore.error(
      {
        title: 'Something went wrong',
        content
      },
      {
        replacesGroup: true,
        duration,
        shouldPersist,
        action
      }
    );

    this.#errorNotifications.push(notification);
  }

  #disposeErrorNotifications() {
    this.#errorNotifications.forEach((notification) => {
      notificationStore.disposeNotification(notification.id, 'error', true);
    });

    this.#errorNotifications = [];
  }
}

export default new GitSyncStore();
