/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, Link, RadioTile, Stack, TextInput } from '@carbon/react';

import { idpProjectStore } from 'stores';
import { IdpDocumentAutomation, IdpExtraction } from 'icons';

import * as Styled from './CreationModal.styled';

const IDP_PROJECT_TYPES = [
  {
    id: 'extraction',
    title: 'Extraction',
    value: 'EXTRACTION',
    description: () => {
      return (
        <>
          <span>
            Upload sample document, set up a taxonomy fields, and annotate document to enable accurate data extraction.
          </span>
          &nbsp;
          <Link href="" target="_blank">
            Learn more about extraction
          </Link>
        </>
      );
    },
    disabled: false
  },
  {
    id: 'document-automation',
    title: 'Document automation',
    value: 'DOCUMENT_AUTOMATION',
    description: () => {
      return (
        <>
          <span>
            Use LLMs to automatically sort documents into predefined types for faster and more accurate organization.
          </span>
          &nbsp;
          <Link href="" target="_blank">
            Learn more about document automation
          </Link>
        </>
      );
    },
    disabled: true
  }
];
const IDP_PROJECT_EXTRACTION_METHODS = [
  {
    id: 'form-extraction',
    title: 'Form Extraction',
    value: 'FORM',
    description: 'Extract data from documents with a consistent layout.',
    disabled: true
  },
  {
    id: 'unstructured-data-extraction',
    title: 'Unstructured data extraction',
    value: 'UNSTRUCTURED_DATA',
    description: 'Extract Unstructured data into structured formats.',
    disabled: false
  }
];

function CreationModal() {
  const [type, setType] = useState(IDP_PROJECT_TYPES[0].value);
  const [name, setName] = useState('');
  const [extractionMethod, setExtractionMethod] = useState(IDP_PROJECT_EXTRACTION_METHODS[1].value);

  const hasValidIdpProjectName = name.trim().length;

  const closeModal = () => {
    idpProjectStore.setIsCreationModalVisible(false);
    setName('');
  };

  const performRequest = async () => {
    if (!hasValidIdpProjectName) {
      return;
    }

    await idpProjectStore.finalizeCreation(name, type, extractionMethod);
    closeModal();
  };

  const getTileIconByProjectType = (idpProjectType) => {
    switch (idpProjectType.id) {
      case 'extraction':
        return <IdpExtraction width="15" height="15" />;
      case 'document-automation':
        return <IdpDocumentAutomation width="15" height="15" />;
    }
  };

  const IdpProjectTypeTile = ({ idpProjectType }) => {
    return (
      <RadioTile
        key={idpProjectType.id}
        value={idpProjectType.value}
        checked={idpProjectType.value === type}
        onChange={() => setType(idpProjectType.value)}
        disabled={idpProjectType.disabled}
      >
        <Stack>
          {getTileIconByProjectType(idpProjectType)}
          &nbsp;
          <b>{idpProjectType.title}</b>
          &nbsp;
          {idpProjectType.description()}
        </Stack>
      </RadioTile>
    );
  };

  const IdpProjectTypeSelectorSection = () => {
    return (
      <>
        <b>Select project type</b>
        <Styled.IdpProjectTypeSelectorContainer>
          {IDP_PROJECT_TYPES.map((idpProjectType) => (
            <IdpProjectTypeTile key={idpProjectType.id} idpProjectType={idpProjectType} />
          ))}
        </Styled.IdpProjectTypeSelectorContainer>
      </>
    );
  };

  return (
    <Styled.IdpProjectModal
      open={idpProjectStore.isCreationModalVisible}
      modalHeading="Create new project"
      primaryButtonText="Create"
      primaryButtonDisabled={!hasValidIdpProjectName}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
    >
      <Styled.IdpProjectModalContent>
        <IdpProjectTypeSelectorSection />
        <b>Project details</b>
        <TextInput
          autoComplete="off"
          data-modal-primary-focus
          enableCounter
          id="idp-project-name"
          labelText="Name"
          placeholder="The name should reflect the project document type. i.e. Invoice"
          value={name}
          maxCount={100}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Dropdown
          id="idp-project-extraction-method"
          titleText="Extraction method"
          initialSelectedItem={IDP_PROJECT_EXTRACTION_METHODS[1]}
          label="Extraction method"
          aria-label="Extraction method"
          onChange={({ selectedItem }) => {
            setExtractionMethod(selectedItem.value);
          }}
          items={IDP_PROJECT_EXTRACTION_METHODS}
          itemToString={(item) => (item ? item.title : '')}
          itemToElement={(item) => (
            <>
              <b>{item.title}</b>
              <br />
              <span>{item.description}</span>
            </>
          )}
        />
      </Styled.IdpProjectModalContent>
    </Styled.IdpProjectModal>
  );
}

export default observer(CreationModal);
