/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import { processApplicationStore, currentDiagramStore, formStore, organizationStore } from 'stores';
import getIdFromSlug from 'utils/getIdFromSlug';
import { CodeEditor } from 'components';
import { trackingService } from 'services';
import { useLocalStorage } from 'hooks';
import { ResizablePanel } from 'primitives';
import { getPageTitle, isContainedInProcessApplication } from 'utils/helpers';
import { useAreAiFeaturesEnabled } from 'experiments/hooks';

import {
  useMakePropertiesPanelInputsReadOnly,
  useSwitchToAvailableModeForUserWithPermissionToOnlyReadProperties
} from './ReadOnlyProperties';
import Header from './Header';
import ActionBar from './ActionBar';
import FormErrorPanel from './ErrorPanel';
import EDITOR_AI_ASSISTANT_MODULES from './editorAIAssistantModules';
import * as Styled from './Form.styled';

const DEFAULT_LAYOUT = {
  'form-preview': {
    open: false
  },
  'form-input': {
    open: false
  },
  'form-output': {
    open: false
  }
};
const COLLAPSED_PANEL_SIZE = 35;
const ACTION_BAR_HEIGHT = 56;
const TOP_BAR_HEIGHT = 47;

const Form = () => {
  // @ts-expect-error TS2339
  const { slug } = useParams();

  const formRef = useRef();
  const layoutChangeTriggeredByRef = useRef();

  const {
    form,
    formEditor,
    formPlayground,
    hasEditPermission,
    hasPermissionToOnlyViewProperties,
    lintErrors,
    loading
  } = formStore;

  const [bottomPanelHeight, setBottomPanelHeight] = useState(0);

  const isFormViewerReady = !loading && !hasEditPermission && !hasPermissionToOnlyViewProperties; // Only view permission
  const isFormPlaygroundReady = !loading && (hasEditPermission || hasPermissionToOnlyViewProperties); // Has edit permission or can view properties
  const { isOrganizationInfoLoaded } = organizationStore;

  useAttachViewer(isFormViewerReady, formEditor, formRef);

  const [mode, switchMode, setLayout] = useModeSwitcher(
    isFormPlaygroundReady,
    formPlayground,
    formRef,
    layoutChangeTriggeredByRef
  );

  useEffect(() => {
    (async () => {
      if (isOrganizationInfoLoaded) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const isAiFeaturesEnabled = useAreAiFeaturesEnabled();
        const additionalModules = isAiFeaturesEnabled ? EDITOR_AI_ASSISTANT_MODULES : [];
        await formStore.init(getIdFromSlug(slug), setLayout, layoutChangeTriggeredByRef, additionalModules);
        formStore.trackFormView('direct');
      }
    })();

    trackingService.trackPageView('form');

    return () => formStore.reset();
  }, [slug, isOrganizationInfoLoaded]);

  useEffect(() => {
    (async () => {
      if (form && isContainedInProcessApplication(form)) {
        await processApplicationStore.init(form.folderId, true);
      }
    })();

    return () => processApplicationStore.reset();
  }, [form]);

  const keyboardShortcut = useKeyboardShortcut(isFormPlaygroundReady, mode, switchMode);
  useSwitchToAvailableModeForUserWithPermissionToOnlyReadProperties(
    hasPermissionToOnlyViewProperties,
    isFormPlaygroundReady,
    mode,
    switchMode
  );
  useMakePropertiesPanelInputsReadOnly(hasPermissionToOnlyViewProperties, isFormPlaygroundReady);

  return (
    <>
      <Styled.Wrapper>
        <Helmet>
          <title>{getPageTitle(form?.name)}</title>
        </Helmet>

        <Header />

        <ActionBar
          showModeSwitcher={isFormPlaygroundReady}
          mode={mode}
          switchMode={switchMode}
          keyboardShortcut={keyboardShortcut}
          calledBy={Array.isArray(form?.calledBy) ? form?.calledBy : []}
        />

        {!loading && (
          <>
            {mode === 'jsonEditor' && (hasEditPermission || hasPermissionToOnlyViewProperties) ? (
              // @ts-expect-error TS2741
              <CodeEditor value={form?.content} readOnly />
            ) : (
              <Styled.FormContainer
                ref={formRef}
                style={
                  bottomPanelHeight > COLLAPSED_PANEL_SIZE
                    ? {
                        maxHeight: `calc(100vh - ${bottomPanelHeight}px - ${ACTION_BAR_HEIGHT + TOP_BAR_HEIGHT}px)`
                      }
                    : {
                        maxHeight: `calc(100vh - ${COLLAPSED_PANEL_SIZE + ACTION_BAR_HEIGHT + TOP_BAR_HEIGHT}px)`
                      }
                }
                // @ts-expect-error TS2769
                $readOnly={hasPermissionToOnlyViewProperties}
              />
            )}
          </>
        )}
      </Styled.Wrapper>

      {(hasEditPermission || hasPermissionToOnlyViewProperties) && mode !== 'jsonEditor' && (
        <ResizablePanel
          panelKey="error-panel"
          handleAriaLabel="Resize error panel"
          open={!currentDiagramStore.isErrorPanelCollapsed}
          background="white"
          position="bottom"
          minSize={235}
          maxSize={560}
          sizeClosed={COLLAPSED_PANEL_SIZE}
          onResize={setBottomPanelHeight}
          onOpenChange={(state) => currentDiagramStore.setIsErrorPanelCollapsed(state)}
        >
          <FormErrorPanel
            lintErrors={lintErrors}
            collapsed={currentDiagramStore.isErrorPanelCollapsed}
            onCollapse={(value) => {
              currentDiagramStore.setIsErrorPanelCollapsed(value);
            }}
          />
        </ResizablePanel>
      )}
    </>
  );
};

// Attach form viewer (only for users without edit permission)
const useAttachViewer = (isFormViewerReady, formEditor, formRef) => {
  useEffect(() => {
    if (isFormViewerReady) {
      formEditor?.attachTo(formRef.current);
    }
  }, [isFormViewerReady]);
};

// Mode switcher logic
const useModeSwitcher = (isFormPlaygroundReady, formPlayground, formRef, layoutChangeTriggeredByRef) => {
  const [mode, setMode] = useLocalStorage('formeditor_mode', 'design');
  const [layout, setLayout] = useLocalStorage('formeditor_layout', DEFAULT_LAYOUT);

  const switchMode = (modeVal, from) => {
    layoutChangeTriggeredByRef.current = from;
    if (modeVal === 'validate') {
      formPlayground?.open(['form-preview', 'form-input', 'form-output']);
    } else if (modeVal === 'design') {
      formPlayground?.collapse(['form-preview', 'form-input', 'form-output']);
    }
    setMode(modeVal);
  };

  useAttachOrDetachPlayground(isFormPlaygroundReady, formPlayground, mode, formRef);
  useSetModeOnLayoutChange(isFormPlaygroundReady, mode, setMode, layout);
  useRestorePreviousValidateLayout(isFormPlaygroundReady, formPlayground, mode, layout, layoutChangeTriggeredByRef);

  return [mode, switchMode, setLayout];
};

// Attach / detach form playground and event listeners when switching between modes
const useAttachOrDetachPlayground = (isFormPlaygroundReady, formPlayground, mode, formRef) => {
  const eventListenersAttachedRef = useRef(false);

  useEffect(() => {
    if (isFormPlaygroundReady) {
      if (mode === 'jsonEditor') {
        formPlayground?.detach();
      } else {
        formPlayground?.attachTo(formRef.current);

        const eventListenersAlreadyAttached = eventListenersAttachedRef.current;
        if (!eventListenersAlreadyAttached) {
          const formPreviewNode = formRef.current.querySelector('.cfp-preview-container');
          formPreviewNode?.addEventListener('focusout', () => {
            trackingService.trackFormEditorPreviewChanged(formStore.form.id);
          });

          const dataEditorNode = formRef.current.querySelector('.cfp-data-container');
          dataEditorNode?.addEventListener('focusout', () => {
            trackingService.trackFormEditorInputDataChanged(formStore.form.id);
          });

          eventListenersAttachedRef.current = true;
        }
      }
    }
  }, [mode, isFormPlaygroundReady]);
};

// Show the correct mode in the switcher when the layout is changed by directly clicking on the collapsible panel titles
const useSetModeOnLayoutChange = (isFormPlaygroundReady, mode, setMode, layout) => {
  useEffect(() => {
    if (isFormPlaygroundReady) {
      if (mode !== 'jsonEditor') {
        setMode(layout['form-preview']?.open ? 'validate' : 'design');
      }
    }
  }, [layout, isFormPlaygroundReady]);
};

// When opening the form page, if the previous mode was validate, restore the previous layout of the panels (collapsed/expanded)
const useRestorePreviousValidateLayout = (
  isFormPlaygroundReady,
  formPlayground,
  mode,
  layout,
  layoutChangeTriggeredByRef
) => {
  useEffect(() => {
    if (isFormPlaygroundReady) {
      if (mode === 'validate') {
        if (layout['form-preview']?.open) {
          const openedContainers = ['form-preview'];
          ['form-input', 'form-output'].forEach((key) => {
            if (layout[key]?.open) {
              openedContainers.push(key);
            }
          });
          layoutChangeTriggeredByRef.current = 'restore';
          formPlayground?.open(openedContainers);
        }
      }
    }
  }, [isFormPlaygroundReady, formPlayground]);
};

const useKeyboardShortcut = (isFormPlaygroundReady, mode, switchMode) => {
  useEffect(() => {
    const getNextMode = () => {
      let nextMode;
      switch (mode) {
        case 'design':
          nextMode = 'validate';
          break;
        case 'validate':
          nextMode = 'jsonEditor';
          break;
        case 'jsonEditor':
          nextMode = 'design';
          break;
        default:
          nextMode = 'design';
      }
      return nextMode;
    };

    const switchModeUsingKeyboard = (e) => {
      if (e.code === 'KeyP' && e.shiftKey && (e.metaKey || e.ctrlKey)) {
        switchMode(getNextMode(), 'keyboardShortcut');
        e.preventDefault();
      }
    };

    if (isFormPlaygroundReady) {
      document.addEventListener('keydown', switchModeUsingKeyboard);
    }

    return () => {
      document.removeEventListener('keydown', switchModeUsingKeyboard);
    };
  }, [isFormPlaygroundReady, mode]);

  const isMac = window.navigator.platform === 'MacIntel';
  return isMac ? '⌘ + ⇧ + P' : 'Ctrl + ⇧ + P';
};

export default observer(Form);
