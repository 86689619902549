/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const Sidebar = styled.aside`
  ${({ $isVisible }) => {
    return `width: ${$isVisible ? '400px' : '0px !important'};`;
  }}

  max-height: calc(100vh - 48px);

  position: relative;
  margin-top: -1px;
`;

export const Inner = styled.div`
  height: 100%;

  background-color: white;

  border-left: 1px solid var(--silver-darken-87);
  box-shadow: -3px 0 5px -4px rgba(0, 0, 0, 0.1);

  overflow: hidden;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  height: 50%;
`;

export const Title = styled.h3`
  margin: 0;
  padding: 8px var(--spacing-small);

  color: var(--grey-darken-33);
  background-color: var(--silver-base-97);

  font-size: 14px;

  border-bottom: 1px solid var(--silver-darken-87);
  border-top: 1px solid var(--silver-darken-87);
`;

export const Subtitle = styled.h3`
  margin: -1px 0 0;
  padding: 0 var(--spacing-small);
  position: relative;
  height: 30px;
  line-height: 30px;

  color: var(--grey-darken-33);

  font-size: 12px;

  border-top: 1px solid var(--silver-darken-87);
  border-bottom: 1px solid var(--silver-darken-87);
`;

export const Separator = styled.h4`
  font-size: 11px;
  font-weight: normal;

  color: var(--grey-darken-33);

  padding: 7px var(--spacing-small);
  margin: 0;
`;

export const Toggle = styled.button`
  position: absolute;
  left: 1px;
  top: 50%;
  width: 160px;
  height: 34px;

  background-color: var(--cm-color-white-base);
  color: var(--grey-darken-33);

  transform: rotate(-90deg) translateX(-50%) translateY(-100%);
  transform-origin: top left;

  font-weight: bold;
  font-size: 13px;
  font-family: inherit;

  border: 1px solid var(--silver-darken-87);
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  outline: none;
  cursor: pointer;
  z-index: 1;

  &:hover,
  &:focus {
    background-color: var(--silver-darken-94);
  }

  > svg {
    position: absolute;
    top: 5px;
    right: 5px;
    padding-bottom: 3px;
    box-sizing: content-box;

    color: var(--grey-darken-23);
    border-bottom: 1px solid var(--silver-darken-87);

    transform: rotate(90deg);
  }
`;

export const Footer = styled.footer`
  position: absolute;
  left: 1px;
  right: 0;
  bottom: 0;

  display: flex;

  background-color: var(--silver-base-97);
  border-top: 1px solid var(--silver-darken-87);

  padding: 8px var(--spacing-small);
`;
