/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { notificationStore } from 'stores';
import { tracingService, trackingService } from 'services';
import { README } from 'utils/constants';

import saveFile from './save-file';

/**
 * Downloads a given readme's markdown content to the user's computer.
 *
 * @param {Object} readme
 * @param {String} readme.id The readme's id
 * @param {String} readme.content The readme's markdown content
 * @param {String} readme.name The readme's name
 */
export function exportReadme({ id, content, name }) {
  try {
    const fileName = `${name}.md`;

    saveFile(content, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport({
      fileId: id,
      fileTypeKey: README,
      exportType: 'md',
      from: 'readme',
      fileCount: 1,
      fileSize: content.length
    });
  } catch (ex) {
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
    tracingService.traceError(ex, 'Failed to export readme');
  }
}
