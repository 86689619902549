/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { notificationStore } from 'stores';
import { tracingService, trackingService } from 'services';
import { CONNECTOR_TEMPLATE } from 'utils/constants';

import saveFile from './save-file';

/**
 * Downloads a given Connector template to the user's computer.
 *
 * @param {Object} template
 * @param {String} [template.id] The Connector template's id
 * @param {String} [template.content] The Connector template's stringified JSON content.
 * @param {String} [template.name] The Connector template's name.
 */
export default function exportConnectorTemplate({ id, content, name }) {
  try {
    const fileName = `${name}.json`;

    saveFile(content, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport({
      fileId: id,
      fileTypeKey: CONNECTOR_TEMPLATE,
      exportType: 'json',
      from: 'connector-template',
      fileCount: 1,
      fileSize: content.length
    });
  } catch (ex) {
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
    tracingService.traceError(ex, 'Failed to export Connector template');
  }
}
