/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import getZeebeProperty from './get-zeebe-property';
import { getExtensionElement } from './extension-elements-util';

// NOTE: This has been partially taken from https://github.com/camunda/bpmnlint-plugin-camunda-compat/blob/14fac9595bcc6005ead060c3f93625e0bec71ec2/rules/camunda-cloud/connector-properties/config.js#L28C5-L36C7
// and adapted to match also tasks that implement a connector.

const INBOUND_CONNECTOR_PROPERTY = 'inbound.type';
const KNOWN_INBOUND_CONNECTOR_TYPES = [
  'io.camunda:webhook:1',
  'io.camunda:connector-rabbitmq-inbound:1',
  'io.camunda:http-polling:1',
  'io.camunda:connector-kafka-inbound:1',
  'io.camunda:slack-webhook:1',
  'io.camunda:aws-sqs-inbound:1',
  'io.camunda:aws-sns-webhook:1'
];

const CONNECTOR_TASK_TYPE_REGEXP = /^io\.camunda.*/;

export default (element) => isInboundConnector(element, KNOWN_INBOUND_CONNECTOR_TYPES) || isConnectorTaskType(element);

const isInboundConnector = (node, names) => {
  const zeebeProperty = getZeebeProperty(node, INBOUND_CONNECTOR_PROPERTY);
  return Boolean(zeebeProperty && names.includes(zeebeProperty.get('value')));
};

const isConnectorTaskType = (node) => {
  const extensionProperty = getExtensionElement(node, 'zeebe:TaskDefinition');
  const type = extensionProperty?.get('type');
  return Boolean(type && CONNECTOR_TASK_TYPE_REGEXP.test(type));
};
