/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';
import { Modal } from '@carbon/react';

export const IdpApplicationModal = styled(Modal)`
  .cds--modal-container {
    max-width: ${(props) => (props.variant === 'sm' ? '565px' : '740px')};
  }

  .cds--modal-content {
    /*
     * Needed for cluster selection dropdown only for SM inside the modal to render properly
     * See https://github.com/carbon-design-system/carbon/issues/4684#issuecomment-554090767
     */
    overflow: ${(props) => props.variant === 'sm' && 'visible'};
  }
`;

export const IdpApplicationModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
