/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { createRoot } from 'react-dom/client';

import DocumentationOverlay from './DocumentationOverlay';

const OVERLAY_TYPE = 'documentation-overlay';

export function DocumentationOverlayExtension(eventBus, elementRegistry, overlays, bpmnjs) {
  eventBus.on('import.done', () => {
    recreateDocumentationOverlays(elementRegistry, overlays, bpmnjs);
  });

  eventBus.on('shape.changed', () => {
    recreateDocumentationOverlays(elementRegistry, overlays, bpmnjs);
  });

  eventBus.on('commandStack.element.updateModdleProperties.postExecute', (event) => {
    const properties = event?.context?.properties;
    const isDocumentationUpdated = 'text' in properties || 'documentation' in properties;

    if (isDocumentationUpdated) {
      const element = event.context.element;

      const isDocumentationEmpty = element?.businessObject.get('documentation').length === 0;
      if (isDocumentationEmpty) {
        removeDocumentationOverlayForElement(element, overlays);
      } else {
        removeDocumentationOverlayForElement(element, overlays);
        addOverlay(element, overlays, bpmnjs);
      }
    }
  });
}

DocumentationOverlayExtension.$inject = ['eventBus', 'elementRegistry', 'overlays', 'bpmnjs'];

export default {
  __init__: ['documentationOverlayExtension'],
  documentationOverlayExtension: ['type', DocumentationOverlayExtension]
};

const addOverlay = (element, overlays, bpmnjs) => {
  const html = document.createElement('div');
  createRoot(html).render(<DocumentationOverlay element={element} bpmnjs={bpmnjs} />);

  if (!is(element, 'bpmn:Process') && !is(element, 'bpmn:Collaboration')) {
    overlays.add(element, OVERLAY_TYPE, {
      position: { bottom: -10, right: 6 },
      show: { minZoom: 0.7 },
      html
    });
  }
};

const removeDocumentationOverlayForElement = (element, overlays) => {
  overlays.remove({ element, type: OVERLAY_TYPE });
};

const recreateDocumentationOverlays = (elementRegistry, overlays, bpmnjs) => {
  const elementsWithDocumentation = elementRegistry.filter((element) => {
    if (element.type === 'label') {
      return false;
    }

    return element.businessObject.get('documentation').length > 0;
  });

  elementsWithDocumentation.forEach((element) => {
    removeDocumentationOverlayForElement(element, overlays);
    addOverlay(element, overlays, bpmnjs);
  });
};
