/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ActionableNotification as _ActionableNotification } from '@carbon/react';
import styled from 'styled-components';

export const Title = styled.header`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
`;

export const ActionableNotification = styled(_ActionableNotification)`
  margin-bottom: 10px;
`;
