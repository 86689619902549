/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';

import callingProcessesStore from './CallingProcessesStore';

export function CallingProcessesExtension(eventBus) {
  eventBus.on('selection.changed', (event) => {
    const element = event.newSelection?.length === 1 ? event.newSelection[0] : null;
    callingProcessesStore.setSelectedElement(element);
  });

  eventBus.on(
    ['commandStack.element.updateProperties.executed', 'commandStack.element.updateProperties.reverted'],
    (event) => {
      const idWasChanged = event.context.oldProperties.id !== event.context.properties.id;
      const element = event.context.element;
      if (is(element, 'dmn:Decision') && idWasChanged) {
        callingProcessesStore.setSelectedElement({ ...element });
      }
    }
  );
}

CallingProcessesExtension.$inject = ['eventBus'];

export default {
  __init__: ['callingProcessesExtension'],
  callingProcessesExtension: ['type', CallingProcessesExtension]
};
