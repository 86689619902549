/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { v4 } from 'uuid';
import replaceIds from '@bpmn-io/replace-ids';

import { BPMN, CONNECTOR_TEMPLATE, DMN, FORM, README } from 'utils/constants';
import generateId from 'utils/generate-id';

import getBpmnDiagram from './templates/bpmn-diagram';
import getDmnDiagram from './templates/dmn-diagram';
import getFormTemplate from './templates/form-template';
import getConnectorTemplate from './templates/connector-template';

export const BPMN_DIAGRAM_DEFAULT_NAME = 'New BPMN diagram';
export const README_DEFAULT_CONTENT = `# Documentation

Use this file to document the contents and processes within this scope.
Keep it up to date with relevant details, instructions, and notes as needed.
`;

/**
 * Creates a new file and its associated properties, based on the given `type`.
 */
export default class FileMapper {
  /**
   * Constructor.
   *
   * @param {String} type The file type.
   */
  constructor(type) {
    this.type = type;
  }

  /**
   * Generates a new file.
   *
   * @param {Object} obj
   * @param {String} [obj.processId] The process ID for BPMN diagrams. If set to null, a new ID will be generated.
   * @param {String} [obj.definitionsId] The definitions ID for DMN diagrams. If set to null, a new ID will be generated.
   * @param {String} [obj.content] The file's content. If set to null, the default content will be generated.
   * @param {String} [obj.name] The file's name. If set to null, the default name will be used.
   * @returns {Object}
   */
  generate(args = {}) {
    let fileProperties;

    switch (this.type) {
      case BPMN: {
        fileProperties = this.getBpmnDiagramParams(args);
        break;
      }

      case DMN: {
        fileProperties = this.getDmnDiagramParams(args);
        break;
      }

      case FORM:
        fileProperties = this.getFormParams(args);
        break;

      case CONNECTOR_TEMPLATE:
        fileProperties = this.getConnectorTemplateParams(args);
        break;

      case README:
        fileProperties = this.getReadmeParams(args);
        break;

      default:
        throw new Error(`Unknown type \`${this.type}\` provided to EntityCreator.`);
    }

    return {
      type: this.type,
      ...fileProperties
    };
  }

  /**
   * Creates a BPMN file by merging default and existing properties.
   *
   * @param {Object} obj
   * @param {String} [obj.processId] The process ID from the XML. If set to null, a new ID will be generated.
   * @param {String} [obj.processTemplateId] The process template ID.
   * @param {String} [obj.content] The diagram's XML. If set to null, an empty XML will be generated.
   * @param {String} [obj.name] The diagram's name. If set to null, the default name will be used.
   * @returns {Object}
   */
  getBpmnDiagramParams({
    processId = `Process_${generateId()}`,
    processTemplateId = undefined,
    name = BPMN_DIAGRAM_DEFAULT_NAME,
    content = getBpmnDiagram({ processId, processName: name })
  }) {
    return { processTemplateId, content, name };
  }

  /**
   * Creates a DMN file by merging default and existing properties.
   *
   * @param {Object} obj
   * @param {String} [obj.definitionsId] The definitions ID from the XML. If set to null, a new ID will be generated.
   * @param {String} [obj.content] The diagram's XML. If set to null, an empty XML will be generated.
   * @param {String} [obj.name] The diagram's name. If set to null, the default name will be used.
   * @returns {Object}
   */
  getDmnDiagramParams({
    definitionsId = `Definitions_${generateId()}`,
    // @ts-expect-error TS2339
    decisionId,
    // @ts-expect-error TS2339
    decisionName,
    content = replaceIds(getDmnDiagram({ definitionsId, decisionId, decisionName }), generateId),
    name = 'New DMN diagram'
  }) {
    return { definitionsId, content, name };
  }

  /**
   * Creates a Camunda Form.
   *
   * @param {Object} obj
   * @param {String} [obj.content] The form's JSON. If set to null, the default JSON will be generated.
   * @param {String} [obj.name] The form's name. If set to null, the default name will be used.
   * @returns {Object}
   */
  // @ts-expect-error TS2339
  getFormParams({ content, formId, name = 'New form' }) {
    let formContent = content;
    if (!formContent) {
      formContent = getFormTemplate({ formId });
    }
    return { content: formContent, name };
  }

  /**
   * Creates a Connector Template.
   *
   * @param {Object} obj
   * @param {String} [obj.content] The template JSON. If set to null, the default JSON will be generated.
   * @param {String} [obj.name] The template name. If set to null, the default name will be used.
   * @param {String} [obj.description] The template description. Optional.
   * @param {String} [obj.icon] The template icon. Optional.
   * @param {Boolean} [obj.shouldOverrideProps] If set true, the name, description, and icon will be overridden in the template content.
   * @returns {Object}
   */
  getConnectorTemplateParams({
    content,
    name = 'New connector template',
    description = '',
    icon = '',
    shouldOverrideProps = false
  }) {
    let templateContentString = content;
    try {
      if (!content) {
        templateContentString = getConnectorTemplate({ templateId: v4(), name, description, icon });
      } else if (shouldOverrideProps) {
        // override name, description, and icon if necessary
        let templateContentJson = typeof content === 'string' ? JSON.parse(content) : content;
        if (name?.length > 0) {
          templateContentJson.name = name;
        }
        if (description?.length > 0) {
          templateContentJson.description = description;
        }
        if (icon?.length > 0) {
          templateContentJson.icon = { contents: icon };
        } else {
          // if there isn't any intentional override we should delete the icon (which might come from the generator)
          delete templateContentJson.icon;
        }
        templateContentString = JSON.stringify(templateContentJson);
      }
      if (name?.length < 1) {
        name =
          JSON.parse(templateContentString).name?.length > 0
            ? JSON.parse(templateContentString).name
            : 'New connector template';
      }
    } catch (e) {
      console.error(e);
    }
    return { content: templateContentString, name };
  }

  /**
   * Creates a README file.
   *
   * @param {Object} obj
   * @param {String} [obj.content] The README content. If set to null, the default content will be generated.
   * @returns {Object}
   */
  getReadmeParams({ content }) {
    return { content: content ?? README_DEFAULT_CONTENT, name: 'README' };
  }
}
