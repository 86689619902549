/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { getSanitizedZeebeVersionString } from 'utils/cluster/cluster-version-util';
import {
  processApplicationStore,
  clustersStore,
  currentDiagramStore,
  notificationStore,
  organizationStore
} from 'stores';
import { processApplicationService, trackingService } from 'services';
import {
  deploymentErrorsStore,
  inboundConnectorStore,
  isDeployedModalVisibleStore,
  publicationStore
} from 'App/Pages/Diagram/stores';
import { getErrorCode, getErrorString } from 'App/Pages/Diagram/stores/DeploymentErrorsStore';
import InstanceStartedNotification from 'App/Pages/Diagram/Deployment/InstanceStartedNotification';
import DeploymentSuccessNotification from 'App/Pages/Diagram/Deployment/DeploymentSuccessNotification';
import capitalize from 'utils/capitalize';
import {
  AVAILABLE_ALPHA_LINT_VERSIONS,
  AVAILABLE_LINT_VERSIONS,
  BPMN,
  DEFAULT_ZEEBE_VERSION,
  FILE_TYPE_MAPPING,
  PROCESS_APPLICATION
} from 'utils/constants';
import { errorPanelStore } from 'App/Pages/Diagram/ErrorPanel';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import { CLUSTER_STATUS } from 'utils/cluster/constants';

import deploymentService from './DeploymentService';

class DeploymentStore {
  latestDeployedCluster = null;
  selectedClusterId = null;
  selectedClusterIdForPlay = null;
  processId = null;
  isLoadingSuccess = false; // Used to prevent wrong message from being shown in the tooltip in case the loading of data fails
  processApplicationVersionId = null;

  constructor() {
    makeObservable(this, {
      latestDeployedCluster: observable,
      selectedClusterId: observable,
      selectedClusterIdForPlay: observable,
      selectedCluster: computed,
      hasSelectedClusterDevelopmentPlanType: computed,
      processId: observable,
      isLoadingSuccess: observable,
      processApplicationVersionId: observable,
      setProcessApplicationVersionId: action,
      operateUrl: computed,
      tasklistUrl: computed,
      optimizeUrl: computed,
      playClusterId: computed,
      init: action,
      reset: action,
      setLatestDeployedCluster: action,
      setSelectedClusterId: action,
      setSelectedClusterIdForPlay: action,
      deploy: action,
      execute: action
    });
  }

  /**
   * Initializes the DeploymentStore by retrieving the available clusters, and prefilling the selection
   * @param {String} fileId
   * @param {String} processId
   * @return {Promise<void>}
   */
  async init(fileId, processId) {
    this.processId = processId;

    try {
      await clustersStore.loadClusters();

      if (processApplicationStore.fromAProcessApplication) {
        await this.setSelectedClusterForAProcessApplication(fileId);
      } else {
        await this.setSelectedClusterBasedOnLatestDeployment(fileId);
      }

      runInAction(() => {
        this.isLoadingSuccess = true;
      });
    } catch (e) {
      runInAction(() => {
        this.isLoadingSuccess = false;
      });
      console.error('Initialization failed', e);
    }
  }

  /**
   * Manages the cluster selection in the process application context, checking if there is a default cluster to be used
   * @param {String} fileId
   * @return {Promise<void>}
   */
  async setSelectedClusterForAProcessApplication(fileId) {
    await this.ensureProcessApplicationLoaded();

    const defaultDevClusterId = processApplicationStore.processApplication.defaultDevClusterId;
    if (defaultDevClusterId) {
      this.setSelectedClusterId(defaultDevClusterId);
    } else {
      await this.setSelectedClusterBasedOnLatestDeployment(fileId);
    }
  }

  /**
   * Awaits the process application to be loaded
   * @return {Promise<void>}
   */
  async ensureProcessApplicationLoaded() {
    if (processApplicationStore.loadingPromise) {
      await processApplicationStore.loadingPromise;
    }
  }

  /**
   * Retrieves all the deployment for the given file, and set the selection to the latest used cluster
   * @param {String} fileId
   * @return {Promise<void>}
   */
  async setSelectedClusterBasedOnLatestDeployment(fileId) {
    // Init latestDeployedCluster
    const deployments = await deploymentService.fetchDeploymentsById(fileId);
    if (deployments?.length > 0) {
      this.setLatestDeployedCluster(deployments[0].clusterId);
    }

    // Init selectedClusterId to the last deployed cluster (if the last deployed cluster is present in the list)
    const latestDeployedClusterId = this.latestDeployedCluster?.uuid;
    if (clustersStore.clusters.some((cluster) => cluster.uuid === latestDeployedClusterId)) {
      this.setSelectedClusterId(latestDeployedClusterId);
    }
  }

  reset() {
    this.latestDeployedCluster = null;
    this.selectedClusterId = null;
    this.processId = null;
    this.isLoadingSuccess = false;
    this.selectedClusterIdForPlay = null;
    this.processApplicationVersionId = null;
  }

  setProcessApplicationVersionId(processApplicationVersionId) {
    this.processApplicationVersionId = processApplicationVersionId;
  }

  setLatestDeployedCluster = (clusterId) => {
    this.latestDeployedCluster = clustersStore.clusters?.find((cluster) => cluster.uuid === clusterId);
  };

  setSelectedClusterId = (clusterId) => {
    this.selectedClusterId = clusterId;
  };

  setSelectedClusterIdForPlay = (clusterId) => {
    // we need to also save play cluster id in local storage because when page is refreshed while on Play tab, we do not display the deployment modal
    localStorage.setItem(`play-${organizationStore.currentOrganizationId}-clusterId`, clusterId);
    this.selectedClusterIdForPlay = clusterId;
  };

  get playClusterId() {
    return (
      this.selectedClusterIdForPlay ?? localStorage.getItem(`play-${organizationStore.currentOrganizationId}-clusterId`)
    );
  }

  async deployProcessApplication() {
    const deployResponse = await processApplicationService.deployProcessApplication(
      this.selectedClusterId,
      processApplicationStore.processApplication.id,
      formLinkStore.formResolutions
    );
    return deployResponse;
  }

  async deployProcessApplicationVersion() {
    const deployResponse = await processApplicationService.deployProcessApplicationVersion(
      this.selectedClusterId,
      this.processApplicationVersionId,
      formLinkStore.formResolutions
    );
    return deployResponse;
  }

  async deploy(onCloseHandler) {
    deploymentErrorsStore.reset();
    const { diagram } = currentDiagramStore.state;

    try {
      let deployResponse;
      if (this.processApplicationVersionId) {
        deployResponse = await this.deployProcessApplicationVersion();

        const { defaultDevClusterId, defaultTestClusterId, defaultStageClusterId, defaultProdClusterId } =
          processApplicationStore.processApplication;
        trackingService.trackProcessAppVersionDeploy({
          selectedClusterId: this.selectedClusterId,
          defaultDevClusterId,
          defaultTestClusterId,
          defaultStageClusterId,
          defaultProdClusterId
        });
      } else if (processApplicationStore.fromAProcessApplication) {
        await this.switchLintVersion();
        deployResponse = await this.deployProcessApplication();
      } else {
        await this.switchLintVersion();
        deployResponse = await deploymentService.deploy(
          this.selectedClusterId,
          currentDiagramStore.state.diagram.id,
          formLinkStore.formResolutions
        );
      }
      this.setLatestDeployedCluster(this.selectedClusterId);

      onCloseHandler(this.selectedCluster);

      if (processApplicationStore.fromAProcessApplication) {
        this.showNotificationWithLinkToOperate(deployResponse);
      } else if (this.latestDeployedCluster?.rba) {
        if (currentDiagramStore.isBPMN) {
          isDeployedModalVisibleStore.setProcessId(currentDiagramStore.state.diagram.processId);
        } else {
          isDeployedModalVisibleStore.setDecisionIds(currentDiagramStore.state.diagram.decisionIds);
        }
        isDeployedModalVisibleStore.show();
      } else {
        if (currentDiagramStore.isBPMN) {
          this.showNotificationWithLinkToOperate(deployResponse);
        } else {
          notificationStore.showSuccess('Diagram deployed!');
        }
      }

      trackingService.trackDeployment({
        diagram,
        // @ts-expect-error TS2322
        deployType: processApplicationStore.fromAProcessApplication
          ? FILE_TYPE_MAPPING[PROCESS_APPLICATION]
          : 'single-file',
        success: true,
        // @ts-expect-error TS2740
        deployedForms: formLinkStore.usedFormIds,
        clusterVersion: this.selectedClusterVersion,
        clusterEnv: this.selectedClusterEnv,
        numberOfFiles: processApplicationStore.fromAProcessApplication ? deployResponse.numberOfFiles : undefined,
        bundleSize: processApplicationStore.fromAProcessApplication ? deployResponse.bundleSize : undefined
      });

      inboundConnectorStore.setRecentlyDeployed();
      publicationStore.setRecentlyDeployed();
    } catch (e) {
      onCloseHandler(this.selectedCluster);

      const { errorCount } = deploymentErrorsStore.parseAndSetDeploymentErrors('deploy', e);
      const errorMessage = getErrorString(e);
      const errorCode = getErrorCode(e);

      trackingService.trackDeployment({
        diagram,
        // @ts-expect-error TS2322
        deployType: processApplicationStore.fromAProcessApplication
          ? FILE_TYPE_MAPPING[PROCESS_APPLICATION]
          : 'single-file',
        success: false,
        errorMessage,
        errorCode,
        // @ts-expect-error TS2322
        deployedForms: formLinkStore.usedFormIds,
        clusterVersion: this.selectedClusterVersion,
        clusterEnv: this.selectedClusterEnv
      });

      if (processApplicationStore.fromAProcessApplication) {
        deploymentErrorsStore.showErrorsModal({
          title: 'Deploy process application',
          notification: 'The application failed to deploy'
        });
      } else {
        notificationStore.showError(`Diagram could not be deployed. Check the output tab for more details.`);

        trackingService.trackDiagramErrors(diagram, 'DEPLOY', errorCount);

        currentDiagramStore.setIsErrorPanelCollapsed(false);
        errorPanelStore.switchToOutputTab();
      }
    }
  }

  showNotificationWithLinkToOperate(deployResponse) {
    const version = processApplicationStore.fromAProcessApplication
      ? deployResponse?.processes?.find((process) => process.id === this.processId)?.version
      : deployResponse?.version;

    notificationStore.showSuccess(
      <DeploymentSuccessNotification
        link={
          this.latestDeployedCluster?.urls?.operate
            ? `${this.latestDeployedCluster.urls.operate}/processes?process=${this.processId}&version=${version}`
            : null
        }
      />,
      5000
    );
  }

  async execute(onCloseHandler, payload) {
    deploymentErrorsStore.reset();
    const { diagram } = currentDiagramStore.state;

    try {
      await this.switchLintVersion();
      let data;
      if (processApplicationStore.fromAProcessApplication) {
        if (this.processApplicationVersionId) {
          data = await processApplicationService.executeProcessApplicationVersion(
            this.selectedClusterId,
            payload,
            this.processApplicationVersionId,
            formLinkStore.formResolutions
          );
        } else {
          data = await processApplicationService.executeProcessApplication(
            this.selectedClusterId,
            payload,
            processApplicationStore.processApplication.id,
            formLinkStore.formResolutions
          );
        }
      } else {
        data = await deploymentService.execute(
          this.selectedClusterId,
          payload,
          currentDiagramStore.state.diagram.id,
          formLinkStore.formResolutions
        );
      }
      this.setLatestDeployedCluster(this.selectedClusterId);

      onCloseHandler(this.selectedCluster);

      notificationStore.showSuccess(
        <InstanceStartedNotification
          link={
            this.latestDeployedCluster?.urls?.operate
              ? `${this.latestDeployedCluster.urls.operate}/processes/${data.processInstanceKey}`
              : null
          }
        />,
        5000
      );

      inboundConnectorStore.setRecentlyDeployed();

      trackingService.trackExecution({
        diagram,
        // @ts-expect-error TS2322
        deployType: processApplicationStore.fromAProcessApplication
          ? FILE_TYPE_MAPPING[PROCESS_APPLICATION]
          : 'single-file',
        success: true,
        // @ts-expect-error TS2322
        deployedForms: formLinkStore.usedFormIds,
        clusterVersion: this.selectedClusterVersion,
        clusterEnv: this.selectedClusterEnv,
        numberOfFiles: processApplicationStore.fromAProcessApplication ? data.numberOfFiles : undefined,
        bundleSize: processApplicationStore.fromAProcessApplication ? data.bundleSize : undefined
      });
    } catch (e) {
      onCloseHandler(this.selectedCluster);

      const { errorCount } = deploymentErrorsStore.parseAndSetDeploymentErrors('execute', e);
      const errorMessage = getErrorString(e);
      const errorCode = getErrorCode(e);

      trackingService.trackExecution({
        diagram,
        // @ts-expect-error TS2322
        deployType: processApplicationStore.fromAProcessApplication
          ? FILE_TYPE_MAPPING[PROCESS_APPLICATION]
          : 'single-file',
        success: false,
        errorMessage,
        errorCode,
        // @ts-expect-error TS2322
        deployedForms: formLinkStore.usedFormIds,
        clusterVersion: this.selectedClusterVersion,
        clusterEnv: this.selectedClusterEnv
      });

      if (processApplicationStore.fromAProcessApplication) {
        deploymentErrorsStore.showErrorsModal({
          title: 'Start instance',
          notification: 'The application failed to execute'
        });
      } else {
        notificationStore.showError('Instance could not be started. Check the output tab for more details.');

        trackingService.trackDiagramErrors(diagram, 'DEPLOY', errorCount);

        currentDiagramStore.setIsErrorPanelCollapsed(false);
        errorPanelStore.switchToOutputTab();
      }
    }
  }

  isLinkEnabled = (application) =>
    clustersStore.clusters &&
    this.latestDeployedCluster &&
    this.latestDeployedCluster.status[application] === CLUSTER_STATUS.HEALTHY;

  get operateUrl() {
    if (this.isLinkEnabled('operate')) {
      if (currentDiagramStore.state.diagram.type === BPMN) {
        return `${this.latestDeployedCluster.urls.operate}/processes?process=${this.processId}&version=all&active=true&incidents=true&completed=true&canceled=true`;
      } else {
        // link to the first decision present in the diagram
        const decision = currentDiagramStore.elementRegistry?.find((element) =>
          element?.businessObject?.$instanceOf('dmn:Decision')
        );
        if (decision) {
          return `${this.latestDeployedCluster.urls.operate}/decisions/?name=${decision.id}&version=all&evaluated=true&failed=true`;
        } else {
          return `${this.latestDeployedCluster.urls.operate}/decisions/?evaluated=true&failed=true`;
        }
      }
    }
    return '';
  }

  get tasklistUrl() {
    if (this.isLinkEnabled('tasklist')) {
      return this.latestDeployedCluster.urls.tasklist;
    }
    return '';
  }

  get optimizeUrl() {
    if (this.isLinkEnabled('optimize')) {
      return this.latestDeployedCluster.urls.optimize;
    }
    return '';
  }

  get selectedCluster() {
    return clustersStore.clusters?.find((cluster) => cluster.uuid === this.selectedClusterId);
  }

  get isSelectedClusterProd() {
    return this.selectedCluster?.labels?.camunda?.includes('prod');
  }

  get hasSelectedClusterDevelopmentPlanType() {
    return this.selectedCluster?.planType?.isDevelopment;
  }

  /**
   * Returns the environment of the selected cluster.
   * @returns {string}
   */
  get selectedClusterEnv() {
    return this.selectedCluster?.labels?.camunda?.[0] || 'unknown';
  }

  getTooltip = (application) => {
    if (this.isLoadingSuccess) {
      if (!this.latestDeployedCluster) {
        return 'The diagram is not deployed yet!';
      } else if (this.latestDeployedCluster.status[application] !== 'Healthy') {
        return `${capitalize(application)} is not healthy!`;
      }
    }

    return '';
  };

  switchLintVersion = async () => {
    // Switch linting version to the current selected cluster version
    if (currentDiagramStore.isBPMN) {
      const selectedCluster = this.selectedCluster;
      const versionWithoutPatch = getSanitizedZeebeVersionString(selectedCluster?.generation?.versions?.zeebe, {
        includePatch: false,
        includePrerelease: false
      });

      let lintingVersion = DEFAULT_ZEEBE_VERSION;
      if (
        AVAILABLE_LINT_VERSIONS.includes(`${versionWithoutPatch}.0`) ||
        AVAILABLE_ALPHA_LINT_VERSIONS.includes(`${versionWithoutPatch}.0`)
      ) {
        lintingVersion = `${versionWithoutPatch}.0`;
      }
      await currentDiagramStore.setExecutionPlatformVersion(lintingVersion);
    }
  };

  get selectedClusterVersion() {
    const selectedCluster = this.selectedCluster;
    return getSanitizedZeebeVersionString(selectedCluster?.generation?.versions?.zeebe, {
      includePatch: false,
      includePrerelease: false
    });
  }
}

export default new DeploymentStore();
