/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { StructuredListWrapper, StructuredListHead, StructuredListRow, StructuredListBody } from '@carbon/react';

import { truncateLongText } from 'App/Pages/Project/IdpApplication/IdpProject/utils/utils';
import ActionCell from 'App/Pages/Project/IdpApplication/IdpProject/IdpEvaluateExtraction/ActionCell/ActionCell';
import StatusCell from 'App/Pages/Project/IdpApplication/IdpProject/IdpEvaluateExtraction/StatusCell/StatusCell';

import * as Styled from './ExtractionExpandedSection.styled';

function ExtractionExpandedSection({ field, onSetActiveDocument }) {
  const rows = field.testCaseResults.map((testCaseResult) => ({
    id: testCaseResult.idpDocumentId,
    name: testCaseResult.idpDocumentName,
    extractedValue: testCaseResult.extractedValue,
    expectedValue: testCaseResult.expectedValue,
    status: testCaseResult.status
  }));

  return (
    <Styled.NestedTableWrapper>
      <StructuredListWrapper isCondensed>
        <StructuredListHead>
          <StructuredListRow head>
            <Styled.StructuredListCellName key="name" head>
              Document name
            </Styled.StructuredListCellName>
            <Styled.StructuredListCellValue key="extractedValue" head>
              Extracted value
            </Styled.StructuredListCellValue>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          {rows.map((row) => (
            <StructuredListRow key={row.id}>
              <Styled.StructuredListCellName>{truncateLongText(row.name)}</Styled.StructuredListCellName>
              <Styled.StructuredListCellValue>
                <Styled.ExtractedValueCell>
                  <StatusCell row={row} />
                  <ActionCell row={row} onSetActiveDocument={onSetActiveDocument} />
                </Styled.ExtractedValueCell>
              </Styled.StructuredListCellValue>
            </StructuredListRow>
          ))}
        </StructuredListBody>
      </StructuredListWrapper>
    </Styled.NestedTableWrapper>
  );
}

export default ExtractionExpandedSection;
