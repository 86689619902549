/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { MAX_ALLOWED_IDP_DOCUMENT_FILE_SIZE } from 'App/Pages/Project/IdpApplication/idp-project/utils/constants';
import { ValidationError } from 'App/Pages/Project/IdpApplication/idp-project/utils/ValidationError';

export const getExtractionFieldNameInvalidText = (newExtractionFieldName, extractionFields) => {
  if (!newExtractionFieldName) {
    return;
  }

  return !/^[a-zA-Z0-9_-]+$/.test(newExtractionFieldName)
    ? 'Name can only contain alphanumeric characters, underscores and dashes.'
    : extractionFields.some((field) => field.name === newExtractionFieldName)
      ? 'Extraction field already exists with this name.'
      : '';
};

export const findLlmModelIdWithLatestTestCaseResults = (testCaseResultsByLlmModel) => {
  if (!testCaseResultsByLlmModel) {
    return null;
  }

  return [...testCaseResultsByLlmModel].reduce(
    (accumulated, [llmModelId, extractionFields]) => {
      const allTestCaseResults = extractionFields.flatMap((extractionField) => extractionField.testCaseResults || []);
      const modelMaxTimestamp = allTestCaseResults.reduce((maxSoFar, { created, lastChanged }) => {
        return Math.max(maxSoFar, created ?? -Infinity, lastChanged ?? -Infinity);
      }, -Infinity);

      return modelMaxTimestamp > accumulated.max ? { llmModelId, max: modelMaxTimestamp } : accumulated;
    },
    { llmModelId: null, max: -Infinity }
  ).llmModelId;
};

export const truncateLongText = (extraction) => {
  if (!extraction) {
    return '';
  }
  return extraction.length > 255 ? extraction.substring(0, 255) + '...' : extraction;
};

/**
 * Validates the sizes of IDP documents.
 * Checks each document for empty content or exceeding the maximum allowed file size.
 *
 * @param {File[]} documents - Array of documents to validate.
 * @throws {ValidationError} Throws a `ValidationError` if any document fails validation, with a `response` property
 * containing a JSON-stringified list of validation errors.
 */
export const validateIdpDocuments = (documents) => {
  const totalDocumentsSize = documents.reduce((totalSize, { size }) => totalSize + size, 0);
  if (documents.length > 1 && totalDocumentsSize > MAX_ALLOWED_IDP_DOCUMENT_FILE_SIZE) {
    throw new ValidationError(
      'Document validation failed',
      JSON.stringify({
        errors: [
          {
            reason: 'IDP_DOCUMENTS_TOO_LARGE',
            detail: `The combined file size of all documents must be smaller than ${MAX_ALLOWED_IDP_DOCUMENT_FILE_SIZE / 1048576}MB. Please try uploading your documents in smaller batches.`
          }
        ]
      })
    );
  }

  documents.forEach((document) => {
    const errors = [];
    if (document.size === 0) {
      errors.push({
        reason: 'IDP_DOCUMENT_EMPTY',
        detail: `${document.name} has no data (0MB). Please check the file and try again.`
      });
    } else if (document.size > MAX_ALLOWED_IDP_DOCUMENT_FILE_SIZE) {
      errors.push({
        reason: 'IDP_DOCUMENT_TOO_LARGE',
        detail: `${document.name} must be smaller than ${MAX_ALLOWED_IDP_DOCUMENT_FILE_SIZE / 1048576}MB. Please reduce the file size and try again.`
      });
    }

    if (errors.length > 0) {
      throw new ValidationError('Document validation failed', JSON.stringify({ errors }));
    }
  });
};
