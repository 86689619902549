/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Launch } from '@carbon/icons-react';

import { MarkdownRenderer } from 'features/markdown-file-handling';
import buildPath from 'utils/build-path';

import { SidePanelEntry } from './SidePanelEntry';
import * as Styled from './SidePanelComponents.styled';

export const SelectionSidePanel = ({ selectedNode, readmeError, isReadmeLoading, readmeContent }) => {
  const { processFileId, readmeFileId, version } = selectedNode.businessObject?.externalData || {};

  return (
    <>
      <SidePanelEntry
        title={selectedNode.name}
        headerActions={
          processFileId && (
            <Styled.SidePanelHeaderActions>
              <Styled.NodeLink href={buildPath(`/diagrams/${processFileId}?from=projectLandscape`)} target="_blank">
                Open diagram <Launch />
              </Styled.NodeLink>
            </Styled.SidePanelHeaderActions>
          )
        }
      >
        {version && <Styled.SidePanelInfoText>{version}</Styled.SidePanelInfoText>}
      </SidePanelEntry>

      <SidePanelEntry
        title="Documentation"
        headerActions={
          <Styled.SidePanelHeaderActions>
            {readmeFileId ? (
              <Styled.NodeLink href={buildPath(`/readmes/${readmeFileId}?from=projectLandscape`)} target="_blank">
                Edit readme <Launch />
              </Styled.NodeLink>
            ) : null}
          </Styled.SidePanelHeaderActions>
        }
      >
        {readmeError ? (
          <Styled.SidePanelInfoText style={{ color: 'red' }}>
            Failed to load documentation: {readmeError.message}
          </Styled.SidePanelInfoText>
        ) : isReadmeLoading ? (
          <Styled.SidePanelInfoText>Loading documentation...</Styled.SidePanelInfoText>
        ) : readmeFileId ? (
          <MarkdownRenderer content={readmeContent} noPadding />
        ) : (
          <Styled.DisabledText>No readme available</Styled.DisabledText>
        )}
      </SidePanelEntry>
    </>
  );
};
