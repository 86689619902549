/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useStatsigClient } from 'App/Statsig';
import { useAreAiFeaturesEnabled } from 'experiments/hooks';
import { currentDiagramStore, milestoneStore, processApplicationStore } from 'stores';
import { processApplicationService, trackingService } from 'services';
import {
  shouldShowBpmnCopilot,
  CamundaAiButtonWithCopilot,
  CamundaAiFeatureDiscovery
} from 'experiments/ai-features/bpmn-copilot';

import CamundaAiSparkleButton from './CamundaAiSparkleButton';
import { CamundaDocsAiFeatureDiscovery } from './docs-ai/';

const CamundaAi = ({ location }) => {
  const areAIFeaturesEnabled = useAreAiFeaturesEnabled();
  const { checkGate } = useStatsigClient();

  const showBpmnCopilot = shouldShowBpmnCopilot(currentDiagramStore, checkGate);

  return areAIFeaturesEnabled ? (
    <AIWidgetTrigger location={location} showBpmnCopilot={showBpmnCopilot} />
  ) : (
    <>
      {showBpmnCopilot ? (
        <CamundaAiFeatureDiscovery location={location} />
      ) : (
        <CamundaDocsAiFeatureDiscovery location={location} />
      )}
    </>
  );
};

const versionName = 'Autosaved before Copilot (AI) generation';

const createVersionProcessApplication = (processApplicationId) => {
  return async () => processApplicationService.createProcessApplicationVersion(processApplicationId, versionName);
};

const createVersionNonProcessApplication = (diagram) => {
  return async () =>
    milestoneStore.createAutosaved(diagram, 'bpmnCopilot', {
      name: versionName
    });
};

const getCreateNewVersionFunction = () => {
  if (processApplicationStore?.processApplication?.id) {
    return createVersionProcessApplication(processApplicationStore.processApplication.id);
  } else {
    return createVersionNonProcessApplication(currentDiagramStore?.state?.diagram);
  }
};

const AIWidgetTrigger = ({ location, showBpmnCopilot }) => {
  return showBpmnCopilot ? (
    <CamundaAiButtonWithCopilot
      location={location}
      modeler={currentDiagramStore.modeler}
      saveDiagramContent={() => currentDiagramStore.saveContent('bpmn-copilot')}
      createNewVersion={getCreateNewVersionFunction()}
      isAdminOrEditor={currentDiagramStore.isAdminOrEditor}
      diagramId={currentDiagramStore.diagramId}
    />
  ) : (
    <CamundaAiSparkleButton
      iconDescription="Camunda Docs AI"
      id="camunda-ai-button"
      onClick={() => trackingService.trackDocsAIOpen({ enabled: true, from: location })}
    />
  );
};

export const CamundaAiButton = CamundaAi;
