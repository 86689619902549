/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from './Service';

class ConsoleService extends Service {
  /**
   * Fetches the permissions for the organization from console
   *
   * @returns {Promise}
   */
  getOrganizationInfo(organizationId) {
    return this.get(`/cloud/accounts/organizations/${organizationId}`);
  }

  /**
   * Fetches the list of organizations which the logged user is assigned to
   *
   * @returns {Promise}
   */
  getUserOrganizations() {
    return this.get(`/cloud/accounts/organizations/my`);
  }

  /**
   * Fetches the list of members for the organization
   * @param {string} organizationId
   * @param {boolean} useCache
   *
   * @returns {Promise}
   */
  getOrganizationMembers({ organizationId, useCache = true } = {}) {
    if (!organizationId) {
      throw new Error('Organization ID is required');
    }

    return this.get(`/cloud/accounts/organizations/${organizationId}/members?useCache=${useCache}`);
  }
}

export default new ConsoleService();
