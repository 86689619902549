/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { notificationStore } from 'stores';
import { openAiService } from 'services';

export default class FormAssistantAPI {
  constructor(eventBus) {
    this._eventBus = eventBus;

    this._eventBus.on('form.assistant.import.success', () => {
      notificationStore.showSuccess('Successfully generated smart form.');
    });
  }

  async createChatCompletion(payload) {
    const response = await openAiService.createChatCompletion(payload);
    return response;
  }
}

FormAssistantAPI.$inject = ['eventBus'];
