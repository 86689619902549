/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const FormBody = styled.div`
  white-space: pre;
  padding: 10px 20px;
  max-height: 300px;
  overflow: auto;
  background: var(--cm-color-white-base);
`;

export const FormLastUpdated = styled.div`
  color: var(--cds-text-secondary, #525252);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
`;
