/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ActionableNotification, Button, Checkbox, Link, TextArea } from '@carbon/react';

import NonModalDialog from 'primitives/NonModalDialog/NonModalDialog';
import { authService, trackingService } from 'services';
import { notificationStore, organizationStore } from 'stores';
import config from 'utils/config';

import feedbackDialogStore from './FeedbackDialogStore';
import * as Styled from './FeedbackDialog.styled';

export function FeedbackDialog({ feedbackButtonSlotRef, from }) {
  const [rating, setRating] = useState(null);
  const [suggestions, setSuggestions] = useState('');
  const [allowContact, setAllowContact] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isSlotRendered, anchorEl } = useCheckIfSlotRendered(feedbackButtonSlotRef);

  const onClose = useCallback(() => {
    feedbackDialogStore.close();
  }, [feedbackDialogStore]);

  const onSubmit = useCallback(async () => {
    const type = feedbackDialogStore.state.type;

    const url = getUrl();

    const token = await authService.getToken();

    setIsSubmitting(true);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        allowContact: allowContact ? 'true' : 'false',
        reportType: 'feedback',
        salesPlanType: organizationStore.currentOrganizationInfo.salesPlan.type,
        suggestion: `Topic: ${type}; Rating: ${rating}; Suggestions: ${suggestions.trim().length > 0 ? suggestions : '-'}`
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    setIsSubmitting(false);

    if (response.status === 204) {
      setSuggestions('');
      setRating(null);
      setAllowContact(false);
      notificationStore.showSuccess('Feedback has been sent.');
      trackingService.trackFeedbackSend({ from, score: rating });
    }

    feedbackDialogStore.close();
  }, [allowContact, feedbackDialogStore, rating, suggestions]);

  return (
    isSlotRendered && (
      <NonModalDialog
        anchorEl={anchorEl}
        maxWidth="23rem"
        onClose={onClose}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        open={feedbackDialogStore.state.isOpen}
        primaryButtonDisabled={!rating || !suggestions.trim().length}
        selectorPrimaryFocus=".suggestions textarea"
      >
        <Styled.Message>* {feedbackDialogStore.state.message}</Styled.Message>
        <Styled.RatingsButtons>
          {[1, 2, 3, 4, 5].map((value) => (
            <Button
              className="rating"
              data-rating={value}
              kind={value === rating ? 'primary' : 'tertiary'}
              key={value}
              onClick={() => setRating(value)}
              data-test={`rating-${value}`}
            >
              {value}
            </Button>
          ))}
        </Styled.RatingsButtons>
        <Styled.RatingsLabels>
          <span>Poor</span>
          <span>Excellent</span>
        </Styled.RatingsLabels>
        <Styled.Suggestions>
          <b>Do you have any suggestions?</b>
          <TextArea
            className="suggestions"
            rows={4}
            placeholder="Type your suggestions here"
            value={suggestions}
            onChange={({ target }) => setSuggestions(target.value)}
            labelText=""
            data-test="textarea"
          />
        </Styled.Suggestions>
        <Styled.AllowContact>
          <Checkbox
            id="feedback-dialog-allowContact"
            checked={allowContact}
            onChange={() => setAllowContact(!allowContact)}
            labelText="Camunda may contact me about my feedback"
          />
        </Styled.AllowContact>
        <ActionableNotification
          subtitle={
            <>
              To report bugs or attach files, open the{' '}
              <Link
                target="_blank"
                href="https://docs.camunda.io/docs/next/reference/camunda-help-center/#share-your-feedback"
              >
                Help Center
              </Link>{' '}
              instead.
            </>
          }
          inline
          kind="info"
          lowContrast
          hideCloseButton
        />
      </NonModalDialog>
    )
  );
}

export default observer(FeedbackDialog);

function getUrl() {
  const { camundaCloudBaseDomain } = config;

  return `https://helpcenter.${camundaCloudBaseDomain}/feedback/submit`;
}

function useCheckIfSlotRendered(feedbackButtonSlotRef) {
  const [isSlotRendered, setIsSlotRendered] = useState(false);

  useEffect(() => {
    if (feedbackButtonSlotRef.current) {
      setIsSlotRendered(true);
    }
  }, [feedbackButtonSlotRef.current]);

  return { isSlotRendered, anchorEl: feedbackButtonSlotRef.current };
}
