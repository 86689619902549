/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { ActionableNotification } from '@carbon/react';

export const SectionHeader = styled.header`
  font-size: 16px;
  font-weight: bold;
  padding: var(--spacing-small) 0;
`;

export const Description = styled.div`
  margin-bottom: var(--spacing-medium);
`;

export const HelperText = styled.div`
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  top: -15px;
  position: absolute;
  right: 0;
  white-space: nowrap;
  ${({ hasError }) => (hasError ? 'color: var(--cm-color-red-base);' : '')}
`;

export const EngineSwitchInfo = styled.div`
  vertical-align: middle;
  margin-bottom: var(--spacing-tiny);
`;

export const InfosContainer = styled.div`
  margin-bottom: var(--spacing-medium);
`;

export const ProdClusterInfo = styled(ActionableNotification)`
  .cds--tag {
    margin: 0;
  }
`;

export const NoStagesClustersNotification = styled(ActionableNotification)`
  margin-top: var(--spacing-large);
`;
