/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useMemo, useCallback } from 'react';

import { readFiles } from 'utils/file-io';

import * as Styled from './DropTarget.styled';

export default function DropTarget({
  children,
  isDisabled = false,
  preserveRootWhenDisabled = false,
  onDrop,
  display = 'flex',
  text = 'Drop files for upload'
}) {
  const [dragCounter, setDragCounter] = useState(0);
  const isDragging = dragCounter > 0;

  const dragHandlers = useMemo(() => {
    const preventDefault = (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
    };

    return {
      preventDefault,
      onDragEnter: (evt) => {
        preventDefault(evt);
        setDragCounter((count) => count + 1);
      },
      onDragLeave: (evt) => {
        preventDefault(evt);
        setDragCounter((count) => Math.max(0, count - 1));
      }
    };
  }, []);

  const handleDrop = useCallback(
    async (evt) => {
      dragHandlers.preventDefault(evt);
      setDragCounter(0);

      const files = await readFiles(evt.dataTransfer.files);
      onDrop(files, 'CREATE_DROPPED');
    },
    [dragHandlers, onDrop]
  );

  if (isDisabled && preserveRootWhenDisabled) {
    return <Styled.DropTargetContainer>{children}</Styled.DropTargetContainer>;
  } else if (isDisabled) {
    return children;
  }

  return (
    <Styled.DropTargetContainer
      onDragOver={dragHandlers.preventDefault}
      onDragEnter={dragHandlers.onDragEnter}
      onDragLeave={dragHandlers.onDragLeave}
      onDragStart={dragHandlers.preventDefault}
      onDrop={handleDrop}
      // @ts-expect-error TS2769
      $display={display}
      data-test="drop-target"
    >
      {isDragging && <Styled.DragOverlay>{text}</Styled.DragOverlay>}
      {children}
    </Styled.DropTargetContainer>
  );
}
