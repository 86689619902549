/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Button, Checkbox, Layer, RadioTile } from '@carbon/react';

import { getGitProviderLabel } from 'features/git-sync/utils';
import { ConflictResolution, gitSyncStore } from 'features/git-sync';

import * as Styled from './ConflictsHandler.styled';

function ConflictsHandler() {
  const { conflictResolution, shouldShowConflictDisclaimer, canResolveConflict, conflictDisclaimerAccepted } =
    gitSyncStore;

  const handleResolve = (resolution) => {
    gitSyncStore.setConflictResolution(resolution);
  };

  const gitLabel = getGitProviderLabel();

  return (
    <Layer>
      <strong>Resolve conflict</strong>

      <Styled.TilesContainer
        defaultSelected={conflictResolution}
        legend="Select which data you want to keep:"
        name={`Git conflict`}
        onChange={handleResolve}
      >
        <RadioTile id={`local-source`} value={ConflictResolution.OURS} data-test="ours">
          Web Modeler
          <Styled.OptionDescription>
            Keep your local changes. Data from {gitLabel} will not be merged.
          </Styled.OptionDescription>
        </RadioTile>

        <RadioTile id={`remote-source`} value={ConflictResolution.THEIRS} data-test="theirs">
          {gitLabel}
          <Styled.OptionDescription>
            Pull latest {gitLabel} version. This will overwrite your data.
          </Styled.OptionDescription>
        </RadioTile>
      </Styled.TilesContainer>

      <Styled.Actions $hasDisclaimer={shouldShowConflictDisclaimer}>
        {shouldShowConflictDisclaimer && (
          <Styled.Disclaimer>
            <Checkbox
              labelText={
                'I understand that pulling the latest GitHub version may delete data, and it cannot be restored.'
              }
              id="conflict-disclaimer"
              checked={conflictDisclaimerAccepted}
              onChange={() => gitSyncStore.acceptConflictDisclaimer(!conflictDisclaimerAccepted)}
            />

            <div>
              <Styled.YellowWarningIcon />{' '}
              <Styled.CautionMessage>
                This cannot be undone. To mitigate this, download the process application from the Web Modeler.
              </Styled.CautionMessage>
            </div>
          </Styled.Disclaimer>
        )}

        <Button kind="primary" disabled={!canResolveConflict} onClick={() => gitSyncStore.resolveConflict()}>
          {conflictResolution === ConflictResolution.OURS ? 'Resolve conflict' : `Pull from ${gitLabel}`}
        </Button>
      </Styled.Actions>
    </Layer>
  );
}

export default observer(ConflictsHandler);
