/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { TabPanel as BaseTabPanel } from '@carbon/react';

const containerPadding = '40px';

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  padding: ${containerPadding} ${containerPadding} 0 ${containerPadding};
`;

export const Subtitle = styled.span`
  margin: 0 auto 0 0;
  font-size: 15px;
  color: rgb(51, 51, 51);
  min-width: 0;
  > span {
    flex: 1;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  > span {
    flex: 0;
  }
`;

export const TabsContainer = styled.main`
  margin-top: 20px;
`;

export const TabPanel = styled(BaseTabPanel)`
  padding: 24px 0 0;
`;
styled(BaseTabPanel)`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: absolute;
  right: ${containerPadding};
  z-index: 2;
`;

export const PublishOption = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const EmptyStateDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;
