/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { ActionableNotification, ProgressBar as CarbonProgressBar } from '@carbon/react';

export const Message = styled.div`
  margin-bottom: 10px;
`;

export const Notification = styled(ActionableNotification)`
  margin-top: 14px;
`;

export const ProgressBar = styled(CarbonProgressBar)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const VersionTagDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  font-size: 12px;
`;

export const Property = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-small);
`;

export const PropertyName = styled.span`
  color: var(--cds-text-secondary, #525252);
`;

export const PropertyValue = styled.span`
  color: var(--cds-text-primary, #161616);
`;
