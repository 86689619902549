/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';

import { processApplicationStore } from 'stores';

import * as Styled from './CreationModal.styled';

function CreationModal({
  modalContentExtensionSection,
  getAdditionalParametersWhenFinalizingCreation = () => [],
  onModalClose = () => ({})
}) {
  const [processAppName, setProcessAppName] = useState('');

  const hasValidProcessAppName = processAppName.trim().length;

  const closeModal = () => {
    processApplicationStore.setIsCreationModalVisible(false);
    processApplicationStore.setIsAdvertisingModalVisible(false);
    setProcessAppName('');
    onModalClose();
  };

  const performRequest = async () => {
    if (hasValidProcessAppName) {
      await processApplicationStore.finalizeCreation(
        processAppName,
        ...getAdditionalParametersWhenFinalizingCreation()
      );
      closeModal();
    }
  };

  return (
    <Styled.ProcessApplicationModal
      open={processApplicationStore.isCreationModalVisible}
      modalHeading="Create a process application"
      primaryButtonText="Create"
      primaryButtonDisabled={!hasValidProcessAppName}
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={closeModal}
    >
      <Styled.ProcessApplicationModalContent>
        <Styled.NameInput
          data-modal-primary-focus
          id="process-application-name"
          labelText="Name"
          placeholder="New process application"
          value={processAppName}
          onChange={(event) => {
            setProcessAppName(event.target.value);
          }}
        />
        {modalContentExtensionSection}
      </Styled.ProcessApplicationModalContent>
    </Styled.ProcessApplicationModal>
  );
}

export default observer(CreationModal);
